var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.nbLoading > 0
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                ])
              : _c("div", [
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("strong", [_vm._v("Produit")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mb-2 mt-3" }, [
                            _c("div", { staticClass: "col-md-5 pt-1" }, [
                              _c("label", [
                                _vm._v("Choix du produit "),
                                _c("small", [_vm._v("*")]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-7" },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.sale.gv_product_id,
                                        expression: "sale.gv_product_id",
                                      },
                                    ],
                                    staticClass: "custom-select mb-0",
                                    attrs: { disabled: _vm.sale.id !== null },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.sale,
                                          "gv_product_id",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { domProps: { value: null } },
                                      [_vm._v("--")]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.available_products,
                                      function (available_product) {
                                        return _c(
                                          "option",
                                          {
                                            key: available_product.id,
                                            domProps: {
                                              value: available_product.id,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(available_product.name) +
                                                "\n                                    "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "gv_product_id",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.selected_product != null &&
                          _vm.selected_product.type === "menu"
                            ? [
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                                    _c("label", [
                                      _vm._v("Quantité "),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-7" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.sale.menu_quantity,
                                            expression: "sale.menu_quantity",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          disabled: _vm.sale.id !== null,
                                          type: "number",
                                        },
                                        domProps: {
                                          value: _vm.sale.menu_quantity,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.sale,
                                              "menu_quantity",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        attrs: {
                                          errors: _vm.errors,
                                          errorKey: "menu_quantity",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.selected_product.products_options.data
                                  .length > 0
                                  ? _c("div", { staticClass: "row mb-2" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-5 pt-1" },
                                        [
                                          _c("label", [
                                            _vm._v(
                                              "Options de " +
                                                _vm._s(
                                                  _vm.isYservices
                                                    ? "la prestation"
                                                    : "l'offre"
                                                )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-7" },
                                        [
                                          _vm._l(
                                            _vm.selected_product
                                              .products_options.data,
                                            function (products_option) {
                                              return _c(
                                                "div",
                                                {
                                                  key: products_option.id,
                                                  staticClass: "row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-3" },
                                                    [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                products_option.quantity,
                                                              expression:
                                                                "products_option.quantity",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "custom-select",
                                                          attrs: {
                                                            disabled:
                                                              _vm.sale.id !==
                                                              null,
                                                          },
                                                          on: {
                                                            change: [
                                                              function (
                                                                $event
                                                              ) {
                                                                var $$selectedVal =
                                                                  Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                _vm.$set(
                                                                  products_option,
                                                                  "quantity",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.recompute++
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.productsOptionsSelectValues,
                                                          function (
                                                            select_value
                                                          ) {
                                                            return _c(
                                                              "option",
                                                              {
                                                                key: select_value,
                                                                domProps: {
                                                                  value:
                                                                    select_value,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                    " +
                                                                    _vm._s(
                                                                      select_value
                                                                    ) +
                                                                    "\n                                                "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "col-9 pt-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            products_option.name
                                                          ) +
                                                          " -\n                                            " +
                                                          _vm._s(
                                                            products_option.include
                                                              ? "Inclus"
                                                              : _vm.formatCurrency(
                                                                  products_option.price
                                                                )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("ShowErrors", {
                                                    staticClass: "col-12",
                                                    attrs: {
                                                      errors: _vm.errors,
                                                      errorKey: `products_options.${products_option.id}.id`,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("ShowErrors", {
                                                    staticClass: "col-12",
                                                    attrs: {
                                                      errors: _vm.errors,
                                                      errorKey: `products_options.${products_option.id}.quantity`,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            attrs: {
                                              errors: _vm.errors,
                                              errorKey: "products_options",
                                            },
                                          }),
                                        ],
                                        2
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                                    _c("label", [_vm._v("Prix")]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7 pt-1" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatCurrency(_vm.total_menu_price)
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                                    _c("label", [
                                      _vm._v("Date d'expiration"),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-7" },
                                    [
                                      _c("datepicker", {
                                        staticClass: "date-resa-cal",
                                        attrs: {
                                          format: "dd/MM/yyyy",
                                          disabled: _vm.sale.id === null,
                                          "disabled-dates": _vm.disabledDates,
                                          "calendar-class":
                                            "positition-relative mt-2",
                                          "input-class": "w-auto",
                                          "monday-first": true,
                                          language: _vm.fr,
                                        },
                                        model: {
                                          value: _vm.expires_on,
                                          callback: function ($$v) {
                                            _vm.expires_on = $$v
                                          },
                                          expression: "expires_on",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        attrs: {
                                          errors: _vm.errors,
                                          errorKey: "expires_on",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selected_product != null &&
                          _vm.selected_product.type === "amount"
                            ? [
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                                    _c("label", [
                                      _vm._v("Montant "),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _vm.selected_product.allow_custom_amounts
                                    ? _c(
                                        "div",
                                        { staticClass: "col-md-7" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.amount_custom,
                                                expression: "amount_custom",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              disabled: _vm.sale.id !== null,
                                              type: "number",
                                              min: _vm.selected_product
                                                .min_amount,
                                              max: _vm.selected_product
                                                .max_amount,
                                            },
                                            domProps: {
                                              value: _vm.amount_custom,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.amount_custom =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            attrs: {
                                              errors: _vm.errors,
                                              errorKey: "amount",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "col-md-7" },
                                        [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.amount_select,
                                                  expression: "amount_select",
                                                },
                                              ],
                                              staticClass: "custom-select mb-0",
                                              attrs: {
                                                disabled: _vm.sale.id !== null,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.amount_select = $event
                                                    .target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                { domProps: { value: null } },
                                                [_vm._v("--")]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.selected_product
                                                  .predefined_amounts,
                                                function (amount) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: amount,
                                                      domProps: {
                                                        value: amount,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(amount) +
                                                          "€\n                                        "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            attrs: {
                                              errors: _vm.errors,
                                              errorKey: "amount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                                    _c("label", [
                                      _vm._v("Date d'expiration"),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-7" },
                                    [
                                      _c("datepicker", {
                                        staticClass: "date-resa-cal",
                                        attrs: {
                                          format: "dd/MM/yyyy",
                                          disabled: _vm.sale.id === null,
                                          "disabled-dates": _vm.disabledDates,
                                          "calendar-class":
                                            "positition-relative mt-2",
                                          "input-class": "w-auto",
                                          "monday-first": true,
                                          language: _vm.fr,
                                        },
                                        model: {
                                          value: _vm.expires_on,
                                          callback: function ($$v) {
                                            _vm.expires_on = $$v
                                          },
                                          expression: "expires_on",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        attrs: {
                                          errors: _vm.errors,
                                          errorKey: "expires_on",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("strong", [_vm._v("Paiement")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mb-2 mt-3" }, [
                            _c("div", { staticClass: "col-md-5 pt-1" }, [
                              _c("label", [
                                _vm._v("Type de paiement "),
                                _c("small", [_vm._v("*")]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-7" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-inline-block align-middle lead-switch switch-choice",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.sale.send_payment_ask,
                                          expression: "sale.send_payment_ask",
                                        },
                                      ],
                                      staticClass:
                                        "switch align-self-center is-rounded",
                                      attrs: {
                                        disabled: _vm.sale.id !== null,
                                        type: "checkbox",
                                        id: "chk-send_payment_ask",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.sale.send_payment_ask
                                        )
                                          ? _vm._i(
                                              _vm.sale.send_payment_ask,
                                              null
                                            ) > -1
                                          : _vm.sale.send_payment_ask,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.sale.send_payment_ask,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.sale,
                                                  "send_payment_ask",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.sale,
                                                  "send_payment_ask",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.sale,
                                              "send_payment_ask",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(
                                      "\n                                    Manuel "
                                    ),
                                    _c("label", {
                                      attrs: { for: "chk-send_payment_ask" },
                                    }),
                                    _vm._v(
                                      "Envoyer une demande de paiement\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "send_payment_ask",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          !_vm.sale.send_payment_ask
                            ? [
                                _c("div", { staticClass: "row mb-2 mt-3" }, [
                                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                                    _c("label", [
                                      _vm._v("Moyen de paiement "),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-7" },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.sale.payment_type,
                                              expression: "sale.payment_type",
                                            },
                                          ],
                                          staticClass: "custom-select mb-0",
                                          attrs: {
                                            disabled: _vm.sale.id !== null,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.sale,
                                                "payment_type",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { domProps: { value: null } },
                                            [_vm._v("--")]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.available_payment_types,
                                            function (available_payment_type) {
                                              return _c(
                                                "option",
                                                {
                                                  key: available_payment_type,
                                                  domProps: {
                                                    value:
                                                      available_payment_type,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.getPaymentTypeLabel(
                                                          available_payment_type
                                                        )
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        attrs: {
                                          errors: _vm.errors,
                                          errorKey: "payment_type",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("strong", [_vm._v("Expédition")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mb-2 mt-3" }, [
                            _c("div", { staticClass: "col-md-5 pt-1" }, [
                              _c("label", [
                                _vm._v("Mode d'envoi "),
                                _c("small", [_vm._v("*")]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-7" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-inline-block align-middle lead-switch switch-choice",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.gift_expedition_type,
                                          expression: "gift_expedition_type",
                                        },
                                      ],
                                      staticClass:
                                        "switch align-self-center is-rounded",
                                      attrs: {
                                        disabled:
                                          _vm.sale.id !== null ||
                                          !_vm.expedition_postal_mail_available,
                                        type: "checkbox",
                                        id: "chk-gift_expedition_type",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.gift_expedition_type
                                        )
                                          ? _vm._i(
                                              _vm.gift_expedition_type,
                                              null
                                            ) > -1
                                          : _vm.gift_expedition_type,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.gift_expedition_type,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.gift_expedition_type =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.gift_expedition_type = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.gift_expedition_type = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(
                                      "\n                                    Mail "
                                    ),
                                    _c("label", {
                                      attrs: {
                                        for: "chk-gift_expedition_type",
                                      },
                                    }),
                                    _vm._v(
                                      "Courrier\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "expedition_type",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("div", { staticClass: "col-md-5 pt-1" }, [
                              _c("label", [
                                _vm._v("Destinataire "),
                                _c("small", [_vm._v("*")]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-7" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-inline-block align-middle lead-switch switch-choice",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.sale.has_beneficiary,
                                          expression: "sale.has_beneficiary",
                                        },
                                      ],
                                      staticClass:
                                        "switch align-self-center is-rounded",
                                      attrs: {
                                        disabled: _vm.sale.id !== null,
                                        type: "checkbox",
                                        id: "chk-has_beneficiary",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.sale.has_beneficiary
                                        )
                                          ? _vm._i(
                                              _vm.sale.has_beneficiary,
                                              null
                                            ) > -1
                                          : _vm.sale.has_beneficiary,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.sale.has_beneficiary,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.sale,
                                                  "has_beneficiary",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.sale,
                                                  "has_beneficiary",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.sale,
                                              "has_beneficiary",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(
                                      "\n                                    Client "
                                    ),
                                    _c("label", {
                                      attrs: { for: "chk-has_beneficiary" },
                                    }),
                                    _vm._v(
                                      "Bénéficiaire\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "has_beneficiary",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.sale.has_beneficiary
                            ? [
                                _c("div", { staticClass: "row mt-3" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c("strong", [
                                      _vm._v("Informations du bénéficiaire"),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2 mt-3" }, [
                                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                                    _c("label", [
                                      _vm._v("Prénom "),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-7" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.sale.beneficiary_firstname,
                                            expression:
                                              "sale.beneficiary_firstname",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.sale.beneficiary_firstname,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.sale,
                                              "beneficiary_firstname",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        attrs: {
                                          errors: _vm.errors,
                                          errorKey: "beneficiary_firstname",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                                    _c("label", [
                                      _vm._v("Nom "),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-7" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.sale.beneficiary_lastname,
                                            expression:
                                              "sale.beneficiary_lastname",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.sale.beneficiary_lastname,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.sale,
                                              "beneficiary_lastname",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        attrs: {
                                          errors: _vm.errors,
                                          errorKey: "beneficiary_lastname",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                !_vm.gift_expedition_type
                                  ? _c("div", { staticClass: "row mb-2" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-5 pt-1" },
                                        [_c("label", [_vm._v("Email")])]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-7" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.sale.beneficiary_email,
                                                expression:
                                                  "sale.beneficiary_email",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: { type: "email" },
                                            domProps: {
                                              value: _vm.sale.beneficiary_email,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.sale,
                                                  "beneficiary_email",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          !_vm.gift_expedition_type
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "d-block text-warning",
                                                },
                                                [
                                                  _vm._v(
                                                    "Si vous ne renseignez pas le mail du bénéficiaire, le bon cadeau ne sera pas transmis\n                                        automatiquement."
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            attrs: {
                                              errors: _vm.errors,
                                              errorKey: "beneficiary_email",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.gift_expedition_type
                            ? [
                                _c("div", { staticClass: "row my-2" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c("strong", [
                                      _vm._v("Adresse d'expédition"),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                                    _c("label", [
                                      _vm._v("Prénom "),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-7" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.sale.delivery_firstname,
                                            expression:
                                              "sale.delivery_firstname",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.sale.delivery_firstname,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.sale,
                                              "delivery_firstname",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        attrs: {
                                          errors: _vm.errors,
                                          errorKey: "delivery_firstname",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                                    _c("label", [
                                      _vm._v("Nom "),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-7" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.sale.delivery_lastname,
                                            expression:
                                              "sale.delivery_lastname",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.sale.delivery_lastname,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.sale,
                                              "delivery_lastname",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        attrs: {
                                          errors: _vm.errors,
                                          errorKey: "delivery_lastname",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                                    _c("label", [
                                      _vm._v("Adresse "),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-7" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.sale.delivery_address,
                                            expression: "sale.delivery_address",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "email" },
                                        domProps: {
                                          value: _vm.sale.delivery_address,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.sale,
                                              "delivery_address",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        attrs: {
                                          errors: _vm.errors,
                                          errorKey: "delivery_address",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                                    _c("label", [
                                      _vm._v("Code postal "),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-7" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.sale.delivery_postal_code,
                                            expression:
                                              "sale.delivery_postal_code",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "email" },
                                        domProps: {
                                          value: _vm.sale.delivery_postal_code,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.sale,
                                              "delivery_postal_code",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        attrs: {
                                          errors: _vm.errors,
                                          errorKey: "delivery_postal_code",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                                    _c("label", [
                                      _vm._v("Ville "),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-7" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.sale.delivery_city,
                                            expression: "sale.delivery_city",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "email" },
                                        domProps: {
                                          value: _vm.sale.delivery_city,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.sale,
                                              "delivery_city",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        attrs: {
                                          errors: _vm.errors,
                                          errorKey: "delivery_city",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                                    _c("label", [
                                      _vm._v("Pays "),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-7" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.sale.delivery_country,
                                            expression: "sale.delivery_country",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          disabled: _vm.sale.id !== null,
                                          value: "France",
                                          type: "email",
                                        },
                                        domProps: {
                                          value: _vm.sale.delivery_country,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.sale,
                                              "delivery_country",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        attrs: {
                                          errors: _vm.errors,
                                          errorKey: "delivery_country",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("strong", [_vm._v("Client")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mt-3 mb-2 radio" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-md-4 d-none d-md-block pt-2",
                              },
                              [
                                _c("label", [
                                  _vm._v("Civilité "),
                                  _vm.mandatoryFields.civility
                                    ? _c("small", [_vm._v("*")])
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-8 pt-2" },
                              [
                                _c("label", { staticClass: "container-box" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.client.civility,
                                        expression: "client.civility",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "civility",
                                      value: "monsieur",
                                      tabindex: "5",
                                      disabled: _vm.client.is_deleted,
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.client.civility,
                                        "monsieur"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.client,
                                          "civility",
                                          "monsieur"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "checkmark" }),
                                  _vm._v(
                                    " Monsieur\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("label", { staticClass: "container-box" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.client.civility,
                                        expression: "client.civility",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      name: "civility",
                                      value: "madame",
                                      tabindex: "6",
                                      disabled: _vm.client.is_deleted,
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.client.civility,
                                        "madame"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.client,
                                          "civility",
                                          "madame"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "checkmark" }),
                                  _vm._v(
                                    " Madame\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  staticClass: "d-block",
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "client.civility",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-md-4 d-none d-md-block pt-2",
                              },
                              [
                                _c("label", [
                                  _vm._v("Prénom "),
                                  _vm.mandatoryFields.firstname
                                    ? _c("small", [_vm._v("*")])
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-8" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dropdown add-resa-search-client",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.client.firstname,
                                          expression: "client.firstname",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        disabled: _vm.client.is_deleted,
                                        placeholder: "Prénom",
                                        name: "firstname",
                                        autocomplete: "off",
                                        tabindex: "7",
                                      },
                                      domProps: { value: _vm.client.firstname },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.client,
                                              "firstname",
                                              $event.target.value
                                            )
                                          },
                                          _vm.searchClientByMultiField,
                                        ],
                                        focus: function ($event) {
                                          _vm.client.firstnameHasFocus = true
                                        },
                                        blur: function ($event) {
                                          _vm.client.firstnameHasFocus = false
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    (_vm.client.firstnameHasFocus ||
                                      _vm.client.search.firstnameHasFocus) &&
                                    _vm.client.search.results &&
                                    _vm.client.search.results.length > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "dropdown-menu prename-field shadow show p-0",
                                            on: {
                                              mouseover: function ($event) {
                                                _vm.client.search.firstnameHasFocus = true
                                              },
                                              mouseout: function ($event) {
                                                _vm.client.search.firstnameHasFocus = false
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "ul",
                                              { staticClass: "list-group" },
                                              _vm._l(
                                                _vm.client.search.results,
                                                function (result) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "list-group-item p-0",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pointer p-3",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectClient(
                                                                result
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                result.firstname
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  result.lastname
                                                                )
                                                            ),
                                                          ]),
                                                          _vm._v(
                                                            " |\n                                                    "
                                                          ),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(result.tel)
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "email-search",
                                                            },
                                                            [
                                                              result.company
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          result.company
                                                                        ) +
                                                                        " | "
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v(
                                                                "\n                                                        " +
                                                                  _vm._s(
                                                                    result.email
                                                                  ) +
                                                                  "\n                                                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "client.firstname",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-md-4 d-none d-md-block pt-2",
                              },
                              [
                                _c("label", [
                                  _vm._v("Nom "),
                                  _vm.mandatoryFields.lastname
                                    ? _c("small", [_vm._v("*")])
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-8" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dropdown add-resa-search-client",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.client.lastname,
                                          expression: "client.lastname",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        disabled: _vm.client.is_deleted,
                                        placeholder: "Nom",
                                        name: "lastname",
                                        autocomplete: "off",
                                        tabindex: "8",
                                      },
                                      domProps: { value: _vm.client.lastname },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.client,
                                              "lastname",
                                              $event.target.value
                                            )
                                          },
                                          _vm.searchClientByMultiField,
                                        ],
                                        focus: function ($event) {
                                          _vm.client.lastnameHasFocus = true
                                        },
                                        blur: function ($event) {
                                          _vm.client.lastnameHasFocus = false
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    (_vm.client.lastnameHasFocus ||
                                      _vm.client.search.lastnameHasFocus) &&
                                    _vm.client.search.results &&
                                    _vm.client.search.results.length > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "dropdown-menu name-field shadow show p-0",
                                            on: {
                                              mouseover: function ($event) {
                                                _vm.client.search.lastnameHasFocus = true
                                              },
                                              mouseout: function ($event) {
                                                _vm.client.search.lastnameHasFocus = false
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "ul",
                                              { staticClass: "list-group" },
                                              _vm._l(
                                                _vm.client.search.results,
                                                function (result) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "list-group-item p-0",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "d-block p-3",
                                                          attrs: {
                                                            href: "javascript:",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectClient(
                                                                result
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                result.firstname
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  result.lastname
                                                                )
                                                            ),
                                                          ]),
                                                          _vm._v(
                                                            " |\n                                                    "
                                                          ),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(result.tel)
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "email-search",
                                                            },
                                                            [
                                                              result.company
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        result.company
                                                                      ) + " |"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v(
                                                                "\n                                                        " +
                                                                  _vm._s(
                                                                    result.email
                                                                  ) +
                                                                  "\n                                                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "client.lastname",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-md-4 d-none d-md-block pt-2",
                              },
                              [
                                _c("label", [
                                  _vm._v("Société "),
                                  _vm.mandatoryFields.company
                                    ? _c("small", [_vm._v("*")])
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-8" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dropdown add-resa-search-client",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.client.company,
                                          expression: "client.company",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        disabled: _vm.client.is_deleted,
                                        placeholder: "Société",
                                        name: "company",
                                        autocomplete: "off",
                                        tabindex: "8",
                                      },
                                      domProps: { value: _vm.client.company },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.client,
                                              "company",
                                              $event.target.value
                                            )
                                          },
                                          _vm.searchClientByMultiField,
                                        ],
                                        focus: function ($event) {
                                          _vm.client.companyHasFocus = true
                                        },
                                        blur: function ($event) {
                                          _vm.client.companyHasFocus = false
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    (_vm.client.companyHasFocus ||
                                      _vm.client.search.companyHasFocus) &&
                                    _vm.client.search.results &&
                                    _vm.client.search.results.length > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "dropdown-menu name-field shadow show p-0",
                                            on: {
                                              mouseover: function ($event) {
                                                _vm.client.search.companyHasFocus = true
                                              },
                                              mouseout: function ($event) {
                                                _vm.client.search.companyHasFocus = false
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "ul",
                                              { staticClass: "list-group" },
                                              _vm._l(
                                                _vm.client.search.results,
                                                function (result) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "list-group-item p-0",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "d-block p-3",
                                                          attrs: {
                                                            href: "javascript:",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectClient(
                                                                result
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                result.firstname
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  result.company
                                                                )
                                                            ),
                                                          ]),
                                                          _vm._v(
                                                            " |\n                                                    "
                                                          ),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(result.tel)
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "email-search",
                                                            },
                                                            [
                                                              result.company
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        result.company
                                                                      ) + " |"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    result.email
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "client.company",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-md-4 d-none d-md-block pt-2",
                              },
                              [
                                _c("label", [
                                  _vm._v("Email "),
                                  _vm.mandatoryFields.email
                                    ? _c("small", [_vm._v("*")])
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-8" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dropdown add-resa-search-client",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.client.email,
                                          expression: "client.email",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        disabled: _vm.client.is_deleted,
                                        placeholder: "Email",
                                        name: "email",
                                        autocomplete: "off",
                                        tabindex: "9",
                                      },
                                      domProps: { value: _vm.client.email },
                                      on: {
                                        focus: function ($event) {
                                          _vm.client.emailHasFocus = true
                                        },
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.client,
                                              "email",
                                              $event.target.value
                                            )
                                          },
                                          _vm.searchClientByMultiField,
                                        ],
                                        blur: function ($event) {
                                          _vm.client.emailHasFocus = false
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    (_vm.client.emailHasFocus ||
                                      _vm.client.search.emailHasFocus) &&
                                    _vm.client.search.results &&
                                    _vm.client.search.results.length > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "dropdown-menu email-field shadow show p-0",
                                            on: {
                                              mouseover: function ($event) {
                                                _vm.client.search.emailHasFocus = true
                                              },
                                              mouseout: function ($event) {
                                                _vm.client.search.emailHasFocus = false
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "ul",
                                              { staticClass: "list-group" },
                                              _vm._l(
                                                _vm.client.search.results,
                                                function (result) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "list-group-item p-0",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "d-block p-3",
                                                          attrs: {
                                                            href: "javascript:",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectClient(
                                                                result
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                result.firstname
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  result.lastname
                                                                )
                                                            ),
                                                          ]),
                                                          _vm._v(
                                                            " |\n                                                    "
                                                          ),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(result.tel)
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "email-search",
                                                            },
                                                            [
                                                              result.company
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        result.company
                                                                      ) + " |"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    result.email
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "client.email",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-md-4 d-none d-md-block pt-2",
                              },
                              [
                                _c("label", [
                                  _vm._v("Téléphone "),
                                  _vm.mandatoryFields.tel
                                    ? _c("small", [_vm._v("*")])
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-8" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dropdown add-resa-search-client",
                                  },
                                  [
                                    _c("vue-tel-input", {
                                      ref: "vueTelInput",
                                      staticClass:
                                        "form-control d-flex text-left",
                                      attrs: {
                                        defaultCountry: _vm.defaultTelCountry,
                                        name: "telInput",
                                        disabled: _vm.client.is_deleted,
                                        enabledCountryCode: true,
                                        inputOptions: {
                                          tabindex: 10,
                                          autocomplete: "off",
                                        },
                                        mode: "international",
                                        inputClasses: "m-0 border-0",
                                        placeholder: "Téléphone",
                                      },
                                      on: {
                                        onValidate: _vm.setPhoneAndCountry,
                                        onInput: _vm.searchClientByMultiField,
                                      },
                                      model: {
                                        value: _vm.client.phone.raw,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.client.phone, "raw", $$v)
                                        },
                                        expression: "client.phone.raw",
                                      },
                                    }),
                                    _vm._v(" "),
                                    (_vm.client.phoneNumberHasFocus ||
                                      _vm.client.search.phoneNumberHasFocus) &&
                                    _vm.client.search.results &&
                                    _vm.client.search.results.length > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "dropdown-menu phone-field shadow show p-0",
                                            on: {
                                              mouseover: function ($event) {
                                                _vm.client.search.phoneNumberHasFocus = true
                                              },
                                              mouseout: function ($event) {
                                                _vm.client.search.phoneNumberHasFocus = false
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "ul",
                                              { staticClass: "list-group" },
                                              _vm._l(
                                                _vm.client.search.results,
                                                function (result) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "list-group-item p-0",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "d-block p-3",
                                                          attrs: {
                                                            href: "javascript:",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectClient(
                                                                result
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                result.firstname
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  result.lastname
                                                                )
                                                            ),
                                                          ]),
                                                          _vm._v(
                                                            " |\n                                                    "
                                                          ),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(result.tel)
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "email-search",
                                                            },
                                                            [
                                                              result.company
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        result.company
                                                                      ) + " |"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    result.email
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "client.tel",
                                  },
                                }),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "client.tel_country",
                                  },
                                }),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "client.id",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-md-4 d-none d-md-block pt-2",
                              },
                              [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(_vm.$tl("labels.clients.type")) + " "
                                  ),
                                  _vm.mandatoryFields.type
                                    ? _c("small", [_vm._v("*")])
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-8" },
                              [
                                _c("select-client-type", {
                                  attrs: { "restaurant-id": _vm.restaurantId },
                                  model: {
                                    value: _vm.client.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.client, "type", $$v)
                                    },
                                    expression: "client.type",
                                  },
                                }),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "client.type",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-4 pt-1" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$tl("labels.clients.vip"))),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-8 lead-switch" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.client.is_vip,
                                    expression: "client.is_vip",
                                  },
                                ],
                                staticClass:
                                  "switch align-self-center is-rounded",
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.client.is_vip)
                                    ? _vm._i(_vm.client.is_vip, null) > -1
                                    : _vm.client.is_vip,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.client.is_vip,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.client,
                                            "is_vip",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.client,
                                            "is_vip",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.client, "is_vip", $$c)
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", {
                                on: {
                                  click: function ($event) {
                                    _vm.client.is_vip = !_vm.client.is_vip
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-4 pt-1" }, [
                              _c("label", { staticClass: "none-mobile" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tl("labels.form.acceptNewsletter")
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("label", { staticClass: "none-desk" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tl("labels.form.acceptNewsletterShort")
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-8 lead-switch" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.client.newsletter,
                                    expression: "client.newsletter",
                                  },
                                ],
                                staticClass:
                                  "switch align-self-center is-rounded",
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.client.newsletter)
                                    ? _vm._i(_vm.client.newsletter, null) > -1
                                    : _vm.client.newsletter,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.client.newsletter,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.client,
                                            "newsletter",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.client,
                                            "newsletter",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.client, "newsletter", $$c)
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", {
                                on: {
                                  click: function ($event) {
                                    _vm.client.newsletter =
                                      !_vm.client.newsletter
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.client.phone.search.results !== null &&
                          _vm.client.id === null
                            ? _c(
                                "div",
                                { staticClass: "add-resa-search-client" },
                                [
                                  _vm.client.phone.search.results.length > 0
                                    ? _c("div", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-danger d-inline-block mt-2 mb-2",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    Un client existe déjà avec ce numéro de téléphone."
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "\n                                    Si vous enregistrez le bon cadeau, celui-ci sera mis à jour.\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "list-group-item p-0",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "d-block p-3",
                                                attrs: { href: "javascript:" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectClient(
                                                      _vm.client.phone.search
                                                        .results[0]
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.client.phone.search
                                                          .results[0].firstname
                                                      ) +
                                                      "\n                                            " +
                                                      _vm._s(
                                                        _vm.client.phone.search
                                                          .results[0].lastname
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]),
                                                _vm._v(
                                                  "\n                                        | "
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.client.phone.search
                                                        .results[0].tel
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "email-search",
                                                  },
                                                  [
                                                    _vm.client.phone.search
                                                      .results[0].company
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.client.phone
                                                                .search
                                                                .results[0]
                                                                .company
                                                            ) + " |"
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.client.phone.search
                                                          .results[0].email
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _c("div", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-inline-block mt-2 mb-2 text-muted",
                                          },
                                          [
                                            _vm._v(
                                              "Le client sera créé à l'enregistrement du bon cadeau."
                                            ),
                                          ]
                                        ),
                                      ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("strong", [_vm._v("Commentaire privé")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.sale.comment_private,
                                      expression: "sale.comment_private",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { rows: "3" },
                                  domProps: { value: _vm.sale.comment_private },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.sale,
                                        "comment_private",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "comment_private",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }