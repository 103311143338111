<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Détails du {{ emailsCampaignPack.name }}</h5>
        <div slot="body">
            <purchase-emails-campaign-pack
                :emailsCampaignPack="emailsCampaignPack"
                @close="close"
                @paid="$emit('paid', $event)"
                @disable-close-button="closeButtonEnable = false" />
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button
                class="modal-default-button btn-sm btn btn-secondary btn-circle"
                @click="close"
                :disabled="!closeButtonEnable">
                Fermer
            </button>
        </div>
    </modal>
</template>

<script>
import PurchaseEmailsCampaignPack from "../../campaign/PurchaseEmailsCampaignPack";

export default {
    name: "PurchaseEmailsCampaignPackModal",
    data: function () {
        return {
            loading: false,
            emailsCampaignPack: {
                name: "Pack emails de campagne",
                description: "Ce pack vous octroiera un crédit de 10.000 emails de campagne.",
                price: 1200, // in cent
                price_ht: 1000, // in cent
            },
            closeButtonEnable: true,
        };
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        PurchaseEmailsCampaignPack,
    },
};
</script>
