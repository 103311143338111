var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "p",
      [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.$t("labels.clients.import.steps.1.acceptedFiles", {
                formattedAllowedExtensions: _vm.formattedAllowedExtensions,
              })
            )
        ),
        _c("br"),
        _vm._v(" "),
        _vm.allowedExtensions.includes(".csv")
          ? [
              _vm._v(
                " " +
                  _vm._s(_vm.$tl("labels.clients.import.steps.1.specificToCSV"))
              ),
              _c("br"),
            ]
          : _vm._e(),
        _vm._v(" "),
        _c("span", {
          domProps: {
            innerHTML: _vm._s(
              _vm.$tl("labels.clients.import.steps.1.noHeader")
            ),
          },
        }),
        _c("br"),
        _vm._v(
          "\n        " +
            _vm._s(_vm.$tl("labels.clients.import.steps.1.defineCol")) +
            "\n    "
        ),
      ],
      2
    ),
    _vm._v(" "),
    _c("p", [_vm._v("Liste des champs autorisés :")]),
    _vm._v(" "),
    _c("ul", [
      _c("li", { staticClass: "list-style-type-circle" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.$tl("labels.form.civility")) +
            ' ("' +
            _vm._s(
              _vm.$tl("labels.clients.civilities.long.monsieur").toLowerCase()
            ) +
            '" ou "' +
            _vm._s(
              _vm.$tl("labels.clients.civilities.long.madame").toLowerCase()
            ) +
            '")\n        '
        ),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "list-style-type-circle" }, [
        _vm._v(_vm._s(_vm.$tl("labels.form.lastname")) + " "),
        _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "list-style-type-circle" }, [
        _vm._v(_vm._s(_vm.$tl("labels.form.firstname")) + " "),
        _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "list-style-type-circle" }, [
        _vm._v(_vm._s(_vm.$tl("labels.form.company"))),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "list-style-type-circle" }, [
        _vm._v(_vm._s(_vm.$tl("labels.form.email"))),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "list-style-type-circle" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.$tl("labels.form.phoneNumber")) +
            "\n            "
        ),
        _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
        _vm._v(
          "\n            (" +
            _vm._s(
              _vm.$tl("labels.clients.import.steps.1.allowedFields.phoneInfo")
            ) +
            ")\n        "
        ),
      ]),
      _vm._v(" "),
      _c(
        "li",
        { staticClass: "list-style-type-circle" },
        [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.$tl(
                  "labels.clients.import.steps.1.allowedFields.phoneCountryCode"
                )
              ) +
              "\n            "
          ),
          _c(
            "i18n",
            {
              attrs: {
                path: "labels.clients.import.steps.1.allowedFields.phoneCountryCodeFormat",
                tag: "span",
              },
            },
            [
              _c("template", { slot: "link" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      href: "http://www.atlas-monde.net/codes-iso/",
                    },
                  },
                  [_vm._v("ISO 3166-1 alpha-2")]
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("li", { staticClass: "list-style-type-circle" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.$tl("labels.clients.type")) +
            " (" +
            _vm._s(
              _vm.$tl(
                "labels.clients.import.steps.1.allowedFields.clientTypeDefault"
              )
            ) +
            ")\n        "
        ),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "list-style-type-circle" }, [
        _vm._v(
          _vm._s(_vm.$tl("labels.clients.import.steps.1.allowedFields.isVip"))
        ),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "list-style-type-circle" }, [
        _vm._v(
          _vm._s(_vm.$tl("labels.clients.import.steps.1.allowedFields.isRisky"))
        ),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "list-style-type-circle" }, [
        _vm._v(
          _vm._s(
            _vm.$tl("labels.clients.import.steps.1.allowedFields.newsletter")
          )
        ),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "list-style-type-circle" }, [
        _vm._v(_vm._s(_vm.$tl("labels.booking.reservations.clientComment"))),
      ]),
    ]),
    _vm._v(" "),
    _c("p", [
      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
      _vm._v(" " + _vm._s(_vm.$tl("labels.form.mandatoryFields"))),
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "\n        " +
          _vm._s(
            _vm.$t("infos.clients.import.noPhoneNumberCountryCodeNote", {
              phoneCountryCode: _vm.$tl(
                "labels.clients.import.steps.1.allowedFields.phoneCountryCode"
              ),
            })
          )
      ),
      _c("br"),
      _vm._v(
        "\n        " +
          _vm._s(
            _vm.$tl("infos.clients.import.phoneNumberCountryCodeConsiderFrench")
          ) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      _vm._l(_vm.allowedExtensions, function (ext) {
        return _c(
          "a",
          {
            key: ext,
            staticClass: "d-block",
            attrs: { href: `/assets/clients${ext}`, download: `clients${ext}` },
          },
          [
            _c("small", [
              _vm._v(
                _vm._s(
                  _vm.$t("labels.clients.import.steps.1.exampleFormat", { ext })
                )
              ),
            ]),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("input", {
      attrs: { id: "fileInput", type: "file", accept: _vm.allowedExtensions },
      on: { change: _vm.fileInputChanged },
    }),
    _vm._v(" "),
    _c("div", [
      _vm.errors.form !== null
        ? _c(
            "div",
            { staticClass: "alert alert-danger d-block position-relative" },
            _vm._l(_vm.errors.forms, function (error, i) {
              return _c("p", { key: i }, [_vm._v(_vm._s(error))])
            }),
            0
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }