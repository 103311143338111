export default {
    data() {
        return {
            DISPATCH_TYPE_HOME: {
                value: "home",
                label: "À domicile",
            },
            DISPATCH_TYPE_SERVICE_POINT: {
                value: "service_point",
                label: "En point relais",
            },
        };
    },
    methods: {
        getDispatchTypeLabel(value) {
            const found = this.ALL_DISPATCH_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
    },
    computed: {
        ALL_DISPATCH_TYPES() {
            return [this.DISPATCH_TYPE_HOME, this.DISPATCH_TYPE_SERVICE_POINT];
        },
    },
};
