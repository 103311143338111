<template>
    <div class="pb-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Paiements</h5>
                </div>
            </div>
        </div>
        <div class="row m-0 pb-5">
            <div class="col-12">
                <LoaderComponent v-if="loading" />
                <div v-else>
                    <div v-if="error" class="alert alert-danger">{{ error }}</div>
                    <div v-else>
                        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-3">
                            <div>
                                <div style="margin-top: 4px" class="d-inline-block">
                                    <form @submit="fetchData">
                                        <div style="border-radius: 20px 0 0 20px !important" class="form-control search-client d-inline pr-1">
                                            <input v-model="filter" class="search-input" placeholder="Nom, Numéro..." type="text" />
                                            <button
                                                type="button"
                                                @click="resetFilter"
                                                class="p-0 m-0 clear-search-button"
                                                style="background: none; border: none">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-x">
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            </button>
                                        </div>
                                        <button
                                            type="submit"
                                            class="btn btn-sm btn-outline-secondary search-client-btn"
                                            style="border-radius: 0 20px 20px 0">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-search">
                                                <circle cx="11" cy="11" r="8" />
                                                <line x1="21" y1="21" x2="16.65" y2="16.65" />
                                            </svg>
                                        </button>
                                    </form>
                                </div>
                                <button @click="showAdvanceSearch = !showAdvanceSearch" class="btn btn-sm btn-outline-secondary ml-1 btn-circle">
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="css-i6dzq1 feather">
                                        <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
                                    </svg>
                                    Filtres
                                </button>
                            </div>
                            <div>
                                <div class="btn-group">
                                    <!-- <button class="btn btn-sm btn-outline-secondary mb-0 none-mobile" style="border-radius: 20px 0 0 20px">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                                        Importer
                  </button>-->

                                    <button
                                        :disabled="!has_right_to_export_payments"
                                        @click="exportPayments()"
                                        class="btn btn-sm btn-outline-secondary btn-circle none-mobile"
                                        style="border-radius: 50px 0 0 50px !important">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-upload">
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                            <polyline points="17 8 12 3 7 8"></polyline>
                                            <line x1="12" y1="3" x2="12" y2="15"></line>
                                        </svg>
                                        Export Excel
                                    </button>
                                    <button
                                        :disabled="!has_right_to_export_payments"
                                        @click="downloadPayments()"
                                        class="btn btn-sm btn-outline-secondary btn-circle none-mobile"
                                        style="border-radius: 0 50px 50px 0 !important">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-upload">
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                            <polyline points="17 8 12 3 7 8"></polyline>
                                            <line x1="12" y1="3" x2="12" y2="15"></line>
                                        </svg>
                                        Télécharger
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div v-if="showAdvanceSearch" class="advance-filter">
                            <div class="m-0" style="color: #666666">
                                <div class="border-light b-radius-20 p-4 mb-3">
                                    <div class="row">
                                        <div class="col-12 d-inline-block date-resa-cal">
                                            Statut
                                            <select
                                                v-model="filter_status"
                                                class="custom-select d-inline-block ml-2 mr-2 mb-0"
                                                style="width: initial">
                                                <option :value="null">--</option>
                                                <option value="paid">Payé</option>
                                                <option value="canceled">Annulé</option>
                                                <option value="refunded">Remboursé</option>
                                                <option value="pending">En attente</option>
                                            </select>
                                            Moyen de paiement
                                            <select
                                                v-model="filter_payment_type"
                                                class="custom-select d-inline-block ml-2 mr-2 mb-0"
                                                style="width: initial">
                                                <option :value="null">--</option>
                                                <option value="stripe">Carte bancaire (en ligne)</option>
                                                <option value="card">Carte bancaire (sur place)</option>
                                                <option value="check">Chèque</option>
                                                <option value="cash">Espèces</option>
                                                <option value="meal_voucher">Titre restaurant</option>
                                            </select>
                                            Du&nbsp;
                                            <datepicker
                                                class="d-inline-block"
                                                format="dd/MM/yyyy"
                                                input-class="form-control"
                                                :monday-first="true"
                                                :language="fr"
                                                v-model="filter_from_date_payment"></datepicker>
                                            &nbsp;au&nbsp;
                                            <datepicker
                                                class="d-inline-block"
                                                format="dd/MM/yyyy"
                                                input-class="form-control"
                                                :monday-first="true"
                                                :language="fr"
                                                v-model="filter_to_date_payment"></datepicker>
                                            <button @click="fetchData()" class="btn btn-sm btn-success btn-circle ml-2">Rechercher</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <table class="capitalize table table-sm table-striped border-bottom">
                            <thead class="border-bottom">
                                <tr>
                                    <th>N°</th>
                                    <th>Montant</th>
                                    <th>Statut</th>
                                    <th>Client</th>
                                    <th>Date</th>
                                    <th><small>Moyen de</small> paiement</th>
                                    <th v-if="!isTvaDisabled"><small>TVA</small> 5.5%</th>
                                    <th v-if="!isTvaDisabled"><small>TVA</small> 10%</th>
                                    <th v-if="!isTvaDisabled"><small>TVA</small> 20%</th>
                                    <th>Frais</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    class="clickable"
                                    v-for="(payment, i) in formattedPayments"
                                    v-bind:key="i"
                                    @click="showCommand($event, payment[0].command_id)">
                                    <td>{{ payment[0].numero }}</td>
                                    <td>
                                        {{ formatCurrency(getAmountSum(payment) / 100)
                                        }}<span v-if="payment[0].amount_refunded" class="text-warning">
                                            (-{{ formatCurrency(payment[0].amount_refunded / 100) }})</span
                                        ><feather
                                            v-if="payment[0].is_group_payment"
                                            v-tooltip="getTooltip('Paiement de groupe')"
                                            type="users"
                                            class="ml-2" />
                                    </td>
                                    <td :class="getStatusColor(payment[0].status)">{{ payment[0].status }}</td>
                                    <td>
                                        {{ payment[0].firstname }}
                                        <span class="text-uppercase">{{ payment[0].lastname }}</span>
                                    </td>
                                    <td>{{ payment[0].paid_at ? formatDate(payment[0].paid_at) : "--" }}</td>
                                    <td>
                                        <template v-if="payment[0].is_online">
                                            <span class="d-block" v-for="(cpayment, index) in payment" :key="index">
                                                {{ getOnlinePaymentMethodLabel(cpayment.online_payment_method)
                                                }}{{
                                                    payment.length > 1
                                                        ? " (" + formatCurrency((cpayment.amount_paid || cpayment.amount_to_pay) / 100) + ")"
                                                        : ""
                                                }}
                                                <img
                                                    v-if="getOnlinePaymentMethodImg(cpayment.online_payment_method)"
                                                    v-tooltip="getTooltip('Paiement en ligne')"
                                                    style="width: 14px; margin-left: 2px; margin-bottom: 2px"
                                                    :src="`/images/${getOnlinePaymentMethodImg(cpayment.online_payment_method)}`" />
                                            </span>
                                        </template>
                                        <span v-else v-html="payment[0].method.replace(' - ', '<br/>')"></span>
                                    </td>
                                    <td v-if="!isTvaDisabled">
                                        {{ payment[0].tva.five_dot_five ? formatCurrency(payment[0].tva.five_dot_five) : "--" }}
                                    </td>
                                    <td v-if="!isTvaDisabled">
                                        {{ payment[0].tva.ten ? formatCurrency(payment[0].tva.ten) : "--" }}
                                    </td>
                                    <td v-if="!isTvaDisabled">
                                        {{ payment[0].tva.twenty ? formatCurrency(payment[0].tva.twenty) : "--" }}
                                    </td>
                                    <td>
                                        <span v-if="payment[0].online_payment_method === ONLINE_PAYMENT_METHOD_PAYPLUG.value">NC</span>
                                        <span v-else>
                                            {{ formatCurrency(getStripeFees(payment))
                                            }}<span v-if="areStripeFeesRefunded(payment) === false"> (Non remboursés)</span>
                                        </span>
                                    </td>
                                    <td>
                                        <button
                                            @click="showClickAndCollectCommandModal = payment[0].command_id"
                                            type="button"
                                            class="btn btn-sm btn-outline-secondary btn-square none-tablet"
                                            v-tooltip="getTooltip('Voir les détails')">
                                            <feather type="eye" />
                                        </button>
                                        <button
                                            class="btn btn-sm btn-outline-secondary btn-square"
                                            :disabled="
                                                !has_right_to_export_payments ||
                                                !payment[0].is_online ||
                                                !payment[0].cc_command ||
                                                !payment[0].cc_command.invoice
                                            "
                                            @click="downloadInvoice(payment[0])"
                                            v-tooltip="getTooltip('Télécharger la facture')">
                                            <feather type="download-cloud"></feather>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span v-if="payments && paymentsCountTotal > 0" class="badge badge-secondary ml-1 mr-2 float-left" style="margin-top: 21px">
                            {{ paymentsCountTotal }} paiement{{ paymentsCountTotal > 1 ? "s" : "" }}
                        </span>
                        <div v-if="!isSearch" class="mt-3">
                            <a
                                class="btn btn-sm btn-outline-secondary btn-circle"
                                v-if="links.prev"
                                href="javascript:"
                                @click="fetchData($event, links.prev)">
                                Précédent
                            </a>
                            <a
                                class="btn btn-sm btn-outline-secondary btn-circle"
                                v-if="links.next"
                                href="javascript:"
                                @click="fetchData($event, links.next)">
                                Suivant
                            </a>
                        </div>
                        <show-click-and-collect-payment-modal
                            v-if="showShowClickAndCollectPaymentModal"
                            @close="showShowClickAndCollectPaymentModal = false" />
                        <show-command-modal
                            :command_id="showClickAndCollectCommandModal"
                            :restaurant_id="$route.params.restaurant_id"
                            @close="showClickAndCollectCommandModal = false"
                            v-if="showClickAndCollectCommandModal" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../components/LoaderComponent.vue";
import showClickAndCollectPaymentModal from "../../components/Modals/clickAndCollect/showClickAndCollectPaymentModal.vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import PaymentStatusEnum from "../../mixins/enums/click_and_collect/PaymentStatusEnum";
import OnlinePaymentMethodEnum from "../../mixins/enums/click_and_collect/OnlinePaymentMethodEnum";
import ShowCommandModal from "../../components/Modals/clickAndCollect/ShowClickAndCollectCommand";

export default {
    data() {
        return {
            loading: false,
            error: null,
            restaurant_id: this.$route.params.restaurant_id,
            showAdvanceSearch: false,
            showShowClickAndCollectPaymentModal: false,
            payments: null,
            paymentsCountTotal: 0,
            links: {
                next: null,
                prev: null,
            },
            paginate: false,
            fr: fr,
            isSearch: false,
            filter: null,
            filter_status: null,
            filter_payment_type: null,
            filter_from_date_payment: null,
            filter_to_date_payment: null,
            showClickAndCollectCommandModal: false,
        };
    },
    mixins: [PaymentStatusEnum, OnlinePaymentMethodEnum],
    props: {
        user: {
            required: true,
        },
    },
    components: {
        LoaderComponent,
        showClickAndCollectPaymentModal,
        Datepicker,
        ShowCommandModal,
    },
    created() {
        this.fetchData();
    },
    computed: {
        formattedPayments() {
            let payments = [];

            Object.values(this.groupBy(this.payments, "command_id")).forEach((commandPayments) => {
                let successPayments = [];
                let otherPayments = [];
                commandPayments.forEach((payment) => {
                    if (this.inEnum(payment.raw_status, this.PAID_STATUS)) {
                        successPayments.push(payment);
                    } else otherPayments.push(payment);
                });
                if (successPayments.length > 0) payments.push(successPayments);
                if (otherPayments.length > 0) payments.push(otherPayments);
            });
            return payments.sort(function (a, b) {
                return b[0].numero - a[0].numero;
            });
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_export_payments() {
            return this.rights.includes("click_and_collect.payments.export");
        },
        isTvaDisabled() {
            return this.$store.getters["restaurants/isTvaDisabled"](this.restaurant_id);
        },
    },
    methods: {
        getAmountSum(payments) {
            let amount = 0;
            payments.forEach((payment) => {
                if (payment.amount_paid) amount += payment.amount_paid;
                else if (payment.amount_to_pay) amount += payment.amount_to_pay;
            });
            return amount;
        },
        getStripeFees(payments) {
            let amount = 0;
            payments.forEach((payment) => {
                if (payment.stripe_fees) amount += payment.stripe_fees;
            });
            return amount;
        },
        areStripeFeesRefunded(payments) {
            const stripePayment = payments.find(
                (p) => p.online_payment_method == this.ONLINE_PAYMENT_METHOD_STRIPE.value && p.raw_status == this.PAYMENT_REFUNDED.value
            );
            if (stripePayment) return stripePayment.stripe_fees_refunded ? true : false;
            return null;
        },
        downloadInvoice(payment) {
            window.open(`/api/click_and_collect/${this.$route.params.restaurant_id}/invoices/${payment.command_id}`, "_blank");
        },
        showCommand(e, command_id) {
            if (!e) return;
            const excludedTags = ["button", "a", "svg", "line", "path", "circle"];
            if (e && e.target && excludedTags.includes(e.target.tagName.toLowerCase())) {
                return;
            }
            this.showClickAndCollectCommandModal = command_id;
        },
        getStatusColor(status) {
            switch (status) {
                case "Payé":
                    return "text-success";
                case "Remboursé":
                case "Remboursé partiellement":
                case "En attente":
                    return "text-warning";
                default:
                    return "text-danger";
            }
        },
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY");
        },
        resetFilter() {
            this.filter = null;
            this.filter_status = null;
            this.filter_payment_type = null;
            this.filter_from_date_payment = null;
            this.filter_to_date_payment = null;
            this.showAdvanceSearch = false;
            this.fetchData();
        },
        exportPayments() {
            if (!confirm("L'exportation des paiements prendra en compte les filtres sélectionnés. Souhaitez vous continuer ?")) return;

            let api_url = `/api/click_and_collect/${this.$route.params.restaurant_id}/export/payments?`;
            if (this.filter !== null) api_url += "&filter=" + this.filter;
            if (this.filter_status !== null) api_url += "&filter_status=" + this.filter_status;
            if (this.filter_payment_type !== null) api_url += "&filter_payment_type=" + this.filter_payment_type;
            if (this.filter_from_date_payment !== null)
                api_url += "&filter_from_date_payment=" + moment(this.filter_from_date_payment).format("Y-MM-DD");
            if (this.filter_to_date_payment !== null) api_url += "&filter_to_date_payment=" + moment(this.filter_to_date_payment).format("Y-MM-DD");

            axios
                .get(`${api_url}&only_count=1`)
                .then((response) => {
                    if (response.data && response.data.count) {
                        if (response.data.count <= 100) window.open(api_url, "_blank");
                        else
                            axios.get(api_url).then((response) => {
                                this.$notify({
                                    group: "notification",
                                    type: "success",
                                    title: response.data.message,
                                });
                            });
                    } else window.open(api_url, "_blank");
                })
                .catch(() => window.open(api_url, "_blank"));
        },
        downloadPayments() {
            if (
                !confirm(
                    "L'exportation des paiements prendra en compte les filtres sélectionnés. Note : l'export ne doit pas comprendre plus de 100 factures. Souhaitez vous continuer ?"
                )
            )
                return;

            let api_url = `/api/click_and_collect/${this.$route.params.restaurant_id}/export/payments/bill?`;
            if (this.filter !== null) api_url += "&filter=" + this.filter;
            if (this.filter_status !== null) api_url += "&filter_status=" + this.filter_status;
            if (this.filter_payment_type !== null) api_url += "&filter_payment_type=" + this.filter_payment_type;
            if (this.filter_from_date_payment !== null)
                api_url += "&filter_from_date_payment=" + moment(this.filter_from_date_payment).format("Y-MM-DD");
            if (this.filter_to_date_payment !== null) api_url += "&filter_to_date_payment=" + moment(this.filter_to_date_payment).format("Y-MM-DD");

            axios
                .get(api_url)
                .then((response) => {
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    let errMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errMsg = error.response.data.message;
                    else if (error.message) errMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errMsg,
                    });
                });
        },
        fetchData(e, api_url) {
            if (e) e.preventDefault();
            this.loading = true;
            this.isSearch = false;
            this.payments = null;
            this.error = this.clients = this.links.next = this.links.prev = null;

            if (!api_url) api_url = `/api/click_and_collect/${this.$route.params.restaurant_id}/payments?`;
            api_url += "&include=cc_command";
            if (this.filter !== null) api_url += "&filter=" + this.filter;
            if (this.filter_status !== null) api_url += "&filter_status=" + this.filter_status;
            if (this.filter_payment_type !== null) api_url += "&filter_payment_type=" + this.filter_payment_type;
            if (this.filter_from_date_payment !== null)
                api_url += "&filter_from_date_payment=" + moment(this.filter_from_date_payment).format("Y-MM-DD");
            if (this.filter_to_date_payment !== null) api_url += "&filter_to_date_payment=" + moment(this.filter_to_date_payment).format("Y-MM-DD");
            axios
                .get(api_url)
                .then((response) => {
                    this.loading = false;
                    this.payments = response.data.data;
                    this.links.next = null;
                    this.links.prev = null;
                    if (response.data.meta.pagination.links) {
                        this.paginate = true;
                        if (response.data.meta.pagination.links.next) {
                            this.links.next = response.data.meta.pagination.links.next;
                        }
                        if (response.data.meta.pagination.links.previous) {
                            this.links.prev = response.data.meta.pagination.links.previous;
                        }
                    }
                    this.paymentsCountTotal = response.data.meta.pagination.total;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = error.response.data.message ? error.response.data.message : "Une erreur est survenue";
                });
        },
    },
};
</script>
