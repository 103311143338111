<template>
    <div>
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-3 mb-2">
                    <div>
                        <h5 class="title mt-2">Statistiques</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-12">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <ul class="settings-menu nav nav-tabs flex-row top mb-2" style="position: relative; top: 0">
                        <li class="nav-item" v-for="service in services" :key="service.id" @click="currentService = service.id">
                            <a class="nav-link" :class="{ 'router-link-active': service.id === currentService }" style="color: #6c757d">
                                {{ service.label }}
                            </a>
                        </li>
                    </ul>
                    <keep-alive>
                        <component :is="currentComponent" />
                    </keep-alive>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BookingStats from "../Booking/RestaurantsStats.vue";
import ClickAndCollectStats from "../ClickAndCollect/ClickAndCollectReport.vue";
import GiftVouchersStats from "../GiftVouchers/GiftVouchersReport.vue";

export default {
    data() {
        return {
            currentService: 0,
            services: [
                {
                    id: 0,
                    label: "Réservations",
                },
                {
                    id: 1,
                    label: "Vente en ligne",
                },
                {
                    id: 2,
                    label: "Bons cadeaux",
                },
            ],
        };
    },
    computed: {
        currentComponent() {
            switch (this.currentService) {
                case 0:
                    return "BookingStats";
                case 1:
                    return "ClickAndCollectStats";
                case 2:
                    return "GiftVouchersStats";
                default:
                    return undefined;
            }
        },
    },
    methods: {},
    components: {
        BookingStats,
        ClickAndCollectStats,
        GiftVouchersStats,
    },
    created() {},
};
</script>
