<template>
    <div class="pb-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">{{ $tl("labels.routes.booking.payments") }}</h5>
                </div>
            </div>
        </div>
        <div class="row m-0 pb-5">
            <div class="col-12">
                <LoaderComponent v-if="loading" />
                <div class="alert alert-danger" v-else-if="error">{{ error }}</div>
                <div v-else>
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-3">
                        <div>
                            <div style="margin-top: 4px" class="d-inline-block">
                                <form @submit="getReservationPayments">
                                    <div style="border-radius: 20px 0 0 20px !important" class="form-control search-client d-inline pr-1">
                                        <input
                                            v-model="queries.search"
                                            class="search-input"
                                            :placeholder="$t('labels.booking.payments.filterPlaceHolder')"
                                            type="text" />
                                        <button
                                            type="button"
                                            @click="resetSearch"
                                            class="p-0 m-0 clear-search-button"
                                            style="background: none; border: none">
                                            <feather type="x" />
                                        </button>
                                    </div>
                                    <button
                                        type="submit"
                                        class="btn btn-sm btn-outline-secondary search-client-btn"
                                        style="border-radius: 0 20px 20px 0; margin-top: -2px; height: 29.5px !important; padding-top: 5px">
                                        <feather type="search" />
                                    </button>
                                </form>
                            </div>
                            <button @click="showAdvanceSearch = !showAdvanceSearch" class="btn btn-sm btn-outline-secondary ml-1 btn-circle">
                                <feather type="filter" />
                                {{ $tl("labels.filters.title") }}
                            </button>
                        </div>
                    </div>
                    <div v-if="showAdvanceSearch" class="advance-filter">
                        <div class="m-0" style="color: #666666">
                            <div class="border-light b-radius-20 p-4 mb-3">
                                <div class="row">
                                    <div class="col-12 d-inline-block date-resa-cal">
                                        {{ $tl("labels.filters.dates.from") }}&nbsp;
                                        <DatePicker
                                            class="d-inline-block"
                                            input-class="form-control"
                                            :format="DateTime.DATE_SHORT"
                                            :canBeEmpty="true"
                                            v-model="queries.filters.from" />
                                        &nbsp;{{ $tl("labels.filters.dates.to").toLowerCase() }}&nbsp;
                                        <DatePicker
                                            class="d-inline-block"
                                            input-class="form-control"
                                            :format="DateTime.DATE_SHORT"
                                            :canBeEmpty="true"
                                            v-model="queries.filters.to" />
                                        <button @click="getReservationPayments()" class="btn btn-sm btn-success btn-circle ml-2">
                                            {{ $tl("labels.form.actions.search") }}
                                        </button>
                                        <button @click="resetFilter" class="btn btn-sm btn-secondary btn-circle ml-2">
                                            {{ $tl("labels.form.actions.reset") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PaymentsGrid
                        :payments="payments"
                        :pagination="pagination"
                        :queries="queries"
                        @update-data="updateDataFromTableEvent"
                        @showResa="showResaFct">
                        <template v-slot:toolbar>
                            <button
                                :disabled="!has_right_to_export_payments || loadingExportInvoices"
                                @click="downloadPayments"
                                class="btn btn-sm btn-outline-secondary none-mobile"
                                style="border-radius: 20px 0px 0px 20px">
                                <feather type="download" />
                                {{ $tl("labels.invoices.download") }}
                            </button>
                        </template>
                    </PaymentsGrid>
                    <ShowReservationModal
                        v-if="showResa !== false"
                        :reservation_id="showResa"
                        :restaurant_id="restaurant_id"
                        :showEditButton="false"
                        @close="showResa = false" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent";
import PaymentsGrid from "../../components/reservations/payments/PaymentsGrid";
import DatePicker from "../../components/forms/DatePicker.vue";
import { DateTime } from "luxon";
import PaymentStatusEnum from "../../mixins/enums/booking/PaymentStatusEnum";
import ShowReservationModal from "../../components/Modals/reservations/ShowReservationModal";

export default {
    data() {
        return {
            loading: false,
            DateTime,
            error: null,
            payments: [],
            showAdvanceSearch: false,
            showResa: false,
            loadingExportInvoices: false,
            pagination: {
                count: 0,
                current_page: 1,
                per_page: 12,
                total: 0,
                total_pages: 1,
            },
            queries: {
                page: 1,
                per_page: 12,
                sort: {
                    field: null,
                    order: null,
                },
                filters: {
                    from: null,
                    to: null,
                },
                dynamic_filters: {},
                search: "",
            },
        };
    },
    mixins: [PaymentStatusEnum],
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_export_payments() {
            return this.rights.includes("booking.payments.export");
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        showResaFct(id) {
            this.showResa = id;
        },
        downloadPayments() {
            if (!confirm(this.$tl("questions.booking.payments.export"))) {
                return;
            }

            const queries = this.parseQueries();
            this.loadingExportInvoices = true;

            this.httpGet(`/api/restaurants/${this.restaurant_id}/export/payments/bill?${queries}`, {
                handleResolve: true,
            }).finally(() => (this.loadingExportInvoices = false));
        },
        updateDataFromTableEvent(event) {
            this.queries.sort.field = null;
            this.queries.sort.order = null;
            this.queries.dynamic_filters = {};

            this.queries.page = event.page;
            this.queries.per_page = event.per_page;
            if (event.field !== null && event.order !== null) {
                this.queries.sort.field = event.field;
                this.queries.sort.order = event.order;
            }

            event.dynamic_filters.forEach((filter) => {
                //WARNING: For now only choices are supported
                if (filter.filterOptions && filter.filterOptions.choices) {
                    this.queries.dynamic_filters[filter.field] = filter.filterOptions.choices;
                }
            });

            this.getReservationPayments();
        },
        getReservationPayments() {
            this.loading = true;
            this.error = null;

            const includes = "&include=reservation.client";
            const queries = this.parseQueries();

            this.httpGet(`/api/restaurants/${this.restaurant_id}/payments?${includes}${queries}`, {
                mustReject: true,
            })
                .then((response) => {
                    this.payments = response.data.data;
                    this.setPagination(response.data.meta.pagination);
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        setPagination(pagination) {
            this.pagination = pagination;
            if (this.pagination.per_page === this.pagination.total) {
                this.pagination.per_page = "all";
            }
        },
        parseQueries() {
            const filters = this.parseQueryFilters();
            const dynamicFilters = this.parseDynamicFilters();
            const search = this.parseSearch();
            const sort = this.parseQuerySort();
            const page = this.parseQueryPage();

            return filters + dynamicFilters + search + sort + page;
        },
        parseQueryFilters() {
            let queryFilters = "";

            Object.keys(this.queries.filters).forEach((key) => {
                if (this.queries.filters[key] !== null) {
                    const value = this.queries.filters[key] instanceof DateTime ? this.queries.filters[key].toISODate() : this.queries.filters[key];
                    queryFilters = queryFilters.concat("&", `${key}=${value}`);
                }
            });
            return queryFilters;
        },
        parseDynamicFilters() {
            let queryDynamicFilters = "";

            Object.keys(this.queries.dynamic_filters).forEach((key) => {
                Object.values(this.queries.dynamic_filters[key]).forEach((filter) => {
                    queryDynamicFilters = queryDynamicFilters.concat("&", `${key}[]=${filter}`);
                });
            });
            return queryDynamicFilters;
        },
        parseSearch() {
            if (this.queries.search !== "" && this.queries.search !== null) {
                return "&".concat("search=", this.queries.search);
            }
            return "";
        },
        parseQuerySort() {
            if (this.queries.sort.field !== null && this.queries.sort.order !== null) {
                return "&".concat("sort=", this.queries.sort.field) + "&".concat("order=", this.queries.sort.order);
            }
            return "";
        },
        parseQueryPage() {
            return "&".concat("page=", this.queries.page) + "&".concat("per_page=", this.queries.per_page);
        },
        resetSearch() {
            this.queries.search = "";
            this.getReservationPayments();
        },
        resetFilter() {
            Object.keys(this.queries.filters).forEach((key) => {
                this.queries.filters[key] = null;
            });
            this.getReservationPayments();
        },
    },
    components: {
        LoaderComponent,
        PaymentsGrid,
        DatePicker,
        ShowReservationModal,
    },
    created() {
        this.getReservationPayments();
    },
};
</script>
