var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pr-0 pb-5" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.loading
            ? _c("LoaderComponent")
            : _c("div", [
                _vm.error
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.error) +
                          "\n                "
                      ),
                    ])
                  : _c("div", [
                      _c("div", { staticClass: "row m-0" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                            },
                            [
                              _c("h5", { staticClass: "title mt-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.routes.booking.services.closures"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-sm btn-success btn-circle",
                                  class: {
                                    disabled: !_vm.has_right_to_create_service,
                                  },
                                  attrs: {
                                    to: {
                                      name: "booking.restaurants.settings.closures.add",
                                      params: {
                                        restaurant_id: _vm.restaurant_id,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("feather", { attrs: { type: "plus" } }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl("labels.booking.closures.add")
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.closures * 1 === 0
                        ? _c("div", [
                            _c("div", { staticClass: "row m-0" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c("em", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.closures.empty",
                                        _vm.restaurant_id
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                          ])
                        : _c("div", [
                            _c("div", { staticClass: "row m-0" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _vm.closures
                                  ? _c(
                                      "table",
                                      {
                                        staticClass:
                                          "table table-sm table-striped border-bottom",
                                      },
                                      [
                                        _c(
                                          "thead",
                                          { staticClass: "border-bottom" },
                                          [
                                            _c("tr", [
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tl("labels.form.name")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.booking.closures.services",
                                                      _vm.restaurant_id
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.booking.closures.dates"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.form.actions.title"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.closures,
                                            function (closure) {
                                              return _c(
                                                "tr",
                                                { key: closure.id },
                                                [
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(closure.name)
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    _vm._l(
                                                      closure.services,
                                                      function (service) {
                                                        return _c(
                                                          "div",
                                                          { key: service.id },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  service.name
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _vm.areDatesSame(
                                                        closure.datetime_begin,
                                                        closure.datetime_end
                                                      )
                                                        ? [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.displayDate(
                                                                    closure.datetime_begin
                                                                  )
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ]
                                                        : [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.$tl(
                                                                    "labels.filters.dates.from"
                                                                  )
                                                                ) +
                                                                "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.displayDate(
                                                                    closure.datetime_begin
                                                                  )
                                                                ) +
                                                                "\n                                                    " +
                                                                _vm._s(
                                                                  _vm
                                                                    .$tl(
                                                                      "labels.filters.dates.to"
                                                                    )
                                                                    .toLowerCase()
                                                                ) +
                                                                "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.displayDate(
                                                                    closure.datetime_end
                                                                  )
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ],
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "tooltip",
                                                              rawName:
                                                                "v-tooltip",
                                                              value:
                                                                _vm.getTooltip(
                                                                  _vm.$t(
                                                                    "labels.form.actions.edit"
                                                                  )
                                                                ),
                                                              expression:
                                                                "getTooltip($t('labels.form.actions.edit'))",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "btn btn-sm btn-success btn-square",
                                                          class: {
                                                            disabled:
                                                              !_vm.has_right_to_update_service,
                                                          },
                                                          attrs: {
                                                            to: {
                                                              name: "booking.restaurants.settings.closures.edit",
                                                              params: {
                                                                restaurant_id:
                                                                  _vm.restaurant_id,
                                                                closure_id:
                                                                  closure.id,
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("feather", {
                                                            attrs: {
                                                              type: "edit",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "tooltip",
                                                              rawName:
                                                                "v-tooltip",
                                                              value:
                                                                _vm.getTooltip(
                                                                  _vm.$t(
                                                                    "labels.form.actions.delete"
                                                                  )
                                                                ),
                                                              expression:
                                                                "getTooltip($t('labels.form.actions.delete'))",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "btn btn-sm btn-danger btn-square",
                                                          attrs: {
                                                            "data-id":
                                                              closure.id,
                                                            disabled:
                                                              !_vm.has_right_to_delete_service,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteClosure(
                                                                closure.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("feather", {
                                                            attrs: {
                                                              type: "trash-2",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ]),
                    ]),
              ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }