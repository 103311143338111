var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "text-muted" }, [
        _vm._v(_vm._s(_vm.$tl("labels.clients.merge.keep"))),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-6" }, [
      _c(
        "form",
        {
          staticClass: "border-light b-radius-20 p-4 clickable",
          class: {
            selected_client_for_merging: _vm.selectedClientNum === 1,
            not_selected_client_for_merging: _vm.selectedClientNum === 2,
          },
          on: {
            click: function ($event) {
              return _vm.selectClient(1)
            },
          },
        },
        [
          _c("clientsReservations", {
            attrs: {
              reservation: _vm.reservation1,
              readOnly: true,
              hideEditButton: true,
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-6" }, [
      _c(
        "form",
        {
          staticClass: "border-light b-radius-20 p-4 clickable",
          class: {
            selected_client_for_merging: _vm.selectedClientNum === 2,
            not_selected_client_for_merging: _vm.selectedClientNum === 1,
          },
          on: {
            click: function ($event) {
              return _vm.selectClient(2)
            },
          },
        },
        [
          _c("clientsReservations", {
            attrs: {
              reservation: _vm.reservation2,
              readOnly: true,
              hideEditButton: true,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }