<template>
    <div>
        <small v-if="mailLog.permanent_failed_at || !mailLog.sent_at" class="text-danger">
            {{
                $t("labels.booking.reservations.mailLogs.permanent_failed", {
                    type: getReservationMailLogLabel(mailLog.type, restaurant_id),
                })
            }}
        </small>
        <small v-else-if="mailLog.sent_at && !mailLog.delivered_at" class="text-warning">
            {{ $t("labels.booking.reservations.mailLogs.pending", { type: getReservationMailLogLabel(mailLog.type, restaurant_id) }) }}
        </small>
        <small v-else-if="mailLog.delivered_at">
            {{
                $t("labels.booking.reservations.mailLogs.sent", {
                    type: getReservationMailLogLabel(mailLog.type, restaurant_id),
                    date: displayDate(mailLog.delivered_at, DATE_SHORT),
                    hour: displayDate(mailLog.delivered_at, TIME_SIMPLE),
                })
            }}<span v-if="mailLog.opened_at"> ({{ $tl("labels.booking.reservations.mailLogs.opened") }})</span>
        </small>
    </div>
</template>

<script>
import ReservationMailLogEnum from "../../../mixins/enums/booking/ReservationMailLogEnum";

export default {
    data() {
        return {};
    },
    mixins: [ReservationMailLogEnum],
    props: {
        mailLog: {
            required: true,
            type: Object,
        },
    },
    computed: {
        restaurant_id() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
    },
};
</script>
