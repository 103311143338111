import { METHOD_PUT } from "../../utils/http.js";

const CLUB_MED_OWNER_ID_LOCAL = 9;
const CLUB_MED_OWNER_ID_DEV = 185;
const CLUB_MED_OWNER_ID_PROD = 194;

import axios from "axios";

export default {
    data() {
        return {
            YOUTUBE_EMBED_BASE_URL: "https://www.youtube.com/embed",
        };
    },
    methods: {
        isValidFile(file) {
            return file && file instanceof File;
        },
        formatPrice(price, translateMethod = undefined) {
            if ("undefined" === typeof translateMethod) {
                var thousandSeparator = this.$tl("labels.thousandSeparator");
                var decimalSeparator = this.$tl("labels.decimalSeparator");
            } else {
                var thousandSeparator = translateMethod("labels.thousandSeparator");
                var decimalSeparator = translateMethod("labels.decimalSeparator");
            }
            const formattedPrice = Number.parseFloat(price).toFixed(2).toString();
            const splitPrice = formattedPrice.split(".");
            const firstPart = splitPrice[0]
                .split("")
                .reverse()
                .join("")
                .replace(/(\d{3})/g, "$1" + thousandSeparator)
                .split("")
                .reverse()
                .join("");
            return (firstPart + decimalSeparator + splitPrice[1]).trim();
        },
        formatCurrency(amount, currency = "EUR", locale = this.userLang) {
            return new Intl.NumberFormat(locale, { style: "currency", currency }).format(amount);
        },
        getRestaurantCurrency(restaurantId) {
            return this.$store.getters["restaurants/currency"](restaurantId) || "EUR";
        },
        capitalize(s) {
            return s ? s.charAt(0).toUpperCase() + s.slice(1) : "";
        },
        inEnum(value, enums) {
            return enums.some((e) => e.value === value);
        },
        arrayPluck(key, arrayOfObjects) {
            if (!Array.isArray(arrayOfObjects)) {
                return arrayOfObjects;
            }

            return arrayOfObjects.map((obj) => obj[key]);
        },
        notifyError(error, defaultMessage = undefined) {
            this.$notify({
                group: "notification",
                type: "error",
                title: this.getErrorMsgFromErrorResponse(error, defaultMessage),
            });
        },
        notifySuccess(response, defaultMessage = undefined) {
            let title = "OK";

            if (response && response.data && response.data.message) {
                title = response.data.message;
            } else if (defaultMessage !== undefined) {
                title = defaultMessage;
            }

            this.$notify({
                group: "notification",
                type: "success",
                title,
            });
        },
        getErrorMsgFromErrorResponse(error, defaultMessage = undefined) {
            if (error && error.response && error.response.data && error.response.data.message) {
                return error.response.data.message;
            }

            if (error && error.message) {
                return error.message;
            }

            if (typeof error === "string" && error !== "") {
                return error;
            }

            if (typeof defaultMessage !== "undefined") {
                return defaultMessage;
            }

            return this.$tl("errors.common.unknown");
        },
        getTooltip(content, data = {}) {
            return {
                delay: {
                    show: 400,
                    hide: 0,
                },
                content,
                ...data,
            };
        },
        getTooltipNotice(label, triggers = ["click"], html = true, popperClass = "") {
            return {
                content: this.$t(label),
                html,
                triggers,
                popperClass,
            };
        },
        getTooltipNoticeLexicon(label, restaurantId = undefined, triggers = ["click"], html = true, popperClass = "") {
            return {
                content: this.$tl(label, restaurantId),
                html,
                triggers,
                popperClass,
            };
        },
        escapeHtml(text) {
            if (text === null || text === undefined) {
                return text;
            }
            const map = {
                "&": "&amp;",
                "<": "&lt;",
                ">": "&gt;",
                '"': "&quot;",
                "'": "&#039;",
            };
            return text.replace(/[&<>"']/g, (m) => map[m]);
        },
        generateRandomString() {
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        },
        groupBy(xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },
        fileNameOnly(fileName) {
            if (fileName == null) return "";
            return fileName.replace(/.*\//g, "").replace(/\?.*/, "");
        },
        isClubMedOwnerId(user_id) {
            if (!this.isNoShow) {
                return false;
            }

            if (this.isLocal()) {
                return user_id === CLUB_MED_OWNER_ID_LOCAL;
            }

            if (this.isDevelopement(true)) {
                return user_id === CLUB_MED_OWNER_ID_DEV;
            }

            return user_id === CLUB_MED_OWNER_ID_PROD;
        },
        customTrim(str, charsList) {
            if (typeof str !== "string" || typeof charsList !== "string" || charsList === "") {
                return str;
            }
            return str.replace(new RegExp(`^[${charsList}]|[${charsList}]$`, "g"), "");
        },
        httpGet(url, { config = {}, handleResolve = false, handleReject = true, mustReject = false } = {}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(url, config)
                    .then((response) => {
                        if (handleResolve) {
                            this.notifySuccess(response);
                        }

                        resolve(response);
                    })
                    .catch((error) => {
                        if (handleReject) {
                            this.notifyError(error);
                        }

                        if (mustReject) {
                            reject(error);
                        } else {
                            resolve(false);
                        }
                    });
            });
        },
        httpPost(
            url,
            data = {},
            { config = {}, handleResolve = true, handleReject = true, defaultMessage = undefined, defaultErrorMessage = undefined } = {}
        ) {
            return new Promise((resolve, reject) => {
                axios
                    .post(url, data, config)
                    .then((response) => {
                        if (handleResolve) {
                            this.notifySuccess(response, defaultMessage);
                        }

                        resolve(response);
                    })
                    .catch((error) => {
                        if (handleReject) {
                            this.notifyError(error, defaultErrorMessage);

                            if (
                                error.response &&
                                error.response.data &&
                                error.response.data.errors &&
                                this.errors !== null &&
                                typeof this.errors === "object"
                            ) {
                                this.errors.form = error.response.data.errors;
                            }

                            resolve(false);
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        httpPut(
            url,
            data = {},
            { config = {}, handleResolve = true, handleReject = true, defaultMessage = undefined, defaultErrorMessage = undefined } = {}
        ) {
            return new Promise((resolve, reject) => {
                let promise = null;

                if (data instanceof FormData) {
                    if (!data.has("_method")) {
                        data.append("_method", METHOD_PUT);
                    }

                    promise = axios.post(url, data, config);
                } else {
                    promise = axios.put(url, data, config);
                }

                promise
                    .then((response) => {
                        if (handleResolve) {
                            this.notifySuccess(response, defaultMessage);
                        }

                        resolve(response);
                    })
                    .catch((error) => {
                        if (handleReject) {
                            this.notifyError(error, defaultErrorMessage);

                            if (
                                error.response &&
                                error.response.data &&
                                error.response.data.errors &&
                                this.errors !== null &&
                                typeof this.errors === "object"
                            ) {
                                this.errors.form = error.response.data.errors;
                            }

                            resolve(false);
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        httpDelete(url, { config = {}, handleResolve = true, handleReject = true, mustReject = false, defaultMessage = undefined } = {}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(url, config)
                    .then((response) => {
                        if (handleResolve) {
                            this.notifySuccess(response, defaultMessage);
                        }

                        resolve(response);
                    })
                    .catch((error) => {
                        if (handleReject) {
                            this.notifyError(error);
                        }

                        if (mustReject) {
                            reject(error);
                        } else {
                            resolve(false);
                        }
                    });
            });
        },
        checkForExistingNewBadge(currentRoute) {
            return this.$store.getters["users/newBadges"].some((badge) =>
                badge.route_name.split("|").some((route) => route.startsWith(currentRoute))
            );
        },
        isReserveWithGoogleEnabled() {
            return this.isNoShow;
        },
        hexToRgb(hex) {
            const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result
                ? {
                      r: parseInt(result[1], 16),
                      g: parseInt(result[2], 16),
                      b: parseInt(result[3], 16),
                  }
                : null;
        },
    },
    computed: {
        isBrowserIE() {
            /* MSIE used to detect old browsers and Trident used to newer ones*/
            return navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
        },
        isYservices() {
            return THEME === "yservices";
        },
        isNoShow() {
            return THEME === "noshow";
        },
        themeUppercased() {
            return THEME.toUpperCase();
        },
    },
};
