<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Configuration DNS —— Étape {{ step }}</h5>
        <div slot="body">
            <LoaderComponent v-if="loading" />
            <div class="container-fluid" v-else>
                <div class="row">
                    <div class="col-12 mb-3">
                        <div class="border-light b-radius-20 p-4">
                            <div v-show="step === 0">
                                <p>
                                    Avant de commencer la configuration de votre domaine, assurez-vous d'avoir la possibilité de modifier les
                                    enregistrements DNS.
                                </p>
                            </div>
                            <div class="row" v-show="step === 1">
                                <div class="col-12">
                                    <p>Veuillez choisir une méthode de validation du nom de domaine :</p>
                                </div>
                                <div class="col-12 rd-verification-method-container radio">
                                    <label class="container-box">
                                        <input type="radio" name="rd-verification-method" v-model="verification_method" value="DNS_TXT" />
                                        <span class="checkmark"></span>Enregistrement TXT (recommandé)
                                    </label>
                                    <label class="container-box">
                                        <input type="radio" name="rd-verification-method" v-model="verification_method" value="DNS_CNAME" />
                                        <span class="checkmark"></span>Enregistrement CNAME (peut prendre plusieurs heures)
                                    </label>
                                </div>
                            </div>
                            <div class="row" v-show="step === 2 && token != null">
                                <div class="col-12">
                                    <div class="alert alert-danger" v-if="error">{{ error }}</div>
                                </div>
                                <div class="col-12">
                                    <p>
                                        Accédez à la section permettant de modifier les enregistrements DNS de votre domaine.<br />
                                        Ajoutez un nouvel enregistrement :
                                    </p>
                                </div>
                                <div class="col-3">Sous-domaine / Host :</div>
                                <div class="col-9 mb-2">{{ website_settings.prefix_identifier }}</div>
                                <div class="col-3">Type :</div>
                                <div class="col-9">{{ verification_method.substr(4) }}</div>
                                <div class="col-3 d-flex align-items-center"><span>Réponse / Answer :</span></div>
                                <div class="col-9 align-items-center">
                                    <CopyToClipboard :textToCopy="token" :element="$tl('labels.tokenIdentifier')" />
                                </div>
                            </div>
                            <div class="row" v-show="step === 3">
                                <div class="alert alert-danger" v-if="error">{{ error }}</div>
                            </div>
                            <div class="row" v-show="step === 4">
                                <p>
                                    Votre site a bien été vérifié. Retournez sur la page de configuration des enregistrements DNS de votre domaine.
                                    Supprimez l'enregistrement créé dans l'étape précédente et ajoutez l'enregistrement suivant :
                                </p>
                                <div class="col-3">Sous-domaine / Host :</div>
                                <div class="col-9 mb-2">{{ website_settings.prefix_identifier }}</div>
                                <div class="col-3">Type :</div>
                                <div class="col-9">CNAME</div>
                                <div class="col-3 d-flex align-items-center"><span>Réponse / Answer :</span></div>
                                <div class="col-9 d-flex align-items-center">
                                    <span id="display-copy-storage-cloud">c.storage.googleapis.com</span>
                                    <CopyToClipboard
                                        textToCopy="c.storage.googleapis.com"
                                        :element="$tl('labels.linkIdentifier')"
                                        :showInput="false" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="$emit('close')">Annuler</button>
            <button class="btn-sm btn btn-secondary btn-circle" v-if="step !== 0 && step !== 2 && step !== 4" @click="step--">Retour</button>
            <button
                class="btn btn-success btn-sm btn-circle"
                :disabled="loading"
                @click="step === 3 ? verifyDomain() : step === 4 ? $emit('verified') : step++">
                {{ step === 3 ? "Vérifier mon domaine" : step === 4 ? "Fermer" : "Continuer" }}
            </button>
        </div>
    </modal>
</template>

<script>
import LoaderComponent from "../LoaderComponent";
import CopyToClipboard from "../Buttons/CopyToClipboard.vue";
import axios from "axios";
import $ from "jquery";

export default {
    components: {
        LoaderComponent,
        CopyToClipboard,
    },
    data() {
        return {
            error: null,
            loading: false,
            step: this.website_settings.deploy_step === "get_token" ? 0 : this.website_settings.deploy_step === "verify" ? 3 : 4,
            verification_method: "DNS_TXT",
            token: null,
        };
    },
    props: {
        website_settings: {
            required: true,
            type: Object,
        },
    },
    created() {
        if (this.step === 3) this.verifyDomain();
    },
    methods: {
        getVerificationToken() {
            this.loading = true;
            this.error = null;
            this.token = null;
            axios
                .get(`/api/website/${this.$route.params.restaurant_id}/website/getVerificationToken/${this.verification_method}`)
                .then((response) => {
                    this.loading = false;
                    this.token = response.data.message;
                    this.website_settings.deploy_step = "verify";
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: error.response.data.message ? error.response.data.message : "Une erreur est survenue",
                    });
                });
        },
        verifyDomain() {
            this.loading = true;
            this.error = null;
            axios
                .post(`/api/website/${this.$route.params.restaurant_id}/website/verifyDomain`)
                .then((response) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    this.website_settings.deploy_step = "publish";
                    this.step++;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = error.response.data.message ? error.response.data.message : "Une erreur est survenue";
                });
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    watch: {
        step(newVal, oldVal) {
            if (newVal === 2 && oldVal < 2) this.getVerificationToken();
            else if (newVal === 2) this.step = 1;
            if (newVal === 3) this.verifyDomain();
        },
    },
};
</script>

<style scoped>
.rd-verification-method-container .container-box {
    display: block;
    width: auto;
}
button:disabled {
    cursor: default;
}
.alert {
    position: relative;
    top: 0;
    text-align: justify;
}
</style>
