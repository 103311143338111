var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row p-1" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.$tl("labels.booking.reservations.add.date")) +
            "\n        "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 mt-2" },
        [
          _vm.loading
            ? _c("LoaderComponent")
            : _vm.error
            ? _c("span", { staticClass: "text-danger" }, [
                _vm._v(_vm._s(_vm.error)),
              ])
            : _c("DatePicker", {
                staticClass: "date-resa-cal p-1",
                class: { "is-invalid": _vm.hasErrors("reservation_date") },
                attrs: {
                  disabledDates: _vm.disabledDates,
                  tabindex: "1",
                  highlighted: _vm.highlighted,
                  format: "ccc DDD",
                  calendarClass: "w-auto",
                  inline: true,
                },
                on: { selected: _vm.dateSelected },
                model: {
                  value: _vm.reservation.reservation_date,
                  callback: function ($$v) {
                    _vm.$set(_vm.reservation, "reservation_date", $$v)
                  },
                  expression: "reservation.reservation_date",
                },
              }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }