var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("ChooseLang", { staticClass: "float-right mr-3 mt-4" }),
          _vm._v(" "),
          _c("horizontal-stepper", {
            ref: "stepper",
            attrs: { steps: _vm.stepList, currentStep: { index: _vm.step } },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(_vm.currentComponent, {
        tag: "component",
        attrs: {
          restaurant_id: _vm.restaurant.id,
          restaurant: _vm.restaurant,
          showBackButton: true,
        },
        on: {
          done: function ($event) {
            _vm.step += 1
          },
          back: function ($event) {
            _vm.step -= 1
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }