<template>
    <div class="row">
        <div class="col-12">
            <div class="border-light b-radius-20 p-4">
                <div class="row">
                    <div class="col-12">
                        <strong>{{ $tl("labels.booking.services.menusAndOptions.title", restaurantId) }}</strong>
                    </div>
                </div>
                <div class="menu-option mt-3">
                    <div class="row mb-3">
                        <div class="col-12">
                            <label>{{ $tl("labels.booking.services.menusAndOptions.link", restaurantId) }}</label>
                        </div>
                        <div class="offset-1 col-11 mt-2">
                            <div v-if="menus.length > 0">
                                <div class="mb-2" v-for="menu in menus" :key="menu.id">
                                    <label class="container-box" style="margin-bottom: 0; width: 100%">
                                        <input
                                            type="checkbox"
                                            name="menu"
                                            @change="changedMenu($event, menu.id)"
                                            :checked="currentMenus.checkedMenus[menu.id]" />
                                        <span class="checkmark"></span> {{ menu.name }}
                                    </label>
                                    <div class="mt-1" v-if="currentMenus.checkedMenus[menu.id]">
                                        <div class="row mb-0">
                                            <div class="col-5 offset-1">
                                                <label class="container-box" style="width: 30px">
                                                    <input
                                                        type="checkbox"
                                                        :value="true"
                                                        v-model="currentMenus.checkedMenus[menu.id].enable_nb_min_pers" />
                                                    <span style="margin-top: -2px" class="checkmark"></span>
                                                </label>
                                                <label class="pt-2">{{
                                                    $tl("labels.booking.services.menusAndOptions.enableFrom", restaurantId)
                                                }}</label>
                                            </div>
                                            <div class="col-6 d-flex align-items-center">
                                                <input
                                                    style="width: 30%"
                                                    type="number"
                                                    step="1"
                                                    min="0"
                                                    name="nb_pers_min"
                                                    id="nb_pers_min"
                                                    class="form-control"
                                                    v-model="currentMenus.checkedMenus[menu.id].nb_min_pers"
                                                    :disabled="!currentMenus.checkedMenus[menu.id].enable_nb_min_pers" />
                                                <p class="m-0 p-0 ml-2">{{ capitalize($tl("labels.persons")) }}</p>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-5 offset-1">
                                                <label class="container-box" style="width: 30px">
                                                    <input
                                                        type="checkbox"
                                                        :value="true"
                                                        v-model="currentMenus.checkedMenus[menu.id].enable_nb_max_pers" />
                                                    <span style="margin-top: -2px" class="checkmark"></span>
                                                </label>
                                                <label class="pt-2">{{
                                                    $tl("labels.booking.services.menusAndOptions.disableFrom", restaurantId)
                                                }}</label>
                                            </div>
                                            <div class="col-6 d-flex align-items-center">
                                                <input
                                                    style="width: 30%"
                                                    type="number"
                                                    step="1"
                                                    min="0"
                                                    name="nb_pers_max"
                                                    id="nb_pers_max"
                                                    class="form-control"
                                                    v-model="currentMenus.checkedMenus[menu.id].nb_max_pers"
                                                    :disabled="!currentMenus.checkedMenus[menu.id].enable_nb_max_pers" />
                                                <p class="m-0 p-0 ml-2">{{ capitalize($tl("labels.persons")) }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-4 mb-4">
                                <button type="button" @click="showAddMenuModal = true" class="btn btn-success btn-circle btn-sm">
                                    {{ $tl("labels.booking.menus.add", restaurantId) }}
                                </button>
                            </div>
                        </div>
                    </div>

                    <template v-if="!$_.isEmpty(currentMenus.checkedMenus)">
                        <div class="row mb-0">
                            <div class="col-5">
                                <label>
                                    {{ $tl("labels.booking.menus.mandatory", restaurantId) }}
                                    <feather
                                        v-tooltip="getTooltipNotice('notices.booking.servicesConfig.mandatoryMenu')"
                                        type="info"
                                        class="ml-1 pointer tooltip-infos" />
                                </label>
                            </div>
                            <div class="col-7">
                                <label class="container-box" style="width: 100%">
                                    <input type="checkbox" name="menu_unique" v-model="currentMenus.isMandatory" />
                                    <span class="checkmark"></span> {{ $tl("labels.yes") }}
                                </label>
                            </div>
                        </div>
                        <div v-if="currentMenus.isMandatory" class="row mb-4">
                            <div class="col-5 pt-2">
                                <label>{{ $tl("labels.booking.menus.mandatoryFrom", restaurantId) }}</label>
                            </div>
                            <div class="col-7">
                                <div class="row">
                                    <div class="col-4">
                                        <input
                                            type="number"
                                            name="menu_unique_from"
                                            v-model="currentMenus.nbPersMandatoryAbovePax"
                                            class="form-control"
                                            min="1"
                                            step="1" />
                                    </div>
                                    <div class="col-8 mt-2">
                                        {{ $tl("labels.personss") }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-0">
                            <div class="col-5">
                                <label>
                                    {{ $tl("labels.booking.menus.unique", restaurantId) }}
                                    <feather
                                        v-tooltip="getTooltipNoticeLexicon('notices.booking.servicesConfig.uniqueMenu', restaurantId)"
                                        type="info"
                                        class="ml-1 pointer tooltip-infos" />
                                </label>
                            </div>
                            <div class="col-7">
                                <label class="container-box" style="width: 100%">
                                    <input type="checkbox" name="menu_unique" v-model="currentMenus.config.isUnique" />
                                    <span class="checkmark"></span> {{ $tl("labels.yes") }}
                                </label>
                            </div>
                        </div>
                        <div v-if="currentMenus.config.isUnique" class="row mb-4">
                            <div class="col-5 pt-2">
                                <label>{{ $tl("labels.booking.menus.uniqueFrom", restaurantId) }}</label>
                            </div>
                            <div class="col-7">
                                <div class="row">
                                    <div class="col-4">
                                        <input
                                            type="number"
                                            name="menu_unique_from"
                                            v-model="currentMenus.config.nbPersUniqueAbovePax"
                                            class="form-control"
                                            min="1"
                                            step="1" />
                                    </div>
                                    <div class="col-8 mt-2">
                                        {{ $tl("labels.personss") }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="sepa"></div>
                    <div class="row mt-4">
                        <div class="col-3">
                            <label>{{ $tl("labels.booking.services.generalOptions.link") }}</label>
                        </div>
                        <div class="col-9">
                            <div v-if="generalOptions.length > 0">
                                <div class="mb-2" v-for="option in generalOptions" :key="option.id">
                                    <label class="container-box" style="margin-bottom: 0; width: 100%">
                                        <input type="checkbox" name="general_options_selected[]" :value="option.id" v-model="checkedGeneralOptions" />
                                        <span class="checkmark"></span> {{ option.name }}
                                    </label>
                                </div>
                            </div>
                            <div class="mt-4 mb-4">
                                <button type="button" @click="showAddGeneralOptionModal = true" class="btn btn-success btn-circle btn-sm">
                                    {{ $tl("labels.booking.generalOptions.add") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <add-menu-modal v-if="showAddMenuModal" @menu-added="$emit('fetch-menus')" @close="showAddMenuModal = false" />
        <add-menu-option-modal
            v-if="showAddGeneralOptionModal"
            :option-type="MENU_OPTION_GENERAL.value"
            @menu-option-added="$emit('fetch-options')"
            @close="showAddGeneralOptionModal = false" />
    </div>
</template>

<script>
import OverlayLayout from "../../layouts/OverlayLayout.vue";
import AddMenuModal from "../../Modals/menus/addMenuModal.vue";
import addMenuOptionModal from "../../Modals/menuOptions/addMenuOptionModal.vue";
import MenuOptionTypeEnum from "../../../mixins/enums/booking/MenuOptionTypeEnum.js";
import AddGeneralOptionModal from "../../Modals/generalOptions/addGeneralOptionModal.vue";

export default {
    name: "MenuAndOptionsGroup",
    components: {
        OverlayLayout,
        AddMenuModal,
        addMenuOptionModal,
        AddGeneralOptionModal,
    },
    data() {
        return {
            canEmitUpdate: false,

            showAddGeneralOptionModal: false,
            showAddMenuModal: false,

            currentMenus: {
                checkedMenus: {},
                config: {
                    isUnique: false,
                    nbPersUniqueAbovePax: 8,
                    isMandatory: false,
                    nbPersMandatoryAbovePax: null,
                },
            },

            checkedGeneralOptions: [],
        };
    },
    mixins: [MenuOptionTypeEnum],
    props: {
        menus: {
            type: Array,
            required: true,
        },
        generalOptions: {
            type: Array,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
    },
    computed: {
        restaurantId() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        emitEvent(eventName, val) {
            if (!this.canEmitUpdate) {
                return;
            }
            this.$emit(eventName, val);
        },
        changedMenu(event, menu_id) {
            if (event.target.checked) {
                this.$set(this.currentMenus.checkedMenus, menu_id, {
                    id: menu_id,
                    enable_nb_min_pers: false,
                    nb_min_pers: null,
                    enable_nb_max_pers: false,
                    nb_max_pers: null,
                });
            } else {
                this.currentMenus.checkedMenus = _.omit(this.currentMenus.checkedMenus, menu_id);
            }
        },
        initMenus() {
            this.currentMenus.config.isMandatory = this.service.menus.mandatory;
            this.currentMenus.config.nbPersMandatoryAbovePax = this.service.menus.mandatoryAbovePax;
            this.currentMenus.config.isUnique = this.service.menus.mandatory;
            this.currentMenus.config.isManbPersUniqueAbovePaxndatory = this.service.menus.mandatory;
            this.currentMenus.config.isUnique = this.service.menus.mandatory;
            this.currentMenus.checkedMenus = this.service.menus.value;
        },
        initGeneralOptions() {
            this.checkedGeneralOptions = this.service.generalOptions.value;
        },
    },
    mounted() {
        this.initMenus();
        this.initGeneralOptions();
        this.canEmitUpdate = true;
    },
    watch: {
        currentMenus: {
            handler(val) {
                this.emitEvent("update-menus", val);
            },
            deep: true,
        },
        checkedGeneralOptions: {
            handler(val) {
                this.emitEvent("update-general-options", val);
            },
            deep: true,
        },
    },
};
</script>
