<template>
    <div class="pb-5">
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="error" class="error">
                {{ error }}
            </div>
            <div v-else>
                <div class="row m-0">
                    <div class="col-12">
                        <div
                            class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1"
                            :class="{ 'mb-1': hasMore, 'pb-2': !hasMore }">
                            <h5 class="title mt-2">{{ $tl("labels.booking.reservations.created.title") }}</h5>
                        </div>
                        <small class="d-block text-warning mb-3" v-if="hasMore">
                            {{ $t("infos.booking.reservations.onlyXDisplayed", { viewLimit }) }}
                            <br />{{ $tl("infos.booking.reservations.viewMoreCreated") }}
                        </small>
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col-12">
                        <div v-if="restaurant">
                            <div v-if="dates.length <= 0">
                                <em>{{ $tl("labels.booking.reservations.created.empty", restaurant_id) }}</em>
                            </div>
                            <div v-else>
                                <div v-for="(date, dateIndex) in dates" :key="dateIndex">
                                    <h5 class="mb-3" style="color: #083e60">
                                        <strong>{{ displayDate(date.full_date, DATE_FULL_NO_YEAR, false) }}</strong>
                                    </h5>
                                    <div v-for="(service, serviceIndex) in date.services" :key="`${dateIndex}-${serviceIndex}`">
                                        <div class="d-flex flex-wrap justify-content-between flex-md-nowrap pb-2 mb-3 border-bottom">
                                            <h6 style="text-transform: uppercase">
                                                {{ getDateTime(date.full_date).weekdayLong }}
                                                <span class="capacite badge badge-secondary">{{ getServiceCategoryLabel(service) }}</span>
                                            </h6>
                                            <h6>
                                                <span class="capacite badge badge-secondary"
                                                    >{{ $tl("labels.booking.reservations.created.confirmedPAX").toUpperCase() }} : {{ service.pax }} /
                                                    {{ service.new_pax !== null ? service.new_pax : service.max_pax }}</span
                                                >
                                            </h6>
                                        </div>
                                        <div class="border-bottom mb-5">
                                            <table class="capitalize table-size-30 table table-book table-striped table-sm">
                                                <thead class="border-bottom">
                                                    <tr>
                                                        <th>
                                                            <feather type="user" class="none-desk" style="width: 20px; height: 20px" />
                                                            <span class="none-mobile">{{
                                                                $tl("labels.booking.reservations.grid.columns.client")
                                                            }}</span>
                                                        </th>
                                                        <th>
                                                            <feather type="users" class="none-desk" style="width: 20px; height: 20px" />
                                                            <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.pax") }}</span>
                                                        </th>
                                                        <th>
                                                            <feather type="clock" class="none-desk" style="width: 20px; height: 20px" />
                                                            <span class="none-mobile">{{
                                                                $tl("labels.booking.reservations.grid.columns.hour")
                                                            }}</span>
                                                        </th>
                                                        <th class="none-mobile">
                                                            <feather type="monitor" class="none-desk" style="width: 20px; height: 20px" />
                                                            <span class="none-mobile">{{
                                                                $tl("labels.booking.reservations.grid.columns.table", restaurant_id)
                                                            }}</span>
                                                        </th>
                                                        <th>
                                                            <feather type="check-circle" class="none-desk" style="width: 20px; height: 20px" />
                                                            <span class="none-mobile">{{
                                                                $tl("labels.booking.reservations.grid.columns.status")
                                                            }}</span>
                                                        </th>
                                                        <th class="none-mobile">
                                                            <feather type="credit-card" class="none-desk" style="width: 20px; height: 20px" />
                                                            <span class="none-mobile">{{
                                                                $tl("labels.booking.reservations.grid.columns.optionBankStatus")
                                                            }}</span>
                                                        </th>
                                                        <th class="none-mobile">
                                                            <feather type="mouse-pointer" class="none-desk" style="width: 20px; height: 20px" />
                                                            <span class="none-mobile">{{
                                                                $tl("labels.booking.reservations.grid.columns.actions")
                                                            }}</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="reservation in service.createdReservations.data"
                                                        :key="reservation.id"
                                                        @click="showResa(reservation.id, $event)"
                                                        class="pointer">
                                                        <td>
                                                            <inline-client
                                                                :client="reservation.client"
                                                                :module-enum="MODULE_TYPE_BOOKING"
                                                                :public-comment="reservation.comment"
                                                                :private-comment="reservation.restaurant_comment"
                                                                show-noshows>
                                                                <template v-slot:icons-start>
                                                                    <feather
                                                                        v-if="reservation.gift"
                                                                        type="tag"
                                                                        class="feather-blue"
                                                                        v-tooltip="getTooltip(reservation.gift)" />
                                                                </template>
                                                            </inline-client>
                                                        </td>
                                                        <td class="table-text-center">
                                                            {{ reservation.nb_pers * 1 + reservation.nb_children * 1 }}
                                                        </td>
                                                        <td>
                                                            {{ reservation.slot.hour_start }}
                                                        </td>
                                                        <td class="table-text-center none-mobile">
                                                            {{ reservation.num_table }}
                                                        </td>
                                                        <td>
                                                            <ReservationTag
                                                                :cancellation="service.cancellation_until"
                                                                :update="false"
                                                                :reservation="reservation"
                                                                :restaurant_id="restaurant.id"
                                                                :isStripeEnabled="
                                                                    restaurant.stripe_client_id ||
                                                                    (restaurant.payplug_public_key && restaurant.payplug_secret_key)
                                                                "
                                                                @displayCancelModal="displayCancelModal({ newStatus: $event, data: reservation })" />
                                                        </td>
                                                        <td class="table-text-center none-mobile">
                                                            <option-bank-status-icon :reservation="reservation" />
                                                        </td>
                                                        <td class="none-mobile">
                                                            <button
                                                                class="btn btn-sm btn-success btn-square"
                                                                @click="editReservation($event, reservation.id)"
                                                                v-tooltip="getTooltip($t('labels.form.actions.edit'))">
                                                                <feather type="edit" />
                                                            </button>
                                                            <button
                                                                class="btn btn-sm btn-outline-secondary btn-square"
                                                                v-if="reservation.status === 'created'"
                                                                @click="sendConfirmationEmail(reservation.id)"
                                                                v-tooltip="getTooltip($t('labels.booking.reservations.sendDetailsToClient'))">
                                                                <feather type="send" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <show-reservation-modal
            v-if="showReservationModal"
            :reservation_id="selected_reservation_id"
            :restaurant_id="selected_restaurant_id"
            @resa-edit="editReservationFromShowModal"
            @close="showReservationModal = false" />
        <edit-reservation-modal
            v-if="showEditReservationModal"
            :restaurant_id="selected_restaurant_id"
            :reservation_id="selected_reservation_id"
            @close="showEditReservationModal = false"
            @reservation-edited="reservationEdited" />
        <cancel-reservation-modal
            v-if="showCancelModal"
            :reservation-id="selected_reservation_id"
            :client="selected_reservation_client"
            :status="newCancelStatus"
            @close="showCancelModal = false" />
    </div>
</template>

<script>
import axios from "axios";
import OptionBankStatusIcon from "../../components/stripe/OptionBankStatusIcon";
import ReservationTag from "../../components/reservations/ReservationTagComponent";
import ShowReservationModal from "../../components/Modals/reservations/ShowReservationModal";
import EditReservationModal from "../../components/Modals/reservations/EditReservationModal";
import LoaderComponent from "../../components/LoaderComponent.vue";
import LangsEnum from "../../mixins/enums/LangsEnum";
import InlineClient from "../../components/Default/Clients/inlineClient.vue";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum.js";
import CancelReservationModal from "../../components/Modals/reservations/CancelReservationModal.vue";

export default {
    data() {
        return {
            loading: false,
            error: null,
            restaurant: {},
            dates: null,
            showEditReservationModal: false,
            showReservationModal: false,
            selected_reservation_id: null,
            selected_reservation_client: null,
            selected_restaurant_id: null,
            socket: {
                services: {
                    listeningChannels: [],
                },
                new_reservations: {
                    listeningChannel: "",
                },
            },
            hasMore: false,
            viewLimit: 100,
            newCancelStatus: "canceled",
            showCancelModal: false,
        };
    },
    mixins: [LangsEnum, ModuleTypesEnum],
    methods: {
        displayCancelModal({ newStatus, data }) {
            this.selected_reservation_id = data.id;
            this.selected_reservation_client = data.client;
            this.newCancelStatus = newStatus;
            this.showCancelModal = true;
        },
        fetchData() {
            this.error = this.restaurant = null;
            this.loading = true;

            this.fetchRestaurant();
        },
        fetchDates() {
            this.unregisterAllUpdatedReservationSocket();
            this.unregisterNewCreatedReservationSocket();
            axios
                .get(`/api/restaurants/${this.$route.params.restaurant_id}/reservationsCreated?limit=${this.viewLimit}`)
                .then((response) => {
                    this.dates = response.data.data;
                    this.hasMore = response.data.hasMore;
                    this.registerNewCreatedReservationSocket();
                    this.registerUpdatedReservationSocket();
                    this.$nextTick(() => {
                        this.loading = false;
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        fetchRestaurant() {
            axios
                .get(`/api/restaurants/${this.$route.params.restaurant_id}`)
                .then((response) => {
                    this.restaurant = response.data;
                    this.fetchDates();
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        sendConfirmationEmail(reservation_id) {
            axios
                .get(`/api/restaurants/${this.$route.params.restaurant_id}'/reservations/${reservation_id}/sendConfirm`)
                .then(() => {
                    this.notifySuccess(null, this.$tl("success.booking.reservations.confirmationSent"));
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => (this.loading = false));
        },
        showResa(id, event) {
            const excludedTags = ["BUTTON", "A"];

            if (!event || !event.target || !excludedTags.includes(event.target.tagName)) {
                this.$set(this, "selected_reservation_id", id);
                this.$nextTick(() => {
                    this.showReservationModal = true;
                });
            }
        },
        editReservation(event, id) {
            event.preventDefault();
            event.stopPropagation();

            this.$set(this, "selected_reservation_id", id);
            this.$nextTick(() => {
                this.showEditReservationModal = true;
            });
        },
        reservationEdited() {
            this.fetchData();
            this.showEditReservationModal = false;
            this.notifySuccess(null, this.$tl("success.booking.reservations.edited"));
        },
        editReservationFromShowModal({ reservation_id }) {
            this.$set(this, "selected_reservation_id", reservation_id);
            this.$nextTick(() => {
                this.showReservationModal = false;
                this.showEditReservationModal = true;
            });
        },
        registerNewCreatedReservationSocket() {
            const channelName = `App.restaurant.${this.$route.params.restaurant_id}`;

            Echo.private(channelName).listen(".reservation.new.created", this.onNewCreatedReservation);

            this.socket.new_reservations.listeningChannel = channelName;
            this.$store.commit("sockets/addChannel", channelName);
        },
        unregisterNewCreatedReservationSocket() {
            Echo.leave(this.socket.new_reservations.listeningChannel);

            this.$store.commit("sockets/removeChannel", this.socket.new_reservations.listeningChannel);
            this.socket.new_reservations.listeningChannel = "";
        },
        registerUpdatedReservationSocket() {
            this.dates.forEach((date) => {
                date.services.forEach((service) => {
                    this.registerUpdatedReservationSocketForAService(service, date.full_date);
                });
            });
        },
        registerUpdatedReservationSocketForAService(service, date) {
            const channelName = `App.restaurant.${this.$route.params.restaurant_id}.service.${service.id}.date.${date}`;

            Echo.private(channelName).listen(".reservation.updated", this.onUpdatedReservation);

            this.socket.services.listeningChannels.push(channelName);
            this.$store.commit("sockets/addChannel");
        },
        unregisterAllUpdatedReservationSocket() {
            this.socket.services.listeningChannels.forEach((channel) => {
                Echo.leave(channel);
                this.$store.commit("sockets/removeChannel", channel);
            });

            this.socket.services.listeningChannels = [];
        },
        unregisterAllSockets() {
            this.$store.getters["sockets/getChannels"].forEach((channelName) => {
                Echo.leave(channelName);
            });

            this.$store.commit("sockets/removeAllChannels");
        },
        onNewCreatedReservation(e) {
            const updatedReservation = e.reservation;
            const date = this.getDateTime(updatedReservation.reservation_datetime).toISODate();
            const updatedReservationService = updatedReservation.slot.service;
            let foundDate = -1;
            let foundService = -1;

            dateLoop: for (var i = 0; i < this.dates.length; i++) {
                const dateObject = this.dates[i];

                if (dateObject.full_date === date) {
                    foundDate = i;
                    for (var j = 0; j < dateObject.services.length; j++) {
                        const service = dateObject.services[j];

                        if (service.id === updatedReservationService.id) {
                            foundService = j;
                            break dateLoop;
                        }
                    }
                    break dateLoop;
                }
            }

            if (foundDate === -1) {
                foundDate =
                    this.dates.push({
                        full_date: date,
                        services: [],
                    }) - 1;
            }

            if (foundService === -1) {
                foundService = this.dates[foundDate].services.push(updatedReservationService) - 1;
                this.registerUpdatedReservationSocketForAService(updatedReservationService, date);
            }

            if (!_.has(this.dates[foundDate].services[foundService], "createdReservations")) {
                this.dates[foundDate].services[foundService].createdReservations = {
                    data: [],
                };
            }

            this.dates[foundDate].services[foundService].createdReservations.data.push(updatedReservation);
        },
        onUpdatedReservation(e) {
            const updatedReservation = e.reservation;
            const date = this.getDateTime(updatedReservation.reservation_datetime).toISODate();
            const updatedReservationService = updatedReservation.slot.service;

            for (var i = 0; i < this.dates.length; i++) {
                const dateObject = this.dates[i];

                if (dateObject.full_date === date) {
                    for (var j = 0; j < dateObject.services.length; j++) {
                        const service = dateObject.services[j];

                        if (service.id === updatedReservationService.id) {
                            for (var k = 0; k < service.createdReservations.data.length; k++) {
                                const reservation = service.createdReservations.data[k];

                                if (reservation.id === updatedReservation.id) {
                                    this.$set(this.dates[i].services[j].createdReservations.data, k, updatedReservation);
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        },
    },
    created() {
        this.selected_restaurant_id = this.$route.params.restaurant_id;
        this.fetchData();
    },
    beforeDestroy() {
        this.unregisterAllSockets();
    },
    watch: {
        "$route.params.restaurant_id": function (id) {
            this.selected_restaurant_id = this.$route.params.restaurant_id;
            this.fetchData();
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    components: {
        ReservationTag,
        OptionBankStatusIcon,
        ShowReservationModal,
        EditReservationModal,
        LoaderComponent,
        InlineClient,
        CancelReservationModal,
    },
};
</script>
