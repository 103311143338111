<template>
    <div class="my-2">
        <h5>Montant</h5>
        <div class="radio my-3">
            <radio-button-component :inputValue="DISCOUNT_TYPE_AMOUNT_OFF.value" v-model="form.discount_type">Montant fixe</radio-button-component>
            <radio-button-component :inputValue="DISCOUNT_TYPE_PERCENT_OFF.value" v-model="form.discount_type">
                Réduction en pourcentage
            </radio-button-component>
        </div>
        <input-component
            v-if="form.discount_type === DISCOUNT_TYPE_AMOUNT_OFF.value"
            :input-name="DISCOUNT_TYPE_AMOUNT_OFF.value"
            input-type="number"
            label="Montant (en euros)"
            append-element="€"
            required
            :min="1"
            :form-errors="errors.form"
            inputClasses="w-80"
            v-model="form.amount_off" />

        <input-component
            v-else
            :input-name="DISCOUNT_TYPE_PERCENT_OFF.value"
            input-type="number"
            label="Réduction (en %)"
            append-element="%"
            required
            :min="1"
            :max="100"
            :form-errors="errors.form"
            inputClasses="w-80"
            v-model="form.percent_off" />

        <switch-input-component label="Définir un nombre maximum d'utilisation" input-name="switchNbUseMax" v-model="canPreciseMaxRedemption" />
        <input-component
            v-if="canPreciseMaxRedemption"
            required
            input-name="max_redemptions"
            input-type="number"
            label="Nombre d'utilisations maximum pour le code promo"
            v-model="form.max_redemptions" />
        <switch-input-component
            label="Définir un nombre maximum d'utilisation par client"
            input-name="switchNbUseMaxPerUser"
            :min="1"
            v-model="canPreciseMaxRedemptionPerUser" />
        <input-component
            v-if="canPreciseMaxRedemptionPerUser"
            required
            input-name="maxRedemptionPerUSer"
            input-type="number"
            :min="1"
            label="Nombre d'utilisations maximum par client pour le code promo"
            v-model="form.max_redemptions_by_user" />
    </div>
</template>
<script>
import RadioButtonComponent from "../../../forms/RadioButtonComponent.vue";
import InputComponent from "../../../forms/InputComponent.vue";
import SwitchInputComponent from "../../../forms/SwitchInputComponent.vue";
import DiscountTypeEnum from "../../../../mixins/enums/DiscountTypeEnum.js";

export default {
    props: {
        errors: {
            type: Object,
            required: true,
        },
    },
    components: {
        RadioButtonComponent,
        InputComponent,
        SwitchInputComponent,
    },
    mixins: [DiscountTypeEnum],
    data() {
        return {
            form: {
                amount_off: null,
                percent_off: null,
                discount_type: null,
                max_redemptions: null,
                max_redemptions_by_user: null,
            },
            canPreciseMaxRedemption: false,
            canPreciseMaxRedemptionPerUser: false,
        };
    },
    created() {
        this.form.discount_type = this.DISCOUNT_TYPE_AMOUNT_OFF.value;
    },
    watch: {
        form: {
            handler(newValue) {
                this.$emit("update-form", newValue);
            },
            immediate: true,
            deep: true,
        },
        canPreciseMaxRedemption(newValue) {
            if (!newValue) {
                this.form.max_redemptions = null;
            }
        },
        canPreciseMaxRedemptionPerUser(newValue) {
            if (!newValue) {
                this.form.max_redemptions_by_user = null;
            }
        },
    },
};
</script>
