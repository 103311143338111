var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.command.status != _vm.COMMAND_COLLECTED.value
    ? _c(
        "button",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.getTooltip("Marquer la commande comme 'Livrée'"),
              expression:
                "getTooltip('Marquer la commande comme \\'Livrée\\'')",
            },
          ],
          staticClass: "btn btn-sm btn-outline-secondary btn-square",
          attrs: { disabled: _vm.loading },
          on: {
            click: function ($event) {
              return _vm.markDelivered()
            },
          },
        },
        [_c("feather", { attrs: { type: "check" } })],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }