<template>
    <div>
        <input
            type="password"
            v-model="password"
            :data-test-id="dataTestId"
            @focus="inputFocus = true"
            @blur="inputFocus = false"
            class="form-control"
            :class="{ 'is-invalid': !passwordsMatch }"
            v-tooltip="!passwordsMatch ? { content: 'Les mots de passe ne correspondent pas' } : undefined" />
        <div class="div-pass-check" v-show="showLegend_">
            <span v-show="!passwordsMatch" class="text-danger">Les mots de passe ne correspondent pas</span>
            <span v-show="passwordsMatch && mainPassword && password" class="text-success"
                >Les mots de passe correspondent</span
            >
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            inputFocus: false,
        };
    },
    props: {
        mainPassword: {
            default: null,
        },
        value: {
            default: null,
        },
        showLegend: {
            default: false,
        },
        hideLegend: {
            default: false,
        },
        dataTestId: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        passwordsMatch() {
            if (!this.mainPassword || !this.password) return true;
            return this.mainPassword === this.password;
        },
        showLegend_() {
            return !this.hideLegend && this.password !== "" && (this.showLegend || this.inputFocus);
        },
        password: {
            get() {
                return this.value;
            },
            set(newVal) {
                this.$emit("input", newVal);
            },
        },
    },
};
</script>
