<template>
    <modal @close="closeFromModal">
        <h5 slot="header">
            {{ $t("labels.form.sms.detailsFor", { packName: $t("labels.invoices.smsPack", { nb: smsPack.nb_sms }) }) }}
        </h5>
        <div slot="body">
            <purchase-sms-pack :sms-pack="smsPack"></purchase-sms-pack>
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="close">
                {{ $tl("labels.form.actions.close") }}
            </button>
        </div>
    </modal>
</template>

<script>
import purchaseSmsPack from "../../sms/PurchaseSmsPack.vue";

export default {
    data: function () {
        return {
            loading: false,
        };
    },
    props: {
        smsPack: {
            default: "",
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        purchaseSmsPack,
    },
};
</script>
