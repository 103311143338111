var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass: "d-md-block bg-dark sidebar",
      class: {
        "col-lg-2 col-md-3": !_vm.isSidebarCollapsed,
        "col-lg-1 col-md-1 text-center mr-0": _vm.isSidebarCollapsed,
      },
    },
    [
      _c("div", { staticClass: "sidebar-sticky" }, [
        _c(
          "div",
          [
            _vm.restaurant_id && !_vm.isSidebarCollapsed
              ? _c("div", { staticClass: "input-group restaurant-title p-3" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.restaurant_id,
                          expression: "restaurant_id",
                        },
                      ],
                      staticClass: "custom-select rounded-pill pl-3",
                      attrs: { id: "inputGroupSelect01" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.restaurant_id = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function ($event) {
                            return _vm.selectRestaurant($event)
                          },
                        ],
                      },
                    },
                    _vm._l(_vm.restaurants, function (restaurant) {
                      return _c(
                        "option",
                        { domProps: { value: restaurant.id } },
                        [_vm._v(_vm._s(restaurant.name))]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.shouldShowEntries
              ? _c(
                  "ul",
                  {
                    staticClass: "sid-nav nav flex-column",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close-menu")
                      },
                    },
                  },
                  _vm._l(_vm.entries, function (entry, index) {
                    return _c("sidebar-nav-item", {
                      key: index,
                      attrs: {
                        user: _vm.user,
                        isCollapsed: _vm.isSidebarCollapsed,
                        entry: entry,
                      },
                    })
                  }),
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            [
              _c(
                "h6",
                {
                  staticClass: "sidebar-heading px-3 mt-4 mb-1 text-muted",
                  class: {
                    "text-center": _vm.isSidebarCollapsed,
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$tl("labels.help")) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "sid-nav-bot nav flex-column" },
                [
                  _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: { "text-center": _vm.isSidebarCollapsed },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            href: _vm.externalHelpRoute,
                            target: "_blank",
                          },
                        },
                        [
                          _c("feather", {
                            class: {
                              "size-22": _vm.isSidebarCollapsed,
                              "size-16": !_vm.isSidebarCollapsed,
                            },
                            attrs: { type: "book" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isSidebarCollapsed,
                                  expression: "!isSidebarCollapsed",
                                },
                              ],
                              staticClass: "display-sid-min",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$tl("labels.routes.documentation"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isYservices
                    ? _c(
                        "li",
                        {
                          staticClass: "nav-item none-tablet",
                          class: { "text-center": _vm.isSidebarCollapsed },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openHelpscoutBeacon.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c("feather", {
                                class: {
                                  "size-22": _vm.isSidebarCollapsed,
                                  "size-16": !_vm.isSidebarCollapsed,
                                },
                                attrs: { type: "help-circle" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.isSidebarCollapsed,
                                      expression: "!isSidebarCollapsed",
                                    },
                                  ],
                                  staticClass: "display-sid-min",
                                },
                                [_vm._v(_vm._s(_vm.$tl("labels.routes.help")))]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm.isNoShow
                    ? [
                        _c(
                          "li",
                          {
                            staticClass: "nav-item none-tablet",
                            class: { "text-center": _vm.isSidebarCollapsed },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link open-intercom",
                                attrs: { href: "#" },
                              },
                              [
                                _c("feather", {
                                  class: {
                                    "size-22": _vm.isSidebarCollapsed,
                                    "size-16": !_vm.isSidebarCollapsed,
                                  },
                                  attrs: { type: "help-circle" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.isSidebarCollapsed,
                                        expression: "!isSidebarCollapsed",
                                      },
                                    ],
                                    staticClass: "display-sid-min",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$tl("labels.routes.help"))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            staticClass: "nav-item none-tablet",
                            class: { "text-center": _vm.isSidebarCollapsed },
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link",
                                attrs: { to: _vm.onlineHelpRoute },
                              },
                              [
                                _c("feather", {
                                  class: {
                                    "size-22": _vm.isSidebarCollapsed,
                                    "size-16": !_vm.isSidebarCollapsed,
                                  },
                                  attrs: { type: "film" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.isSidebarCollapsed,
                                        expression: "!isSidebarCollapsed",
                                      },
                                    ],
                                    staticClass: "display-sid-min",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl("labels.routes.onlineHelp")
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.checkForExistingNewBadge("online_help")
                                  ? _c("newBadge", { staticClass: "ml-2" })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      staticClass: "nav-item none-tablet",
                      class: { "text-center": _vm.isSidebarCollapsed },
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: { to: { name: "app_updates.list" } },
                        },
                        [
                          _c(
                            "svg",
                            {
                              class: {
                                "size-22": _vm.isSidebarCollapsed,
                                "size-16": !_vm.isSidebarCollapsed,
                              },
                              attrs: {
                                version: "1.1",
                                viewBox: "0 0 1200 1200",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("g", { attrs: { fill: "#fff" } }, [
                                _c("path", {
                                  attrs: {
                                    d: "m913.84 399.6 0.61328-319.37-116.74-0.074219-22.5 32.555c-70.32 102.23-150.04 218.09-274.7 218.44l-35.16-0.14453 0.17969-41.027-284.03-1.3203-0.44531 97.969-117.56-0.63672-1.1641 245.1 117.6 0.60156-0.44531 97.535 47.316 0.21484 104.14 390.41 141.09-48.73-57.215-340.84 48.758 0.21484 0.22656-51.277 34.559 0.058594c125.5 0.50391 205.3 117.1 275.72 219.97l21.949 31.945 116.79 0.26562 0.66016-342.47c40.02-12.719 69.121-50.09 69.324-94.273 0.17578-44.422-28.805-82.176-68.957-95.121zm-791.22 171.79 0.60156-125.07 57.574 0.3125-0.57422 125.06zm730.31 299.93-25.297-0.058593-4.043-5.9023c-74.855-109.34-168.01-245.44-325.05-246.07l-34.465-0.058593 1.0195-228.21 35.387 0.14453c156.3-0.43359 249.38-135.72 324.17-244.43l4.5234-6.5391 25.176 0.023438z",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "m1035.7 464.26h101.91v60h-101.91z",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "m992.95 255.69 103.12-61.875 30.871 51.449-103.12 61.875z",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "m992.95 732.8 30.871-51.449 103.12 61.875-30.871 51.449z",
                                  },
                                }),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isSidebarCollapsed,
                                  expression: "!isSidebarCollapsed",
                                },
                              ],
                              staticClass: "display-sid-min",
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.$tl("labels.routes.appUpdates")) +
                                  "\n                                "
                              ),
                              _vm.hasUnreadAppUpdates
                                ? _c("div", { staticClass: "red-bubble ml-2" })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.checkForExistingNewBadge("app_updates")
                                ? _c("new-badge", { staticClass: "ml-2" })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.restaurant_id,
                    expression: "restaurant_id",
                  },
                ],
                staticClass: "btn-sid-min",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn text-white",
                    on: {
                      click: function ($event) {
                        return _vm.$emit(
                          "sidebar-collapse-changed",
                          !_vm.isSidebarCollapsed
                        )
                      },
                    },
                  },
                  [
                    _c("feather", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isSidebarCollapsed,
                          expression: "!isSidebarCollapsed",
                        },
                      ],
                      staticClass: "feather-24",
                      attrs: { type: "chevrons-left" },
                    }),
                    _vm._v(" "),
                    _c("feather", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isSidebarCollapsed,
                          expression: "isSidebarCollapsed",
                        },
                      ],
                      staticClass: "feather-24",
                      attrs: { type: "chevrons-right" },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }