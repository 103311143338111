var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("LoaderComponent")
    : _c("div", [
        _vm.error
          ? _c("div", { staticClass: "alert alert-danger" }, [
              _vm._v("\n        " + _vm._s(_vm.error) + "\n    "),
            ])
          : _c(
              "div",
              [_c("show-gift-voucher", { attrs: { sale: _vm.gv_sale } })],
              1
            ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }