var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5 pt-5" },
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                ])
              : _c("div", [
                  _c("div", { staticClass: "row m-0" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between pb-2 pt-2 mt-1 mb-1",
                        },
                        [
                          _c("h5", { staticClass: "title mt-2" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$tl(
                                    "labels.routes.booking.config.notifications"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-success btn-circle",
                              attrs: {
                                type: "button",
                                disabled: _vm.formLoading,
                              },
                              on: { click: _vm.postForm },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$tl("labels.form.actions.save")) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.formErrors && _vm.formErrors.length > 0
                    ? _c(
                        "div",
                        { staticClass: "alert alert-danger" },
                        _vm._l(_vm.formErrors, function (error, index) {
                          return _c("p", { key: index }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(error) +
                                "\n                "
                            ),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      attrs: { id: "editRestaurantConfig", method: "post" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.postForm.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm.formLoading
                        ? _c("LoaderComponent")
                        : _c("div", { staticClass: "row m-0" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "border-light b-radius-20 p-4 mb-3",
                                },
                                [
                                  _c("div", { staticClass: "row mb-3" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-4" },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.notifications.config"
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("feather", {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value:
                                                _vm.getTooltipNoticeLexicon(
                                                  "notices.booking.notifications.notificationsConfig",
                                                  _vm.restaurant_id
                                                ),
                                              expression:
                                                "getTooltipNoticeLexicon('notices.booking.notifications.notificationsConfig', restaurant_id)",
                                            },
                                          ],
                                          staticClass:
                                            "ml-1 pointer tooltip-infos",
                                          attrs: { type: "info" },
                                        }),
                                        _vm._v(" "),
                                        _c("show-new-badge-details", {
                                          staticClass: "ml-1",
                                          attrs: {
                                            badge_uuid:
                                              "ba792829-2eb4-4b9b-9ada-6e05c37a4b01",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-8" },
                                      [
                                        _c("ChooseLang", {
                                          staticClass: "d-inline-block",
                                          staticStyle: {
                                            "z-index": "auto !important",
                                          },
                                          attrs: {
                                            disabled: _vm.formLoading,
                                            "is-input-mode": "",
                                            "should-translate-label": "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function ({ lang }) {
                                                return [
                                                  lang.value !== _vm.locale &&
                                                  _vm.getUnsavedChangeIndex(
                                                    null,
                                                    lang.value
                                                  ) !== -1
                                                    ? _c("div", {
                                                        staticClass:
                                                          "ml-2 red-bubble",
                                                      })
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ]),
                                          model: {
                                            value: _vm.locale,
                                            callback: function ($$v) {
                                              _vm.locale = $$v
                                            },
                                            expression: "locale",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _vm.hasUnsavedChangeForOtherLocale
                                          ? _c("div", {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: _vm.getTooltip(
                                                    _vm.$t(
                                                      "infos.common.unsavedChanges"
                                                    )
                                                  ),
                                                  expression:
                                                    "getTooltip($t('infos.common.unsavedChanges'))",
                                                },
                                              ],
                                              staticClass: "red-bubble",
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mb-4",
                                              attrs: {
                                                label: _vm.$t(
                                                  "labels.notifications.booking.createdShort"
                                                ),
                                                "mail-preview-id":
                                                  "client-created",
                                                "sms-preview-id":
                                                  "client-created",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                formErrors: _vm.formErrors,
                                                errorKey: "client_resa_created",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_resa_created"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_resa_created"
                                          )
                                        )
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "sepa" }),
                                      _vm._v(" "),
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mt-4 mb-4",
                                              attrs: {
                                                label: _vm.$t(
                                                  "labels.notifications.booking.confirmed"
                                                ),
                                                "mail-preview-id":
                                                  "client-validated",
                                                "sms-preview-id":
                                                  "client-validated",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                formErrors: _vm.formErrors,
                                                errorKey:
                                                  "client_resa_validated",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_resa_validated"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_resa_validated"
                                          )
                                        )
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "sepa" }),
                                      _vm._v(" "),
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mt-4 mb-4",
                                              attrs: {
                                                label: _vm.$t(
                                                  "labels.notifications.booking.confirmedWithFootprint"
                                                ),
                                                "mail-preview-id":
                                                  "client-validated-footprint",
                                                "sms-preview-id":
                                                  "client-validated-footprint",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                formErrors: _vm.formErrors,
                                                errorKey:
                                                  "client_resa_validated_footprint",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_resa_validated_footprint"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_resa_validated_footprint"
                                          )
                                        )
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "sepa" }),
                                      _vm._v(" "),
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mt-4 mb-4",
                                              attrs: {
                                                label: _vm.$t(
                                                  "labels.notifications.booking.refused"
                                                ),
                                                "mail-preview-id":
                                                  "client-refused",
                                                "sms-preview-id":
                                                  "client-refused",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                formErrors: _vm.formErrors,
                                                errorKey: "client_resa_refused",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_resa_refused"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_resa_refused"
                                          )
                                        )
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "sepa" }),
                                      _vm._v(" "),
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mt-4 mb-4",
                                              attrs: {
                                                label: _vm.$t(
                                                  "labels.notifications.booking.askFootprint"
                                                ),
                                                "mail-preview-id":
                                                  "client-resa-footprint",
                                                "sms-preview-id":
                                                  "client-resa-footprint",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                formErrors: _vm.formErrors,
                                                errorKey:
                                                  "client_resa_footprint",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_resa_footprint"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_resa_footprint"
                                          )
                                        )
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "sepa" }),
                                      _vm._v(" "),
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mt-4 mb-4",
                                              attrs: {
                                                label: _vm.$t(
                                                  "labels.notifications.booking.askPayment"
                                                ),
                                                "mail-preview-id":
                                                  "client-resa-payment",
                                                "sms-preview-id":
                                                  "client-resa-payment",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                formErrors: _vm.formErrors,
                                                errorKey: "client_resa_payment",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_resa_payment"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_resa_payment"
                                          )
                                        )
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "sepa" }),
                                      _vm._v(" "),
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mt-4 mb-4",
                                              attrs: {
                                                label: _vm.$t(
                                                  "labels.notifications.booking.reminderFootprint"
                                                ),
                                                "mail-preview-id":
                                                  "client-resa-reminder-footprint",
                                                "sms-preview-id":
                                                  "client-resa-reminder-footprint",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                formErrors: _vm.formErrors,
                                                errorKey:
                                                  "client_resa_reminder_footprint",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_resa_reminder_footprint"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_resa_reminder_footprint"
                                          )
                                        )
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "sepa" }),
                                      _vm._v(" "),
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mt-4 mb-4",
                                              attrs: {
                                                label: _vm.$t(
                                                  "labels.notifications.booking.reminderPayment"
                                                ),
                                                "mail-preview-id":
                                                  "client-resa-reminder-payment",
                                                "sms-preview-id":
                                                  "client-resa-reminder-payment",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                formErrors: _vm.formErrors,
                                                errorKey:
                                                  "client_resa_reminder_payment",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_resa_reminder_payment"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_resa_reminder_payment"
                                          )
                                        )
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mt-4 mb-4",
                                              attrs: {
                                                label: _vm.$t(
                                                  "labels.notifications.booking.reminder"
                                                ),
                                                "mail-preview-id":
                                                  "client-reminder",
                                                "sms-preview-id":
                                                  "client-reminder",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                canBeDisabled: true,
                                                formErrors: _vm.formErrors,
                                                errorKey:
                                                  "client_resa_reminder",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_resa_reminder"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_resa_reminder"
                                          )
                                        )
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "sepa" }),
                                      _vm._v(" "),
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mt-4 mb-4",
                                              attrs: {
                                                label: _vm.$t(
                                                  "labels.notifications.booking.askConfirmation"
                                                ),
                                                "mail-preview-id":
                                                  "client-ask-resa-confirmation",
                                                "sms-preview-id":
                                                  "client-ask-resa-confirmation",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                formErrors: _vm.formErrors,
                                                errorKey:
                                                  "client_resa_ask_confirmation",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_resa_ask_confirmation"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_resa_ask_confirmation"
                                          )
                                        ),
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "row" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-md-4" },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "labels.notifications.send.title"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-md-8 radio",
                                                },
                                                [
                                                  _c(
                                                    "radio-button",
                                                    {
                                                      attrs: {
                                                        inputValue: false,
                                                        disabled:
                                                          !_vm.has_right_to_update_notification,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .notifications_setting
                                                            .client_resa_ask_confirmation_is_auto,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.notifications_setting,
                                                            "client_resa_ask_confirmation_is_auto",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "notifications_setting.client_resa_ask_confirmation_is_auto",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.notifications.send.manually"
                                                            )
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "radio-button",
                                                    {
                                                      attrs: {
                                                        inputValue: true,
                                                        disabled:
                                                          !_vm.has_right_to_update_notification,
                                                        tooltip: _vm.$t(
                                                          "infos.booking.notifications.send24HoursBeforeResa"
                                                        ),
                                                      },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .notifications_setting
                                                            .client_resa_ask_confirmation_is_auto,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.notifications_setting,
                                                            "client_resa_ask_confirmation_is_auto",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "notifications_setting.client_resa_ask_confirmation_is_auto",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.notifications.send.auto"
                                                            )
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "mt-2" },
                                                    [
                                                      _vm.notifications_setting
                                                        .client_resa_ask_confirmation_is_auto
                                                        ? _c("small", [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "infos.booking.notifications.send24HoursBeforeResa"
                                                                  )
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("ShowErrors", {
                                                staticClass: "d-block",
                                                attrs: {
                                                  errors: _vm.formErrors,
                                                  errorKey:
                                                    "client_resa_ask_confirmation_is_auto",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "sepa" }),
                                      _vm._v(" "),
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mt-4 mb-4",
                                              attrs: {
                                                label: _vm.$t(
                                                  "labels.notifications.booking.canceled"
                                                ),
                                                "mail-preview-id":
                                                  "client-canceled",
                                                "sms-preview-id":
                                                  "client-canceled",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                formErrors: _vm.formErrors,
                                                errorKey:
                                                  "client_resa_canceled",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_resa_canceled"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_resa_canceled"
                                          )
                                        )
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "sepa" }),
                                      _vm._v(" "),
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mt-4 mb-4",
                                              attrs: {
                                                label: _vm.$t(
                                                  "labels.notifications.booking.canceledNoFootprint"
                                                ),
                                                "mail-preview-id":
                                                  "client-canceled-footprint",
                                                "sms-preview-id":
                                                  "client-canceled-footprint",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                formErrors: _vm.formErrors,
                                                errorKey:
                                                  "client_resa_canceled_footprint",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_resa_canceled_footprint"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_resa_canceled_footprint"
                                          )
                                        )
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "sepa" }),
                                      _vm._v(" "),
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mt-4 mb-4",
                                              attrs: {
                                                label: _vm.$t(
                                                  "labels.notifications.booking.canceledNoPayment"
                                                ),
                                                "mail-preview-id":
                                                  "client-canceled-payment",
                                                "sms-preview-id":
                                                  "client-canceled-payment",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                formErrors: _vm.formErrors,
                                                errorKey:
                                                  "client_resa_canceled_payment",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_resa_canceled_payment"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_resa_canceled_payment"
                                          )
                                        )
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "sepa" }),
                                      _vm._v(" "),
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mt-4 mb-4",
                                              attrs: {
                                                label: _vm.$tl(
                                                  "labels.notifications.booking.tableAvailable",
                                                  _vm.restaurant_id
                                                ),
                                                "mail-preview-id":
                                                  "client-resa-available",
                                                "sms-preview-id":
                                                  "client-resa-available",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                formErrors: _vm.formErrors,
                                                errorKey:
                                                  "client_resa_available",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_resa_available"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_resa_available"
                                          )
                                        )
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "sepa" }),
                                      _vm._v(" "),
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mt-4 mb-4",
                                              attrs: {
                                                label: _vm.$t(
                                                  "labels.notifications.booking.noshowTotal"
                                                ),
                                                "mail-preview-id":
                                                  "client-noshow",
                                                "sms-preview-id":
                                                  "client-noshow",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                formErrors: _vm.formErrors,
                                                errorKey: "client_resa_noshow",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_resa_noshow"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_resa_noshow"
                                          )
                                        )
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "sepa" }),
                                      _vm._v(" "),
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mt-4 mb-4",
                                              attrs: {
                                                label: _vm.$t(
                                                  "labels.notifications.booking.noshowPartial"
                                                ),
                                                "mail-preview-id":
                                                  "client-noshow-partial",
                                                "sms-preview-id":
                                                  "client-noshow-partial",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                formErrors: _vm.formErrors,
                                                errorKey:
                                                  "client_resa_noshow_partial",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_resa_noshow_partial"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_resa_noshow_partial"
                                          )
                                        )
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "sepa" }),
                                      _vm._v(" "),
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mt-4 mb-4",
                                              attrs: {
                                                label: _vm.$t(
                                                  "labels.notifications.booking.noshowFree"
                                                ),
                                                "mail-preview-id":
                                                  "client-noshow-free",
                                                "sms-preview-id":
                                                  "client-noshow-free",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                formErrors: _vm.formErrors,
                                                errorKey:
                                                  "client_resa_noshow_free",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_resa_noshow_free"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_resa_noshow_free"
                                          )
                                        )
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "sepa" }),
                                      _vm._v(" "),
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mt-4 mb-4",
                                              attrs: {
                                                label: _vm.$t(
                                                  "labels.notifications.booking.overThanks"
                                                ),
                                                "mail-preview-id":
                                                  "client-thanks",
                                                "sms-preview-id":
                                                  "client-thanks",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                canBeDisabled: true,
                                                formErrors: _vm.formErrors,
                                                errorKey: "client_thanks",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_thanks"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_thanks"
                                          )
                                        )
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "sepa" }),
                                      _vm._v(" "),
                                      _c(
                                        "EditNotification",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mt-4 mb-4",
                                              attrs: {
                                                label: _vm.$tl(
                                                  "labels.notifications.booking.serviceOver",
                                                  _vm.restaurant_id
                                                ),
                                                "mail-preview-id":
                                                  "client-waiting-service-ended",
                                                "sms-preview-id":
                                                  "client-waiting-service-ended",
                                                isSmsModeAvailable:
                                                  _vm.notifications_setting
                                                    .sms_activated,
                                                disabled:
                                                  !_vm.has_right_to_update_notification,
                                                canBeDisabled: true,
                                                formErrors: _vm.formErrors,
                                                errorKey:
                                                  "client_waiting_service_ended",
                                              },
                                            },
                                            "EditNotification",
                                            _vm.getPropertiesBindingForEditComponent(
                                              "client_waiting_service_ended"
                                            ),
                                            false
                                          ),
                                          _vm.getEventsBindingForEditComponent(
                                            "client_waiting_service_ended"
                                          )
                                        )
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "border-light b-radius-20 p-4" },
                                [
                                  _c("div", { staticClass: "row mb-3" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12 mb-2" },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.notifications.qrCode.title"
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("feather", {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value:
                                                _vm.getTooltipNoticeLexicon(
                                                  "notices.booking.notifications.qrCode",
                                                  _vm.restaurant_id
                                                ),
                                              expression:
                                                "getTooltipNoticeLexicon('notices.booking.notifications.qrCode', restaurant_id)",
                                            },
                                          ],
                                          staticClass:
                                            "ml-1 pointer tooltip-infos",
                                          attrs: { type: "info" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-4 mt-2" }, [
                                      _c("label", { staticClass: "mb-1" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.notifications.qrCode.enableForResa"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-8 mt-2" }, [
                                      _c(
                                        "label",
                                        { staticClass: "container-box" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.notifications_setting
                                                    .enable_qr_code,
                                                expression:
                                                  "notifications_setting.enable_qr_code",
                                              },
                                            ],
                                            attrs: {
                                              disabled:
                                                !_vm.has_right_to_update_notification,
                                              type: "checkbox",
                                            },
                                            domProps: {
                                              value: true,
                                              checked: Array.isArray(
                                                _vm.notifications_setting
                                                  .enable_qr_code
                                              )
                                                ? _vm._i(
                                                    _vm.notifications_setting
                                                      .enable_qr_code,
                                                    true
                                                  ) > -1
                                                : _vm.notifications_setting
                                                    .enable_qr_code,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.notifications_setting
                                                      .enable_qr_code,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = true,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.notifications_setting,
                                                        "enable_qr_code",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.notifications_setting,
                                                        "enable_qr_code",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.notifications_setting,
                                                    "enable_qr_code",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row m-0 mt-3" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("input", {
                            staticClass: "btn btn-success btn-circle",
                            attrs: {
                              type: "submit",
                              name: "submit",
                              disabled: !_vm.has_right_to_update_notification,
                            },
                            domProps: {
                              value: _vm.$t("labels.form.actions.save"),
                            },
                          }),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }