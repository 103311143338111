<template>
    <div class="w-100 d-flex justify-content-end mb-4">
        <slot></slot>

        <component v-for="item in toolbarItems" :key="item.type" :is="item.type" :settings="item.settings" :data-source="dataSource"></component>
    </div>
</template>

<script>
import ExcelExport from "./Items/ExcelExport.vue";
import ColumnPicker from "./Items/ColumnPicker.vue";

/**
 * toolbarSettings: {
 *  ?items: [
 *    {
 *      ?type: enum("ExcelExport", "ColumnPicker"),
 *      ?settings: Object, see component for details
 *    }
 *  ]
 * }
 */
export default {
    props: {
        toolbarSettings: {
            type: Object,
            default: () => {
                return {};
            },
        },
        dataSource: {
            type: Array,
            required: true,
        },
    },
    methods: {
        getTable() {
            return this.$parent.getTable();
        },
        getDataSource(params = {}) {
            return this.$parent.getDataSource(params);
        },
    },
    computed: {
        toolbarItems() {
            if (Array.isArray(this.toolbarSettings.items)) {
                return this.toolbarSettings.items;
            }

            return [];
        },
    },
    components: {
        ExcelExport,
        ColumnPicker,
    },
};
</script>
