var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Zones de livraison dessinées"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _c("small", { staticClass: "text-muted d-block mb-3" }, [
          _vm._v(
            "\n            Attention, si certaines zones de livraison se chevauchent, la zone dont les frais de livraison sont les plus élevés sera prise en\n            compte.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("GoogleMap", {
          ref: "map",
          attrs: { stopLoader: false, mapZoom: 12, mapCenter: _vm.mapCenter },
          on: { initialized: _vm.onMapInitialized },
        }),
        _vm._v(" "),
        !_vm.loading
          ? [
              _c("div", { staticClass: "mt-2" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success btn-circle btn-sm",
                    attrs: { disabled: !_vm.editable },
                    on: {
                      click: function ($event) {
                        return _vm.showAddDeliveryAreaModal()
                      },
                    },
                  },
                  [
                    _c("feather", {
                      staticClass: "mr-1",
                      attrs: { type: "plus" },
                    }),
                    _vm._v("Ajouter une zone de livraison\n                "),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("ShowErrors", {
                staticClass: "d-block mt-1 mb-2",
                attrs: { errors: _vm.errors, errorKey: "*" },
              }),
              _vm._v(" "),
              _vm.deliveryAreasFormatted.length > 0
                ? [
                    _c(
                      "table",
                      {
                        staticClass:
                          "table table-sm table-striped border-bottom",
                      },
                      [
                        _c("thead", { staticClass: "border-bottom" }, [
                          _c("tr", [
                            _c("th", [_vm._v("Nom")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Couleur")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Prix (TTC)")]),
                            _vm._v(" "),
                            !_vm.isTvaDisabled
                              ? _c("th", [_vm._v("TVA")])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("th", [_vm._v("Livraison gratuite")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Montant minimum")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Créneaux")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Actions")]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(
                            _vm.deliveryAreasFormatted.map(
                              (d) => d.deliveryAreaObj
                            ),
                            function (area, index) {
                              return _c("tr", { key: index }, [
                                _c("td", [_vm._v(_vm._s(area.name))]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("div", {
                                    staticStyle: {
                                      height: "20px",
                                      width: "80px",
                                    },
                                    style: `background-color: ${area.drawn_color}`,
                                  }),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        area.price !== null && !area.is_free
                                          ? _vm.formatCurrency(area.price)
                                          : "Gratuite"
                                      ) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                !_vm.isTvaDisabled
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            area.tax_rate !== null &&
                                              !area.is_free
                                              ? `${area.tax_rate} %`
                                              : "--"
                                          ) +
                                          "\n                            "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        area.free_above !== null
                                          ? _vm.formatCurrency(area.free_above)
                                          : "--"
                                      ) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        area.min_amount !== null
                                          ? _vm.formatCurrency(area.min_amount)
                                          : "--"
                                      ) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        area.use_default_delivery_slots
                                          ? "Tous"
                                          : "Limité"
                                      ) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.getTooltip("Éditer"),
                                          expression: "getTooltip('Éditer')",
                                        },
                                      ],
                                      staticClass:
                                        "btn btn-sm btn-success btn-square",
                                      attrs: { disabled: !_vm.editable },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showAddDeliveryAreaModal(
                                            index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("feather", {
                                        attrs: { type: "edit" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.getTooltip("Supprimer"),
                                          expression: "getTooltip('Supprimer')",
                                        },
                                      ],
                                      staticClass:
                                        "btn btn-sm btn-danger btn-square",
                                      attrs: { disabled: !_vm.editable },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteDeliveryArea(
                                            index,
                                            area.name
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("feather", {
                                        attrs: { type: "trash-2" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ])
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.addDeliveryArea.showAddDeliveryAreaModal
                ? _c("addClickAndCollectDeliveryModal", {
                    attrs: {
                      deliveryArea: _vm.addDeliveryArea.deliveryArea,
                      do_not_save: true,
                    },
                    on: {
                      saved: _vm.createNewArea,
                      close: function ($event) {
                        _vm.addDeliveryArea.showAddDeliveryAreaModal = false
                      },
                    },
                  })
                : _vm._e(),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            attrs: { type: "button" },
            on: { click: _vm.close },
          },
          [_vm._v("Annuler")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-success btn-circle ml-2",
            attrs: { type: "button" },
            on: { click: _vm.saveAreas },
          },
          [_vm._v("Enregistrer")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }