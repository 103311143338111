<template>
    <div>
        <div class="row mb-2 pointer" @click="canShow = !canShow">
            <div class="col-12 d-flex justify-content-between">
                <div>
                    <strong>Liste des sms</strong>
                </div>
            </div>
        </div>
        <div v-show="canShow" class="row mx-auto" :class="{ 'justify-content-center': isLoading }">
            <div class="w-100">
                <!-- filters -->
                <div class="advance-filter">
                    <div class="m-0" style="color: #666666">
                        <div class="border-light b-radius-20 p-4 mb-3">
                            <div class="row">
                                <div class="col-12 d-flex align-items-center date-resa-cal">
                                    <div class="d-flex">
                                        <datepicker class="d-inline-block mr-2" v-model="from" :disabled-dates="disabledDatesFrom" />
                                        <datepicker class="d-inline-block mx-2" v-model="to" :disabled-dates="disabledDatesTo" />
                                        <tel-input-component
                                            ref="telInputComponentSmsList"
                                            class="mx-2"
                                            v-model="phone.tel"
                                            @set-tel-country="phone.telCountry = $event"
                                            inputName="tel"
                                            :placeholder="$t('labels.form.phoneNumber')" />
                                    </div>
                                    <button type="button" :disabled="isDisabled" class="btn btn-sm btn-success btn-circle ml-2" @click="getSmsList">
                                        Filtrer
                                    </button>
                                    <button
                                        type="button"
                                        :disabled="isDisabled"
                                        class="btn btn-sm btn-secondary btn-circle ml-2"
                                        @click="reinitialize">
                                        Réinitialiser
                                    </button>
                                </div>
                                <div class="col-12 mt-1">
                                    <small><i>Remarque: la période entre la date de début et de fin ne peut pas excéder 30 jours</i></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <loader-component v-if="isLoading" />
                <div v-else-if="!hasSmsList">
                    <p class="text-danger">Impossible de récupérer les sms pour cet utilisateur</p>
                </div>
                <ns-table v-else tableClass="table table-sm table-striped border-bottom" allow-paging :pagination="pagination" :data-source="smsRows">
                    <template #head>
                        <ns-th field="date" allow-sorting :sort-comparer="dateTimeCompareFn"> Date d'envoi </ns-th>
                        <ns-th field="tel"> Téléphone </ns-th>
                        <ns-th field="content"> Contenu du sms </ns-th>
                        <ns-th field="nbCredit"> Coûts (crédits) </ns-th>
                        <ns-th field="status" allow-sorting> Statut </ns-th>
                    </template>
                    <template #body="{ data }">
                        <td>
                            {{ formatDate(data.date) }}
                        </td>
                        <td>
                            <tel-input-component class="mx-2" v-model="data.tel" disabled />
                        </td>
                        <td>
                            <p v-html="parseLink(data.content)"></p>
                        </td>
                        <td>
                            {{ data.nbCredit }}
                        </td>
                        <td :class="getStatusClasses(data.status)">
                            {{ getStatusLabel(data.status) }}
                        </td>
                    </template>
                </ns-table>
            </div>
        </div>
    </div>
</template>
<script>
import LoaderComponent from "../LoaderComponent.vue";
import NsTable from "../Datatable/NsTable.vue";
import NsTh from "../Datatable/NsTh.vue";
import { DateTime } from "luxon";
import Datepicker from "../../components/forms/DatePicker.vue";
import TelInputComponent from "../../components/forms/TelInputComponent.vue";

export default {
    components: {
        NsTable,
        NsTh,
        LoaderComponent,
        Datepicker,
        TelInputComponent,
    },
    props: {
        ownerDetails: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            isDisabled: true,
            isLoading: true,
            canShow: false,
            smsRows: [],
            hasSmsList: true,
            from: DateTime.now().minus({ days: 10 }),
            to: DateTime.now(),
            tel: null,
            phone: {
                tel: null,
                telCountry: null,
            },
            pagination: {
                isServerSide: true,
                count: 0, // count
                current_page: 1, // page
                per_page: 10, // pageSize
                total: 0, // totalCount
                total_pages: 1, // Not given by the api so => total_pages / per_page
            },
        };
    },
    computed: {
        startDate() {
            return this.from.toISODate();
        },
        endDate() {
            return this.to.toISODate();
        },
        disabledDatesFrom() {
            const disabledDates = { from: new Date() };

            if (this.to !== null) {
                disabledDates.from = this.to.toJSDateCustom();
            }

            return disabledDates;
        },
        disabledDatesTo() {
            const disabledDates = { from: new Date() };

            if (this.from !== null) {
                disabledDates.to = this.from.toJSDateCustom();
            }

            return disabledDates;
        },
    },
    created() {
        this.defineStatusConstants();
        this.getSmsList();
    },
    methods: {
        parseLink(content) {
            const sentences = content.split(" ");
            const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
            const regex = new RegExp(expression);

            sentences.forEach((value, index) => {
                if (regex.test(value)) {
                    sentences[index] = `<a class="link" href="${value}" target="_blank">${value}</a>`;
                } else {
                    sentences[index] = this.escapeHtml(value);
                }
            });

            return sentences.join(" ");
        },
        formatDate(date) {
            return this.displayDate(DateTime.fromISO(date), this.DATETIME_SHORT);
        },
        dateTimeCompareFn(a, b) {
            const aDate = DateTime.fromISO(a.date);
            const bDate = DateTime.fromISO(b.date);
            return bDate.diff(aDate, "minutes").minutes;
        },
        getStatusLabel(status) {
            const scheduledStatuses = [this.STATUS_SCHEDULED, this.STATUS_EN_ROUTE];

            if (status === this.STATUS_DELIVERED) {
                return "Envoyé";
            } else if (scheduledStatuses.includes(status)) {
                return "En cours d'envoi";
            }
            return "Non envoyé";
        },
        getStatusClasses(status) {
            const scheduledStatuses = [this.STATUS_SCHEDULED, this.STATUS_EN_ROUTE];

            if (status === this.STATUS_DELIVERED) {
                return "text-success";
            } else if (scheduledStatuses.includes(status)) {
                return "text-warning";
            }
            return "text-danger";
        },
        reinitialize() {
            this.from = DateTime.now().minus({ days: 10 });
            this.to = DateTime.now();
            this.$refs.telInputComponentSmsList.resetInput();
            this.phone.tel = null;
            this.phone.telCountry = null;

            this.getSmsList();
        },
        resetData() {
            this.hasSmsList = true;
            this.smsRows = [];
            this.pagination.count = 0;
            this.pagination.current_page = 1;
            this.pagination.per_page = 10;
            this.pagination.total = 0;
            this.pagination.total_pages = 1;
        },
        updatePagination({ page, pageSize, count, totalCount }) {
            this.pagination.current_page = page;
            this.pagination.per_page = pageSize;
            this.pagination.count = count;
            this.pagination.total = totalCount;
            this.pagination.total_pages = Math.ceil(totalCount / pageSize);
        },
        updateSMSRows(items) {
            this.smsRows = items.map((item) => {
                return {
                    date: item.sentDate,
                    content: item.body.text,
                    tel: item.recipient.to,
                    nbCredit: item.price.amount,
                    status: item.status.value,
                };
            });
        },
        getSmsList() {
            this.isLoading = true;
            this.isDisabled = true;
            this.resetData();

            let url = `/api/admin/owners/${this.ownerDetails.id}/sms?startDate=${this.startDate}&endDate=${this.endDate}&page=${this.pagination.current_page}&pageSize=${this.pagination.per_page}`;

            if (this.phone.tel !== null) {
                url = url.concat("&phoneNumber=", this.phone.tel).concat("&phoneCountry=", this.phone.telCountry);
            }

            this.httpGet(url, {
                handleReject: false,
                mustReject: true,
            })
                .then((response) => {
                    const data = response.data.data;

                    if (data instanceof Object && !Array.isArray(data)) {
                        if (data.items instanceof Array) {
                            this.updateSMSRows(data.items);
                        }

                        this.updatePagination(data);
                    }
                })
                .catch((error) => {
                    this.hasSmsList = false;
                })
                .finally(() => {
                    this.isLoading = false;
                    this.isDisabled = false;
                });
        },
        defineStatusConstants() {
            this.STATUS_EN_ROUTE = "ENROUTE";
            this.STATUS_SCHEDULED = "SCHEDULED";
            this.STATUS_UNDELIVERABLE = "UNDELIVERABLE";
            this.STATUS_UNDELIVERED = "UNDELIVERED";
            this.STATUS_DELIVERED = "DELIVERED";
        },
    },
};
</script>
<style>
.form-control.custom-form-control {
    width: auto;
    display: flex;
}
</style>
