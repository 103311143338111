<template>
    <div class="w-100">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2" data-test-id="title-admin_panel">Panel Admin</h5>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <template v-for="(link, index) in links">
                <home-card
                    class="col-md-2 mb-3"
                    v-if="link.if === undefined || (typeof link.if === 'function' && link.if()) || link.if === true"
                    :key="index"
                    @click="$router.push({ name: link.routeName })"
                    :link="link" />
            </template>
        </div>
    </div>
</template>

<script>
import HomeCard from "../../components/Admin/HomeCard.vue";

export default {
    data() {
        return {
            links: [
                {
                    name: "Comptes clients",
                    icon: "users",
                    routeName: "admin.owners",
                },
                {
                    name: "Contrats",
                    icon: "archive",
                    routeName: "admin.stats_subscriptions",
                    if: () => this.isNoShow,
                },
                {
                    name: "Statistiques",
                    icon: "bar-chart-2",
                    routeName: "admin.stats_services",
                },
                {
                    name: "Factures",
                    icon: "file-text",
                    routeName: "admin.invoices",
                    if: () => this.isNoShow,
                },
                {
                    name: "Campagnes",
                    icon: "mail",
                    routeName: "admin.campaigns",
                    if: () => this.isNoShow,
                },
                {
                    name: "Nouveautés",
                    icon: "gift",
                    routeName: "admin.new_features",
                },
                {
                    name: "Profil",
                    icon: "user",
                    routeName: "account",
                },
                {
                    name: "Archives",
                    icon: "trash-2",
                    routeName: "admin.archive",
                },
                {
                    name: "Vidéos",
                    icon: "film",
                    routeName: "admin.videos.list",
                    if: () => this.isNoShow,
                },
                {
                    name: "Notes de mises à jour",
                    rawIcon: `<svg version="1.1" stroke-width="0" fill="currentColor" width="40" height="40" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <path d="m913.84 399.6 0.61328-319.37-116.74-0.074219-22.5 32.555c-70.32 102.23-150.04 218.09-274.7 218.44l-35.16-0.14453 0.17969-41.027-284.03-1.3203-0.44531 97.969-117.56-0.63672-1.1641 245.1 117.6 0.60156-0.44531 97.535 47.316 0.21484 104.14 390.41 141.09-48.73-57.215-340.84 48.758 0.21484 0.22656-51.277 34.559 0.058594c125.5 0.50391 205.3 117.1 275.72 219.97l21.949 31.945 116.79 0.26562 0.66016-342.47c40.02-12.719 69.121-50.09 69.324-94.273 0.17578-44.422-28.805-82.176-68.957-95.121zm-791.22 171.79 0.60156-125.07 57.574 0.3125-0.57422 125.06zm730.31 299.93-25.297-0.058593-4.043-5.9023c-74.855-109.34-168.01-245.44-325.05-246.07l-34.465-0.058593 1.0195-228.21 35.387 0.14453c156.3-0.43359 249.38-135.72 324.17-244.43l4.5234-6.5391 25.176 0.023438z"/>
                                        <path d="m1035.7 464.26h101.91v60h-101.91z"/>
                                        <path d="m992.95 255.69 103.12-61.875 30.871 51.449-103.12 61.875z"/>
                                        <path d="m992.95 732.8 30.871-51.449 103.12 61.875-30.871 51.449z"/>
                                    </g>
                                </svg>`,
                    routeName: "admin.app_updates.list",
                },
                {
                    name: "Codes Promos",
                    icon: "percent",
                    routeName: "admin.promo_codes.list",
                    if: () => this.isNoShow,
                    rights: ["admin.promocodes.read"],
                },
            ],
        };
    },
    components: {
        HomeCard,
    },
};
</script>
