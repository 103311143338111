<template>
    <modal class="modal-add-zone" @close="closeFromModal">
        <h5 slot="header">
            {{ $tl("labels.areasEstablishments.createAreaTitle") }}
        </h5>
        <div slot="body">
            <div class="row mb-4">
                <div class="col-5">
                    <label for="name">{{ $tl("labels.areasEstablishments.areaName") }} <small>*</small> </label>
                </div>
                <div class="col-md-7">
                    <input class="form-control" type="text" name="name" id="name" v-model="zoneName" required />
                </div>
            </div>
            <div class="row">
                <div class="container col-12">
                    <restaurant-list
                        :restaurants="restaurants"
                        :selectedRestaurants="selectedRestaurants"
                        @update:selected-restaurants="updateSelectedRestaurants" />
                </div>
            </div>
        </div>
        <div slot="footer">
            <button type="button" class="btn btn-sm btn-secondary btn-circle" @click.prevent="$emit('close')">
                {{ $tl("labels.form.actions.cancel") }}
            </button>
            <button type="button" class="btn btn-sm btn-primary btn-circle ml-2" @click="createZone">
                {{ $tl("labels.areasEstablishments.create") }}
            </button>
        </div>
    </modal>
</template>

<script>
import RestaurantList from "./RestaurantList.vue";

export default {
    name: "addZoneModal",
    props: {
        restaurants: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            zoneName: "",
            selectedRestaurants: [],
        };
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal(event) {
            if (event.target && event.target.classList.contains("modal-wrapper")) {
                return this.close();
            }
        },
        createZone() {
            this.$emit("create-zone", {
                name: this.zoneName,
                restaurants: this.selectedRestaurants,
            });
        },
        updateSelectedRestaurants(restaurants) {
            this.selectedRestaurants = restaurants;
        },
    },
    components: { RestaurantList },
};
</script>
