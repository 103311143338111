<template>
    <div class="default-home">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">{{ $tl("labels.routes.sms") }}</h5>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-12">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <div>
                        <strong>{{ $tl("infos.sms.stats.title") }}</strong>
                        <br /><br />
                        - {{ $tl("infos.sms.stats.openingRate.title") }}
                        <span class="value">{{ $t("infos.sms.stats.openingRate.value", { percentSms: 95, percentEmail: 20 }) }}</span
                        ><br />
                        - {{ $tl("infos.sms.stats.readTimeDelay.title") }}
                        <span class="value">{{ $t("infos.sms.stats.readTimeDelay.value", { timeSms: 4, timeEmail: 90 }) }}</span
                        ><br />
                        - {{ $tl("infos.sms.stats.spam.title") }}
                        <span class="value">{{ $t("infos.sms.stats.spam.value", { percentSms: 0, percentEmail: 80 }) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <loader-component v-if="loadingSmsMode" />
        <div v-else>
            <template v-if="smsActivated">
                <sms-pack></sms-pack>
            </template>
            <div class="row m-0" v-else>
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <div class="text-center">
                            <template v-if="isNoShow">
                                <p>
                                    {{ $t("infos.sms.freeWhenEnabled", { nb: 100 }) }}<br />
                                    <i class="text-muted">{{ $tl("infos.sms.configNotifs") }}</i>
                                </p>
                            </template>
                            <p v-else>
                                {{ $tl("infos.sms.configNotifs") }}
                            </p>
                            <button type="button" class="btn btn-sm btn-circle btn-success" :disabled="!isOwner" @click="activateSms">
                                {{ $tl("labels.form.sms.enable") }}
                            </button>
                            <span class="text-danger d-block" v-if="!isOwner">{{ $tl("labels.form.sms.notEnoughRights") }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../components/LoaderComponent.vue";
import SmsPack from "../components/sms/ListSmsPacks.vue";
import RolesEnum from "../mixins/enums/RolesEnum.js";

export default {
    name: "ListSmsPacks",
    data() {
        return {
            loadingSmsMode: true,
            smsActivated: null,
        };
    },
    mixins: [RolesEnum],
    computed: {
        role() {
            return this.$store.getters["users/role"];
        },
        isOwner() {
            return this.role === this.ROLE_OWNER.value;
        },
    },
    methods: {
        fetchData() {
            this.fetchIfSmsActivated();
        },
        fetchIfSmsActivated() {
            this.loadingSmsMode = true;

            this.httpGet("/api/sms/activation")
                .then((response) => {
                    if (response !== false) {
                        this.smsActivated = response.data.isActivated;
                    }
                })
                .finally(() => (this.loadingSmsMode = false));
        },
        activateSms() {
            this.loadingSmsMode = true;

            this.httpPost("/api/sms/activation", {}, { handleResolve: false }).then((response) => {
                if (response !== false) {
                    location.reload();
                } else {
                    this.loadingSmsMode = false;
                }
            });
        },
    },
    created() {
        this.fetchData();
    },
    components: {
        SmsPack,
        LoaderComponent,
    },
};
</script>
