<template>
    <form data-test-id="form-register" class="form-register align-middle" @submit.prevent="register">
        <div>
            <img class="mb-4 mt-2" width="200" src="/images/logo-lead.png" alt="No Show" />

            <div class="d-flex justify-content-between">
                <h5 data-test-id="title-register_page">
                    {{ $tl("labels.form.register.title") }}
                </h5>
                <ChooseLang ref="chooseLang" @lang-changed="user.lang = $event" />
            </div>

            <div v-if="isNoShow">
                {{ $t("infos.register.welcome1") }}<br />
                {{ $t("infos.register.welcome2") }}
            </div>
        </div>

        <loader-component v-if="loading" />
        <div v-else class="mt-4">
            <div v-if="errorMessage" class="alert-danger alert">
                {{ errorMessage }}
            </div>
            <div class="form-group mb-1 row">
                <label for="firstname" class="col-md-6 col-form-label">
                    {{ $tl("labels.form.firstname") }} <small>*</small>
                    <input
                        id="firstname"
                        type="text"
                        data-test-id="input-txt-firstname"
                        class="mt-2 form-control"
                        :class="{ 'is-invalid': hasErrors('firstname') }"
                        name="firstname"
                        v-model="user.firstname"
                        required
                        autofocus />

                    <div v-if="hasErrors('firstname')" class="invalid-feedback" role="alert" v-html="joinErrors('firstname')"></div>
                </label>

                <label for="lastname" class="col-md-6 col-form-label">
                    {{ $tl("labels.form.lastname") }} <small>*</small>
                    <input
                        id="lastname"
                        type="text"
                        data-test-id="input-txt-lastname"
                        class="mt-2 form-control"
                        :class="{ 'is-invalid': hasErrors('lastname') }"
                        v-model="user.lastname"
                        name="lastname"
                        required />

                    <div v-if="hasErrors('lastname')" class="invalid-feedback" role="alert" v-html="joinErrors('lastname')"></div>
                </label>
            </div>

            <div class="form-group mb-1 row">
                <label for="email" class="col-md-6 col-form-label">
                    {{ $tl("labels.form.emailBusiness") }} <small>*</small>
                    <input
                        id="email"
                        type="email"
                        data-test-id="input-txt-email"
                        class="mt-2 form-control"
                        :class="{ 'is-invalid': hasErrors('email') }"
                        v-model="user.email"
                        name="email"
                        required />

                    <div
                        data-test-id="txt-error-email"
                        v-if="hasErrors('email')"
                        class="invalid-feedback"
                        role="alert"
                        v-html="joinErrors('email')"></div>
                </label>
                <label for="tel" class="col-md-6 col-form-label">
                    {{ $tl("labels.form.portalPhoneNumber") }} <small>*</small>
                    <input
                        id="tel"
                        type="text"
                        data-test-id="input-txt-tel"
                        class="mt-2 form-control"
                        :class="{ 'is-invalid': hasErrors('tel') }"
                        name="tel"
                        v-model="user.tel"
                        required />

                    <div v-if="hasErrors('tel')" class="invalid-feedback" role="alert" v-html="joinErrors('tel')"></div>
                </label>
            </div>

            <div class="form-group mb-1 row">
                <label for="password" class="col-md-6 col-form-label">
                    {{ $tl("labels.form.password") }} <small>*</small>
                    <PasswordInputComponent class="mt-2" v-model="user.password" dataTestId="input-txt-password" />
                    <div v-if="hasErrors('password')" class="invalid-feedback" role="alert" v-html="joinErrors('password')"></div>
                </label>
                <label for="password-confirm" class="col-md-6 col-form-label">
                    {{ $tl("labels.form.passwordConfirm") }} <small>*</small>
                    <PasswordConfirmationInputComponent
                        class="mt-2"
                        dataTestId="input-txt-password_confirm"
                        :mainPassword="user.password"
                        v-model="user.password_confirmation" />
                    <div v-if="hasErrors('password-confirm')" class="invalid-feedback" role="alert" v-html="joinErrors('password-confirm')"></div>
                </label>
            </div>

            <div class="form-group pl-3 mb-2 mt-3 row">
                <label class="col-md-12 container-box" :class="{ 'is-invalid': hasErrors('accept_cgv') }" style="width: 100%">
                    <input type="checkbox" id="accept_cgv" data-test-id="input-chk-accept_cgv" name="accept_cgv" v-model="user.accept_cgv" required />
                    <span class="checkmark"></span> {{ $t("labels.form.register.CGU1") }}
                    <a class="alert-link" :href="`/cgu_global.pdf?v=${$__BUILD_ID__}`" target="_blank">{{ $t("labels.form.register.CGU2") }}</a>
                    <small>*</small>
                    <div v-if="hasErrors('accept_cgv')" class="invalid-feedback" role="alert" v-html="joinErrors('accept_cgv')"></div>
                </label>
            </div>

            <div class="form-group pl-3 mb-2 mt-3 row">
                <label class="col-md-12 container-box" :class="{ 'is-invalid': hasErrors('accept_data_processing') }" style="width: 100%">
                    <input
                        type="checkbox"
                        id="accept_data_processing"
                        data-test-id="input-chk-accept_data_processing"
                        name="accept_data_processing"
                        v-model="user.accept_data_processing"
                        required />
                    <span class="checkmark"></span>{{ $t("labels.form.register.PCDP1") }}
                    <a class="alert-link" :href="`/datapolicy.pdf?v=${$__BUILD_ID__}`" target="_blank">{{ $tl("labels.rgpd.pcdp") }}</a
                    >.
                    <small>*</small>
                    <div
                        v-if="hasErrors('accept_data_processing')"
                        class="invalid-feedback"
                        role="alert"
                        v-html="joinErrors('accept_data_processing')"></div>
                </label>
            </div>

            <div class="form-group row">
                <div class="col-md-12">
                    <button data-test-id="btn-register" type="submit" class="btn btn-success btn-circle">
                        {{ $tl("labels.form.register.button") }}
                    </button>
                    <router-link data-test-id="lnk-login_page" class="ml-2" :to="{ name: 'public.auth.login' }">
                        {{ $tl("labels.form.register.goToLoginPage") }}
                    </router-link>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent.vue";
import PasswordInputComponent from "../../components/forms/PasswordInputComponent.vue";
import PasswordConfirmationInputComponent from "../../components/forms/PasswordConfirmationInputComponent.vue";
import ChooseLang from "../../components/Default/ChooseLang.vue";

export default {
    name: "AuthRegister",
    data() {
        return {
            loading: false,
            errorMessage: null,
            errors: null,
            user: {
                firstname: "",
                lastname: "",
                email: "",
                tel: "",
                lang: undefined,
                password: "",
                password_confirmation: "",
                accept_cgv: false,
                accept_data_processing: false,
            },
        };
    },
    computed: {
        isPasswordSecured() {
            return !this.user.password ? true : /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[?{}[\]\^!@#&%\*])(?=.{8,})/.test(this.user.password);
        },
    },
    methods: {
        setUserLang(newLang) {
            this.setUserGlobalLang(newLang);
            this.user.lang = newLang;
        },
        register() {
            this.loading = true;
            this.$store
                .dispatch("auth/register", {
                    user: {
                        ...this.user,
                        lang: this.userLang,
                    },
                })
                .then(() => {
                    window.location.href = "/";
                })
                .catch((error) => {
                    this.errorMessage = this.getErrorMsgFromErrorResponse(error);

                    if (error.response && error.response.data.errors) {
                        this.errors = error.response.data.errors;
                    }

                    this.password = "";
                    this.password_confirmation = "";
                    this.loading = false;
                });
        },
        hasErrors(key) {
            return this.errors !== null && this.errors[key] && this.errors[key].length > 0;
        },
        joinErrors(key) {
            return this.errors[key].join("<br/>");
        },
    },
    components: {
        LoaderComponent,
        PasswordInputComponent,
        PasswordConfirmationInputComponent,
        ChooseLang,
    },
    mounted() {
        this.user.lang = this.$refs.chooseLang.userLang;
    },
};
</script>
