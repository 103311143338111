var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-12 pointer",
              on: {
                click: function ($event) {
                  _vm.showIntegration = !_vm.showIntegration
                },
              },
            },
            [
              _c("strong", [
                _vm._v(
                  _vm._s(_vm.$tl("infos.widget.instagramIntegration.title"))
                ),
              ]),
              _vm.badgeUUID !== null
                ? _c("showNewBadgeDetails", {
                    attrs: { badge_uuid: _vm.badgeUUID },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.showIntegration
          ? _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-5" }, [
                    _c("p", [_vm._v(_vm._s(_vm.$tl("infos.widget.subtitle")))]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$t("labels.stepX", { step: 1 })) +
                          _vm._s(_vm.$tl("labels.colon"))
                      ),
                    ]),
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.$t("infos.widget.instagramIntegration.step1")
                        ) +
                        "\n                            "
                    ),
                    _c("p", { staticClass: "mt-1 mb-3" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("infos.widget.facebookIntegration.content1")
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$t("labels.stepX", { step: 2 })) +
                          _vm._s(_vm.$tl("labels.colon"))
                      ),
                    ]),
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.$t("infos.widget.instagramIntegration.step2")
                        ) +
                        "\n                            "
                    ),
                    _c("p", { staticClass: "mt-1" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("infos.widget.instagramIntegration.content2")
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "w-25 mb-3",
                      attrs: {
                        src: "/images/instagram_step1.png",
                        alt: "Clique sur le logo mon compte",
                      },
                    }),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("infos.widget.instagramIntegration.content3")
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "w-25 mb-3",
                      attrs: {
                        src: "/images/instagram_step2.png",
                        alt: "Clique sur le bouton modifier profil",
                      },
                    }),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("infos.widget.instagramIntegration.content4")
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "w-25 mb-3",
                      attrs: {
                        src: "/images/instagram_step3.png",
                        alt: "Coller le lien dans la case site web",
                      },
                    }),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }