import axios from "axios";
import store from "../../store/store.js";
import router from "../../routes";

function handlePaymentsErrors(response) {
    store.commit("errors/setResponse", response);

    if (response && response.data && response.data.message && response.data.message.startsWith("licences.")) {
        if (router.currentRoute.name !== "errors.licences") {
            router.push({
                name: "errors.licences",
                params: {
                    ...router.currentRoute.params,
                    public: router.currentRoute.meta && router.currentRoute.meta.public ? router.currentRoute.meta.public : false,
                },
            });
        }

        return;
    }

    store.commit("errors/setResponse", null);
}

function handleAuthErrors(response) {
    if (response && response.data && response.data.message) {
        switch (response.data.message) {
            case "auth.required":
                if (router.currentRoute.name !== "public.auth.login") {
                    router.push({ name: "public.auth.login", query: { redirect: window.location.href } });
                }
                break;
        }
    }
}

function handleAppVersionErrors(response) {
    document.location.reload();
}

function handleInMaintenance(response) {
    document.location.reload();
}

export default function setup() {
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (err) {
            if (err && err.response) {
                switch (err.response.status) {
                    case 402:
                        handlePaymentsErrors(err.response);
                        break;
                    case 401:
                        handleAuthErrors(err.response);
                        break;
                    case 410:
                        handleAppVersionErrors(err.response);
                        break;
                    case 503:
                        handleInMaintenance(err.response);
                        break;
                    default:
                        break;
                }
            }

            return Promise.reject(err);
        }
    );
}
