<template>
    <div>
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <div>
                        <h5 class="title mt-2">Contrats</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-12">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <ul class="settings-menu nav nav-tabs flex-row top mb-2" style="position: relative; top: 0">
                        <li class="nav-item" v-for="(tab, index) in tabs" :key="index" @click="currentTab = index">
                            <a class="nav-link" :class="{ 'router-link-active': index === currentTab }" style="color: #6c757d">
                                {{ tab.label }}
                            </a>
                        </li>
                    </ul>
                    <keep-alive>
                        <component :is="currentComponent" />
                    </keep-alive>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ShowSubscriptionsCA from "../../components/Admin/ShowSubscriptionsCA";
import ShowSubscriptionsCanceled from "../../components/Admin/ShowSubscriptionsCanceled";
import ShowInvoicesSmsPacks from "../../components/Admin/ShowInvoicesSmsPacks";
import ShowInvoicesCampaigns from "../../components/Admin/ShowInvoicesCampaigns";
import ShowSubscriptionDiscounts from "../../components/Admin/ShowSubscriptionDiscounts";
import ShowSubscriptionsByModule from "../../components/Admin/ShowSubscriptionsByModule.vue";

export default {
    data() {
        return {
            currentTab: 0,
            tabs: [
                {
                    label: "Licences",
                    component: "ShowSubscriptionsCA",
                },
                {
                    label: "Licences par module",
                    component: "ShowSubscriptionsByModule",
                },
                {
                    label: "Désinscriptions",
                    component: "ShowSubscriptionsCanceled",
                },
                {
                    label: "Packs SMS",
                    component: "ShowInvoicesSmsPacks",
                },
                {
                    label: "Campagnes",
                    component: "ShowInvoicesCampaigns",
                },
                {
                    label: "Remises",
                    component: "ShowSubscriptionDiscounts",
                },
            ],
        };
    },
    computed: {
        currentComponent() {
            if (this.currentTab >= this.tabs.length || this.currentTab < 0) return "undefined";
            return this.tabs[this.currentTab].component;
        },
    },
    components: {
        ShowSubscriptionsCA,
        ShowSubscriptionsCanceled,
        ShowInvoicesSmsPacks,
        ShowInvoicesCampaigns,
        ShowSubscriptionDiscounts,
        ShowSubscriptionsByModule,
    },
};
</script>
