var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.txtAreaObj[_vm.txtAreaObjKey],
          expression: "txtAreaObj[txtAreaObjKey]",
        },
      ],
      staticClass: "form-control",
      attrs: {
        disabled: _vm.disabled,
        id: _vm.txtAreaId,
        rows: _vm.txtAreaRows,
      },
      domProps: { value: _vm.txtAreaObj[_vm.txtAreaObjKey] },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.$set(_vm.txtAreaObj, _vm.txtAreaObjKey, $event.target.value)
        },
      },
    }),
    _vm._v(" "),
    _c("div", [
      _vm.minNbChar !== null
        ? _c("small", [_vm._v("Min : " + _vm._s(_vm.minNbChar))])
        : _vm._e(),
      _vm._v(" "),
      _vm.maxNbChar !== null
        ? _c("small", [_vm._v("Max : " + _vm._s(_vm.maxNbChar))])
        : _vm._e(),
      _vm._v(" "),
      _vm.minNbChar !== null || _vm.maxNbChar !== null
        ? _c(
            "small",
            { class: "d-block font-weight-bold " + _vm.getContentCounterClass },
            [
              _vm._v(
                "Actuel : " +
                  _vm._s(
                    _vm.txtAreaObj[_vm.txtAreaObjKey] !== null
                      ? _vm.txtAreaObj[_vm.txtAreaObjKey].length
                      : 0
                  ) +
                  " caractères"
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }