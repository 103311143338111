var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5" },
    [
      _vm.loading
        ? _c("loader-component")
        : _c("div", { staticClass: "default-home" }, [
            _c("div", { staticClass: "row m-0" }, [
              _c(
                "div",
                {
                  staticClass: "col-12",
                  class: { "d-flex": _vm.showBackButton },
                },
                [
                  _vm.showBackButton
                    ? _c(
                        "button",
                        {
                          staticClass: "btn d-flex pl-0",
                          on: {
                            click: function ($event) {
                              return _vm.$router.go(-1)
                            },
                          },
                        },
                        [
                          _c("feather", {
                            staticClass: "feather-24",
                            attrs: { type: "chevron-left" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                    },
                    [
                      !_vm.restaurant_id
                        ? _c("h5", { staticClass: "title mt-2" }, [
                            _vm._v(
                              _vm._s(_vm.$tl("labels.routes.addRestaurant"))
                            ),
                          ])
                        : _c("h5", { staticClass: "title mt-2" }, [
                            _vm._v(_vm._s(_vm.restaurant.name)),
                          ]),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                ])
              : _c("div", [
                  _vm.userRole === "owner"
                    ? _c("div", [
                        _c("div", { staticClass: "row m-0" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "border-light b-radius-20 p-4 mb-3",
                              },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-12 mb-3" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.$tl("labels.licences"))
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  _vm._l(
                                    _vm.ALL_MODULES_TYPES,
                                    function (MODULE_TYPE) {
                                      return _c(
                                        "div",
                                        {
                                          key: MODULE_TYPE.value,
                                          staticClass: "col-3 p-2",
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getModuleTypeLabel(
                                                  MODULE_TYPE.value
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.licences[
                                                      MODULE_TYPE.value
                                                    ],
                                                  expression:
                                                    "licences[MODULE_TYPE.value]",
                                                },
                                              ],
                                              staticClass: "custom-select m-0",
                                              attrs: {
                                                disabled:
                                                  !_vm.has_right_to_update_subscription,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    _vm.licences,
                                                    MODULE_TYPE.value,
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  attrs: { disabled: true },
                                                  domProps: { value: null },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "labels.form.licences.assign"
                                                        )
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.getAvailableSubscriptions(
                                                  MODULE_TYPE.value
                                                ),
                                                function (licence, index) {
                                                  return [
                                                    licence.restaurant_id ==
                                                      _vm.restaurant_id ||
                                                    !licence.restaurant_id
                                                      ? _c(
                                                          "option",
                                                          {
                                                            key: index,
                                                            domProps: {
                                                              value: licence.id,
                                                              selected:
                                                                licence.id ==
                                                                _vm.licences[
                                                                  MODULE_TYPE
                                                                    .value
                                                                ],
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  _vm.$tl(
                                                                    "labels.licence"
                                                                  )
                                                                ) +
                                                                "\n                                                " +
                                                                _vm._s(
                                                                  _vm.getModulePlanTypeLabel(
                                                                    licence.plan
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  index + 1
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4 mb-3" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12 mb-3" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.$tl("labels.visualIdentity"))
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-5 mb-3" }, [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.$tl("labels.form.logo"))),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-7" },
                                [
                                  _c("ImageComponent", {
                                    attrs: {
                                      src: _vm.restaurant.logo.url,
                                      disabled: !_vm.hasRightToEdit,
                                      upload: false,
                                      maxSize: 10,
                                      suffixId: "logo",
                                    },
                                    on: {
                                      "new-file": function ($event) {
                                        _vm.restaurant.logo.file = $event
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("ShowErrors", {
                                    staticClass: "d-block",
                                    attrs: {
                                      errors: _vm.errors.form,
                                      "error-key": "logo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-3" }, [
                              _c("div", { staticClass: "col-md-5 pt-2" }, [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.$tl("labels.form.color"))),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7 pt-2" },
                                [
                                  _vm.hasRightToEdit
                                    ? _c("chrome-picker", {
                                        model: {
                                          value: _vm.restaurant.widget_color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.restaurant,
                                              "widget_color",
                                              $$v
                                            )
                                          },
                                          expression: "restaurant.widget_color",
                                        },
                                      })
                                    : _c(
                                        "span",
                                        {
                                          staticClass: "p-1",
                                          style: `background-color: ${_vm.restaurant.widget_color}`,
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.restaurant.widget_color
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-5 pt-2" }, [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.form.restaurant.titleFont"
                                      )
                                    ) + " "
                                  ),
                                  _c("small", [_vm._v("*")]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-md-7 d-flex align-items-center",
                                },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.restaurant.widget_font_title,
                                          expression:
                                            "restaurant.widget_font_title",
                                        },
                                      ],
                                      staticClass: "custom-select w-auto m-0",
                                      attrs: { disabled: !_vm.hasRightToEdit },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.restaurant,
                                            "widget_font_title",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(
                                      _vm.availableFontsTitle,
                                      function (font) {
                                        return _c(
                                          "option",
                                          {
                                            key: font.value,
                                            domProps: { value: font.value },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(font.name) +
                                                "\n                                        "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "ml-2 font-previsu",
                                      style:
                                        "font-family: " +
                                        _vm.restaurant.widget_font_title,
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.restaurant.name ||
                                            _vm.$tl("labels.reservation")
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      attrs: { id: "editRestaurant", method: "post" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.postForm.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "row m-0" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c(
                              "div",
                              { staticClass: "border-light b-radius-20 p-4" },
                              [
                                _vm.formLoading
                                  ? _c("loader-component")
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.formLoading,
                                        expression: "!formLoading",
                                      },
                                    ],
                                  },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-12 mb-3" },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(_vm.$tl("labels.infos"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("InputComponent", {
                                      attrs: {
                                        required: "",
                                        label: _vm.$t(
                                          "labels.form.restaurant.name"
                                        ),
                                        inputName: "name",
                                        disabled: !_vm.hasRightToEdit,
                                        "form-errors": _vm.errors.form,
                                      },
                                      model: {
                                        value: _vm.restaurant.name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.restaurant, "name", $$v)
                                        },
                                        expression: "restaurant.name",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-5 pt-2" },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$tl("labels.form.address")
                                              ) +
                                              " "
                                          ),
                                          _c("small", [_vm._v("*")]),
                                          _vm._v(" "),
                                          !_vm.hasFillGoogleMapsAddress
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "text-warn d-block mt-2",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "infos.restaurants.newAddressComponent"
                                                        )
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-7" },
                                        [
                                          _c("postal-address-autocomplete", {
                                            attrs: {
                                              disabled: !_vm.hasRightToEdit,
                                              "search-address-type": false,
                                            },
                                            model: {
                                              value:
                                                _vm.restaurant
                                                  .google_maps_address,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.restaurant,
                                                  "google_maps_address",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "restaurant.google_maps_address",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            staticClass: "d-block",
                                            attrs: {
                                              errors: _vm.errors.form,
                                              "error-key":
                                                "google_maps_address",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("InputComponent", {
                                      attrs: {
                                        label: _vm.$t(
                                          "labels.form.addressComp"
                                        ),
                                        notice:
                                          "notices.restaurants.addressComp",
                                        inputName: "further_address_details",
                                        "form-errors": _vm.errors.form,
                                      },
                                      model: {
                                        value:
                                          _vm.restaurant
                                            .further_address_details,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.restaurant,
                                            "further_address_details",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "restaurant.further_address_details",
                                      },
                                    }),
                                    _vm._v(" "),
                                    !_vm.hasFillGoogleMapsAddress
                                      ? [
                                          _c("InputComponent", {
                                            attrs: {
                                              required: "",
                                              label: _vm.$t(
                                                "labels.form.address"
                                              ),
                                              inputName: "address",
                                              disabled: "",
                                              "form-errors": _vm.errors.form,
                                            },
                                            model: {
                                              value: _vm.restaurant.address,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.restaurant,
                                                  "address",
                                                  $$v
                                                )
                                              },
                                              expression: "restaurant.address",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("InputComponent", {
                                            attrs: {
                                              label: _vm.$t(
                                                "labels.form.addressComp"
                                              ),
                                              inputName:
                                                "further_address_details",
                                              disabled: "",
                                              "form-errors": _vm.errors.form,
                                            },
                                            model: {
                                              value:
                                                _vm.restaurant
                                                  .further_address_details,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.restaurant,
                                                  "further_address_details",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "restaurant.further_address_details",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("InputComponent", {
                                            attrs: {
                                              required: "",
                                              label: _vm.$t(
                                                "labels.form.postalCode"
                                              ),
                                              inputName: "zipcode",
                                              maxlength: "5",
                                              disabled: "",
                                              "form-errors": _vm.errors.form,
                                            },
                                            model: {
                                              value: _vm.restaurant.zipcode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.restaurant,
                                                  "zipcode",
                                                  $$v
                                                )
                                              },
                                              expression: "restaurant.zipcode",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("InputComponent", {
                                            attrs: {
                                              required: "",
                                              label: _vm.$t("labels.form.city"),
                                              inputName: "city",
                                              disabled: "",
                                              "form-errors": _vm.errors.form,
                                            },
                                            model: {
                                              value: _vm.restaurant.city,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.restaurant,
                                                  "city",
                                                  $$v
                                                )
                                              },
                                              expression: "restaurant.city",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("InputComponent", {
                                            attrs: {
                                              required: "",
                                              label: _vm.$t(
                                                "labels.form.country"
                                              ),
                                              inputName: "country",
                                              disabled: "",
                                              "form-errors": _vm.errors.form,
                                            },
                                            model: {
                                              value: _vm.restaurant.country,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.restaurant,
                                                  "country",
                                                  $$v
                                                )
                                              },
                                              expression: "restaurant.country",
                                            },
                                          }),
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c("div", { staticClass: "col-5 pt-2" }, [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$tl("labels.form.phoneNumber")
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-7" },
                                        [
                                          _c("TelInputComponent", {
                                            attrs: {
                                              inputName: "tel",
                                              disabled: !_vm.hasRightToEdit,
                                              "form-errors": _vm.errors.form,
                                              placeholder: _vm.$t(
                                                "labels.form.phoneNumber"
                                              ),
                                            },
                                            on: {
                                              "set-tel-country": function (
                                                $event
                                              ) {
                                                _vm.restaurant.telCountry =
                                                  $event
                                              },
                                            },
                                            model: {
                                              value: _vm.restaurant.tel,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.restaurant,
                                                  "tel",
                                                  $$v
                                                )
                                              },
                                              expression: "restaurant.tel",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("InputComponent", {
                                      attrs: {
                                        notice:
                                          "notices.booking.restaurant.email",
                                        label: _vm.$t("labels.form.email"),
                                        inputName: "email",
                                        inputType: "email",
                                        disabled: !_vm.hasRightToEdit,
                                        "form-errors": _vm.errors.form,
                                      },
                                      model: {
                                        value: _vm.restaurant.email,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.restaurant, "email", $$v)
                                        },
                                        expression: "restaurant.email",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("InputComponent", {
                                      attrs: {
                                        placeholder: "www.monsite.com",
                                        label: _vm.$t("labels.form.website"),
                                        inputName: "website",
                                        disabled: !_vm.hasRightToEdit,
                                        "form-errors": _vm.errors.form,
                                      },
                                      model: {
                                        value: _vm.restaurant.website,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.restaurant,
                                            "website",
                                            $$v
                                          )
                                        },
                                        expression: "restaurant.website",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "row pt-1 mb-1" },
                                      [
                                        _c("div", { staticClass: "col-5" }, [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.form.restaurant.noTVA"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-7" }, [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "container-box",
                                              staticStyle: { width: "initial" },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.restaurant
                                                        .tva_disabled,
                                                    expression:
                                                      "restaurant.tva_disabled",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "true-value": "1",
                                                  disabled: !_vm.hasRightToEdit,
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.restaurant.tva_disabled
                                                  )
                                                    ? _vm._i(
                                                        _vm.restaurant
                                                          .tva_disabled,
                                                        null
                                                      ) > -1
                                                    : _vm._q(
                                                        _vm.restaurant
                                                          .tva_disabled,
                                                        "1"
                                                      ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.restaurant
                                                          .tva_disabled,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? "1"
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.restaurant,
                                                            "tva_disabled",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.restaurant,
                                                            "tva_disabled",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.restaurant,
                                                        "tva_disabled",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                                class: {
                                                  disabled: !_vm.hasRightToEdit,
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-5 pt-2" },
                                        [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl("labels.timezone")
                                              ) + " "
                                            ),
                                            _c("small", [_vm._v("*")]),
                                          ]),
                                          _vm._v(" "),
                                          _c("ShowNewBadgeDetails", {
                                            attrs: {
                                              badge_uuid:
                                                "8dd53584-2fad-474e-97f1-ffbe45f87b93",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-7" },
                                        [
                                          _c("TimeZonePicker", {
                                            staticClass:
                                              "custom-select w-auto m-0",
                                            attrs: {
                                              disabled: !_vm.hasRightToEdit,
                                              "data-test-id":
                                                "select-txt-timezone",
                                            },
                                            model: {
                                              value: _vm.restaurant.timezone,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.restaurant,
                                                  "timezone",
                                                  $$v
                                                )
                                              },
                                              expression: "restaurant.timezone",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            staticClass: "d-block",
                                            attrs: {
                                              errors: _vm.errors.form,
                                              "error-key": "timezone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "row mb-2 mt-2" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col-5 pt-2" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.form.indicative"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("feather", {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: _vm.getTooltipNotice(
                                                    "notices.defaultTelCountry"
                                                  ),
                                                  expression:
                                                    "getTooltipNotice('notices.defaultTelCountry')",
                                                },
                                              ],
                                              staticClass:
                                                "ml-1 pointer tooltip-infos",
                                              attrs: { type: "info" },
                                            }),
                                            _vm._v(" "),
                                            _c("ShowNewBadgeDetails", {
                                              attrs: {
                                                badge_uuid:
                                                  "1481b527-49c6-462c-a69f-74f0855ce7cd",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "padding-left": "15px",
                                            },
                                          },
                                          [
                                            _c("vue-tel-input", {
                                              staticClass: "form-control p-0",
                                              attrs: {
                                                "data-test-id":
                                                  "input-tel-default_tel_country",
                                                disabled: !_vm.hasRightToEdit,
                                                defaultCountry:
                                                  _vm.restaurant
                                                    .default_tel_country,
                                                inputClasses: "d-none",
                                              },
                                              on: {
                                                "country-changed": (e) =>
                                                  (_vm.restaurant.default_tel_country =
                                                    e.iso2),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "row mb-2 mt-2" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col-5 pt-2" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.form.restaurant.currency"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("feather", {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value:
                                                    _vm.getTooltipNotice(
                                                      "notices.currency"
                                                    ),
                                                  expression:
                                                    "getTooltipNotice('notices.currency')",
                                                },
                                              ],
                                              staticClass:
                                                "ml-1 pointer tooltip-infos",
                                              attrs: { type: "info" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-md-7 d-flex align-items-center",
                                          },
                                          [
                                            _c("choose-currency", {
                                              attrs: { disabled: _vm.hasPsp },
                                              model: {
                                                value: _vm.restaurant.currency,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.restaurant,
                                                    "currency",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "restaurant.currency",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.isYservices
                                      ? [
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2 mt-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-5 pt-2" },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "labels.lexicons.lexicon"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-md-7 d-flex align-items-center",
                                                },
                                                [
                                                  _c("choose-lexicon", {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value:
                                                          _vm.restaurant_id !==
                                                          null
                                                            ? _vm.getTooltip(
                                                                "Ce paramètre n'est modifiable que lors de la création d'établissement"
                                                              )
                                                            : undefined,
                                                        expression:
                                                          "\n                                                    restaurant_id !== null\n                                                        ? getTooltip('Ce paramètre n\\'est modifiable que lors de la création d\\'établissement')\n                                                        : undefined\n                                                ",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.restaurant_id !==
                                                        null,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.restaurant.lexicon,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.restaurant,
                                                          "lexicon",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "restaurant.lexicon",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.restaurant.lexicon !==
                                          _vm.LEXICON_RESTAURANT.value
                                            ? _c("switch-input-component", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "labels.form.restaurant.showToRealizeView"
                                                  ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.restaurant
                                                      .show_to_realize_view,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.restaurant,
                                                      "show_to_realize_view",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "restaurant.show_to_realize_view",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.restaurant.lexicon !==
                                          _vm.LEXICON_RESTAURANT.value
                                            ? _c("switch-input-component", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "labels.form.restaurant.enableSeatingPlan"
                                                  ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.restaurant
                                                      .feat_seating_plan,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.restaurant,
                                                      "feat_seating_plan",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "restaurant.feat_seating_plan",
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("Cgv", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.formLoading,
                                  expression: "!formLoading",
                                },
                              ],
                              ref: "cgvComponent",
                              attrs: {
                                disabled: !_vm.hasRightToEdit,
                                restaurant_id: _vm.restaurant_id,
                                "form-errors": _vm.errors.form,
                                "owner-id": _vm.ownerId,
                                isTvaDisabledProp: _vm.restaurant.tva_disabled,
                              },
                            }),
                            _vm._v(" "),
                            _vm.restaurant_id &&
                            !_vm.created_restaurant_id &&
                            _vm.restaurant.notifications_setting.sms_activated
                              ? _c("EditSmsSettings", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.formLoading,
                                      expression: "!formLoading",
                                    },
                                  ],
                                  attrs: {
                                    restaurant: _vm.restaurant,
                                    "form-errors": _vm.errors.form,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isNoShow &&
                            _vm.restaurant_id !== null &&
                            !_vm.created_restaurant_id
                              ? _c("div", { staticClass: "row mt-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12" },
                                    [
                                      _vm.restaurant.prefered_payment_system
                                        ? _c("ChooseCardPaymentSystem", {
                                            staticClass:
                                              "border-light b-radius-20 p-4",
                                            attrs: {
                                              prefered_payment_system:
                                                _vm.restaurant
                                                  .prefered_payment_system,
                                            },
                                            on: {
                                              "set-prefered-payment-system":
                                                function ($event) {
                                                  _vm.restaurant.prefered_payment_system =
                                                    $event
                                                },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-3" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between",
                                  },
                                  [
                                    _vm.isOwner && _vm.restaurant_id
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-danger btn-circle",
                                            attrs: {
                                              disabled: _vm.formLoading,
                                              type: "button",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.showDeleteRestaurantModal = true
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.form.restaurant.actions.delete"
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-success btn-circle",
                                        attrs: {
                                          disabled:
                                            !_vm.hasRightToEdit ||
                                            _vm.formLoading,
                                          "data-test-id": "btn-save",
                                          type: "submit",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.form.actions.save"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]),
          ]),
      _vm._v(" "),
      _vm.showCreateRestaurantModal
        ? _c("CreateRestaurantModal", {
            attrs: {
              smsActivated: _vm.restaurant.notifications_setting.sms_activated,
              restaurant_id: _vm.restaurant_id,
            },
            on: { close: _vm.onCreateRestaurantModalClosed },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showDeleteRestaurantModal
        ? _c("DeleteRestaurantModal", {
            attrs: { restaurant: _vm.restaurant },
            on: {
              close: function ($event) {
                _vm.showDeleteRestaurantModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }