<template>
    <div>
        <LoaderComponent v-if="loading.global > 0" />
        <div v-else>
            <div class="row m-0" v-if="errorMessages.global">
                <div class="col-12 border-light b-radius-20 p-4 mb-3">
                    <p class="text-danger w-100 text-center m-0">{{ errorMessages.global }}</p>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12 border-light b-radius-20 p-4 mb-3">
                    <div class="row">
                        <div class="col-12 mb-3 pb-3 border-bottom-light">
                            <div class="d-flex w-25 justify-content-between align-items-center lead-switch switch-choice">
                                <input
                                    type="checkbox"
                                    class="switch align-self-center is-rounded"
                                    :class="{ disabled: oneServiceDisabled }"
                                    id="chk-collect_type"
                                    v-model="command.collect_type"
                                    false-value="collect"
                                    true-value="delivery"
                                    :disabled="oneServiceDisabled" />
                                Retrait <label for="chk-collect_type"></label>Livraison
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row mb-2">
                                <div class="col-md-12" v-if="hasErrors('date')">
                                    <div class="invalid-feedback d-block" v-html="joinErrors('date')"></div>
                                </div>
                                <div class="col-md-4 pt-1">
                                    <label>Date <small>*</small></label>
                                </div>
                                <div class="col-md-8 date-resa-cal" style="font-size: 0.75rem">
                                    <datepicker
                                        tabindex="1"
                                        :monday-first="true"
                                        v-model="commandDateComputed"
                                        :format="customDateFormater"
                                        :disabledDates="disabledDates"
                                        :language="fr">
                                    </datepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" v-if="command.collect_type === 'collect'">
                            <div class="row mb-2">
                                <div class="col-md-12" v-if="hasErrors('slot.id')">
                                    <div class="invalid-feedback d-block" v-html="joinErrors('slot.id')"></div>
                                </div>
                                <div class="col-md-4 pt-1">
                                    <label>Heure de retrait *</label>
                                </div>
                                <div class="col-md-8">
                                    <select
                                        v-if="slots.length > 0"
                                        class="custom-select mb-0"
                                        style="margin-top: -2px !important"
                                        v-model="command.slot">
                                        <option v-for="slot in slots" :key="slot.id" :value="slot">
                                            {{ slotLabel(slot.hour) }}
                                        </option>
                                    </select>
                                    <small v-else class="text-danger d-block mt-2">{{ $tl("errors.commands.add.no_slots") }}</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" v-if="command.collect_type === 'delivery'">
                            <div class="row mb-2">
                                <div class="col-md-12" v-if="hasErrors('slot.id')">
                                    <div class="invalid-feedback d-block" v-html="joinErrors('slot.id')"></div>
                                </div>
                                <div class="col-md-4 pt-1">
                                    <label>Créneau de livraison *</label>
                                </div>
                                <div class="col-md-8">
                                    <select
                                        v-if="slots.length > 0"
                                        class="custom-select mb-0"
                                        style="margin-top: -2px !important"
                                        v-model="command.slot">
                                        <option v-for="slot in slots" :key="slot.id" :value="slot">
                                            {{ slotLabel(slot.hour_start) }} - {{ slotLabel(slot.hour_end) }}
                                        </option>
                                    </select>
                                    <small v-else class="text-danger d-block mt-2">{{ $tl("errors.commands.add.no_slots") }}</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" v-if="command.collect_type === 'delivery'">
                            <div class="row mb-2">
                                <div class="col-md-4 pt-1">
                                    <label>Adresse de livraison *</label>
                                </div>
                                <div class="col-md-8">
                                    <template v-if="command.address.deliveryInfo !== false">
                                        <span class="d-inline-block" style="padding-left: 13px; padding-top: 7px; padding-bottom: 7px">{{
                                            command.address.raw
                                        }}</span>
                                        <feather @click="resetAddress" type="x" style="color: red" class="mt-1"></feather>
                                    </template>
                                    <input v-else type="text" class="form-control" v-model="command.address.raw" @input="clientAddressChanged" />
                                    <span class="text-danger d-block mt-2" v-if="errorMessages.address">{{ errorMessages.address }}</span>
                                    <ShowErrors class="d-block" :errors="formErrors" errorKey="address.final.address" />
                                    <div class="address_results border-light" v-if="addresses_.length > 0">
                                        <span
                                            @click="selectAddress(address)"
                                            class="d-block p-2 address-results"
                                            v-for="(address, index) in addresses_"
                                            :key="index">
                                            {{ address.description }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row mb-3">
                            <div class="col-md-12 pt-1" v-if="hasErrors('products')">
                                <p class="text-danger">Les produits sont obligatoires</p>
                            </div>
                            <div class="col-md-4 pt-1">
                                <label>Produits <small>*</small></label>
                            </div>
                            <div class="col-md-8">
                                <select v-model="productForm.product" class="custom-select d-inline-block mb-1 mr-1" style="width: 300px">
                                    <option :value="null">--</option>
                                    <option
                                        v-for="product in products"
                                        :disabled="
                                            product.out_of_stock ||
                                            (product.remaining_stock.stock !== null &&
                                                getProductQuantityInCart(product.id, false) >= product.remaining_stock.stock)
                                        "
                                        :key="product.id"
                                        :value="product">
                                        {{
                                            product.out_of_stock ||
                                            (product.remaining_stock.stock !== null &&
                                                getProductQuantityInCart(product.id, false) >= product.remaining_stock.stock)
                                                ? `Épuisé - ${product.name}`
                                                : product.name
                                        }}
                                    </option>
                                </select>
                                <feather type="x"></feather>
                                <input
                                    v-model="productForm.quantity"
                                    type="number"
                                    :min="1"
                                    step="1"
                                    class="form-control d-inline-block ml-1 mr-2 pr-2"
                                    style="width: 60px"
                                    name="" />
                                <feather type="plus" class="text-success feather-20" @click="addProduct"></feather>
                                <span v-if="errorMessages.invalidQuantity.length > 0" class="text-danger d-block">{{
                                    errorMessages.invalidQuantity.join(", ")
                                }}</span>
                                <template v-if="productForm.product && productForm.product.cc_product_components.data.length > 0">
                                    <span v-if="errorMessages.choiceNeeded.length > 0" class="text-danger d-block"
                                        >Choix obligatoire{{ errorMessages.choiceNeeded.length > 1 ? "s" : "" }} :
                                        {{ errorMessages.choiceNeeded.join(", ") }}</span
                                    >
                                    <span v-if="errorMessages.invalidExtraQuantity.length > 0" class="text-danger d-block"
                                        >Quantité{{ errorMessages.invalidExtraQuantity.length > 1 ? "s" : "" }} invalide{{
                                            errorMessages.invalidExtraQuantity.length > 1 ? "s" : ""
                                        }}
                                        : {{ errorMessages.invalidExtraQuantity.join(", ") }}</span
                                    >
                                    <template v-for="productComponent in sortedProductComponents">
                                        <div
                                            v-if="productComponent.is_mandatory && productComponent.cc_product_options.data.length > 0"
                                            :key="productComponent.id">
                                            <select
                                                v-model="productForm.optionsMandatory[productComponent.id]"
                                                class="custom-select d-inline-block mb-1 mr-1"
                                                style="width: 300px">
                                                <option :disabled="true" class="disabled" :value="null">
                                                    {{ productComponent.name }}
                                                </option>
                                                <option
                                                    v-for="productOption in productComponent.cc_product_options.data"
                                                    :key="`${productComponent.id}-${productOption.id}`"
                                                    :disabled="
                                                        productOption.cc_product.out_of_stock ||
                                                        (productOption.cc_product.remaining_stock.stock !== null &&
                                                            getProductQuantityInCart(productOption.cc_product.id, false) >=
                                                                productOption.cc_product.remaining_stock.stock)
                                                    "
                                                    :value="productOption">
                                                    {{
                                                        productOption.cc_product.out_of_stock ||
                                                        (productOption.cc_product.remaining_stock.stock !== null &&
                                                            getProductQuantityInCart(productOption.cc_product.id, false) >=
                                                                productOption.cc_product.remaining_stock.stock)
                                                            ? `Épuisé - ${productOption.cc_product.name}`
                                                            : productOption.cc_product.name
                                                    }}
                                                </option>
                                            </select>
                                        </div>
                                        <template v-else-if="!productComponent.is_mandatory && productComponent.cc_product_options.data.length > 0">
                                            <div
                                                v-for="(extra, index) in productForm.extras[productComponent.id]"
                                                class="mt-3"
                                                :key="`${productComponent.id}-option-${index}`">
                                                <span style="width: 300px">{{ extra.extra.cc_product.name }}</span>
                                                <feather type="x"></feather>
                                                <input
                                                    v-model.number="extra.quantity"
                                                    type="number"
                                                    min="1"
                                                    step="1"
                                                    class="form-control d-inline-block ml-1 mr-2 pr-2"
                                                    style="width: 60px" />
                                                <feather type="x" class="text-danger" @click="deleteExtra(productComponent.id, index)"></feather>
                                            </div>
                                            <div
                                                class="d-inline-block mt-3 mb-1 mr-1"
                                                v-if="notSelectedExtras(productComponent.id).length > 0"
                                                :key="productComponent.id">
                                                <select
                                                    v-model="productForm.extraForms[productComponent.id].extra"
                                                    @change="extraFormChanged(productComponent.id)"
                                                    class="custom-select d-inline-block mt-1 mr-1"
                                                    style="width: 300px">
                                                    <option :disabled="true" class="disabled" :value="null">
                                                        {{ productComponent.name }}
                                                    </option>
                                                    <option
                                                        v-for="(extra, extraIndex) in notSelectedExtras(productComponent.id)"
                                                        :value="extra"
                                                        :key="`${productComponent.id}-extra-${extraIndex}`">
                                                        {{ extra.cc_product.name }}
                                                    </option>
                                                </select>
                                                <feather type="x"></feather>
                                                <input
                                                    v-model.number="productForm.extraForms[productComponent.id].quantity"
                                                    type="number"
                                                    min="1"
                                                    step="1"
                                                    class="form-control d-inline-block ml-1 mr-2 pr-2"
                                                    style="width: 60px" />
                                                <feather type="plus" class="text-success feather-20" @click="addExtra(productComponent.id)"></feather>
                                            </div>
                                        </template>
                                    </template>
                                </template>
                            </div>
                        </div>
                        <div class="border-light p-4">
                            <div class="row">
                                <div class="col-md-4 pt-1">
                                    <label>Récapitulatif de commande</label>
                                </div>
                                <div class="col-md-8 pt-1" style="color: #666666">
                                    <div
                                        class="border-bottom-light pb-2 mb-2 d-flex justify-content-between"
                                        v-for="(product, index) in command.products"
                                        :key="index">
                                        <div>
                                            <span>{{ product.quantity }} x {{ product.product.name }}</span
                                            ><br />
                                            <span
                                                class="ml-3 d-block"
                                                v-for="(option, indexOption) in Object.values(product.optionsMandatory)"
                                                :key="`${indexOption}-option`">
                                                <span class="ml-3"
                                                    >{{ 1 }} x {{ option.cc_product.name }}
                                                    {{ !option.include ? formatCurrency(option.price) : "" }}</span
                                                >
                                            </span>
                                            <template v-for="componentId in Object.keys(product.extras)">
                                                <span
                                                    class="ml-3 d-block"
                                                    v-for="(extra, indexExtra) in product.extras[componentId]"
                                                    :key="`${componentId}-${indexExtra}-extra`">
                                                    {{ extra.quantity }} x {{ extra.extra.cc_product.name }}
                                                    {{ !extra.extra.include ? formatCurrency(extra.extra.price) : " " }}
                                                    <feather
                                                        type="x"
                                                        class="text-danger ml-2"
                                                        @click="deleteProductExtra(componentId, index, indexExtra)"></feather>
                                                </span>
                                            </template>
                                        </div>
                                        <div>
                                            {{ formatCurrency(getProductPrice(product)) }}
                                            <feather type="x" class="text-danger ml-2" @click="deleteProduct(index)"></feather>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between" v-if="command.collect_type === 'collect'">
                                        <strong>Total</strong>
                                        <strong class="text-success">{{ formatCurrency(getTotal()) }}</strong>
                                    </div>
                                    <template v-else-if="command.collect_type === 'delivery'">
                                        <div class="d-flex justify-content-between">
                                            <strong>Sous-total</strong>
                                            <strong class="text-success">{{ formatCurrency(getTotal()) }}</strong>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <strong>Frais de livraison</strong>
                                            <strong class="text-success">{{ getDeliveryFees() }}</strong>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <strong>Total</strong>
                                            <strong class="text-success">{{ formatCurrency(getTotalWithDeliveryFees()) }}</strong>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row">
                            <div class="col-12">
                                <strong>Paiement</strong>
                            </div>
                        </div>
                        <div class="row mb-2 mt-3">
                            <div class="col-md-12" v-if="hasErrors('payment.isOnline')">
                                <div class="invalid-feedback d-block" v-html="joinErrors('payment.isOnline')"></div>
                            </div>
                            <div class="col-md-5 pt-1">
                                <label>Type de paiement <small>*</small></label>
                            </div>
                            <div class="col-md-7">
                                <div class="d-flex justify-content-around align-items-center lead-switch switch-choice">
                                    <input
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        id="chk-send_payment_ask"
                                        v-model="command.payment.isOnline" />
                                    Sur place <label for="chk-send_payment_ask"></label>Envoyer une demande de paiement
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div>
                            <div class="row mb-2 radio">
                                <div class="col-md-12" v-if="hasErrors('client.civility')">
                                    <div class="invalid-feedback d-block" v-html="joinErrors('client.civility')"></div>
                                </div>
                                <div class="col-md-4 d-none d-md-block pt-2">
                                    <label>Civilité <small v-if="mandatoryFields.civility">*</small></label>
                                </div>
                                <div class="col-md-8 pt-2">
                                    <label class="container-box">
                                        <input
                                            type="radio"
                                            name="civility"
                                            value="monsieur"
                                            tabindex="5"
                                            v-model="command.client.civility"
                                            @click="$refs.firstname.focus()" />
                                        <span class="checkmark"></span> Monsieur
                                    </label>
                                    <!-- <div class="none-desk" style="height: 8px"></div> -->
                                    <label class="container-box">
                                        <input
                                            type="radio"
                                            name="civility"
                                            value="madame"
                                            tabindex="6"
                                            v-model="command.client.civility"
                                            @click="$refs.firstname.focus()" />
                                        <span class="checkmark"></span> Madame
                                    </label>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-12" v-if="hasErrors('client.firstname')">
                                    <div class="invalid-feedback d-block" v-html="joinErrors('client.firstname')"></div>
                                </div>
                                <div class="col-md-4 d-none d-md-block pt-2">
                                    <label>Prénom <small v-if="mandatoryFields.firstname">*</small></label>
                                </div>
                                <div class="col-md-8">
                                    <div class="dropright add-resa-search-client">
                                        <input
                                            type="text"
                                            placeholder="Prénom"
                                            ref="firstname"
                                            name="firstname"
                                            v-model="command.client.firstname"
                                            autocomplete="off"
                                            class="form-control"
                                            tabindex="7"
                                            @input="searchClientByMultiField"
                                            @focus="command.client.firstnameHasFocus = true"
                                            @blur="command.client.firstnameHasFocus = false" />
                                        <div
                                            @mouseover="command.client.search.firstnameHasFocus = true"
                                            @mouseout="command.client.search.firstnameHasFocus = false"
                                            class="dropdown-menu prename-field shadow show p-0"
                                            v-if="
                                                (command.client.firstnameHasFocus || command.client.search.firstnameHasFocus) &&
                                                command.client.search.results &&
                                                command.client.search.results.length > 0
                                            ">
                                            <ul class="list-group">
                                                <li class="list-group-item p-0" v-for="result in command.client.search.results">
                                                    <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                                        <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                                        <span>{{ result.tel }}</span>
                                                        <span class="email-search"
                                                            ><span v-if="result.company">{{ result.company }} |</span> {{ result.email }}</span
                                                        >
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-12" v-if="hasErrors('client.lastname')">
                                    <div class="invalid-feedback d-block" v-html="joinErrors('client.lastname')"></div>
                                </div>
                                <div class="col-md-4 d-none d-md-block pt-2">
                                    <label>Nom <small v-if="mandatoryFields.lastname">*</small></label>
                                </div>
                                <div class="col-md-8">
                                    <div class="dropright add-resa-search-client">
                                        <input
                                            type="text"
                                            placeholder="Nom"
                                            name="lastname"
                                            v-model="command.client.lastname"
                                            autocomplete="off"
                                            class="form-control"
                                            tabindex="8"
                                            @input="searchClientByMultiField"
                                            @focus="command.client.lastnameHasFocus = true"
                                            @blur="command.client.lastnameHasFocus = false" />
                                        <div
                                            @mouseover="command.client.search.lastnameHasFocus = true"
                                            @mouseout="command.client.search.lastnameHasFocus = false"
                                            class="dropdown-menu name-field shadow show p-0"
                                            v-if="
                                                (command.client.lastnameHasFocus || command.client.search.lastnameHasFocus) &&
                                                command.client.search.results &&
                                                command.client.search.results.length > 0
                                            ">
                                            <ul class="list-group">
                                                <li class="list-group-item p-0" v-for="result in command.client.search.results">
                                                    <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                                        <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                                        <span>{{ result.tel }}</span>
                                                        <span class="email-search"
                                                            ><span v-if="result.company">{{ result.company }} |</span> {{ result.email }}</span
                                                        >
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-12" v-if="hasErrors('client.company')">
                                    <div class="invalid-feedback d-block" v-html="joinErrors('client.company')"></div>
                                </div>
                                <div class="col-md-4 d-none d-md-block pt-2">
                                    <label>Société <small v-if="mandatoryFields.company">*</small></label>
                                </div>
                                <div class="col-md-8">
                                    <div class="dropright add-resa-search-client">
                                        <input
                                            type="text"
                                            placeholder="Société"
                                            name="lastname"
                                            v-model="command.client.company"
                                            autocomplete="off"
                                            class="form-control"
                                            tabindex="8"
                                            @input="searchClientByMultiField"
                                            @focus="command.client.companyHasFocus = true"
                                            @blur="command.client.companyHasFocus = false" />
                                        <div
                                            @mouseover="command.client.search.companyHasFocus = true"
                                            @mouseout="command.client.search.companyHasFocus = false"
                                            class="dropdown-menu company-field shadow show p-0"
                                            v-if="
                                                (command.client.companyHasFocus || command.client.search.companyHasFocus) &&
                                                command.client.search.results &&
                                                command.client.search.results.length > 0
                                            ">
                                            <ul class="list-group">
                                                <li class="list-group-item p-0" v-for="result in command.client.search.results">
                                                    <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                                        <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                                        <span>{{ result.tel }}</span>
                                                        <span class="email-search"
                                                            ><span v-if="result.company">{{ result.company }} |</span> {{ result.email }}</span
                                                        >
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-12" v-if="hasErrors('client.email')">
                                    <div class="invalid-feedback d-block" v-html="joinErrors('client.email')"></div>
                                </div>
                                <div class="col-md-4 d-none d-md-block pt-2">
                                    <label>Email <small v-if="mandatoryFields.email">*</small></label>
                                </div>
                                <div class="col-md-8">
                                    <div class="dropright add-resa-search-client">
                                        <input
                                            type="text"
                                            placeholder="Email"
                                            name="email"
                                            v-model="command.client.email"
                                            autocomplete="off"
                                            class="form-control"
                                            tabindex="9"
                                            @focus="command.client.emailHasFocus = true"
                                            @input="searchClientByMultiField"
                                            @blur="command.client.emailHasFocus = false" />
                                        <div
                                            @mouseover="command.client.search.emailHasFocus = true"
                                            @mouseout="command.client.search.emailHasFocus = false"
                                            class="dropdown-menu email-field shadow show p-0"
                                            v-if="
                                                (command.client.emailHasFocus || command.client.search.emailHasFocus) &&
                                                command.client.search.results &&
                                                command.client.search.results.length > 0
                                            ">
                                            <ul class="list-group">
                                                <li class="list-group-item p-0" v-for="result in command.client.search.results">
                                                    <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                                        <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                                        <span>{{ result.tel }}</span>
                                                        <span class="email-search"
                                                            ><span v-if="result.company">{{ result.company }} |</span> {{ result.email }}</span
                                                        >
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div
                                    v-if="hasErrors('client.phoneNumber') || hasErrors('client.phoneCountry') || hasErrors('client.id')"
                                    class="col-md-12">
                                    <div v-if="hasErrors('client.phoneNumber')">
                                        <div class="invalid-feedback d-block" v-html="joinErrors('client.phoneNumber')"></div>
                                    </div>
                                    <div v-else-if="hasErrors('client.phoneCountry')">
                                        <div class="invalid-feedback d-block" v-html="joinErrors('client.phoneCountry')"></div>
                                    </div>
                                </div>
                                <div class="col-md-4 d-none d-md-block pt-2">
                                    <label>Téléphone <small v-if="mandatoryFields.tel">*</small></label>
                                </div>
                                <div class="col-md-8">
                                    <div class="dropright add-resa-search-client">
                                        <vue-tel-input
                                            :defaultCountry="defaultTelCountry"
                                            name="telInput"
                                            @onValidate="setPhoneAndCountry"
                                            @onInput="searchClientByMultiField"
                                            ref="vueTelInput"
                                            v-model="command.client.phone.raw"
                                            :enabledCountryCode="true"
                                            :inputOptions="{ tabindex: 10, autocomplete: 'off' }"
                                            :class="
                                                command.client.phone.value !== null && command.client.phone.isValid === false
                                                    ? ['form-control', 'is-invalid', 'd-flex', 'text-left']
                                                    : ['form-control', 'd-flex', 'text-left']
                                            "
                                            mode="international"
                                            inputClasses="m-0 border-0"
                                            placeholder="Téléphone"></vue-tel-input>
                                        <div
                                            @mouseover="command.client.search.phoneNumberHasFocus = true"
                                            @mouseout="command.client.search.phoneNumberHasFocus = false"
                                            class="dropdown-menu phone-field shadow show p-0"
                                            v-if="
                                                (command.client.phoneNumberHasFocus || command.client.search.phoneNumberHasFocus) &&
                                                command.client.search.results &&
                                                command.client.search.results.length > 0
                                            ">
                                            <ul class="list-group">
                                                <li class="list-group-item p-0" v-for="result in command.client.search.results">
                                                    <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                                        <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                                        <span>{{ result.tel }}</span>
                                                        <span class="email-search"
                                                            ><span v-if="result.company">{{ result.company }} |</span> {{ result.email }}</span
                                                        >
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-12" v-if="hasErrors('client.type')">
                                    <div class="invalid-feedback d-block" v-html="joinErrors('client.type')"></div>
                                </div>
                                <div class="col-md-4 d-none d-md-block pt-2">
                                    <label>{{ $tl("labels.clients.type") }} <small v-if="mandatoryFields.type">*</small></label>
                                </div>
                                <div class="col-md-8">
                                    <select-client-type :restaurant-id="restaurant_id" v-model="command.client.type" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 pt-1">
                                    <label>{{ $tl("labels.clients.vip") }}</label>
                                </div>
                                <div class="col-8 lead-switch">
                                    <input type="checkbox" class="switch align-self-center is-rounded" v-model="command.client.is_vip" />
                                    <label @click="command.client.is_vip = !command.client.is_vip"></label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 pt-1">
                                    <label class="none-mobile">{{ $tl("labels.form.acceptNewsletter") }}</label>
                                    <label class="none-desk">{{ $tl("labels.form.acceptNewsletterShort") }}</label>
                                </div>
                                <div class="col-8 lead-switch">
                                    <input type="checkbox" class="switch align-self-center is-rounded" v-model="command.client.newsletter" />
                                    <label @click="command.client.newsletter = !command.client.newsletter"></label>
                                </div>
                            </div>

                            <div class="add-resa-search-client" v-if="command.client.phone.search.results !== null && command.client.id === null">
                                <div v-if="command.client.phone.search.results.length > 0">
                                    <span class="text-danger d-inline-block mt-2 mb-2"
                                        >Un client existe déjà avec ce numéro de téléphone.<br />
                                        Si vous enregistrez la réservation, celui-ci sera mis à jour.</span
                                    >
                                    <li class="list-group-item p-0">
                                        <a class="d-block p-3" href="javascript:" @click="selectClient(command.client.phone.search.results[0])">
                                            <span>
                                                {{ command.client.phone.search.results[0].firstname }}
                                                {{ command.client.phone.search.results[0].lastname }}
                                            </span>
                                            | <span>{{ command.client.phone.search.results[0].tel }}</span>
                                            <span class="email-search"
                                                ><span v-if="command.client.phone.search.results[0].company"
                                                    >{{ command.client.phone.search.results[0].company }} |</span
                                                >{{ command.client.phone.search.results[0].email }}</span
                                            >
                                        </a>
                                    </li>
                                </div>
                                <div v-else>
                                    <span class="d-inline-block mt-2 mb-2 text-muted">Le client sera créé à l'enregistrement de la réservation.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row">
                            <div class="col-md-4 d-none d-md-block pt-2">
                                <label>Commentaire Privé</label>
                            </div>
                            <div class="col-md-8">
                                <textarea class="form-control" v-model="command.private_comment" rows="3"></textarea>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-4 d-none d-md-block pt-2">
                                <label>Commentaire Public</label>
                            </div>
                            <div class="col-md-8">
                                <textarea class="form-control" v-model="command.comment" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent";
import VueTimepicker from "vue2-timepicker";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import axios from "axios";
import _ from "lodash";
import ShowErrors from "../errors/ShowErrors";
import SelectClientType from "../forms/clients/selectClientType.vue";
moment.locale("fr");

export default {
    data() {
        return {
            loading: {
                global: 0,
                products: 0,
            },
            is_collect_enabled: false,
            is_delivery_enabled: false,
            errorMessages: {
                global: null,
                slots: null,
                products: null,
                command: null,
                choiceNeeded: [],
                invalidExtraQuantity: [],
                invalidQuantity: [],
                address: null,
            },
            baseCommand: null,
            formErrors: null,
            productForm: {
                product: null,
                quantity: 1,
                optionsMandatory: {},
                options: [],
                extras: {},
                extraForms: {},
            },
            command: {
                collect_type: "collect",
                slot: null,
                products: [],
                date: null,
                private_comment: null,
                comment: null,
                address: {
                    deliveryInfo: false,
                    raw: "",
                    final: null,
                },
                client: {
                    id: null,
                    civility: null,
                    firstname: null,
                    lastname: null,
                    company: null,
                    email: null,
                    phone: {
                        raw: null,
                        isValid: null,
                        country: null,
                        value: null,
                        search: {
                            results: null,
                        },
                    },
                    type: null,
                    is_vip: false,
                    newsletter: false,
                    firstnameHasFocus: false,
                    lastnameHasFocus: false,
                    companyHasFocus: false,
                    emailHasFocus: false,
                    phoneNumberHasFocus: false,
                    search: {
                        results: null,
                        errors: null,
                        isLoading: false,
                        firstnameHasFocus: false,
                        lastnameHasFocus: false,
                        companyHasfocus: false,
                        emailHasFocus: false,
                        phoneNumberHasFocus: false,
                    },
                },
                payment: {
                    isOnline: false,
                    status: null,
                },
            },
            slots: [],
            products: [],
            openDaysPeriods: [],
            disabledDates: {
                customPredictor: this.customAvailabilityPredictor,
            },
            fr,
            addresses: [],
            sessionToken: null,
            mandatoryFields: {
                civility: false,
                firstname: false,
                lastname: false,
                company: false,
                email: false,
                type: false,
                tel: false,
            },
        };
    },
    computed: {
        defaultTelCountry() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurant_id).default_tel_country;
        },
        oneServiceDisabled() {
            return !this.is_collect_enabled || !this.is_delivery_enabled;
        },
        deliveryFees() {
            if (!this.command.address.deliveryInfo) return null;
            const deliveryArea = this.command.address.deliveryInfo.cc_delivery_area;
            if (deliveryArea.is_free || (deliveryArea.free_above !== null && deliveryArea.free_above <= this.getTotal())) return 0;
            return deliveryArea.price;
        },
        addresses_() {
            return this.addresses;
        },
        sortedProductComponents() {
            return this.productForm.product.cc_product_components.data.sort((a, b) => {
                if (a.is_mandatory === b.is_mandatory) return a.order - b.order;
                if (a.is_mandatory && !b.is_mandatory) return -1;
                if (!a.is_mandatory && b.is_mandatory) return 1;
                return 0;
            });
        },
        restaurant_id: function () {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        commandDateComputed: {
            get() {
                if (!this.command.date) {
                    this.command.date = moment().format("YYYY-MM-DD");
                }

                return moment(this.command.date).toDate();
            },
            set(value) {
                this.command.date = moment(value).format("YYYY-MM-DD");
            },
        },
    },
    created() {
        this.fetchFields();
        this.loading.global++;
        axios
            .get(`/api/click_and_collect/${this.restaurant_id}/settings`)
            .then((response) => {
                this.is_collect_enabled = response.data.is_click_and_collect_enabled;
                this.is_delivery_enabled = response.data.is_delivery_enabled;
                if (!this.is_collect_enabled && this.is_delivery_enabled) this.command.collect_type = "delivery";
                else this.fetchData();
                this.loading.global--;
            })
            .catch((error) => {
                if (error.response && error.response.data.message) {
                    this.errorMessages.global = error.response.data.message;
                } else if (error.message) {
                    this.errorMessages.global = error.message;
                } else {
                    this.errorMessages.global = "Une erreur est survenue.";
                }
                this.loading.global--;
            });
    },
    methods: {
        fetchFields() {
            this.loading.global++;
            this.httpGet(`/api/restaurants/${this.restaurant_id}/reservations/input-params/fields?module=click-and-collect`, {
                handleReject: false,
            })
                .then((response) => {
                    if (response !== false) {
                        response.data.forEach((field) => {
                            this.mandatoryFields[field.field_name] = field.pivot.is_mandatory;
                        });
                    }
                })
                .finally(() => this.loading.global--);
        },
        getProductQuantityInCart(product_id, include_product_form = true) {
            let quantity = 0;
            if (this.productForm.product && include_product_form) {
                if (this.productForm.product.id === product_id) quantity += this.productForm.quantity * 1;
                if (this.productForm.extras)
                    Object.values(this.productForm.extras).forEach((extras) => {
                        extras.forEach((extra) => {
                            if (extra && extra.extra && extra.extra.cc_product_id === product_id)
                                quantity += extra.quantity * this.productForm.quantity;
                        });
                    });
                if (this.productForm.extraForms)
                    Object.values(this.productForm.extraForms).forEach((extra) => {
                        if (extra && extra.extra && extra.extra.cc_product_id === product_id) quantity += extra.quantity * this.productForm.quantity;
                    });
                if (this.productForm.optionsMandatory)
                    Object.values(this.productForm.optionsMandatory).forEach((option) => {
                        if (option && option.cc_product_id === product_id) quantity += this.productForm.quantity * 1;
                    });
            }
            this.command.products.forEach((product) => {
                if (product.product.id === product_id) quantity += product.quantity * 1;
                Object.values(product.extras).forEach((extras) => {
                    extras.forEach((extra) => {
                        if (extra.extra.cc_product_id === product_id) quantity += extra.quantity * product.quantity;
                    });
                });
                Object.values(product.optionsMandatory).forEach((option) => {
                    if (option.cc_product_id === product_id) quantity += product.quantity * 1;
                });
            });
            return quantity;
        },
        notSelectedExtras(componentId) {
            let component = this.productForm.product.cc_product_components.data.find((component) => component.id === componentId);
            if (!component) return [];
            return component.cc_product_options.data.filter((option) => {
                return !this.productForm.extras[componentId].find((extra) => extra.extra.id === option.id);
            });
        },
        fetchData() {
            this.fetchOpenDaysPeriods();
        },
        fetchForDay() {
            this.command.products = [];
            this.emptyProductForm();
            this.fetchSlots(this.command.date);
        },
        fetchOpenDaysPeriods() {
            this.loading.global++;
            this.$store
                .dispatch("ccCommands/fetchOpenDaysPeriods", {
                    restaurant_id: this.restaurant_id,
                    collect_type: this.command.collect_type,
                })
                .then((response) => {
                    this.openDaysPeriods = response.data.data;
                    this.loading.global--;
                })
                .catch((error) => {
                    if (error.response && error.response.data.message) {
                        this.errorMessages.global = error.response.data.message;
                    } else if (error.message) {
                        this.errorMessages.global = error.message;
                    } else {
                        this.errorMessages.global = "Une erreur est survenue.";
                    }
                    this.loading.global--;
                });
        },
        fetchSlots(date) {
            this.loading.global++;
            this.$store
                .dispatch("ccCommands/fetchSlotsForDay", {
                    restaurant_id: this.restaurant_id,
                    params: {
                        date,
                        collect_type: this.command.collect_type,
                    },
                })
                .then((response) => {
                    this.slots = response.data.data;
                    this.loading.global--;
                })
                .catch((error) => {
                    if (error.response && error.response.data.message) {
                        this.errorMessages.slots = error.response.data.message;
                    } else if (error.message) {
                        this.errorMessages.slots = error.message;
                    } else {
                        this.errorMessages.slots = "Une erreur est survenue.";
                    }
                    this.loading.global--;
                });
        },
        fetchProducts(service_id) {
            this.loading.products++;
            this.$store
                .dispatch("ccCommands/fetchProductsForDay", {
                    restaurant_id: this.restaurant_id,
                    params: {
                        include: "cc_product_components.cc_product_options.cc_product.remaining_stock,remaining_stock",
                        date: this.command.date,
                        collect_type: this.collect_type,
                        slot: this.command.slot.id,
                    },
                })
                .then((response) => {
                    this.products = response.data.data;
                    this.products.forEach((product) => {
                        product.out_of_stock = false;
                        if (product.remaining_stock) {
                            if (product.remaining_stock.out_of_stock || product.remaining_stock.stock == 0) product.out_of_stock = true;
                        }
                        product.cc_product_components.data.forEach((c) => {
                            c.cc_product_options.data.forEach((po) => {
                                po.cc_product.out_of_stock = false;
                                if (po.cc_product.remaining_stock) {
                                    if (po.cc_product.remaining_stock.out_of_stock || po.cc_product.remaining_stock.stock == 0)
                                        po.cc_product.out_of_stock = true;
                                }
                            });
                        });
                    });
                    this.products.sort((a, b) => {
                        if (a.out_of_stock === b.out_of_stock) return 0;
                        if (a.out_of_stock && !b.out_of_stock) return 1;
                        return -1;
                    });
                    this.unselectProductsUnavailable();
                    this.loading.products--;
                })
                .catch((error) => {
                    if (error.response && error.response.data.message) {
                        this.errorMessages.products = error.response.data.message;
                    } else if (error.message) {
                        this.errorMessages.products = error.message;
                    } else {
                        this.errorMessages.products = "Une erreur est survenue.";
                    }
                    this.loading.products--;
                });
        },
        unselectProductsUnavailable() {
            this.command.products = this.command.products.filter((p) => {
                return this.products.map((p) => p.id).includes(p.id);
            });
        },
        slotLabel(slotValue) {
            return slotValue.replace(":", "h").substr(0, 5);
        },
        customDateFormater(date) {
            return moment(date).format("ddd LL");
        },
        customAvailabilityPredictor(date) {
            const momentDate = moment(date);
            const openings = this.openDaysPeriods.filter((odp) => {
                if (!odp.special) return false;
                const odpFrom = moment(odp.from);
                const odpTo = moment(odp.to);
                return odpFrom.isSameOrBefore(momentDate) && odpTo.isSameOrAfter(momentDate);
            });
            if (openings.length > 0) {
                var period = openings.find((opening) => {
                    return opening.days.includes(this.getUsWeekDayFromFrWeekday(momentDate.weekday()));
                });
            } else {
                var period = this.openDaysPeriods.find((odp) => {
                    const odpFrom = moment(odp.from);
                    const odpTo = odp.to ? moment(odp.to) : null;

                    return (
                        odpFrom.isSameOrBefore(momentDate) &&
                        (!odpTo || odpTo.isSameOrAfter(momentDate)) &&
                        odp.days.includes(this.getUsWeekDayFromFrWeekday(momentDate.weekday()))
                    );
                });
            }

            if (period) {
                return false;
            }

            return true;
        },
        getUsWeekDayFromFrWeekday(frWeekday) {
            frWeekday += 1;
            if (frWeekday === 7) {
                frWeekday = 0;
            }

            return frWeekday;
        },
        addProduct() {
            if (!this.productForm.product) return;
            this.errorMessages.invalidQuantity = [];
            if (
                this.productForm.product.remaining_stock.stock !== null &&
                this.getProductQuantityInCart(this.productForm.product.id) > this.productForm.product.remaining_stock.stock
            ) {
                this.errorMessages.invalidQuantity.push(
                    `Vous avez atteint la quantité maximale pour le produit "${this.productForm.product.name}" (${this.productForm.product.remaining_stock.stock}).`
                );
                return;
            }
            if (this.productForm.quantity > 0 && this.checkProductFormOptions()) {
                this.command.products.push({
                    product: this.productForm.product,
                    quantity: this.productForm.quantity,
                    optionsMandatory: this.productForm.optionsMandatory,
                    extras: this.productForm.extras,
                });
                this.emptyProductForm();
            }
        },
        checkProductFormOptions() {
            this.errorMessages.choiceNeeded = [];
            this.errorMessages.invalidExtraQuantity = [];
            Object.keys(this.productForm.optionsMandatory).forEach((componentId) => {
                if (!this.productForm.optionsMandatory[componentId]) {
                    let component = this.productForm.product.cc_product_components.data.find((c) => c.id == componentId);
                    this.errorMessages.choiceNeeded.push(component ? component.name : "");
                } else if (
                    this.productForm.optionsMandatory[componentId].cc_product.remaining_stock.stock !== null &&
                    this.getProductQuantityInCart(this.productForm.optionsMandatory[componentId].cc_product.id) >
                        this.productForm.optionsMandatory[componentId].cc_product.remaining_stock.stock
                ) {
                    this.errorMessages.invalidQuantity.push(
                        `Vous avez atteint la quantité maximale pour le produit "${this.productForm.optionsMandatory[componentId].cc_product.name}" (${this.productForm.optionsMandatory[componentId].cc_product.remaining_stock.stock})`
                    );
                }
            });
            Object.values(this.productForm.extras).forEach((componentExtras) => {
                componentExtras.forEach((extra) => {
                    if (extra.quantity < 1) this.errorMessages.invalidExtraQuantity.push(extra.extra.cc_product.name);
                    else if (
                        extra.extra.cc_product.remaining_stock.stock != null &&
                        this.getProductQuantityInCart(extra.extra.cc_product.id) > extra.extra.cc_product.remaining_stock.stock
                    ) {
                        this.errorMessages.invalidQuantity.push(
                            `Vous avez atteint la quantité maximale pour le produit "${extra.extra.cc_product.name}" (${extra.extra.cc_product.remaining_stock.stock})`
                        );
                    }
                });
            });
            return (
                this.errorMessages.choiceNeeded.length === 0 &&
                this.errorMessages.invalidExtraQuantity.length === 0 &&
                this.errorMessages.invalidQuantity.length === 0
            );
        },
        deleteExtra(componentId, extraIndex) {
            this.productForm.extras[componentId].splice(extraIndex, 1);
            this.$forceUpdate();
        },
        addExtra(componentId) {
            this.errorMessages.invalidExtraQuantity = [];
            this.errorMessages.invalidQuantity = [];
            if (this.productForm.extraForms[componentId].extra) {
                if (
                    this.productForm.extraForms[componentId].extra.cc_product.remaining_stock.stock != null &&
                    this.getProductQuantityInCart(this.productForm.extraForms[componentId].extra.cc_product.id) >
                        this.productForm.extraForms[componentId].extra.cc_product.remaining_stock.stock
                ) {
                    this.errorMessages.invalidQuantity.push(
                        `Vous avez atteint la quantité maximale pour le produit "${this.productForm.extraForms[componentId].extra.cc_product.name}" (${this.productForm.extraForms[componentId].extra.cc_product.remaining_stock.stock})`
                    );
                } else {
                    this.productForm.extras[componentId].push({
                        extra: this.productForm.extraForms[componentId].extra,
                        quantity: this.productForm.extraForms[componentId].quantity,
                    });
                    this.emptyExtraForm(componentId);
                }
            }
        },
        deleteProductExtra(componentId, productIndex, extraIndex) {
            this.command.products[productIndex].extras[componentId].splice(extraIndex, 1);
            this.$forceUpdate();
        },
        deleteProduct(productIndex) {
            this.command.products.splice(productIndex, 1);
        },
        emptyProductForm() {
            this.productForm = {
                product: null,
                optionsMandatory: {},
                extras: {},
                extraForms: {},
            };
        },
        emptyExtraForm(componentId) {
            this.productForm.extraForms[componentId] = {
                extra: null,
                quantity: null,
            };
            this.$forceUpdate();
        },
        hasOffer(selectedProduct) {
            if (!selectedProduct.enable_price_offer) return false;
            if (selectedProduct.offer_starts_on && moment().startOf("day").isBefore(moment(selectedProduct.offer_starts_on).startOf("day")))
                return false;
            if (selectedProduct.offer_ends_on && moment().startOf("day").isAfter(moment(selectedProduct.offer_ends_on).startOf("day"))) return false;
            if (!selectedProduct.price_offer) return false;
            return true;
        },
        getProductPrice(selectedProduct) {
            var price = 0;

            price += this.hasOffer(selectedProduct.product) ? selectedProduct.product.price_offer : selectedProduct.product.price;

            Object.values(selectedProduct.optionsMandatory).forEach((option) => {
                if (option && !option.include) price += option.price;
            });
            Object.values(selectedProduct.extras).forEach((componentExtras) => {
                componentExtras.forEach((extra) => {
                    if (extra.extra && !extra.extra.include) price += extra.extra.price * parseInt(extra.quantity);
                });
            });

            return price * selectedProduct.quantity;
        },
        getDeliveryFees() {
            if (this.deliveryFees === null) return "--";
            if (this.deliveryFees === 0) return "Gratuit";
            return this.formatCurrency(this.deliveryFees);
        },
        getTotal() {
            var price = 0;

            this.command.products.forEach((product) => {
                price += this.getProductPrice(product);
            });

            return price;
        },
        getTotalWithDeliveryFees() {
            return this.getTotal() + (this.deliveryFees || 0);
        },
        setPhoneAndCountry(phoneObject) {
            if (phoneObject.number && phoneObject.isValid !== undefined && phoneObject.country) {
                this.command.client.phone.isValid = phoneObject.isValid;

                if (this.command.client.phone.isValid) {
                    this.command.client.phone.value = phoneObject.number;

                    if (phoneObject.regionCode && phoneObject.regionCode !== "") {
                        this.command.client.phone.country = phoneObject.regionCode;
                    } else {
                        this.command.client.phone.country = phoneObject.country.iso2;
                    }

                    if (!this.command.client.search.results || this.command.client.search.results.length < 1) {
                        axios
                            .get(`/api/restaurants/${this.restaurant_id}/clients/search/phone`, {
                                params: {
                                    phoneNumber: this.command.client.phone.value.international,
                                    phoneCountry: this.command.client.phone.country,
                                },
                            })
                            .then((response) => {
                                this.command.client.phone.search.results = response.data.data;
                            })
                            .catch((error) => {});
                    }
                } else {
                    this.command.client.phone.search.results = null;
                }
            } else {
                this.command.client.phone.isValid = false;
                this.command.client.phone.search.results = null;
            }
        },
        searchClientByMultiField() {
            this.command.client.search.errors = [];
            this.command.client.search.isLoading = true;
            var params = {};

            if (this.command.client.firstname && this.command.client.firstname !== "") {
                params.firstname = this.command.client.firstname;
            }

            if (this.command.client.lastname && this.command.client.lastname !== "") {
                params.lastname = this.command.client.lastname;
            }

            if (this.command.client.email && this.command.client.email !== "") {
                params.email = this.command.client.email;
            }

            if (this.command.client.phone.raw && this.command.client.phone.raw !== "") {
                params.phone_number = this.command.client.phone.raw;
            }

            if (this.command.client.company && this.command.client.company !== "") {
                params.company = this.command.client.company;
            }

            axios
                .get(`/api/restaurants/${this.restaurant_id}/clients/search/multi`, {
                    params,
                })
                .then((response) => {
                    this.command.client.search.results = response.data.data;
                    this.command.client.search.isLoading = false;

                    if (
                        !this.command.client.search.results ||
                        (this.command.client.search.results.length === 0 && this.command.client.phone.isValid)
                    ) {
                        axios
                            .get(`/api/restaurants/${this.restaurant_id}/clients/search/phone`, {
                                params: {
                                    phoneNumber: this.command.client.phone.value.international,
                                    phoneCountry: this.command.client.phone.country,
                                },
                            })
                            .then((response) => {
                                this.command.client.phone.search.results = response.data.data;
                            })
                            .catch((error) => {});
                    }
                })
                .catch((err) => {});
        },
        selectClient: function (client) {
            this.$set(
                this.command,
                "client",
                Object.assign(this.command.client, {
                    id: client.id,
                    civility: client.civility,
                    firstname: client.firstname,
                    lastname: client.lastname,
                    company: client.company,
                    email: client.email,
                    type: client.type,
                    is_vip: client.is_vip,
                    newsletter: client.newsletter,
                    phone: {
                        raw: client.tel,
                        isValid: true,
                        country: client.tel_country,
                        value: {
                            international: client.tel,
                        },
                        search: {
                            results: null,
                        },
                    },
                })
            );
            this.command.client.search.companyHasFocus = false;
            this.command.client.search.firstnameHasFocus = false;
            this.command.client.search.lastnameHasFocus = false;
            this.command.client.search.emailHasFocus = false;
            this.command.client.search.phoneNumberHasFocus = false;
        },
        postForm() {
            this.loading.global++;
            this.formErrors = null;
            this.errorMessages.global = null;
            const form = this.formatForm();

            return this.$store
                .dispatch("ccCommands/addCommand", {
                    restaurant_id: this.restaurant_id,
                    form,
                })
                .then((response) => {
                    return true;
                })
                .catch((error) => {
                    if (error.response && error.response.data.errors) {
                        this.formErrors = error.response.data.errors;
                    }

                    if (error.response && error.response.data.message) {
                        this.errorMessages.global = error.response.data.message;
                    } else if (error.message) {
                        this.errorMessages.global = error.message;
                    } else {
                        this.errorMessages.global = "Une erreur est survenue à l'enregistrement.";
                    }
                    return false;
                })
                .then((result) => {
                    this.loading.global--;
                    return result;
                });
        },
        hasErrors(name) {
            return this.formErrors && !_.isEmpty(this.formErrors[name]);
        },
        joinErrors(name) {
            return this.formErrors[name].join("<br/>");
        },
        formatForm() {
            return {
                ...this.command,
                client: {
                    id: this.command.client.id,
                    civility: this.command.client.civility,
                    firstname: this.command.client.firstname,
                    lastname: this.command.client.lastname,
                    company: this.command.client.company,
                    email: this.command.client.email,
                    phoneNumber: this.command.client.phone.value ? this.command.client.phone.value.international : null,
                    phoneCountry: this.command.client.phone.country,
                    type: this.command.client.type,
                    is_vip: this.command.client.is_vip,
                    newsletter: this.command.client.newsletter,
                },
            };
        },
        extraFormChanged(componentId) {
            this.productForm.extraForms[componentId].quantity = 1;
            this.$forceUpdate();
        },
        resetAddress() {
            this.command.address.final = null;
            this.command.address.deliveryInfo = false;
            this.addresses = [];
            this.errorMessages.address = null;
        },
        clientAddressChanged(e) {
            this.command.address.final = null;
            this.command.address.deliveryInfo = false;
            const newVal = e.target.value;
            if (newVal.length < 5) {
                this.addresses = [];
                this.errorMessages.address = null;
                this.sessionToken = null;
                return;
            }
            setTimeout(() => {
                this.searchAddress(newVal);
            }, 500);
        },
        selectAddress(address) {
            this.command.address.final = address;
            this.addresses = [];
            this.errorAddress = null;
            this.validateAddress();
        },
        searchAddress(oldAddressVal) {
            if (this.command.address.raw !== oldAddressVal) return;

            if (!this.sessionToken) this.sessionToken = this.generateRandomString();

            axios
                .get(
                    `/api/click_and_collect/${this.restaurant_id}/commands/searchAddress?query=${this.command.address.raw}&sessionToken=${this.sessionToken}`
                )
                .then((response) => {
                    this.errorMessages.address = null;
                    this.addresses = response.data.data;
                    if (this.addresses_.length === 0) this.errorMessages.address = this.$tl("errors.address.no_match");
                })
                .catch((error) => {
                    this.addresses = [];
                    this.errorMessages.address = this.$tl("errors.common.unknown");
                    if (error.response && error.response.data.message) this.errorMessages.address = error.response.data.message;
                    else if (error.message) this.errorMessages.address = error.message;
                });
        },
        validateAddress() {
            if (!this.command.address.final) {
                this.errorMessages.address = this.$tl("errors.address.no_one_selected");
                return;
            }

            axios
                .post(`/api/click_and_collect/${this.restaurant_id}/commands/validateAddress`, {
                    ...this.command.address.final,
                    sessionToken: this.sessionToken,
                })
                .then((response) => {
                    this.sessionToken = null;
                    this.command.address.deliveryInfo = response.data.deliveryCity;
                    this.command.address.raw = `${response.data.placeDetails.address}, ${response.data.placeDetails.postalCode} ${response.data.placeDetails.city}`;
                    this.command.address.final = response.data.placeDetails;
                })
                .catch((error) => {
                    this.addresses = [];
                    if (error.response && error.response.data.reason) {
                        switch (error.response.data.reason) {
                            case "no_config":
                                this.errorMessages.address = this.$tl("errors.common.module_closed");
                                break;
                            case "no_deliverable":
                                this.errorMessages.address = this.$tl("errors.address.no_deliverable");
                                break;
                            case "bad_request":
                                this.errorMessages.address = this.$tl("errors.address.invalid");
                                break;
                            default:
                                this.errorMessages.address = this.$tl("errors.common.unknown");
                                break;
                        }
                    } else if (error.message) this.errorMessages.address = error.message;
                    else this.errorMessages.address = "Une erreur est survenue";
                });
        },
    },
    watch: {
        "command.date": {
            immediate: true,
            handler: function (newVal, oldVal) {
                if (newVal) {
                    this.fetchForDay();
                }
            },
        },
        "command.slot": {
            immediate: true,
            handler: function (newVal, oldVal) {
                if (newVal) {
                    this.fetchProducts(this.command.slot.cc_service_id);
                } else {
                    this.products = [];
                    this.command.products = [];
                }
            },
        },
        "command.collect_type": function () {
            this.fetchData();
            this.fetchForDay();
        },
        "productForm.product": {
            handler: function (newVal, oldVal) {
                if (newVal) {
                    if (!oldVal || newVal.id !== oldVal.id) {
                        this.productForm.quantity = 1;
                    }

                    this.productForm.optionsMandatory = {};
                    this.productForm.extras = {};
                    newVal.cc_product_components.data.forEach((productComponent) => {
                        if (productComponent.is_mandatory && productComponent.cc_product_options.data.length > 0) {
                            this.productForm.optionsMandatory[productComponent.id] = null;
                            if (productComponent.cc_product_options.data.length === 1)
                                this.productForm.optionsMandatory[productComponent.id] = productComponent.cc_product_options.data[0];
                        } else {
                            this.productForm.extras[productComponent.id] = [];
                            this.productForm.extraForms[productComponent.id] = {
                                extra: null,
                                quantity: null,
                            };
                        }
                    });
                }
            },
        },
    },
    components: {
        LoaderComponent,
        VueTimepicker,
        Datepicker,
        ShowErrors,
        SelectClientType,
    },
};
</script>
