var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("NsTable", {
    attrs: {
      tableClass: "table table-striped",
      "allow-sorting": "",
      "data-source": _vm.moduleSubscriptions,
    },
    scopedSlots: _vm._u([
      {
        key: "head",
        fn: function () {
          return [
            _c("ns-th", { attrs: { field: "type", "allow-sorting": "" } }, [
              _vm._v("Module"),
            ]),
            _vm._v(" "),
            _c(
              "ns-th",
              {
                staticClass: "text-right",
                attrs: { field: "total_clients", "allow-sorting": "" },
              },
              [_vm._v("Nombre de clients")]
            ),
            _vm._v(" "),
            _c(
              "ns-th",
              {
                staticClass: "text-right",
                attrs: { field: "total_subscriptions", "allow-sorting": "" },
              },
              [_vm._v("Nombre d'abonnements")]
            ),
            _vm._v(" "),
            _c(
              "ns-th",
              {
                staticClass: "text-right",
                attrs: { field: "monthly_subscriptions", "allow-sorting": "" },
              },
              [_vm._v("Dont abonnements mensuels")]
            ),
            _vm._v(" "),
            _c(
              "ns-th",
              {
                staticClass: "text-right",
                attrs: { field: "yearly_subscriptions", "allow-sorting": "" },
              },
              [_vm._v("Dont abonnements annuels")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function ({ data: moduleSubscription }) {
          return [
            _c("td", [
              _vm._v(_vm._s(_vm.getModuleTypeLabel(moduleSubscription.type))),
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "text-right" }, [
              _vm._v(_vm._s(moduleSubscription.total_clients)),
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "text-right" }, [
              _vm._v(_vm._s(moduleSubscription.total_subscriptions)),
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "text-right" }, [
              _vm._v(_vm._s(moduleSubscription.monthly_subscriptions)),
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "text-right" }, [
              _vm._v(_vm._s(moduleSubscription.yearly_subscriptions)),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }