var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                ])
              : _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("div", { staticClass: "col-md-5 pt-1" }, [
                              _c("label", [_vm._v("Zone")]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-7" },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.area.area,
                                        expression: "area.area",
                                      },
                                    ],
                                    staticClass: "custom-select",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.area,
                                          "area",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        domProps: {
                                          value:
                                            _vm.area.id === null
                                              ? null
                                              : _vm.area.area,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.area.id === null
                                                ? "--"
                                                : _vm.getAreaLabel(
                                                    _vm.area.area
                                                  )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.available_areas,
                                      function (available_area) {
                                        return _c(
                                          "option",
                                          {
                                            key: available_area,
                                            domProps: { value: available_area },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.getAreaLabel(
                                                    available_area
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "area",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("div", { staticClass: "col-md-5 pt-1" }, [
                              _c("label", [_vm._v("Frais d'expédition TTC")]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-7" }, [
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.area.fees,
                                          expression: "area.fees",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "number" },
                                      domProps: { value: _vm.area.fees },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.area,
                                            "fees",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-8 mt-2" }, [
                                    _vm._v("euros"),
                                  ]),
                                  _vm._v(" "),
                                  _c("ShowErrors", {
                                    staticClass: "col-12",
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "fees",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-5 pt-1" }, [
                              _c("label", [_vm._v("Délai d'envoi")]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-7" }, [
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.area.shipping_time,
                                          expression: "area.shipping_time",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "number" },
                                      domProps: {
                                        value: _vm.area.shipping_time,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.area,
                                            "shipping_time",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-8 mt-2" }, [
                                    _vm._v("jours"),
                                  ]),
                                  _vm._v(" "),
                                  _c("ShowErrors", {
                                    staticClass: "col-12",
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "shipping_time",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }