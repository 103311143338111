<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-center text-center">
        <h5 class="text-center">
            {{ message }}
        </h5>
        <button v-if="!isRoutePublic" type="button" @click="redirectToSubscription" class="btn btn-circle btn-success">Modifier mes licences</button>
    </div>
</template>

<script>
export default {
    name: "LicencesErrorsView",
    computed: {
        isRoutePublic() {
            return this.$route.params.public || false;
        },
        message() {
            let message = "";
            if (!this.isRoutePublic) {
                if (this.response) {
                    switch (this.response.data.message) {
                        case "licences.expired":
                            message = "Votre licence a expirée.";
                            break;
                        case "licences.not_found":
                            message = "Aucune licence.";
                            break;
                    }
                }
            } else {
                message = this.$tl("errors.common.widgetDisabled");
            }

            return message;
        },
        response() {
            return this.$store.getters["errors/getResponse"];
        },
    },
    methods: {
        redirectToSubscription() {
            this.$store.commit("errors/setResponse", null);

            this.$router.push({ name: "subscription" });
        },
    },
};
</script>
