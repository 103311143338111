<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else class="div-profil">
                <div class="row">
                    <div class="col-md-7 mb-3">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row">
                                <div class="col-6">Numéro</div>
                                <div class="col-6 value">
                                    {{ currentSale.numero }}
                                </div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-6">Statut</div>
                                <div class="col-6 value">
                                    {{ capitalize(getSaleStatusLabel(currentSale.status)) }}
                                </div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-6">Utilisé</div>
                                <div class="col-6 value">
                                    {{ currentSale.used ? "Oui" : "Non" }}
                                </div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-6">Produit</div>
                                <div class="col-6 value">
                                    <template v-if="currentSale.gv_product">
                                        <span class="d-block" v-if="currentSale.gv_product.type === 'menu'">
                                            {{ currentSale.menu_quantity }} x {{ currentSale.gv_product.name }}
                                        </span>
                                        <span class="d-block" v-if="currentSale.gv_product.type === 'amount'">
                                            {{ currentSale.gv_product.name }}
                                        </span>
                                    </template>
                                    <span class="d-block" v-else>{{ currentSale.imported_product_name || "--" }}</span>
                                    <span class="d-block" v-if="currentSale.expedition_type === 'postal_mail'">1 x Livraison</span>
                                </div>
                            </div>
                            <div v-if="currentSale.gv_sale_product_options.data.length > 0" class="sepa"></div>
                            <div v-if="currentSale.gv_sale_product_options.data.length > 0" class="row">
                                <div class="col-6">Options</div>
                                <div class="col-6 value">
                                    <div class="row" v-for="products_option in currentSale.gv_sale_product_options.data" :key="products_option.id">
                                        <div class="col-12">
                                            {{ products_option.quantity }} x
                                            {{ products_option.gv_products_option.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-6">Montant</div>
                                <div class="col-6 value">
                                    {{
                                        currentSale.amount !== null
                                            ? formatCurrency(
                                                  currentSale.expedition_fees !== null
                                                      ? currentSale.amount + currentSale.expedition_fees
                                                      : currentSale.amount
                                              )
                                            : "--"
                                    }}
                                </div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-6">Moyen de paiement</div>
                                <div class="col-6 value">
                                    {{ getPaymentTypeLabel(currentSale.payment_type) }}
                                </div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-6">Date d'expiration</div>
                                <div class="col-6 value">
                                    {{ formatDateToFr(currentSale.expires_on) }}
                                </div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-12 mb-1">Commentaire public</div>
                                <div class="col-12 value">
                                    {{ currentSale.comment_client }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 mb-3">
                        <loader-component v-if="isLoadingClient" />
                        <template v-else>
                            <client-card
                                v-if="currentSale.client"
                                :client="currentSale.client"
                                :module-enum="MODULE_TYPE_GIFT_VOUCHERS"
                                :is-change-in-progress="isAssociatingInProgress"
                                @cancel-in-progress="emptyClient"
                                @update-in-progress="associateClientBack" />
                            <div v-else class="d-flex justify-content-center align-items-center h-100">
                                <button class="btn btn-sm btn-success btn-circle" @click="canOpenSearchClientModal = true">
                                    {{ $t("labels.form.actions.associate.client") }}
                                </button>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="row mb-3" v-if="currentSale.has_beneficiary">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <label class="mb-1">
                                <strong>Bénéficiaire</strong>
                            </label>
                            <div class="row">
                                <div class="col-12">{{ currentSale.beneficiary_firstname }} {{ currentSale.beneficiary_lastname }}</div>
                            </div>
                            <div class="row" v-if="currentSale.beneficiary_email">
                                <div class="col-12">
                                    {{ currentSale.beneficiary_email }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3" v-if="currentSale.expedition_type === EXPEDITION_TYPE_POSTAL_MAIL.value">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <label class="mb-1">
                                <strong>Expédition</strong>
                            </label>
                            <div class="row">
                                <div class="col-12">{{ currentSale.delivery_firstname }} {{ currentSale.delivery_lastname }}</div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <span>{{ currentSale.full_delivery_address }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <label class="mb-1">
                                <strong>Commentaire privé</strong>
                            </label>
                            <div class="row">
                                <div class="col-12">
                                    {{ currentSale.comment_private }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 mb-3">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row" style="cursor: pointer" @click="showLog = !showLog">
                                <div class="col-12">
                                    <strong class="mb-0">Historique des modifications</strong>
                                </div>
                            </div>
                            <template v-if="showLog">
                                <ShowSaleLogsList :sale="currentSale" />
                            </template>
                        </div>
                    </div>
                </div>

                <div class="row m-0">
                    <div class="col-12 text-right pr-4">
                        <div>
                            <i
                                >Bon cadeau {{ getSaleTypeLabel(currentSale.sale_type)
                                }}<span v-if="currentSale.sale_type != SALE_TYPE_IMPORTED.value"> ajouté</span> le
                                {{ formatDateTime(currentSale.created_at) }}<span v-if="saleUserDisplayable"> par {{ saleUserDisplayable }}</span
                                >.<template v-if="showSendBy">
                                    Envoyé par {{ getExpeditionTypeLabel(currentSale.expedition_type) }} au
                                    {{ currentSale.has_beneficiary ? "bénéficiaire" : "client" }}</template
                                ></i
                            >
                        </div>
                        <template v-if="currentSale.mail_logs">
                            <div v-for="mailLog in currentSale.mail_logs.data" :key="mailLog.id">
                                <small v-if="mailLog.permanent_failed_at || !mailLog.sent_at" class="text-danger"
                                    >Une erreur est survenue lors de l'envoi du mail de {{ getSaleMailLogLabel(mailLog.type) }}</small
                                >
                                <small v-else-if="mailLog.sent_at && !mailLog.delivered_at" class="text-warning"
                                    >Le mail de {{ getSaleMailLogLabel(mailLog.type) }} est en cours d'envoi</small
                                >
                                <small v-else-if="mailLog.delivered_at"
                                    >Mail de {{ getSaleMailLogLabel(mailLog.type) }} envoyé le
                                    {{ moment(mailLog.delivered_at).format("DD/MM/Y[ à ]HH:mm")
                                    }}<span v-if="mailLog.opened_at"> (ouvert)</span></small
                                >
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <wrapper-search-client-modal v-if="canOpenSearchClientModal" @close="canOpenSearchClientModal = false" @selected-client="associateClient" />
    </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent.vue";
import moment from "moment";
import PaymentTypesEnum from "../../mixins/enums/gift_vouchers/PaymentTypesEnum.js";
import ExpeditionTypesEnum from "../../mixins/enums/gift_vouchers/ExpeditionTypesEnum.js";
import SaleTypesEnum from "../../mixins/enums/gift_vouchers/SaleTypesEnum.js";
import SaleStatusEnum from "../../mixins/enums/gift_vouchers/SaleStatusEnum.js";
import SaleMailLogEnum from "../../mixins/enums/gift_vouchers/SaleMailLogEnum.js";
import ShowSaleLogsList from "../../components/giftVouchers/logs/ShowSaleLogsList.vue";
import ClientCard from "../Default/Clients/clientCard.vue";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum.js";
import WrapperSearchClientModal from "../Modals/clients/WrapperSearchClientModal.vue";

moment.locale("fr");

export default {
    data() {
        return {
            loading: false,
            error: null,
            showLog: false,
            moment,
            currentSale: {},
            canOpenSearchClientModal: false,
            isAssociatingInProgress: false,
            isLoadingClient: false,
        };
    },
    mixins: [PaymentTypesEnum, ExpeditionTypesEnum, SaleTypesEnum, SaleStatusEnum, SaleMailLogEnum, ModuleTypesEnum],
    props: {
        sale: {
            type: Object,
            default: null,
        },
        sale_id: {
            default: null,
        },
        mustNotEmit: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        showSendBy() {
            return (
                this.currentSale.sale_type != this.SALE_TYPE_IMPORTED.value &&
                (!this.currentSale.expedition_type === this.EXPEDITION_TYPE_EMAIL.value ||
                    !this.currentSale.has_beneficiary ||
                    (this.currentSale.has_beneficiary && this.currentSale.beneficiary_email))
            );
        },
        saleUserDisplayable() {
            if (!this.sale || !this.currentSale.user) return null;
            return `${this.currentSale.user.firstname} ${this.currentSale.user.lastname}`;
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        formatDateTime(dateTime) {
            return moment(dateTime).format("LLL");
        },
        formatDateToFr(date) {
            return moment(date).format("DD/MM/Y");
        },
        fetchData() {
            this.loading = true;

            axios
                .get(
                    `/api/gift_vouchers/${this.restaurant_id}/sales/${this.sale_id}?include=user,client,gv_product,gv_sale_product_options,gv_sale_product_options.gv_products_option,mail_logs,logs.user`
                )
                .then((response) => {
                    this.loading = false;
                    if (this.mustNotEmit) {
                        this.currentSale = response.data;
                    } else {
                        this.$emit("set-sale", response.data);
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        associateClientBack() {
            this.isClientLoading = true;
            this.httpPut(`/api/gift_vouchers/${this.restaurant_id}/sales/${this.sale_id}/clients/${this.currentSale.client.id}/associate`)
                .then((response) => {
                    if (response !== false) {
                        this.isAssociatingInProgress = false;
                    }
                })
                .finally(() => (this.isClientLoading = false));
        },
        emptyClient() {
            this.currentSale.client = null;
            this.isAssociatingInProgress = false;
        },
        associateClient(event) {
            this.currentSale.client = event;
            this.isAssociatingInProgress = true;
            this.canOpenSearchClientModal = false;
        },
    },
    created() {
        if (this.sale_id !== null && this.sale === null) {
            this.fetchData();
        } else if (this.sale !== null) {
            this.currentSale = this.sale;
        } else {
            throw error("Props sale_id or sale is required");
        }
    },
    mounted() {
        if (this.$refs.vueTelInput) {
            this.$refs.vueTelInput.$el.classList.remove("vue-tel-input");
        }
    },
    watch: {
        sale: {
            handler(value) {
                if (this.sale_id !== null && this.mustNotEmit) {
                    return;
                }
                this.currentSale = value;
            },
            deep: true,
        },
    },
    components: {
        LoaderComponent,
        ShowSaleLogsList,
        ClientCard,
        WrapperSearchClientModal,
    },
};
</script>
