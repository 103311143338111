<template>
    <div class="p-2">
        <loader-component v-if="loading" />
        <div v-else-if="appUpdate !== null">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-between mt-1">
                        <div>
                            <h5 class="title">{{ $t("labels.appUpdates.title", { date: displayDate(appUpdate.update_date, DATE_MED) }) }}</h5>
                            <small>{{ $t("labels.appUpdates.lastUpdate", { date: displayDate(appUpdate.updated_at, DATE_MED) }) }}</small>
                        </div>
                        <div>
                            <span v-for="tmpModule in appUpdate.modules" :key="tmpModule" class="badge badge-success mr-1">
                                {{ getModuleTypeLabel(tmpModule) }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sepa my-2"></div>
            <div class="row">
                <div class="col-12 ck-content" style="overflow-x: auto" v-html="appUpdate.content"></div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";

export default {
    data() {
        return {
            loading: false,
            appUpdate: null,
        };
    },
    mixins: [ModuleTypesEnum],
    computed: {
        appUpdateId() {
            return Number.parseInt(this.$route.params.app_update_id);
        },
    },
    methods: {
        fetchAppUpdate() {
            this.loading = true;

            this.httpGet(`/api/app_updates/${this.appUpdateId}`)
                .then((response) => {
                    if (response !== false) {
                        this.appUpdate = response.data;
                    }
                })
                .finally(() => (this.loading = false));
        },
    },
    created() {
        this.fetchAppUpdate();
    },
    components: {
        LoaderComponent,
    },
};
</script>
