export default {
    common: {
        services: "You must first create services to edit this parameter.",
    },
    roles: {
        kitchen:
            "The kitchen account is intended to be the account accessible from a tablet provided in the kitchen. It provides access to the list of reservations, menus chosen by customers at the time of reservation, customer comments and internal notes (allergies, intolerances, etc.).",
    },
    booking: {
        services: {
            maxPaxIfRotation: "If table rotation is enabled, this is the maximum number of covers in real time",
            enableBack: "To re-enable a service, go to your {dashboard}.",
            cantDelete: "You cannot delete this service because future reservations are associated with it",
            hasFutureReservations: "This service refers to several upcoming reservations.",
            changeSeatingPlan: [
                "If you confirm the change of seating plan on this service, future reservations will no longer be linked to the tables of the old seating plan, and the seating preferences will not be kept.",
                "The algorithm will attempt to move your reservations to the next reservation period.",
                "If, however, reservations cannot be placed, taking reservations will be temporarily suspended.",
                "We strongly advise you to check your upcoming services.",
            ],
        },
        reservations: {
            menus: {
                unique: "A single menu is requested for the whole table",
            },
            add: {
                changeSlotSelectedTables: "Please unlink the tables so that you can choose another slot",
                willBePlacedAuto:
                    "The reservation will be placed on your seating plan automatically, choose a table only if you want to assign a specific table to your customer.",
            },
            edit: {
                date: "Please note: changing the date will automatically delete the selected table.",
                nbPers: "Please note: changing the number of adults will automatically delete the selected table.",
                nbChildren: "Please note: changing the date will automatically delete the selected table.",
                slot: "Please note: changing the reservation slot will automatically delete the selected table.",
                changeRestaurant: "Please note: delete the selected table to move the reservation to another establishment.",
                duration: "To edit reservation duration, you must first unlink the selected table.",
            },
        },
    },
};
