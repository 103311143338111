var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("tab-bar", {
        attrs: {
          direction: "horizontal",
          side: "top",
          entries: _vm.tabBarEntries,
        },
      }),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }