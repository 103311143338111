var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading ? _c("loader-component") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading",
            },
          ],
        },
        [
          _c("div", { staticClass: "mb-4 pointer" }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("clear-selected-form")
                  },
                },
              },
              [
                _c("feather", {
                  staticClass: "mr-1",
                  attrs: { type: "chevron-left" },
                }),
                _vm._v(_vm._s(_vm.$tl("labels.form.actions.back")) + " "),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.formError
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v("\n            " + _vm._s(_vm.formError) + "\n        "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.cardError
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v("\n            " + _vm._s(_vm.cardError) + "\n        "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { ref: "cardForm" }),
          _vm._v(" "),
          _vm.showButtons
            ? _c(
                "button",
                {
                  staticClass: "btn btn-success btn-circle",
                  class: { disabled: _vm.cardError },
                  attrs: { disabled: _vm.cardError },
                  on: { click: _vm.save },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$tl("labels.form.actions.save")) +
                      "\n        "
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }