<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ modalTitle }}</h5>
        <div slot="body">
            <LoaderComponent v-if="loading" />
            <component
                v-if="restaurant.id"
                :restaurant_id="restaurant.id"
                v-show="!loading"
                :restaurant="restaurant"
                :is="currentComponent"
                :formErrors="formErrors" />
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="close" data-test-id="btn-close_modal">
                {{ $tl("labels.form.actions.close") }}
            </button>
            <button class="btn-sm btn btn-secondary btn-circle" v-if="step < 2" @click="nextStep(false)">
                {{ $tl("labels.form.actions.skip") }}
            </button>
            <button class="btn-sm btn btn-secondary btn-circle" v-if="step < 2" @click="nextStep()">
                {{ $tl("labels.form.actions.validate") }}
            </button>
        </div>
    </modal>
</template>

<script>
import LoaderComponent from "../LoaderComponent";
import EditSmsSettings from "../sms/EditSmsSettings";
import StripeConnection from "../Default/Restaurants/StripeConnection";

export default {
    data() {
        return {
            loading: false,
            step: 1,
            formErrors: null,
            restaurant: {
                logo: {},
            },
        };
    },
    props: {
        restaurant_id: {
            type: Number,
            required: true,
        },
        smsActived: {
            default: false,
        },
    },
    computed: {
        modalTitle() {
            if (this.step == 1) return this.$tl("labels.form.sms.edit");
            if (this.step == 2) return this.$tl("labels.form.psp.stripe.config");
            return this.$tl("labels.form.configEnded");
        },
        currentComponent() {
            if (this.step == 1) return "EditSmsSettings";
            if (this.step == 2) return "StripeConnection";
        },
    },
    methods: {
        fetchRestaurantById() {
            this.loading = true;
            this.error = false;
            this.$store
                .dispatch("restaurants/fetchRestaurantById", {
                    restaurant_id: this.restaurant_id,
                    includes: ["notifications_setting"],
                })
                .then((response) => {
                    this.restaurant = response.data;
                    this.restaurant.notifications_setting.sms_emitter_name = this.restaurant.notifications_setting.sms_emitter_name || "";
                    this.loading = false;
                })
                .catch((error) => {
                    if (error.response && error.response.data.message) {
                        this.error = error.response.data.message;
                    } else if (error.message) {
                        this.error = error.message;
                    } else {
                        this.error = "une erreur est survenue.";
                    }
                    this.loading = false;
                });
        },
        postSms() {
            this.formErrors = null;
            this.loading = true;

            this.$store
                .dispatch("restaurants/editSmsSettings", {
                    restaurant_id: this.restaurant_id,
                    formData: this.restaurant.notifications_setting,
                })
                .then(() => {
                    this.loading = false;
                    this.step++;
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response) this.formErrors = error.response.data.errors;
                });
        },
        nextStep(save = true) {
            if (!save) {
                this.step++;
                return;
            }
            if (this.step == 1) this.postSms();
            else if (this.step == 2) this.close();
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        EditSmsSettings,
        StripeConnection,
        LoaderComponent,
    },
    created() {
        this.fetchRestaurantById();
    },
};
</script>
