export default {
    data() {
        return {
            TABLE_TYPE_TABLE: {
                value: "table",
            },
            TABLE_TYPE_COUNTER: {
                value: "counter",
            },
            TABLE_TYPE_DELIMITER: {
                value: "delimiter",
            },
            TABLE_TYPE_FLOWER: {
                value: "flower",
            },
            TABLE_TYPE_AQUARIUM: {
                value: "aquarium",
            },
            TABLE_TYPE_WARDROBE_WINE: {
                value: "wardrobe-wine",
            },
            TABLE_TYPE_CHIMNEY: {
                value: "chimney",
            },
            TABLE_TYPE_KITCHEN: {
                value: "kitchen",
            },
            TABLE_TYPE_STAIRS1: {
                value: "stairs1",
            },
            TABLE_TYPE_STAIRS1_RIGHT: {
                value: "stairs1-right",
            },
            TABLE_TYPE_STAIRS2: {
                value: "stairs2",
            },
            TABLE_TYPE_STAIRS2_RIGHT: {
                value: "stairs2-right",
            },
            TABLE_TYPE_STAIRS3: {
                value: "stairs3",
            },
            TABLE_TYPE_STAIRS3_LEFT: {
                value: "stairs3-left",
            },
            TABLE_TYPE_STAIRS4: {
                value: "stairs4",
            },
            TABLE_TYPE_CAR_PARK: {
                value: "car-park",
            },
            TABLE_TYPE_TOILET: {
                value: "toilet",
            },
            TABLE_TYPE_POOL: {
                value: "pool",
            },
            TABLE_TYPE_BEACH: {
                value: "beach",
            },
            TABLE_TYPE_DOOR1: {
                value: "door1",
            },
            TABLE_TYPE_DOOR2: {
                value: "door2",
            },
            TABLE_TYPE_BUFFET: {
                value: "buffet",
            },
            TABLE_TYPE_FRIDGE: {
                value: "fridge",
            },
        };
    },
    methods: {
        getTableTypeLabel(value) {
            const found = this.ALL_TABLE_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") {
                return `${value}-label`;
            }
            console.error("No SeatingPlanTableType for given value", value);
            return value;
        },
    },
    computed: {
        ALL_TABLE_TYPES() {
            return [
                // Table
                this.TABLE_TYPE_TABLE,

                // Decoration
                this.TABLE_TYPE_COUNTER,
                this.TABLE_TYPE_DELIMITER,
                this.TABLE_TYPE_FLOWER,
                this.TABLE_TYPE_AQUARIUM,
                this.TABLE_TYPE_WARDROBE_WINE,
                this.TABLE_TYPE_CHIMNEY,
                this.TABLE_TYPE_KITCHEN,
                this.TABLE_TYPE_STAIRS1,
                this.TABLE_TYPE_STAIRS1_RIGHT,
                this.TABLE_TYPE_STAIRS2,
                this.TABLE_TYPE_STAIRS2_RIGHT,
                this.TABLE_TYPE_STAIRS3,
                this.TABLE_TYPE_STAIRS3_LEFT,
                this.TABLE_TYPE_STAIRS4,
                this.TABLE_TYPE_CAR_PARK,
                this.TABLE_TYPE_TOILET,
                this.TABLE_TYPE_POOL,
                this.TABLE_TYPE_BEACH,
                this.TABLE_TYPE_DOOR1,
                this.TABLE_TYPE_DOOR2,
                this.TABLE_TYPE_BUFFET,
                this.TABLE_TYPE_FRIDGE,
            ];
        },
        ALL_TABLE_TYPES_LABELS() {
            return this.ALL_TABLE_TYPES.map((tableType) => this.getTableTypeLabel(tableType.value));
        },
    },
};
