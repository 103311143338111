export default {
    methods: {
        isUserAgentValid() {
            const userAgent = navigator.userAgent;

            if (userAgent) {
                const refusedBrowserRegex = [/edg\//i, /edge\//i, /trident\//i, /msie /i];

                return !refusedBrowserRegex.some((regex) => userAgent.match(regex));
            }

            return false;
        },
    },
};
