<template>
    <div class="row">
        <div class="col-md-4">
            <div class="row mb-2">
                <div class="col-md-4 pt-1">
                    <label>{{ $tl("labels.booking.reservations.edit.date") }} <small>*</small></label>
                </div>
                <div class="col-md-8 date-resa-cal" style="font-size: 0.75rem">
                    <DatePicker
                        :disabledDates="disabledDates"
                        tabindex="1"
                        :disabled="readOnly"
                        format="ccc DDD"
                        :highlighted="highlighted"
                        v-tooltip="
                            hasTablesSelected
                                ? {
                                      content: $tl('infos.booking.reservations.edit.date', restaurant_id),
                                  }
                                : undefined
                        "
                        :class="{ 'is-invalid': hasErrors('reservation_date') }"
                        @input="hasTablesSelected ? $emit('unlink-all-tables') : undefined"
                        v-model="reservation_dat" />
                    <div v-if="hasErrors('reservation_date')">
                        <div class="invalid-feedback d-block" v-for="err in formErrors.errors.reservation_date">
                            {{ err }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="row mb-2">
                <div class="col-md-7 pt-1">
                    <label for="nb_pers">{{ $tl("labels.booking.reservations.edit.nbPers") }} <small>*</small></label>
                </div>
                <div class="col-md-5">
                    <input
                        id="nb_pers"
                        :disabled="readOnly"
                        v-tooltip="
                            hasTablesSelected
                                ? {
                                      content: $tl('infos.booking.reservations.edit.nbPers', restaurant_id),
                                  }
                                : undefined
                        "
                        @focus="hasTablesSelected ? $emit('unlink-all-tables') : undefined"
                        @change="hasTablesSelected ? $emit('unlink-all-tables') : undefined"
                        v-model="nb_per"
                        class="form-control"
                        :class="{ 'is-invalid': hasErrors('nb_pers') }"
                        type="number"
                        min="1"
                        max="1500"
                        autocomplete="off"
                        tabindex="2"
                        required />
                    <div v-if="hasErrors('nb_pers')">
                        <div class="invalid-feedback d-block" v-for="err in formErrors.errors.nb_pers">
                            {{ err }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="row mb-2">
                <div class="col-md-7 pt-1">
                    <label for="nb_children">{{ $tl("labels.booking.reservations.edit.nbChildren") }}</label>
                </div>
                <div class="col-md-5">
                    <input
                        id="nb_children"
                        :disabled="readOnly"
                        v-tooltip="
                            hasTablesSelected
                                ? {
                                      content: $tl('infos.booking.reservations.edit.nbChildren', restaurant_id),
                                  }
                                : undefined
                        "
                        @focus="hasTablesSelected ? $emit('unlink-all-tables') : undefined"
                        @change="hasTablesSelected ? $emit('unlink-all-tables') : undefined"
                        class="form-control"
                        v-model="nb_child"
                        :class="{ 'is-invalid': hasErrors('nb_children') }"
                        type="number"
                        min="0"
                        autocomplete="off"
                        tabindex="3"
                        max="1500" />
                    <div v-if="hasErrors('nb_children')">
                        <div class="invalid-feedback d-block" v-for="err in formErrors.errors.nb_children">
                            {{ err }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from "../forms/DatePicker.vue";
import axios from "axios";
import _ from "lodash";

export default {
    name: "servicesReservations",
    components: {
        DatePicker,
    },
    data() {
        return this.$_.cloneDeep(
            this.$_.defaultsDeep(this.defaultValues, {
                days: [],
                ranges: [],
                disabledDates: {
                    customPredictor: this.datepickerAvailabilityPredictor,
                },
                highlighted: {
                    includeDisabled: false,
                    from: null,
                    to: null,
                },
            })
        );
    },
    props: {
        hasTablesSelected: {
            default: false,
        },
        restaurant_id: {
            default: null,
        },
        nb_pers: {
            default: 0,
        },
        nb_children: {
            default: null,
        },
        reservation_date: {
            default: null,
        },
        formErrors: {
            default: null,
        },
        readOnly: {
            default: false,
        },
    },
    methods: {
        datepickerAvailabilityPredictor(date) {
            if (this.days.includes(date.getDay())) {
                return false;
            }

            for (var range of this.ranges) {
                var from = this.getDateTime(range.from, false).toJSDateCustom();
                var to = this.getDateTime(range.to, false).toJSDateCustom();

                if (from.getTime() <= date.getTime() && date.getTime() <= to.getTime() && range.days.includes(date.getDay())) {
                    return false;
                }
            }

            return true;
        },
        loadOpeningDays() {
            this.$emit("update-loading-services", true);
            return axios
                .get(`/api/restaurants/${this.restaurant_id}/opening`)
                .then((response) => {
                    this.days = response.data.days ?? [];
                    this.ranges = response.data.ranges;
                })
                .catch(() => {});
        },
        setDisabledDates() {
            var disabledDates = [];
            Object.entries(this.services).forEach(([key, service]) => {
                if (service.days.monday === 1) {
                    disabledDates.push(1);
                }
                if (service.days.tuesday === 1) {
                    disabledDates.push(2);
                }

                if (service.days.wednesday === 1) {
                    disabledDates.push(3);
                }
                if (service.days.thursday === 1) {
                    disabledDates.push(4);
                }
                if (service.days.friday === 1) {
                    disabledDates.push(5);
                }
                if (service.days.saturday === 1) {
                    disabledDates.push(6);
                }
                if (service.days.sunday === 1) {
                    disabledDates.push(0);
                }
            });
            disabledDates = [...new Set(disabledDates)];
            if (!disabledDates.includes(1)) {
                this.disabledDates["days"].push(1);
            }
            if (!disabledDates.includes(2)) {
                this.disabledDates["days"].push(2);
            }
            if (!disabledDates.includes(3)) {
                this.disabledDates["days"].push(3);
            }
            if (!disabledDates.includes(4)) {
                this.disabledDates["days"].push(4);
            }
            if (!disabledDates.includes(5)) {
                this.disabledDates["days"].push(5);
            }
            if (!disabledDates.includes(6)) {
                this.disabledDates["days"].push(6);
            }
            if (!disabledDates.includes(0)) {
                this.disabledDates["days"].push(0);
            }
        },
        setHightlightedDates() {
            Object.entries(this.services).forEach(([key, service]) => {
                if ("special_begin" in service) {
                    this.highlighted.from = this.getDateTime(service["special_begin"], false).toJSDateCustom();
                    this.highlighted.to = this.getDateTime(service["special_end"], false).toJSDateCustom();
                }
            });
        },
        hasErrors(name) {
            return this.formErrors && this.formErrors.errors && !_.isEmpty(this.formErrors.errors[name]);
        },
    },
    mounted() {
        this.loadOpeningDays();
        this.$emit("update-loading-services", false);
    },
    computed: {
        reservation_dat: {
            get() {
                return this.reservation_date;
            },
            set(value) {
                this.$emit("update-reservation-date", this.getDateTime(value, false));
            },
        },
        nb_per: {
            get: function () {
                return this.nb_pers;
            },
            set: function (value) {
                this.$emit("update-nb-pers", value);
            },
        },
        nb_child: {
            get: function () {
                return this.nb_children;
            },
            set: function (value) {
                this.$emit("update-nb-children", value);
            },
        },
    },
};
</script>
