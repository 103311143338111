<template>
    <div class="pb-5 pt-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Livraison</h5>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <div v-show="!loading" class="row m-0">
            <div class="alert alert-danger" v-if="error">{{ error }}</div>
            <div class="col-12">
                <div class="row mb-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row">
                                <div class="col-md-5">
                                    <strong>Livraison</strong>
                                </div>
                                <div class="col-md-7 pt-1">
                                    <div class="d-inline-block align-middle lead-switch">
                                        <input
                                            :disabled="!has_right_to_update_config"
                                            type="checkbox"
                                            class="switch align-self-center is-rounded"
                                            v-model="deliveryEnable" />
                                        <label @click="deliveryEnable = has_right_to_update_config ? !deliveryEnable : deliveryEnable"></label>
                                    </div>
                                </div>
                            </div>
                            <template v-if="deliveryEnable">
                                <div class="row mt-4">
                                    <div class="col-md-5"><label>Plages horaires de livraison</label> <small>*</small></div>
                                    <div class="col-md-7 pt-2">
                                        <ShowErrors class="d-block" :errors="errors" errorKey="delivery_slots" />
                                        <show-errors :errors="errors" errorKey="delivery_slots.*" />
                                        <div v-for="day in weekDays" :key="day">
                                            <div class="mb-2">
                                                <label class="container-box" style="margin-bottom: 0; width: 100%">
                                                    <input
                                                        type="checkbox"
                                                        v-model="deliverySlots[day].active"
                                                        :disabled="!has_right_to_update_config" />
                                                    <span class="checkmark" :class="{ disabled: !has_right_to_update_config }"></span>
                                                    {{ dayLabel(day, true) }}
                                                </label>
                                            </div>
                                            <div class="setting-open" v-if="deliverySlots[day].active">
                                                <div class="setting-hours">
                                                    <span>
                                                        <div v-for="(slot, index) in deliverySlots[day].slots" :key="index" class="mb-1">
                                                            <template v-if="slot.id === null">
                                                                <vue-timepicker
                                                                    :disabled="!has_right_to_update_config"
                                                                    format="HH:mm"
                                                                    :minute-interval="15"
                                                                    v-model="slot.hour_start"></vue-timepicker>
                                                                <vue-timepicker
                                                                    :disabled="!has_right_to_update_config"
                                                                    format="HH:mm"
                                                                    :minute-interval="15"
                                                                    v-model="slot.hour_end"
                                                                    :hour-range="calculHourRange(slot.hour_start)"
                                                                    hide-disabled-hours></vue-timepicker>
                                                            </template>
                                                            <template v-else>
                                                                <span>{{ formatHour(slot.hour_start) }}</span> -
                                                                <span>{{ formatHour(slot.hour_end) }}</span>
                                                            </template>
                                                            <svg
                                                                @click="deleteDeliverySlot(day, index)"
                                                                style="color: red"
                                                                viewBox="0 0 24 24"
                                                                width="24"
                                                                height="24"
                                                                stroke="currentColor"
                                                                stroke-width="2"
                                                                fill="none"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather css-i6dzq1 pt-1">
                                                                <line x1="18" y1="6" x2="6" y2="18" />
                                                                <line x1="6" y1="6" x2="18" y2="18" />
                                                            </svg>
                                                        </div>
                                                        <svg
                                                            v-if="has_right_to_update_config"
                                                            @click="addDeliverySlot(day)"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            aria-hidden="true"
                                                            class="feather feather-plus sc-dnqmqq jxshSx">
                                                            <line x1="12" y1="5" x2="12" y2="19" />
                                                            <line x1="5" y1="12" x2="19" y2="12" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-md-5 pt-1">
                                        <label>Zone de livraison <small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <button
                                            v-if="this.cc_configuration_id !== null"
                                            :disabled="!has_right_to_update_config"
                                            @click="addDeliveryArea"
                                            class="btn btn-sm btn-success btn-circle">
                                            <feather type="plus" />
                                            <span> Ajouter une zone de livraison</span>
                                        </button>
                                        <span v-else class="text-danger"
                                            >Merci d'enregistrer une première fois votre configuration afin de pouvoir ajouter des zones de
                                            livraison</span
                                        >
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-md-5 pt-1">
                                        <label>Adresse du point de départ de livraison</label>
                                    </div>
                                    <div class="col-md-7 pt-1">
                                        <input v-if="!displayableDeliveryDeparture" type="text" class="form-control mb-2" v-model="currentAddress" />
                                        <div class="d-flex" v-else>
                                            <input :disabled="true" type="text" class="form-control mb-2" :value="displayableDeliveryDeparture" />
                                            <feather type="x" class="text-danger mt-2 ml-1 pointer" @click="resetDeparture" />
                                        </div>
                                        <span class="d-block text-danger" v-if="errorAddress">{{ errorAddress }}</span>
                                        <LoaderComponent v-if="loadingPlaceDetails" />
                                        <div class="dropdown-menu prename-field shadow show p-0" v-else-if="availableAddresses.length > 0">
                                            <ul class="list-group">
                                                <li class="list-group-item p-0" v-for="(address, index) in availableAddresses" :key="index">
                                                    <a class="d-block p-3" href="javascript:" @click="addAddress(address)">
                                                        <span>{{ address.description }}</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <ShowErrors class="d-block" :errors="errors" errorKey="delivery_departure_address" />
                                        <ShowErrors class="d-block" :errors="errors" errorKey="delivery_departure_postal_code" />
                                        <ShowErrors class="d-block" :errors="errors" errorKey="delivery_departure_city" />
                                        <ShowErrors class="d-block" :errors="errors" errorKey="delivery_departure_country" />
                                        <ShowErrors class="d-block" :errors="errors" errorKey="delivery_departure_lat" />
                                        <ShowErrors class="d-block" :errors="errors" errorKey="delivery_departure_lng" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5 pt-1">
                                        <label>Paramétrage des zones de livraison</label>
                                    </div>
                                    <div class="col-md-7 pt-1">
                                        <div class="row">
                                            <div class="col-12 mb-1" v-for="areaType in ALL_DELIVERY_AREA_TYPES" :key="areaType.value">
                                                <div class="radio">
                                                    <label
                                                        class="container-box"
                                                        v-tooltip="
                                                            areaType.value == DELIVERY_AREA_TYPE_CITIES.value || displayableDeliveryDeparture
                                                                ? undefined
                                                                : {
                                                                      content: `Merci de saisir l'adresse du point de départ avant de configurer les zones '${areaType.label.toLowerCase()}'`,
                                                                  }
                                                        ">
                                                        <input
                                                            type="radio"
                                                            v-model="delivery_areas_type"
                                                            :value="areaType.value"
                                                            :disabled="
                                                                !has_right_to_update_config ||
                                                                (areaType.value != DELIVERY_AREA_TYPE_CITIES.value && !displayableDeliveryDeparture)
                                                            " />
                                                        <span class="checkmark"></span> {{ areaType.label }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1 mb-3" v-if="cc_configuration_id !== null">
                                    <div class="col-12">
                                        <div class="border-light b-radius-20 p-4 mt-4">
                                            <button
                                                v-if="delivery_areas_type == DELIVERY_AREA_TYPE_DRAWN.value"
                                                @click="showEditClickAndCollectDrawDeliveryAreas = true"
                                                class="btn btn-sm btn-success btn-circle mb-1">
                                                Editer les zones de livraison
                                            </button>
                                            <template v-if="deliveryAreas_.length > 0">
                                                <button
                                                    v-if="delivery_areas_type == DELIVERY_AREA_TYPE_CROW_FLIES.value"
                                                    @click="viewOnGMaps"
                                                    class="btn btn-sm btn-success btn-circle mb-1">
                                                    Voir les zones de livraison sur une carte
                                                </button>
                                                <table class="table table-sm table-striped border-bottom">
                                                    <thead class="border-bottom">
                                                        <tr>
                                                            <th
                                                                :style="
                                                                    delivery_areas_type != DELIVERY_AREA_TYPE_DRAWN.value
                                                                        ? 'width: 40% !important;'
                                                                        : ''
                                                                ">
                                                                Zone
                                                            </th>
                                                            <th v-if="delivery_areas_type == DELIVERY_AREA_TYPE_DRAWN.value">Couleur</th>
                                                            <th>Prix (TTC)</th>
                                                            <th v-if="!isTvaDisabled">TVA</th>
                                                            <th>Livraison gratuite</th>
                                                            <th>Montant minimum</th>
                                                            <th>Créneaux</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="area in deliveryAreas_" :key="area.id">
                                                            <td
                                                                :style="
                                                                    delivery_areas_type != DELIVERY_AREA_TYPE_DRAWN.value
                                                                        ? 'width: 40% !important;'
                                                                        : ''
                                                                ">
                                                                <span v-if="area.type == DELIVERY_AREA_TYPE_CITIES.value">{{
                                                                    area.cities.data.map((c) => `${c.name} (${c.postcode})`).join(" - ")
                                                                }}</span>
                                                                <span v-else-if="area.type == DELIVERY_AREA_TYPE_CROW_FLIES.value">
                                                                    <span class="d-block" v-if="area.distance_min_km"
                                                                        >Entre {{ area.distance_min_km }} km et {{ area.distance_max_km }} km du point
                                                                        de départ de livraison</span
                                                                    >
                                                                    <span class="d-block" v-else
                                                                        >À moins de {{ area.distance_max_km }} km du point de départ de
                                                                        livraison</span
                                                                    >
                                                                </span>
                                                                <span v-else-if="area.type == DELIVERY_AREA_TYPE_DRAWN.value">{{ area.name }}</span>
                                                            </td>
                                                            <td v-if="area.type == DELIVERY_AREA_TYPE_DRAWN.value">
                                                                <div
                                                                    style="height: 20px; width: 80px"
                                                                    :style="`background-color: ${area.drawn_color}`"></div>
                                                            </td>
                                                            <td>
                                                                {{ area.price !== null ? formatCurrency(area.price) : "Gratuite" }}
                                                            </td>
                                                            <td v-if="!isTvaDisabled">
                                                                {{ area.tax_rate !== null ? `${area.tax_rate} %` : "--" }}
                                                            </td>
                                                            <td>
                                                                {{ area.free_above !== null ? formatCurrency(area.free_above) : "--" }}
                                                            </td>
                                                            <td>
                                                                {{ area.min_amount !== null ? formatCurrency(area.min_amount) : "--" }}
                                                            </td>
                                                            <td>
                                                                {{ area.use_default_delivery_slots ? "Tous" : "Limité" }}
                                                            </td>
                                                            <td>
                                                                <button
                                                                    class="btn btn-sm btn-success btn-square"
                                                                    v-if="area.type != DELIVERY_AREA_TYPE_DRAWN.value"
                                                                    :disabled="!has_right_to_update_config"
                                                                    @click="editDeliveryArea(area)"
                                                                    v-tooltip="{
                                                                        delay: { show: 400, hide: 0 },
                                                                        content: 'Éditer',
                                                                    }">
                                                                    <feather type="edit" />
                                                                </button>
                                                                <button
                                                                    :disabled="!has_right_to_update_config"
                                                                    @click="deleteDeliveryArea(area.id)"
                                                                    class="btn btn-sm btn-danger btn-square"
                                                                    v-tooltip="{
                                                                        delay: { show: 400, hide: 0 },
                                                                        content: 'Supprimer',
                                                                    }">
                                                                    <feather type="trash-2" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </template>
                                            <em class="d-block" v-else>Vous n'avez ajouté aucune zone de livraison</em>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <template v-if="deliveryEnable">
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4">
                                <div class="row">
                                    <div class="col-12">
                                        <strong>Paramètres généraux</strong>
                                    </div>
                                </div>
                                <div class="row mt-3 mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>
                                            Délai de préparation de commande <small>*</small>
                                            <feather
                                                v-tooltip="getTooltipNotice('notices.clickAndCollect.configDelivery.orderPreparationDelay')"
                                                type="info"
                                                class="ml-1 pointer tooltip-infos" />
                                        </label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <input
                                                    :disabled="!has_right_to_update_config"
                                                    type="number"
                                                    class="form-control"
                                                    min="1"
                                                    v-model="deliveryPreparationTime" />
                                            </div>
                                            <div class="col-md-8">
                                                <select
                                                    :disabled="!has_right_to_update_config"
                                                    v-model="deliveryPreparationTimeUnit"
                                                    class="custom-select mb-0"
                                                    style="width: initial">
                                                    <option value="mins">Minutes</option>
                                                    <option value="hours">Heures</option>
                                                    <option value="days">Jours</option>
                                                </select>
                                            </div>
                                        </div>
                                        <show-errors :errors="errors" errorKey="delivery_preparation_time" />
                                        <show-errors :errors="errors" errorKey="delivery_preparation_time_unit" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label> Période d'ouverture des commandes <small>*</small> </label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <input
                                                    :disabled="!has_right_to_update_config"
                                                    type="number"
                                                    min="0"
                                                    class="form-control"
                                                    v-model="open_period_days" />
                                            </div>
                                            <div class="col-md-8 mt-1">jours</div>
                                        </div>
                                        <show-errors :errors="errors" errorKey="delivery_opening_period" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>
                                            Nombre de commandes maximum par créneau de livraison
                                            <small>*</small>
                                        </label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <input
                                                    :disabled="slotMaxOrder || !has_right_to_update_config"
                                                    type="number"
                                                    min="1"
                                                    class="form-control"
                                                    v-model="cmd_per_slot" />
                                            </div>
                                            <div class="col-md-8 mt-1">
                                                <label class="container-box" style="width: initial">
                                                    <input :disabled="!has_right_to_update_config" type="checkbox" v-model="slotMaxOrder" />
                                                    <span class="checkmark" :class="{ disabled: !has_right_to_update_config }"></span>
                                                    Illimité
                                                </label>
                                            </div>
                                        </div>
                                        <show-errors :errors="errors" errorKey="orders_max_per_delivery_slot" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-5 pt-2">
                                        <label>
                                            Confirmation des commandes <small>*</small>
                                            <feather
                                                v-tooltip="getTooltipNotice('notices.clickAndCollect.configDelivery.commandConfirm')"
                                                type="info"
                                                class="ml-1 pointer tooltip-infos" />
                                        </label>
                                    </div>
                                    <div class="col-7 radio w-100 h-100 d-table pt-2">
                                        <div class="d-table-cell align-middle">
                                            <label class="container-box">
                                                <input
                                                    type="radio"
                                                    :disabled="!has_right_to_update_config"
                                                    v-model="autoconfirm.autoconfirm_delivery"
                                                    :value="0" />
                                                <span class="checkmark"></span> Manuelle
                                            </label>
                                            <label class="container-box">
                                                <input
                                                    :disabled="!has_right_to_update_config"
                                                    type="radio"
                                                    v-model="autoconfirm.autoconfirm_delivery"
                                                    :value="1" />
                                                <span class="checkmark"></span> Automatique
                                            </label>
                                        </div>
                                        <show-errors class="d-block" :errors="errors" errorKey="autoconfirm_delivery" />
                                    </div>
                                </div>
                                <template v-if="autoconfirm.autoconfirm_delivery">
                                    <div class="row mb-2">
                                        <div class="col-4 pt-2 offset-1">
                                            <label> Jusqu'à </label>
                                        </div>
                                        <div class="col-7">
                                            <div class="row">
                                                <div class="col-4">
                                                    <input
                                                        type="number"
                                                        v-model="autoconfirm.autoconfirm_delivery_command"
                                                        class="form-control"
                                                        :disabled="!has_right_to_update_config" />
                                                </div>
                                                <div class="col-8 mt-2">
                                                    commande(s) par créneau de livraison<small> - 0 pour ne pas bloquer</small>
                                                </div>
                                                <show-errors class="col-12" :errors="errors" errorKey="autoconfirm_delivery_command" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-4 pt-2 offset-1">
                                            <label> Jusqu'à </label>
                                        </div>
                                        <div class="col-7">
                                            <div class="row">
                                                <div class="col-4">
                                                    <input
                                                        type="number"
                                                        v-model="autoconfirm.autoconfirm_delivery_until"
                                                        class="form-control"
                                                        :disabled="!has_right_to_update_config" />
                                                </div>
                                                <div class="col-8 mt-2">
                                                    minute(s) avant le début du créneau de livraison<small> - 0 pour ne pas bloquer</small>
                                                </div>
                                                <show-errors class="col-12" :errors="errors" errorKey="autoconfirm_delivery_until" />
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Afficher les produits en rupture</label>
                                    </div>
                                    <div class="col-md-7 pt-1">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                :disabled="!has_right_to_update_config"
                                                type="checkbox"
                                                class="switch align-self-center is-rounded"
                                                v-model="show_product_out" />
                                            <label
                                                @click="show_product_out = has_right_to_update_config ? !show_product_out : show_product_out"></label>
                                        </div>
                                    </div>
                                    <show-errors :errors="errors" errorKey="delivery_show_out_of_stock_products" />
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Afficher les produits inactifs</label>
                                    </div>
                                    <div class="col-md-7 pt-1">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                :disabled="!has_right_to_update_config"
                                                type="checkbox"
                                                class="switch align-self-center is-rounded"
                                                v-model="show_product_disable" />
                                            <label
                                                @click="
                                                    show_product_disable = has_right_to_update_config ? !show_product_disable : show_product_disable
                                                "></label>
                                        </div>
                                    </div>
                                    <show-errors :errors="errors" errorKey="delivery_show_inactive_products" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4">
                                <div class="row">
                                    <div class="col-12">
                                        <strong>Paiements</strong>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-5 pt-1">
                                        <label>Autoriser les paiements de groupe</label>
                                    </div>
                                    <div class="col-md-7 pt-1">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                :disabled="!has_right_to_update_config"
                                                type="checkbox"
                                                class="switch align-self-center is-rounded"
                                                v-model="group_payments" />
                                            <label @click="group_payments = has_right_to_update_config ? !group_payments : group_payments"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="hasMealVouchersEnabled && isNoShow">
                                    <div class="col-md-5 pt-1">
                                        <label>Autoriser les paiements en titres-restaurant</label>
                                    </div>
                                    <div class="col-md-7 pt-1">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                :disabled="!has_right_to_update_config"
                                                type="checkbox"
                                                class="switch align-self-center is-rounded"
                                                v-model="delivery_mealvouchers_enabled" />
                                            <label
                                                @click="
                                                    delivery_mealvouchers_enabled = has_right_to_update_config
                                                        ? !delivery_mealvouchers_enabled
                                                        : collect_mealvouchers_enabled
                                                "></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5 pt-1">
                                        <label>Liaison au module Bons Cadeaux</label>
                                    </div>
                                    <div class="col-md-7 pt-1">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                :disabled="!has_right_to_update_config || !hasGvSubscription"
                                                type="checkbox"
                                                class="switch align-self-center is-rounded"
                                                v-model="delivery_connect_to_gv" />
                                            <label
                                                @click="
                                                    delivery_connect_to_gv =
                                                        has_right_to_update_config && hasGvSubscription
                                                            ? !delivery_connect_to_gv
                                                            : delivery_connect_to_gv
                                                "></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4">
                                <div class="row">
                                    <div class="col-12">
                                        <strong>Conditions de livraison</strong>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-5 pt-1">
                                        <label>Informations relatives aux conditions de livraison</label>
                                    </div>
                                    <div class="col-md-7">
                                        <textarea
                                            :disabled="!has_right_to_update_config"
                                            class="form-control"
                                            maxlength="190"
                                            rows="3"
                                            v-model="delivery_legal_information"></textarea>
                                        <small class="text-muted">Max : 190 caractères</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <automatic-export-component
                        v-model="automaticExport"
                        :module="MODULE_TYPE_CLICK_AND_COLLECT.value"
                        services-name="delivery_services"
                        :is-delivery="true"
                        :can-fetch-services="canFetchServices"
                        @services-fetched="canFetchServices = false"
                        class="row mb-3" />
                    <editClickAndCollectNotifications
                        class="mt-2"
                        ref="editClickAndCollectNotifications"
                        :show-save-button="false"
                        :notifications-data="notificationsData"
                        :collect-type="COLLECT_TYPE_DELIVERY.value" />
                </template>
                <div class="row">
                    <div class="col-12">
                        <input
                            @click="post"
                            type="submit"
                            name="submit"
                            value="Enregistrer"
                            class="btn btn-success btn-circle"
                            :class="{ disabled: loading }"
                            :disabled="loading || !has_right_to_update_config" />
                    </div>
                </div>
            </div>
        </div>
        <add-click-and-collect-delivery-modal
            v-if="showAddClickAndCollectDeliveryModal"
            :deliveryArea="deliveryArea"
            @close="showAddClickAndCollectDeliveryModal = false"
            @saved="deliveryAreaSaved" />
        <viewDeliveryAreasOnGMapsModal v-if="showViewDeliveryAreasOnGMapsModal" @close="showViewDeliveryAreasOnGMapsModal = false" :config="$data" />
        <editClickAndCollectDrawDeliveryAreas
            v-if="showEditClickAndCollectDrawDeliveryAreas"
            :config="$data"
            :editable="has_right_to_update_config"
            :isTvaDisabled="isTvaDisabled"
            :getDefaultDeliveryArea="getDefaultDeliveryArea"
            @saved="deliveryAreaSaved"
            @close="showEditClickAndCollectDrawDeliveryAreas = false" />
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import VueTimepicker from "vue2-timepicker";
import addClickAndCollectDeliveryModal from "../../../components/Modals/clickAndCollect/settings/addClickAndCollectDeliveryModal.vue";
import editClickAndCollectNotifications from "../../../components/clickAndCollect/settings/editClickAndCollectNotifications.vue";
import DeliveryAreaTypesEnum from "../../../mixins/enums/click_and_collect/DeliveryAreaTypesEnum.js";
import viewDeliveryAreasOnGMapsModal from "../../../components/Modals/clickAndCollect/settings/viewDeliveryAreasOnGMapsModal.vue";
import editClickAndCollectDrawDeliveryAreas from "../../../components/Modals/clickAndCollect/settings/editClickAndCollectDrawDeliveryAreas.vue";
import CollectTypesEnum from "../../../mixins/enums/click_and_collect/CollectTypesEnum.js";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum";
import AutomaticExportComponent from "../../Booking/Settings/AutomaticExport/AutomaticExportComponent.vue";
import ShowErrors from "../../../components/errors/ShowErrors.vue";

function getDefaultDeliverySlots() {
    return {
        0: {
            active: false,
            slots: [],
        },
        1: {
            active: false,
            slots: [],
        },
        2: {
            active: false,
            slots: [],
        },
        3: {
            active: false,
            slots: [],
        },
        4: {
            active: false,
            slots: [],
        },
        5: {
            active: false,
            slots: [],
        },
        6: {
            active: false,
            slots: [],
        },
    };
}

export default {
    data() {
        return {
            loading: false,
            errors: null,
            error: null,
            canFetchServices: false,
            open_period_days: null,
            cc_configuration_id: null,
            delivery_legal_information: "",
            cmd_per_slot: "",
            slotMaxOrder: false,
            show_product_out: false,
            show_product_disable: false,
            group_payments: false,
            delivery_mealvouchers_enabled: false,
            deliveryEnable: false,
            deliveryPreparationTime: null,
            deliveryPreparationTimeUnit: "mins",
            deliveryArea: null,
            deliveryAreas: null,
            deliverySlots: getDefaultDeliverySlots(),
            showAddClickAndCollectDeliveryModal: false,
            weekDays: [1, 2, 3, 4, 5, 6, 0],
            autoconfirm: {
                autoconfirm_delivery: false,
                autoconfirm_delivery_command: 0,
                autoconfirm_delivery_until: 60,
            },
            delivery_connect_to_gv: false,
            availableAddresses: [],
            loadingPlaceDetails: false,
            currentAddress: "",
            errorAddress: null,
            sessionToken: null,
            delivery_departure_address: null,
            delivery_departure_city: null,
            delivery_departure_postal_code: null,
            delivery_departure_country: null,
            delivery_departure_lat: null,
            delivery_departure_lng: null,
            showViewDeliveryAreasOnGMapsModal: false,
            delivery_areas_type: "cities",
            showEditClickAndCollectDrawDeliveryAreas: false,
            notificationsData: [
                {
                    label: "Confirmation de commande",
                    previewId: "order-confirmed-delivery",
                    key: "delivery_order_confirmation",
                },
                {
                    label: "Commande en attente",
                    previewId: "order-waiting-confirmation-delivery",
                    key: "delivery_order_waiting_confirmation",
                },
                {
                    label: "Commande prête",
                    previewId: "order-ready-delivery",
                    key: "delivery_order_ready",
                    canBeDisabled: true,
                },
                {
                    label: "Commande annulée",
                    previewId: "order-cancel-delivery",
                    key: "delivery_order_cancel",
                },
                {
                    label: "Commande partiellement remboursée",
                    previewId: "order-partial-refund-delivery",
                    key: "delivery_order_partial_refund",
                },
                {
                    label: "Commande livrée",
                    previewId: "order-delivered",
                    key: "delivery_order_delivered",
                    canBeDisabled: true,
                },
                {
                    label: "Commande modifiée",
                    previewId: "order-edition-delivery",
                    key: "delivery_order_edition",
                },
            ],
            automaticExport: {
                is_enabled: false,
                minutes: 30,
                delivery_services: [],
                emails: [""],
            },
        };
    },
    mixins: [DeliveryAreaTypesEnum, CollectTypesEnum, ModuleTypesEnum],
    computed: {
        restaurant() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurant_id);
        },
        hasMealVouchersEnabled() {
            if (!this.restaurant) return false;
            return this.restaurant.edenred_enabled;
        },
        deliveryAreas_() {
            if (!this.deliveryAreas || !this.deliveryAreas.data) return null;
            return this.deliveryAreas.data.filter((area) => area.type == this.delivery_areas_type);
        },
        displayableDeliveryDeparture() {
            if (
                this.delivery_departure_address &&
                this.delivery_departure_city &&
                this.delivery_departure_postal_code &&
                this.delivery_departure_country &&
                this.delivery_departure_lat &&
                this.delivery_departure_lng
            )
                return `${this.delivery_departure_address}, ${this.delivery_departure_postal_code} ${this.delivery_departure_city}`;
            return null;
        },
        isTvaDisabled() {
            return this.$store.getters["restaurants/isTvaDisabled"](this.restaurant_id);
        },
        hasGvSubscription() {
            return this.$store.getters["restaurants/hasModule"]("gift-vouchers", this.restaurant_id);
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_config() {
            return this.rights.includes("click_and_collect.config.update");
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        deliverySlotsForSend() {
            let slots = [];
            Object.values(this.deliverySlots).forEach((day) => {
                if (day.active)
                    day.slots.forEach((slot) => {
                        if (slot.id !== null) slots.push(slot);
                        else {
                            slots.push({
                                id: null,
                                day: slot.day,
                                hour_start: `${slot.hour_start.HH}:${slot.hour_start.mm}:00`,
                                hour_end: `${slot.hour_end.HH}:${slot.hour_end.mm}:00`,
                            });
                        }
                    });
            });
            return slots;
        },
    },
    methods: {
        sanitizeAutomaticExportEmails() {
            if (this.automaticExport.emails.length > 1) {
                this.automaticExport.emails = this.automaticExport.emails.filter((email) => email !== "");
            }
        },
        getDefaultDeliveryArea() {
            return {
                id: null,
                name: null,
                type: this.DELIVERY_AREA_TYPE_CITIES.value,
                cities: {
                    data: [],
                },
                distances: {
                    data: [],
                },
                is_free: 1,
                price: null,
                tax_rate: null,
                free_above: null,
                min_amount: null,
                cc_configuration_id: this.cc_configuration_id,
                use_default_delivery_slots: 1,
                delivery_slots: {
                    data: [],
                },
                gps_points: {
                    data: [],
                },
                center_lat: null,
                center_lng: null,
                drawn_color: null,
            };
        },
        toggleDeliveryAreaType() {
            const msg = `Attention, la modification de ce paramètre désactivera les zones de livraison ${this.getDeliveryAreaTypeLabel(
                this.delivery_areas_type
            ).toLowerCase()}. Souhaitez-vous continuer ?`;
            if (!this.has_right_to_update_config || !this.displayableDeliveryDeparture || !confirm(msg)) {
                return;
            }

            this.delivery_areas_type =
                this.delivery_areas_type === this.DELIVERY_AREA_TYPE_CROW_FLIES.value
                    ? this.DELIVERY_AREA_TYPE_CITIES.value
                    : this.DELIVERY_AREA_TYPE_CROW_FLIES.value;
        },
        viewOnGMaps() {
            this.showViewDeliveryAreasOnGMapsModal = true;
        },
        resetDeparture() {
            if (
                (this.delivery_areas_type === this.DELIVERY_AREA_TYPE_CROW_FLIES.value &&
                    !confirm(
                        "La suppression de l'adresse de départ entraine la désactivation des zones de livraison par vol d'oiseau. Souhaitez vous continuer ?"
                    )) ||
                (this.delivery_areas_type === this.DELIVERY_AREA_TYPE_DRAWN.value &&
                    !confirm(
                        "La suppression de l'adresse de départ entraine la désactivation des zones de livraison dessinées. Souhaitez vous continuer ?"
                    ))
            ) {
                return;
            }

            this.delivery_departure_address = null;
            this.delivery_departure_city = null;
            this.delivery_departure_postal_code = null;
            this.delivery_departure_country = null;
            this.delivery_departure_lat = null;
            this.delivery_departure_lng = null;
            this.delivery_areas_type = this.DELIVERY_AREA_TYPE_CITIES.value;
        },
        addAddress(address) {
            this.loadingPlaceDetails = true;
            this.errorAddress = null;

            axios
                .get(`/api/click_and_collect/${this.restaurant_id}/placeDetails/${address.place_id}?sessionToken=${this.sessionToken}`)
                .then((response) => {
                    this.loadingPlaceDetails = false;
                    this.delivery_departure_address = response.data.address;
                    this.delivery_departure_city = response.data.city;
                    this.delivery_departure_postal_code = response.data.postalCode;
                    this.delivery_departure_country = response.data.country;
                    this.delivery_departure_lat = response.data.location.lat;
                    this.delivery_departure_lng = response.data.location.lng;
                    this.currentAddress = "";
                    this.sessionToken = null;
                })
                .catch((error) => {
                    this.loadingPlaceDetails = false;
                    this.availableAddresses = [];
                    this.errorAddress = this.getErrorMsgFromErrorResponse(error);
                });
        },
        searchAddress(oldCityVal) {
            if (this.currentAddress !== oldCityVal) return;

            this.errorAddress = null;
            if (!this.sessionToken) this.sessionToken = this.generateRandomString();

            axios
                .get(`/api/click_and_collect/${this.restaurant_id}/searchAddress?query=${this.currentAddress}&sessionToken=${this.sessionToken}`)
                .then((response) => {
                    if (response.data.data.length > 0) {
                        this.availableAddresses = response.data.data;
                    } else {
                        this.errorAddress = this.$tl("errors.common.address.noHits");
                        this.availableAddresses = [];
                    }
                })
                .catch((error) => {
                    this.availableAddresses = [];
                    this.errorAddress = this.getErrorMsgFromErrorResponse(error);
                });
        },
        calculHourRange(start_hour) {
            var start = parseInt(start_hour.HH);
            return [[start, 23]];
        },
        addDeliveryArea() {
            this.deliveryArea = this.getDefaultDeliveryArea();
            this.deliveryArea.type = this.delivery_areas_type;
            this.showAddClickAndCollectDeliveryModal = true;
        },
        editDeliveryArea(area) {
            this.deliveryArea = _.cloneDeep(area);
            this.showAddClickAndCollectDeliveryModal = true;
        },
        deliveryAreaSaved() {
            this.showAddClickAndCollectDeliveryModal = false;
            this.showEditClickAndCollectDrawDeliveryAreas = false;
            this.fetchDeliveryAreas();
        },
        addDeliverySlot(day) {
            this.deliverySlots[day].slots.push({
                id: null,
                day,
                hour_start: {
                    HH: "HH",
                    mm: "mm",
                },
                hour_end: {
                    HH: "HH",
                    mm: "mm",
                },
            });
        },
        deleteDeliverySlot(day, index) {
            if (!this.has_right_to_update_config) return;
            const nb_future_commands = this.deliverySlots[day].slots[index].nb_future_commands;
            if (
                nb_future_commands > 0 &&
                !confirm(
                    `Attention, vous avez ${nb_future_commands} commande${nb_future_commands > 1 ? "s" : ""} future${
                        nb_future_commands > 1 ? "s" : ""
                    } sur cette plage horaire. Pensez à ${nb_future_commands > 1 ? "les " : "l'"}annuler si vous ne pouvez pas ${
                        nb_future_commands > 1 ? "les " : "l'"
                    }honorer.`
                )
            )
                return;
            this.deliverySlots[day].slots.splice(index, 1);
        },
        formatDeliverySlots(slots) {
            Object.keys(this.deliverySlots).forEach((day) => {
                this.deliverySlots[day].slots = slots.filter((s) => s.day == day);
                this.deliverySlots[day].active = this.deliverySlots[day].slots.length > 0;
            });
        },
        fetchData() {
            this.loading = true;
            this.errors = null;
            this.error = null;

            axios
                .get(
                    `/api/click_and_collect/${this.restaurant_id}/settings?include=areas.cities,areas.delivery_slots,areas.gps_points,delivery_slots.nb_future_commands&is_delivery=1`
                )
                .then((response) => {
                    this.open_period_days = response.data.delivery_opening_period;
                    this.cmd_per_slot = response.data.orders_max_per_delivery_slot;

                    if (this.cmd_per_slot == null) {
                        this.slotMaxOrder = true;
                    }

                    this.delivery_legal_information = response.data.delivery_legal_information;

                    this.show_product_out = response.data.delivery_show_out_of_stock_products;
                    this.show_product_disable = response.data.delivery_show_inactive_products;
                    this.group_payments = response.data.delivery_group_payment;
                    this.delivery_mealvouchers_enabled = response.data.delivery_mealvouchers_enabled;

                    this.deliveryEnable = response.data.is_delivery_enabled;
                    this.deliveryAreas = response.data.areas;
                    this.cc_configuration_id = response.data.id;

                    this.deliveryPreparationTime = response.data.delivery_preparation_time;
                    this.deliveryPreparationTimeUnit = response.data.delivery_preparation_time_unit;

                    this.autoconfirm.autoconfirm_delivery = response.data.autoconfirm_delivery;
                    this.autoconfirm.autoconfirm_delivery_command = response.data.autoconfirm_delivery_command;
                    this.autoconfirm.autoconfirm_delivery_until = response.data.autoconfirm_delivery_until;

                    this.delivery_departure_address = response.data.delivery_departure_address;
                    this.delivery_departure_city = response.data.delivery_departure_city;
                    this.delivery_departure_postal_code = response.data.delivery_departure_postal_code;
                    this.delivery_departure_country = response.data.delivery_departure_country;
                    this.delivery_departure_lat = response.data.delivery_departure_lat;
                    this.delivery_departure_lng = response.data.delivery_departure_lng;

                    this.delivery_areas_type = response.data.delivery_areas_type;

                    if (!this.$_.isEmpty(response.data.automatic_exports)) {
                        this.automaticExport = Object.assign(this.automaticExport, response.data.automatic_exports[0]);
                    }

                    if (this.hasGvSubscription) this.delivery_connect_to_gv = response.data.delivery_connect_to_gv;

                    this.formatDeliverySlots(response.data.delivery_slots.data);
                    this.canFetchServices = true;
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.not_a_fatal_error === true) {
                        return;
                    }

                    this.error = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => (this.loading = false));
        },
        post() {
            this.loading = true;

            const callBack = (result) => {
                if (result === true) {
                    if (this.cc_configuration_id) {
                        this.putForm();
                    } else {
                        this.postForm();
                    }
                } else {
                    this.loading = false;

                    this.notifyError(null, result || "Une erreur est survenue");
                }
            };

            if (this.deliveryEnable) {
                this.$refs.editClickAndCollectNotifications.postForm().then(callBack);
            } else {
                callBack(true);
            }
        },
        postForm() {
            this.errors = null;
            this.loading = true;

            return axios
                .post(`/api/click_and_collect/${this.restaurant_id}/settingsDelivery`, this.getFormattedData())
                .then(() => {
                    this.notifySuccess(null, "La configuration a été enregistrée avec succès.");

                    this.fetchData();
                })
                .catch((error) => {
                    this.loading = false;

                    this.notifyError(error);

                    if (error.response && error.response.data) {
                        this.errors = error.response.data.errors;
                    }
                });
        },
        putForm() {
            this.loading = true;
            this.errors = null;

            return axios
                .put(`/api/click_and_collect/${this.restaurant_id}/settingsDelivery/${this.cc_configuration_id}`, this.getFormattedData())
                .then(() => {
                    this.notifySuccess(null, "La configuration a été enregistrée avec succès.");

                    this.fetchData();
                })
                .catch((error) => {
                    this.loading = false;

                    if (error.response && error.response.data) {
                        this.errors = error.response.data.errors;
                    }

                    this.notifyError(error);
                });
        },
        getFormattedData() {
            this.sanitizeAutomaticExportEmails();

            let data = {
                restaurant_id: this.restaurant_id,
                delivery_opening_period: this.open_period_days,
                orders_max_per_delivery_slot: this.slotMaxOrder ? null : this.cmd_per_slot,
                delivery_show_out_of_stock_products: this.show_product_out,
                delivery_legal_information: this.delivery_legal_information,
                delivery_show_inactive_products: this.show_product_disable,
                delivery_group_payment: this.group_payments,
                delivery_mealvouchers_enabled: this.delivery_mealvouchers_enabled,
                is_delivery_enabled: this.deliveryEnable,
                delivery_preparation_time: this.deliveryPreparationTime,
                delivery_preparation_time_unit: this.deliveryPreparationTimeUnit,
                delivery_slots: this.deliverySlotsForSend,
                ...this.autoconfirm,
                delivery_connect_to_gv: this.delivery_connect_to_gv,
                has_delivery_departure_address: this.displayableDeliveryDeparture ? true : false,
                delivery_departure_address: this.delivery_departure_address,
                delivery_departure_city: this.delivery_departure_city,
                delivery_departure_postal_code: this.delivery_departure_postal_code,
                delivery_departure_country: this.delivery_departure_country,
                delivery_departure_lat: this.delivery_departure_lat,
                delivery_departure_lng: this.delivery_departure_lng,
                delivery_areas_type: this.delivery_areas_type,
                automatic_export: this.automaticExport,
            };

            if (this.cc_configuration_id) {
                data.cc_configuration_id = this.cc_configuration_id;
            }

            return data;
        },
        fetchDeliveryAreas() {
            this.loading = true;

            axios
                .get(
                    `/api/click_and_collect/${this.restaurant_id}/deliveryAreas/${this.cc_configuration_id}?include=cities,delivery_slots,gps_points`
                )
                .then((response) => {
                    this.deliveryAreas = response.data;
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => (this.loading = false));
        },
        deleteDeliveryArea(area_id) {
            if (!confirm("Êtes vous sûr de vouloir supprimer cette zone de livraison ?")) {
                return;
            }

            this.loading = true;

            axios
                .delete(`/api/click_and_collect/${this.restaurant_id}/deliveryAreas/${area_id}`)
                .then((response) => {
                    this.notifySuccess(response);

                    this.fetchDeliveryAreas();
                })
                .catch((error) => {
                    this.loading = false;

                    this.notifyError(error);
                });
        },
    },
    components: {
        LoaderComponent,
        VueTimepicker,
        ShowErrors,
        addClickAndCollectDeliveryModal,
        editClickAndCollectNotifications,
        viewDeliveryAreasOnGMapsModal,
        editClickAndCollectDrawDeliveryAreas,
        AutomaticExportComponent,
    },
    watch: {
        slotMaxOrder(newVal) {
            if (newVal) {
                this.cmd_per_slot = null;
            }
        },
        currentAddress(newVal) {
            if (newVal.length < 3) {
                this.availableAddresses = [];
                this.errorAddress = null;
                this.sessionToken = null;
                return;
            }

            setTimeout(() => {
                this.searchAddress(newVal);
            }, 500);
        },
    },
    created() {
        this.fetchData();
    },
};
</script>

<style scoped>
input[type="submit"]:disabled {
    cursor: default;
}
</style>
