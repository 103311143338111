<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Remboursement</h5>
        <div slot="body">
            <RefundProducts ref="refundProducts" :command_id="command_id" @refunded="$emit('refunded', $event)" @loading="loading = $event" />
        </div>
        <div slot="footer" class="d-flex">
            <button
                :disabled="loading"
                :class="{ disabled: loading }"
                type="button"
                class="modal-default-button btn btn-sm btn-secondary btn-circle"
                @click="close">
                Annuler
            </button>
            <button
                :disabled="loading"
                :class="{ disabled: loading }"
                type="button"
                class="btn btn-sm btn-success btn-circle ml-2"
                @click="$refs.refundProducts.post()">
                Valider
            </button>
        </div>
    </modal>
</template>

<script>
import RefundProducts from "../../../clickAndCollect/Command/RefundProducts.vue";

export default {
    data() {
        return {
            loading: false,
        };
    },
    props: {
        command_id: {
            type: Number,
            required: true,
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        RefundProducts,
    },
};
</script>
