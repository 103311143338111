var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 pt-3" }, [
        _c("div", { staticClass: "border-light b-radius-20 p-4 mb-3" }, [
          _c("div", { staticClass: "row" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-8" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.campaign.name,
                      expression: "campaign.name",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.campaign.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.campaign, "name", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("ShowErrors", {
                  staticClass: "d-block",
                  attrs: { errors: _vm.errors, errorKey: "name" },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-3 switch-type" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-8" },
              [
                _c("input", {
                  staticClass: "switch align-self-center is-rounded",
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: _vm.campaign.type == _vm.CAMPAIGN_TYPE_SMS.value,
                  },
                }),
                _vm._v("\n                        Email "),
                _c("label", { on: { click: _vm.toggleCampaignType } }),
                _vm._v(" SMS\n                        "),
                _c("ShowErrors", {
                  staticClass: "d-block",
                  attrs: { errors: _vm.errors, errorKey: "type" },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c(
          "div",
          { staticClass: "border-light b-radius-20 p-4 mb-3" },
          [
            _vm._m(2),
            _vm._v(" "),
            _vm.campaign.type == _vm.CAMPAIGN_TYPE_EMAIL.value
              ? [
                  _c("div", { staticClass: "row mt-3" }, [
                    _c("div", { staticClass: "col-md-4 pt-2 mb-2" }, [
                      _vm._v("Objet *"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-8" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.campaign.mail_object,
                              expression: "campaign.mail_object",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.campaign.mail_object },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.campaign,
                                "mail_object",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors,
                            errorKey: "mail_object",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-2" }, [
                    _c("div", { staticClass: "col-md-4 pt-2 mb-2" }, [
                      _c("span", { staticClass: "d-block mb-1" }, [
                        _vm._v("Message *"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "badge badge-secondary mb-1",
                          staticStyle: { border: "none" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.addToMessage("[FIRSTNAME]")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                                Insérer le prénom du client\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "badge badge-secondary mb-1",
                          staticStyle: { border: "none" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.addToMessage("[LASTNAME]")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                                Insérer le nom du client\n                            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-8" },
                      [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.campaign.content,
                              expression: "campaign.content",
                            },
                          ],
                          ref: "txtEmailBody",
                          staticClass: "form-control",
                          staticStyle: { "font-size": "0.75rem" },
                          attrs: { rows: "8" },
                          domProps: { value: _vm.campaign.content },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.campaign,
                                "content",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          staticClass: "d-block",
                          attrs: { errors: _vm.errors, errorKey: "content" },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-2" }, [
                    _c("div", { staticClass: "col-md-4 mb-2" }, [
                      _vm._v("Image"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-8" },
                      [
                        _c("ImageComponent", {
                          attrs: {
                            src: _vm.campaign.img_path,
                            suffixId: "campaign",
                            postURI: _vm.imgPostUri,
                            maxSize: 10,
                            callBack: (response) =>
                              (this.campaign.img_path = response.data.img_path),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-2" }, [
                    _c("div", { staticClass: "col-md-4 pt-2 mb-2" }, [
                      _vm._v("Lien au clique sur l'image"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-8" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.campaign.img_link,
                              expression: "campaign.img_link",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.campaign.img_link },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.campaign,
                                "img_link",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          staticClass: "d-block",
                          attrs: { errors: _vm.errors, errorKey: "img_link" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              : [
                  _c("div", { staticClass: "row mt-3" }, [
                    _vm._m(3),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-7" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.campaign.sms_expeditor,
                              expression: "campaign.sms_expeditor",
                            },
                          ],
                          staticClass: "form-control mb-2",
                          class: {
                            "is-invalid":
                              !_vm.campaign.sms_expeditor ||
                              !_vm.campaign.sms_expeditor.match(/^[a-z]+$/i),
                          },
                          attrs: { type: "text", maxlength: "11" },
                          domProps: { value: _vm.campaign.sms_expeditor },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.campaign,
                                "sms_expeditor",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors,
                            errorKey: "sms_expeditor",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "limiter text-muted pl-2" }, [
                          _vm._v(
                            "Il vous reste " +
                              _vm._s(_vm.sms_emitter_name_count) +
                              " caractère" +
                              _vm._s(_vm.sms_emitter_name_count > 1 ? "s" : "")
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "small",
                          { staticClass: "text-danger d-block pl-2" },
                          [
                            _vm._v(
                              "Attention : seule les lettres minuscules et majuscules sont autorisées, les\n                                caractères spéciaux, chiffres, espaces, ou lettres accentuées ne sont pas acceptés\n                                par notre prestataire d'envoi de SMS."
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-3" }, [
                    _c("div", { staticClass: "col-md-5 pt-2 mb-2" }, [
                      _vm._v("Message *"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-7" },
                      [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.campaign.content,
                              expression: "campaign.content",
                            },
                          ],
                          staticClass: "form-control mb-2",
                          attrs: { rows: "4", maxlength: "469" },
                          domProps: { value: _vm.campaign.content },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.campaign,
                                "content",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "limiter text-muted pl-2" }, [
                          _vm._v(
                            _vm._s(_vm.sms_message_count) +
                              " caractère" +
                              _vm._s(_vm.sms_message_count > 1 ? "s" : "")
                          ),
                        ]),
                        _vm._v(
                          "\n                            /\n                            "
                        ),
                        _vm.sms_message_count <= 149
                          ? _c("span", { staticClass: "text-muted" }, [
                              _vm._v("1 crédit"),
                            ])
                          : _vm.sms_message_count <= 309
                          ? _c("span", { staticClass: "text-muted" }, [
                              _vm._v("2 crédits"),
                            ])
                          : _c("span", { staticClass: "text-muted" }, [
                              _vm._v("3 crédits"),
                            ]),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          staticClass: "d-block",
                          attrs: { errors: _vm.errors, errorKey: "content" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-4 mb-2" }, [
      _c("strong", [_vm._v("Nom de la campagne")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-4 mb-2" }, [
      _c("strong", [_vm._v("Type de campagne")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("strong", [_vm._v("Composez votre message")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5" }, [
      _c("label", { staticClass: "mb-1" }, [
        _vm._v("Nom affiché au client, à la réception : *"),
      ]),
      _vm._v(" "),
      _c("small", { staticClass: "d-block" }, [
        _vm._v("11 caractères maximum"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }