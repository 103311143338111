var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : !_vm.cc_notification
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$tl("errors.common.unknown")) + "\n    "
            ),
          ])
        : _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("strong", [_vm._v("Notifications")]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _vm._l(
                      _vm.notificationsData,
                      function (notificationData, index) {
                        return [
                          _c(
                            "EditNotification",
                            _vm._g(
                              _vm._b(
                                {
                                  key: index,
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: notificationData.label,
                                    "mail-preview-id":
                                      notificationData.previewId,
                                    "sms-preview-id":
                                      notificationData.previewId,
                                    "is-sms-mode-available":
                                      _vm.cc_notification.sms_activated,
                                    disabled: !_vm.has_right_to_update_config,
                                    "can-be-disabled":
                                      notificationData.canBeDisabled,
                                    formErrors: _vm.errors,
                                    "error-key": notificationData.key,
                                    saas: "click_and_collect",
                                  },
                                },
                                "EditNotification",
                                _vm.getPropertiesBindingForEditComponent(
                                  notificationData.key
                                ),
                                false
                              ),
                              _vm.getEventsBindingForEditComponent(
                                notificationData.key
                              )
                            )
                          ),
                          _vm._v(" "),
                          index < _vm.notificationsData.length - 1
                            ? _c("div", {
                                key: `${index}-sepa`,
                                staticClass: "sepa",
                              })
                            : _vm._e(),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
      _vm._v(" "),
      _vm.showSaveButton
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success btn-circle",
                  attrs: {
                    type: "button",
                    disabled: !_vm.has_right_to_update_config,
                  },
                  on: { click: _vm.postForm },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("labels.actions.save")) +
                      "\n            "
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }