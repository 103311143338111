<template>
    <div class="h-100">
        <div v-html="customCss"></div>

        <div class="w-100 h-100 position-relative overflow-auto">
            <hour-cursor
                v-if="isToday"
                :minutes-interval="minutesInterval"
                :start-date-time="hoursRange[0]"
                :end-date-time="hoursRange[hoursRange.length - 1]"
                :cell-width="realCellWidth"
                :left="firstColOffset"
                :header-height="headerHeight"
                :cursor-height="tableHeight - headerHeight" />

            <template v-for="(data, index) in dataSourceToShow">
                <show-event
                    v-for="event in data.events"
                    :key="`${event.uniqId}-${data.uniqId}`"
                    :event="event"
                    :start-hour-date-time="hoursRange[0]"
                    :end-hour-date-time="hoursRange[hoursRange.length - 1]"
                    :cell-width="realCellWidth"
                    :minutes-interval="minutesInterval"
                    :top="`${headerHeight + rowHeight * index}px`"
                    :first-col-offset="firstColOffset"
                    @event-clicked="$emit('event-clicked', { event })">
                    <template v-slot:event="{ event }">
                        <slot name="event" v-bind="{ event }"></slot>
                    </template>
                </show-event>
            </template>

            <table class="table timeline" ref="table">
                <thead ref="tableHead">
                    <tr class="text-center">
                        <th ref="tableFirstCol" class="border align-middle" style="width: 200px">
                            <span class="text-danger font-weight-normal text-capitalize">
                                {{ currentDate.toLocaleString(DATE_MED_DAY_NO_YEAR) }}
                            </span>
                        </th>
                        <th ref="tableCellHour" v-for="hour in hoursRange" :key="hour.toSeconds()" class="header-cell-hour">
                            {{ hour.toLocaleString(TIME_SIMPLE) }}
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <template v-for="data in dataSourceToShow">
                        <tr ref="rows" v-if="data.collapse" :key="`collapse-${data.uniqId}`" class="bg-light">
                            <td class="border pl-2">
                                <feather
                                    class="pointer mr-1"
                                    :type="openedTr.includes(data.uniqId) ? 'chevron-down' : 'chevron-right'"
                                    @click="toggleTr(data.uniqId)" />
                                <slot name="cell-collapse-name" v-bind="{ data: data.data, uniqId: data.uniqId }"></slot>
                            </td>
                            <td v-for="hour in hoursRange" :key="hour.toSeconds()" class="border"></td>
                        </tr>
                        <tr
                            ref="rows"
                            v-else
                            v-show="typeof data.collapseBy === 'undefined' || openedTr.includes(data.collapseBy)"
                            :key="`row-${data.uniqId}`">
                            <td class="border text-right pr-3">
                                <slot name="cell-row-name" v-bind="{ data: data.data, uniqId: data.uniqId }"></slot>
                            </td>
                            <td
                                v-for="hour in hoursRange"
                                :key="hour.toSeconds()"
                                class="border cell-highlight text-center"
                                :class="{ 'bg-light': hour > endHourDateTime }">
                                <slot name="cell-body" v-bind="{ hour, data: data.data, uniqId: data.uniqId }"></slot>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import hourCursor from "./hourCursor.vue";
import showEvent from "./showEvent.vue";

/**
 * dataSource: Array<Object> [
 *  {
 *    uniqId: string|int, // A unique id use as "key"
 *    ?collapse: bool, // Does data collapse other rows ?
 *    ?collapseBy: string|int, // Is data collapsed by another row ?
 *    ?data: Object, // Additional data bind to slots
 *  }
 * ],
 * events: Array<Object> [
 *  {
 *    uniqId: string|int, // A unique id use as "key"
 *    name: string, // The event name
 *    startsAt: DateTime, // Event starts at given datetime
 *    endsAt: DateTime, // Event ends at given datetime
 *    belongsTo: string|int|Array<string|int>, // dataSource uniqId(s)
 *    style: Object, // Style apply to event container (width)
 *    clickable: Boolean, // Whether the event is clickable or not
 *  }
 * ]
 */
export default {
    data() {
        return {
            openedTr: [],
            tableHeight: undefined,
            headerHeight: undefined,
            rowHeight: undefined,
            firstColOffset: undefined,
            realCellWidth: undefined,
        };
    },
    props: {
        currentDate: {
            type: DateTime,
            required: true,
        },
        startHour: {
            type: String,
            required: true,
        },
        endHour: {
            type: String,
            required: true,
        },
        dataSource: {
            type: Array,
            required: true,
        },
        events: {
            type: Array,
            default: () => [],
        },
        stopAtEndHour: {
            type: Boolean,
            default: false,
        },
        minutesInterval: {
            type: Number,
            default: 15,
        },
        cellWidth: {
            type: Number,
            default: 50,
        },
    },
    computed: {
        customCss() {
            return `
                <style>
                    table thead th.header-cell-hour {
                        width: ${this.cellWidth}px;
                    }
                </style>
            `;
        },
        isToday() {
            return this.areDatesSame(this.getDateTime(), this.currentDate);
        },
        startHourDateTime() {
            const startHour = this.startHour.split(":");

            if (startHour.length < 2) {
                throw "Invalid start hour";
            }

            return this.currentDate
                .set({
                    hours: Number.parseInt(startHour[0]),
                    minutes: Number.parseInt(startHour[1]),
                })
                .startOf("minute");
        },
        endHourDateTime() {
            const endHour = this.endHour.split(":");

            if (endHour.length < 2) {
                throw "Invalid end hour";
            }

            return this.currentDate
                .set({
                    hours: Number.parseInt(endHour[0]),
                    minutes: Number.parseInt(endHour[1]),
                })
                .startOf("minute");
        },
        hoursRange() {
            let startHourDateTime = this.startHourDateTime;

            if (startHourDateTime > this.endHourDateTime) {
                throw "start hour is after end hour";
            }

            const endHourDateTime = this.stopAtEndHour ? this.endHourDateTime : startHourDateTime.set({ hours: 24, minutes: 0 });

            const range = [];
            while (startHourDateTime < endHourDateTime) {
                range.push(startHourDateTime);

                startHourDateTime = startHourDateTime.plus({ minutes: this.minutesInterval });
            }

            return range;
        },
        dataSourceToShow() {
            return this.dataSource
                .filter((data) => {
                    if (typeof data.collapseBy !== "undefined") {
                        return this.openedTr.includes(data.collapseBy);
                    }

                    return true;
                })
                .map((data) => {
                    return {
                        ...data,
                        events: this.events.filter((event) => {
                            return Array.isArray(event.belongsTo)
                                ? event.belongsTo.some((id) => id === data.uniqId)
                                : data.uniqId === event.belongsTo;
                        }),
                    };
                });
        },
    },
    methods: {
        toggleTr(id) {
            const index = this.openedTr.indexOf(id);

            if (index === -1) {
                this.openedTr.push(id);
            } else {
                this.openedTr.splice(index, 1);
            }

            this.$nextTick(this.setComputedDimensions);
        },
        setComputedDimensions() {
            this.tableHeight = this.$refs.table.offsetHeight;
            this.headerHeight = this.$refs.tableHead.offsetHeight;
            this.rowHeight = this.$refs.rows[0].offsetHeight;
            this.firstColOffset = this.$refs.tableFirstCol.offsetWidth;
            this.realCellWidth = this.$refs.tableCellHour[0].offsetWidth;
        },
    },
    created() {
        this.openedTr.push(...this.dataSource.filter((data) => data.collapse).map((data) => data.uniqId));
    },
    mounted() {
        this.setComputedDimensions();
    },
    components: {
        hourCursor,
        showEvent,
    },
};
</script>
