<template>
    <LoaderComponent v-if="loading" />
    <div v-else>
        <div v-if="error" class="alert alert-danger">
            {{ error }}
        </div>
        <div v-else-if="command" class="div-profil">
            <div class="row m-0">
                <div class="col-md-7 mb-3">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row">
                            <div class="col-6">Numéro de commande</div>
                            <div class="col-6 value">
                                {{ command.numero }}
                            </div>
                        </div>
                        <div class="sepa"></div>
                        <div class="row">
                            <div class="col-6">Date de la commande</div>
                            <div class="col-6 value" v-if="!isEditing">
                                {{ moment(command.reservation_date).format("LL") }}
                                <button
                                    @click="editCommandDateAndSlot"
                                    class="btn btn-sm"
                                    v-if="command.collect_type != COLLECT_TYPE_DISPATCH.value && command.status != COMMAND_CANCELED.value"
                                    type="button"
                                    style="float: right"
                                    :disabled="!has_right_to_update"
                                    title="Modifier la date de la commande"
                                    v-tooltip="{ delay: { show: 400, hide: 0 }, content: 'Modifier' }">
                                    <feather type="edit" style="color: #666666"></feather>
                                </button>
                            </div>
                            <div class="col-6 date-resa-cal" v-else>
                                <datepicker
                                    tabindex="1"
                                    :monday-first="true"
                                    v-model="commandDateEditComputed"
                                    :format="customDateFormater"
                                    :disabledDates="disabledDates"
                                    :language="fr">
                                </datepicker>
                            </div>
                        </div>
                        <template v-if="command.collect_type !== COLLECT_TYPE_DISPATCH.value">
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-6">Créneau</div>
                                <div class="col-6 value" v-if="!isEditing">
                                    <feather v-if="command.collect_type === COLLECT_TYPE_DELIVERY.value" type="truck" class="mr-1 text-success" />
                                    {{ slot_hour_computed }}
                                </div>
                                <div class="col-6 value" v-else>
                                    <select v-if="slots" class="custom-select" style="margin-top: -2px !important" v-model="edition.slot">
                                        <option v-for="slot in slots" :key="slot.id" :value="slot">
                                            {{
                                                command.collect_type === "collect"
                                                    ? formatHour(slot.hour)
                                                    : `${formatHour(slot.hour_start)} - ${formatHour(slot.hour_end)}`
                                            }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-12" v-if="isEditing">
                                    <div class="d-flex justify-content-between">
                                        <button class="btn btn-sm btn-circle btn-success" @click="isEditing = false">Annuler</button>
                                        <button class="btn btn-sm btn-circle btn-success" @click="putEdit">Enregistrer</button>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="command.collect_type === COLLECT_TYPE_DELIVERY.value">
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-6">Livraison</div>
                                <div class="col-6 value">
                                    {{ deliveryAmount }}
                                </div>
                            </div>
                        </template>
                        <template v-if="command.collect_type === COLLECT_TYPE_DISPATCH.value">
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-6">Expédition</div>
                                <div class="col-6 value">{{ command.dispatch_shipping_method_name }} - {{ dispatchFees }}</div>
                            </div>
                        </template>
                        <div class="sepa"></div>
                        <div class="row">
                            <div class="col-6">Statut</div>
                            <div class="col-6 value">
                                <ClickAndCollectStatusTag :restaurant_id="restaurant_id" :command="command" />
                            </div>
                        </div>
                        <div class="sepa"></div>
                        <div class="row">
                            <div class="col-6">Montant</div>
                            <div class="col-6 value">{{ formatCurrency(command.total_amount / 100) }}</div>
                        </div>
                        <div class="sepa"></div>
                        <div class="row">
                            <div class="col-6">Code promotionnel</div>
                            <div class="col-6 value">
                                {{ command.promo_code ? getPromoCodeDisplayable(command.promo_code) : "--" }}
                            </div>
                        </div>
                        <template v-if="hasGiftVouchers">
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-6">Chèque cadeau</div>
                                <div class="col-6 value">
                                    {{ command.gv_sale ? getGvSaleDisplayable(command.gv_sale) : "--" }}
                                </div>
                            </div>
                        </template>
                        <div class="sepa"></div>
                        <div class="row">
                            <div class="col-6">Moyen de paiement{{ paidAndRefundedCommandPayments.length > 1 ? "s" : "" }}</div>
                            <div class="col-6 value">
                                <template v-if="paidAndRefundedCommandPayments.length > 0">
                                    <span class="d-block" v-for="payment in paidAndRefundedCommandPayments" :key="payment.id">
                                        <template v-if="payment.is_online">
                                            {{ getOnlinePaymentMethodLabel(payment.online_payment_method) }}
                                            <img
                                                v-if="getOnlinePaymentMethodImg(payment.online_payment_method)"
                                                v-tooltip="{
                                                    delay: { show: 400, hide: 0 },
                                                    content: 'Paiement en ligne',
                                                }"
                                                style="width: 14px; margin-left: 2px; margin-bottom: 2px"
                                                :src="`/images/${getOnlinePaymentMethodImg(payment.online_payment_method)}`" />
                                        </template>
                                        <template v-else>
                                            {{ getPaymentMethodLabel(payment.method) }}
                                        </template>
                                    </span>
                                </template>
                                <span v-else>Non renseigné</span>
                            </div>
                        </div>
                        <div class="sepa"></div>
                        <div class="row">
                            <div class="col-6">Statut du paiement</div>
                            <div class="col-6 value">
                                <span :class="getPaymentStatusClass(command.payment_status)">{{
                                    getPaymentStatusLabel(command.payment_status)
                                }}</span>
                            </div>
                        </div>
                        <div class="sepa"></div>
                        <div class="row mb-1">
                            <div class="col-12">Commentaire Public</div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <blockquote>
                                    <p class="value">
                                        {{ $_.isEmpty(command.comment) ? "Aucun commentaire" : command.comment }}
                                    </p>
                                </blockquote>
                            </div>
                        </div>
                        <template v-if="hasPartialRefund || canRefundPartially">
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-12">
                                    <div v-if="canRefundPartially" class="d-flex justify-content-between">
                                        <button type="button" class="btn btn-sm btn-success btn-circle" @click="showRefundProductsModal = true">
                                            Remboursement
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-sm btn-danger btn-circle"
                                            v-if="canCancelCommand"
                                            @click="cancelCommand()">
                                            Annuler la commande
                                        </button>
                                    </div>
                                    <ShowRefundedProducts v-else-if="hasPartialRefund" :command="command" />
                                </div>
                            </div>
                        </template>
                        <template v-else-if="canCancelCommand">
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-12">
                                    <button type="button" class="btn btn-sm btn-danger btn-circle" @click="cancelCommand()">
                                        Annuler la commande
                                    </button>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="col-md-5 mb-3 d-flex flex-column">
                    <loader-component v-if="isClientLoading" />
                    <template v-else>
                        <client-card
                            v-if="command && command.client !== null"
                            :client="command.client"
                            :module-enum="MODULE_TYPE_CLICK_AND_COLLECT"
                            :is-change-in-progress="isAssociatingInProgress"
                            @cancel-in-progress="emptyClient"
                            @update-in-progress="associateClientBack"
                            :hide-client-edit-btn="hide_client_btn" />
                        <div v-else class="d-flex justify-content-center align-items-center h-100">
                            <button class="btn btn-sm btn-success btn-circle" @click="canOpenSearchClientModal = true">
                                {{ $t("labels.form.actions.associate.client") }}
                            </button>
                        </div>
                    </template>
                    <div
                        class="p-4 bg-grad text-white"
                        v-if="command.collect_type === COLLECT_TYPE_DELIVERY.value || command.dispatch_type === DISPATCH_TYPE_HOME.value">
                        <div class="row m-0">
                            <div class="col-md-12">
                                <label class="mb-1">
                                    <strong>Adresse de livraison</strong>
                                </label>
                                <div class="row">
                                    <div class="col-12">
                                        {{ clientFullName }}<br />
                                        <span class="d-block">{{ command.delivery_address_displayable }}</span>
                                        <a
                                            class="btn btn-circle b-block mt-3"
                                            style="background-color: white"
                                            :href="'https://www.google.com/maps/place/' + command.delivery_address_displayable"
                                            target="_blank"
                                            >S'y rendre</a
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <ul class="pl-0 mb-0">
                            <template v-for="(commandProduct, index) in command.command_products.data">
                                <li :key="`${command.id}-${index}`">
                                    {{ commandProduct.quantity }} x {{ commandProduct.product.name }}
                                    <small class="text-warning" v-if="commandProduct.comment && !$_.isEmpty(commandProduct.comment)"
                                        >({{ commandProduct.comment }})</small
                                    >
                                    <ul class="mb-0" v-if="commandProduct.command_product_option.data.length > 0">
                                        <li
                                            v-for="(commandProductOption, index2) in commandProduct.command_product_option.data"
                                            :key="`${command.id}-${index}-${index2}`">
                                            {{ commandProductOption.product_option.cc_product.name }}
                                            <span v-if="commandProductOption.quantity > 1"> (x{{ commandProductOption.quantity }})</span>
                                        </li>
                                    </ul>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row m-0" v-if="command.private_comment">
                <div class="col-12 mb-3">
                    <div class="border-light b-radius-20 p-4">
                        <label class="mb-1">
                            <strong>Commentaire Privé</strong>
                        </label>
                        <div class="row">
                            <div class="col-12">
                                {{ command.private_comment }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row m-0">
                <div class="col-12 mb-3">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row" style="cursor: pointer" @click="showLog = !showLog">
                            <div class="col-12">
                                <strong class="mb-0">Historique des modifications</strong>
                            </div>
                        </div>
                        <template v-if="showLog">
                            <ShowCommandLogsList :command="command" />
                        </template>
                    </div>
                </div>
            </div>

            <div class="row m-0" v-if="commandTypeLabel">
                <div class="col-12 text-right pr-4">
                    <div>
                        <i
                            >Commande {{ commandTypeLabel }} le {{ moment(command.created_at).format("LLL")
                            }}<span v-if="commandUserDisplayable"> par {{ commandUserDisplayable }}</span></i
                        >
                    </div>
                    <div v-for="mailLog in command.mail_logs.data" :key="mailLog.id">
                        <small v-if="mailLog.permanent_failed_at || !mailLog.sent_at" class="text-danger"
                            >Une erreur est survenue lors de l'envoi du mail de {{ getCommandMailLogLabel(mailLog.type) }}</small
                        >
                        <small v-else-if="mailLog.sent_at && !mailLog.delivered_at" class="text-warning"
                            >Le mail de {{ getCommandMailLogLabel(mailLog.type) }} est en cours d'envoi</small
                        >
                        <small v-else-if="mailLog.delivered_at"
                            >Mail de {{ getCommandMailLogLabel(mailLog.type) }} envoyé le {{ moment(mailLog.delivered_at).format("DD/MM/Y[ à ]HH:mm")
                            }}<span v-if="mailLog.opened_at"> (ouvert)</span></small
                        >
                    </div>
                </div>
            </div>
            <RefundProductsModal
                v-if="showRefundProductsModal"
                @refunded="productsRefunded"
                @close="showRefundProductsModal = false"
                :command_id="command_id" />
            <AskKeepFeesModal v-if="showAskKeepFeesModal" :command="command" @validate="cancelCommand" @close="showAskKeepFeesModal = false" />
            <wrapper-search-client-modal
                v-if="canOpenSearchClientModal"
                @close="canOpenSearchClientModal = false"
                @selected-client="associateClient" />
        </div>
    </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import moment from "moment";

import LoaderComponent from "../../../components/LoaderComponent.vue";
import ClickAndCollectStatusTag from "./StatusTag.vue";
import ShowRefundedProducts from "./Payment/ShowRefundedProducts.vue";
import ShowCommandLogsList from "./logs/ShowCommandLogsList.vue";
import ClientCard from "../../Default/Clients/clientCard.vue";

import AskKeepFeesModal from "../../Modals/clickAndCollect/Commands/AskKeepFeesModal.vue";
import RefundProductsModal from "../../Modals/clickAndCollect/Commands/RefundProductsModal.vue";

import PaymentStatusEnum from "../../../mixins/enums/click_and_collect/PaymentStatusEnum.js";
import OnlinePaymentMethodEnum from "../../../mixins/enums/click_and_collect/OnlinePaymentMethodEnum.js";
import PaymentMethodEnum from "../../../mixins/enums/click_and_collect/PaymentMethodEnum.js";
import PromoCodeTypeEnum from "../../../mixins/enums/click_and_collect/PromoCodeTypeEnum.js";
import CollectTypesEnum from "../../../mixins/enums/click_and_collect/CollectTypesEnum.js";
import DispatchTypesEnum from "../../../mixins/enums/click_and_collect/DispatchTypesEnum.js";
import CommandStatusEnum from "../../../mixins/enums/click_and_collect/CommandStatusEnum.js";
import CommandMailLogEnum from "../../../mixins/enums/click_and_collect/CommandMailLogEnum.js";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";
import WrapperSearchClientModal from "../../Modals/clients/WrapperSearchClientModal.vue";

moment.locale("fr");

export default {
    name: "ShowClickAndCollectCommand",
    data() {
        return {
            showLog: false,
            loading: 0,
            error: null,
            command: null,
            fr,
            moment,
            showRefundProductsModal: false,
            isEditing: false,
            edition: {
                date: null,
                slot: null,
            },
            openDaysPeriods: null,
            slots: null,
            disabledDates: {
                customPredictor: this.customAvailabilityPredictor,
            },
            showAskKeepFeesModal: false,
            canOpenSearchClientModal: false,
            isAssociatingInProgress: false,
            isClientLoading: false,
        };
    },
    props: {
        command_id: {
            default: null,
        },
        restaurant_id: {
            default: null,
        },
        hide_client_btn: {
            default: false,
        },
    },
    mixins: [
        PaymentStatusEnum,
        PaymentMethodEnum,
        PromoCodeTypeEnum,
        CollectTypesEnum,
        DispatchTypesEnum,
        CommandStatusEnum,
        OnlinePaymentMethodEnum,
        CommandMailLogEnum,
        ModuleTypesEnum,
    ],
    computed: {
        clientFullName() {
            if (this.command.client !== null) {
                return `${this.command.client.firstname || ""} ${this.command.client.lastname || ""}`;
            }
            return "";
        },
        hasPartialRefund() {
            return this.command.command_payments.data.some((payment) => payment.status === this.PAYMENT_PARTIALLY_REFUNDED.value);
        },
        canRefundPartially() {
            const paidPayments = this.command.command_payments.data.filter((payment) => {
                return this.inEnum(payment.status, this.PAID_STATUS);
            });
            if (paidPayments.length !== 1) return false;
            const payment = paidPayments[0];
            const validPaymentMethod = [this.ONLINE_PAYMENT_METHOD_STRIPE.value, this.ONLINE_PAYMENT_METHOD_PAYPLUG.value];
            return (
                payment.status === this.PAYMENT_CAPTURED.value &&
                payment.is_online &&
                !payment.is_group_payment &&
                validPaymentMethod.includes(payment.online_payment_method)
            );
        },
        paidAndRefundedCommandPayments() {
            return this.command.command_payments.data.filter((payment) => {
                return this.inEnum(payment.status, this.PAID_STATUS_WITH_REFUNDED);
            });
        },
        canCancelCommand() {
            const validStatusForCancel = [this.COMMAND_TO_PREPARE.value, this.COMMAND_READY.value];
            if (!validStatusForCancel.includes(this.command.status)) return false;
            if (!this.command.command_payment) return true;
            return this.command.command_payment.status != this.PAYMENT_PARTIALLY_REFUNDED.value;
        },
        commandTypeLabel() {
            if (!this.command) return null;
            if (this.command.type === "widget") return "web effectuée";
            if (this.command.type === "bo") return "manuelle prise";
            return null;
        },
        commandUserDisplayable() {
            if (!this.command || !this.command.user) return null;
            return `${this.command.user.firstname} ${this.command.user.lastname}`;
        },
        hasGiftVouchers() {
            return this.$store.getters["restaurants/hasModule"](this.MODULE_TYPE_GIFT_VOUCHERS.value, this.restaurant_id);
        },
        commandDateEditComputed: {
            get() {
                return moment(this.edition.date).toDate();
            },
            set(value) {
                this.edition.date = moment(value).format("YYYY-MM-DD");
            },
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update() {
            return this.rights.includes("click_and_collect.commands.update");
        },
        slot_hour_computed() {
            switch (this.command.collect_type) {
                case this.COLLECT_TYPE_COLLECT.value:
                    return this.formatHour(this.command.slot.hour);
                case this.COLLECT_TYPE_DELIVERY.value:
                    return this.formatHour(this.command.slot.hour_start) + " - " + this.formatHour(this.command.slot.hour_end);
                default:
                    return "";
            }
        },
        deliveryAmount() {
            if (!this.command.cc_delivery_area) return "Error";
            const deliveryArea = this.command.cc_delivery_area;
            if (
                deliveryArea.is_free ||
                (deliveryArea.free_above !== null && deliveryArea.free_above * 100 <= this.command.total_amount - deliveryArea.price * 100)
            )
                return "Gratuite";
            return this.formatCurrency(deliveryArea.price);
        },
        dispatchFees() {
            if (this.command.collect_type != this.COLLECT_TYPE_DISPATCH.value) return this.formatCurrency(0);
            if (this.command.dispatch_fees !== null) return this.formatCurrency(this.command.dispatch_fees / 100);
            return "0€";
        },
        hasPaidWithStripeAndWasCaptured() {
            return this.command.command_payments.data.some((payment) => {
                return payment.online_payment_method == this.ONLINE_PAYMENT_METHOD_STRIPE.value && payment.status == this.PAYMENT_CAPTURED.value;
            });
        },
    },
    methods: {
        cancelCommand(keep_fees = null) {
            if (this.hasPaidWithStripeAndWasCaptured) {
                if (!keep_fees) {
                    this.showAskKeepFeesModal = true;
                    return;
                }
                this.showAskKeepFeesModal = false;
            } else if (!confirm("Êtes-vous sûr de vouloir annuler cette commande ? (Cette action entraînera son remboursement)")) return;
            this.loading++;
            this.$store
                .dispatch("ccCommands/updateStatus", {
                    restaurant_id: this.restaurant_id,
                    command_id: this.command.id,
                    status: this.COMMAND_CANCELED.value,
                    keep_fees,
                })
                .then(() => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: "La commande a bien été annulée",
                    });
                    this.fetchData();
                })
                .catch((error) => {
                    this.loading--;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "warn",
                        title: errorMsg,
                    });
                });
        },
        getPromoCodeDisplayable(promo_code) {
            const unit = this.getPromoCodeTypeUnit(promo_code.type);
            let amount = promo_code.amount;
            if (promo_code.type === this.PROMO_CODE_TYPE_AMOUNT.value) amount /= 100;
            return `${promo_code.name} (-${amount}${unit})`;
        },
        getGvSaleDisplayable(gv_sale) {
            return `${gv_sale.numero} (-${gv_sale.amount}€)`;
        },
        putEdit() {
            this.loading++;

            this.$store
                .dispatch("ccCommands/updateDateAndSlot", {
                    restaurant_id: this.restaurant_id,
                    command_id: this.command_id,
                    params: {
                        date: this.edition.date,
                        slot_id: this.edition.slot ? this.edition.slot.id : null,
                    },
                })
                .then((response) => {
                    this.loading--;
                    this.isEditing = false;
                    this.fetchData();
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                })
                .catch((error) => {
                    this.loading--;
                    let errorMsg = "Une erreur est survenue.";
                    if (error.response && error.response.data.message) {
                        errorMsg = error.response.data.message;
                    } else if (error.message) {
                        errorMsg = error.message;
                    }
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
        customDateFormater(date) {
            return moment(date).format("ddd LL");
        },
        customAvailabilityPredictor(date) {
            const momentDate = moment(date);
            const openings = this.openDaysPeriods.filter((odp) => {
                if (!odp.special) return false;
                const odpFrom = moment(odp.from);
                const odpTo = moment(odp.to);
                return odpFrom.isSameOrBefore(momentDate) && odpTo.isSameOrAfter(momentDate);
            });
            if (openings.length > 0) {
                var period = openings.find((opening) => {
                    return opening.days.includes(this.getUsWeekDayFromFrWeekday(momentDate.weekday()));
                });
            } else {
                var period = this.openDaysPeriods.find((odp) => {
                    const odpFrom = moment(odp.from);
                    const odpTo = odp.to ? moment(odp.to) : null;

                    return (
                        odpFrom.isSameOrBefore(momentDate) &&
                        (!odpTo || odpTo.isSameOrAfter(momentDate)) &&
                        odp.days.includes(this.getUsWeekDayFromFrWeekday(momentDate.weekday()))
                    );
                });
            }

            if (period) {
                return false;
            }

            return true;
        },
        getUsWeekDayFromFrWeekday(frWeekday) {
            frWeekday += 1;
            if (frWeekday === 7) {
                frWeekday = 0;
            }

            return frWeekday;
        },
        editCommandDateAndSlot() {
            this.isEditing = true;
            this.edition.date = this.command.reservation_date;
        },
        productsRefunded() {
            this.showRefundProductsModal = false;
            this.fetchData();
        },
        formatHour(hour) {
            return hour.split(":").splice(0, 2).join("h");
        },
        fetchOpenDaysPeriods() {
            this.loading++;
            this.$store
                .dispatch("ccCommands/fetchOpenDaysPeriods", {
                    restaurant_id: this.restaurant_id,
                    collect_type: this.command.collect_type,
                })
                .then((response) => {
                    this.openDaysPeriods = response.data.data;
                    this.loading--;
                })
                .catch((error) => {
                    if (error.response && error.response.data.message) {
                        this.error = error.response.data.message;
                    } else if (error.message) {
                        this.error = error.message;
                    } else {
                        this.error = "Une erreur est survenue.";
                    }
                    this.loading--;
                });
        },
        fetchSlots() {
            this.loading++;
            this.$store
                .dispatch("ccCommands/fetchSlotsForDay", {
                    restaurant_id: this.restaurant_id,
                    params: {
                        date: this.edition.date,
                        collect_type: this.command.collect_type,
                        for_edit: 1,
                    },
                })
                .then((response) => {
                    this.slots = response.data.data;
                    this.loading--;
                })
                .catch((error) => {
                    if (error.response && error.response.data.message) {
                        this.error = error.response.data.message;
                    } else if (error.message) {
                        this.error = error.message;
                    } else {
                        this.error = "Une erreur est survenue.";
                    }
                    this.loading--;
                });
        },
        fetchData() {
            this.error = null;

            if (this.restaurant_id === null || this.restaurant_id === "" || this.command_id === null || this.command_id === "") {
                return;
            }

            this.loading++;
            this.$store
                .dispatch("ccCommands/fetchCommandById", {
                    command_id: this.command_id,
                    restaurant_id: this.restaurant_id,
                })
                .then((response) => {
                    if (response.data) {
                        this.$set(this, "command", response.data);
                        this.$emit("command-fetched", response.data);
                        if (response.data.collect_type != this.COLLECT_TYPE_DISPATCH.value && this.has_right_to_update) this.fetchOpenDaysPeriods();
                    }
                    this.loading--;
                })
                .catch((err) => {
                    if (err.response && err.response.data && err.response.data.message) {
                        this.error = err.response.data.message;
                    } else if (err.message) {
                        this.error = err.message;
                    }
                    this.loading--;
                });
        },
        associateClientBack() {
            this.isClientLoading = true;
            this.httpPut(`/api/click_and_collect/${this.restaurant_id}/commands/${this.command_id}/clients/${this.command.client.id}/associate`)
                .then((response) => {
                    if (response !== false) {
                        this.isAssociatingInProgress = false;
                    }
                })
                .finally(() => (this.isClientLoading = false));
        },
        emptyClient() {
            this.command.client = null;
            this.isAssociatingInProgress = false;
        },
        associateClient(event) {
            this.command.client = event;
            this.isAssociatingInProgress = true;
            this.canOpenSearchClientModal = false;
        },
    },
    created() {
        this.fetchData();
    },
    updated() {
        if (this.$refs.vueTelInput) {
            this.$refs.vueTelInput.$el.classList.remove("vue-tel-input");
        }
    },
    watch: {
        restaurant_id: {
            handler: function (newVal) {
                this.fetchData();
            },
        },
        command_id: {
            handler: function (newVal) {
                this.fetchData();
            },
        },
        "edition.date": function (newVal, oldVal) {
            if (moment(newVal).day() === moment(this.command.reservation_date).day()) this.edition.slot = this.command.slot;
            else this.edition.slot = null;
            if (newVal) {
                this.fetchSlots();
            }
        },
    },
    components: {
        ClickAndCollectStatusTag,
        LoaderComponent,
        RefundProductsModal,
        ShowRefundedProducts,
        Datepicker,
        ShowCommandLogsList,
        AskKeepFeesModal,
        ClientCard,
        WrapperSearchClientModal,
    },
};
</script>
