<template>
    <div class="d-flex flex-column justify-content-center">
        <button
            style="min-width: 300px"
            type="button"
            class="btn btn-sm btn-outline-secondary dropdown-toggle d-md-none"
            @click.stop.prevent="toggleDisplay = !toggleDisplay">
            {{ $tl("labels.form.pickColumnsToDisplay") }}
        </button>
        <div style="min-width: 300px" :class="{ 'd-none': toggleDisplay }" class="d-md-block">
            <vue-multiselect
                :disabled="isLoading"
                v-model="selectedColumns"
                :multiple="true"
                :options="options"
                :max-height="300"
                :placeholder="$tl('labels.form.pickColumnsToDisplay')"
                :searchable="false"
                :close-on-select="false"
                :show-labels="false"
                label="label"
                track-by="value"
                @input="updateColumns"
                open-direction="bottom">
            </vue-multiselect>
        </div>
    </div>
</template>
<script>
import VueMultiselect from "vue-multiselect";
import ColumnReservationEnum from "../../../../mixins/enums/booking/ColumnReservationEnum.js";

export default {
    components: {
        VueMultiselect,
    },
    mixins: [ColumnReservationEnum],
    props: {
        settings: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            selectedColumns: [],
            backupSelectedOptions: [],
            isLoading: false,
            options: [],
            toggleDisplay: true,
        };
    },
    computed: {
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        url() {
            let url = `/api/restaurants/${this.restaurantId}/reservations/datatable-columns`;

            if (this.settings.props && this.settings.props.serviceId) {
                url = url.concat("?", `service=${this.settings.props.serviceId}`);
            }

            return url;
        },
        selectedColumnsSanitized() {
            return this.selectedColumns.map((object) => object.value);
        },
    },
    created() {
        this.getColumns();
    },
    methods: {
        mapToLabel(value) {
            return value.map((element) => {
                return {
                    label: this.getLabelColumnReservation(element, this.restaurantId),
                    value: element,
                };
            });
        },
        getColumns() {
            this.httpGet(this.url, { handleReject: false }).then((response) => {
                if (response === false) {
                    return;
                }

                this.selectedColumns = this.mapToLabel(response.data.columns);
                this.options = this.mapToLabel(response.data.options);
                this.$parent.$emit("emit-event", { eventName: "update-columns", data: this.selectedColumnsSanitized });
            });
        },
        updateColumns() {
            this.isLoading = true;
            this.httpPut(this.url, { columns: this.selectedColumnsSanitized })
                .then((response) => {
                    if (response !== false) {
                        this.$parent.$emit("emit-event", { eventName: "update-columns", data: this.selectedColumnsSanitized });
                    } else {
                        this.selectedColumns = this.backupSelectedOptions;
                    }
                })
                .finally(() => (this.isLoading = false));
        },
    },
    watch: {
        selectedColumns: {
            handler(newValue, oldValue) {
                this.backupSelectedOptions = oldValue;
            },
            deep: true,
        },
    },
};
</script>
