<template>
    <div>
        <div v-if="hasErrors('choosen_menus')">
            <div class="invalid-feedback d-block" v-for="(error, index) in formErrors.errors.choosen_menus" :key="index">
                {{ error }}
            </div>
        </div>
        <div class="one-menu mb-1" v-for="menu in parsedMenus" :key="menu.id">
            <div class="row text-left mb-0">
                <div class="col-sm-8">
                    <label class="mb-1">
                        {{ menu.name }}{{ menu.price ? ` - ${formatCurrency(menu.price, getRestaurantCurrency(restaurantId))}` : "" }}
                    </label>
                    <small class="d-block text-muted mb-2">
                        {{ menu.description }}
                    </small>
                </div>
                <div class="col-sm-4">
                    <select
                        style="height: 44px"
                        class="custom-select"
                        :ref="`ref_menu_${menu.id}`"
                        :class="{ 'is-invalid': hasErrors('choosen_menus') }"
                        :value="menu.quantity"
                        @change="chooseMenu(menu, $event)">
                        <option :value="0">0</option>
                        <option v-for="nb in menusLeftToChoose(menu.quantity)" :value="nb" :key="nb">
                            {{ nb }}
                        </option>
                    </select>
                </div>
            </div>
            <div v-if="menu.quantity > 0 && menu.options.length > 0" class="p-3 border-right-light border-bottom-light border-left-light">
                <div class="row text-left mb-0" v-for="option in menu.options" :key="option.id">
                    <div class="col-8">
                        <label class="mb-0">
                            {{ option.name }}{{ option.price ? ` - ${formatCurrency(option.price, getRestaurantCurrency(restaurantId))}` : "" }}
                            <small v-if="option.quantity_type === 'mandatory_for_each'" class="text-muted"> ({{ $tl("labels.perPerson") }}) </small>
                        </label>
                        <small class="d-block text-muted">
                            {{ option.description }}
                        </small>
                    </div>
                    <div class="col-4">
                        <div v-if="option.quantity_type === 'mandatory_for_each'">
                            <select v-model="option.quantity" :ref="`ref_option_${menu.id}${option.id}`" class="custom-select">
                                <option value="0">--</option>
                                <option :value="menu.quantity">
                                    {{ menu.quantity }}
                                </option>
                            </select>
                        </div>

                        <select
                            v-else-if="option.quantity_type === 'limited_by_pers'"
                            v-model="option.quantity"
                            class="custom-select"
                            :ref="`ref_option_${menu.id}${option.id}`">
                            <option value="0">0</option>
                            <option v-for="nbOption in menu.quantity" :key="nbOption" :value="nbOption">
                                {{ nbOption }}
                            </option>
                        </select>

                        <input
                            v-else-if="option.quantity_type === 'unlimited'"
                            v-model="option.quantity"
                            class="form-control mb-2"
                            type="number"
                            step="1"
                            min="0"
                            :ref="`ref_option_${menu.id}${option.id}`" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        available_menus: {
            default: null,
        },
        are_menus_unique: {
            default: false,
        },
        reservation: {
            type: Object,
            required: true,
        },
        nb_total: {
            default: 0,
        },
        formErrors: {
            default: null,
        },
        restaurantId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            choicesPool: 0,
            parsedMenus: [],
            currentMenuFound: undefined,
        };
    },
    created() {
        this.parsedMenus = this.parseMenus();
    },
    watch: {
        parsedMenus: {
            immediate: true,
            deep: true,
            handler(newValue) {
                this.reservation.choosen_menus = newValue;
            },
        },
    },
    methods: {
        parseMenus() {
            return this.available_menus.map((menu) => {
                let options = [];
                const menuQuantity = this.getMenuQuantity(menu);

                if (menu.menu_options.data.length > 0) {
                    options = menu.menu_options.data.map((menuOption) => {
                        return {
                            id: menuOption.id,
                            name: menuOption.name,
                            description: menuOption.description,
                            price: menuOption.price,
                            quantity_type: menuOption.quantity_type,
                            quantity: this.getMenuOptionQuantity(menu, menuOption),
                        };
                    });
                }

                return {
                    id: menu.id,
                    name: menu.name,
                    description: menu.description,
                    price: menu.price,
                    options: options,
                    quantity: menuQuantity,
                };
            });
        },
        getMenuOptionQuantity(menu, menuOption) {
            let quantity = 0;

            if (this.currentMenuFound !== undefined && this.currentMenuFound.id === menu.id) {
                const menuOptionFound = this.currentMenuFound.options.data.find((currentMenuOption) => currentMenuOption.id === menuOption.id);

                if (menuOptionFound !== undefined) {
                    quantity = Number.parseInt(menuOptionFound.nb);
                }
            }
            return quantity;
        },
        getMenuQuantity(menu) {
            let quantity = 0;

            if (this.reservation.back_choosen_menus instanceof Array) {
                const menuFound = this.reservation.back_choosen_menus.find((currentMenu) => currentMenu.id === menu.id);

                if (menuFound !== undefined) {
                    this.currentMenuFound = menuFound;
                    quantity = Number.parseInt(menuFound.nb);
                    this.choicesPool += quantity;
                }
            }
            return quantity;
        },
        menusLeftToChoose(menuQuantity) {
            return this.nb_total - this.choicesPool + menuQuantity;
        },
        chooseMenu(menu, event) {
            const value = this.sanitizeValue(event.target.value);

            this.choicesPool += value - menu.quantity;
            menu.quantity = value;
        },
        sanitizeValue(value) {
            value = Number.parseInt(value);

            if (isNaN(value) || value < 0) {
                value = 0;
            }
            if (value > this.nb_total) {
                value = this.nb_total;
            }
            return value;
        },
        hasErrors(name) {
            return this.formErrors && this.formErrors.errors && !this.$_.isEmpty(this.formErrors.errors[name]);
        },
    },
};
</script>
