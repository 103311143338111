var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "h-100 w-100 d-flex flex-column align-items-center justify-content-center text-center",
    },
    [
      _c("h5", { staticClass: "text-center" }, [
        _vm._v("\n        " + _vm._s(_vm.message) + "\n    "),
      ]),
      _vm._v(" "),
      !_vm.isRoutePublic
        ? _c(
            "button",
            {
              staticClass: "btn btn-circle btn-success",
              attrs: { type: "button" },
              on: { click: _vm.redirectToSubscription },
            },
            [_vm._v("Modifier mes licences")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }