import StripePlanTypesEnum from "./StripePlanTypesEnum";

export default {
    data() {
        return {
            SUBSCRIPTION_STATUS_TRIALING: {
                label: "Essai gratuit",
                value: "trialing",
            },
            SUBSCRIPTION_STATUS_ACTIVE: {
                label: "Active",
                value: "active",
            },
            SUBSCRIPTION_STATUS_PAST_DUE: {
                label: "En retard de paiement",
                value: "past_due",
            },
            SUBSCRIPTION_STATUS_CANCELED: {
                label: "Annulée",
                value: "canceled",
            },
            SUBSCRIPTION_STATUS_UNPAID: {
                label: "Non payée",
                value: "unpaid",
            },
            SUBSCRIPTION_STATUS_INCOMPLETE: {
                label: "Incomplète",
                value: "incomplete",
            },
            SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED: {
                label: "Incomplète expirée",
                value: "incomplete_expired",
            },
        };
    },
    mixins: [StripePlanTypesEnum],
    methods: {
        getSubscriptionStatusLabel(value) {
            const found = this.ALL_SUBSCRIPTION_STATUS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
        getSubscriptionStatusLabelClass(value) {
            switch (value) {
                case this.SUBSCRIPTION_STATUS_INCOMPLETE.value:
                case this.SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED.value:
                case this.SUBSCRIPTION_STATUS_CANCELED.value:
                case this.SUBSCRIPTION_STATUS_UNPAID.value:
                    return "text-danger";
                case this.SUBSCRIPTION_STATUS_PAST_DUE.value:
                    return "text-warning";
                case this.SUBSCRIPTION_STATUS_ACTIVE.value:
                    return "text-success";
                case this.SUBSCRIPTION_STATUS_TRIALING.value:
                default:
                    return "";
            }
        },
        getValidForDiscountSubscriptions(plan) {
            // No discount for monthly subscriptions
            if (plan === this.STRIPE_PLAN_TYPE_MONTHLY.value) {
                return [];
            }

            let validForDiscountSubscriptions = this.ownerSubscriptions.filter(
                (s) => this.inEnum(s.status, this.validSubscriptionStatus) && !s.is_free && !s.discounted
            );

            if (plan === "yearly") {
                validForDiscountSubscriptions = validForDiscountSubscriptions.filter((s) => s.plan === plan);
            }

            return validForDiscountSubscriptions;
        },
    },
    computed: {
        ALL_SUBSCRIPTION_STATUS() {
            return [
                this.SUBSCRIPTION_STATUS_INCOMPLETE,
                this.SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED,
                this.SUBSCRIPTION_STATUS_TRIALING,
                this.SUBSCRIPTION_STATUS_ACTIVE,
                this.SUBSCRIPTION_STATUS_PAST_DUE,
                this.SUBSCRIPTION_STATUS_CANCELED,
                this.SUBSCRIPTION_STATUS_UNPAID,
            ];
        },
        validSubscriptionStatus() {
            return [
                this.SUBSCRIPTION_STATUS_TRIALING,
                this.SUBSCRIPTION_STATUS_ACTIVE,
                this.SUBSCRIPTION_STATUS_PAST_DUE,
                this.SUBSCRIPTION_STATUS_UNPAID,
            ];
        },
        cancelNowSubscriptionStatus() {
            return [this.SUBSCRIPTION_STATUS_PAST_DUE, this.SUBSCRIPTION_STATUS_UNPAID];
        },
        ownerSubscriptions() {
            return this.$store.getters["users/getOwnerSubscriptions"];
        },
    },
};
