<template>
    <div class="reservations-grid">
        <ns-table
            tableClass="table table-responsive-lg capitalize table-striped"
            :data-source="reservations_"
            clickable
            use-slot-headings
            :toolbar-settings="toolbarSettings"
            @update-columns="columns = $event"
            @row-selected="rowSelected">
            <template v-slot:toolbar>
                <slot name="toolbar"></slot>
            </template>
            <template v-slot:head>
                <ns-headings :headings="headings" :excluded-columns="excludedColumns" />
            </template>
            <template v-slot:body="{ data }">
                <td v-if="!excludedColumns.includes(COLUMN_RESERVATION_DATE.value)" class="none-mobile">
                    <feather type="clock" v-tooltip="getTooltip(displayDate(data.created_at, DATETIME_MED))" />
                    <reservation-issuer-icon
                        class="ml-1"
                        :issuer="data.created_from"
                        :provider="data.provider_name"
                        :created-at="data.created_at"
                        :user="data.user" />
                </td>
                <td>
                    <column-client :row="data" />
                </td>
                <td v-if="!excludedColumns.includes(COLUMN_RESERVATION_COMPANY.value)">
                    {{ data.client && data.client.company ? data.client.company : ""}}
                </td>
                <td>
                    <span v-if="data.nb_children" v-tooltip="getTooltip(displayPax(data.total_nb_pers, data.nb_children))">
                        {{ data.total_nb_pers }}({{ data.nb_children }})
                    </span>
                    <span v-else>
                        {{ data.total_nb_pers }}
                    </span>
                </td>
                <td>
                    {{ displayHour(data.slot.hour_start) }}
                </td>
                <td v-if="!excludedColumns.includes(COLUMN_RESERVATION_TABLE.value)" class="none-mobile">
                    <div class="table-text-center">
                        <template v-if="data.tables.data.length > 0">
                            <span v-for="(table, index) in data.tables.data" :key="index">
                                {{ table.name }}{{ index + 1 < data.tables.data.length ? " - " : "" }}
                            </span>
                        </template>
                        <template v-else-if="data.num_table && data.num_table !== ''">
                            {{ data.num_table }}
                        </template>
                    </div>
                </td>
                <td
                    v-if="(isFeatRoomNumbersEnable || isHotelModeEnabled) && !excludedColumns.includes(COLUMN_RESERVATION_ROOM.value)"
                    class="none-mobile">
                    <hotel-client-icon v-if="data.is_hotel_client" />
                    {{ data.room_number }}
                </td>
                <td>
                    <reservation-tag
                        :editable="has_right_to_update_reservation"
                        :cancellation="service.cancellation_until"
                        :update="false"
                        :reservation="data"
                        :restaurant_id="restaurant.id"
                        :isStripeEnabled="restaurant.stripe_client_id || (restaurant.payplug_public_key && restaurant.payplug_secret_key)"
                        @displayNoshowModal="emitEvent('displayNoshowModal', data)"
                        @displayCancelModal="emitEvent('displayCancelModal', { newStatus: $event, data })"
                        @displayRefundPartiallyModal="emitEvent('displayRefundPartiallyModal', data)" />
                </td>
                <td v-if="!excludedColumns.includes(COLUMN_RESERVATION_PAYMENT.value)">
                    <div class="table-text-center">
                        <option-bank-status-icon
                            :restaurant_id="data.restaurant_id"
                            :timezoneForOverride="getAutoDetectedTimezone(data.restaurant_id)"
                            :reservation="data" />
                    </div>
                </td>
                <td class="none-mobile d-flex">
                    <button
                        type="button"
                        v-tooltip="getTooltip($tl('labels.form.actions.edit'))"
                        class="btn btn-sm btn-success btn-square ml-1"
                        :disabled="!has_right_to_update_reservation"
                        @click="emitEvent('editReservation', data.id, $event)">
                        <feather type="edit" />
                    </button>
                    <button
                        type="button"
                        v-tooltip="getTooltip($tl('labels.booking.reservations.sendDetailsToClient'))"
                        class="btn btn-sm btn-outline-secondary btn-square ml-1"
                        v-if="!avoidStatusForDetails.includes(data.status)"
                        :disabled="!has_right_to_update_reservation"
                        @click="emitEvent('sendEmail', data.id, $event)">
                        <feather type="send" />
                    </button>
                </td>
                <td v-if="!excludedColumns.includes(COLUMN_RESERVATION_CONFIRMATION.value)" class="none-mobile">
                    <template v-if="data.confirmed_by_client !== null">
                        <button v-if="data.confirmed_by_client === 1" type="button" class="btn btn-sm btn-square" :disabled="true">
                            <feather
                                v-tooltip="getTooltip($tl('labels.booking.reservations.confirmationValidated'))"
                                type="shield"
                                class="feather-green" />
                        </button>
                        <button
                            v-else-if="data.client !== null"
                            type="button"
                            v-tooltip="getTooltip($tl('labels.booking.reservations.confirmManually'))"
                            class="btn btn-sm btn-outline-secondary btn-square"
                            :disabled="!has_right_to_update_reservation || isLoading"
                            @click.prevent.stop="confirmResaForClient(data)">
                            <feather type="shield-off" class="feather-orange" />
                        </button>
                    </template>
                    <button
                        v-if="canAskConfirmation(data)"
                        type="button"
                        class="btn btn-sm btn-outline-secondary btn-square"
                        :disabled="!has_right_to_update_reservation || isLoading"
                        @click.prevent.stop="askConfirmation(data)">
                        <feather type="shield" class="feather-black" />
                    </button>
                </td>
            </template>
        </ns-table>
    </div>
</template>

<script>
import NsTable from "../Datatable/NsTable.vue";
import NsHeadings from "../Datatable/NsHeadings.vue";
import ColumnClient from "./GridColumns/ColumnClient.vue";
import ReservationStatusEnum from "../../mixins/enums/booking/ReservationStatusEnum";
import ReservationTag from "./ReservationTagComponent.vue";
import OptionBankStatusIcon from "../stripe/OptionBankStatusIcon.vue";
import HotelClientIcon from "./hotelClientIcon.vue";
import ColumnReservationEnum from "../../mixins/enums/booking/ColumnReservationEnum.js";
import reservationIssuerIcon from "./reservationIssuerIcon.vue";

export default {
    data() {
        return {
            isLoading: false,
            columns: [],
        };
    },
    computed: {
        excludedColumns() {
            return this.ALL_COLUMN_RESERVATIONS.filter((field) => !this.columns.includes(field.value)).map((column) => column.value);
        },
        labelHeadings() {
            let headings = [
                this.$tl("labels.booking.reservations.grid.columns.creationDate"),
                this.$tl("labels.booking.reservations.grid.columns.client"),
                this.$tl("labels.booking.reservations.grid.columns.company"),
                this.$tl("labels.booking.reservations.grid.columns.pax"),
                this.$tl("labels.booking.reservations.grid.columns.hour"),
                this.$tl("labels.booking.reservations.grid.columns.table", this.restaurant_id),
                this.$tl("labels.booking.reservations.grid.columns.room_number"),
                this.$tl("labels.booking.reservations.grid.columns.status"),
                this.$tl("labels.booking.reservations.grid.columns.optionBankStatus"),
                this.$tl("labels.booking.reservations.grid.columns.confirmation"),
                this.$tl("labels.booking.reservations.grid.columns.commentPublic"),
                this.$tl("labels.booking.reservations.grid.columns.commentPrivate"),
            ];
            if (!this.isFeatRoomNumbersEnable && !this.isHotelModeEnabled) {
                headings.splice(5, 1);
            }
            return headings;
        },
        allowExport() {
            return this.has_right_to_update_reservation && this.reservations_.length > 0;
        },
        toolbarSettings() {
            let toolbarSettings = {
                items: [],
            };
            toolbarSettings.items.push({
                type: "ColumnPicker",
                settings: {
                    props: {
                        serviceId: this.service.id,
                    },
                },
            });
            if (this.allowExport) {
                toolbarSettings.items.push({
                    type: "ExcelExport",
                    settings: {
                        headings: this.labelHeadings,
                        autoWidth: true,
                        autoFormat: true,
                        formatRow: (reservation) => {
                            let reservationFormatted = {
                                created_at: this.displayDate(reservation.created_at, this.DATETIME_SHORT),
                                client: this.getClientFullName(reservation),
                                company: reservation.client && reservation.client.company ? reservation.client.company : "",
                                total_nb_pers: reservation.total_nb_pers,
                                hour_start: this.displayHour(reservation.slot.hour_start),
                                tables: "",
                                room_number: reservation.room_number,
                                status: this.getReservationStatusLabel(reservation.status),
                                option_bank_score: this.getLabelOptionBank(reservation),
                                confirmation_score: this.getLabelConfirmation(reservation),
                                comment: reservation.comment,
                                restaurant_comment: reservation.restaurant_comment,
                            };
                            if (reservation.tables.data.length > 0) {
                                reservationFormatted.tables = reservation.tables.data.map((t) => t.name).join("-");
                            } else if (reservation.num_table && reservation.num_table !== "") {
                                reservationFormatted.tables = reservation.num_table;
                            }
                            if (!this.isFeatRoomNumbersEnable && !this.isHotelModeEnabled) {
                                delete reservationFormatted.room_number;
                            }

                            return Object.values(reservationFormatted);
                        },
                        anchorClasses: "btn btn-sm btn-outline-secondary export-hover d-flex align-items-center",
                        anchorStyle: "border-radius: 0px 20px 20px 0;",
                        iconClasses: "feather mr-1",
                        iconStyle: "height:auto",
                        fileName: `${this.$tl("labels.reservationPlural").toLowerCase()}-${
                            this.currentServiceName
                        }-${this.currentDate.toISODate()}.xlsx`,
                    },
                });
            }
            return toolbarSettings;
        },
        avoidStatusForDetails() {
            return [
                this.RESERVATION_STATUS_PENDING.value,
                this.RESERVATION_STATUS_CREATED.value,
                this.RESERVATION_STATUS_NOSHOW.value,
                this.RESERVATION_STATUS_REFUSED.value,
            ];
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        restaurantFromGetters() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurant_id);
        },
        isFeatRoomNumbersEnable() {
            return this.restaurantFromGetters ? this.restaurantFromGetters.feat_room_numbers : false;
        },
        isHotelModeEnabled() {
            return this.$store.getters["widgets/getWidget"].hotel_mode;
        },
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_reservation() {
            return this.rights.includes("booking.booking.update");
        },
        statusForFiltering() {
            const reservationStatus = this.reservations_
                .map((reservation) => reservation.status)
                .filter((value, index, self) => self.indexOf(value) === index);
            const status = this.ALL_RESERVATION_STATUS.filter((s) => reservationStatus.includes(s.value));

            return status.map((status) => {
                return { label: this.getReservationStatusLabel(status.value, undefined, this.restaurant_id), value: status.value };
            });
        },
        optionBanksForFiltering() {
            const optionBanksForFiltering = [
                {
                    label: this.$tl("labels.booking.reservations.grid.optionBanksForFiltering.confirmed"),
                    value: "5",
                },
                {
                    label: this.$tl("labels.booking.reservations.grid.optionBanksForFiltering.pending"),
                    value: "4",
                },
                {
                    label: this.$tl("labels.booking.reservations.grid.optionBanksForFiltering.expired"),
                    value: "3",
                },
                {
                    label: this.$tl("labels.booking.reservations.grid.optionBanksForFiltering.canceledRefunded"),
                    value: "2",
                },
                {
                    label: this.$tl("labels.booking.reservations.grid.optionBanksForFiltering.noOne"),
                    value: "1",
                },
            ];

            const optionBanks = this.reservations_.map((r) => r.option_bank_score).filter((value, index, self) => self.indexOf(value) === index);
            return optionBanksForFiltering.filter((s) => optionBanks.some((o) => o == s.value));
        },
        confirmationForFiltering() {
            const confirmationsForFiltering = [
                {
                    label: this.$tl("labels.booking.reservations.grid.confirmationForFiltering.validated"),
                    value: "4",
                },
                {
                    label: this.$tl("labels.booking.reservations.grid.confirmationForFiltering.pending"),
                    value: "3",
                },
                {
                    label: this.$tl("labels.booking.reservations.grid.confirmationForFiltering.notSent"),
                    value: "2",
                },
            ];

            const confirmations = this.reservations_.map((r) => r.confirmation_score).filter((value, index, self) => self.indexOf(value) === index);
            return confirmationsForFiltering.filter((s) => confirmations.some((c) => c == s.value || (c == 1 && s.value == "2")));
        },
        reservations_() {
            return this.reservations.map((r) => {
                return {
                    ...r,
                    restaurant_id: this.$route && this.$route.params ? this.$route.params.restaurant_id : undefined,
                };
            });
        },
        currentDate() {
            const reservation = this.reservations_.length > 0 ? this.reservations_[0] : null;
            if (!reservation) {
                return this.getDateTime();
            }
            return this.getDateTime(reservation.reservation_datetime);
        },
        currentServiceName() {
            return this.service.name.replace(/ +/g, "_").toLowerCase();
        },
        headings() {
            return [
                /**
                 * @prop {string} [columnName] - The name of the column for the datatable
                 * @prop {string} [field] - The name of the property, nested notation with dot is accepted
                 * @prop {string|number} width - Width of the column, the unit is in em
                 * @prop {boolean} [allowSorting] - Allow to sort the column
                 * @prop {boolean} [allowFiltering] - Allow to filter the column
                 * @prop {object} [icon] - Object option for the icon
                 * @prop {string} [icon.name] - The name of the icon
                 * @prop {boolean} [icon.hideOnMobile] - Hide the icon for mobile
                 * @prop {boolean} [icon.hideOnDesktop] - Hide the icon for desktop
                 * @prop {object}  [title] - Object option for the title
                 * @prop {string} [title.content] - The content title
                 * @prop {boolean} [title.hideOnMobile] - Hide the title for mobile
                 * @prop {boolean} [title.hideOnDesktop] - Hide the title for desktop
                 * @prop {callback} [sortableFunction] - Override the default sort function used by the table component
                 * @prop {object} [filterOptions] - Object option of the filter
                 * @prop {string} [filterOptions.type] - Define if the filter will be checkbox or another tag element for now [choices] is only supported
                 * @prop {array} [filterOptions.choices] - An array of all different choices for the filter
                 * @prop {callback} [filterOptions.filterFunction] - Override the default filter function for the type choices, first param is the data and the second param is the selected choices
                 * @prop {boolean} [disabled] - Disable the column
                 */
                {
                    columnName: "created_at",
                    field: "created_at",
                    width: "3.1",
                    allowSorting: true,
                    icon: {
                        name: "clock",
                    },
                    hideOnMobile: true,
                },
                {
                    field: "client",
                    width: "17.8",
                    allowSorting: true,
                    sortableFunction: this.sortClient,
                    icon: {
                        name: "user",
                        hideOnDesktop: true,
                    },
                    title: {
                        content: this.$tl("labels.booking.reservations.grid.columns.client"),
                        hideOnMobile: true,
                    },
                },
                {
                    field: "company",
                    columnName: "company",
                    width: "4",
                    title: {
                        content: this.$tl("labels.booking.reservations.grid.columns.company", this.restaurant_id),
                    },
                    hideOnMobile: true,
                },
                {
                    field: "total_nb_pers",
                    width: "4.75",
                    allowSorting: true,
                    allowFiltering: true,
                    icon: {
                        name: "users",
                        hideOnDesktop: true,
                    },
                    title: {
                        content: this.$tl("labels.booking.reservations.grid.columns.pax"),
                        hideOnMobile: true,
                    },
                    filterOptions: {
                        type: "choices",
                        choices: this.attributeForFiltering("total_nb_pers"),
                    },
                },
                {
                    field: "slot.hour_start",
                    width: "5.3",
                    allowSorting: true,
                    allowFiltering: true,
                    icon: {
                        name: "clock",
                        hideOnDesktop: true,
                    },
                    title: {
                        content: this.$tl("labels.booking.reservations.grid.columns.hour"),
                        hideOnMobile: true,
                    },
                    filterOptions: {
                        type: "choices",
                        choices: this.attributeForFiltering("slot.hour_start"),
                    },
                },
                {
                    columnName: "table",
                    width: "4",
                    title: {
                        content: this.$tl("labels.booking.reservations.grid.columns.table", this.restaurant_id),
                    },
                    hideOnMobile: true,
                },
                {
                    columnName: "room_number",
                    field: "room_number",
                    disabled: !this.isFeatRoomNumbersEnable && !this.isHotelModeEnabled,
                    width: "5.9",
                    allowSorting: true,
                    title: {
                        content: this.$tl("labels.booking.reservations.grid.columns.room_number"),
                    },
                    hideOnMobile: true,
                },
                {
                    field: "status",
                    width: "7.5",
                    allowFiltering: true,
                    allowSorting: true,
                    sortableFunction: this.sortStatus,
                    icon: {
                        name: "check-circle",
                        hideOnDesktop: true,
                    },
                    title: {
                        content: this.$tl("labels.booking.reservations.grid.columns.status"),
                        hideOnMobile: true,
                    },
                    filterOptions: {
                        type: "choices",
                        choices: this.statusForFiltering,
                    },
                },
                {
                    columnName: "payment",
                    field: "option_bank_score",
                    width: "4.3",
                    allowSorting: true,
                    allowFiltering: true,
                    icon: {
                        name: "credit-card",
                        hideOnDesktop: true,
                    },
                    title: {
                        content: this.$tl("labels.booking.reservations.grid.columns.optionBankStatus"),
                        hideOnMobile: true,
                    },
                    filterOptions: {
                        type: "choices",
                        choices: this.optionBanksForFiltering,
                    },
                },
                {
                    width: "5",
                    title: {
                        content: this.$tl("labels.booking.reservations.grid.columns.actions"),
                    },
                    hideOnMobile: true,
                },
                {
                    columnName: "confirmation",
                    field: "confirmation_score",
                    width: "9.3",
                    allowSorting: true,
                    allowFiltering: true,
                    icon: {
                        name: "check",
                    },
                    hideOnMobile: true,
                    filterOptions: {
                        type: "choices",
                        choices: this.confirmationForFiltering,
                        filterFunction: this.filterConfirmation,
                    },
                },
            ];
        },
    },
    props: {
        restaurant: {
            required: true,
            type: Object,
        },
        service: {
            type: Object,
            required: true,
        },
        reservations: {
            type: Array,
            required: true,
        },
    },
    components: {
        NsTable,
        NsHeadings,
        ColumnClient,
        ReservationTag,
        OptionBankStatusIcon,
        HotelClientIcon,
        reservationIssuerIcon,
    },
    methods: {
        getClientFullName(reservation) {
            if (reservation.client !== null) {
                return `${reservation.client.firstname || ""} ${reservation.client.lastname || ""}`;
            }
            return "";
        },
        filterConfirmation(data, selectedChoices) {
            return data.filter((datum) => {
                const score = datum.confirmation_score !== "1" ? datum.confirmation_score : "2";
                return selectedChoices.includes(score);
            });
        },
        attributeForFiltering(field) {
            const attributes = this.reservations_.map((reservation) => {
                return {
                    label: field === "slot.hour_start" ? this.displayHour(reservation.slot.hour_start) : this.$_.get(reservation, field),
                    value: this.$_.get(reservation, field),
                };
            });
            // Return an array with unique values
            return attributes.filter((attribute, index, self) => index === self.findIndex((value) => value.value === attribute.value));
        },
        displayPax(nbPers, nbChild) {
            return `${this.$tc("labels.person", nbPers)} (${this.$tc("labels.booking.reservations.includingXChildren", nbChild)})`;
        },
        displayHour(hour) {
            return this.displayDate(this.setHourOnDateTime(hour), this.TIME_SIMPLE);
        },
        getClientName(item, clientKey) {
            return item.client !== null ? item.client[clientKey] || "" : "";
        },
        sortClient(a, b) {
            const lastnameResult = this.getClientName(a, "lastname").localeCompare(this.getClientName(b, "lastname"));
            if (lastnameResult !== 0) {
                return lastnameResult;
            }

            return this.getClientName(a, "firstname").localeCompare(this.getClientName(b, "firstname"));
        },
        sortStatus(a, b) {
            const ranking = {
                confirmed: 6,
                over: 5,
                created: 4,
                noshow: 3,
                canceled: 2,
                refused: 1,
            };

            return (ranking[a.status] || 0) - (ranking[b.status] || 0);
        },
        canAskConfirmation(data) {
            const avoidStatus = [
                this.RESERVATION_STATUS_CANCELED.value,
                this.RESERVATION_STATUS_REFUSED.value,
                this.RESERVATION_STATUS_CREATED.value,
            ];
            if (data.client === null || avoidStatus.includes(data.status)) {
                return false;
            }

            const resaDateTime = this.getDateTime(data.reservation_datetime);
            const now = this.getDateTime();

            if (resaDateTime.diff(now, "hours").hours < 24) {
                return false;
            }

            if (data.confirmed_by_client !== null) {
                return false;
            }

            return true;
        },
        confirmResaForClient(data) {
            this.isLoading = true;
            this.httpPut(
                `/api/restaurants/${this.restaurant_id}/reservations/${data.id}/confirmation`,
                {},
                { handleResolve: false, handleReject: false }
            )
                .then((response) => this.notifySuccess(null, this.$tl("success.booking.reservations.confirmed")))
                .catch((error) => this.notifyError(error))
                .finally(() => (this.isLoading = false));
        },
        askConfirmation(data) {
            this.isLoading = true;
            this.httpPost(
                `/api/restaurants/${this.restaurant_id}/reservations/${data.id}/confirmation`,
                {},
                { handleResolve: false, handleReject: false }
            )
                .then((response) => this.notifySuccess(null, this.$tl("success.booking.reservations.askConfirmationSent")))
                .catch((error) => this.notifyError(error))
                .finally(() => (this.isLoading = false));
        },
        getLabelOptionBank(reservation) {
            const score = reservation.option_bank_score;

            if (score === "1") {
                return "";
            }

            const labels = {
                2: reservation.refund_at ? "refunded" : "released",
                3: "expired",
                4: "pending",
                5: "validated",
            };

            return this.$tl(`labels.booking.${reservation.option_bank}.${labels[score]}`);
        },
        getLabelConfirmation(reservation) {
            const score = reservation.confirmation_score;

            if (score === "1") {
                return "";
            }

            const labels = {
                2: "notSent",
                3: "pending",
                4: "validated",
            };

            return this.$tl(`labels.booking.reservations.grid.confirmationForFiltering.${labels[score]}`);
        },
        rowSelected({ event, data }) {
            const tagsToExclude = ["button", "a", "svg", "rect", "path", "line"];

            const targetTagName = event && event.target && event.target.tagName ? event.target.tagName.toLowerCase() : "";

            if (!tagsToExclude.includes(targetTagName)) {
                this.emitEvent("showResa", data.id);
            }
        },
        emitEvent(nameEvent, value, event = null) {
            if (event === null) {
                this.$emit(nameEvent, value);
            } else {
                this.$emit(nameEvent, event, value);
            }
        },
    },
    mixins: [ReservationStatusEnum, ColumnReservationEnum],
};
</script>
<style>
/** Style necessary for some ejs-components */
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
</style>
