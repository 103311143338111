var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pb-5" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row m-0 pb-5" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.loading
            ? _c("LoaderComponent")
            : _c("div", [
                _vm.error
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _vm._v(_vm._s(_vm.error)),
                    ])
                  : _c(
                      "div",
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-3",
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "d-inline-block",
                                  staticStyle: { "margin-top": "4px" },
                                },
                                [
                                  _c(
                                    "form",
                                    { on: { submit: _vm.fetchData } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-control search-client d-inline pr-1",
                                          staticStyle: {
                                            "border-radius":
                                              "20px 0 0 20px !important",
                                          },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.filter,
                                                expression: "filter",
                                              },
                                            ],
                                            staticClass: "search-input",
                                            attrs: {
                                              placeholder: "Nom, Numéro...",
                                              type: "text",
                                            },
                                            domProps: { value: _vm.filter },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.filter = $event.target.value
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "p-0 m-0 clear-search-button",
                                              staticStyle: {
                                                background: "none",
                                                border: "none",
                                              },
                                              attrs: { type: "button" },
                                              on: { click: _vm.resetFilter },
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "feather feather-x",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "24",
                                                    height: "24",
                                                    viewBox: "0 0 24 24",
                                                    fill: "none",
                                                    stroke: "currentColor",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                  },
                                                },
                                                [
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "18",
                                                      y1: "6",
                                                      x2: "6",
                                                      y2: "18",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "6",
                                                      y1: "6",
                                                      x2: "18",
                                                      y2: "18",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-outline-secondary search-client-btn",
                                          staticStyle: {
                                            "border-radius": "0 20px 20px 0",
                                          },
                                          attrs: { type: "submit" },
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass:
                                                "feather feather-search",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "24",
                                                height: "20",
                                                viewBox: "0 0 24 24",
                                                fill: "none",
                                                stroke: "currentColor",
                                                "stroke-width": "2",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                              },
                                            },
                                            [
                                              _c("circle", {
                                                attrs: {
                                                  cx: "11",
                                                  cy: "11",
                                                  r: "8",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "21",
                                                  y1: "21",
                                                  x2: "16.65",
                                                  y2: "16.65",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-sm btn-outline-secondary ml-1 btn-circle",
                                  on: {
                                    click: function ($event) {
                                      _vm.showAdvanceSearch =
                                        !_vm.showAdvanceSearch
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "css-i6dzq1 feather",
                                      attrs: {
                                        viewBox: "0 0 24 24",
                                        width: "24",
                                        height: "24",
                                        stroke: "currentColor",
                                        "stroke-width": "2",
                                        fill: "none",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                      },
                                    },
                                    [
                                      _c("polygon", {
                                        attrs: {
                                          points:
                                            "22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                                Filtres\n                            "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("div", { staticClass: "btn-group" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-outline-secondary btn-circle none-mobile",
                                    staticStyle: {
                                      "border-radius":
                                        "50px 0 0 50px !important",
                                    },
                                    attrs: {
                                      disabled:
                                        !_vm.has_right_to_export_payments,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.exportPayments()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "feather feather-upload",
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          width: "24",
                                          height: "24",
                                          viewBox: "0 0 24 24",
                                          fill: "none",
                                          stroke: "currentColor",
                                          "stroke-width": "2",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("polyline", {
                                          attrs: { points: "17 8 12 3 7 8" },
                                        }),
                                        _vm._v(" "),
                                        _c("line", {
                                          attrs: {
                                            x1: "12",
                                            y1: "3",
                                            x2: "12",
                                            y2: "15",
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                                    Export Excel\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-outline-secondary btn-circle none-mobile",
                                    staticStyle: {
                                      "border-radius":
                                        "0 50px 50px 0 !important",
                                    },
                                    attrs: {
                                      disabled:
                                        !_vm.has_right_to_export_payments,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadPayments()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "feather feather-upload",
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          width: "24",
                                          height: "24",
                                          viewBox: "0 0 24 24",
                                          fill: "none",
                                          stroke: "currentColor",
                                          "stroke-width": "2",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("polyline", {
                                          attrs: { points: "17 8 12 3 7 8" },
                                        }),
                                        _vm._v(" "),
                                        _c("line", {
                                          attrs: {
                                            x1: "12",
                                            y1: "3",
                                            x2: "12",
                                            y2: "15",
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                                    Télécharger\n                                "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.showAdvanceSearch
                          ? _c("div", { staticClass: "advance-filter" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "m-0",
                                  staticStyle: { color: "#666666" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "border-light b-radius-20 p-4 mb-3",
                                    },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 d-inline-block date-resa-cal",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Statut\n                                        "
                                            ),
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.filter_status,
                                                    expression: "filter_status",
                                                  },
                                                ],
                                                staticClass:
                                                  "custom-select d-inline-block ml-2 mr-2 mb-0",
                                                staticStyle: {
                                                  width: "initial",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.filter_status = $event
                                                      .target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  { domProps: { value: null } },
                                                  [_vm._v("--")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "paid" } },
                                                  [_vm._v("Payé")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      value: "canceled",
                                                    },
                                                  },
                                                  [_vm._v("Annulé")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      value: "refunded",
                                                    },
                                                  },
                                                  [_vm._v("Remboursé")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { value: "pending" },
                                                  },
                                                  [_vm._v("En attente")]
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                                        Moyen de paiement\n                                        "
                                            ),
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.filter_payment_type,
                                                    expression:
                                                      "filter_payment_type",
                                                  },
                                                ],
                                                staticClass:
                                                  "custom-select d-inline-block ml-2 mr-2 mb-0",
                                                staticStyle: {
                                                  width: "initial",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.filter_payment_type =
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  { domProps: { value: null } },
                                                  [_vm._v("--")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { value: "stripe" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Carte bancaire (en ligne)"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "card" } },
                                                  [
                                                    _vm._v(
                                                      "Carte bancaire (sur place)"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "check" } },
                                                  [_vm._v("Chèque")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "cash" } },
                                                  [_vm._v("Espèces")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      value: "meal_voucher",
                                                    },
                                                  },
                                                  [_vm._v("Titre restaurant")]
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                                        Du \n                                        "
                                            ),
                                            _c("datepicker", {
                                              staticClass: "d-inline-block",
                                              attrs: {
                                                format: "dd/MM/yyyy",
                                                "input-class": "form-control",
                                                "monday-first": true,
                                                language: _vm.fr,
                                              },
                                              model: {
                                                value:
                                                  _vm.filter_from_date_payment,
                                                callback: function ($$v) {
                                                  _vm.filter_from_date_payment =
                                                    $$v
                                                },
                                                expression:
                                                  "filter_from_date_payment",
                                              },
                                            }),
                                            _vm._v(
                                              "\n                                         au \n                                        "
                                            ),
                                            _c("datepicker", {
                                              staticClass: "d-inline-block",
                                              attrs: {
                                                format: "dd/MM/yyyy",
                                                "input-class": "form-control",
                                                "monday-first": true,
                                                language: _vm.fr,
                                              },
                                              model: {
                                                value:
                                                  _vm.filter_to_date_payment,
                                                callback: function ($$v) {
                                                  _vm.filter_to_date_payment =
                                                    $$v
                                                },
                                                expression:
                                                  "filter_to_date_payment",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-success btn-circle ml-2",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.fetchData()
                                                  },
                                                },
                                              },
                                              [_vm._v("Rechercher")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "table",
                          {
                            staticClass:
                              "capitalize table table-sm table-striped border-bottom",
                          },
                          [
                            _c("thead", { staticClass: "border-bottom" }, [
                              _c("tr", [
                                _c("th", [_vm._v("N°")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Montant")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Statut")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Client")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Date")]),
                                _vm._v(" "),
                                _c("th", [
                                  _c("small", [_vm._v("Moyen de")]),
                                  _vm._v(" paiement"),
                                ]),
                                _vm._v(" "),
                                !_vm.isTvaDisabled
                                  ? _c("th", [
                                      _c("small", [_vm._v("TVA")]),
                                      _vm._v(" 5.5%"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.isTvaDisabled
                                  ? _c("th", [
                                      _c("small", [_vm._v("TVA")]),
                                      _vm._v(" 10%"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.isTvaDisabled
                                  ? _c("th", [
                                      _c("small", [_vm._v("TVA")]),
                                      _vm._v(" 20%"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("th", [_vm._v("Frais")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Actions")]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.formattedPayments,
                                function (payment, i) {
                                  return _c(
                                    "tr",
                                    {
                                      key: i,
                                      staticClass: "clickable",
                                      on: {
                                        click: function ($event) {
                                          return _vm.showCommand(
                                            $event,
                                            payment[0].command_id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("td", [
                                        _vm._v(_vm._s(payment[0].numero)),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.formatCurrency(
                                                  _vm.getAmountSum(payment) /
                                                    100
                                                )
                                              )
                                          ),
                                          payment[0].amount_refunded
                                            ? _c(
                                                "span",
                                                { staticClass: "text-warning" },
                                                [
                                                  _vm._v(
                                                    "\n                                        (-" +
                                                      _vm._s(
                                                        _vm.formatCurrency(
                                                          payment[0]
                                                            .amount_refunded /
                                                            100
                                                        )
                                                      ) +
                                                      ")"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          payment[0].is_group_payment
                                            ? _c("feather", {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value:
                                                      _vm.getTooltip(
                                                        "Paiement de groupe"
                                                      ),
                                                    expression:
                                                      "getTooltip('Paiement de groupe')",
                                                  },
                                                ],
                                                staticClass: "ml-2",
                                                attrs: { type: "users" },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          class: _vm.getStatusColor(
                                            payment[0].status
                                          ),
                                        },
                                        [_vm._v(_vm._s(payment[0].status))]
                                      ),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(payment[0].firstname) +
                                            "\n                                    "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "text-uppercase" },
                                          [_vm._v(_vm._s(payment[0].lastname))]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            payment[0].paid_at
                                              ? _vm.formatDate(
                                                  payment[0].paid_at
                                                )
                                              : "--"
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          payment[0].is_online
                                            ? _vm._l(
                                                payment,
                                                function (cpayment, index) {
                                                  return _c(
                                                    "span",
                                                    {
                                                      key: index,
                                                      staticClass: "d-block",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.getOnlinePaymentMethodLabel(
                                                              cpayment.online_payment_method
                                                            )
                                                          ) +
                                                          _vm._s(
                                                            payment.length > 1
                                                              ? " (" +
                                                                  _vm.formatCurrency(
                                                                    (cpayment.amount_paid ||
                                                                      cpayment.amount_to_pay) /
                                                                      100
                                                                  ) +
                                                                  ")"
                                                              : ""
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                      _vm.getOnlinePaymentMethodImg(
                                                        cpayment.online_payment_method
                                                      )
                                                        ? _c("img", {
                                                            directives: [
                                                              {
                                                                name: "tooltip",
                                                                rawName:
                                                                  "v-tooltip",
                                                                value:
                                                                  _vm.getTooltip(
                                                                    "Paiement en ligne"
                                                                  ),
                                                                expression:
                                                                  "getTooltip('Paiement en ligne')",
                                                              },
                                                            ],
                                                            staticStyle: {
                                                              width: "14px",
                                                              "margin-left":
                                                                "2px",
                                                              "margin-bottom":
                                                                "2px",
                                                            },
                                                            attrs: {
                                                              src: `/images/${_vm.getOnlinePaymentMethodImg(
                                                                cpayment.online_payment_method
                                                              )}`,
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              )
                                            : _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    payment[0].method.replace(
                                                      " - ",
                                                      "<br/>"
                                                    )
                                                  ),
                                                },
                                              }),
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      !_vm.isTvaDisabled
                                        ? _c("td", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  payment[0].tva.five_dot_five
                                                    ? _vm.formatCurrency(
                                                        payment[0].tva
                                                          .five_dot_five
                                                      )
                                                    : "--"
                                                ) +
                                                "\n                                "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.isTvaDisabled
                                        ? _c("td", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  payment[0].tva.ten
                                                    ? _vm.formatCurrency(
                                                        payment[0].tva.ten
                                                      )
                                                    : "--"
                                                ) +
                                                "\n                                "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.isTvaDisabled
                                        ? _c("td", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  payment[0].tva.twenty
                                                    ? _vm.formatCurrency(
                                                        payment[0].tva.twenty
                                                      )
                                                    : "--"
                                                ) +
                                                "\n                                "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("td", [
                                        payment[0].online_payment_method ===
                                        _vm.ONLINE_PAYMENT_METHOD_PAYPLUG.value
                                          ? _c("span", [_vm._v("NC")])
                                          : _c("span", [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.formatCurrency(
                                                      _vm.getStripeFees(payment)
                                                    )
                                                  )
                                              ),
                                              _vm.areStripeFeesRefunded(
                                                payment
                                              ) === false
                                                ? _c("span", [
                                                    _vm._v(" (Non remboursés)"),
                                                  ])
                                                : _vm._e(),
                                            ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value:
                                                  _vm.getTooltip(
                                                    "Voir les détails"
                                                  ),
                                                expression:
                                                  "getTooltip('Voir les détails')",
                                              },
                                            ],
                                            staticClass:
                                              "btn btn-sm btn-outline-secondary btn-square none-tablet",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                _vm.showClickAndCollectCommandModal =
                                                  payment[0].command_id
                                              },
                                            },
                                          },
                                          [
                                            _c("feather", {
                                              attrs: { type: "eye" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.getTooltip(
                                                  "Télécharger la facture"
                                                ),
                                                expression:
                                                  "getTooltip('Télécharger la facture')",
                                              },
                                            ],
                                            staticClass:
                                              "btn btn-sm btn-outline-secondary btn-square",
                                            attrs: {
                                              disabled:
                                                !_vm.has_right_to_export_payments ||
                                                !payment[0].is_online ||
                                                !payment[0].cc_command ||
                                                !payment[0].cc_command.invoice,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.downloadInvoice(
                                                  payment[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("feather", {
                                              attrs: { type: "download-cloud" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.payments && _vm.paymentsCountTotal > 0
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "badge badge-secondary ml-1 mr-2 float-left",
                                staticStyle: { "margin-top": "21px" },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.paymentsCountTotal) +
                                    " paiement" +
                                    _vm._s(
                                      _vm.paymentsCountTotal > 1 ? "s" : ""
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isSearch
                          ? _c("div", { staticClass: "mt-3" }, [
                              _vm.links.prev
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-outline-secondary btn-circle",
                                      attrs: { href: "javascript:" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.fetchData(
                                            $event,
                                            _vm.links.prev
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Précédent\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.links.next
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-outline-secondary btn-circle",
                                      attrs: { href: "javascript:" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.fetchData(
                                            $event,
                                            _vm.links.next
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Suivant\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showShowClickAndCollectPaymentModal
                          ? _c("show-click-and-collect-payment-modal", {
                              on: {
                                close: function ($event) {
                                  _vm.showShowClickAndCollectPaymentModal = false
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showClickAndCollectCommandModal
                          ? _c("show-command-modal", {
                              attrs: {
                                command_id: _vm.showClickAndCollectCommandModal,
                                restaurant_id: _vm.$route.params.restaurant_id,
                              },
                              on: {
                                close: function ($event) {
                                  _vm.showClickAndCollectCommandModal = false
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
              ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [_c("h5", { staticClass: "title mt-2" }, [_vm._v("Paiements")])]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }