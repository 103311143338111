<template>
    <modal @close="closeFromModal" class="modal-add-service">
        <h5 slot="header">
            {{ isSpecial ? $tl("labels.booking.services.special.add") : $tl("labels.booking.services.add", restaurant_id) }}
        </h5>
        <div slot="body">
            <add-service-form :isSpecial="isSpecial" @service-added="serviceAdded" @close="close" />
        </div>
        <div slot="footer"></div>
    </modal>
</template>

<script>
import AddServiceForm from "../../services/addServiceForm.vue";

export default {
    name: "addServiceModal",
    props: {
        isSpecial: {
            default: false,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        serviceAdded(event) {
            this.$emit("service-added", event);
            this.close();
        },
        close() {
            this.$emit("close");
        },
        closeFromModal(event) {
            if (event.target && event.target.classList.contains("modal-wrapper")) {
                return this.close();
            }
        },
    },
    components: {
        AddServiceForm,
    },
};
</script>
