var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CaPerEntityComponent",
    _vm._b(
      { attrs: { entity: _vm.MENU_OPTION } },
      "CaPerEntityComponent",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }