<template>
    <!-- WARNING : This component is used in the modal -->
    <div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else>
                <div class="row m-0" v-if="showHeadTitle">
                    <div class="col-12">
                        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                            <div v-if="general_option_id">
                                <h5 class="title mt-2">
                                    {{ $tl("labels.booking.generalOptions.edit") }} <span class="value">{{ name }}</span>
                                </h5>
                            </div>
                            <div v-else>
                                <h5 class="title mt-2">{{ $tl("labels.booking.generalOptions.add") }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <ShowErrors errClass="alert alert-danger" :errors="formErrors" errorKey="general_option_id" />
                <div v-if="formSuccess" class="alert alert-success">
                    {{ formSuccess }}
                </div>
                <LoaderComponent v-if="formLoading" />
                <form v-else id="addOption" @submit="postForm" method="post">
                    <div class="row m-0">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4 mb-3">
                                <div class="row mb-2">
                                    <div class="col-5 pt-2">
                                        <label for="name">{{ $tl("labels.form.name") }} <small>*</small></label>
                                    </div>
                                    <div class="col-7">
                                        <input
                                            :disabled="!has_right_to_update_menu"
                                            type="text"
                                            name="name"
                                            v-model="name"
                                            id="name"
                                            required
                                            class="form-control" />
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="name" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-5 pt-2">
                                        <label for="description">{{ $tl("labels.form.description") }}</label>
                                    </div>
                                    <div class="col-7">
                                        <textarea
                                            :disabled="!has_right_to_update_menu"
                                            name="description"
                                            id="description"
                                            v-model="description"
                                            class="form-control"></textarea>
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="description" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-5 pt-2">
                                        <label for="price">{{ $tl("labels.price") }} <small>*</small></label>
                                    </div>
                                    <div class="col-7">
                                        <div class="row">
                                            <div class="col-4">
                                                <input
                                                    :disabled="!has_right_to_update_menu"
                                                    type="number"
                                                    step="0.01"
                                                    min="0"
                                                    name="price"
                                                    v-model="price"
                                                    id="price"
                                                    class="form-control"
                                                    required />
                                            </div>
                                            <div class="col-8 mt-2">
                                                {{ getRestaurantCurrency(restaurantId) }}
                                            </div>
                                            <ShowErrors class="col-12" :errors="formErrors" errorKey="price" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2" v-if="!isTvaDisabled">
                                    <div class="col-5 pt-2">
                                        <label for="tax_rate"> {{ $tl("labels.taxRate") }} <small>*</small></label>
                                    </div>
                                    <div class="col-7">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <select class="custom-select mb-0" :disabled="!has_right_to_update_menu" v-model="tax_rate" required>
                                                    <option :value="null">--</option>
                                                    <option :value="5.5">5,5</option>
                                                    <option :value="10">10</option>
                                                    <option :value="20">20</option>
                                                </select>
                                            </div>
                                            <div class="col-md-8 mt-1">%</div>
                                            <ShowErrors class="col-12" :errors="formErrors" errorKey="tax_rate" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-5 pt-2">
                                        <label for="quantity_type">{{ $tl("labels.booking.menus.options.quantity") }}</label>
                                    </div>
                                    <div class="col-7">
                                        <select
                                            :disabled="!has_right_to_update_menu"
                                            id="quantity_type"
                                            name="quantity_type"
                                            v-model="quantity_type"
                                            required
                                            class="custom-select mb-0">
                                            <option
                                                v-for="optionQuantityType in ALL_OPTION_QUANTITY_TYPES"
                                                :key="optionQuantityType.value"
                                                :value="optionQuantityType.value">
                                                {{ getOptionQuantityTypeLabel(optionQuantityType.value, restaurantId) }}
                                            </option>
                                        </select>
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="quantity_type" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4">
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <strong>{{ $tl("labels.booking.menus.options.enabledSettings") }}</strong>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-5">
                                        <label class="container-box" style="width: 30px">
                                            <input :disabled="!has_right_to_update_menu" type="checkbox" v-model="enable_min_pers" true-value="1" />
                                            <span style="margin-top: -2px" class="checkmark"></span>
                                        </label>
                                        <label class="pt-2" for="price">{{ $tl("labels.booking.menus.options.enabledFrom") }}</label>
                                    </div>
                                    <div class="col-7">
                                        <div class="d-flex align-items-center">
                                            <input
                                                style="width: 30%"
                                                type="number"
                                                step="1"
                                                min="0"
                                                name="nb_pers_min"
                                                :disabled="!enable_min_pers || !has_right_to_update_menu"
                                                v-model="nb_pers_min"
                                                id="nb_pers_min"
                                                class="form-control" />
                                            <p class="m-0 p-0 ml-2">{{ $tl("labels.persons") }}</p>
                                        </div>
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="enable_min_pers" />
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="nb_pers_min" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-5">
                                        <label class="container-box" style="width: 30px">
                                            <input :disabled="!has_right_to_update_menu" type="checkbox" v-model="enable_max_pers" true-value="1" />
                                            <span style="margin-top: -2px" class="checkmark"></span>
                                        </label>
                                        <label class="pt-2" for="price">{{ $tl("labels.booking.menus.options.disabledFrom") }}</label>
                                    </div>
                                    <div class="col-7">
                                        <div class="d-flex align-items-center">
                                            <input
                                                style="width: 30%"
                                                type="number"
                                                step="1"
                                                min="0"
                                                name="nb_pers_max"
                                                :disabled="!enable_max_pers || !has_right_to_update_menu"
                                                v-model="nb_pers_max"
                                                id="nb_pers_max"
                                                class="form-control" />
                                            <p class="m-0 p-0 ml-2">{{ $tl("labels.persons") }}</p>
                                        </div>
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="enable_max_pers" />
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="nb_pers_max" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3 m-0" v-if="showSaveButton">
                        <div class="col-12">
                            <input
                                :disabled="!has_right_to_update_menu"
                                type="submit"
                                name="submit"
                                :value="$t('labels.form.actions.save')"
                                class="btn btn-success btn-circle" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import ShowErrors from "../../../components/errors/ShowErrors";
import OptionQuantityTypesEnum from "../../../mixins/enums/booking/OptionQuantityTypesEnum";

export default {
    data() {
        return {
            loading: false,
            formLoading: false,
            error: null,
            formErrors: null,
            formSuccess: null,
            name: null,
            price: null,
            quantity_type: "unlimited",
            enable_min_pers: 0,
            nb_pers_min: null,
            enable_max_pers: 0,
            nb_pers_max: null,
            tax_rate: null,
            description: null,
            general_option_id: null,
        };
    },
    mixins: [OptionQuantityTypesEnum],
    props: {
        showSaveButton: {
            default: true,
        },
        showHeadTitle: {
            default: true,
        },
    },
    computed: {
        restaurantId() {
            return this.$route.params.restaurant_id;
        },
        isTvaDisabled() {
            return this.$store.getters["restaurants/isTvaDisabled"](this.restaurantId);
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_menu() {
            return this.rights.includes("booking.menu.create");
        },
        has_right_to_update_menu() {
            if (this.general_option_id) return this.rights.includes("booking.menu.update");
            return this.has_right_to_create_menu;
        },
    },
    methods: {
        initData(changing_general_option_id) {
            this.error = this.restaurant = null;
            this.loading = true;

            this.general_option_id = this.$route.params.general_option_id;

            // if we are editing a menu's option
            if (this.general_option_id) {
                axios
                    .get(`/api/restaurants/${this.restaurantId}/general/options/${this.general_option_id}`)
                    .then((response) => {
                        this.loading = false;
                        this.name = response.data.name;
                        this.description = response.data.description;
                        this.price = Number.parseFloat(response.data.price).toFixed(2);
                        this.nb_pers_min = response.data.nb_pers_min;
                        this.nb_pers_max = response.data.nb_pers_max;
                        this.quantity_type = response.data.quantity_type;
                        this.enable_max_pers = response.data.enable_max_pers;
                        this.enable_min_pers = response.data.enable_min_pers;
                        this.tax_rate = response.data.tax_rate;
                    })
                    .catch((error) => {
                        this.error = this.getErrorMsgFromErrorResponse(error);
                        this.$notify({
                            group: "notification",
                            type: "error",
                            title: this.getErrorMsgFromErrorResponse(error),
                        });
                    });
            } else {
                this.loading = false;

                // if user come from a editing menu view
                if (changing_general_option_id) {
                    // empty form fields
                    this.emptyForm();
                }
            }
        },
        postForm: function (e) {
            if (e) {
                e.preventDefault();
            }
            this.formLoading = true;
            this.formSuccess = null;
            this.formErrors = null;

            // if we are editing a reservation
            if (this.general_option_id) {
                var axiosPromise = axios.put(`/api/restaurants/${this.restaurantId}/general/options/${this.general_option_id}`, {
                    name: this.name,
                    description: this.description,
                    price: this.price,
                    nb_pers_min: this.nb_pers_min === null ? "" : this.nb_pers_min,
                    nb_pers_max: this.nb_pers_max === null ? "" : this.nb_pers_max,
                    quantity_type: this.quantity_type,
                    enable_min_pers: this.enable_min_pers,
                    tax_rate: this.tax_rate,
                    enable_max_pers: this.enable_max_pers,
                });

                if (this.showSaveButton) {
                    axiosPromise
                        .then((response) => {
                            // display success message
                            this.formLoading = false;
                            //this.formSuccess = response.data.message;
                            this.$notify({
                                group: "notification",
                                type: "success",
                                title: this.$tl("success.booking.generalOptions.added"),
                            });
                            this.emptyForm();

                            // redirect to menus list
                            this.$router.push({
                                name: "booking.restaurants.settings.general_options",
                                params: {
                                    restaurant_id: this.restaurantId,
                                },
                            });

                            // reload data
                            this.initData();
                        })
                        .catch((error) => {
                            this.formErrors =
                                error.response && error.response.data ? error.response.data.errors : [this.getErrorMsgFromErrorResponse(error)];
                            this.formLoading = false;
                            this.$notify({
                                group: "notification",
                                type: "error",
                                title: this.getErrorMsgFromErrorResponse(error),
                            });
                        });
                }

                return axiosPromise;
            } else {
                // we are adding a new menu
                const axiosPromise = axios.post(`/api/restaurants/${this.restaurantId}/general/options`, {
                    name: this.name,
                    description: this.description,
                    price: this.price,
                    nb_pers_min: this.nb_pers_min === null ? "" : this.nb_pers_min,
                    nb_pers_max: this.nb_pers_max === null ? "" : this.nb_pers_max,
                    quantity_type: this.quantity_type,
                    tax_rate: this.tax_rate,
                    enable_min_pers: this.enable_min_pers,
                    enable_max_pers: this.enable_max_pers,
                });

                if (this.showSaveButton) {
                    axiosPromise
                        .then((response) => {
                            // display success message
                            this.formLoading = false;
                            //this.formSuccess = response.data.message;
                            this.$notify({
                                group: "notification",
                                type: "success",
                                title: this.$tl("success.booking.generalOptions.added"),
                            });
                            // empty form fields
                            this.emptyForm();

                            this.$router.push({
                                name: "booking.restaurants.settings.general_options",
                                params: {
                                    restaurant_id: this.restaurantId,
                                },
                            });
                        })
                        .catch((error) => {
                            if (error.response && error.response.data && error.reponse.data.errors) {
                                this.formErrors = error.response.data.errors;
                            } else {
                                this.formErrors = this.getErrorMsgFromErrorResponse(error);
                            }
                        });
                }

                return axiosPromise;
            }
        },
        emptyForm() {
            this.name = null;
            this.price = null;
            this.tax_rate = null;
            this.description = null;
        },
        setFormErrors(value) {
            this.formErrors = value;
        },
        setFormLoading(value) {
            this.formLoading = value;
        },
    },
    created() {
        this.initData();
    },
    watch: {
        restaurantId: function (id) {
            this.initData();
        },
        "$route.params.general_option_id": function (id) {
            this.initData(true);
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
    },
};
</script>
