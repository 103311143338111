const StatsType = {
    RESERVATIONS_COUNT: {
        key: "reservations-count-per-origin",
        label: "reservationCountPerOrigin",
    },
    RESERVATIONS_PAX: {
        key: "reservations-pax-per-origin",
        label: "reservationPaxPerOrigin",
    },
};

export { StatsType };
