<template>
    <div>
        <div class="row mb-2">
            <div class="col-12">
                <strong class="pointer" @click="show = !show">Employés</strong>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <div class="row" v-else-if="show">
            <div class="col-12">
                <table v-if="employees && employees.length > 0" class="capitalize table table-sm table-striped border-bottom">
                    <thead>
                        <th>Nom</th>
                        <th>Email</th>
                        <th>Téléphone</th>
                        <th>Rôle</th>
                        <th>Actions</th>
                    </thead>
                    <tbody>
                        <tr v-for="employee in employees" :key="employee.id">
                            <td>{{ employee.firstname }} {{ employee.lastname }}</td>
                            <td>{{ employee.email }}</td>
                            <td>{{ employee.tel }}</td>
                            <td>{{ formatRole(employee.role) }}</td>
                            <td>
                                <button
                                    @click="selectEmployee(employee)"
                                    type="button"
                                    class="btn btn-sm btn-outline-secondary btn-square"
                                    v-tooltip="getTooltip(`Se connecter en tant que '${employee.email}'`)">
                                    <feather type="log-in" />
                                </button>
                                <button class="btn btn-danger btn-sm" @click="deleteEmployee(employee)">Supprimer</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-else>
                    <p class="text-muted">Cet établissement ne possède aucun employé</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent";
import axios from "axios";

export default {
    data() {
        return {
            loading: false,
            show: false,
        };
    },
    props: {
        employees: {
            required: true,
            type: Array,
        },
    },
    methods: {
        logAs(employee) {
            this.loading = true;

            axios
                .post(`/api/admin/log_as/${employee.id}`)
                .then(() => {
                    document.location.href = "/";
                })
                .catch((error) => {
                    this.loading = false;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
        selectEmployee(employee) {
            if (!confirm(`Souhaitez vous vous connecter avec l'idendifiant "${employee.email}" ?`)) return;
            this.logAs(employee);
        },
        deleteEmployee(employee) {
            if (!confirm(`Supprimer l'employé "${employee.firstname} ${employee.lastname}" ?`)) return;
            this.loading = true;

            this.$store
                .dispatch("admin/deleteEmployee", { employee_id: employee.id })
                .then((response) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    this.$emit("employee-deleted");
                })
                .catch((error) => {
                    this.loading = false;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
        formatRole(role) {
            switch (role) {
                case "manager":
                    return "Responsable";
                case "employee":
                    return "Employé";
                case "owner":
                    return "Gérant";
                case "room":
                    return "Salle";
                case "kitchen":
                    return "Cuisine";
            }
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>
