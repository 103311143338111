<template>
    <div class="my-2">
        <switch-input-component label="Code promo actif" input-name="is_active" v-model="form.is_active" />
        <switch-input-component label="Clients n'ayant jamais souscrit à ce module" input-name="only_subscribers" v-model="form.only_subscribers" />
        <switch-input-component label="Associer le code promo à un ou plusieurs clients existants" input-name="hasUsers" v-model="hasUsers" />

        <loader-component v-if="isLoading" />
        <div v-else-if="hasUsers" class="form-group">
            <vue-multiselect
                v-model="form.users"
                :options="users"
                :multiple="true"
                label="label"
                track-by="value"
                placeholder="Sélectionnez un utilisateur"
                :close-on-select="false"
                :show-labels="false"
                open-direction="bottom">
            </vue-multiselect>
        </div>
    </div>
</template>
<script>
import SwitchInputComponent from "../../../forms/SwitchInputComponent.vue";
import VueMultiselect from "vue-multiselect";
import LoaderComponent from "../../../LoaderComponent.vue";

export default {
    components: {
        SwitchInputComponent,
        VueMultiselect,
        LoaderComponent,
    },
    data() {
        return {
            isLoading: false,
            form: {
                is_active: true,
                only_subscribers: false,
                users: [],
            },
            users: [],
            hasUsers: false,
        };
    },
    watch: {
        hasUsers(newValue) {
            if (newValue) {
                this.getUsers();
            } else {
                this.isLoading = false;
                this.users = [];
                this.form.users = [];
            }
        },
        form: {
            handler(newValue) {
                this.$emit("update-form", newValue);
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        getUsers() {
            this.isLoading = true;

            this.httpGet("/api/admin/owners")
                .then((response) => {
                    if (response !== false) {
                        this.users = response.data.data.map((user) => {
                            return {
                                value: user.id,
                                label: user.firstname + " " + user.lastname,
                            };
                        });
                    }
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
