<template>
    <div>
        <div class="d-flex justify-content-between">
            <div class="text-center">
                <feather class="btn btn-sm stepper-btn" type="calendar" :class="getClasses(0)" @click="changeStep(0)" />
                <small class="text-muted d-block" v-if="reservation_date !== false">
                    {{ reservation_date }}
                </small>
            </div>
            <div class="text-center">
                <feather class="btn btn-sm stepper-btn" type="users" :class="getClasses(1)" @click="changeStep(1)" />
                <small class="text-muted d-block" v-if="pax !== false">
                    {{ $tc("labels.person", pax) }}
                </small>
            </div>
            <div class="text-center">
                <feather class="btn btn-sm stepper-btn" type="clock" :class="getClasses(2)" @click="changeStep(2)" />
                <small class="text-muted d-block" v-if="slotHour !== false">
                    {{ slotHour }}
                </small>
            </div>
            <div class="text-center">
                <feather class="btn btn-sm stepper-btn" type="tool" :class="getClasses(3)" @click="changeStep(3)" />
                <small class="text-muted d-block" v-if="step > 3">
                    {{ $tl("labels.booking.menus.title", restaurant_id) }}
                </small>
            </div>
            <div class="text-center">
                <feather class="btn btn-sm stepper-btn" type="user" :class="getClasses(4)" @click="changeStep(4)" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    props: {
        reservation: {
            type: Object,
            required: true,
        },
        step: {
            type: Number,
            required: true,
        },
        restaurantId: {
            default: null,
        },
    },
    computed: {
        restaurant_id() {
            return this.restaurantId || this.$route.params.restaurant_id;
        },
        reservation_date() {
            if (!this.reservation.reservation_date) return false;
            return this.reservation.reservation_date.toFormat("ccc dd LLLL y");
        },
        pax() {
            return [0, null].includes(this.reservation.nb_pers) ? false : this.reservation.nb_pers + this.reservation.nb_children;
        },
        slotHour() {
            if (!this.reservation.slot_id || !this.reservation.slot || !this.reservation.slot.hour_start) return false;
            return this.reservation.slot.hour_start;
        },
    },
    methods: {
        getClasses(step) {
            return {
                "text-success": this.step === step,
                "cursor-default": this.step <= step,
            };
        },
        changeStep(step) {
            if (this.step < step) return;
            this.$emit("change-step", step);
        },
    },
};
</script>

<style scoped>
.stepper-btn {
    width: 32px;
    height: 26px;
    color: #6c757d;
    padding: 0;
    border: none;
}

.cursor-default {
    cursor: default;
}
</style>
