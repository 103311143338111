<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Transmettre les instructions d'intégration</h5>
        <div slot="body">
            <send-click-and-collect-widget-doc-to-dev
                :showSaveButton="false"
                @update-mail="handleMail"
                :pmail="mail"
                :errors="errors" />
        </div>
        <div slot="footer" class="d-flex">
            <button
                type="button"
                class="modal-default-button btn btn-sm btn-secondary btn-circle"
                @click="$emit('close')">
                Annuler
            </button>
            <button type="button" class="btn btn-sm btn-success btn-circle ml-2" @click="sendWidgetDoc()">
                Envoyer
            </button>
        </div>
    </modal>
</template>

<script>
import sendClickAndCollectWidgetDocToDev from "../../clickAndCollect/sendClickAndCollectWidgetDocToDev";
import axios from "axios";

export default {
    data() {
        return {
            mail: null,
            errors: null,
        };
    },
    props: {
        restaurant_id: {
            required: true,
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
        handleMail(value) {
            this.mail = value;
        },
        sendWidgetDoc() {
            this.errors = {};

            axios
                .post(`/api/click_and_collect/${this.restaurant_id}/widget/doc`, {
                    mail: this.mail,
                    restaurant_id: this.restaurant_id,
                })
                .then((response) => {
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: "Instructions envoyées avec succès.",
                    });
                    this.close();
                })
                .catch((error) => {
                    this.loading = false;
                    this.errors = error.response.data.errors ? error.response.data.errors : null;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: error.response.data.message ? error.response.data.message : "Une erreur est survenue",
                    });
                });
        },
    },
    components: {
        sendClickAndCollectWidgetDocToDev,
    },
};
</script>
