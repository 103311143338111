var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pr-0 pb-5" },
    [
      _vm.nbLoading > 0
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.errors.length > 0
              ? _c(
                  "div",
                  { staticClass: "alert alert-danger" },
                  _vm._l(_vm.errors, function (err, i) {
                    return _c("span", { key: i, staticClass: "d-block" }, [
                      _vm._v(_vm._s(err)),
                    ])
                  }),
                  0
                )
              : _c("div", [
                  _c("div", { staticClass: "row m-0" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                        },
                        [
                          _c("h5", { staticClass: "title mt-2" }, [
                            _vm._v(_vm._s(_vm.$tl("labels.settings.options"))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              staticClass: "btn btn-sm btn-success btn-circle",
                              class: {
                                disabled: !_vm.has_right_to_create_menu,
                              },
                              attrs: {
                                to: {
                                  name: "booking.restaurants.settings.menus_options.add",
                                  params: { restaurant_id: _vm.restaurant_id },
                                },
                              },
                            },
                            [
                              _c("feather", { attrs: { type: "plus" } }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tl("labels.booking.menus.options.add")
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mx-0 my-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "my-2 d-flex justify-content-between" },
                        [
                          _c("h6", { staticClass: "title mt-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$tl(
                                  "labels.settings.menuOptions",
                                  _vm.restaurant_id
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.has_right_to_update_menu
                            ? [
                                !_vm.isOrdering
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-success mr-1 btn-circle",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.startOrdering(
                                              _vm.MENU_OPTION_MENU.value
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "css-i6dzq1 feather",
                                            attrs: {
                                              viewBox: "0 0 24 24",
                                              width: "24",
                                              height: "24",
                                              stroke: "currentColor",
                                              "stroke-width": "2",
                                              fill: "none",
                                              "stroke-linecap": "round",
                                              "stroke-linejoin": "round",
                                            },
                                          },
                                          [
                                            _c("polyline", {
                                              attrs: {
                                                points: "9 5 12 2 15 5",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("polyline", {
                                              attrs: {
                                                points: "15 19 12 22 9 19",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("line", {
                                              attrs: {
                                                x1: "12",
                                                y1: "2",
                                                x2: "12",
                                                y2: "22",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.form.actions.orderMenusOptions",
                                                _vm.restaurant_id
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  : [
                                      _c("div", [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-success mr-1 btn-circle",
                                            attrs: {
                                              disabled: _vm.nbLoading > 0,
                                              type: "button",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.saveOrdering(
                                                  _vm.MENU_OPTION_MENU.value
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.form.actions.save"
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-outline-secondary mr-1 btn-circle",
                                            attrs: {
                                              disabled: _vm.nbLoading > 0,
                                              type: "button",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.cancelOrdering(
                                                  _vm.MENU_OPTION_MENU.value
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.form.actions.cancel"
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.options && _vm.options.length > 0
                        ? _c(
                            "table",
                            {
                              staticClass:
                                "table table-sm table-striped border-bottom",
                            },
                            [
                              _c("thead", { staticClass: "border-bottom" }, [
                                _c("tr", [
                                  _vm.isOrdering
                                    ? _c("th", { staticClass: "w-p-10" }, [
                                        _vm._v(_vm._s(_vm.$tl("labels.order"))),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "w-p-10" }, [
                                    _vm._v(_vm._s(_vm.$tl("labels.form.name"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "w-p-10" }, [
                                    _vm._v(_vm._s(_vm.$tl("labels.price"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "w-p-40" }, [
                                    _vm._v(
                                      _vm._s(_vm.$tl("labels.form.description"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "w-p-20" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.menus.options.quantity"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "w-p-10" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl("labels.form.actions.title")
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "draggable",
                                {
                                  attrs: {
                                    disabled: !_vm.isOrdering,
                                    tag: "tbody",
                                    draggable: ".menus-options",
                                  },
                                  model: {
                                    value: _vm.options,
                                    callback: function ($$v) {
                                      _vm.options = $$v
                                    },
                                    expression: "options",
                                  },
                                },
                                _vm._l(_vm.options, function (option, i) {
                                  return _c(
                                    "tr",
                                    { key: i, staticClass: "menus-options" },
                                    [
                                      _vm.isOrdering
                                        ? _c("td", [
                                            _c(
                                              "svg",
                                              {
                                                staticClass:
                                                  "css-i6dzq1 feather",
                                                staticStyle: {
                                                  cursor: "grab",
                                                  color: "rgb(170, 170, 170)",
                                                },
                                                attrs: {
                                                  viewBox: "0 0 24 24",
                                                  width: "24",
                                                  height: "24",
                                                  stroke: "currentColor",
                                                  "stroke-width": "2",
                                                  fill: "none",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                },
                                              },
                                              [
                                                _c("line", {
                                                  attrs: {
                                                    x1: "3",
                                                    y1: "9",
                                                    x2: "21",
                                                    y2: "9",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("line", {
                                                  attrs: {
                                                    x1: "3",
                                                    y1: "15",
                                                    x2: "21",
                                                    y2: "15",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("td", [_vm._v(_vm._s(option.name))]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              option.price === 0
                                                ? _vm.$tl("labels.free")
                                                : `${_vm.formatCurrency(
                                                    option.price,
                                                    _vm.getRestaurantCurrency(
                                                      _vm.restaurant_id
                                                    )
                                                  )}`
                                            ) +
                                            "\n                                "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(option.description)),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getOptionQuantityTypeLabel(
                                              option.quantity_type,
                                              _vm.restaurant_id
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: {
                                                    delay: {
                                                      show: 400,
                                                      hide: 0,
                                                    },
                                                    content: _vm.$t(
                                                      "labels.form.actions.edit"
                                                    ),
                                                  },
                                                  expression:
                                                    "{\n                                            delay: { show: 400, hide: 0 },\n                                            content: $t('labels.form.actions.edit'),\n                                        }",
                                                },
                                              ],
                                              staticClass:
                                                "btn btn-sm btn-success btn-square",
                                              attrs: {
                                                to: _vm.getEditRouteForOption(
                                                  option
                                                ),
                                              },
                                            },
                                            [
                                              _c("feather", {
                                                attrs: { type: "edit" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          !option.has_future_reservations
                                            ? _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: {
                                                        delay: {
                                                          show: 400,
                                                          hide: 0,
                                                        },
                                                        content: _vm.$t(
                                                          "labels.form.actions.delete"
                                                        ),
                                                      },
                                                      expression:
                                                        "{\n                                            delay: { show: 400, hide: 0 },\n                                            content: $t('labels.form.actions.delete'),\n                                        }",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-sm btn-danger btn-square",
                                                  attrs: {
                                                    type: "button",
                                                    disabled:
                                                      !_vm.has_right_to_delete_menu,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteOption(
                                                        option
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("feather", {
                                                    attrs: { type: "trash-2" },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          option.has_future_reservations
                                            ? _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: {
                                                        delay: {
                                                          show: 400,
                                                          hide: 0,
                                                        },
                                                        content: _vm.$t(
                                                          "infos.booking.menus.options.cantDelete"
                                                        ),
                                                      },
                                                      expression:
                                                        "{\n                                            delay: { show: 400, hide: 0 },\n                                            content: $t('infos.booking.menus.options.cantDelete'),\n                                        }",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-sm btn-secondary btn-square",
                                                  attrs: { type: "button" },
                                                },
                                                [
                                                  _c("feather", {
                                                    attrs: { type: "trash-2" },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          )
                        : _c("div", [
                            _c("em", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl("labels.booking.menus.options.empty")
                                )
                              ),
                            ]),
                          ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mx-0 my-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "my-2 d-flex justify-content-between" },
                        [
                          _c("h6", { staticClass: "title mt-2" }, [
                            _vm._v(
                              _vm._s(_vm.$tl("labels.settings.generalOptions"))
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.has_right_to_update_menu
                            ? [
                                !_vm.isOrderingGeneral
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-success mr-1 btn-circle",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.startOrdering(
                                              _vm.MENU_OPTION_GENERAL.value
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "css-i6dzq1 feather",
                                            attrs: {
                                              viewBox: "0 0 24 24",
                                              width: "24",
                                              height: "24",
                                              stroke: "currentColor",
                                              "stroke-width": "2",
                                              fill: "none",
                                              "stroke-linecap": "round",
                                              "stroke-linejoin": "round",
                                            },
                                          },
                                          [
                                            _c("polyline", {
                                              attrs: {
                                                points: "9 5 12 2 15 5",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("polyline", {
                                              attrs: {
                                                points: "15 19 12 22 9 19",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("line", {
                                              attrs: {
                                                x1: "12",
                                                y1: "2",
                                                x2: "12",
                                                y2: "22",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.form.actions.orderGeneralOptions"
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  : [
                                      _c("div", [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-success mr-1 btn-circle",
                                            attrs: {
                                              disabled: _vm.nbLoading > 0,
                                              type: "button",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.saveOrdering(
                                                  _vm.MENU_OPTION_GENERAL.value
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.form.actions.save"
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-outline-secondary mr-1 btn-circle",
                                            attrs: {
                                              disabled: _vm.nbLoading > 0,
                                              type: "button",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.cancelOrdering(
                                                  _vm.MENU_OPTION_GENERAL.value
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.form.actions.cancel"
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.generalOptions && _vm.generalOptions.length > 0
                        ? _c(
                            "table",
                            {
                              staticClass:
                                "table table-sm table-striped border-bottom",
                            },
                            [
                              _c("thead", { staticClass: "border-bottom" }, [
                                _c("tr", [
                                  _vm.isOrderingGeneral
                                    ? _c("th", { staticClass: "w-p-10" }, [
                                        _vm._v(_vm._s(_vm.$tl("labels.order"))),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "w-p-10" }, [
                                    _vm._v(_vm._s(_vm.$tl("labels.form.name"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "w-p-10" }, [
                                    _vm._v(_vm._s(_vm.$tl("labels.price"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "w-p-40" }, [
                                    _vm._v(
                                      _vm._s(_vm.$tl("labels.form.description"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "w-p-20" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.menus.options.quantity"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "w-p-10" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl("labels.form.actions.title")
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "draggable",
                                {
                                  attrs: {
                                    disabled: !_vm.isOrderingGeneral,
                                    tag: "tbody",
                                    draggable: ".menus-options",
                                  },
                                  model: {
                                    value: _vm.generalOptions,
                                    callback: function ($$v) {
                                      _vm.generalOptions = $$v
                                    },
                                    expression: "generalOptions",
                                  },
                                },
                                _vm._l(
                                  _vm.generalOptions,
                                  function (option, i) {
                                    return _c(
                                      "tr",
                                      { key: i, staticClass: "menus-options" },
                                      [
                                        _vm.isOrderingGeneral
                                          ? _c("td", [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "css-i6dzq1 feather",
                                                  staticStyle: {
                                                    cursor: "grab",
                                                    color: "rgb(170, 170, 170)",
                                                  },
                                                  attrs: {
                                                    viewBox: "0 0 24 24",
                                                    width: "24",
                                                    height: "24",
                                                    stroke: "currentColor",
                                                    "stroke-width": "2",
                                                    fill: "none",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                  },
                                                },
                                                [
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "3",
                                                      y1: "9",
                                                      x2: "21",
                                                      y2: "9",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "3",
                                                      y1: "15",
                                                      x2: "21",
                                                      y2: "15",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("td", [_vm._v(_vm._s(option.name))]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                option.price === 0
                                                  ? _vm.$tl("labels.free")
                                                  : _vm.formatCurrency(
                                                      option.price,
                                                      _vm.getRestaurantCurrency(
                                                        _vm.restaurant_id
                                                      )
                                                    )
                                              ) +
                                              "\n                                "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(_vm._s(option.description)),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getOptionQuantityTypeLabel(
                                                option.quantity_type,
                                                _vm.restaurant_id
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: {
                                                      delay: {
                                                        show: 400,
                                                        hide: 0,
                                                      },
                                                      content: _vm.$t(
                                                        "labels.form.actions.edit"
                                                      ),
                                                    },
                                                    expression:
                                                      "{\n                                            delay: { show: 400, hide: 0 },\n                                            content: $t('labels.form.actions.edit'),\n                                        }",
                                                  },
                                                ],
                                                staticClass:
                                                  "btn btn-sm btn-success btn-square",
                                                attrs: {
                                                  to: _vm.getEditRouteForOption(
                                                    option
                                                  ),
                                                },
                                              },
                                              [
                                                _c("feather", {
                                                  attrs: { type: "edit" },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            !option.has_future_reservations
                                              ? _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value: {
                                                          delay: {
                                                            show: 400,
                                                            hide: 0,
                                                          },
                                                          content: _vm.$t(
                                                            "labels.form.actions.delete"
                                                          ),
                                                        },
                                                        expression:
                                                          "{\n                                            delay: { show: 400, hide: 0 },\n                                            content: $t('labels.form.actions.delete'),\n                                        }",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "btn btn-sm btn-danger btn-square",
                                                    attrs: {
                                                      type: "button",
                                                      disabled:
                                                        !_vm.has_right_to_delete_menu,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteOption(
                                                          option
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("feather", {
                                                      attrs: {
                                                        type: "trash-2",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            option.has_future_reservations
                                              ? _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value: {
                                                          delay: {
                                                            show: 400,
                                                            hide: 0,
                                                          },
                                                          content: _vm.$t(
                                                            "infos.booking.menus.options.cantDelete"
                                                          ),
                                                        },
                                                        expression:
                                                          "{\n                                            delay: { show: 400, hide: 0 },\n                                            content: $t('infos.booking.menus.options.cantDelete'),\n                                        }",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "btn btn-sm btn-secondary btn-square",
                                                    attrs: { type: "button" },
                                                  },
                                                  [
                                                    _c("feather", {
                                                      attrs: {
                                                        type: "trash-2",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          )
                        : _c("div", [
                            _c("em", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl("labels.booking.menus.options.empty")
                                )
                              ),
                            ]),
                          ]),
                    ]),
                  ]),
                ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }