var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal-rounded-no-header", { on: { close: _vm.closeFromModal } }, [
    _c("div", { attrs: { slot: "body" }, slot: "body" }, [
      _c(
        "p",
        { staticClass: "text-center" },
        _vm._l(
          _vm.$tl("infos.booking.seatingPlan.needed"),
          function (tmpMsg, index) {
            return _c("span", { key: index, staticClass: "d-block" }, [
              _vm._v("\n                " + _vm._s(tmpMsg) + "\n            "),
            ])
          }
        ),
        0
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex w-100 justify-content-between",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "button",
          {
            staticClass: "modal-default-button btn btn-secondary btn-circle",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [
            _vm._v(
              "\n            " + _vm._s(_vm.closeButtonTitle) + "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "btn btn-success btn-circle d-flex align-items-center justify-content-between mr-2",
            attrs: { type: "button" },
            on: { click: _vm.goToSeatingPlanBuilder },
          },
          [
            _c("feather", {
              staticClass: "feather-plus mr-1",
              attrs: { type: "plus" },
            }),
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.booking.seatingPlan.add")) +
                "\n        "
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }