<template>
    <div>
        <div class="row mb-2">
            <div class="col-12">
                <strong>{{ $tl("labels.notifications.title") }}</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-9 pt-2">
                <label>{{ $tl("labels.notifications.enable") }}</label>
            </div>
            <div class="col-3 pt-2">
                <label class="container-box">
                    <input type="checkbox" v-model="enable_mail_notifications_" true-value="1" />
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
        <div class="row">
            <div class="col-9 pt-2">
                <label>{{ $tl("labels.notifications.enableRingtone") }}</label>
            </div>
            <div class="col-3 pt-2">
                <label class="container-box">
                    <input type="checkbox" v-model="enable_notification_ringtone_" true-value="1" />
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
        <div class="row mt-3" v-if="enable_mail_notifications && hasRightToEditChannels">
            <div class="col-12">
                <button type="button" class="btn btn-success btn-sm btn-circle" @click="showChannelCustomization = !showChannelCustomization">
                    {{ $tl("labels.notifications.editChannel") }}
                </button>
            </div>
        </div>
        <template v-if="showChannelCustomization && enable_mail_notifications && hasRightToEditChannels">
            <LoaderComponent v-if="loading" />
            <div v-else>
                <div class="mt-3 pt-3 border-top-light" v-for="module in availableModules_" :key="module">
                    <div class="row">
                        <div class="col-12">
                            <strong>{{ module == "default" ? $tl("labels.notifications.general.title") : getModuleTypeLabel(module) }}</strong>
                            <div class="row mt-2" v-for="notification in Object.keys(notificationsLabel[module])" :key="notification">
                                <div class="col-7">{{ notificationsLabel[module][notification] }}</div>
                                <div class="col-5 radio">
                                    <label
                                        class="container-box"
                                        :class="{ 'cursor-not-allowed': !smsActivated || !tel_is_mobile }"
                                        v-tooltip="smsTooltip">
                                        <input
                                            type="radio"
                                            :disabled="!smsActivated || !tel_is_mobile"
                                            v-model="user_notifications_setting[notification]"
                                            :value="NOTIFICATION_TYPE_SMS.value" />
                                        <span class="checkmark" :class="{ disabled: !smsActivated || !tel_is_mobile }"></span>
                                        {{ getNotificationTypeLabel(NOTIFICATION_TYPE_SMS.value) }}
                                    </label>

                                    <label class="container-box">
                                        <input
                                            type="radio"
                                            v-model="user_notifications_setting[notification]"
                                            :value="NOTIFICATION_TYPE_EMAIL.value" />
                                        <span class="checkmark"></span>
                                        {{ getNotificationTypeLabel(NOTIFICATION_TYPE_EMAIL.value) }}
                                    </label>

                                    <label class="container-box">
                                        <input
                                            type="radio"
                                            v-model="user_notifications_setting[notification]"
                                            :value="NOTIFICATION_TYPE_DISABLED.value" />
                                        <span class="checkmark"></span>
                                        {{ getNotificationTypeLabel(NOTIFICATION_TYPE_DISABLED.value) }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import LoaderComponent from "../../LoaderComponent";
import NotificationTypesEnum from "../../../mixins/enums/NotificationTypesEnum";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum";
import SubscriptionStatusEnum from "../../../mixins/enums/SubscriptionStatusEnum";

export default {
    data() {
        return {
            loading: 0,
            showChannelCustomization: false,
            smsActivated: false,
            excludedPropertiesToFill: ["id", "user_id"],
        };
    },
    mixins: [NotificationTypesEnum, ModuleTypesEnum, SubscriptionStatusEnum],
    props: {
        user_notifications_setting: {
            type: Object,
            required: true,
        },
        enable_mail_notifications: {
            default: false,
        },
        enable_notification_ringtone: {
            default: false,
        },
        tel_is_mobile: {
            default: false,
        },
    },
    computed: {
        notificationsLabel() {
            return {
                default: {
                    restau_sms_credits_alert: this.$tl("labels.notifications.general.smsAlert"),
                },
                booking: {
                    restau_option_bank_canceled: this.$tl("labels.notifications.booking.footprintPaymentExpired"),
                    restau_resa_canceled: this.$tl("labels.notifications.booking.canceled"),
                    restau_resa_created: this.$tl("labels.notifications.booking.created"),
                    restau_resa_validated: this.$tl("labels.notifications.booking.confirmed"),
                    restau_resa_updated: this.$tl("labels.notifications.booking.updated"),
                },
                "gift-vouchers": {
                    restaurant_payment_receipt: this.$tl("labels.notifications.giftVouchers.new"),
                },
                "click-and-collect": {
                    restaurant_order_confirmed: this.$tl("labels.notifications.clickAndCollect.newAndCreated"),
                },
            };
        },
        smsTooltip() {
            if (!this.smsActivated) return { content: "Vous devez activer les SMS pour activer les notifications par SMS" };
            if (!this.tel_is_mobile) return { content: "Vous devez posséder un téléphone mobile pour activer les notifications par SMS" };
            return undefined;
        },
        hasRightToEditChannels() {
            return (
                this.$store.getters["users/formattedRights"].includes("default.employees.create") ||
                this.$store.getters["users/formattedRights"].includes("default.employees.update")
            );
        },
        enable_mail_notifications_: {
            get() {
                return this.enable_mail_notifications;
            },
            set(newVal) {
                this.$emit("set-property", {
                    key: "enable_mail_notifications",
                    value: newVal,
                });
            },
        },
        enable_notification_ringtone_: {
            get() {
                return this.enable_notification_ringtone;
            },
            set(newVal) {
                this.$emit("set-property", {
                    key: "enable_notification_ringtone",
                    value: newVal,
                });
            },
        },
        availableModules() {
            return Object.keys(this.$store.getters["users/activeModules"]);
        },
        availableModules_() {
            const modulesWithNotifications = [
                this.MODULE_TYPE_BOOKING.value,
                this.MODULE_TYPE_CLICK_AND_COLLECT.value,
                this.MODULE_TYPE_GIFT_VOUCHERS.value,
            ];
            return modulesWithNotifications.filter((m) => this.availableModules.some((m1) => m1 === m)).concat(["default"]);
        },
    },
    methods: {
        fetchIfSmsActivated() {
            this.loading++;

            axios
                .get("/api/sms/activation")
                .then((response) => {
                    this.loading--;
                    this.smsActivated = response.data.isActivated;
                })
                .catch(() => {
                    this.loading--;
                    this.smsActivated = false;
                });
        },
    },
    watch: {
        enable_mail_notifications() {
            Object.keys(this.user_notifications_setting).forEach((notification) => {
                if (!this.excludedPropertiesToFill.includes(notification))
                    this.user_notifications_setting[notification] = this.NOTIFICATION_TYPE_EMAIL.value;
            });
        },
        tel_is_mobile(newVal) {
            if (!newVal) {
                Object.keys(this.user_notifications_setting).forEach((notification) => {
                    if (
                        !this.excludedPropertiesToFill.includes(notification) &&
                        this.user_notifications_setting[notification] === this.NOTIFICATION_TYPE_SMS.value
                    )
                        this.user_notifications_setting[notification] = this.NOTIFICATION_TYPE_EMAIL.value;
                });
            }
        },
    },
    components: {
        LoaderComponent,
    },
    created() {
        this.fetchIfSmsActivated();
    },
};
</script>

<style scoped>
.cursor-not-allowed {
    cursor: not-allowed;
}
</style>
