<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div v-else-if="service.isOpen">
            <strong style="text-transform: capitalize">{{ getServiceCategoryLabel(service) }}</strong>
            <small
                v-if="service.is_full === 1 || service.isOpen == false"
                class="text-uppercase ml-2 d-inline-block badge-pill badge-danger text-white-hover">
                &nbsp;{{ service.is_full === 1 ? $tl("labels.booking.services.full") : $tl("labels.booking.services.closedSpecial") }} </small
            ><br />
            <small>{{ service.name }}</small> - {{ service.pax }} / {{ service.new_pax !== null ? service.new_pax : service.max_pax }}<br />
            <strong style="color: red" v-if="service.pax > (service.new_pax !== null ? service.new_pax : service.max_pax)">{{
                $tl("labels.paxTooHigh")
            }}</strong>
            <ul class="radio mt-3 mb-2" style="padding-left: 0">
                <li
                    class="mb-2"
                    v-for="slot in service.slotsWithPax.data"
                    v-tooltip="
                        hasTablesSelected
                            ? {
                                  content: $tl('infos.booking.reservations.edit.slot', restaurant_id),
                              }
                            : undefined
                    "
                    :key="slot.id"
                    @click="hasTablesSelected ? $emit('unlink-all-tables') : undefined">
                    <label class="container-box" :class="{ 'cursor-disabled': readOnly }">
                        <input :disabled="readOnly" type="radio" name="slot" v-model="slotId" :value="slot.id" />
                        <span class="checkmark" :style="readOnly ? 'opacity: 0.5;' : ''" :class="{ 'cursor-disabled': readOnly }"></span>
                        {{ slot.hour_start
                        }}<span class="none-mobile" v-if="service.is_table_rotation_enabled == true">
                            - {{ slot.pax }} /
                            {{
                                service.is_slot_max_pax_enabled && slot.is_max_pax_enabled && slot.max_pax
                                    ? slot.max_pax
                                    : service.new_pax !== null
                                    ? service.new_pax
                                    : service.max_pax
                            }}
                            {{ $tl("labels.personsShort") }}</span
                        ><span v-else class="none-mobile">
                            - {{ slot.pax_real }}
                            {{ service.is_slot_max_pax_enabled && slot.is_max_pax_enabled && slot.max_pax ? `/ ${slot.max_pax}` : `` }}
                            {{ $tl("labels.personsShort") }}</span
                        >
                    </label>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import LoaderComponent from "../../components/LoaderComponent.vue";
import LangsEnum from "../../mixins/enums/LangsEnum";

export default {
    name: "slotsReservations",
    data() {
        return this.$_.cloneDeep(
            this.$_.defaultsDeep(this.defaultValues, {
                loading: true,
                service: null,
                slotId: null,
            })
        );
    },
    mixins: [LangsEnum],
    props: {
        hasTablesSelected: {
            default: false,
        },
        service_id: {
            default: null,
        },
        restaurant_id: {
            default: null,
        },
        slot_id: {
            default: null,
        },
        formErrors: {
            default: null,
        },
        readOnly: {
            default: false,
        },
        reservation_date: {
            required: true,
        },
        preselected_service_id: {
            default: null,
        },
        preselected_slot_hour: {
            default: null,
        },
    },
    methods: {
        loadService(value = this.service_id) {
            this.loading = true;
            return axios
                .get(`/api/restaurants/${this.restaurant_id}/services/${value}?include=slotsWithPax,isOpen&date=${this.reservation_date.toISODate()}`)
                .then((response) => {
                    this.service = response.data;
                    this.loading = false;
                    const slotsId = this.service.slotsWithPax.data.map((s) => s.id);
                    if (this.slot_id && slotsId.includes(this.slot_id)) {
                        this.slotId = this.slot_id;
                    } else if (this.preselected_service_id && this.preselected_slot_hour && this.service.id == this.preselected_service_id) {
                        const slotFound = this.service.slotsWithPax.data.find((s) => {
                            return s.hour_start == this.preselected_slot_hour;
                        });

                        if (slotFound) {
                            this.slotId = slotFound.id;
                        }
                    }
                })
                .catch((error) => {});
        },
        hasErrors(name) {
            return this.formErrors && this.formErrors.errors && !_.isEmpty(this.formErrors.errors[name]);
        },
    },
    mounted() {
        this.loadService();
    },
    watch: {
        slotId: {
            handler(newVal, oldVal) {
                if (newVal !== null) {
                    const slot = this.service.slotsWithPax.data.find((s) => {
                        return s.id == newVal;
                    });

                    this.$emit("update-slot-id", {
                        slot_hour: slot.hour_start,
                        slot_id: newVal,
                        service_id: this.service_id,
                    });
                }
            },
        },
        slot_id: {
            handler(newVal) {
                if (this.service) {
                    const slotsId = this.service.slotsWithPax.data.map((s) => s.id);
                    if (newVal && slotsId.includes(newVal)) {
                        this.slotId = newVal;
                    } else {
                        this.slotId = null;
                    }
                } else {
                    this.slotId = null;
                }
            },
        },
        restaurant_id: {
            handler(newVal) {
                if (newVal) {
                    this.loadService();
                }
            },
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>

<style scoped>
.cursor-disabled {
    cursor: not-allowed !important;
}
</style>
