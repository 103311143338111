<template>
    <div class="default-home">
        <div class="row m-0">
            <div class="col-12 d-flex">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1">
                    <h5 class="title mt-2">{{ $tl("labels.restaurants") }}</h5>
                </div>
            </div>
        </div>
        <div>
            <div class="row m-0">
                <div class="col-12">
                    <list-item v-for="restaurant in restaurants" :key="restaurant.id" :restaurant="restaurant" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ModuleTypesEnum from "../../../../mixins/enums/ModuleTypesEnum.js";
import ListItem from "./ListItem.vue";

export default {
    computed: {
        restaurants() {
            return this.$store.getters["restaurants/restaurants"];
        },
    },
    mixins: [ModuleTypesEnum],
    components: {
        ListItem,
    },
};
</script>
