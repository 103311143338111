<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ category.id !== null ? "Éditer" : "Ajouter" }} une catégorie</h5>
        <div slot="body">
            <add-click-and-collect-category
                ref="addClickAndCollectCategoryComponent"
                :category="category"
                @saved="$emit('saved')" />
        </div>
        <div slot="footer" class="d-flex">
            <button
                type="button"
                class="modal-default-button btn btn-sm btn-secondary btn-circle"
                @click="$emit('close')">
                Annuler
            </button>
            <button
                type="button"
                class="btn btn-sm btn-success btn-circle ml-2"
                @click="$refs.addClickAndCollectCategoryComponent.save()">
                Enregistrer
            </button>
        </div>
    </modal>
</template>

<script>
import addClickAndCollectCategory from "../../clickAndCollect/products/addClickAndCollectCategory";

export default {
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    props: {
        category: {
            type: Object,
            required: true,
        },
    },
    components: {
        addClickAndCollectCategory,
    },
};
</script>
