var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.error
              ? _c("div", { staticClass: "error" }, [
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                ])
              : _c(
                  "div",
                  [
                    _vm.formErrors && _vm.formErrors.message
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$tl("errors.common.unknown")) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.formSuccess
                      ? _c("div", { staticClass: "alert alert-success" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.formSuccess) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.formLoading
                      ? _c("LoaderComponent", {
                          attrs: { message: _vm.$t("labels.saveInProgress") },
                        })
                      : _c(
                          "form",
                          {
                            staticClass: "mb-4 mt-5",
                            attrs: { method: "post" },
                            on: { submit: _vm.postForm },
                          },
                          [
                            _vm.slot
                              ? _c("p", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$tl(
                                          "questions.booking.services.openSlotForDate",
                                          _vm.restaurant_id,
                                          {
                                            hour: _vm.slot.hour_start,
                                            service: _vm.service.name,
                                            date: _vm.formatDate(_vm.date),
                                          }
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ])
                              : _c("p", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$tl(
                                          "questions.booking.services.openServiceForDate",
                                          _vm.restaurant_id,
                                          {
                                            service: _vm.service.name,
                                            date: _vm.formatDate(_vm.date),
                                          }
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "sepa" }),
                            _vm._v(" "),
                            _vm.showSaveButton
                              ? _c(
                                  "div",
                                  { staticClass: "form-row mb-2 mt-4" },
                                  [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c("input", {
                                        staticClass:
                                          "btn btn-success btn-circle btn-sm",
                                        attrs: {
                                          type: "submit",
                                          name: "submit",
                                        },
                                        domProps: {
                                          value: _vm.$t(
                                            "labels.form.actions.continue"
                                          ),
                                        },
                                      }),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                  ],
                  1
                ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }