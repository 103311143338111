<template>
    <div class="row mb-3">
        <div class="col-12">
            <loader-component v-if="nbLoading > 0" />
            <div v-else-if="stats.main" class="border-light b-radius-20 p-4">
                <div class="row m-0">
                    <h6 class="mt-3 col-10">
                        <strong>{{ $tl("labels.booking.globalStats.waitingsList.title", firstSelectedRestaurantId) }}</strong>
                    </h6>
                </div>
                <div class="table-responsive">
                    <table class="table table-striped overflowx-auto">
                        <thead>
                            <tr>
                                <th scope="col" colspan="1" class="group-header" :class="{ 'w-25': filters.hasComparison }"></th>
                                <th scope="col" :colspan="filters.hasComparison ? 5 : 1">
                                    {{ $tl("labels.booking.stats.waitingList.nb", firstSelectedRestaurantId) }}
                                </th>
                                <th scope="col" :colspan="filters.hasComparison ? 5 : 1">
                                    {{ $tl("labels.booking.stats.waitingList.nbLeadToResa", firstSelectedRestaurantId) }}
                                </th>
                                <th scope="col" :colspan="filters.hasComparison ? 5 : 1">
                                    {{ $tl("labels.booking.globalStats.table.cells.percentBooking", firstSelectedRestaurantId) }}
                                </th>
                            </tr>
                            <tr v-if="filters.hasComparison">
                                <td>{{ $tl("labels.booking.globalStats.table.cells.nameRestaurant", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.periodOne", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.periodTwo", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.periodOneOnTwo", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.varNb", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.varPercent", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.periodOne", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.periodTwo", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.periodOneOnTwo", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.varNb", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.varPercent", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.periodOne", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.periodTwo", firstSelectedRestaurantId) }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="!filters.hasComparison && !filters.hasGroupComparison">
                                <tr v-for="restaurant in filters.main.selectedRestaurants" :key="restaurant.id">
                                    <td>{{ restaurant.name }}</td>
                                    <td>{{ getMainStats(restaurant.id).count_waitings }}</td>
                                    <td>{{ getMainStats(restaurant.id).count_waitings_accepted }}</td>
                                    <td>
                                        {{
                                            getPercent(
                                                getMainStats(restaurant.id).count_waitings_accepted,
                                                getMainStats(restaurant.id).count_waitings
                                            )
                                        }}%
                                    </td>
                                </tr>
                            </template>
                            <template v-else-if="filters.hasGroupComparison">
                                <tr v-for="restaurant in mergedSelectedRestaurants" :key="restaurant.id">
                                    <td>{{ restaurant.name }}</td>
                                    <td>{{ restaurant.stats.count_waitings }}</td>
                                    <td>{{ restaurant.stats.count_waitings_accepted }}</td>
                                    <td>
                                        {{
                                            getPercent(
                                                getMainStats(restaurant.id).count_waitings,
                                                getMainStats(restaurant.id).count_waitings_accepted
                                            )
                                        }}%
                                    </td>
                                </tr>
                            </template>
                            <template v-else-if="filters.hasComparison">
                                <tr v-for="restaurant in filters.main.selectedRestaurants" :key="restaurant.id">
                                    <td>{{ restaurant.name }}</td>
                                    <td>{{ getMainStats(restaurant.id).count_waitings }}</td>
                                    <td>{{ getComparisonStats(restaurant.id).count_waitings }}</td>
                                    <td
                                        :class="
                                            getEvolutionClass(
                                                getEvolution(
                                                    getMainStats(restaurant.id).count_waitings,
                                                    getComparisonStats(restaurant.id).count_waitings
                                                )
                                            )
                                        ">
                                        <feather
                                            :type="
                                                getFeatherArrowIcon(
                                                    getEvolution(
                                                        getMainStats(restaurant.id).count_waitings,
                                                        getComparisonStats(restaurant.id).count_waitings
                                                    )
                                                )
                                            " />
                                    </td>
                                    <td
                                        :class="
                                            getEvolutionClass(
                                                getEvolution(
                                                    getMainStats(restaurant.id).count_waitings,
                                                    getComparisonStats(restaurant.id).count_waitings
                                                )
                                            )
                                        ">
                                        {{
                                            getDifference(
                                                getMainStats(restaurant.id).count_waitings,
                                                getComparisonStats(restaurant.id).count_waitings
                                            )
                                        }}
                                    </td>
                                    <td
                                        :class="
                                            getEvolutionClass(
                                                getEvolution(
                                                    getMainStats(restaurant.id).count_waitings,
                                                    getComparisonStats(restaurant.id).count_waitings
                                                )
                                            )
                                        ">
                                        {{
                                            formatPercent(
                                                getEvolution(
                                                    getMainStats(restaurant.id).count_waitings,
                                                    getComparisonStats(restaurant.id).count_waitings
                                                )
                                            )
                                        }}
                                    </td>
                                    <td>{{ getMainStats(restaurant.id).count_waitings_accepted }}</td>
                                    <td>{{ getComparisonStats(restaurant.id).count_waitings_accepted }}</td>
                                    <td
                                        :class="
                                            getEvolutionClass(
                                                getEvolution(
                                                    getMainStats(restaurant.id).count_waitings_accepted,
                                                    getComparisonStats(restaurant.id).count_waitings_accepted
                                                )
                                            )
                                        ">
                                        <feather
                                            :type="
                                                getFeatherArrowIcon(
                                                    getEvolution(
                                                        getMainStats(restaurant.id).count_waitings_accepted,
                                                        getComparisonStats(restaurant.id).count_waitings_accepted
                                                    )
                                                )
                                            " />
                                    </td>
                                    <td
                                        :class="
                                            getEvolutionClass(
                                                getEvolution(
                                                    getMainStats(restaurant.id).count_waitings_accepted,
                                                    getComparisonStats(restaurant.id).count_waitings_accepted
                                                )
                                            )
                                        ">
                                        {{
                                            getDifference(
                                                getMainStats(restaurant.id).count_waitings_accepted,
                                                getComparisonStats(restaurant.id).count_waitings_accepted
                                            )
                                        }}
                                    </td>
                                    <td
                                        :class="
                                            getEvolutionClass(
                                                getEvolution(
                                                    getMainStats(restaurant.id).count_waitings_accepted,
                                                    getComparisonStats(restaurant.id).count_waitings_accepted
                                                )
                                            )
                                        ">
                                        {{
                                            formatPercent(
                                                getEvolution(
                                                    getMainStats(restaurant.id).count_waitings_accepted,
                                                    getComparisonStats(restaurant.id).count_waitings_accepted
                                                )
                                            )
                                        }}
                                    </td>

                                    <td>
                                        {{
                                            getPercent(
                                                getMainStats(restaurant.id).count_waitings_accepted,
                                                getMainStats(restaurant.id).count_waitings
                                            )
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            getPercent(
                                                getComparisonStats(restaurant.id).count_waitings_accepted,
                                                getComparisonStats(restaurant.id).count_waitings
                                            )
                                        }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";
import StatsUtils from "../../mixins/helpers/booking/StatsUtils.js";
import ArrowPercentEvolutionComponent from "./ArrowPercentEvolutionComponent.vue";
export default {
    props: {
        restaurantId: {
            required: false,
            type: Number,
        },
        filters: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            isGlobalReport: false,
            stats: {
                main: null,
                comparison: null,
            },
            unGroupedStats: null,
        };
    },
    mixins: [StatsUtils],
    computed: {
        mergedSelectedRestaurants() {
            const mainRestaurants = this.filters.main.selectedRestaurants || [];
            const comparisonRestaurants = this.filters.comparison?.selectedRestaurants || [];

            const result = mainRestaurants.map((restaurant) => ({
                ...restaurant,
                stats: this.stats.main?.[restaurant.id] || { count_waitings: 0, count_waitings_accepted: 0 },
            }));

            if (this.filters.hasGroupComparison) {
                const mainRestaurantIds = mainRestaurants.map((r) => r.id);
                const additionalRestaurants = comparisonRestaurants
                    .filter((restaurant) => !mainRestaurantIds.includes(restaurant.id))
                    .map((restaurant) => ({
                        ...restaurant,
                        stats: this.stats.comparison?.[restaurant.id] || { count_waitings: 0, count_waitings_accepted: 0 },
                    }));

                result.push(...additionalRestaurants);
            }

            return result;
        },
        firstSelectedRestaurantId() {
            if (this.mergedSelectedRestaurants.length < 1) {
                return undefined;
            }

            return this.mergedSelectedRestaurants[0].id;
        },
    },
    methods: {
        fetchStats(abortControllerSignal = null, forComparison = false) {
            const filters = this.getFiltersQueryParams(forComparison);

            return this.httpGet(`/api/stats/waitings-count?${filters}&should_group_by_restaurant_id=1`, {
                config: { signal: abortControllerSignal },
                handleReject: false,
            }).then((response) => {
                if (response !== false) {
                    this.stats[forComparison ? "comparison" : "main"] = response.data;
                }
            });
        },
        getMainStats(restaurantId) {
            return this.stats.main?.[restaurantId] || { count_waitings: 0, count_waitings_accepted: 0 };
        },
        getComparisonStats(restaurantId) {
            return this.stats.comparison?.[restaurantId] || { count_waitings: 0, count_waitings_accepted: 0 };
        },
    },
    components: { LoaderComponent, ArrowPercentEvolutionComponent },
};
</script>

<style scoped>
.table th,
.table td {
    border: 1px solid #ddd;
    text-align: center;
    padding: 8px;
}

.group-header > td {
    background-color: #f2f2f2;
}

.table th {
    background-color: #f2f2f2;
    font-weight: bold;
}
</style>
