<template>
    <div class="p-2">
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Notes de mise à jour</h5>
                    <router-link :to="{ name: 'admin.app_updates.add' }" class="btn btn-sm btn-success btn-circle">
                        <feather type="plus" />
                        <span>Créer une note de mise à jour</span>
                    </router-link>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <ns-table v-if="appUpdatesList.length > 0" tableClass="table table-striped" allow-sorting :data-source="appUpdatesList">
                <template v-slot:head>
                    <ns-th field="update_date" width="9" allow-sorting>Date de la MàJ</ns-th>
                    <ns-th field="description" width="15">Description</ns-th>
                    <ns-th field="modules">Modules concernés</ns-th>
                    <ns-th field="updated_at" width="6" allow-sorting>Modifié le</ns-th>
                    <ns-th field="published_at" width="6" allow-sorting>Publié le</ns-th>
                    <ns-th width="12">Actions</ns-th>
                </template>
                <template v-slot:body="{ data: appUpdate }">
                    <td>{{ displayDate(appUpdate.update_date, DATE_SHORT) }}</td>
                    <td>{{ appUpdate.description }}</td>
                    <td>
                        <span v-if="appUpdate.modules.length === 0" class="font-italic">Non renseignés</span>
                        <template v-else>
                            <span v-for="tmpModule in appUpdate.modules" :key="tmpModule" class="badge badge-success mr-1">
                                {{ getModuleTypeLabel(tmpModule) }}
                            </span>
                        </template>
                    </td>
                    <td>{{ displayDate(appUpdate.updated_at, DATE_SHORT) }}</td>
                    <td>
                        <span v-if="appUpdate.published_at === null" class="font-italic">Brouillon</span>
                        <span v-else>{{ displayDate(appUpdate.published_at, DATE_SHORT) }}</span>
                    </td>
                    <td>
                        <router-link
                            class="btn btn-sm btn-square btn-outline-secondary"
                            :to="{ name: 'app_updates.show', params: { app_update_id: appUpdate.id } }"
                            v-tooltip="getTooltip($t('labels.form.actions.view'))">
                            <feather type="eye" />
                        </router-link>
                        <router-link
                            class="btn btn-sm btn-square btn-success"
                            :to="{ name: 'admin.app_updates.edit', params: { app_update_id: appUpdate.id } }"
                            v-tooltip="getTooltip($t('labels.form.actions.edit'))">
                            <feather type="edit" />
                        </router-link>
                        <button
                            class="btn btn-sm btn-square btn-success"
                            type="button"
                            :disabled="appUpdate.published_at !== null"
                            @click="publishAppUpdate(appUpdate)"
                            v-tooltip="
                                appUpdate.published_at === null ? getTooltip('Publier') : getTooltip('Cette note de mise à jour a déjà été publiée')
                            ">
                            <feather type="upload-cloud" />
                        </button>
                        <button
                            class="btn btn-sm btn-square btn-danger"
                            type="button"
                            @click="deleteAppUpdate(appUpdate)"
                            v-tooltip="getTooltip($t('labels.form.actions.delete'))">
                            <feather type="trash-2" />
                        </button>
                    </td>
                </template>
            </ns-table>
            <p v-else class="font-italic">Aucune note de mise à jour n'a encore été créée</p>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import NsTable from "../../../components/Datatable/NsTable.vue";
import NsTh from "../../../components/Datatable/NsTh.vue";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";

export default {
    name: "AppUpdatesList",
    data() {
        return {
            loading: false,
            appUpdatesList: [],
        };
    },
    mixins: [ModuleTypesEnum],
    methods: {
        fetchAppUpdatesList() {
            this.loading = true;

            this.httpGet("/api/app_updates")
                .then((response) => {
                    if (response !== false) {
                        this.appUpdatesList = response.data.data;
                    }
                })
                .finally(() => (this.loading = false));
        },
        publishAppUpdate(appUpdate) {
            if (
                !confirm(`Êtes vous sûr de vouloir publier la note de mise à jour du ${this.displayDate(appUpdate.updated_date, this.DATE_SHORT)} ?`)
            ) {
                return;
            }

            this.loading = true;

            this.httpPut(`/api/admin/app_updates/${appUpdate.id}/publish`).then((response) => {
                if (response !== false) {
                    this.fetchAppUpdatesList();
                } else {
                    this.loading = false;
                }
            });
        },
        deleteAppUpdate(appUpdate) {
            if (
                !confirm(
                    `Êtes vous sûr de vouloir supprimer la note de mise à jour du ${this.displayDate(appUpdate.updated_date, this.DATE_SHORT)} ?`
                )
            ) {
                return;
            }

            this.loading = true;

            this.httpDelete(`/api/admin/app_updates/${appUpdate.id}`).then((response) => {
                if (response !== false) {
                    this.fetchAppUpdatesList();
                } else {
                    this.loading = false;
                }
            });
        },
    },
    components: {
        LoaderComponent,
        NsTable,
        NsTh,
    },
    created() {
        this.fetchAppUpdatesList();
    },
};
</script>
