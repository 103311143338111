var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-md-7" },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentCity,
              expression: "currentCity",
            },
          ],
          staticClass: "form-control mb-2",
          attrs: { type: "text" },
          domProps: { value: _vm.currentCity },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.currentCity = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _vm._l(_vm.deliveryArea.cities.data, function (city, index) {
          return _c(
            "span",
            { key: index, staticClass: "tag is-rounded mb-1" },
            [
              _vm._v(
                "\n            " +
                  _vm._s(city.name) +
                  " (" +
                  _vm._s(city.postcode) +
                  ")\n            "
              ),
              _c("button", {
                staticClass: "delete",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.deliveryArea.cities.data.splice(index, 1)
                  },
                },
              }),
            ]
          )
        }),
        _vm._v(" "),
        _vm.errorCity
          ? _c("span", { staticClass: "d-block text-danger" }, [
              _vm._v(_vm._s(_vm.errorCity)),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.loadingPlaceDetails
          ? _c("LoaderComponent")
          : _vm.availableCities.length > 0
          ? _c(
              "div",
              { staticClass: "dropdown-menu prename-field shadow show p-0" },
              [
                _c(
                  "ul",
                  { staticClass: "list-group" },
                  _vm._l(_vm.availableCities, function (city, index) {
                    return _c(
                      "li",
                      { key: index, staticClass: "list-group-item p-0" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "d-block p-3",
                            attrs: { href: "javascript:" },
                            on: {
                              click: function ($event) {
                                return _vm.addCity(city)
                              },
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(city.description))])]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("ShowErrors", {
          staticClass: "d-block",
          attrs: { errors: _vm.errors, errorKey: "cities" },
        }),
        _vm._v(" "),
        _c("ShowErrors", {
          staticClass: "d-block",
          attrs: { errors: _vm.errors, errorKey: "cities.*" },
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1 mb-2" }, [
      _c("label", { staticClass: "mb-0" }, [
        _vm._v("Choix des villes "),
        _c("small", [_vm._v("*")]),
      ]),
      _vm._v(" "),
      _c("small", { staticClass: "d-block text-muted" }, [
        _vm._v("Merci de privilégier la recherche par code postal"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }