var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", {}, [
      _c("div", { staticClass: "row m-0" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _vm.command && _vm.command.status === "canceled"
              ? _c(
                  "div",
                  {
                    staticClass: "bg-danger text-center text-white p-1",
                    staticStyle: { "border-radius": "100px" },
                  },
                  [
                    _vm._v(
                      "\n                    Attention cette commande a été annulée\n                "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.command && _vm.command.status === "collected"
              ? _c(
                  "div",
                  {
                    staticClass: "bg-danger text-center text-white p-1",
                    staticStyle: { "border-radius": "100px" },
                  },
                  [
                    _vm._v(
                      "\n                    Attention, commande déjà scannée\n                "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("show-click-and-collect-command", {
              attrs: {
                command_id: _vm.command_id,
                restaurant_id: _vm.restaurant_id,
              },
              on: {
                "command-fetched": function ($event) {
                  ;(_vm.command = $event), _vm.$emit("command-get", $event)
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.showValidateButton
      ? _c("div", [
          _c(
            "button",
            {
              staticClass: "btn btn-success btn-sm btn-circle",
              class: {
                disabled:
                  !_vm.command ||
                  !["to_prepare", "ready"].includes(_vm.command.status),
              },
              attrs: {
                disabled:
                  !_vm.command ||
                  !["to_prepare", "ready"].includes(_vm.command.status),
              },
              on: { click: _vm.validate },
            },
            [_vm._v("\n            Valider\n        ")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }