import axios from "axios";

export default {
    data() {
        return {
            seatingPlanPrint: {
                isPrinting: false,
            },
        };
    },
    methods: {
        printSelectedRooms(data) {
            this.seatingPlanPrint.isPrinting = true;

            // Using $nextTick instead of setTimeout does not update front (weird)
            setTimeout(() => {
                const roomsToPrint = (this.rooms || this.konva.rooms)
                    .map((r, index) => {
                        return {
                            ...r,
                            baseIndex: index,
                        };
                    })
                    .filter((r) => data.roomsIds.includes(r.id));

                this.printRoom(roomsToPrint, this.konva.selectedRoom, []);
            }, 100);
        },
        // Recursive
        printRoom(roomsToPrint, oldSelectedRoom, rooms) {
            const currentRoomToPrint = roomsToPrint[0];

            this.konva.selectedRoom = currentRoomToPrint.baseIndex;

            this.$nextTick(() => {
                const url = (this.mainStage || this.konva.mainStage).toDataURL({
                    quality: 1,
                    pixelRatio: 2,
                });

                rooms.push({
                    url,
                    roomName: currentRoomToPrint.name,
                });

                this.$nextTick(() => {
                    if (roomsToPrint.length > 1) {
                        roomsToPrint.splice(0, 1);

                        this.printRoom(roomsToPrint, oldSelectedRoom, rooms);
                    } else {
                        this.konva.selectedRoom = oldSelectedRoom;

                        this.sendImgsForPdfComputing(rooms);
                    }
                });
            });
        },
        sendImgsForPdfComputing(rooms) {
            const restaurantId = this.restaurantId || this.$route.params.restaurant_id;

            axios
                .post(
                    `/api/restaurants/${restaurantId}/printSeatingPlan`,
                    {
                        rooms,
                        date: this.date ? this.date.toISODate() : null,
                        serviceName: this.selectedService ? this.selectedService.name : null,
                    },
                    { responseType: "arraybuffer" }
                )
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));

                    window.open(url, "_blank");
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.seatingPlanPrint.isPrinting = false;
                });
        },
    },
};
