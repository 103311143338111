var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tbody",
    _vm._l(_vm.filteredDataSource, function (data, index) {
      return _c(
        "tr",
        {
          key: _vm.filteredDataSource[_vm.keyColumn] || index,
          class: {
            pointer: _vm.clickable,
            clickable: _vm.clickable,
            new: data.new,
            updated: data.updated,
          },
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.$parent.$emit("row-selected", { event: $event, data })
            },
          },
        },
        [
          _c(
            "td",
            [
              _c("inline-client", {
                attrs: {
                  client: data[_vm.attribute].client,
                  "module-enum": _vm.MODULE_TYPE_BOOKING,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("td", [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.displayDate(
                    data[_vm.attribute].reservation_datetime,
                    _vm.DATE_SHORT
                  )
                ) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("td", [
            _vm._v(
              "\n            " +
                _vm._s(_vm.displayHour(data[_vm.attribute].slot.hour_start)) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("td", [
            data[_vm.attribute].nb_children
              ? _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getTooltip(
                          _vm.displayPax(
                            data[_vm.attribute].total_nb_pers,
                            data[_vm.attribute].nb_children
                          )
                        ),
                        expression:
                          "getTooltip(displayPax(data[attribute].total_nb_pers, data[attribute].nb_children))",
                      },
                    ],
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(data[_vm.attribute].total_nb_pers) +
                        "(" +
                        _vm._s(data[_vm.attribute].nb_children) +
                        ")\n            "
                    ),
                  ]
                )
              : _c("span", [
                  _vm._v(
                    "\n                " +
                      _vm._s(data[_vm.attribute].total_nb_pers) +
                      "\n            "
                  ),
                ]),
          ]),
          _vm._v(" "),
          _c("td", [
            _vm._v(
              "\n            " +
                _vm._s(_vm.displayDate(data.created_at, _vm.DATE_SHORT)) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("td", [
            data.status === _vm.LOG_MAIL_NOT_DELIVERED.value
              ? _c("span", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$tl(
                          "labels.booking.reservations.mailLogs.permanent_failed",
                          _vm.restaurantId,
                          {
                            type: _vm.getMailLogLabel(data.type),
                          }
                        )
                      ) +
                      "\n            "
                  ),
                ])
              : data.status === _vm.LOG_MAIL_PENDING.value
              ? _c("span", { staticClass: "text-warning" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$tl(
                          "labels.booking.reservations.mailLogs.pending",
                          _vm.restaurantId,
                          { type: _vm.getMailLogLabel(data.type) }
                        )
                      ) +
                      "\n            "
                  ),
                ])
              : data.status === _vm.LOG_MAIL_DELIVERED.value
              ? _c("span", [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$tl(
                          "labels.booking.reservations.mailLogs.sent_confirmed",
                          _vm.restaurantId,
                          {
                            type: _vm.getMailLogLabel(data.type),
                          }
                        )
                      ) +
                      "\n            "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("td", [
            _vm.isFragmentLoading[data.id]
              ? _c(
                  "div",
                  {
                    staticClass: "spinner-border feather-20",
                    attrs: { role: "status" },
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Loading..."),
                    ]),
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-outline-secondary btn-square",
                    attrs: {
                      type: "button",
                      disabled: _vm.isDisabled || !data.should_be_send_again,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.$emit("send-mail", data)
                      },
                    },
                  },
                  [_c("feather", { attrs: { type: "send" } })],
                  1
                ),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }