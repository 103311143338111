var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-items-center" }, [
    _c("input", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showInput,
          expression: "showInput",
        },
      ],
      ref: "noshowTxtCopyUrl",
      staticClass: "form-control",
      attrs: { type: "text", readonly: "" },
      domProps: { value: _vm.textToCopy },
    }),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.currentCopyTooltip,
            expression: "currentCopyTooltip",
          },
        ],
        staticClass: "btn",
        attrs: { type: "button" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.copy.apply(null, arguments)
          },
        },
      },
      [
        _c("i", {
          staticClass: "far fa-copy",
          staticStyle: { color: "#666666" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }