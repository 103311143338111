<template>
    <div>
        <div class="one-menu mb-3" v-for="(o, oIndex) in available_general_options">
            <div class="row text-left mb-0">
                <div class="col-sm-8">
                    <label class="mb-1">{{ o.name }} - {{ formatCurrency(o.price, getRestaurantCurrency(restaurantId)) }}</label>
                    <small class="d-block text-muted mb-2">
                        {{ o.description }}
                    </small>
                </div>
                <div class="col-sm-4">
                    <div v-if="o.quantity_type === 'mandatory_for_each'">
                        <select
                            :ref="`ref_general_option_${o.id}`"
                            class="custom-select"
                            :disabled="readonly"
                            @change="chooseGeneralOption(o, $event)">
                            <option value="0">--</option>
                            <option :value="nb_pers_total">
                                {{ nb_pers_total }}
                            </option>
                        </select>
                    </div>

                    <select
                        style="height: 40px; padding: 10px 15px"
                        v-else-if="o.quantity_type === 'limited_by_pers'"
                        class="custom-select"
                        :disabled="readonly"
                        :ref="`ref_general_option_${o.id}`"
                        @change="chooseGeneralOption(o, $event)">
                        <option value="0">0</option>
                        <option v-for="(option, index) in nb_pers_total" :value="index * 1 + 1">
                            {{ index * 1 + 1 }}
                        </option>
                    </select>

                    <input
                        style="height: 40px; padding: 10px 15px"
                        class="form-control"
                        type="number"
                        step="1"
                        :disabled="readonly"
                        min="0"
                        value="0"
                        :ref="`ref_general_option_${o.id}`"
                        v-else-if="o.quantity_type === 'unlimited'"
                        @change="chooseGeneralOption(o, $event)" />
                </div>
            </div>
        </div>
        <template v-if="available_general_options_trashed">
            <div class="sepa"></div>
            <div class="one-menu mb-3" v-for="(o, oIndex) in deleted_general_options">
                <div class="row text-left mb-0">
                    <div class="col-sm-8">
                        <label class="mb-1"
                            >{{ available_general_options_trashed.find((mt) => o.id === mt.id).name }} -
                            {{
                                formatCurrency(
                                    available_general_options_trashed.find((mt) => o.id === mt.id).price,
                                    getRestaurantCurrency(restaurantId)
                                )
                            }}</label
                        >
                        <small class="d-block text-muted mb-2">
                            {{ available_general_options_trashed.find((mt) => o.id === mt.id).description }}
                        </small>
                    </div>
                    <div class="col-sm-4">
                        <div v-if="available_general_options_trashed.find((mt) => o.id === mt.id).quantity_type === 'mandatory_for_each'">
                            <select
                                :ref="`ref_general_option_${o.id}`"
                                class="custom-select"
                                :disabled="readonly"
                                @change="chooseGeneralOption(o, $event)">
                                <option value="0">--</option>
                                <option :value="nb_pers_total">
                                    {{ nb_pers_total }}
                                </option>
                            </select>
                        </div>

                        <select
                            style="height: 40px; padding: 10px 15px"
                            v-else-if="available_general_options_trashed.find((mt) => o.id === mt.id).quantity_type === 'limited_by_pers'"
                            class="custom-select"
                            :disabled="readonly"
                            :ref="`ref_general_option_${o.id}`"
                            @change="chooseGeneralOption(o, $event)">
                            <option value="0">0</option>
                            <option v-for="(option, index) in nb_pers_total" :value="index * 1 + 1">
                                {{ index * 1 + 1 }}
                            </option>
                        </select>

                        <input
                            style="height: 40px; padding: 10px 15px"
                            class="form-control"
                            type="number"
                            step="1"
                            min="0"
                            :disabled="readonly"
                            value="0"
                            :ref="`ref_general_option_${o.id}`"
                            v-else-if="available_general_options_trashed.find((mt) => o.id === mt.id).quantity_type === 'unlimited'"
                            @change="chooseGeneralOption(o, $event)" />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import _ from "lodash";
import menusReservation from "./menusReservation.vue";

export default {
    components: { menusReservation },
    data() {
        return {};
    },
    props: {
        available_general_options: {
            required: true,
        },
        available_general_options_trashed: {
            default: null,
        },
        value: {
            required: true,
        },
        nb_pers_total: {
            required: true,
            type: Number,
        },
        restaurantId: {
            type: Number,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.$nextTick(() => {
                this.initInputs();
            });
        });
    },
    computed: {
        deleted_general_options() {
            return Object.values(this.choosen_general_options).filter((cm) => !this.available_general_options.some((am) => am.id === cm.id));
        },
        choosen_general_options: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        initInputs() {
            if (this.choosen_general_options) {
                for (var optionKey of Object.keys(this.choosen_general_options)) {
                    var option = this.choosen_general_options[optionKey];
                    if (this.$refs[`ref_general_option_${option.id}`] && this.$refs[`ref_general_option_${option.id}`][0]) {
                        this.$refs[`ref_general_option_${option.id}`][0].value = option.value.toString();
                    }
                }
            }
        },
        chooseGeneralOption(option, event) {
            let value = Number.parseInt(event.target.value);
            let tmp = this.choosen_general_options;

            if (isNaN(value) || value < 0) {
                event.target.value = 0;
                value = 0;
            }

            if (value > 0) {
                let newVal = undefined;
                tmp[`id_${option.id}`] !== undefined
                    ? ((newVal = this.choosen_general_options[`id_${option.id}`]), (newVal.value = value))
                    : (newVal = { id: option.id, value, price: option.price });
                tmp[`id_${option.id}`] = newVal;
            } else {
                tmp = _.omit(tmp, `id_${option.id}`);
            }
            this.choosen_general_options = tmp;
        },
    },
};
</script>
