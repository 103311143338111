var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("loader-component")
        : _c("div", { staticClass: "row" }, [
            _vm.users.length === 0
              ? _c("div", { staticClass: "col-12" }, [
                  _c("span", [
                    _vm._v(
                      "Ce code promo n'est pas restreint à un certain nombre d'utilisateurs"
                    ),
                  ]),
                ])
              : _c(
                  "div",
                  { staticClass: "col-12" },
                  _vm._l(_vm.users, function (user) {
                    return _c(
                      "div",
                      { key: user.id, staticClass: "row my-2" },
                      [
                        _c("div", { staticClass: "col-12" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.displayUser(user)) +
                              "\n                "
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }