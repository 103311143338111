<template>
    <div class="nav-btn-2 mx-md-5" v-if="restaurant_id && user">
        <div class="btn-group">
            <button
                type="button"
                class="btn btn-sm btn-outline-secondary btn-nav-mob btn-circle btn-pre-site"
                :disabled="!website_enabled || !has_right_to_read_config"
                @click="previewWebsite">
                Pré-visualiser le site
            </button>
            <button
                type="button"
                class="btn btn-sm btn-success btn-nav-mob btn-circle ml-2"
                :disabled="!has_right_to_publish_config || (step < 4 && !already_puslished) || isPublishing"
                @click="saveAndPublish">
                Enregistrer et publier
            </button>
            <button
                type="button"
                class="btn btn-sm btn-success btn-nav-mob btn-circle ml-2"
                :disabled="step < 5 && !already_puslished"
                @click="viewWebsite">
                Voir mon site
            </button>
        </div>
    </div>
</template>

<script>
import SettingsStepsEnum from "../../mixins/enums/website/SettingsStepsEnum";

export default {
    name: "WebsiteTopbarActions",
    data() {
        return {};
    },
    mixins: [SettingsStepsEnum],
    props: {
        user: {
            required: true,
        },
        isSidebarCollapsed: {
            default: false,
        },
    },
    computed: {
        isPublishing() {
            return this.$store.getters["websites/is_publishing"];
        },
        website_settings() {
            return this.$store.getters["websites/all_settings"];
        },
        already_puslished() {
            if (!this.website_settings) return false;
            return this.website_settings.already_puslished;
        },
        step() {
            if (!this.website_settings || !this.website_settings.deploy_step) return 1;
            switch (this.website_settings.deploy_step) {
                case this.SETTINGS_STEP_WEBSITE_SETTINGS.value:
                    return 1;
                case this.SETTINGS_STEP_GET_TOKEN.value:
                    return 2;
                case this.SETTINGS_STEP_VERIFY.value:
                    return 3;
                case this.SETTINGS_STEP_PUBLISH.value:
                    return 4;
                case this.SETTINGS_STEP_PUBLISHED.value:
                    return 5;
            }
            return 1;
        },
        website_enabled() {
            return this.$store.getters["websites/website_enabled"];
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_read_config() {
            return this.rights.includes("website.config.read");
        },
        has_right_to_update_config() {
            return this.rights.includes("website.config.update");
        },
        has_right_to_publish_config() {
            return this.rights.includes("website.config.publish");
        },
        unsaved_changes() {
            return this.$store.getters["website/unsaved_changes"];
        },
    },
    methods: {
        viewWebsite() {
            if (!this.website_settings) return;
            window.open(`http://${this.website_settings.prefix_identifier}${this.website_settings.domain_name}`);
        },
        previewWebsite() {
            if (
                this.has_right_to_update_config &&
                !confirm(
                    "Avant de prévisualiser votre site web, assurez-vous d'avoir enregistré toutes les images et les réglages du site."
                )
            )
                return;
            window.open(`/api/website/${this.restaurant_id}/website/preview`);
        },
        saveAndPublish() {
            const catchFct = (error) => {
                this.$store.dispatch("websites/setIsPublishing", false);
                this.isSaving = false;
                this.$notify({
                    group: "notification",
                    type: "error",
                    title: this.getErrorMsgFromErrorResponse(error),
                });
            };
            this.$store.dispatch("websites/setIsPublishing", true);
            this.$store
                .dispatch("websites/updateWebsiteSettings", {
                    restaurant_id: this.restaurant_id,
                    params: {
                        ...this.website_settings,
                        show_address: this.website_settings.show_address ? false : true,
                        section: "publish",
                    },
                })
                .then(() => {
                    this.$store
                        .dispatch("websites/publish", {
                            restaurant_id: this.restaurant_id,
                            params: {
                                ...this.website_settings,
                                show_address: this.website_settings.show_address ? false : true,
                                section: "publish",
                            },
                        })
                        .then((response) => {
                            this.website_settings.deploy_step = this.SETTINGS_STEP_PUBLISHED.value;
                            this.$store.dispatch("websites/setIsPublishing", false);
                            this.$notify({
                                group: "notification",
                                type: "success",
                                title: response.data.message,
                            });
                            setInterval(() => {
                                window.location.reload();
                            }, 500);
                        })
                        .catch(catchFct);
                })
                .catch(catchFct);
        },
    },
};
</script>
