export default {
    booking: {
        covers: "Covers",
        pax: {
            nb: "Number of covers",
        },
        menus: {
            title: "Menus",
            add: "Add a menu",
            edit: "Edit the menu:",
            mandatory: "Mandatory menu",
            mandatoryFrom: "Mandatory menu from",
            unique: "Single menu",
            uniqueFrom: "Single menu from",
            empty: "No menu for this establishment",
            childrenOnly: "Children only menu",
            externalMenu: {
                externalMenu: "Restaurant menu visualization",
            },
        },
        options: {
            title: "Menu options",
            addMenuOption: "Add a menu option",
            types: {
                menu: "Menu option",
            },
            quantityTypes: {
                limited_by_pers: "Limited to the number of menus",
            },
        },
        closures: {
            empty: "No special closures for this establishment",
            services: "Service(s)",
            concernedServices: "Services involved",
            noServiceForDate: "No services available for the dates selected",
        },
        services: {
            add: "Add a service",
            name: "Service name",
            edit: "Edit the service:",
            duplicate: "Duplicate a service",
            next: "Next service",
            live: "Service in progress",
            createFirst: "Create my first service",
            noOneAvailable: "No service is available",
            startHour: "Service start time",
            endHour: "Service end time",
            maxPax: "Maximum number of covers per service",
            enableMaxPax: "Enable configuration of the number of {term} per slot",
            coversPerSlot: "Covers/slots",
            inCaseFull: "When the service is full",
            showRestaurantService: "Offer the customer services at the following restaurants:",
            takeResaLive: "Take reservations during the service",
            minsBeforeStart: "minutes before the start of the service",
            servicesOnDate: "Services on {date}:",
            recap: "Service summary",
            editDayMaxPax: "Edit service max pax for given day",
            emptyForRestaurant: "No service for this establishment",
            showOnDashboardLong: "Show service on dashboard",
            enableTableRotation: "Enable table rotation",
            averageTimeAtTable: "Average time at table",
            enableAlgorithm: "Enable room filling according to my tables (real PAX)",
            special: {
                empty: "No special openings for this establishment",
            },
            menusAndOptions: {
                title: "Menus and options",
                link: "Link the menus",
                enableFrom: "Menu enabled from",
                disableFrom: "Menu disabled from",
                menus: "Menu(s)",
            },
        },
        reservations: {
            editService: "Edit service",
            table: "Table",
            noTable: "No table assigned",
            status: {
                at_table: "At table",
            },
            edit: {
                table: "Choice of Table(s)",
                tableNotAvailable: "The selected tables are not available for the selected date, slot and duration",
                changeRestaurant: "Allocate to another restaurant",
            },
            export: {
                columns: {
                    table: "Table(s)",
                    menus_and_options: "Menus and options",
                },
            },
            add: {
                chooseMenus: "Choice of menus:",
            },
            grid: {
                columns: {
                    table: "Table",
                    menusAndOptions: "Menus and Options",
                },
            },
        },
        stats: {
            filters: {
                serviceCategory: "Service category",
            },
            pax: {
                title: "Number of covers",
            },
            charts: {
                CAPerMenu: {
                    title: "Turnover by menu type",
                    y: "Menu",
                    tooltip: "No menu sold | 1 menu sold | {count} menus sold",
                    filters: {
                        onlySold: "Show only sold menus",
                    },
                },
                CAPerMenuOption: {
                    title: "Turnover by menu option type",
                    y: "Option",
                    filters: {
                        onlySold: "Show only sold options",
                    },
                },
                CAPerGeneralOption: {
                    title: "Turnover by general option type",
                    y: "Option",
                    filters: {
                        onlySold: "Show only sold options",
                    },
                },
                percentMenusSold: {
                    title: "Percent of menus sold",
                },
                percentMenuOptionsSold: {
                    title: "Percent of menus options sold",
                },
                percentGeneralOptionsSold: {
                    title: "Percent of general options sold",
                },
            },
        },
        globalStats: {
            table: {
                cells: {
                    nameRestaurant: "Restaurant Name",
                },
            },
            reservationCountPerOrigin: {
                title: "Details by Restaurants | Number of Reservations",
            },
            reservationPaxPerOrigin: {
                title: "Details by Restaurants | Number of Covers",
            },
            waitingsList: {
                title: "Details by Restaurants | Waiting List",
            },
        },
        widget: {
            automaticExport: {
                nbMinutes: "Schedule export before service (in minutes)",
                services: "Choose linked services",
            },
        },
        restaurant: {
            closedForSomeService: "Establishment closed today for some services.",
        },
        list: {
            emptyForService: "You have no reservations for this service.",
        },
        seatingPlan: {
            emptyForService: 'You have no reservations today in "{status}" for the "{service}" service.',
            tables: "At table",
            room: {
                disabledForService: "This room is disabled for this service",
                enabledForService: "This room is enabled for this service only",
            },
            columns: {
                services: "Services",
            },
        },
        waitings: {
            mailLogs: {
                type: "available table",
            },
        },
        waitingList: {
            content: {
                menus: "Menus:",
            },
            buttons: {
                seeMenusAndOptions: "See menus and options",
            },
        },
    },
    routes: {
        booking: {
            liveService: "In service",
            kitchen: "In kitchen",
            config: {
                services: "Services",
            },
            services: {
                current: "Current services",
                menus: "Menus",
            },
        },
    },
    notitifications: {
        booking: {
            serviceOver: "Waiting list - service over",
            tableAvailable: "Waiting list - table available",
            restauResaNotPlaced: {
                content:
                    "0 | Due to a table deletion, a reservation could not be placed for {date}. Please place it manually on the table plan. | Due to a table deletion, {count} reservations could not be placed for {date}. Please place them manually on the table plan.",
            },
        },
    },
    settings: {
        menuOptions: "Menu Options",
    },
    form: {
        actions: {
            orderMenus: "Order the menus",
            orderMenusOptions: "Order the menu options",
        },
    },
};
