<template>
    <div class="h-100">
        <div v-if="!isBrowserIE" class="back-off h-100">
            <topbar
                :style="moduleSwitcher.width > 0 ? `margin-left: ${moduleSwitcher.width}px !important` : ''"
                v-if="user && user.role !== 'kitchen'"
                :isSidebarCollapsed="isSidebarCollapsed"
                :restaurants="restaurants"
                :user="user"
                :home="{ name: 'booking.home' }"
                module_type="booking"
                @module-switcher-button-clicked="moduleSwitcher.show = !moduleSwitcher.show"
                :mobileMenuDisplayed="mobileMenuDisplayed"
                @mobile-menu-updated="mobileMenuDisplayed = $event">
                <booking-topbar-actions :isSidebarCollapsed="isSidebarCollapsed" :user="user" />
            </topbar>
            <kitchen-account-topbar v-else :restaurants="restaurants" :user="user" />

            <div class="container-fluid h-100" :style="moduleSwitcher.show == true ? 'overflow-x: hidden;' : ''">
                <div class="row h-100">
                    <module-switcher
                        serviceId="booking"
                        :isSidebarCollapsed="isSidebarCollapsed"
                        :user="user"
                        @open="onModuleSwitcherOpen"
                        @close="closeModuleSwitcher"
                        :show="moduleSwitcher.show" />
                    <sidebar
                        :style="moduleSwitcher.width > 0 ? `margin-left: ${moduleSwitcher.width}px !important` : ''"
                        v-if="user && user.role !== 'kitchen'"
                        :user="user"
                        :restaurants="restaurants"
                        :isSidebarCollapsed="isSidebarCollapsed"
                        :entries="sidebarEntries"
                        :restaurantRouteName="`booking.restaurants.reservations`"
                        :force-show="restaurants.length > 1"
                        @sidebar-collapse-changed="isSidebarCollapsed = $event"
                        @close-menu="mobileMenuDisplayed = false"
                        @reload-router-view="$emit('reload-router-view')">
                    </sidebar>

                    <main
                        role="main"
                        class="pl-0 pr-0 h-100 main"
                        :style="moduleSwitcher.width > 0 ? `margin-left: ${moduleSwitcher.width + 240}px !important` : ''"
                        :class="{
                            'col-md-12 col-lg-12': user && user.role === 'kitchen',
                            'offset-md-3 offset-lg-2 col-md-9 col-lg-10': user && user.role !== 'kitchen' && !isSidebarCollapsed,
                            'offset-md-1 offset-lg-1 col-lg-11 col-md-11': isSidebarCollapsed,
                        }">
                        <notifications style="z-index: 9999" group="notification" />

                        <slot />
                    </main>
                </div>
            </div>
            <AcceptCgvModal :module="MODULE_TYPE_BOOKING" v-if="showAcceptCgvModal" @close="showAcceptCgvModal = false" />
        </div>
        <div v-else style="text-align: center">
            <h1>{{ $tl("errors.browser.invalid.title") }}</h1>
            <p v-html="$t('errors.browser.invalid.infos')"></p>
        </div>
    </div>
</template>

<script>
import axios from "axios";
// load components
import Sidebar from "../Sidebar.vue";
import SetServiceFullModal from "../../components/Modals/services/setServiceFullModal";
import Topbar from "../Topbar.vue";
import KitchenAccountTopbar from "./KitchenAccountTopbar";
import BookingTopbarActions from "./TopbarActions";
import ModuleSwitcher from "../ModuleSwitcher";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum.js";
import AcceptCgvModal from "../../components/Modals/CGU/AcceptCgvModal";
import RolesEnum from "../../mixins/enums/RolesEnum.js";

export default {
    props: {
        user: {
            required: true,
        },
    },
    mixins: [ModuleTypesEnum, RolesEnum],
    data: function () {
        return {
            showAcceptCgvModal: false,
            restaurant_id: this.$route.params.restaurant_id,
            submenu: false,
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
            selected_service_id: null,
            showSetServiceFullModal: false,
            servicesNavbar: false,
            creditCountSms: null,
            smsActivated: null,
            isSidebarCollapsed: this.isSidebarCollapsedTablet(),
            moduleSwitcher: {
                show: false,
                height: 0,
                width: 0,
            },
            mobileMenuDisplayed: false,
        };
    },
    computed: {
        restaurantIdParams() {
            if (this.restaurants.length === 1) {
                return { restaurant_id: this.restaurants[0].id };
            }

            return {};
        },
        sidebarEntries() {
            const entries = [
                {
                    to: { name: "booking.home", params: this.restaurantIdParams },
                    titleI18n: "booking.dashboard",
                    icon: "home",
                    exactUrlMatching: true,
                    rights: ["any"],
                },
            ];

            if (!this.$route.params.restaurant_id && this.restaurants.length > 1) {
                entries.push({
                    to: { name: "booking.globalStats", params: this.restaurantIdParams },
                    titleI18n: "booking.globalStats",
                    icon: "bar-chart-2",
                    exactUrlMatching: true,
                    rights: ["any"],
                });

                return entries;
            }

            entries.push(
                {
                    to: { name: "booking.restaurants.reservations", params: this.restaurantIdParams },
                    titleI18n: "booking.listReservations",
                    icon: "calendar",
                    exactUrlMatching: false,
                    rights: ["booking.booking.*"],
                },
                {
                    to: { name: "booking.restaurants.liveservice", params: this.restaurantIdParams },
                    titleI18nLexicon: "booking.liveService",
                    icon: "clock",
                    exactUrlMatching: false,
                    rights: ["booking.booking.*"],
                },
                {
                    to: { name: "booking.restaurants.reservationsCuisine", params: this.restaurantIdParams },
                    titleI18nLexicon: "booking.kitchen",
                    icon: "zap",
                    exactUrlMatching: false,
                    rights: ["booking.booking.*"],
                    shouldBeShownForRestaurant: (restaurantId) => {
                        const restaurant = this.restaurants.find((r) => r.id === Number.parseInt(restaurantId));

                        return restaurant && restaurant.show_to_realize_view;
                    },
                },
                {
                    to: { name: "booking.restaurants.payments", params: this.restaurantIdParams },
                    titleI18n: "booking.payments",
                    icon: "dollar-sign",
                    exactUrlMatching: false,
                    rights: ["booking.payments.*"],
                },
                {
                    to: { name: "booking.restaurants.stats", params: this.restaurantIdParams },
                    titleI18n: "booking.stats",
                    icon: "bar-chart-2",
                    exactUrlMatching: false,
                    rights: ["booking.statistic.*"],
                },
                {
                    to: { name: "booking.clients", params: this.restaurantIdParams },
                    titleI18n: "booking.clients",
                    icon: "users",
                    exactUrlMatching: false,
                    rights: ["default.customer.*"],
                },
                {
                    to: { name: "booking.restaurants.settings", params: this.restaurantIdParams },
                    titleI18n: "booking.settings",
                    icon: "settings",
                    exactUrlMatching: false,
                    rights: ["booking.service.*", "booking.widget.*", "booking.menu.*", "booking.notification.*", "booking.seatingplan.*"],
                },
                {
                    to: {
                        name: "notifications.logs",
                        params: {
                            ...this.restaurantIdParams,
                            module: this.MODULE_TYPE_BOOKING.value,
                        },
                    },
                    titleI18n: "notificationsLogs",
                    icon: "bell",
                    exactUrlMatching: true,
                    rights: ["default.notificationlogs.*"],
                }
            );

            return entries;
        },
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        restaurants() {
            const restaurants = this.$store.getters["restaurants/getRestaurantsByService"]("booking").filter((r) => {
                return this.$store.getters["users/restaurantIds"].includes(r.id);
            });

            if (this.isClubMedOwnerId(this.$store.getters["users/ownerId"])) {
                restaurants.sort((a, b) => a.name.localeCompare(b.name));
            }

            return restaurants;
        },
        isOwner() {
            return this.$store.getters["users/role"] === this.ROLE_OWNER.value;
        },
        isKitchen() {
            return this.$store.getters["users/role"] === this.ROLE_KITCHEN.value;
        },
    },
    created() {
        if (this.isOwner) this.fetchHasAcceptedCgvs();
        this.checkRestaurantId();
        this.$on("show-service-full", this.showSetServiceFull);
    },
    methods: {
        isSidebarCollapsedTablet() {
            return window.innerWidth <= 1024 && window.innerWidth > 767;
        },
        onModuleSwitcherOpen({ width, height }) {
            this.moduleSwitcher.width = width;
            this.moduleSwitcher.height = height;
        },
        closeModuleSwitcher() {
            this.moduleSwitcher.width = 0;
            this.moduleSwitcher.height = 0;
            this.moduleSwitcher.show = false;
        },
        checkRestaurantId() {
            this.restaurant_id = this.$route.params.restaurant_id;
            if (!this.$route.params.restaurant_id && this.restaurants.length == 1) {
                this.restaurant_id = this.restaurants[0].id;
            }
        },
        logout() {
            axios
                .post("/logout")
                .then((response) => {
                    window.location.href = response.data.redirect_url || response.headers.location || "/login";
                })
                .catch(() => {});
        },
        showSetServiceFull(data) {
            this.selected_service_id = data.service_id;
            this.showSetServiceFullModal = true;
        },
        selectRestaurant(event) {
            const name = this.isKitchen ? "booking.restaurants.reservationsCuisine" : "booking.restaurants.reservations";

            this.$router.push({ name, params: { restaurant_id: event.target.value } });
        },
        fetchHasAcceptedCgvs() {
            this.loading = true;

            return axios
                .get(`/api/accepted_cgvs/modules/${this.MODULE_TYPE_BOOKING.value}`)
                .then((response) => {
                    this.loading = false;
                    this.showAcceptCgvModal = !response.data.result;
                })
                .catch(() => (this.loading = false));
        },
    },
    watch: {
        "$route.params.restaurant_id": function (id) {
            this.checkRestaurantId();
        },
    },
    components: {
        ModuleSwitcher,
        SetServiceFullModal,
        Sidebar,
        Topbar,
        KitchenAccountTopbar,
        AcceptCgvModal,
        BookingTopbarActions,
    },
};
</script>
