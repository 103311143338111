<template>
    <div class="pr-0 pb-5">
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else>
                <div class="row m-0">
                    <div class="col-12">
                        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                            <h5 class="title mt-2">{{ $tl("labels.routes.booking.services.current", restaurant_id) }}</h5>
                            <button
                                :disabled="!has_right_to_create_service"
                                @click="showAddServiceModal = true"
                                class="btn btn-sm btn-success btn-circle">
                                <feather type="plus"></feather>
                                <span>{{ $tl("labels.booking.services.add", restaurant_id) }}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="formErrors" class="alert alert-danger">
                    <p v-for="error in formErrors">
                        {{ error }}
                    </p>
                </div>

                <div v-if="formSuccess" class="alert alert-success">
                    {{ formSuccess }}
                </div>
                <div class="row m-0">
                    <div class="col-12">
                        <table v-if="services && services.length > 0" class="table table-sm table-striped border-bottom">
                            <thead class="border-bottom">
                                <tr>
                                    <th>{{ $tl("labels.booking.services.category") }}</th>
                                    <th>{{ $tl("labels.form.name") }}</th>
                                    <th>{{ $tl("labels.booking.services.activeDays") }}</th>
                                    <th>{{ $tl("labels.booking.services.pax") }}</th>
                                    <th class="text-center" v-tooltip="getTooltip($tl('labels.booking.services.showOnDashboardLong', restaurant_id))">
                                        {{ $tl("labels.booking.services.showOnDashboard") }}
                                    </th>
                                    <th>{{ $tl("labels.form.actions.title") }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="service in services" :key="service.id">
                                    <td class="first-letter-cap">
                                        {{ getServiceCategoryLabel(service) }}
                                    </td>
                                    <td>
                                        {{ service.name }}
                                    </td>
                                    <td class="service-days">
                                        <ServicesDays :days="service.days"></ServicesDays>
                                    </td>
                                    <td>
                                        {{ service.max_pax }}
                                    </td>
                                    <td class="text-center">
                                        <label class="container-box mr-0" style="width: initial">
                                            <input
                                                type="checkbox"
                                                v-model="service.show_on_dashboard"
                                                :disabled="!has_right_to_update_service || updatingShowOnDashboard.includes(service.id)"
                                                @change="toggleShowOnDashboard(service)" />
                                            <span
                                                class="checkmark mr-1 mt-0"
                                                :class="{
                                                    disabled: !has_right_to_update_service || updatingShowOnDashboard.includes(service.id),
                                                }"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <router-link
                                            :to="{
                                                name: 'booking.restaurants.settings.services.edit',
                                                params: {
                                                    restaurant_id: service.restaurant_id,
                                                    service_id: service.id,
                                                },
                                            }"
                                            class="btn btn-sm btn-success btn-square"
                                            v-tooltip="{
                                                delay: { show: 400, hide: 0 },
                                                content: $t('labels.form.actions.edit'),
                                            }">
                                            <feather type="edit" />
                                        </router-link>
                                        <span class="none-mobile">
                                            <router-link
                                                :to="{
                                                    name: 'booking.restaurants.settings.services.add',
                                                    params: {
                                                        restaurant_id: restaurant_id,
                                                        service_id: service.id,
                                                        isDuplicating: true,
                                                    },
                                                }"
                                                class="btn btn-sm btn-success btn-square"
                                                v-tooltip="{
                                                    delay: { show: 400, hide: 0 },
                                                    content: $t('labels.form.actions.duplicate'),
                                                }"
                                                :class="{ disabled: !has_right_to_create_service }">
                                                <feather type="copy" />
                                            </router-link>
                                            <button
                                                v-if="!service.has_future_reservations"
                                                :disabled="!has_right_to_delete_service"
                                                @click="deleteService($event, service)"
                                                class="btn btn-sm btn-danger btn-square"
                                                v-tooltip="{
                                                    delay: { show: 400, hide: 0 },
                                                    content: $t('labels.form.actions.delete'),
                                                }">
                                                <feather type="trash-2" />
                                            </button>
                                            <button
                                                v-else
                                                class="btn btn-sm btn-secondary btn-square"
                                                v-tooltip="getTooltip($tl('infos.booking.services.cantDelete', restaurant_id))">
                                                <feather type="trash-2" />
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-else>
                            <em>{{ $tl("labels.booking.services.emptyForRestaurant", restaurant_id) }}</em>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <add-service-modal v-if="showAddServiceModal" @close="showAddServiceModal = false" @service-added="fetchData"></add-service-modal>
    </div>
</template>

<script>
import axios from "axios";
import ServicesDays from "../../../../components/services/ServiceDaysComponent.vue";
import LoaderComponent from "../../../../components/LoaderComponent.vue";
import AddServiceModal from "../../../../components/Modals/services/addServiceModal.vue";
import LangsEnum from "../../../../mixins/enums/LangsEnum";

export default {
    data() {
        return {
            loading: false,
            error: null,
            formLoading: null,
            formSuccess: null,
            formErrors: null,
            services: null,
            restaurant_id: null,
            restaurant: {
                id: null,
                services: null,
            },
            showAddServiceModal: false,
            updatingShowOnDashboard: [],
        };
    },
    mixins: [LangsEnum],
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_service() {
            return this.rights.includes("booking.service.create");
        },
        has_right_to_update_service() {
            return this.rights.includes("booking.service.update");
        },
        has_right_to_delete_service() {
            return this.rights.includes("booking.service.delete");
        },
    },
    methods: {
        fetchData() {
            this.error = this.restaurant = null;
            this.loading = true;
            this.restaurant_id = this.$route.params.restaurant_id;

            axios
                .get("/api/restaurants/" + this.restaurant_id + "/services")
                .then((response) => {
                    this.services = response.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        duplicateService(event, service) {
            this.loading = true;
            let duplicateServiceId = service.id;

            axios
                .post(`/api/restaurants/${this.restaurant_id}/services/${duplicateServiceId}`)
                .then(() => {
                    this.notifySuccess(null, this.$tl("success.booking.services.duplicated", this.restaurant_id));

                    this.fetchData();
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => (this.loading = false));
        },
        deleteService: function (event, service) {
            var delete_service_id = service.id;
            let confirmString = this.$tl("questions.booking.services.delete", this.restaurant_id);
            if (service.has_future_reservations) {
                confirmString = `${this.$tl("infos.booking.services.hasFutureReservations", this.restaurant_id)}\n${confirmString}`;
            }
            if (confirm(confirmString)) {
                this.loading = true;

                axios
                    .delete(`/api/restaurants/${this.restaurant_id}/services/${delete_service_id}`)
                    .then(() => {
                        this.notifySuccess(null, this.$tl("success.booking.services.deleted", this.restaurant_id));

                        this.fetchData();
                    })
                    .catch((error) => {
                        this.loading = false;

                        this.notifyError(error);
                    });
            }
        },
        toggleShowOnDashboard(service) {
            if (!this.has_right_to_update_service || this.updatingShowOnDashboard.includes(service.id)) {
                return;
            }

            this.updatingShowOnDashboard.push(service.id);

            const toggle = service.show_on_dashboard ? "enable" : "disable";

            this.httpPut(`/api/restaurants/${this.restaurant_id}/services/showOnDashboard/${service.id}/${toggle}`, {}, { handleResolve: false })
                .then((response) => {
                    if (response === false) {
                        service.show_on_dashboard = !service.show_on_dashboard;
                    }
                })
                .finally(() => {
                    const index = this.updatingShowOnDashboard.findIndex((id) => id === service.id);

                    if (index !== -1) {
                        this.updatingShowOnDashboard.splice(index, 1);
                    }
                });
        },
    },
    created() {
        this.fetchData();
    },
    watch: {
        "$route.params.restaurant_id": function (id) {
            this.fetchData();
        },
    },
    components: {
        ServicesDays,
        LoaderComponent,
        AddServiceModal,
    },
};
</script>
