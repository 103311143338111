<template>
    <div class="d-flex align-items-center">
        <input v-show="showInput" type="text" class="form-control" readonly ref="noshowTxtCopyUrl" :value="textToCopy" />
        <button type="button" @click.prevent.stop="copy" class="btn" v-tooltip="currentCopyTooltip">
            <i style="color: #666666" class="far fa-copy"></i>
        </button>
    </div>
</template>
<script>
export default {
    data() {
        return {
            currentCopyTooltip: {
                shown: false,
                html: false,
                content: this.$tl("labels.copy"),
            },
            response: {
                success: this.$t("success.common.copy", { element: this.element }),
                error: this.$t("errors.common.copy.default", { element: this.element }),
            },
        };
    },
    props: {
        textToCopy: {
            default: null,
            type: String,
        },
        element: {
            required: true,
            type: String,
        },
        showInput: {
            default: true,
        },
    },
    methods: {
        updateCurrentTooltip(value) {
            this.currentCopyTooltip = _.assign(this.currentCopyTooltip, value);

            setTimeout(() => {
                this.currentCopyTooltip = _.assign(this.currentCopyTooltip, {
                    triggers: ["hover", "focus"],
                    content: this.$tl("labels.copy"),
                    delay: 0,
                });
            }, 2000); // 500 more than hide
        },
        copyWithExec(txtCopy) {
            txtCopy.select();
            txtCopy.setSelectionRange(0, 99999); // For mobile devices

            try {
                if (!document.execCommand("copy")) {
                    throw "Can't copy";
                }

                this.updateCurrentTooltip({
                    content: this.response.success,
                    delay: { show: 0, hide: 1500 },
                });
            } catch (err) {
                if (!window.isSecureContext) {
                    this.updateCurrentTooltip({
                        content: this.$t("errors.copy.notSecure", { element: $this.element }),
                    });
                }

                if (window.isSecureContext) {
                    this.updateCurrentTooltip({ content: this.response.error });
                }
            }

            window.getSelection().removeAllRanges();
        },
        copyWithNavigator(txtToCopy) {
            navigator.clipboard.writeText(txtToCopy).then(
                () => {
                    this.updateCurrentTooltip({
                        content: this.response.success,
                        delay: { hide: 1500 },
                    });
                },
                () => {
                    if (navigator.permissions) {
                        navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
                            if (result.state !== "granted" && result.state !== "prompt") {
                                return this.updateCurrentTooltip({
                                    content: this.$tl("errors.copy.notActive"),
                                });
                            }

                            this.updateCurrentTooltip({ content: this.response.error });
                        });
                    }
                }
            );
        },
        copy() {
            let txtCopy = this.$refs.noshowTxtCopyUrl;
            if (navigator && navigator.clipboard) {
                return this.copyWithNavigator(txtCopy.value);
            }
            // Deprecated method
            return this.copyWithExec(txtCopy);
        },
    },
};
</script>
