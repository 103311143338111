<template>
    <nav class="navbar navbar-dark justify-content-between fixed-top flex-md-nowrap p-0 bg-light">
        <router-link :to="url" class="shadow-none navbar-brand mr-0 col-lg-2 col-md-3">
            <img class="img-logo display-sid-min" src="/images/logo-lead-extend.png" style="width: 160px" v-if="!isDevelopement()" />
            <img class="img-logo display-sid-min" src="/images/logo-lead-extend.png" style="width: 160px" v-else />
        </router-link>
        <div>
            <ShowNotificationsComponent
                v-if="isCourier"
                :showNotifications="notifications.show"
                :module_type="MODULE_TYPE_CLICK_AND_COLLECT.value"
                @open="notifications.show = true"
                @close="notifications.show = false" />

            <a
                data-test-id="btn-logout"
                class="logout-default"
                href="#"
                @click.prevent="logout"
                v-tooltip="{ delay: { show: 400, hide: 0 }, content: 'Déconnexion' }">
                <feather type="log-out" class="feather-22 text-danger mr-3" :class="{ 'mt-1': isCourier }"></feather>
            </a>
        </div>
    </nav>
</template>

<script>
import RolesEnum from "../../mixins/enums/RolesEnum";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum";
import ShowNotificationsComponent from "../../components/notifications/ShowNotificationsComponent.vue";

export default {
    name: "topbar",
    props: {
        url: {
            default: () => {
                return { name: "home" };
            },
        },
    },
    mixins: [RolesEnum, ModuleTypesEnum],
    data() {
        return {
            notifications: {
                show: false,
            },
        };
    },
    methods: {
        logout() {
            axios
                .post("/logout")
                .then((response) => {
                    window.location.href = response.data.redirect_url || response.headers.location || "/login";
                })
                .catch(() => {});
        },
    },
    computed: {
        isCourier() {
            return this.$store.getters["users/role"] === this.ROLE_COURIER.value;
        },
    },
    components: {
        ShowNotificationsComponent,
    },
};
</script>
