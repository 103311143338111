var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      on: {
        close: function ($event) {
          $event.stopPropagation()
          return _vm.closeFromModal.apply(null, arguments)
        },
      },
    },
    [
      _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Utilisateurs ayant utilisé le code promo"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _c("promo-code-used-users-list", {
            attrs: { "promo-code": _vm.promoCode },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "modal-default-button btn btn-secondary btn-circle",
            attrs: { type: "button" },
            on: { click: _vm.close },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("labels.form.actions.close")) +
                "\n        "
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }