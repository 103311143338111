<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ $tl("labels.booking.menus.options.addGeneralOption") }}</h5>
        <div slot="body">
            <add-general-option-form ref="addGeneralOptionForm" :showHeadTitle="false" :showSaveButton="false" />
        </div>
        <div slot="footer" class="d-flex">
            <button type="button" class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="$emit('close')">
                {{ $tl("labels.form.actions.cancel") }}
            </button>
            <button type="button" class="btn btn-sm btn-success btn-circle ml-2" @click="save">
                {{ $tl("labels.form.actions.save") }}
            </button>
        </div>
    </modal>
</template>

<script>
import addGeneralOptionForm from "../../../views/Booking/Settings/GeneralOptionsAdd.vue";

export default {
    name: "addGeneralOptionModal",
    methods: {
        save() {
            this.$refs.addGeneralOptionForm.setFormErrors(null);
            this.$refs.addGeneralOptionForm.setFormLoading(true);
            const result = this.$refs.addGeneralOptionForm.postForm();

            if (result) {
                result
                    .then((response) => {
                        this.notifySuccess(response);
                        this.$emit("general-option-added", true);
                        this.close();
                    })
                    .catch((err) => {
                        if (err.response && err.response.data && err.response.data.errors) {
                            this.$refs.addGeneralOptionForm.setFormErrors(err.response.data.errors);
                        } else {
                            this.$refs.addGeneralOptionForm.setFormErrors({
                                general_option_id: this.getErrorMsgFromErrorResponse(err),
                            });
                        }
                        this.$refs.addGeneralOptionForm.setFormLoading(false);
                    });
            }
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        addGeneralOptionForm,
    },
};
</script>
