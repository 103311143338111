export default {
    data() {
        return {
            LOG_MAIL_PENDING: {
                value: "pending",
                keyLabel: "inProgress",
            },
            LOG_MAIL_DELIVERED: {
                value: "delivered",
                keyLabel: "delivered",
            },
            LOG_MAIL_NOT_DELIVERED: {
                value: "not-delivered",
                keyLabel: "notDelivered",
            },
        };
    },
    computed: {
        ALL_LOG_MAIL_STATUS() {
            return [this.LOG_MAIL_DELIVERED, this.LOG_MAIL_PENDING, this.LOG_MAIL_NOT_DELIVERED];
        },
    },
};
