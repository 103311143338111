var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row m-0" }, [
    _c("div", { staticClass: "col-12 mx-auto mb-3" }, [
      _c(
        "div",
        { staticClass: "border-light b-radius-20 p-4" },
        [
          _vm._l(_vm.fields, function (field, index) {
            return [
              _c("div", { key: index, staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _vm._v(_vm._s(field.label)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-6 value" },
                  [
                    field.useCopyComponent
                      ? _c("copy-to-clipboard", {
                          staticClass: "copy-container",
                          attrs: {
                            "text-to-copy": field.value,
                            element: `Le ${field.label}`,
                          },
                        })
                      : [
                          _vm._v(
                            "\n                            " +
                              _vm._s(field.value) +
                              "\n                        "
                          ),
                        ],
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              index < _vm.fields.length - 1
                ? _c("div", { staticClass: "sepa" })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }