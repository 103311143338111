var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5 pt-5" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.loading ? _c("LoaderComponent") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading",
            },
          ],
          staticClass: "row m-0",
        },
        [
          _vm.error
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v(_vm._s(_vm.error)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("div", { staticClass: "row mb-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                    _c("div", { staticClass: "row" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-7 pt-1" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-inline-block align-middle lead-switch",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.dispatchEnabled,
                                  expression: "dispatchEnabled",
                                },
                              ],
                              staticClass:
                                "switch align-self-center is-rounded",
                              attrs: {
                                disabled: !_vm.has_right_to_update_config,
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.dispatchEnabled)
                                  ? _vm._i(_vm.dispatchEnabled, null) > -1
                                  : _vm.dispatchEnabled,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.dispatchEnabled,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.dispatchEnabled = $$a.concat([
                                          $$v,
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.dispatchEnabled = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.dispatchEnabled = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", {
                              on: {
                                click: function ($event) {
                                  _vm.dispatchEnabled =
                                    _vm.has_right_to_update_config
                                      ? !_vm.dispatchEnabled
                                      : _vm.dispatchEnabled
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.dispatchEnabled
                ? [
                    _c("editClickAndCollectSendcloudSettings", {
                      attrs: {
                        sendcloud_public_key: _vm.sendcloud_public_key,
                        sendcloud_private_key: _vm.sendcloud_private_key,
                        base_weight: _vm.base_weight,
                        dispatch_min_amount: _vm.dispatch_min_amount,
                        dispatch_free_above: _vm.dispatch_free_above,
                        enable_service_points: _vm.enable_service_points,
                      },
                      on: {
                        "sendcloud-public-key": function ($event) {
                          _vm.sendcloud_public_key = $event
                        },
                        "sendcloud-private-key": function ($event) {
                          _vm.sendcloud_private_key = $event
                        },
                        "base-weight": function ($event) {
                          _vm.base_weight = $event
                        },
                        "dispatch-min-amount": function ($event) {
                          _vm.dispatch_min_amount = $event
                        },
                        "dispatch-free-above": function ($event) {
                          _vm.dispatch_free_above = $event
                        },
                        "enable-service-points": function ($event) {
                          _vm.enable_service_points = $event
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4" },
                          [
                            _vm._m(2),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "row mt-3 mb-2" },
                              [
                                _vm._m(3),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-7 pt-1" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-inline-block align-middle lead-switch",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.show_product_out,
                                            expression: "show_product_out",
                                          },
                                        ],
                                        staticClass:
                                          "switch align-self-center is-rounded",
                                        attrs: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.show_product_out
                                          )
                                            ? _vm._i(
                                                _vm.show_product_out,
                                                null
                                              ) > -1
                                            : _vm.show_product_out,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.show_product_out,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.show_product_out =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.show_product_out = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.show_product_out = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        on: {
                                          click: function ($event) {
                                            _vm.show_product_out =
                                              _vm.has_right_to_update_config
                                                ? !_vm.show_product_out
                                                : _vm.show_product_out
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("show-errors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey:
                                      "delivery_show_out_of_stock_products",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "row mb-2" },
                              [
                                _vm._m(4),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-7 pt-1" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-inline-block align-middle lead-switch",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.show_product_disable,
                                            expression: "show_product_disable",
                                          },
                                        ],
                                        staticClass:
                                          "switch align-self-center is-rounded",
                                        attrs: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.show_product_disable
                                          )
                                            ? _vm._i(
                                                _vm.show_product_disable,
                                                null
                                              ) > -1
                                            : _vm.show_product_disable,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.show_product_disable,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.show_product_disable =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.show_product_disable =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.show_product_disable = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        on: {
                                          click: function ($event) {
                                            _vm.show_product_disable =
                                              _vm.has_right_to_update_config
                                                ? !_vm.show_product_disable
                                                : _vm.show_product_disable
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("show-errors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "delivery_show_inactive_products",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4" },
                          [
                            _vm._m(5),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-3" }, [
                              _vm._m(6),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-7 pt-1" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-inline-block align-middle lead-switch",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.group_payments,
                                          expression: "group_payments",
                                        },
                                      ],
                                      staticClass:
                                        "switch align-self-center is-rounded",
                                      attrs: {
                                        disabled:
                                          !_vm.has_right_to_update_config,
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.group_payments
                                        )
                                          ? _vm._i(_vm.group_payments, null) >
                                            -1
                                          : _vm.group_payments,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.group_payments,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.group_payments =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.group_payments = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.group_payments = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("label", {
                                      on: {
                                        click: function ($event) {
                                          _vm.group_payments =
                                            _vm.has_right_to_update_config
                                              ? !_vm.group_payments
                                              : _vm.group_payments
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.hasMealVouchersEnabled && _vm.isNoShow
                              ? _c("div", { staticClass: "row" }, [
                                  _vm._m(7),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7 pt-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-inline-block align-middle lead-switch",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.dispatch_mealvouchers_enabled,
                                              expression:
                                                "dispatch_mealvouchers_enabled",
                                            },
                                          ],
                                          staticClass:
                                            "switch align-self-center is-rounded",
                                          attrs: {
                                            disabled:
                                              !_vm.has_right_to_update_config,
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.dispatch_mealvouchers_enabled
                                            )
                                              ? _vm._i(
                                                  _vm.dispatch_mealvouchers_enabled,
                                                  null
                                                ) > -1
                                              : _vm.dispatch_mealvouchers_enabled,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.dispatch_mealvouchers_enabled,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.dispatch_mealvouchers_enabled =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.dispatch_mealvouchers_enabled =
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                }
                                              } else {
                                                _vm.dispatch_mealvouchers_enabled =
                                                  $$c
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("label", {
                                          on: {
                                            click: function ($event) {
                                              _vm.dispatch_mealvouchers_enabled =
                                                _vm.has_right_to_update_config
                                                  ? !_vm.dispatch_mealvouchers_enabled
                                                  : _vm.collect_mealvouchers_enabled
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _vm._m(8),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-7 pt-1" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-inline-block align-middle lead-switch",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.dispatch_connect_to_gv,
                                          expression: "dispatch_connect_to_gv",
                                        },
                                      ],
                                      staticClass:
                                        "switch align-self-center is-rounded",
                                      attrs: {
                                        disabled:
                                          !_vm.has_right_to_update_config ||
                                          !_vm.hasGvSubscription,
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.dispatch_connect_to_gv
                                        )
                                          ? _vm._i(
                                              _vm.dispatch_connect_to_gv,
                                              null
                                            ) > -1
                                          : _vm.dispatch_connect_to_gv,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.dispatch_connect_to_gv,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.dispatch_connect_to_gv =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.dispatch_connect_to_gv =
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.dispatch_connect_to_gv = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("label", {
                                      on: {
                                        click: function ($event) {
                                          _vm.dispatch_connect_to_gv =
                                            _vm.has_right_to_update_config &&
                                            _vm.hasGvSubscription
                                              ? !_vm.dispatch_connect_to_gv
                                              : _vm.dispatch_connect_to_gv
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4" },
                          [
                            _vm._m(9),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-3" }, [
                              _vm._m(10),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-7" }, [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.dispatch_legal_information,
                                      expression: "dispatch_legal_information",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    disabled: !_vm.has_right_to_update_config,
                                    maxlength: "190",
                                    rows: "3",
                                  },
                                  domProps: {
                                    value: _vm.dispatch_legal_information,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.dispatch_legal_information =
                                        $event.target.value
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("small", { staticClass: "text-muted" }, [
                                  _vm._v("Max : 190 caractères"),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("editClickAndCollectNotifications", {
                      ref: "editClickAndCollectNotifications",
                      staticClass: "mt-2",
                      attrs: {
                        "show-save-button": false,
                        "notifications-data": _vm.notificationsData,
                        "collect-type": _vm.COLLECT_TYPE_DISPATCH.value,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("input", {
                    staticClass: "btn btn-success btn-circle",
                    class: { disabled: _vm.loading },
                    attrs: {
                      type: "submit",
                      name: "submit",
                      value: "Enregistrer",
                      disabled: _vm.loading || !_vm.has_right_to_update_config,
                    },
                    on: { click: _vm.post },
                  }),
                ]),
              ]),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [_c("h5", { staticClass: "title mt-2" }, [_vm._v("Expédition")])]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5" }, [
      _c("strong", [_vm._v("Expédition")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("strong", [_vm._v("Paramètres généraux")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Afficher les produits en rupture")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Afficher les produits inactifs")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("strong", [_vm._v("Paiements")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Autoriser les paiements de groupe")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Autoriser les paiements en titres-restaurant")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Liaison au module Bons Cadeaux")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("strong", [_vm._v("Message personnalisé")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [
        _vm._v(
          "Informations complémentaires à ajouter au mail de confirmation de commande"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }