<template>
    <div>
        <div
            class="tile restaurant border-light"
            :class="{ clickable: hasRights, disabled: !hasRights }"
            v-tooltip="getTooltip(labelHasNotRights)"
            @click="emitClick(link)">
            <div class="logo">
                <feather v-if="typeof link.icon !== 'undefined'" :type="link.icon" class="grey feather-40" />
                <i v-else-if="typeof link.rawIcon !== 'undefined'" v-html="link.rawIcon" class="grey"></i>
            </div>
            <h6 class="restaurant-title border-top-light text-center">{{ link.name }}</h6>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        link: {
            required: true,
        },
    },
    computed: {
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        hasRights() {
            if (typeof this.link.rights === "undefined") {
                return true;
            }
            return this.link.rights.some((right) => {
                if (this.rights.includes(right)) {
                    return true;
                }

                const index = right.indexOf("*");
                if (index !== -1) {
                    right = right.substring(0, i).replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
                    const regexp = new RegExp(`${right}.+`, "g");
                    return this.rights.some((cright) => cright.match(regexp));
                }
                return false;
            });
        },
        labelHasNotRights() {
            return !this.hasRights ? "Vous n'avez pas le droit d’accéder à cette page" : "";
        },
    },
    methods: {
        emitClick(link) {
            if (this.hasRights) {
                this.$emit("click", link);
            }
        },
    },
};
</script>
<style scoped>
.tile.restaurant.disabled {
    opacity: 0.5;
}
</style>
