<template>
    <div
        class="border-left-light p-0 m-0 bg-light overflow-auto"
        style="border-top: none !important"
        :style="isGroupsAndPrintTabCollapsed ? 'position: absolute; right: 0;' : undefined">
        <feather
            v-if="canCollapse"
            :type="isGroupsAndPrintTabCollapsed ? 'chevrons-left' : 'chevrons-right'"
            class="pointer"
            @click="isGroupsAndPrintTabCollapsed = !isGroupsAndPrintTabCollapsed"
            v-tooltip="getTooltip($t(`labels.booking.seatingPlan.groupsAndPrint.${isGroupsAndPrintTabCollapsed ? 'show' : 'hide'}`))" />
        <div :class="{ 'd-none': isGroupsAndPrintTabCollapsed }">
            <!-- tables -->
            <div class="accordion" id="tablesAccordion" style="flex-direction: unset !important" v-if="showTables">
                <div class="card border-0">
                    <div class="card-header p-0" id="tablesHeader">
                        <h2 class="mb-0">
                            <button
                                class="btn w-100 h-100 text-left"
                                type="button"
                                data-toggle="collapse"
                                data-target="#tablesList"
                                aria-expanded="true"
                                aria-controls="tablesList"
                                @click="isTableListExpanded = !isTableListExpanded">
                                {{ $tl("labels.booking.seatingPlan.tables") }}
                                <feather
                                    v-tooltip="getTooltipNotice('notices.booking.tablePlan.tables')"
                                    type="info"
                                    class="ml-1 pointer tooltip-infos" />
                                <feather v-if="isTableListExpanded" type="chevron-down" />
                                <feather v-else type="chevron-right" />
                            </button>
                        </h2>
                    </div>
                    <div>
                        <div
                            :ref="`tableList`"
                            id="tablesList"
                            class="collapse show border-0"
                            aria-labelledby="tablesHeader"
                            data-parent="#tablesAccordion">
                            <draggable
                                v-if="currentRoom && has_right_to_update_seatingplan"
                                class="list-group border-0"
                                v-model="sortedTables"
                                :disabled="!sortable">
                                <template v-for="(table, index) in sortedTables">
                                    <li
                                        class="list-group-item"
                                        v-if="table.seats > 0"
                                        :key="index"
                                        :class="{ selected: selectedTablesId.includes(table.id) }"
                                        style="cursor: pointer"
                                        v-on:click="tableSelected($event, table)">
                                        <div class="h-100 w-100">
                                            <div class="float-left">
                                                <svg
                                                    v-if="sortable"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="css-i6dzq1 feather mr-1"
                                                    style="color: #aaaaaa">
                                                    <line x1="3" y1="9" x2="21" y2="9"></line>
                                                    <line x1="3" y1="15" x2="21" y2="15"></line>
                                                </svg>
                                                {{ table.seats ? $tl("labels.booking.seatingPlan.table.title") : "" }}
                                                {{ table.name }}
                                            </div>
                                            <div class="float-right" v-if="table.seats">
                                                {{ capitalize($tl("labels.pax")) }} :
                                                {{ table.enabledPax.length > 0 ? table.enabledPax.join(" ,") : table.seats }}
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </draggable>
                            <div class="list-group border-0" v-else-if="currentRoom && !has_right_to_update_seatingplan">
                                <template v-for="(table, index) in sortedTables">
                                    <li
                                        class="list-group-item"
                                        v-if="table.seats > 0"
                                        :key="index"
                                        :class="{ selected: selectedTablesId.includes(table.id) }"
                                        style="cursor: pointer"
                                        v-on:click="tableSelected($event, table)">
                                        <div class="h-100 w-100">
                                            <div class="float-left">
                                                <svg
                                                    v-if="sortable && has_right_to_update_seatingplan"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="css-i6dzq1 feather mr-1"
                                                    style="color: #aaaaaa">
                                                    <line x1="3" y1="9" x2="21" y2="9"></line>
                                                    <line x1="3" y1="15" x2="21" y2="15"></line>
                                                </svg>
                                                {{ $tl("labels.booking.seatingPlan.table.title") }} {{ table.name }}
                                            </div>
                                            <div class="float-right">
                                                {{ capitalize($tl("labels.pax")) }} :
                                                {{ table.enabledPax.length > 0 ? table.enabledPax.join(" ,") : table.seats }}
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- groups -->
            <div class="accordion" id="groupsAccordion" v-if="showGroups">
                <div class="card border-0">
                    <div class="card-header p-1" id="groupsHeader">
                        <h2 class="mb-0">
                            <button
                                class="btn w-100 h-100 text-left"
                                type="button"
                                data-toggle="collapse"
                                data-target="#groupsList"
                                aria-expanded="true"
                                aria-controls="groupsList"
                                @click="isGroupsListExpanded = !isGroupsListExpanded">
                                {{ $tl("labels.booking.seatingPlan.groups") }}
                                <feather
                                    v-if="sortable"
                                    v-tooltip="getTooltipNotice('notices.booking.tablePlan.groups')"
                                    type="info"
                                    class="ml-1 pointer tooltip-infos" />
                                <feather v-if="isGroupsListExpanded" type="chevron-down" />
                                <feather v-else type="chevron-right" />
                            </button>
                        </h2>
                    </div>
                    <div
                        :ref="`groupsList`"
                        id="groupsList"
                        class="collapse show border-0"
                        aria-labelledby="groupsHeader"
                        data-parent="#groupsAccordion">
                        <ul class="list-group border-0" v-if="currentRoom">
                            <draggable v-model="sortedGroups" :disabled="!sortable || !has_right_to_update_seatingplan">
                                <li
                                    class="list-group-item p-0"
                                    v-for="(group, index) in sortedGroups"
                                    :key="index"
                                    :class="{ selected: selectedGroupId === group.id }"
                                    style="cursor: pointer">
                                    <div
                                        class="accordion"
                                        :id="`group-${group.id}-accordion`"
                                        :style="getGroupStyle(group)"
                                        :class="getGroupClasses(group)"
                                        @mouseenter.stop="onGroupMouseEnter($event, group)"
                                        @mouseleave.stop="onGroupMouseLeave($event, group)"
                                        @dragenter.stop="onGroupMouseEnter($event, group)"
                                        @dragleave.stop="onGroupMouseLeave($event, group)"
                                        @dragover.prevent
                                        @drop="onDropOnGroup($event, group)">
                                        <div class="card border-0">
                                            <div class="card-header p-1" :id="`group-${group.id}-header`">
                                                <div class="d-inline">
                                                    <span @click="groupSelected($event, group)">
                                                        <svg
                                                            v-if="sortable && has_right_to_update_seatingplan"
                                                            viewBox="0 0 24 24"
                                                            width="24"
                                                            height="24"
                                                            stroke="currentColor"
                                                            stroke-width="2"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            class="css-i6dzq1 feather mr-1"
                                                            style="color: #aaaaaa">
                                                            <line x1="3" y1="9" x2="21" y2="9"></line>
                                                            <line x1="3" y1="15" x2="21" y2="15"></line>
                                                        </svg>
                                                        {{ $tl("labels.booking.seatingPlan.group.titleShort") }}
                                                        {{ group.name }}
                                                    </span>
                                                    <button
                                                        class="btn"
                                                        type="button"
                                                        data-toggle="collapse"
                                                        :data-target="`#group-${group.id}-list`"
                                                        aria-expanded="true"
                                                        :aria-controls="`group-${group.id}-list`"
                                                        @click="group.expanded = !group.expanded">
                                                        <feather v-if="group.expanded" type="chevron-down" />
                                                        <feather v-else type="chevron-right" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div
                                                :id="`group-${group.id}-list`"
                                                class="collapse border-0"
                                                :aria-labelledby="`group-${group.id}-header`"
                                                :data-parent="`#group-${group.id}-accordion`">
                                                <ul class="list-group border-0" v-if="currentRoom">
                                                    <li
                                                        class="list-group-item"
                                                        v-for="(table, index) in group.tables"
                                                        style="cursor: pointer"
                                                        :style="getTableStyle(table)"
                                                        :key="index">
                                                        <div class="h-100 w-100">
                                                            <div class="float-left">
                                                                {{ $tl("labels.booking.seatingPlan.table.title") }}
                                                                {{ table.name }}
                                                            </div>
                                                            <div class="float-right">
                                                                {{ $tc("labels.booking.seatingPlan.countSeats", table.seats) }}
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </draggable>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion" id="roomsAccordion" style="flex-direction: unset !important" v-if="showRooms && rooms.length > 0">
                <div class="card border-0">
                    <div class="card-header p-0" id="roomsHeader">
                        <h2 class="mb-0">
                            <button
                                class="btn w-100 h-100 text-left"
                                type="button"
                                data-toggle="collapse"
                                data-target="#roomsList"
                                aria-expanded="true"
                                aria-controls="roomsList"
                                @click="isRoomsListExpanded = !isRoomsListExpanded">
                                {{ $tl("labels.booking.seatingPlan.printing") }}
                                <feather v-if="isRoomsListExpanded" type="chevron-down" />
                                <feather v-else type="chevron-right" />
                            </button>
                        </h2>
                    </div>
                    <div>
                        <div :ref="`roomsList`" id="roomsList" class="collapse border-0" aria-labelledby="roomsHeader" data-parent="#roomsAccordion">
                            <div class="list-group border-0">
                                <template v-for="(room, index) in rooms">
                                    <li class="list-group-item" :key="index">
                                        <div class="d-flex justify-content-between">
                                            <span class="pointer" @click="rooms[index].print = !rooms[index].print">
                                                {{ rooms[index].name }}
                                            </span>
                                            <label class="container-box m-0">
                                                <input type="checkbox" v-model="rooms[index].print" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </li>
                                </template>
                                <div class="mx-auto mt-2">
                                    <button
                                        class="btn btn-sm btn-circle btn-success"
                                        :disabled="!atLeastOneRoomSelectedForPrint || isPrinting"
                                        @click.prevent="printSeatingPlan()">
                                        {{ $tl("labels.form.actions.print") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { bTreeView } from "bootstrap-vue-treeview";
import draggable from "vuedraggable";

export default {
    props: {
        currentRoom: {
            required: true,
        },
        selectedTablesId: {
            default: () => [],
        },
        selectedTables: {
            default: () => [],
        },
        selectedGroupId: {
            default: null,
        },
        showTables: {
            default: true,
        },
        showGroups: {
            default: true,
        },
        showRooms: {
            default: true,
        },
        disablePointerEvents: {
            default: false,
        },
        sortable: {
            default: false,
        },
        rooms: {
            default: () => [],
        },
        canCollapse: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isMounted: false,
            isTableListExpanded: true,
            isGroupsListExpanded: true,
            isRoomsListExpanded: false,
            isPrinting: false,
        };
    },
    mounted() {
        this.isMounted = true;
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_seatingplan() {
            return this.rights.includes("booking.seatingplan.update");
        },
        isGroupsAndPrintTabCollapsed: {
            get() {
                return this.canCollapse && this.$store.getters["userPreferences/isGroupsAndPrintTabCollapsed"];
            },
            set(newVal) {
                this.$store.dispatch("userPreferences/setIsGroupsAndPrintTabCollapsed", newVal);

                this.$emit("resized");
            },
        },
        sortedTables: {
            get() {
                return this.currentRoom.tables
                    .map((t, index) => {
                        t.baseIndex = index;
                        return t;
                    })
                    .sort((a, b) => {
                        if (a.order < b.order) {
                            return -1;
                        }
                        return 1;
                    });
            },
            set(value) {
                const result = [];
                for (var index in value) {
                    const val = value[index];
                    const foundIndex = this.currentRoom.tables.findIndex((t) => t.id === val.id);

                    if (foundIndex > -1) {
                        result.push({
                            index: foundIndex,
                            value: {
                                ...this.currentRoom.tables[foundIndex],
                                order: Number.parseInt(index),
                            },
                        });
                    }
                }
                this.$emit("tables-changed", result);
            },
        },
        sortedGroups: {
            get() {
                return this.currentRoom.groups
                    .map((g, index) => {
                        g.baseIndex = index;
                        return g;
                    })
                    .sort((a, b) => {
                        if (a.order < b.order) {
                            return -1;
                        }
                        return 1;
                    });
            },
            set(value) {
                const result = [];
                for (var index in value) {
                    const val = value[index];
                    const foundIndex = this.currentRoom.groups.findIndex((g) => g.id === val.id);

                    if (foundIndex > -1) {
                        result.push({
                            index: foundIndex,
                            value: {
                                ...this.currentRoom.groups[foundIndex],
                                order: Number.parseInt(index),
                            },
                        });
                    }
                }

                this.$emit("groups-changed", result);
            },
        },
        getTablesTreeViewData() {
            let result = [];

            if (this.currentRoom) {
                this.currentRoom.tables.forEach((table) => {
                    result.push({
                        name: table.name,
                    });
                });
            }

            return result;
        },
        selectedGroup() {
            var result = null;

            if (this.selectedGroupId) {
                result = this.findGroupById(this.selectedGroupId);
            }

            return result;
        },
        atLeastOneRoomSelectedForPrint() {
            return this.rooms.length > 0 && this.rooms.some((r) => r.print);
        },
    },
    methods: {
        tableSelected(event, table) {
            if (!event.ctrlKey && !event.metaKey) {
                this.$emit("unselect-all-tables");
            }

            this.$nextTick(() => {
                if (this.selectedGroup) {
                    this.selectedGroup.tables.forEach((t) => {
                        this.selectedTablesId.push(t.id);
                    });
                    this.$emit("group-selected", null);
                }

                this.$emit("table-selected", table.group);
            });
        },
        groupSelected(event, group) {
            this.$emit("group-selected", group);
        },
        findGroupById(id) {
            for (var group of this.currentRoom.groups) {
                if (group.id === id) {
                    return group;
                }
            }

            return null;
        },
        onGroupMouseEnter(event, group) {
            this.$emit("highlight-group", { group, event });
        },
        onGroupMouseLeave(event, group) {
            this.$emit("unhighlight", { event });
        },
        onDropOnGroup(event, group) {
            event.preventDefault();
            event.stopPropagation();
            this.$emit("droped-on-group", { event, group });
        },
        getGroupClasses(group) {
            return {
                "no-child-events": this.disablePointerEvents,
                "group-disabled": group.used || !group.isEnabledForService,
            };
        },
        getGroupStyle(group) {
            var result = "";

            if (group.slots) {
                const groupReservations = group.slots
                    .map((s) => s.reservations_pivot)
                    .flat()
                    .filter((rp, index, self) => {
                        return rp.reservation && self.findIndex((rp2) => rp2.reservation && rp.reservation.id === rp2.reservation.id) === index;
                    })
                    .map((rp) => rp.reservation);

                if (!this.$_.isEmpty(groupReservations)) {
                    if (groupReservations.length > 1) {
                        var percentageUsed = 0;
                        var percentagePerReservation = Math.floor(100 / groupReservations.length);
                        result = "background: linear-gradient(90deg,";

                        for (var reservationIndex in groupReservations) {
                            const reservation = groupReservations[reservationIndex];

                            if (reservationIndex == groupReservations.length - 1) {
                                result += ` ${reservation.color} ${100 - percentageUsed}%);`;
                            } else {
                                result += ` ${reservation.color} ${percentagePerReservation}%,`;
                                percentageUsed += percentagePerReservation;
                            }
                        }
                    } else if (groupReservations.length === 1) {
                        result = `background-color: ${groupReservations[0].color}`;
                    }
                }
            }

            return result;
        },
        getTableStyle(table) {
            var result = "";

            if (table.slots) {
                const tableReservations = table.slots
                    .map((s) => s.reservations_pivot)
                    .flat()
                    .filter((rp, index, self) => {
                        return rp.reservation && self.findIndex((rp2) => rp2.reservation && rp2.reservation.id === rp.reservation.id) === index;
                    })
                    .map((rp) => rp.reservation);

                if (!this.$_.isEmpty(tableReservations)) {
                    if (tableReservations.length > 1) {
                        var percentageUsed = 0;
                        var percentagePerReservation = Math.floor(100 / tableReservations.length);
                        result = "background: linear-gradient(90deg,";

                        for (var reservationIndex in tableReservations) {
                            const reservation = tableReservations[reservationIndex];

                            if (reservationIndex == tableReservations.length - 1) {
                                result += ` ${reservation.color} ${100 - percentageUsed}%);`;
                            } else {
                                result += ` ${reservation.color} ${percentagePerReservation}%,`;
                                percentageUsed += percentagePerReservation;
                            }
                        }
                    } else if (tableReservations.length === 1) {
                        result = `background-color: ${tableReservations[0].color}`;
                    }
                }
            }
            return result;
        },
        getNextReservationFromNow(reservationPivotArray) {
            let reservationFound = null;
            let reservationFoundNowDiff = null;

            const nowDateTime = this.getDateTime();
            const reservationArray = reservationPivotArray.map((rp) => rp.reservation).filter((rp) => rp);

            for (const reservation of reservationArray) {
                if (!reservationFound) {
                    reservationFound = reservation;
                    reservationFoundNowDiff = this.getDateTime(reservation.reservation_datetime).diff(nowDateTime, "minutes").minutes;
                } else {
                    var tmpNowDiff = this.getDateTime(reservation.reservation_datetime).diff(nowDateTime, "minutes").minutes;
                    let swipe = false;

                    if (reservationFoundNowDiff < 0 && tmpNowDiff > 0) {
                        swipe = true;
                    } else if (reservationFoundNowDiff < 0 && tmpNowDiff < 0 && reservationFoundNowDiff < tmpNowDiff) {
                        swipe = true;
                    } else if (reservationFoundNowDiff > 0 && tmpNowDiff > 0 && reservationFoundNowDiff > tmpNowDiff) {
                        swipe = true;
                    }

                    if (swipe) {
                        reservationFound = reservation;
                        reservationFoundNowDiff = tmpNowDiff;
                    }
                }
            }

            return reservationFound;
        },
        printSeatingPlan() {
            const roomsIds = this.rooms.filter((r) => r.print).map((r) => r.id);

            this.$emit("print", { roomsIds });
        },
    },
    components: {
        bTreeView,
        draggable,
    },
};
</script>
