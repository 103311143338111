var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "thead",
    _vm._l(_vm.activeHeadings, function (heading, index) {
      return _c(
        "ns-th",
        {
          key: index,
          staticClass: "text-nowrap",
          class: {
            "none-mobile": heading.hideOnMobile,
            "none-desk": heading.hideOnDesktop,
          },
          attrs: {
            field: heading.field,
            width: heading.width,
            "allow-sorting": heading.allowSorting,
            "allow-filtering": heading.allowFiltering,
            "sort-comparer": heading.sortableFunction ?? null,
            "filter-options": heading.filterOptions ?? null,
          },
        },
        [
          heading.title && heading.title.content
            ? _c(
                "span",
                {
                  class: {
                    "none-mobile": heading.title.hideOnMobile,
                    "none-desk": heading.title.hideOnDesktop,
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(heading.title.content) +
                      "\n        "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          heading.icon && heading.icon.name
            ? _c("feather", {
                staticClass: "feather-20 mr-4",
                class: {
                  "none-mobile": heading.icon.hideOnMobile,
                  "none-desk": heading.icon.hideOnDesktop,
                },
                attrs: { type: heading.icon.name },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }