export default {
    data() {
        return {
            NOTIFICATION_TYPE_SMS: {
                label: "SMS",
                value: "sms",
            },
            NOTIFICATION_TYPE_EMAIL: {
                label: "Mail",
                value: "mail",
            },
            NOTIFICATION_TYPE_DISABLED: {
                label: "Désactivée",
                value: "disabled",
            },
        };
    },
    methods: {
        getNotificationTypeLabel(value) {
            const found = this.ALL_NOTIFICATION_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") return this.$t(`labels.notifications.${value}`);
            return value;
        },
        doesClientHaveValidChannelForNotificationType(type, client) {
            switch (type) {
                case this.NOTIFICATION_TYPE_EMAIL.value:
                    return !!(client && client.email);
                case this.NOTIFICATION_TYPE_SMS.value:
                    return !!(client && client.tel);
                default:
                    return false;
            }
        },
    },
    computed: {
        ALL_NOTIFICATION_TYPES() {
            return [this.NOTIFICATION_TYPE_SMS, this.NOTIFICATION_TYPE_EMAIL, this.NOTIFICATION_TYPE_DISABLED];
        },
    },
};
