<template>
    <modal @close="closeFromModal">
        <h5 slot="header">
            <template v-if="optionType === MENU_OPTION_MENU.value">
                {{ $tl("labels.booking.menus.options.addMenuOption", restaurantIdComputed) }}
            </template>
            <template v-else-if="optionType === MENU_OPTION_GENERAL.value">
                {{ $tl("labels.booking.menus.options.addGeneralOption") }}
            </template>
        </h5>
        <div slot="body">
            <menu-option-form
                ref="form"
                @fully-loaded="onFormLoaded"
                @is-editable="isEditable = $event"
                :option-type="optionType"
                :restaurant-id="restaurantIdComputed"
                :show-option-type-choice="showOptionTypeChoice" />
        </div>
        <div slot="footer" class="d-flex">
            <button type="button" class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="close">
                {{ $tl("labels.form.actions.cancel") }}
            </button>
            <button type="button" :disabled="isLoading || !isEditable" class="btn btn-sm btn-success btn-circle ml-2" @click="save">
                {{ $tl("labels.form.actions.save") }}
            </button>
        </div>
    </modal>
</template>

<script>
import menuOptionForm from "../../menuOptions/menuOptionForm.vue";
import MenuOptionTypeEnum from "../../../mixins/enums/booking/MenuOptionTypeEnum.js";

export default {
    name: "addMenuOptionModal",
    data() {
        return {
            isLoading: true,
            isEditable: false,
        };
    },
    mixins: [MenuOptionTypeEnum],
    props: {
        restaurantId: {
            default: "",
        },
        optionType: {
            type: String,
            required: true,
        },
        showOptionTypeChoice: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        restaurantIdComputed() {
            return Number.parseInt(this.restaurantId || this.$route.params.restaurant_id);
        },
    },
    methods: {
        onFormLoaded() {
            this.isLoading = false;
        },
        save() {
            this.isLoading = true;

            this.$refs.form.submit().then((success) => {
                if (success !== false) {
                    this.$emit("menu-option-added");
                    this.close();
                } else {
                    this.isLoading = false;
                }
            });
        },
        close() {
            this.$emit("close");
        },
        closeFromModal(e) {
            if (e && e.target && e.target.classList.contains("modal-wrapper")) {
                this.close();
            }
        },
    },
    components: {
        menuOptionForm,
    },
};
</script>
