import { LOCAL, DEVELOPMENT, PRODUCTION, SANDBOX, TESTING } from "../../utils/envs";

export default {
    methods: {
        isLocal() {
            return APP_ENV === LOCAL;
        },
        isDevelopement(strict = false) {
            let envs = [DEVELOPMENT];

            if (!strict) {
                envs.push(LOCAL);
            }

            return envs.includes(APP_ENV);
        },
        isTesting() {
            return APP_ENV === TESTING;
        },
        isSandbox() {
            return APP_ENV === SANDBOX;
        },
        isProduction() {
            return APP_ENV === PRODUCTION;
        },
    },
};
