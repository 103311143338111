<template>
    <div class="row mb-3">
        <div class="col-12">
            <loader-component v-if="nbLoading > 0" />
            <div v-else-if="stats.main" class="border-light b-radius-20 p-4">
                <div class="row pointer" @click="showPax = !showPax">
                    <strong class="col-10 d-flex align-items-center">{{ $tl("labels.booking.stats.charts.percentResaPerPax.title") }} </strong>
                    <div class="d-flex justify-content-end align-items-center col-2">
                        <button
                            class="btn btn-sm btn-square"
                            type="button"
                            :class="pinPax ? 'btn-success' : 'btn-secondary'"
                            @click.stop.prevent="pinPax = !pinPax">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="18px" height="18px" fill="currentColor">
                                <path
                                    d="M300.8 203.9L290.7 128H328c13.2 0 24-10.8 24-24V24c0-13.2-10.8-24-24-24H56C42.8 0 32 10.8 32 24v80c0 13.2 10.8 24 24 24h37.3l-10.1 75.9C34.9 231.5 0 278.4 0 335.2c0 8.8 7.2 16 16 16h160V472c0 .7 .1 1.3 .2 1.9l8 32c2 8 13.5 8.1 15.5 0l8-32c.2-.6 .2-1.3 .2-1.9V351.2h160c8.8 0 16-7.2 16-16 .1-56.8-34.8-103.7-83.1-131.3zM33.3 319.2c6.8-42.9 39.6-76.4 79.5-94.5L128 96H64V32h256v64h-64l15.3 128.8c40 18.2 72.7 51.8 79.5 94.5H33.3z" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div v-if="showPax || pinPax" class="row d-flex flex-column align-items-center">
                    <div class="p-3 d-flex">
                        <strong class="text-success pr-1">{{ labelPeriodMain }}</strong>
                        <span v-if="filters.hasComparison">
                            {{ $tl("labels.filters.comparedTo") }}
                            <strong class="text-success">{{ labelPeriodComparison }}</strong>
                        </span>
                    </div>
                    <VChart
                        class="chart"
                        :option="{
                            title: nbResaPerPaxChart.title,
                            series: seriesDataBar,
                            xAxis: nbResaPerPaxChart.xAxis,
                            yAxis: nbResaPerPaxChart.yAxis,
                            tooltip: tooltipDataBarPax,
                            grid: grid,
                        }">
                    </VChart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VChart from "vue-echarts";
import LoaderComponent from "../LoaderComponent.vue";
import StatsUtils from "../../mixins/helpers/booking/StatsUtils.js";

export default {
    name: "PercentResaPerPaxComponent",
    props: {
        grid: {
            required: true,
            type: Object,
        },
        filters: {
            required: true,
            type: Object,
        },
    },
    mixins: [StatsUtils],
    data() {
        return {
            showPax: false,
            nbResaPerPaxChart: {
                title: {
                    text: this.$tl("labels.booking.stats.charts.percentResaPerPax.title"),
                    left: "center",
                    textStyle: {
                        fontWeight: "normal",
                    },
                },
                xAxis: {
                    type: "category",
                    name: this.$tl("labels.booking.stats.charts.percentResaPerPax.x"),
                    nameLocation: "middle",
                    nameTextStyle: {
                        fontWeight: "bold",
                        padding: [10, 0, 10, 0],
                    },
                    axisLabel: {
                        formatter: (value, index) => {
                            if (value === "11 and more") {
                                return value.replace("and more", this.$tl("labels.booking.stats.charts.percentResaPerPax.axisLabel"));
                            }
                            return value;
                        },
                    },
                },
                yAxis: {
                    type: "value",
                    name: this.$tl("labels.booking.stats.charts.percentResaPerPax.y"),
                    nameLocation: "middle",
                    nameTextStyle: {
                        fontWeight: "bold",
                        padding: [0, 0, 45, 0],
                    },
                    nameRotate: 90,
                    axisLabel: {
                        formatter: "{value} %",
                    },
                },
            },
            stats: {
                main: null,
                comparison: null,
            },
        };
    },
    computed: {
        pinPax: {
            get() {
                return this.$store.getters["userPreferences/pinPax"];
            },
            set(newVal) {
                this.$store.dispatch("userPreferences/setPinPax", newVal);
            },
        },
        seriesDataBar() {
            const seriesBar = [
                {
                    type: "bar",
                    data: this.seriesDataBarNbResaAndPax,
                    itemStyle: {
                        color: "#8C54FF",
                    },
                    animation: true,
                    animationDuration: 1000,
                    animationEasing: "exponentialInOut",
                    label: {
                        show: true,
                        position: "top",
                    },
                },
            ];

            if (this.filters.hasComparison && this.stats.comparison) {
                seriesBar.push({
                    type: "bar",
                    data: this.seriesDataBarNbResaAndPaxComparison,
                    itemStyle: {
                        color: "#bc43e1",
                    },
                    animation: true,
                    animationDuration: 1000,
                    animationEasing: "exponentialInOut",
                    label: {
                        show: true,
                        position: "top",
                    },
                });
            }

            return seriesBar;
        },
        seriesDataBarNbResaAndPax() {
            const statsPax = this.stats.main.map((item) => [item.pax, item.percent, item.count_reservations]);
            const maxPax = 10;
            const paxList = [];
            for (let i = 1; i <= maxPax; i++) {
                paxList.push([i, 0, 0]);
            }
            paxList.push(["11 and more", 0, 0]);

            return this.arrayMergeToObject(paxList, statsPax);
        },
        seriesDataBarNbResaAndPaxComparison() {
            const statsPax = this.stats.comparison.map((item) => [item.pax, item.percent, item.count_reservations]);
            const maxPax = 10;
            const paxList = [];
            for (let i = 1; i <= maxPax; i++) {
                paxList.push([i, 0, 0]);
            }
            paxList.push(["11 and more", 0, 0]);

            return this.arrayMergeToObject(paxList, statsPax);
        },
        tooltipDataBarPax() {
            return {
                trigger: "item",
                formatter: (params) => {
                    const percent = params.data[1];
                    const nb = params.data[2];
                    const so = this.$tl("labels.booking.stats.charts.tooltip.so");
                    const reservations = this.$tcl("labels.booking.stats.charts.tooltip.reservations", nb);

                    return `<b>${reservations}</b>, <br/> ${so} ${percent}%`;
                },
            };
        },
    },
    methods: {
        arrayMergeToObject(arr1, arr2) {
            const merged = {};
            arr1.forEach((item) => {
                merged[item[0]] = item;
            });

            arr2.forEach((item) => {
                merged[item[0]] = item;
            });

            return Object.values(merged);
        },
        fetchStats(abortControllerSignal = null, forComparison = false) {
            const filters = this.getFiltersQueryParams(forComparison);

            return this.httpGet(`/api/stats/reservations-count-per-pax?${filters}`, {
                config: { signal: abortControllerSignal },
                handleReject: false,
            }).then((response) => {
                if (response !== false) {
                    this.stats[forComparison ? "comparison" : "main"] = response.data;
                }
            });
        },
    },
    components: { LoaderComponent, VChart },
    watch: {
        pinPax(newVal) {
            if (!newVal) {
                this.showPax = false;
            }
        },
    },
};
</script>

<style scoped>
button.btn:disabled {
    cursor: default;
}

.chart {
    height: 450px;
}
</style>
