var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : !_vm.website_enabled
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("Votre site web n'est pas activé"),
          ])
        : [
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                  },
                  [
                    _c("h5", { staticClass: "title mt-2" }, [
                      _vm._v("Configuration des pop-ups"),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-md-5" }, [
                      _c(
                        "label",
                        { attrs: { for: "chk-instagram-gallery-enabled" } },
                        [_c("strong", [_vm._v('Pop-up "Actualités"')])]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-7" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-inline-block align-middle lead-switch",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.website_settings.news_enabled,
                                expression: "website_settings.news_enabled",
                              },
                            ],
                            staticClass: "switch align-self-center is-rounded",
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              type: "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.website_settings.news_enabled
                              )
                                ? _vm._i(
                                    _vm.website_settings.news_enabled,
                                    null
                                  ) > -1
                                : _vm.website_settings.news_enabled,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.website_settings.news_enabled,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.website_settings,
                                        "news_enabled",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.website_settings,
                                        "news_enabled",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.website_settings,
                                    "news_enabled",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.getTooltip(
                                  "Activer / désactiver cette section"
                                ),
                                expression:
                                  "getTooltip('Activer / désactiver cette section')",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                _vm.website_settings.news_enabled =
                                  _vm.has_right_to_update_customization
                                    ? !_vm.website_settings.news_enabled
                                    : _vm.website_settings.news_enabled
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.website_settings.news_enabled,
                          expression: "website_settings.news_enabled",
                        },
                      ],
                      staticClass: "row",
                    },
                    [
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("label", { attrs: { for: "txt-news-title" } }, [
                          _vm._v("Titre de la pop-up "),
                          _c("small", { staticClass: "text-danger" }, [
                            _vm._v("*"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7 mb-1" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.website_settings.news_title,
                                expression: "website_settings.news_title",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              max: "50",
                              type: "text",
                              id: "txt-news-title",
                            },
                            domProps: {
                              value: _vm.website_settings.news_title,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.website_settings,
                                  "news_title",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "news_title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("label", { attrs: { for: "txt-news-content" } }, [
                          _vm._v("Contenu texte "),
                          _c("small", { staticClass: "text-danger" }, [
                            _vm._v("*"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7 mb-2" },
                        [
                          _c("TextAreaComponentOld", {
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              maxNbChar: 350,
                              txtAreaId: "txt-news-content",
                              txtAreaObj: _vm.website_settings,
                              txtAreaObjKey: "news_content",
                              txtAreaRows: "6",
                            },
                          }),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "news_content",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("label", [
                          _vm._v("Ajouter un bouton de redirection"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-7 mb-2" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-inline-block align-middle lead-switch",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.website_settings.news_btn_enabled,
                                  expression:
                                    "website_settings.news_btn_enabled",
                                },
                              ],
                              staticClass:
                                "switch align-self-center is-rounded",
                              attrs: {
                                disabled:
                                  !_vm.has_right_to_update_customization,
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.website_settings.news_btn_enabled
                                )
                                  ? _vm._i(
                                      _vm.website_settings.news_btn_enabled,
                                      null
                                    ) > -1
                                  : _vm.website_settings.news_btn_enabled,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.website_settings.news_btn_enabled,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.website_settings,
                                          "news_btn_enabled",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.website_settings,
                                          "news_btn_enabled",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.website_settings,
                                      "news_btn_enabled",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.getTooltip(
                                    "Activer / désactiver cette section"
                                  ),
                                  expression:
                                    "getTooltip('Activer / désactiver cette section')",
                                },
                              ],
                              on: {
                                click: function ($event) {
                                  _vm.website_settings.news_btn_enabled =
                                    _vm.has_right_to_update_customization
                                      ? !_vm.website_settings.news_btn_enabled
                                      : _vm.website_settings.news_btn_enabled
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.website_settings.news_btn_enabled
                        ? [
                            _c("div", { staticClass: "col-md-5" }, [
                              _c(
                                "label",
                                { attrs: { for: "txt-news-btn-title" } },
                                [
                                  _vm._v("Titre du bouton "),
                                  _c("small", { staticClass: "text-danger" }, [
                                    _vm._v("*"),
                                  ]),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-7 mb-1" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.website_settings.news_btn_title,
                                      expression:
                                        "website_settings.news_btn_title",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    disabled:
                                      !_vm.has_right_to_update_customization,
                                    max: "20",
                                    type: "text",
                                    id: "txt-news-btn-title",
                                  },
                                  domProps: {
                                    value: _vm.website_settings.news_btn_title,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.website_settings,
                                        "news_btn_title",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  staticClass: "d-block",
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "news_btn_title",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-5" }, [
                              _c(
                                "label",
                                { attrs: { for: "txt-news-btn-title" } },
                                [
                                  _vm._v("Lien "),
                                  _c("small", { staticClass: "text-danger" }, [
                                    _vm._v("*"),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "text-warning d-block" },
                                [
                                  _vm._v(
                                    'Merci de préfixer le lien par "http://" ou "https://"'
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-7 mb-2" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.website_settings.news_btn_link,
                                      expression:
                                        "website_settings.news_btn_link",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    disabled:
                                      !_vm.has_right_to_update_customization,
                                    type: "text",
                                    id: "txt-news-btn-title",
                                  },
                                  domProps: {
                                    value: _vm.website_settings.news_btn_link,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.website_settings,
                                        "news_btn_link",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  staticClass: "d-block",
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "news_btn_link",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0 mt-3" }, [
              _c("div", { staticClass: "col-12 d-inline-block" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success btn-circle mr-1",
                    attrs: {
                      type: "button",
                      disabled: !_vm.has_right_to_update_customization,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.postForm()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Enregistrer\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    'Pour rendre vos modifications visibles sur votre site internet, pensez à "'
                  ),
                  _c("strong", [_vm._v("Enregistrer et publier")]),
                  _vm._v(
                    '" en\n                    cliquant sur le bouton dans la navigation.'
                  ),
                ]),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }