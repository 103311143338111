<template>
    <div class="stripe-card-list">
        <div class="row mb-3 mt-4">
            <div class="col-12">
                <strong>{{ $tl("labels.payments.methods.title") }}</strong>
            </div>
        </div>
        <loader-component v-if="loading" />
        <ul v-show="!loading" class="p-0" v-if="paymentMethods.length > 0">
            <li v-for="paymentMethod in paymentMethods" :key="paymentMethod.id">
                <component
                    :is="getComponentForPaymentMethod(paymentMethod.type)"
                    class="clickable mb-2"
                    :editable="editable"
                    v-model="cardSelected"
                    @addNewCard="showAddCardModal = true"
                    @loading="loading = true"
                    @updated="fetchCustomerAllPaymentMethods()"
                    :card="paymentMethod" />
            </li>
        </ul>
        <div v-show="!loading" class="mb-2" v-else>
            <span>{{ $tl("labels.payments.methods.empty") }}</span>
        </div>
        <button
            v-if="editable && paymentMethods.length === 0"
            v-show="!loading"
            :disabled="!has_right_to_update_subscription"
            @click="showAddCardModal = true"
            class="btn btn-success btn-sm btn-circle add-card">
            <feather type="plus"></feather> {{ $tl("labels.payments.methods.add") }}
        </button>
        <add-card-modal v-if="showAddCardModal" :addCardOnly="false" @close="showAddCardModal = false" @updated="fetchCustomerAllPaymentMethods" />
    </div>
</template>

<script>
import CardItem from "./CardItem";
import SepaDebitItem from "./SepaDebitItem";
import AddCardModal from "../../../Modals/Stripe/Customer/PaymentMethods/AddCardModal";
import LoaderComponent from "../../../LoaderComponent";

export default {
    name: "StripeCustomerCardList",
    components: { AddCardModal, CardItem, LoaderComponent, SepaDebitItem },
    data() {
        return {
            error: null,
            loading: false,
            paymentMethods: [],
            showAddCardModal: false,
            cardSelected_: null,
        };
    },
    props: {
        value: {
            default: undefined,
        },
        editable: {
            default: true,
        },
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_subscription() {
            return this.rights.includes("default.subscription.update");
        },
        cardSelected: {
            get: function () {
                if (typeof this.value == "undefined") {
                    return this.cardSelected_;
                } else {
                    return this.value;
                }
            },
            set: function (value) {
                if (typeof this.value == "undefined") {
                    this.cardSelected_ = value;
                } else {
                    this.$emit("input", value);
                }
            },
        },
    },
    created() {
        this.fetchCustomerAllPaymentMethods();
    },
    methods: {
        getComponentForPaymentMethod(type) {
            return this.capitalize(type.replace(/_./g, (x) => x.toUpperCase()[1])) + "Item";
        },
        fetchCustomerAllPaymentMethods() {
            this.loading = true;
            this.error = null;

            this.$store
                .dispatch("stripe/fetchCustomerAllPaymentMethods")
                .then((response) => {
                    this.paymentMethods = response.data.data;
                    if (this.paymentMethods.length > 0 && !this.cardSelected) {
                        this.cardSelected = this.paymentMethods[0];
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
