var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _vm.nbLoading > 0
        ? _c("loader-component")
        : typeof _vm.restaurant !== "undefined" &&
          _vm.hasOptedIn !== null &&
          _vm.isReserveWithGoogleEnabled()
        ? _c("div", { staticClass: "default-home" }, [
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                  },
                  [
                    _c("h5", { staticClass: "title mt-2" }, [
                      _vm._v(
                        _vm._s(_vm.$tl("labels.routes.rwg", _vm.restaurant.id))
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { staticClass: "border-light b-radius-20 p-4 mb-3" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 d-flex justify-content-between align-item-center",
                        },
                        [
                          _c("h5", { staticClass: "title mt-2" }, [
                            _vm._v(_vm._s(_vm.restaurant.name)),
                          ]),
                          _vm._v(" "),
                          _vm.hasOptedIn
                            ? _c(
                                "p",
                                { staticClass: "mt-2 mb-0 font-italic" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.googleReserve.contract.active",
                                          _vm.restaurant.id,
                                          {
                                            date: _vm.displayDate(
                                              _vm.optedInDate,
                                              _vm.DATE_SHORT
                                            ),
                                          }
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { staticClass: "border-light b-radius-20 p-4 mb-3" },
                  [
                    _c("div", { staticClass: "row mb-3" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-12 d-flex justify-content-between",
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.$tl(
                                  "labels.googleReserve.contract.title",
                                  _vm.restaurant.id
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-circle btn-sm",
                              class: _vm.hasOptedIn
                                ? "btn-danger"
                                : "btn-primary",
                              on: { click: _vm.toggleOptIn },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$tl(
                                      `labels.googleReserve.contract.actions.${this.currentAction}.btn`,
                                      _vm.restaurant.id
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row text-center" }, [
                      _c("div", { staticClass: "col-12 pt-2" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://storage.googleapis.com/noshow-data/noshow/cgu_reserve_with_google.pdf",
                              target: "_blank",
                            },
                          },
                          [
                            _c("p", { staticClass: "bg-light p-3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.googleReserve.contract.terms",
                                    _vm.restaurant.id
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.appUpdateContent !== null
                ? _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      { staticClass: "border-light b-radius-20 p-4 mb-3" },
                      [
                        _c("div", { staticClass: "row mb-3" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.googleReserve.setup",
                                    _vm.restaurant.id
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", {
                            staticClass: "col-12 pt-2 ck-content",
                            staticStyle: { "overflow-x": "auto" },
                            domProps: {
                              innerHTML: _vm._s(_vm.appUpdateContent),
                            },
                          }),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ])
        : _c("div", { staticClass: "text-danger" }, [
            _vm._v(_vm._s(_vm.$tl("errors.common.unknown", _vm.restaurant.id))),
          ]),
      _vm._v(" "),
      _vm.showOptInModal
        ? _c("opt-in-modal", {
            attrs: { "restaurant-id": _vm.restaurantId },
            on: {
              close: function ($event) {
                _vm.showOptInModal = false
              },
              "opted-in": _vm.onOptedIn,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showOptOutModal
        ? _c("opt-out-modal", {
            attrs: { "restaurant-id": _vm.restaurantId },
            on: {
              close: function ($event) {
                _vm.showOptOutModal = false
              },
              "opted-out": _vm.onOptedOut,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }