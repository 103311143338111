var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid h-100 login" }, [
    !_vm.isBrowserIE
      ? _c("div", { staticClass: "row m-0 h-100 w-100" }, [
          _c(
            "main",
            { staticClass: "col-12 ml-sm-auto px-md-5 h-100 d-flex" },
            [_c("router-view")],
            1
          ),
        ])
      : _c("div", { staticStyle: { "text-align": "center" } }, [
          _c("h1", [_vm._v("Navigateur non supporté")]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("br"),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n            Votre navigateur n'est pas compatible avec le logiciel NoShow, veuillez utiliser\n            "
      ),
      _c(
        "a",
        { attrs: { href: "https://www.google.com/intl/fr_fr/chrome/" } },
        [_vm._v("Google Chrome")]
      ),
      _vm._v(" ou\n            "),
      _c("a", { attrs: { href: "https://www.mozilla.org/fr/" } }, [
        _vm._v("Mozilla Firefox"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }