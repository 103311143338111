var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-100 text-center" }, [
      _c("h5", { staticClass: "mt-3" }, [
        _vm._v(
          "Une erreur est survenue. Veuillez contacter l'établissement par téléphone."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }