var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.featherBinding !== null
        ? _c(
            "feather",
            _vm._b(
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.featherBinding.tooltip,
                    expression: "featherBinding.tooltip",
                  },
                ],
              },
              "feather",
              _vm.featherBinding,
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showLabel
        ? [_vm._v(_vm._s(_vm.getClientTypeLabel(_vm.clientType)))]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }