<template>
    <div>
        <LoaderComponent v-if="loading" />
        <span v-else-if="error">{{ error }}</span>
        <div v-else>
            <div class="row mb-3">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row mb-2">
                            <div class="col-md-5 pt-1">
                                <label> Nom <small>*</small> </label>
                            </div>
                            <div class="col-md-7 mb-2">
                                <input type="text" class="form-control" v-model="productComponent.name" />
                                <ShowErrors :errors="errors" errorKey="name" />
                            </div>
                        </div>
                        <div class="row mb-2" v-if="!productComponent.is_extra_forced">
                            <div class="col-md-5 pt-1">
                                <label> Ordre <small>*</small> </label>
                            </div>
                            <div class="col-md-7 mb-2">
                                <input type="number" min="1" class="form-control" v-model="productComponent.order" />
                                <ShowErrors :errors="errors" errorKey="order" />
                            </div>
                        </div>
                        <div class="row" v-if="!productComponent.is_extra_forced">
                            <div class="col-md-5">
                                <label>Choix du composant <small>*</small></label>
                            </div>
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="radio">
                                            <label class="container-box">
                                                <input type="radio" v-model="productComponent.is_mandatory" :value="1" />
                                                <span class="checkmark"></span> Obligatoire
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 d-inline radio mt-1">
                                        <label class="container-box d-inline-block pt-0 mt-2" style="width: initial">
                                            <input type="radio" v-model="productComponent.is_mandatory" :value="0" />
                                            <span class="checkmark"></span> Optionnel
                                        </label>
                                    </div>
                                    <ShowErrors class="col-12" :errors="errors" errorKey="is_mandatory" />
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-5 pt-1 justify-content-between">
                                <label> Produits <small>*</small> </label>
                            </div>
                            <div v-if="productComponent.cc_product_options.data.length === 0" class="col-md-7 mb-2">
                                <select class="custom-select" v-model="selected_product">
                                    <option disabled :value="null">Choisir un produit</option>
                                    <option v-for="product in availableProducts" :key="product.id" :value="product">
                                        {{ product.name }}
                                    </option>
                                </select>
                                <ShowErrors :errors="errors" errorKey="cc_product_options.data" />
                            </div>
                        </div>
                        <template v-if="productComponent.cc_product_options.data.length > 0">
                            <div class="row mb-3" v-for="(productOption, index) in productComponent.cc_product_options.data" :key="index">
                                <div class="col-12">
                                    <div class="border-light p-4">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <strong>{{ productOption.cc_product.name }}</strong>
                                            <button
                                                class="btn btn-sm btn-warning btn-square"
                                                @click="deleteProductOption(index)"
                                                v-tooltip="getTooltip('Retirer')">
                                                <feather type="x" />
                                            </button>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-md-5">
                                                <label>Tarif TTC <small>*</small></label>
                                            </div>
                                            <div class="col-md-7">
                                                <div class="row">
                                                    <div
                                                        class="col-12 text-danger mb-2"
                                                        v-if="!productOption.cc_product.price || !productOption.cc_product.tax_rate">
                                                        Vous devez d'abord définir un prix et un taux de TVA à ce produit afin de pouvoir l'ajouter.
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="radio">
                                                            <label class="container-box">
                                                                <input
                                                                    type="radio"
                                                                    :disabled="!productOption.cc_product.tax_rate || !productOption.cc_product.price"
                                                                    v-model="productOption.include"
                                                                    :value="1" />
                                                                <span class="checkmark"></span> Inclus dans le prix
                                                                {{ productComponent.is_extra_forced ? "du produit" : "de la formule" }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 d-inline radio mt-1">
                                                        <label class="container-box d-inline-block pt-0 mt-2" style="width: initial">
                                                            <input
                                                                type="radio"
                                                                :disabled="!productOption.cc_product.tax_rate || !productOption.cc_product.price"
                                                                v-model="productOption.include"
                                                                :value="0" />
                                                            <span class="checkmark"></span> Coût additionnel
                                                        </label>
                                                        <template v-if="!productOption.include">
                                                            <input
                                                                type="number"
                                                                class="form-control d-inline mr-2"
                                                                style="width: 100px"
                                                                v-model.number="productOption.price" />
                                                            euros
                                                            <ShowErrors
                                                                class="d-block"
                                                                :errors="errors"
                                                                :errorKey="`cc_product_options.data.${index}.price`" />
                                                        </template>
                                                    </div>
                                                    <ShowErrors
                                                        class="col-12"
                                                        :errors="errors"
                                                        :errorKey="`cc_product_options.data.${index}.include`" />
                                                </div>
                                            </div>
                                            <ShowErrors
                                                class="col-12"
                                                :errors="errors"
                                                :errorKey="`cc_product_options.data.${index}.cc_product_id`" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div v-if="productComponent.cc_product_options.data.length > 0" class="row mb-2">
                            <div class="col-md-5 pt-1">
                                <div
                                    v-tooltip="getTooltip('Ajouter un autre produit')"
                                    @click="addProduct = true"
                                    class="pointer p-2 bg-light d-inline">
                                    <feather type="plus" class="text-success" />
                                </div>
                            </div>
                            <div class="col-md-7 mb-2">
                                <select v-if="addProduct" v-model="selected_product" class="custom-select">
                                    <option disabled :value="null">Choisir un produit</option>
                                    <option v-for="product in availableProducts" :key="product.id" :value="product">
                                        {{ product.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent";
import ShowErrors from "../errors/ShowErrors";

export default {
    data() {
        return {
            loading: false,
            errors: null,
            error: null,
            required: 1,
            include: 1,
            selected_product: null,
            addProduct: false,
            products: [],
        };
    },
    props: {
        productComponent: {
            type: Object,
            required: true,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        availableProducts() {
            return this.products.filter(
                (product) => !this.productComponent.cc_product_options.data.some((productOption) => productOption.cc_product_id === product.id)
            );
        },
        builtData() {
            let data = _.cloneDeep(this.productComponent);
            for (let i = 0; i < data.cc_product_options.data.length; i++) {
                if (data.cc_product_options.data[i].price !== null && !isNaN(data.cc_product_options.data[i].price))
                    data.cc_product_options.data[i].price = Number.parseInt(data.cc_product_options.data[i].price * 100);
            }
            return data;
        },
    },
    methods: {
        fetchProducts() {
            this.loading = true;
            this.products = [];

            axios
                .get(`/api/click_and_collect/${this.restaurant_id}/products?filter_options=1`)
                .then((response) => {
                    this.loading = false;
                    this.products = response.data.products;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = error.response && error.reponse.data.message ? error.reponse.data.message : "Une erreur est survenue";
                });
        },
        deleteProductOption(index) {
            this.productComponent.cc_product_options.data.splice(index, 1);
        },
        save() {
            this.loading = true;
            this.errors = null;

            axios
                .post(`/api/click_and_collect/${this.restaurant_id}/products/components/validate`, this.builtData)
                .then((response) => {
                    this.loading = false;
                    this.$emit("saved");
                })
                .catch((error) => {
                    this.loading = false;
                    this.errors = error.response && error.response.data.errors ? error.response.data.errors : null;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
    },
    created() {
        this.fetchProducts();
    },
    components: {
        LoaderComponent,
        ShowErrors,
    },
    watch: {
        selected_product(newVal) {
            if (newVal !== null)
                this.productComponent.cc_product_options.data.push({
                    id: null,
                    include: newVal.price && newVal.tax_rate ? 1 : 2,
                    price: null,
                    cc_product_id: newVal.id,
                    cc_product: newVal,
                });
            this.selected_product = null;
            this.addProduct = false;
        },
    },
};
</script>
