var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _vm.campaign
        ? _c("div", [
            _c("div", { staticClass: "div-profil" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "border-light b-radius-20 p-4" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-5" }, [
                          _vm._v("Nom de la campagne"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-7 value" }, [
                          _vm._v(_vm._s(_vm.campaign.name)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "sepa" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-5" }, [
                          _vm._v("Type de campagne"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-7 value" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.getCampaignTypeLabel(_vm.campaign.type)
                              ) +
                              "\n                            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.campaign.type === _vm.CAMPAIGN_TYPE_SMS.value
                        ? [
                            _c("div", { staticClass: "sepa" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-5" }, [
                                _vm._v("Expéditeur"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-7 value" }, [
                                _vm._v(_vm._s(_vm.campaign.sms_expeditor)),
                              ]),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "sepa" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-5" }, [
                          _vm._v("Filtres"),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "col-md-7 value",
                          domProps: { innerHTML: _vm._s(_vm.filters) },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "sepa" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-5" }, [
                          _vm._v("Statut"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-7 value" }, [
                          _c(
                            "span",
                            {
                              class: {
                                "text-muted":
                                  !_vm.campaign.sent_at &&
                                  !_vm.campaign.will_be_send_at,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.campaign.sent_at
                                    ? "Envoyée"
                                    : _vm.campaign.will_be_send_at
                                    ? "Programmée"
                                    : "Brouillon"
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "sepa" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-5" }, [
                          _vm._v("Date et heure d'envoi"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-7 value" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.campaign.sent_at
                                  ? _vm
                                      .moment(_vm.campaign.sent_at)
                                      .format("LLL")
                                  : _vm.campaign.will_be_send_at
                                  ? _vm
                                      .moment(_vm.campaign.will_be_send_at)
                                      .format("LLL")
                                  : "-"
                              ) +
                              "\n                            "
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                    _vm.campaign.type === _vm.CAMPAIGN_TYPE_EMAIL.value
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-5" }, [
                            _vm._v("Objet"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-7 value" }, [
                            _vm._v(_vm._s(_vm.campaign.mail_object)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.campaign.type === _vm.CAMPAIGN_TYPE_EMAIL.value
                      ? _c("div", { staticClass: "sepa" })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [_vm._v("Message")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 mt-2" }, [
                        _c("small", {
                          staticClass: "value",
                          domProps: { innerHTML: _vm._s(_vm.campaign_content) },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.campaign.img_path &&
                    _vm.campaign.type === _vm.CAMPAIGN_TYPE_EMAIL.value
                      ? _c("div", { staticClass: "sepa" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.campaign.img_path &&
                    _vm.campaign.type === _vm.CAMPAIGN_TYPE_EMAIL.value
                      ? _c(
                          "div",
                          {
                            staticClass: "row",
                            staticStyle: { "max-height": "20vh" },
                          },
                          [
                            _c("div", { staticClass: "col-5" }, [
                              _vm._v("Image"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-7" }, [
                              _c("img", {
                                staticStyle: {
                                  "max-width": "100%",
                                  "max-height": "20vh",
                                },
                                attrs: {
                                  src: _vm.campaign.img_path,
                                  alt: "Image de campagne",
                                  tile: "Image de la campagne",
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.campaign.img_link &&
                    _vm.campaign.type === _vm.CAMPAIGN_TYPE_EMAIL.value
                      ? _c("div", { staticClass: "sepa" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.campaign.img_link &&
                    _vm.campaign.type === _vm.CAMPAIGN_TYPE_EMAIL.value
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-5" }, [
                            _vm._v("Lien de l'image"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-7" }, [
                            _c(
                              "a",
                              {
                                staticClass: "value",
                                attrs: {
                                  target: "_blank",
                                  href: _vm.campaign.img_link.startsWith("http")
                                    ? _vm.campaign.img_link
                                    : "http://" + _vm.campaign.img_link,
                                },
                              },
                              [_vm._v(_vm._s(_vm.campaign.img_link))]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.campaign.sent_at
                ? _c("div", { staticClass: "row mt-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c("div", { staticClass: "row m-0 border-bottom" }, [
                            _c(
                              "table",
                              { staticClass: "table table-striped table-sm" },
                              [
                                _c("thead", { staticClass: "border-bottom" }, [
                                  _c("th", [_vm._v("Utilisateurs")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Envoyés")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Ouvertures")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Clics")]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  [
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.campaign.send_to_owners_only
                                              ? "Compte admin"
                                              : "Tous"
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(_vm.allDeliveries.length)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm.campaign.type ===
                                        _vm.CAMPAIGN_TYPE_SMS.value
                                          ? _c("div", [_vm._v("Indisponible")])
                                          : _vm.getOpenedDeliveries(
                                              _vm.allDeliveries
                                            ).length === 0
                                          ? _c("div", [_vm._v("0")])
                                          : _c("div", [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    _vm.getOpenedDeliveries(
                                                      _vm.allDeliveries
                                                    ).length
                                                  ) +
                                                  " (" +
                                                  _vm._s(
                                                    _vm.formatPercent(
                                                      (_vm.getOpenedDeliveries(
                                                        _vm.allDeliveries
                                                      ).length /
                                                        _vm.allDeliveries
                                                          .length) *
                                                        100
                                                    )
                                                  ) +
                                                  "%)\n                                            "
                                              ),
                                            ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm.campaign.type ===
                                        _vm.CAMPAIGN_TYPE_SMS.value
                                          ? _c("div", [_vm._v("Indisponible")])
                                          : _vm.getClickedDeliveries(
                                              _vm.allDeliveries
                                            ).length === 0
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                                                0\n                                            "
                                              ),
                                            ])
                                          : _c("div", [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    _vm.getClickedDeliveries(
                                                      _vm.allDeliveries
                                                    ).length
                                                  ) +
                                                  " (" +
                                                  _vm._s(
                                                    _vm.formatPercent(
                                                      (_vm.getClickedDeliveries(
                                                        _vm.allDeliveries
                                                      ).length /
                                                        _vm.getOpenedDeliveries(
                                                          _vm.allDeliveries
                                                        ).length) *
                                                        100
                                                    )
                                                  ) +
                                                  "%)\n                                            "
                                              ),
                                            ]),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    !_vm.campaign.send_to_owners_only
                                      ? _vm._l(
                                          _vm.ROLES_FOR_ADMIN_CAMPAIGN,
                                          function (role) {
                                            return _c(
                                              "tr",
                                              { key: role.value },
                                              [
                                                _c("td", [
                                                  _vm._v(_vm._s(role.label)),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getDeliveriesForRole(
                                                        role.value
                                                      ).length
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm.campaign.type ===
                                                  _vm.CAMPAIGN_TYPE_SMS.value
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                                                    Indisponible\n                                                "
                                                        ),
                                                      ])
                                                    : _vm.getOpenedDeliveries(
                                                        _vm.getDeliveriesForRole(
                                                          role.value
                                                        )
                                                      ).length === 0
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                                                    0\n                                                "
                                                        ),
                                                      ])
                                                    : _c("div", [
                                                        _vm._v(
                                                          "\n                                                    " +
                                                            _vm._s(
                                                              _vm.getOpenedDeliveries(
                                                                _vm.getDeliveriesForRole(
                                                                  role.value
                                                                )
                                                              ).length
                                                            ) +
                                                            "\n                                                    (" +
                                                            _vm._s(
                                                              _vm.formatPercent(
                                                                (_vm.getOpenedDeliveries(
                                                                  _vm.getDeliveriesForRole(
                                                                    role.value
                                                                  )
                                                                ).length /
                                                                  _vm.getDeliveriesForRole(
                                                                    role.value
                                                                  ).length) *
                                                                  100
                                                              )
                                                            ) +
                                                            "%)\n                                                "
                                                        ),
                                                      ]),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm.campaign.type ===
                                                  _vm.CAMPAIGN_TYPE_SMS.value
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                                                    Indisponible\n                                                "
                                                        ),
                                                      ])
                                                    : _vm.getClickedDeliveries(
                                                        _vm.getDeliveriesForRole(
                                                          role.value
                                                        )
                                                      ).length === 0
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                                                    0\n                                                "
                                                        ),
                                                      ])
                                                    : _c("div", [
                                                        _vm._v(
                                                          "\n                                                    " +
                                                            _vm._s(
                                                              _vm.getClickedDeliveries(
                                                                _vm.getDeliveriesForRole(
                                                                  role.value
                                                                )
                                                              ).length
                                                            ) +
                                                            "\n                                                    (" +
                                                            _vm._s(
                                                              _vm.formatPercent(
                                                                (_vm.getClickedDeliveries(
                                                                  _vm.getDeliveriesForRole(
                                                                    role.value
                                                                  )
                                                                ).length /
                                                                  _vm.getOpenedDeliveries(
                                                                    _vm.getDeliveriesForRole(
                                                                      role.value
                                                                    )
                                                                  ).length) *
                                                                  100
                                                              )
                                                            ) +
                                                            "%)\n                                                "
                                                        ),
                                                      ]),
                                                ]),
                                              ]
                                            )
                                          }
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }