<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ $tl("labels.clients.merge.multiple") }}</h5>
        <div slot="body">
            <SearchAndSelectClient v-show="step === 1" :avoidClientId="client.id" @client-selected="onClientSelected" />
            <div v-show="!loading">
                <SelectClientForMerging v-if="step === 2" :client1="client" :client2="selectedClient" @client-selected="onFinalClientSelected" />
            </div>
            <LoaderComponent v-if="loading" />
        </div>
        <div slot="footer" class="d-flex">
            <button class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="close">
                {{ $tl("labels.form.actions.cancel") }}
            </button>
            <button v-if="step > 1" class="btn btn-outline-secondary btn-sm btn-circle ml-2" @click="previousStep">
                {{ $tl("labels.form.actions.back") }}
            </button>
            <button
                v-if="step > 1"
                :disabled="!finalSelectedClient || !clientToErase"
                :class="{ disabled: !finalSelectedClient || !clientToErase }"
                class="btn btn-success btn-sm btn-circle ml-2"
                @click="mergeClients">
                {{ $tl("labels.form.actions.validate") }}
            </button>
        </div>
    </modal>
</template>

<script>
import SearchAndSelectClient from "../../Default/Clients/SearchAndSelectClient";
import SelectClientForMerging from "../../Default/Clients/SelectClientForMerging";
import LoaderComponent from "../../LoaderComponent";

export default {
    data() {
        return {
            loading: false,
            step: 1,
            selectedClient: null,
            finalSelectedClient: null,
            clientToErase: null,
        };
    },
    props: {
        client: {
            type: Object,
            required: true,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        onClientSelected(client) {
            this.selectedClient = client;
            this.step++;
        },
        onFinalClientSelected(clientNum) {
            if (clientNum === 1) {
                this.finalSelectedClient = this.client;
                this.clientToErase = this.selectedClient;
            } else if (clientNum === 2) {
                this.finalSelectedClient = this.selectedClient;
                this.clientToErase = this.client;
            }
        },
        previousStep() {
            if (this.step === 2) {
                this.selectedClient = null;
                this.finalSelectedClient = null;
            }
            if (this.step > 1) this.step--;
        },
        mergeClients() {
            if (!confirm(this.$tl("questions.clients.merge"))) return;

            this.loading = true;
            axios
                .post(`/api/restaurants/${this.restaurant_id}/clients/merge`, {
                    client_to_keep_id: this.finalSelectedClient.id,
                    client_to_erase_id: this.clientToErase.id,
                })
                .then(() => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: this.$tl("success.clients.merged"),
                    });
                    this.$emit("clients-merged");
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        SearchAndSelectClient,
        LoaderComponent,
        SelectClientForMerging,
    },
};
</script>
