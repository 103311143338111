var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("label", { staticClass: "container-box" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentValue,
              expression: "currentValue",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.currentValue)
              ? _vm._i(_vm.currentValue, null) > -1
              : _vm.currentValue,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.currentValue,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.currentValue = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.currentValue = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.currentValue = $$c
              }
            },
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "checkmark" }),
      ]),
      _vm._v(" "),
      _c("show-errors", {
        staticClass: "d-block",
        attrs: { errors: _vm.errors, "error-key": _vm.errorKey },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }