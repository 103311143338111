import axios from "axios";

export const actions = {
    fetchCustomerAllPaymentMethods() {
        return axios.get("/api/stripe/payment_methods");
    },
    newPaymentMethod({ commit }, { id }) {
        return axios
            .post("/api/stripe/payment_methods", {
                id,
            })
            .then((response) => {
                commit("setCardId", id);
                return response;
            });
    },
    createSubscription({ dispatch }, { modules, billing_details, restaurant_id, training, url_suffix, promocode }) {
        url_suffix = url_suffix || "";
        return axios
            .post("/api/stripe/licences" + url_suffix, {
                modules,
                ...billing_details,
                restaurant_id,
                training,
                promocode,
            })
            .then((response) => {
                dispatch("users/fetchCurrentUser", {}, { root: true });
                return response;
            });
    },
    fetchAllSubscriptions() {
        return axios.get("/api/stripe/licences");
    },
    linkLicenceToRestaurant({ dispatch }, { licenceId, restaurantId }) {
        return axios.post(`/api/stripe/licence/${licenceId}/link/${restaurantId}`).then((response) => {
            dispatch("users/fetchCurrentUser", {}, { root: true });
            return response;
        });
    },
    getSetupIntent() {
        return axios.get("/api/stripe/cards/intent");
    },
    getSepaSetupIntent() {
        return axios.get("/api/stripe/sepa/intent");
    },
    setPaymentMethodAsDefault({}, { card }) {
        return axios.post("/api/stripe/payment_methods/default", {
            payment_method: card.id,
        });
    },
    deletePaymentMethod({}, { card }) {
        return axios.delete(`/api/stripe/payment_methods?payment_method=${card.id}`);
    },
    deleteSubscription({ dispatch }, { licenceId }) {
        return axios.delete(`/api/stripe/licences/${licenceId}`).then((response) => {
            dispatch("users/fetchCurrentUser", {}, { root: true });
            return response;
        });
    },
    reactivateSubscription({ dispatch }, { licenceId }) {
        return axios.post(`/api/stripe/licences/${licenceId}/reactivate`).then((response) => {
            dispatch("users/fetchCurrentUser", {}, { root: true });
            return response;
        });
    },
    fetchInvoices({}, { type = null, owner_id = null } = { type: null, owner_id: null }) {
        return axios.get(`/api/stripe/invoices?` + (owner_id ? `&owner_id=${owner_id}` : "") + (type ? `&type=${type}` : ""));
    },
    payPastDueSubscription({}, { licenceId }) {
        return axios.post(`/api/stripe/licences/${licenceId}/pay_past_due`);
    },
};

export default actions;
