export default {
    booking: {
        services: {
            full: "Votre restaurant est complet ?",
            delete: "Êtes-vous sûr de vouloir supprimer ce service ?",
            removeFull: "Ouvrir le service aux réservations ?",
            openServiceForDate: 'Le service "{service}" sera ouvert aux réservations pour le {date}. Voulez-vous continuer ?',
            openSlotForDate: 'Le créneau de {hour} pour le service "{service}" sera ouvert aux réservations pour le {date}. Voulez-vous continuer ?',
        },
        menus: {
            delete: "Êtes-vous sûr de vouloir supprimer ce menu ?",
        },
    },
};
