var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row m-0" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "border-light b-radius-20 p-4 mb-3 block" }, [
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("strong", [
              _vm._v(
                " " + _vm._s(_vm.$tl("labels.booking.widget.gTag.title")) + " "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row my-2" }, [
              _c("div", { staticClass: "col-md-5" }, [
                _c(
                  "label",
                  [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.$tl("labels.booking.widget.gTag.id")) +
                        "\n                                "
                    ),
                    _c("feather", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.getTooltipNotice(
                            "notices.booking.widget.gTag"
                          ),
                          expression:
                            "getTooltipNotice('notices.booking.widget.gTag')",
                        },
                      ],
                      staticClass: "ml-1 pointer tooltip-infos",
                      attrs: { type: "info" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-7" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.value || "" },
                      on: {
                        input: function ($event) {
                          return _vm.$emit("input", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }