var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "row m-0 pb-5" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _vm.loading > 0
              ? _c("LoaderComponent")
              : _c(
                  "div",
                  [
                    _vm.error
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.error) +
                              "\n                "
                          ),
                        ])
                      : _c(
                          "div",
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-0",
                              },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-inline-block",
                                      staticStyle: { "margin-top": "4px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-inline-block align-middle lead-switch",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.suspend_orders,
                                                expression: "suspend_orders",
                                              },
                                            ],
                                            staticClass:
                                              "switch align-self-center is-rounded",
                                            attrs: {
                                              type: "checkbox",
                                              disabled:
                                                !_vm.has_right_to_update_config,
                                              "true-value": false,
                                              "false-value": true,
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.suspend_orders
                                              )
                                                ? _vm._i(
                                                    _vm.suspend_orders,
                                                    null
                                                  ) > -1
                                                : _vm._q(
                                                    _vm.suspend_orders,
                                                    false
                                                  ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = _vm.suspend_orders,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? false
                                                    : true
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.suspend_orders =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.suspend_orders = $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                  }
                                                } else {
                                                  _vm.suspend_orders = $$c
                                                }
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("label", {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.getTooltip(
                                                  "Suspendre les commandes"
                                                ),
                                                expression:
                                                  "getTooltip('Suspendre les commandes')",
                                              },
                                            ],
                                            on: {
                                              click: _vm.changeSuspendOrders,
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "h2",
                                        { staticClass: "h5 d-inline-block" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-outline-secondary radius-btn-square",
                                              staticStyle: {
                                                width: "29px",
                                                "padding-left": "5px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.date = new Date(
                                                    _vm.date
                                                  ).setDate(
                                                    _vm.date.getDate() - 1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "feather feather-chevron-left sc-dnqmqq jxshSx",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "24",
                                                    height: "24",
                                                    viewBox: "0 0 24 24",
                                                    fill: "none",
                                                    stroke: "currentColor",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "aria-hidden": "true",
                                                  },
                                                },
                                                [
                                                  _c("polyline", {
                                                    attrs: {
                                                      points: "15 18 9 12 15 6",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "date-resa ml-1 mr-1 pt-1",
                                              staticStyle: {
                                                "text-transform": "uppercase",
                                              },
                                              on: { click: _vm.openPicker },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(_vm.dateString) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-outline-secondary radius-btn-square",
                                              staticStyle: {
                                                width: "29px",
                                                "padding-left": "6px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.date = new Date(
                                                    _vm.date
                                                  ).setDate(
                                                    _vm.date.getDate() + 1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "feather feather-chevron-right sc-dnqmqq jxshSx",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "24",
                                                    height: "24",
                                                    viewBox: "0 0 24 24",
                                                    fill: "none",
                                                    stroke: "currentColor",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "aria-hidden": "true",
                                                  },
                                                },
                                                [
                                                  _c("polyline", {
                                                    attrs: {
                                                      points: "9 18 15 12 9 6",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-outline-secondary none-mobile btn-circle",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.date = new Date()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        Aujourd'hui\n                                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-outline-secondary none-desk btn-circle",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.date = new Date()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        Auj\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-outline-secondary btn-circle",
                                      attrs: {
                                        disabled:
                                          !_vm.has_right_to_export_commands,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.exportCommands()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "feather feather-upload",
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            width: "24",
                                            height: "24",
                                            viewBox: "0 0 24 24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            "stroke-width": "2",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("polyline", {
                                            attrs: { points: "17 8 12 3 7 8" },
                                          }),
                                          _vm._v(" "),
                                          _c("line", {
                                            attrs: {
                                              x1: "12",
                                              y1: "3",
                                              x2: "12",
                                              y2: "15",
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                                Export Excel\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.getTooltip(
                                            "Scanner une commande"
                                          ),
                                          expression:
                                            "getTooltip('Scanner une commande')",
                                        },
                                      ],
                                      staticClass:
                                        "btn radius-btn-square btn-success scan-btn",
                                      staticStyle: {
                                        "padding-top": "3px",
                                        "padding-left": "4px",
                                      },
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showScanReservationModal = true
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-white" },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "feather css-i6dzq1",
                                              staticStyle: {
                                                width: "19px",
                                                height: "19px",
                                                "vertical-align": "text-bottom",
                                              },
                                              attrs: {
                                                viewBox: "0 0 24 24",
                                                width: "24",
                                                height: "24",
                                                stroke: "currentColor",
                                                "stroke-width": "2",
                                                fill: "none",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                              },
                                            },
                                            [
                                              _c("circle", {
                                                attrs: {
                                                  cx: "12",
                                                  cy: "12",
                                                  r: "10",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "14.31",
                                                  y1: "8",
                                                  x2: "20.05",
                                                  y2: "17.94",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "9.69",
                                                  y1: "8",
                                                  x2: "21.17",
                                                  y2: "8",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "7.38",
                                                  y1: "12",
                                                  x2: "13.12",
                                                  y2: "2.06",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "9.69",
                                                  y1: "16",
                                                  x2: "3.95",
                                                  y2: "6.06",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "14.31",
                                                  y1: "16",
                                                  x2: "2.83",
                                                  y2: "16",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "16.62",
                                                  y1: "12",
                                                  x2: "10.88",
                                                  y2: "21.94",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("datepicker", {
                              ref: "theDatePicker",
                              staticClass: "btn btn-sm date-resa date-resa-cal",
                              attrs: {
                                format: "dd/MM/yyyy",
                                "monday-first": true,
                                language: _vm.fr,
                              },
                              model: {
                                value: _vm.date,
                                callback: function ($$v) {
                                  _vm.date = $$v
                                },
                                expression: "date",
                              },
                            }),
                            _vm._v(" "),
                            _c("command-list", {
                              attrs: {
                                restaurant_id: _vm.restaurant_id,
                                commands: _vm.commands,
                                type: _vm.COLLECT_TYPE_DELIVERY.value,
                                columns: {
                                  number: true,
                                  client: true,
                                  status: {
                                    show: true,
                                    readonly: false,
                                  },
                                  date: false,
                                  slot: true,
                                  price: true,
                                  products: true,
                                  actions: {
                                    edit: true,
                                    send: true,
                                    clickable: true,
                                    print_order_form: true,
                                  },
                                },
                              },
                            }),
                          ],
                          1
                        ),
                    _vm._v(" "),
                    _vm.showScanReservationModal
                      ? _c("scan-command-modal", {
                          ref: "scanCommandModal",
                          attrs: { commands: _vm.commands.data },
                          on: {
                            "validate-command": _vm.validateCommand,
                            close: function ($event) {
                              _vm.showScanReservationModal = false
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.modals.displayShowCommandModal
        ? _c("show-command-modal", {
            attrs: {
              command_id: _vm.modals.params.selected_command_id,
              restaurant_id: _vm.modals.params.selected_restaurant_id,
            },
            on: {
              close: function ($event) {
                _vm.modals.displayShowCommandModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [
            _c(
              "h5",
              {
                staticClass: "title mt-2",
                attrs: { "data-test-id": "title-cc_delivery_sales" },
              },
              [_vm._v("Ventes")]
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }