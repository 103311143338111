<template>
    <div>
        <LoaderComponent v-if="loading" />
        <form v-else class="file-uploader-component" action="javascript:void();">
            <div>
                <label :for="formFileId" class="label-file mr-2" :class="{ 'text-muted': disabled, 'cursor-d': disabled }">{{ getLabel }}</label>
                <input
                    ref="uploadFile"
                    :disabled="disabled"
                    type="file"
                    @change="onInputFileChanges"
                    class="input-file"
                    :id="formFileId"
                    :accept="allowedExtensions" />
                <button
                    v-if="hasDirectUpload && unsaveChanges"
                    :disabled="disabled"
                    @click="postFile"
                    type="button"
                    class="btn btn-success btn-sm btn-circle">
                    {{ $tl("labels.form.actions.send") }}
                </button>
                <a v-if="checkFile()" :href="this.src" target="_blank">
                    <button type="button" class="btn btn-success btn-sm btn-circle">
                        {{ $tl("labels.form.file.view") }}
                    </button>
                </a>
                <button v-if="checkFile() && deleteURI !== null" @click="deleteFile" type="button" class="btn btn-danger btn-sm btn-circle">
                    {{ $tl("labels.form.file.delete") }}
                </button>
                <button v-if="isValidFile(file)" @click="cancel" type="button" class="btn btn-danger btn-sm btn-circle">
                    {{ $tl("labels.form.actions.cancel") }}
                </button>
            </div>
        </form>
        <ShowErrors :errors="errors" error-key="file" />
        <span class="text-danger" v-if="isFileTooBig">
            <small>{{ $t("errors.common.file.tooBig", { fileSize, maxSize }) }}</small>
        </span>
        <span class="text-danger" v-else-if="hasDirectUpload && unsaveChanges">
            <small>{{ $tl("labels.form.file.dontForgetSave") }}</small>
        </span>
    </div>
</template>

<script>
import axios from "axios";
import ShowErrors from "../errors/ShowErrors.vue";
import LoaderComponent from "../LoaderComponent";

export default {
    name: "FileUploaderComponent",
    components: {
        LoaderComponent,
        ShowErrors,
    },
    data() {
        return {
            unsaveChanges: false,
            loading: false,
            file: null,
            fileSize: null,
        };
    },
    props: {
        allowedExtensions: {
            type: String,
            default: ".pdf",
        },
        callBackDelete: {
            type: Function,
            default: null,
        },
        callBackPost: {
            type: Function,
            default: null,
        },
        callBackError: {
            type: Function,
            default: null,
        },
        deleteURI: {
            type: String,
            default: null,
        },
        src: {
            default: null,
        },
        suffixId: {
            type: String,
            required: true,
        },
        postURI: {
            type: String,
        },
        postKey: {
            type: String,
            default: "file",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        callBackOnUnsaveChanges: {
            type: Function,
            default: null,
        },
        hasDirectUpload: {
            default: true,
        },
        maxSize: {
            type: Number,
            default: null,
        },
        errors: {
            type: Object,
            default: null,
        },
        propsFile: {
            type: File,
            default: null,
        },
    },
    computed: {
        formFileId() {
            return "file-fileuploader-component-" + this.suffixId;
        },
        getLabel() {
            if (this.isValidFile(this.file) && this.file.name) {
                return this.file.name;
            }
            if (this.src && this.src !== "") {
                return this.$tl("labels.form.file.chooseNew");
            }
            return this.$tl("labels.form.file.choose");
        },
        isFileTooBig() {
            if (this.fileSize === null || this.maxSize === null) {
                return false;
            }
            return this.fileSize > this.maxSize;
        },
    },
    methods: {
        cancel() {
            this.$refs.uploadFile.value = null;
            this.file = null;
            this.fileSize = null;
            this.unsaveChanges = false;

            if (!this.hasDirectUpload) {
                this.$emit("upload-file", null);
                return;
            }
        },
        checkFile() {
            return this.src != "" && this.src != null && !this.isValidFile(this.file);
        },
        onInputFileChanges(event) {
            this.file = event.target.files[0];
            this.fileSize = this.file ? Math.ceil(this.file.size / 1048576) : null;

            if (!this.hasDirectUpload) {
                this.$emit("upload-file", this.file);
                return;
            }

            this.unsaveChanges = this.file ? true : false;
        },
        postFile() {
            if (!this.unsaveChanges) return;
            this.loading = true;

            let formData = new FormData();
            formData.append(this.postKey, document.getElementById(this.formFileId).files[0]);
            axios
                .post(this.postURI, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    this.loading = false;
                    this.unsaveChanges = false;
                    this.file = null;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    if (this.callBackPost !== null) this.callBackPost(response);
                })
                .catch((error) => {
                    this.loading = false;
                    if (this.callBackError !== null) this.callBackError(error);
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        deleteFile() {
            if (!confirm(this.$tl("questions.common.deleteFile"))) {
                return;
            }
            this.loading = true;

            axios
                .delete(this.deleteURI)
                .then((response) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    if (this.callBackDelete !== null) this.callBackDelete(response);
                })
                .catch((error) => {
                    this.loading = false;
                    if (this.callBackError !== null) this.callBackError(error);
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
    },
    watch: {
        unsaveChanges(newVal) {
            if (this.callBackOnUnsaveChanges) this.callBackOnUnsaveChanges(newVal);
        },
    },
    mounted() {
        this.file = this.propsFile;
    },
};
</script>

<style scoped>
.file-uploader-component button:disabled {
    cursor: default;
}

.cursor-d,
.cursor-d:hover {
    cursor: default !important;
}
</style>
