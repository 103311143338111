export default {
    data() {
        return {
            OPTION_BANK_FOOTPRINT: {
                label: "Empreinte bancaire",
                value: "footprint",
                icon: "credit-card",
            },
            OPTION_BANK_PAYMENT: {
                label: "Prépaiement",
                value: "payment",
                icon: "dollar-sign",
            },
        };
    },
    methods: {
        getOptionBankLabel(value) {
            const found = this.ALL_OPTION_BANKS.find((w) => w.value == value);
            if (typeof found != "undefined") {
                return this.$t(`labels.booking.payments.types.${value}`);
            }
            return value;
        },
        getOptionBankIcon(value) {
            const found = this.ALL_OPTION_BANKS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.icon;
            return value;
        },
    },
    computed: {
        ALL_OPTION_BANKS() {
            return [this.OPTION_BANK_FOOTPRINT, this.OPTION_BANK_PAYMENT];
        },
    },
};
