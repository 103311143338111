import { render, staticRenderFns } from "./GiftVouchersPayments.vue?vue&type=template&id=58bab52d&scoped=true"
import script from "./GiftVouchersPayments.vue?vue&type=script&lang=js"
export * from "./GiftVouchersPayments.vue?vue&type=script&lang=js"
import style0 from "./GiftVouchersPayments.vue?vue&type=style&index=0&id=58bab52d&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58bab52d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('58bab52d')) {
      api.createRecord('58bab52d', component.options)
    } else {
      api.reload('58bab52d', component.options)
    }
    module.hot.accept("./GiftVouchersPayments.vue?vue&type=template&id=58bab52d&scoped=true", function () {
      api.rerender('58bab52d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/GiftVouchers/GiftVouchersPayments.vue"
export default component.exports