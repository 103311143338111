<template>
    <modal @close.stop="closeFromModal">
        <h5 slot="header">Utilisateurs ayant utilisé le code promo</h5>
        <div slot="body">
            <promo-code-used-users-list :promo-code="promoCode" />
        </div>
        <div slot="footer">
            <button type="button" class="modal-default-button btn btn-secondary btn-circle" @click="close">
                {{ $t("labels.form.actions.close") }}
            </button>
        </div>
    </modal>
</template>
<script>
import PromoCodeUsedUsersList from "./PromoCodeUsedUsersList.vue";

export default {
    props: {
        promoCode: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        PromoCodeUsedUsersList,
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal(event) {
            if (event && event.target && !event.target.classList.contains("modal-wrapper")) {
                event.stopPropagation();
            } else {
                this.close();
            }
        },
    },
};
</script>
