<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ new_feature_id ? "Éditer" : "Créer" }} une nouveauté</h5>
        <div slot="body">
            <addNewFeature
                ref="addNewFeature"
                :new_feature_id="new_feature_id"
                @set-loading="loading = $event"
                @saved="$emit('saved', $event)" />
        </div>
        <div slot="footer" class="d-flex">
            <button
                class="modal-default-button btn-sm btn btn-secondary btn-circle"
                :disabled="loading > 0"
                @click="close">
                Fermer
            </button>
            <button class="btn btn-success btn-sm btn-circle ml-2" :disabled="loading > 0" @click="save">
                Enregistrer
            </button>
        </div>
    </modal>
</template>

<script>
import addNewFeature from "../../../Admin/NewFeatures/addNewFeature";

export default {
    data() {
        return {
            loading: 0,
        };
    },
    props: {
        new_feature_id: {
            default: null,
        },
    },
    methods: {
        save() {
            this.$refs.addNewFeature.save();
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        addNewFeature,
    },
};
</script>
