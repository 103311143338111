export default {
    data() {
        return {
            COMMAND_MAIL_LOG_CONFIRMATION: {
                label: "confirmation",
                value: "confirmation",
            },
        };
    },
    methods: {
        getCommandMailLogLabel(value) {
            const found = this.ALL_CC_MAIL_LOGS_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
    },
    computed: {
        ALL_CC_MAIL_LOGS_TYPES() {
            return [this.COMMAND_MAIL_LOG_CONFIRMATION];
        },
    },
};
