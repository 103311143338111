var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pr-0 pb-5" },
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                ])
              : _c("div", [
                  _c("div", { staticClass: "row m-0" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                        },
                        [
                          _c("h5", { staticClass: "title mt-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$tl(
                                  "labels.routes.booking.services.special"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-success btn-circle",
                              attrs: {
                                disabled: !_vm.has_right_to_create_service,
                                type: "button",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showAddServiceModal = true
                                },
                              },
                            },
                            [
                              _c("feather", { attrs: { type: "plus" } }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.services.special.addShort"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.formErrors
                    ? _c(
                        "div",
                        { staticClass: "alert alert-danger" },
                        _vm._l(_vm.formErrors, function (error) {
                          return _c("p", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(error) +
                                "\n                "
                            ),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formSuccess
                    ? _c("div", { staticClass: "alert alert-success" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.formSuccess) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row m-0" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _vm.services && _vm.services.length > 0
                        ? _c(
                            "table",
                            {
                              staticClass:
                                "table table-sm table-striped border-bottom",
                            },
                            [
                              _c("thead", { staticClass: "border-bottom" }, [
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.$tl("labels.form.name"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.services.category"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.services.special.datesShort"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl("labels.booking.services.pax")
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.getTooltip(
                                            _vm.$tl(
                                              "labels.booking.services.showOnDashboardLong",
                                              _vm.restaurant_id
                                            )
                                          ),
                                          expression:
                                            "getTooltip($tl('labels.booking.services.showOnDashboardLong', restaurant_id))",
                                        },
                                      ],
                                      staticClass: "text-center",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.booking.services.showOnDashboard"
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl("labels.form.actions.title")
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(_vm.services, function (service) {
                                  return _c("tr", { key: service.id }, [
                                    _c("td", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(service.name) +
                                          "\n                                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "text-transform": "capitalize",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(service.category) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm.areDatesSame(
                                        service.special_datetime_begin,
                                        service.special_datetime_end
                                      )
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.displayDate(
                                                    service.special_datetime_begin
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.filters.dates.from"
                                                  )
                                                ) +
                                                "\n                                        " +
                                                _vm._s(
                                                  _vm.displayDate(
                                                    service.special_datetime_begin
                                                  )
                                                ) +
                                                "\n                                        " +
                                                _vm._s(
                                                  _vm
                                                    .$tl(
                                                      "labels.filters.dates.to"
                                                    )
                                                    .toLowerCase()
                                                ) +
                                                "\n                                        " +
                                                _vm._s(
                                                  _vm.displayDate(
                                                    service.special_datetime_end
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(service.max_pax) +
                                          "\n                                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "text-center" }, [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "container-box mr-0",
                                          staticStyle: { width: "initial" },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  service.show_on_dashboard,
                                                expression:
                                                  "service.show_on_dashboard",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              disabled:
                                                !_vm.has_right_to_update_service ||
                                                _vm.updatingShowOnDashboard.includes(
                                                  service.id
                                                ),
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                service.show_on_dashboard
                                              )
                                                ? _vm._i(
                                                    service.show_on_dashboard,
                                                    null
                                                  ) > -1
                                                : service.show_on_dashboard,
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$a =
                                                      service.show_on_dashboard,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          service,
                                                          "show_on_dashboard",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          service,
                                                          "show_on_dashboard",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      service,
                                                      "show_on_dashboard",
                                                      $$c
                                                    )
                                                  }
                                                },
                                                function ($event) {
                                                  return _vm.toggleShowOnDashboard(
                                                    service
                                                  )
                                                },
                                              ],
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark mr-1 mt-0",
                                            class: {
                                              disabled:
                                                !_vm.has_right_to_update_service ||
                                                _vm.updatingShowOnDashboard.includes(
                                                  service.id
                                                ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.getTooltip(
                                                  _vm.$t(
                                                    "labels.form.actions.edit"
                                                  )
                                                ),
                                                expression:
                                                  "getTooltip($t('labels.form.actions.edit'))",
                                              },
                                            ],
                                            staticClass:
                                              "btn btn-sm btn-success btn-square",
                                            attrs: {
                                              to: {
                                                name: "booking.restaurants.settings.openings.edit",
                                                params: {
                                                  restaurant_id:
                                                    service.restaurant_id,
                                                  service_id: service.id,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _c("feather", {
                                              attrs: { type: "edit" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "none-mobile" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: _vm.getTooltip(
                                                      _vm.$t(
                                                        "labels.form.actions.duplicate"
                                                      )
                                                    ),
                                                    expression:
                                                      "getTooltip($t('labels.form.actions.duplicate'))",
                                                  },
                                                ],
                                                staticClass:
                                                  "btn btn-sm btn-success btn-square",
                                                class: {
                                                  disabled:
                                                    !_vm.has_right_to_create_service,
                                                },
                                                attrs: {
                                                  to: {
                                                    name: "booking.restaurants.settings.openings.add",
                                                    params: {
                                                      restaurant_id:
                                                        service.restaurant_id,
                                                      service_id: service.id,
                                                      isDuplicating: true,
                                                    },
                                                  },
                                                },
                                              },
                                              [
                                                _c("feather", {
                                                  attrs: { type: "copy" },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            !service.has_future_reservations
                                              ? _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value: _vm.getTooltip(
                                                          _vm.$t(
                                                            "labels.form.actions.delete"
                                                          )
                                                        ),
                                                        expression:
                                                          "getTooltip($t('labels.form.actions.delete'))",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "btn btn-sm btn-danger btn-square",
                                                    attrs: {
                                                      disabled:
                                                        !_vm.has_right_to_delete_service,
                                                      "data-id": service.id,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteService(
                                                          service.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("feather", {
                                                      attrs: {
                                                        type: "trash-2",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value: _vm.getTooltip(
                                                          _vm.$tl(
                                                            "infos.booking.services.cantDelete",
                                                            _vm.restaurant_id
                                                          )
                                                        ),
                                                        expression:
                                                          "getTooltip($tl('infos.booking.services.cantDelete', restaurant_id))",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "btn btn-sm btn-secondary btn-square",
                                                  },
                                                  [
                                                    _c("feather", {
                                                      attrs: {
                                                        type: "trash-2",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]
                          )
                        : _c("div", [
                            _c("em", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.services.special.empty",
                                    _vm.restaurant_id
                                  )
                                )
                              ),
                            ]),
                          ]),
                    ]),
                  ]),
                ]),
          ]),
      _vm._v(" "),
      _vm.showAddServiceModal
        ? _c("addServiceModal", {
            attrs: { isSpecial: true },
            on: {
              close: function ($event) {
                _vm.showAddServiceModal = false
              },
              "service-added": _vm.fetchData,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }