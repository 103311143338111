<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ $tl("labels.booking.waitingList.title") }}</h5>
        <div slot="body">
            <waiting-list ref="showWaitingListComponent" :service_id="service_id" :reservation_date="reservation_date" />
            <NotifyWaitingsModal
                v-if="showNotifyWaitingsModal"
                :restaurant_id="restaurant_id"
                :service_id="service_id"
                :reservation_date="reservation_date"
                @sent="onWaitingsNotified"
                @close="showNotifyWaitingsModal = false" />
        </div>
        <div slot="footer" class="d-flex">
            <button class="btn btn-sm btn-success btn-circle mr-2" @click="showNotifyWaitingsModal = true">
                {{ $tl("labels.booking.waitingList.buttons.remindCustomerWaitingList") }}
            </button>
            <button class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="close">
                {{ $tl("labels.form.actions.close") }}
            </button>
        </div>
    </modal>
</template>

<script>
import WaitingList from "../../waitinglist/WaitingList";
import NotifyWaitingsModal from "./NotifyWaitingsModal";

export default {
    name: "ShowWaitingsModal",
    data() {
        return {
            showNotifyWaitingsModal: false,
        };
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        onWaitingsNotified() {
            this.showNotifyWaitingsModal = false;
            this.close();
        },
        fetchWaitings() {
            this.$refs.showWaitingListComponent.fetchWaitings(true);
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    props: {
        service_id: {
            default: "",
        },
        reservation_date: {
            default: "",
        },
    },
    components: {
        WaitingList,
        NotifyWaitingsModal,
    },
};
</script>
