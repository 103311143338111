<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else class="div-profil">
                <div class="row">
                    <div class="col-md-7 mb-3">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row">
                                <div class="col-6">Numéro</div>
                                <div class="col-6 value">5720</div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-6">Montant</div>
                                <div class="col-6 value">50,00€</div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-6">TVA</div>
                                <div class="col-6 value">10,00€</div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-6">Statut</div>
                                <div class="col-6 value">Payé</div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-6">Frais Stripe</div>
                                <div class="col-6 value">2,34 €</div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-6">Date</div>
                                <div class="col-6 value">22/12/2020</div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-12 mb-1">Commentaire public</div>
                                <div class="col-12 value">Test</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="border-light b-radius-20 p-4 text-center">
                            <div class="row mb-2">
                                <div class="col-12">
                                    <div class="rounded-circle div-profil-svg">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            aria-hidden="true"
                                            data-reactid="1306"
                                            class="feather feather-user sc-dnqmqq jxshSx">
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row mb-0">
                                <div class="col-12">
                                    <small>Madame</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-uppercase font-weight-bold">Alexane Roux</div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-12">contact@noshow.io</div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-12">06 88 67 39 20</div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12">
                                    <router-link
                                        :to="{
                                            name: 'click_and_collect.clients.form',
                                            params: { client_id: sale.client.id },
                                        }"
                                        class="btn btn-sm btn-success btn-circle">
                                        Voir la fiche client
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent";
import moment from "moment";

moment.locale("fr");

export default {
    data() {
        return {
            loading: false,
            error: null,
        };
    },

    methods: {
        formatDateTime(dateTime) {
            return moment(dateTime).format("Do dddd MMMM YYYY à HH:mm");
        },
        formatDateToFr(date) {
            return moment(date).format("DD/MM/Y");
        },
        getSaleStatusLabel(status) {
            switch (status) {
                case "paid":
                    return "Payé";
                case "refund":
                    return "Remboursé";
                case "pending":
                    return "En attente";
                case "canceled":
                    return "Annulé";
                default:
                    return "";
            }
        },
        getSaleTypeLabel(sale_type) {
            switch (sale_type) {
                case "bo":
                    return "manuel";
                case "widget":
                    return "client";
                default:
                    return "";
            }
        },
        getPaymentTypeLabel(payment_type) {
            switch (payment_type) {
                case "cb":
                    return "Carte bancaire";
                case "cheque":
                    return "Chèque";
                case "cash":
                    return "Espèces";
                case "bank_transfer":
                    return "Virement bancaire";
                default:
                    return "";
            }
        },
        getSaleExpeditionTypeLabel(expedition_type) {
            switch (expedition_type) {
                case "email":
                    return "mail";
                case "postal_mail":
                    return "courrier";
                default:
                    return "";
            }
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>
