var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.reservation.logs &&
    _vm.reservation.logs.data &&
    _vm.reservation.logs.data.length > 0
      ? _c(
          "div",
          { staticClass: "mt-3" },
          _vm._l(_vm.reservation.logs.data, function (log) {
            return _c("ShowReservationLog", {
              key: log.id,
              attrs: { log: log },
            })
          }),
          1
        )
      : _c("span", { staticClass: "text-muted mt-3 d-inline-block" }, [
          _vm._v(_vm._s(_vm.$tl("labels.booking.reservations.logs.empty"))),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }