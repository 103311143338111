<template>
    <promo-code-fields :promo-code="parentForm" is-preview />
</template>
<script>
import PromoCodeFields from "../Show/PromoCodeFields.vue";

export default {
    props: {
        parentForm: {
            type: Object,
            required: true,
        },
    },
    components: {
        PromoCodeFields,
    },
};
</script>
