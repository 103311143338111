<template>
    <div class="pb-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2" data-test-id="title-cc_delivery_sales">Ventes</h5>
                </div>
            </div>
        </div>
        <div class="row m-0 pb-5">
            <div class="col-12">
                <LoaderComponent v-if="loading > 0" />
                <div v-else>
                    <div v-if="error" class="alert alert-danger">
                        {{ error }}
                    </div>
                    <div v-else>
                        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-0">
                            <div>
                                <div style="margin-top: 4px" class="d-inline-block">
                                    <div class="d-inline-block align-middle lead-switch">
                                        <input
                                            type="checkbox"
                                            :disabled="!has_right_to_update_config"
                                            class="switch align-self-center is-rounded"
                                            :true-value="false"
                                            :false-value="true"
                                            v-model="suspend_orders" />
                                        <label v-tooltip="getTooltip('Suspendre les commandes')" @click="changeSuspendOrders"></label>
                                    </div>
                                    <h2 class="h5 d-inline-block">
                                        <button
                                            @click="date = new Date(date).setDate(date.getDate() - 1)"
                                            class="btn btn-sm btn-outline-secondary radius-btn-square"
                                            style="width: 29px; padding-left: 5px">
                                            <svg
                                                class="feather feather-chevron-left sc-dnqmqq jxshSx"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                aria-hidden="true">
                                                <polyline points="15 18 9 12 15 6"></polyline>
                                            </svg>
                                        </button>

                                        <span class="date-resa ml-1 mr-1 pt-1" style="text-transform: uppercase" @click="openPicker">
                                            {{ dateString }}
                                        </span>

                                        <button
                                            @click="date = new Date(date).setDate(date.getDate() + 1)"
                                            class="btn btn-sm btn-outline-secondary radius-btn-square"
                                            style="width: 29px; padding-left: 6px">
                                            <svg
                                                class="feather feather-chevron-right sc-dnqmqq jxshSx"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                aria-hidden="true">
                                                <polyline points="9 18 15 12 9 6"></polyline>
                                            </svg>
                                        </button>

                                        <button
                                            type="button"
                                            class="btn btn-sm btn-outline-secondary none-mobile btn-circle"
                                            @click="date = new Date()">
                                            Aujourd'hui
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-sm btn-outline-secondary none-desk btn-circle"
                                            @click="date = new Date()">
                                            Auj
                                        </button>
                                    </h2>
                                </div>
                            </div>

                            <div>
                                <button
                                    :disabled="!has_right_to_export_commands"
                                    @click="exportCommands()"
                                    class="btn btn-sm btn-outline-secondary btn-circle">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-upload">
                                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                        <polyline points="17 8 12 3 7 8"></polyline>
                                        <line x1="12" y1="3" x2="12" y2="15"></line>
                                    </svg>
                                    Export Excel
                                </button>

                                <button
                                    type="button"
                                    class="btn radius-btn-square btn-success scan-btn"
                                    style="padding-top: 3px; padding-left: 4px"
                                    @click="showScanReservationModal = true"
                                    v-tooltip="getTooltip('Scanner une commande')">
                                    <span class="text-white">
                                        <svg
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather css-i6dzq1"
                                            style="width: 19px; height: 19px; vertical-align: text-bottom">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <line x1="14.31" y1="8" x2="20.05" y2="17.94"></line>
                                            <line x1="9.69" y1="8" x2="21.17" y2="8"></line>
                                            <line x1="7.38" y1="12" x2="13.12" y2="2.06"></line>
                                            <line x1="9.69" y1="16" x2="3.95" y2="6.06"></line>
                                            <line x1="14.31" y1="16" x2="2.83" y2="16"></line>
                                            <line x1="16.62" y1="12" x2="10.88" y2="21.94"></line>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <datepicker
                            class="btn btn-sm date-resa date-resa-cal"
                            ref="theDatePicker"
                            format="dd/MM/yyyy"
                            :monday-first="true"
                            :language="fr"
                            v-model="date"></datepicker>

                        <command-list
                            :restaurant_id="restaurant_id"
                            :commands="commands"
                            :type="COLLECT_TYPE_DELIVERY.value"
                            :columns="{
                                number: true,
                                client: true,
                                status: {
                                    show: true,
                                    readonly: false,
                                },
                                date: false,
                                slot: true,
                                price: true,
                                products: true,
                                actions: {
                                    edit: true,
                                    send: true,
                                    clickable: true,
                                    print_order_form: true,
                                },
                            }" />
                    </div>
                    <scan-command-modal
                        v-if="showScanReservationModal"
                        ref="scanCommandModal"
                        :commands="commands.data"
                        @validate-command="validateCommand"
                        @close="showScanReservationModal = false" />
                </div>
            </div>
        </div>
        <show-command-modal
            :command_id="modals.params.selected_command_id"
            :restaurant_id="modals.params.selected_restaurant_id"
            @close="modals.displayShowCommandModal = false"
            v-if="modals.displayShowCommandModal" />
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import ScanCommandModal from "../../../components/Modals/clickAndCollect/Scan.vue";
import addClickAndCollectSaleModal from "../../../components/Modals/clickAndCollect/addClickAndCollectSaleModal.vue";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import ClickAndCollectStatusTag from "../../../components/clickAndCollect/Command/StatusTag";
import ShowCommandModal from "../../../components/Modals/clickAndCollect/ShowClickAndCollectCommand";
import CommandList from "../../../components/clickAndCollect/Command/CommandList";
import CollectTypesEnum from "../../../mixins/enums/click_and_collect/CollectTypesEnum";

export default {
    data() {
        return {
            loading: 0,
            error: null,
            date_: new Date(),
            commands_: {
                data: [],
            },
            suspend_orders: true,
            showScanReservationModal: false,
            fr,
            modals: {
                params: {
                    selected_command_id: null,
                    selected_restaurant_id: null,
                },
                displayShowCommandModal: false,
            },
            socket: {
                channel: null,
            },
        };
    },
    mixins: [CollectTypesEnum],
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_config() {
            return this.rights.includes("click_and_collect.config.update");
        },
        has_right_to_export_commands() {
            return this.rights.includes("click_and_collect.commands.export");
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        commands() {
            return {
                ...this.commands_,
                data: this.commands_.data.filter((c) => c.status !== "pending").sort(this.sortCommandsHandler),
            };
        },
        date: {
            get: function () {
                if (this.$route.query.date) {
                    return moment(this.$route.query.date).toDate();
                } else {
                    return this.date_;
                }
            },
            set: function (value) {
                const momentNewDate = moment(value).startOf("day");
                const momentOldDate = moment(this.date).startOf("day");

                if (momentNewDate.diff(momentOldDate, "days") !== 0) {
                    this.$router.push({
                        name: "click_and_collect.restaurants.sales.delivery",
                        params: { restaurant_id: this.restaurant_id },
                        query: { date: momentNewDate.format("Y-MM-DD") },
                    });
                } else {
                    this.fetchData();
                }
            },
        },
        dateString() {
            var date = this.date;

            return `${this.getDayName(date)} ${this.cleanDate(date)}`;
        },
    },
    created() {
        this.registerSocketsForDate();
        this.fetchData();
    },
    beforeDestroy() {
        this.unregisterAllSockets();
    },
    methods: {
        exportCommands() {
            if (!this.has_right_to_export_commands) return;
            let api_url = `/api/click_and_collect/${this.$route.params.restaurant_id}/export/commands/${
                this.COLLECT_TYPE_DELIVERY.value
            }?date=${moment(this.date).format("Y-MM-DD")}`;
            window.open(api_url, "_blank");
        },
        fetchIsOpen() {
            this.loading++;
            this.$store
                .dispatch("ccCommands/areCommandClosedForDate", {
                    restaurant_id: this.restaurant_id,
                    params: {
                        date: this.dateToString(this.date),
                        type: this.COLLECT_TYPE_DELIVERY.value,
                    },
                })
                .then((response) => {
                    this.suspend_orders = response.data.value;
                })
                .catch((error) => {
                    this.suspend_orders = false;
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading--;
                });
        },
        fetchData() {
            this.loading++;
            this.error = null;

            this.fetchIsOpen();

            this.$store
                .dispatch("ccCommands/fetchCommandsAtDate", {
                    restaurant_id: this.restaurant_id,
                    params: {
                        date: this.dateToString(this.date),
                        type: this.COLLECT_TYPE_DELIVERY.value,
                    },
                })
                .then((response) => {
                    this.commands_ = response.data;
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading--;
                });
        },
        openPicker() {
            this.$refs.theDatePicker.showCalendar();
        },
        dateToString(date) {
            var year = date.getFullYear();
            var month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : "0" + month;
            var day = date.getDate().toString();
            day = day.length > 1 ? day : "0" + day;
            return year + "-" + month + "-" + day;
        },
        getDayName(date) {
            var new_date = new Date(date);
            var options = { weekday: "long" };
            return new_date.toLocaleDateString("fr-FR", options);
        },
        cleanDate(date) {
            var new_date = new Date(date);
            var options = { month: "long", day: "numeric" };
            return new_date.toLocaleDateString("fr-FR", options);
        },
        suspendOrdersForDate() {
            var dateString = moment(this.date).format("YYYY-MM-DD");
            this.suspend_orders = true;
            this.$store
                .dispatch("ccCommands/closeCommandForDate", {
                    restaurant_id: this.restaurant_id,
                    params: {
                        date: this.dateToString(this.date),
                        type: this.COLLECT_TYPE_DELIVERY.value,
                    },
                })
                .then(() => {
                    this.notifySuccess(null, `Les commandes ont bien été fermées pour le ${dateString}`);
                })
                .catch((error) => {
                    this.notifyError(error);
                    this.suspend_orders = false;
                });
        },
        openOrdersAtDate() {
            var dateString = moment(this.date).format("YYYY-MM-DD");
            this.suspend_orders = false;
            this.$store
                .dispatch("ccCommands/openCommandForDate", {
                    restaurant_id: this.restaurant_id,
                    params: {
                        date: this.dateToString(this.date),
                        type: this.COLLECT_TYPE_DELIVERY.value,
                    },
                })
                .then(() => {
                    this.notifySuccess(null, `Les commandes ont bien été ouvertes pour le ${dateString}`);
                })
                .catch((error) => {
                    this.notifyError(error);
                    this.suspend_orders = true;
                });
        },
        changeSuspendOrders() {
            if (!this.has_right_to_update_config) return;
            if (this.suspend_orders) {
                this.openOrdersAtDate();
            } else {
                this.suspendOrdersForDate();
            }
        },
        showCommand(command, event) {
            const excludedTags = ["BUTTON", "A"];

            if (!event || !event.target || !excludedTags.includes(event.target.tagName)) {
                this.modals.params.selected_command_id = command.id;
                this.modals.params.selected_restaurant_id = command.restaurant_id;
                this.$nextTick(() => {
                    this.modals.displayShowCommandModal = true;
                });
            }
        },
        registerSocketsForDate() {
            const channelName = `App.restaurant.${this.restaurant_id}.clickandcollect.date.${moment(this.date).format("YYYY-MM-DD")}`;

            Echo.private(channelName)
                .listen(".command.created", this.onCommandCreated)
                .listen(".command.updated", this.onCommandUpdated)
                .listen(".command.deleted", this.onCommandDeleted);

            this.socket.channel = channelName;
            this.$store.commit("sockets/addChannel", channelName);
        },
        unregisterSockets() {
            Echo.leave(this.socket.channel);
            this.$store.commit("sockets/removeChannel", this.socket.channel);
            this.socket.channel = null;
        },
        unregisterAllSockets() {
            this.$store.getters["sockets/getChannels"].forEach((channel) => {
                Echo.leave(channel);
            });

            this.$store.commit("sockets/removeAllChannels");
        },
        onCommandCreated(e) {
            const newCommand = e.command;
            newCommand.new = true;
            const index = this.commands_.data.push(newCommand) - 1;
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        newCommand.new = false;
                        this.$set(this.commands_.data, index, newCommand);
                    });
                });
            });
        },
        onCommandUpdated(e) {
            const updatedCommand = e.command;
            const index = this.commands_.data.findIndex((c) => c.id == updatedCommand.id);
            const oldCommand = this.commands_.data[index];
            if (oldCommand.status === "pending" && oldCommand.status !== updatedCommand.status) {
                updatedCommand.new = true;
            } else {
                updatedCommand.updated = true;
            }
            this.$set(this.commands_.data, index, updatedCommand);
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        if (updatedCommand.new === true) {
                            updatedCommand.new = false;
                        } else {
                            updatedCommand.updated = false;
                        }
                        this.$set(this.commands_.data, index, updatedCommand);
                    });
                });
            });
        },
        onCommandDeleted(e) {
            const deletedCommand = e.command;
            const index = this.commands_.data.findIndex((c) => c.id == deletedCommand.id);

            if (index > -1) {
                var oldCommand = this.commands_.data[index];

                oldCommand.deleted = true;

                this.$set(this.commands_.data, index, oldCommand);

                this.$nextTick(() => {
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            oldCommand.deleted = false;

                            this.commands_.data.splice(index, 1);
                        });
                    });
                });
            }
        },
        sortCommandsHandler(commandA, commandB) {
            const statusScore = {
                pending: 0,
                to_prepare: 1,
                ready: 2,
                collected: 3,
                canceled: 4,
            };

            return statusScore[commandA.status] - statusScore[commandB.status];
        },
        validateCommand({ command_id, restaurant_id }) {
            this.$store
                .dispatch("ccCommands/updateStatus", {
                    restaurant_id,
                    command_id,
                    status: "collected",
                })
                .then(() => {
                    this.notifySuccess(null, "La commande a été validée");
                })
                .catch(() => {
                    this.notifyError(null, "Impossible de valider la commande");
                });
        },
    },
    watch: {
        restaurant_id() {
            this.unregisterSockets();
            this.registerSocketsForDate();
            this.fetchData();
        },
        date() {
            this.unregisterSockets();
            this.registerSocketsForDate();
            this.fetchData();
        },
    },
    components: {
        Datepicker,
        LoaderComponent,
        ScanCommandModal,
        addClickAndCollectSaleModal,
        ClickAndCollectStatusTag,
        ShowCommandModal,
        CommandList,
    },
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
