var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.log.changed_columns, function (column) {
      return _c("div", { key: column, staticClass: "row mt-2" }, [
        _c(
          "div",
          { staticClass: "col-md-9" },
          [
            _c("feather", {
              staticClass: "mr-2",
              attrs: { type: _vm.getCommandColumnIcon(column, _vm.log) },
            }),
            _vm._v(" "),
            _c("strong", [_vm._v(_vm._s(_vm.userDisplayable))]),
            _vm._v(
              " a modifié " +
                _vm._s(_vm.getCommandColumnLabel(column, _vm.log)) +
                " de la commande :\n            "
            ),
            _c("strong", [
              _vm._v(_vm._s(_vm.getCommandColumnValueLabel(column, _vm.log))),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-3 text-right" }, [
          _c("small", [_vm._v(_vm._s(_vm.createdAt))]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }