<template>
    <div class="pb-5 campaign">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Campagnes</h5>
                    <button @click="addCampaign" class="btn btn-sm btn-success btn-circle">
                        <feather type="plus"></feather>
                        Créer une campagne
                    </button>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div class="row m-0 pt-2">
                <div class="container-fluid" v-if="campaigns.length > 0">
                    <div class="row">
                        <div class="col-12 mt-2">
                            <div class="border-bottom">
                                <table class="capitalize table table-book table-striped table-sm">
                                    <thead class="border-bottom">
                                        <tr>
                                            <th>
                                                <svg
                                                    style="width: 20px; height: 20px"
                                                    class="feather feather-user sc-dnqmqq jxshSx none-desk"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    aria-hidden="true"
                                                    data-reactid="1326">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg>
                                                <span class="none-mobile">Nom</span>
                                            </th>
                                            <th>
                                                <svg
                                                    style="width: 20px; height: 20px"
                                                    class="feather feather-user sc-dnqmqq jxshSx none-desk"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    aria-hidden="true"
                                                    data-reactid="1326">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg>
                                                <span class="none-mobile">Type</span>
                                            </th>
                                            <th>
                                                <svg
                                                    style="width: 20px; height: 20px"
                                                    class="feather feather-user sc-dnqmqq jxshSx none-desk"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    aria-hidden="true"
                                                    data-reactid="1326">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg>
                                                <span class="none-mobile">Statut</span>
                                            </th>
                                            <th>
                                                <svg
                                                    style="width: 20px; height: 20px"
                                                    class="feather feather-user sc-dnqmqq jxshSx none-desk"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    aria-hidden="true"
                                                    data-reactid="1326">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg>
                                                <span class="none-mobile">Date d'envoi</span>
                                            </th>
                                            <th>
                                                <svg
                                                    style="width: 20px; height: 20px"
                                                    class="feather feather-user sc-dnqmqq jxshSx none-desk"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    aria-hidden="true"
                                                    data-reactid="1326">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg>
                                                <span class="none-mobile">Nb Envois</span>
                                            </th>
                                            <th>
                                                <svg
                                                    style="width: 20px; height: 20px"
                                                    class="feather feather-user sc-dnqmqq jxshSx none-desk"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    aria-hidden="true"
                                                    data-reactid="1326">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg>
                                                <span class="none-mobile">Nb Ouvertures</span>
                                            </th>
                                            <th>
                                                <svg
                                                    style="width: 20px; height: 20px"
                                                    class="feather feather-user sc-dnqmqq jxshSx none-desk"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    aria-hidden="true"
                                                    data-reactid="1326">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg>
                                                <span class="none-mobile">Nb Clics</span>
                                            </th>
                                            <th class="none-mobile">
                                                <svg
                                                    style="width: 20px; height: 20px"
                                                    class="feather feather-user sc-dnqmqq jxshSx none-desk"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    aria-hidden="true"
                                                    data-reactid="1326">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg>
                                                <span class="none-mobile">Actions</span>
                                            </th>
                                        </tr>

                                        <tr />
                                    </thead>
                                    <tbody>
                                        <tr v-for="campaign in campaigns" :key="campaign.id">
                                            <td>{{ campaign.name }}</td>
                                            <td>{{ getCampaignTypeLabel(campaign.type) }}</td>
                                            <td :class="getStatusClass(campaign)">
                                                {{ getStatusLabel(campaign) }}
                                            </td>
                                            <td>
                                                {{ campaign.sent_at != null ? moment(campaign.sent_at).format("LLL") : "-" }}
                                            </td>
                                            <td>
                                                <span v-if="campaign.is_sending">En cours d'envoi ({{ campaign.nb_sent || 0 }})</span>
                                                <span v-else>{{ campaign.sent_at != null ? campaign.nb_sent : "-" }}</span>
                                            </td>
                                            <td>
                                                <span v-if="campaign.type == CAMPAIGN_TYPE_SMS.value">Indisponible</span>
                                                <span v-else-if="campaign.sent_at != null">{{ campaign.nb_opened }}</span>
                                                <span v-else>-</span>
                                            </td>
                                            <td>
                                                <span v-if="campaign.type == CAMPAIGN_TYPE_SMS.value">Indisponible</span>
                                                <span v-else-if="campaign.sent_at != null">{{ campaign.nb_clicked }}</span>
                                                <span v-else>-</span>
                                            </td>
                                            <td class="none-mobile">
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-outline-secondary btn-square none-tablet"
                                                    @click="showCampaign(campaign.id)"
                                                    v-tooltip="getTooltip('Voir les détails')">
                                                    <feather type="eye" />
                                                </button>
                                                <button
                                                    v-if="!campaign.sent_at"
                                                    type="button"
                                                    class="btn btn-sm btn-success btn-square"
                                                    @click="editCampaign(campaign.id)"
                                                    v-tooltip="getTooltip('Éditer')">
                                                    <feather type="edit" />
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="col-lg-6 offset-lg-3 pt-5 text-center">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <h4 class="mb-4">Vous n'avez pas encore créé de campagne</h4>
                        <button class="btn btn-success btn-circle" @click="addCampaign()">Créer ma première campagne</button>
                        <img class="mt-5 none-mobile" width="450" src="/images/no-campaign.png" alt="No Show" />
                        <img class="mt-3 none-desk" width="300" src="/images/no-campaign.png" alt="No Show" />
                    </div>
                </div>
            </div>

            <add-campaign-modal
                ref="addCampaignModal"
                v-if="showCampaignAddModal"
                :campaign_id="campaignId"
                @close="showCampaignAddModal = false"
                @saved="campaignSaved" />

            <show-campaign-modal v-if="showCampaignShowModal" :campaign_id="campaignId" @close="showCampaignShowModal = false" />
        </div>
    </div>
</template>

<script>
import addCampaignModal from "../../../components/Modals/Admin/Campaigns/addCampaignModal.vue";
import ShowCampaignModal from "../../../components/Modals/Admin/Campaigns/ShowCampaignModal.vue";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import axios from "axios";
import moment from "moment";
import CampaignTypesEnum from "../../../mixins/enums/campaigns/CampaignTypesEnum";

export default {
    data() {
        return {
            loading: false,
            showCampaignAddModal: false,
            showCampaignShowModal: false,
            campaigns: [],
            campaignId: null,
            moment,
        };
    },
    mixins: [CampaignTypesEnum],
    methods: {
        addCampaign() {
            this.campaignId = null;
            this.showCampaignAddModal = true;
        },
        editCampaign(campaign_id) {
            this.campaignId = campaign_id;
            this.showCampaignAddModal = true;
        },
        showCampaign(campaign_id) {
            this.campaignId = campaign_id;
            this.showCampaignShowModal = true;
        },
        campaignSaved() {
            this.showCampaignAddModal = false;
            this.fetchData();
        },
        fetchData() {
            this.loading = true;
            this.campaigns = [];

            axios
                .get("/api/campaigns/admin/campaigns?include=nb_sent,nb_opened,nb_clicked")
                .then((response) => {
                    this.loading = false;
                    this.campaigns = response.data.campaigns.data;
                })
                .catch((error) => {
                    this.loading = false;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
        getStatusClass(campaign) {
            if (campaign.sent_at || campaign.will_be_send_at) return "text-success";
            return "text-muted";
        },
        getStatusLabel(campaign) {
            if (campaign.sent_at) return "Envoyée";
            if (campaign.will_be_send_at) return "Programmée";
            return "Brouillon";
        },
    },
    components: {
        addCampaignModal,
        LoaderComponent,
        ShowCampaignModal,
    },
    created() {
        this.fetchData();
    },
};
</script>
