<template>
    <LoaderComponent v-if="loading" />
    <div v-else>
        <div v-if="error" class="alert alert-danger">
            {{ error }}
        </div>
        <div v-else>
            <show-gift-voucher :sale="gv_sale" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../LoaderComponent";
import showGiftVoucher from "../giftVouchers/showGiftVoucher";

export default {
    data() {
        return {
            loading: false,
            error: null,
        };
    },
    props: {
        gv_sale: {
            type: Object,
            required: true,
        },
    },
    methods: {
        isLoading() {
            return this.loading;
        },
        markGvSaleAsUsed() {
            this.loading = true;

            axios
                .put(`/api/gift_vouchers/${this.$route.params.restaurant_id}/sales/${this.gv_sale.id}/updateUsed`, {
                    used: true,
                })
                .then((response) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    this.$emit("updated");
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: error.response.data.message
                            ? error.response.data.message
                            : "Une erreur est survenue lors de la modification du bon cadeau",
                    });
                });
        },
    },
    components: {
        LoaderComponent,
        showGiftVoucher,
    },
};
</script>
