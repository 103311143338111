<template>
    <div class="w-100">
        <loader-component v-show="loading" />
        <div v-show="!loading && error">
            {{ error }}
        </div>
        <div v-show="!loading && !error" class="default-home">
            <div class="row m-0">
                <div class="col-12 mb-3">
                    <div class="d-flex flex-column align-items-center flex-md-row">
                        <h5 class="title mt-2">{{ $tl("labels.areasEstablishments.title") }}</h5>
                    </div>
                </div>
            </div>
            <div v-if="zones.length < 1" class="border-light b-radius-20 p-3 mb-3">
                <div class="col-12">
                    {{ $tl("labels.areasEstablishments.noAreas") }}
                </div>
            </div>

            <zone-tab-form
                v-for="zone in zones"
                :key="zone.id"
                :zone="zone"
                :restaurants="restaurants"
                :associated-restaurants="zone.restaurants && zone.restaurants.length > 0 ? zone.restaurants.map((r) => r.id) : []"
                @delete-zone="deleteZone"
                @edit-zone="updateZone"
                @update="zone.name = $event" />

            <button class="btn btn-sm btn-success btn-circle ml-1" type="button" @click="showAddZoneModal = true">
                {{ $tl("labels.areasEstablishments.createArea") }}
            </button>

            <add-zone-modal v-if="showAddZoneModal" @close="showAddZoneModal = false" @create-zone="addZone" :restaurants="restaurants" />
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import addZoneModal from "./Zones/addZoneModal.vue";
import ZoneTabForm from "./Zones/ZoneTabForm.vue";

export default {
    name: "ManageZones",
    data() {
        return {
            error: null,
            loading: false,
            restaurants: [],
            zones: [],
            showAddZoneModal: false,
        };
    },
    created() {
        this.fetchRestaurants();
        this.fetchZones();
    },
    computed: {
        ownerId() {
            return this.$store.getters["users/ownerId"];
        },
    },
    methods: {
        fetchZones() {
            this.loading = true;
            this.error = null;
            this.zones = [];

            this.httpGet(`/api/owners/${this.ownerId}/zones`)
                .then((response) => {
                    if (response !== false) {
                        this.zones = response.data.data;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchRestaurants() {
            this.error = null;
            this.loading = true;
            this.restaurants = [];

            this.$store
                .dispatch("restaurants/fetchAllRestaurants")
                .then((response) => {
                    this.restaurants = response.data.data;
                    if (this.isClubMedOwnerId(this.ownerId)) {
                        this.restaurants.sort((a, b) => a.name.localeCompare(b.name));
                    }
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addZone(newZone) {
            this.loading = true;
            this.error = null;

            const payload = {
                name: newZone.name,
                restaurants: newZone.restaurants || [],
            };

            this.httpPost(`/api/owners/${this.ownerId}/zones`, payload, { defaultMessage: this.$tl("success.zones.added") })
                .then((response) => {
                    if (response !== false) {
                        this.zones.push(response.data.data);
                        this.showAddZoneModal = false;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        deleteZone(zoneId) {
            this.httpDelete(`/api/owners/${this.ownerId}/zones/${zoneId}`, { defaultMessage: this.$tl("success.zones.deleted") })
                .then((response) => {
                    if (response !== false) {
                        const index = this.zones.findIndex((zone) => zone.id === zoneId);
                        if (index !== -1) {
                            this.zones.splice(index, 1);
                        }
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        updateZone(zone) {
            this.loading = true;
            this.error = null;

            const payload = {
                name: zone.name,
                restaurants: zone.restaurants || [],
            };
            this.httpPut(`/api/owners/${this.ownerId}/zones/${zone.id}`, payload, { defaultMessage: this.$tl("success.zones.updated") })
                .then((response) => {
                    const index = this.zones.findIndex((z) => z.id === zone.id);
                    if (index !== -1) {
                        this.$set(this.zones, index, response.data.data);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    components: { LoaderComponent, addZoneModal, ZoneTabForm },
};
</script>
