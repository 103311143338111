<template>
    <div>
        <loader-component v-if="isLoading" />
        <div v-else>
            <h6 class="mb-3">{{ $tl("labels.rules") }}</h6>
            <div>
                <label class="container-box mr-0" style="width: initial; margin-top: 5px">
                    <input type="checkbox" :disabled="!hasRightToEdit" v-model="loyaltyConfigReset.enabled" />
                    <span style="margin-top: 0px" class="checkmark mr-1 mt-lg-1" :class="{ disabled: !hasRightToEdit }"></span>
                    {{ $tl(`labels.clients.loyaltyConfigs.resetFrom.${moduleEnum.value}`) }}
                    <input
                        type="number"
                        class="form-control d-inline-block ml-1 mr-1 p-1"
                        style="width: 70px; height: 30px; opacity: 1; cursor: initial"
                        :disabled="!loyaltyConfigReset.enabled || !hasRightToEdit"
                        v-model.number="loyaltyConfigReset.from"
                        :min="1" />
                    {{ $tl("labels.day") }}
                </label>
                <show-errors class="d-block" :errors="errors.form" error-key="enabled"></show-errors>
                <show-errors class="d-block" :errors="errors.form" error-key="from"></show-errors>
            </div>
            <div class="mt-1">
                <button type="button" class="btn btn-sm btn-success btn-circle" :disabled="!hasRightToEdit" @click="saveLoyaltyReset">
                    {{ $tl("labels.form.actions.save") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../../LoaderComponent.vue";
import ShowErrors from "../../../errors/ShowErrors.vue";

export default {
    data() {
        return {
            isLoading: false,
            errors: {
                form: null,
            },
            loyaltyConfigReset: {
                enabled: false,
                from: null,
            },
        };
    },
    props: {
        moduleEnum: {
            type: Object,
            required: true,
        },
        restaurantId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        hasRightToEdit() {
            return this.rights.includes("default.loyalties.update");
        },
    },
    methods: {
        fetchData() {
            this.isLoading = true;

            this.httpGet(`/api/restaurants/clients/loyaltyReset/${this.moduleEnum.value}`)
                .then((response) => {
                    if (response !== false && typeof response.data.from !== "undefined") {
                        this.loyaltyConfigReset.enabled = true;
                        this.loyaltyConfigReset.from = response.data.from;
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        saveLoyaltyReset() {
            this.isLoading = true;
            this.errors.form = null;

            this.httpPut(`/api/restaurants/clients/loyaltyReset/${this.moduleEnum.value}`, this.loyaltyConfigReset).finally(
                () => (this.isLoading = false)
            );
        },
    },
    created() {
        this.fetchData();
    },
    components: {
        LoaderComponent,
        ShowErrors,
    },
};
</script>
