var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(_vm._s(_vm.$tl("labels.booking.reservations.edit.title"))),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _c("main-reservation", {
          ref: "mainReservation",
          attrs: {
            restaurant_id: _vm.restaurant_id,
            reservation_id: _vm.reservation_id,
            showSaveButton: false,
          },
          on: {
            "enable-save-button": function ($event) {
              _vm.saveButtonEnabled = $event
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.cancel")) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-success btn-circle ml-2",
            attrs: { type: "button", disabled: !_vm.saveButtonEnabled },
            on: { click: _vm.save },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.save")) +
                "\n        "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }