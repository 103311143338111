<template>
    <div class="container-fluid">
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else-if="reservation">
                <div class="row">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div>
                                <p class="mb-2">
                                    Date de la réservation :
                                    {{ displayDate(reservation.reservation_datetime, DATE_SHORT) }}
                                </p>
                                <p class="mb-2" v-if="reservation.client">
                                    Client : {{ reservation.client.firstname }} {{ reservation.client.lastname }}
                                </p>
                                <p class="mb-2">Montant réglé (si prépaiement) : {{ formatCurrency(stripeIntentAmount / 100) }}</p>
                                <p>
                                    {{ $tl("labels.booking.services.footprint.amountToCapture", selected_restaurant_id) }}{{ $tl("labels.colon") }}
                                    {{ formatCurrency(reservation.amount_if_noshow / 100) }}
                                </p>
                                <p>Montant total de l'empreinte : {{ formatCurrency(stripeIntentAmount / 100) }}</p>
                                <p v-if="isStripeIntentAmountOverReservationSize" class="text-danger">
                                    Attention le nombre de personnes à été modifié pour cette réservation. L'empreinte bancaire est disponible pour
                                    {{ stripeIntentAmount / reservation.amount_if_noshow }} personnes
                                </p>
                            </div>
                            <div v-if="showSaveButton">
                                <div class="dropdown" v-if="reservation.client && stripeIntentAmount">
                                    <button
                                        class="btn btn-danger btn-circle btn mt-4 dropdown-toggle"
                                        type="button"
                                        id="dropdownMenu2"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false">
                                        Prélever
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                        <button
                                            v-for="n in reservation.nb_pers + reservation.nb_children"
                                            :key="n"
                                            @click="takeNoShow(n)"
                                            class="dropdown-item"
                                            type="button"
                                            :disabled="n * reservation.amount_if_noshow > stripeIntentAmount">
                                            {{ n }} personne(s) ({{ (n * reservation.amount_if_noshow) / 100 }}€)
                                        </button>
                                    </div>
                                </div>
                                <button @click="markNoShow" class="btn btn btn-warning btn-circle text-white mt-4">
                                    {{ $tl("labels.booking.reservations.noshowWithoutDeduction", selected_restaurant_id) }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent";
import axios from "axios";
import PaymentStatusEnum from "../../mixins/enums/booking/PaymentStatusEnum";

export default {
    data() {
        return {
            loading: false,
            error: null,
            reservation: null,
            selected_restaurant_id: null,
            selected_reservation_id: null,
        };
    },
    mixins: [PaymentStatusEnum],
    props: {
        reservation_id: {
            default: null,
        },
        restaurant_id: {
            default: null,
        },
        showSaveButton: {
            default: true,
        },
    },
    computed: {
        isStripeIntentAmountOverReservationSize() {
            return (
                (this.reservation.nb_pers + this.reservation.nb_children) * this.reservation.amount_if_noshow > this.reservation.stripe_intent_amount
            );
        },
        stripeIntentAmount() {
            if (
                this.reservation.payment &&
                this.reservation.payment.stripe_intent_amount &&
                this.reservation.payment.status === this.PAYMENT_STATUS_CAPTURABLE.value
            )
                return this.reservation.payment.stripe_intent_amount;
            return 0;
        },
    },
    methods: {
        // load reservation details
        initData() {
            this.error = this.selected_reservation_id = null;
            this.loading = true;
            this.selected_restaurant_id = this.restaurant_id || this.$route.params.restaurant_id;
            this.selected_reservation_id = this.reservation_id || this.$route.params.reservation_id;
            axios
                .get(`/api/restaurants/${this.selected_restaurant_id}/reservations/${this.selected_reservation_id}?include=client,payment`)
                .then((response) => {
                    this.loading = false;
                    this.reservation = response.data;
                    if (this.reservation.status === "noshow") {
                        this.$notify({
                            group: "notification",
                            type: "warn",
                            title: "La réservation est déjà marquée comme noshow",
                        });
                        this.$router.push({
                            name: "booking.restaurants.reservations",
                            params: { restaurant_id: this.selected_restaurant_id },
                        });
                    }
                })
                .catch((error) => {
                    this.error = error.response.data.message || error.message;
                    if (this.error === "Unauthenticated.") {
                        document.location.reload(true);
                    } else {
                        this.loading = false;
                    }
                });
        },
        takeNoShow(nb_people) {
            if (
                confirm(
                    "Vous êtes sur le point de prélever une empreinte bancaire pour " +
                        nb_people +
                        " personnes. Attention cette action est irréversible."
                )
            ) {
                // take client no show money
                this.loading = true;
                const axiosPromise = axios.post(
                    `/api/restaurants/${this.selected_restaurant_id}/reservations/${this.selected_reservation_id}/noshow`,
                    {
                        nb_people: nb_people,
                    }
                );

                if (this.showSaveButton) {
                    axiosPromise
                        .then((response) => {
                            this.$notify({
                                group: "notification",
                                type: "success",
                                title: "Réservation modifiée",
                            });
                            this.$emit("noShowTaken", true);
                            this.$router.push({
                                name: "booking.restaurants.reservations",
                                params: { restaurant_id: this.selected_restaurant_id },
                            });
                            this.loading = false;
                        })
                        .catch((error) => {
                            //var msg = error.response.data.message || error.message;
                            this.loading = false;
                            let error_message = error.response.data.message || error.message;
                            this.$notify({
                                group: "notification",
                                type: "warn",
                                title: "La réservation n'a pas pu être modifiée : " + error_message,
                            });
                        });
                } else {
                    return axiosPromise;
                }
            }
        },
        markNoShow() {
            // mark as no show without taking money
            this.loading = true;

            const axiosPromise = axios.put(`/api/restaurants/${this.selected_restaurant_id}/reservations/${this.selected_reservation_id}/status`, {
                status: "noshow",
            });

            if (this.showSaveButton) {
                axiosPromise
                    .then((response) => {
                        // display success message
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: "Réservation modifiée",
                        });
                        this.$router.push({
                            name: "booking.restaurants.reservations",
                            params: { restaurant_id: this.selected_restaurant_id },
                        });
                    })
                    .catch((error) => {
                        //var msg = error.response.data.message || error.message;
                        this.loading = false;
                        this.$notify({
                            group: "notification",
                            type: "warn",
                            title: "La réservation n'a pas pu être modifiée",
                        });
                    });
            } else {
                return axiosPromise;
            }
        },
        setLoading(value) {
            this.$set(this, "loading", value);
        },
    },
    mounted() {
        this.initData();
    },
    watch: {
        "$route.params.restaurant_id": function (id) {
            this.initData();
        },
        "$route.params.reservation_id": function (id) {
            this.initData(true);
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>
