export default {
    requestSent: "Demande envoyée",
    common: {
        copy: "{element} a bien été copié",
    },
    employees: {
        deleted: "L'employé a bien été supprimé",
    },
    subscriptions: {
        updated: "L'abonnement a bien été modifié",
        askFor: "Votre demande a bien été prise en compte, votre chargé de projet vous recontactera.",
        modalConfirm: {
            title: "Votre demande d'informations a bien été prise en compte",
            body: "Vous serez prochainement contacté par votre chargé de compte",
        },
    },
    payments: {
        paidWithReceipt: "Le paiement a bien été effectué, un email récapitulatif vous a été envoyé.",
        pendingWithReceipt:
            "Le paiement est en cours de traitement. Un email récapitulatif vous sera envoyé dès la réception de la confirmation du paiement.",
    },
    clients: {
        configSaved: "La configuration à été enregistrée avec succès",
        added: "Le client a bien été ajouté",
        merged: "Les fiches client ont bien été fusionnées",
    },
    stripe: {
        account: {
            linked: "Votre compte stripe à été connecté",
        },
    },
    booking: {
        seatingPlan: {
            resasPlaced: "Les réservations ont été placées",
            rooms: {
                enabledForService: "La salle a été activée pour cette plage horaire",
                disabledForService: "La salle a été désactivée pour cette plage horaire",
            },
            tables: {
                switched: "Les tables ont bien été modifiées",
                enabledForService: "La table a été activée pour cette plage horaire",
                disabledForService: "La table a été désactivée pour cette plage horaire",
            },
        },
        reservations: {
            updated: "Réservation modifiée",
            updatedLong: "La réservation a été mise à jour avec succès.",
            confirmed: "La réservation est confirmée",
            canceled: "Réservation annulée",
            askConfirmationSent: "La demande de confirmation a bien été envoyée",
            confirmationSent: "Les détails de la réservation ont bien été envoyés",
            edited: "La réservation a été éditée avec succès.",
        },
        footprint: {
            sent: "L'empreinte bancaire a été envoyée avec succès",
        },
        services: {
            added: "La plage horaire a bien été ajoutée.",
            duplicated: "La plage horaire a bien été dupliquée",
            deleted: "La plage horaire a bien été supprimée",
            nowFull: 'Les plages horaires sélectionnées sont désormais affichées "complet"',
            removeFull: "La plage horaire sélectionnée n'est plus complet",
            special: {
                added: "L'ouverture exceptionnelle a bien été ajoutée.",
                deleted: "L'ouverture exceptionnelle a bien été supprimée",
            },
        },
        closures: {
            deleted: "La fermeture a bien été supprimée",
        },
        menus: {
            deleted: "La prestation a bien été supprimée",
            options: {
                deleted: "L'option a bien été supprimée",
                edited: "L'option a été modifiée avec succès.",
                added: "L'option a été créée avec succès.",
            },
        },
        generalOptions: {
            added: "L'option générale a été créée avec succès.",
            edited: "L'option générale a été modifiée avec succès.",
        },
        customEvents: {
            added: "L'événement a bien été ajouté.",
            edited: "L'événement a bien été modifié.",
            deleted: "L'événement a bien été supprimé.",
        },
        waitingList: {
            reservationSaved: "La réservation a été enregistrée avec succès.",
        },
    },
    zones: {
        added: "Le groupe a bien été créé",
        updated: "Le groupe a bien été modifié",
        deleted: "Le groupe a bien été supprimé",
    },
};
