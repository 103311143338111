<template>
    <div class="row mt-3 radio">
        <div class="col-12">
            <div class="row">
                <div class="col-6 pt-2">
                    <label>
                        {{ $tl("labels.booking.services.footprint.autoReminder") }}
                        <feather
                            v-tooltip="getTooltipNotice('notices.booking.servicesConfig.sendReminderPayment')"
                            type="info"
                            class="ml-1 pointer tooltip-infos" />
                    </label>
                </div>
                <div class="col-6 d-table w-100 h-100 pt-2 pl-0">
                    <div class="d-table-cell align-middle">
                        <label class="container-box">
                            <input type="radio" :value="false" v-model="currentIsAuto" />
                            <span class="checkmark"></span>
                            {{ $tl("labels.manualM") }}
                        </label>
                        <label class="container-box">
                            <input type="radio" :value="true" v-model="currentIsAuto" />
                            <span class="checkmark"></span>
                            {{ $tl("labels.auto") }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="row my-2" v-if="currentIsAuto">
                <div class="col-6">
                    <label>
                        {{ $tl("labels.booking.services.footprint.autoHour") }}
                    </label>
                </div>
                <div class="col-6 pl-0">
                    <div class="row">
                        <div class="col-5">
                            <input
                                type="number"
                                :value="modelValue.hours"
                                @input="update('hours', $event.target.value)"
                                :min="minHours"
                                max="24"
                                required
                                class="form-control" />
                        </div>
                        <div class="col-7 mt-2">
                            {{ $tl("labels.hour") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    model: {
        prop: "modelValue",
        event: "change",
    },
    props: {
        modelValue: Object,
        cancelAuto: {
            type: [Number, Boolean],
            required: true,
        },
    },
    computed: {
        minHours() {
            return this.cancelAuto ? 2 : 1;
        },
        currentIsAuto: {
            get() {
                return this.modelValue.isAuto;
            },
            set(value) {
                this.update("isAuto", value);
            },
        },
    },
    methods: {
        update(key, value) {
            this.$emit("change", { ...this.modelValue, [key]: value });
        },
    },
    watch: {
        minHours(value) {
            if (this.modelValue.hours < value) {
                this.update("hours", value);
            }
        },
    },
};
</script>
