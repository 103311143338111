<template>
    <div class="loader-main-div text-center mt-4 mb-4">
        <div style="height: 80px">
            <img class="load-bar-absolute load-bar-absolute-top" src="/images/load-bar-top.png" />
            <img class="load-bar-absolute load-bar-absolute-bot" src="/images/load-bar-bot.png" />
        </div>
        <div>{{ loadingMessage }}</div>
    </div>
</template>

<script>
export default {
    name: "LoaderComponent",
    data() {
        return {
            defaultMessage: this.i18nAvailable ? this.$tl("labels.loading") : "Veuillez patienter...",
        };
    },
    props: {
        message: {
            default: null,
        },
        i18nAvailable: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        loadingMessage() {
            return this.message !== null ? this.message : this.defaultMessage;
        },
    },
};
</script>

<style scoped type="text/css">
.lds-ring {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 60px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    margin: 8px;
    border: 4px solid #30a4b7;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #30a4b7 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loadBarTop {
    0% {
        background-position: left;
    }
    50% {
        background-position: right;
    }
    100% {
        background-position: left;
    }
}

@keyframes loadBarBot {
    0% {
        background-position: right;
    }
    50% {
        background-position: left;
    }
    100% {
        background-position: right;
    }
}

@keyframes loadBarTopTranslate {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(18px, -12px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes loadBarBotTranslate {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(-18px, 12px);
    }
    100% {
        transform: translate(0, 0);
    }
}

.load-bar-absolute {
    position: absolute;
    width: 60px;
    margin-left: -30px;
    left: 50%;
}

.load-bar-absolute-top {
    animation: 2s linear infinite loadBarBotTranslate;
}

.load-bar-absolute-bot {
    animation: 2s linear infinite loadBarTopTranslate;
}
</style>
