var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-2" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
            },
            [
              _c("h5", { staticClass: "title mt-2" }, [_vm._v("Nouveautés")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-success btn-circle",
                  attrs: { type: "button" },
                  on: { click: _vm.addNewFeature },
                },
                [
                  _c("feather", { attrs: { type: "plus" } }),
                  _c("span", { staticClass: "ml-1" }, [
                    _vm._v("Ajouter une nouveauté"),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _c(
              "table",
              { staticClass: "table table-sm table-striped border-bottom" },
              [
                _c("thead", { staticClass: "border-bottom" }, [
                  _c("tr", [
                    _c("th", [_vm._v("Titre")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Nombre d'utilisateurs initial")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Nombre de vues")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Date de création")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Actions")]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.new_features, function (new_feature) {
                    return _c("tr", { key: new_feature.id }, [
                      _c("td", [_vm._v(_vm._s(new_feature.title))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(new_feature.nb_users))]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            new_feature.nb_users -
                              new_feature.remaining_nb_users
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.moment(new_feature.created_at).format("DD/MM/Y")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-sm btn-outline-secondary btn-square",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.showShowNewFeatureModal = new_feature.id
                              },
                            },
                          },
                          [_c("feather", { attrs: { type: "eye" } })],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-danger btn-square",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteNewFeature(new_feature.id)
                              },
                            },
                          },
                          [_c("feather", { attrs: { type: "trash-2" } })],
                          1
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            ),
          ]),
      _vm._v(" "),
      _vm.showAddNewFeatureModal
        ? _c("addNewFeatureModal", {
            on: {
              close: function ($event) {
                _vm.showAddNewFeatureModal = false
              },
              saved: _vm.onNewFeatureSaved,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showShowNewFeatureModal !== false
        ? _c("showNewFeatureModal", {
            attrs: {
              markAsRead: false,
              new_feature_id: _vm.showShowNewFeatureModal,
            },
            on: {
              hide: function ($event) {
                _vm.showShowNewFeatureModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }