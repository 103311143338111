<template>
    <div>
        <tab-bar direction="horizontal" side="top" :entries="tabBarEntries" />
        <router-view />
    </div>
</template>

<script>
import TabBar from "../../components/tabBar/TabBar";

export default {
    data() {
        return {
            restaurant_id: this.$route.params.restaurant_id,
        };
    },
    computed: {
        tabBarEntries() {
            return [
                {
                    to: {
                        name: "click_and_collect.restaurants.products.products",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Produits",
                    exactUrlMatching: true,
                },
                {
                    to: {
                        name: "click_and_collect.restaurants.products.categories",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Catégories",
                    exactUrlMatching: false,
                },
            ];
        },
    },
    props: {},
    components: {
        TabBar,
    },
    created() {
        if (this.$route.name === "click_and_collect.restaurants.products")
            this.$router.push({ name: "click_and_collect.restaurants.products.products" });
    },
};
</script>
