var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                ])
              : _c("div", { staticClass: "div-profil" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-7 mb-3" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _vm._v("Numéro"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6 value" }, [
                              _vm._v("5720"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "sepa" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _vm._v("Montant"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6 value" }, [
                              _vm._v("50,00€"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "sepa" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _vm._v("TVA"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6 value" }, [
                              _vm._v("10,00€"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "sepa" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _vm._v("Statut"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6 value" }, [
                              _vm._v("Payé"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "sepa" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _vm._v("Frais Stripe"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6 value" }, [
                              _vm._v("2,34 €"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "sepa" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _vm._v("Date"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6 value" }, [
                              _vm._v("22/12/2020"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "sepa" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12 mb-1" }, [
                              _vm._v("Commentaire public"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12 value" }, [
                              _vm._v("Test"),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-5" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "border-light b-radius-20 p-4 text-center",
                        },
                        [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "rounded-circle div-profil-svg",
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass:
                                        "feather feather-user sc-dnqmqq jxshSx",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "24",
                                        height: "24",
                                        viewBox: "0 0 24 24",
                                        fill: "none",
                                        stroke: "currentColor",
                                        "stroke-width": "2",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        "aria-hidden": "true",
                                        "data-reactid": "1306",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("circle", {
                                        attrs: { cx: "12", cy: "7", r: "4" },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "sepa" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mb-0" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("small", [_vm._v("Madame")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 text-uppercase font-weight-bold",
                              },
                              [_vm._v("Alexane Roux")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "sepa" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _vm._v("contact@noshow.io"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mb-1" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _vm._v("06 88 67 39 20"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-success btn-circle",
                                    attrs: {
                                      to: {
                                        name: "click_and_collect.clients.form",
                                        params: {
                                          client_id: _vm.sale.client.id,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Voir la fiche client\n                                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }