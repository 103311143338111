import labels from "./labels.js";
import rights from "./rights.js";
import errors from "./errors.js";
import success from "./success.js";
import notices from "./notices.js";
import infos from "./infos.js";
import questions from "./questions.js";
import lexicons from "./lexicons";

export default {
    labels,
    rights,
    errors,
    success,
    notices,
    infos,
    questions,
    lexicons,
};
