export default {
    data() {
        return {
            CUSTOM_EVENT_VISIBILITY_INTERNAL: {
                value: "internal",
            },
            CUSTOM_EVENT_VISIBILITY_EXTERNAL: {
                value: "external",
            },
        };
    },
    methods: {
        getCustomEventVisibilityLabel(value) {
            const found = this.ALL_CUSTOM_EVENT_VISIBILITIES.find((p) => p.value === value);

            return typeof found !== "undefined" ? this.$t(`labels.booking.customEvents.visibilities.${value}`) : value;
        },
    },
    computed: {
        ALL_CUSTOM_EVENT_VISIBILITIES() {
            return [this.CUSTOM_EVENT_VISIBILITY_INTERNAL, this.CUSTOM_EVENT_VISIBILITY_EXTERNAL];
        },
    },
};
