<template>
    <div>
        <LoaderComponent v-if="loading" />
        <span class="text-danger" v-else-if="error">{{ error }}</span>
        <template v-else-if="service">
            <div class="mb-3">
                <strong class="text-muted" style="text-transform: capitalize">{{ getServiceCategoryLabel(service) }} | </strong>
                <span class="text-muted">
                    {{ service.name }} - {{ service.pax }} /
                    {{ service.new_pax !== null ? service.new_pax : service.max_pax }}
                    <strong style="color: red" v-if="service.pax > (service.new_pax !== null ? service.new_pax : service.max_pax)">{{
                        $tl("labels.paxTooHigh")
                    }}</strong>
                </span>
                <small
                    v-if="service.is_full === 1 || service.isOpen == false"
                    class="text-uppercase ml-2 d-inline-block badge-pill badge-danger text-white-hover">
                    &nbsp;{{ service.is_full === 1 ? $tl("labels.booking.services.full") : $tl("labels.booking.services.closedSpecial") }}
                </small>
            </div>
            <div class="d-flex flex-wrap btn-select-slot-container">
                <button
                    class="btn btn-select-slot p-2 mb-2"
                    :class="{ 'btn-success': slot.id === reservation.slot_id, disabled: btnSlotReadonly(slot.id) }"
                    v-for="slot in service.slotsWithPax.data"
                    :key="slot.id"
                    :disabled="btnSlotReadonly(slot.id)"
                    @click="selectSlot(slot)">
                    <span class="d-block" :style="slot.id === reservation.slot_id ? 'color: white !important' : ''">{{ slot.hour_start }}</span>
                    <small
                        class="text-muted"
                        :style="slot.id === reservation.slot_id ? 'color: white !important' : ''"
                        v-if="service.is_table_rotation_enabled == true">
                        {{ slot.pax }} /
                        {{
                            service.is_slot_max_pax_enabled && slot.is_max_pax_enabled && slot.max_pax !== null
                                ? slot.max_pax
                                : service.new_pax !== null
                                ? service.new_pax
                                : service.max_pax
                        }}
                        {{ $tl("labels.personsShort") }}
                    </small>
                    <small class="text-muted" :style="slot.id === reservation.slot_id ? 'color: white !important' : ''" v-else>
                        {{ slot.pax_real }}
                        {{ service.is_slot_max_pax_enabled && slot.is_max_pax_enabled && slot.max_pax !== null ? `/ ${slot.max_pax}` : `` }}
                        {{ $tl("labels.personsShort") }}
                    </small>
                </button>
            </div>
        </template>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../LoaderComponent";
import LangsEnum from "../../../mixins/enums/LangsEnum";

export default {
    name: "slotsReservations",
    data() {
        return {
            loading: false,
            error: null,
            service: null,
            slot_id: this.reservation.slot_id,
        };
    },
    mixins: [LangsEnum],
    props: {
        reservation: {
            type: Object,
            required: true,
        },
        service_id: {
            type: Number,
            required: true,
        },
        restaurant_id: {
            default: null,
        },
        formErrors: {
            default: null,
        },
        readOnly: {
            default: false,
        },
        serviceIdToPreselect: {
            type: Number,
            default: null,
        },
        slotIdToPreselect: {
            type: Number,
            default: null,
        },
    },
    methods: {
        btnSlotReadonly(slot_id) {
            if (!this.readOnly) return false;
            return slot_id !== this.reservation.slot_id;
        },
        loadService(value = this.service_id) {
            this.loading = true;

            axios
                .get(
                    `/api/restaurants/${
                        this.restaurant_id
                    }/services/${value}?include=slotsWithPax,isOpen,slots,seatingPlanFilled,paxDurations,has_seating_plan&date=${this.reservation.reservation_date.toISODate()}`
                )
                .then((response) => {
                    this.loading = false;
                    this.service = response.data;
                    const slotsId = this.service.slotsWithPax.data.map((s) => s.id);
                    let slotId = this.reservation.slot_id || this.slotIdToPreselect;
                    if (slotId) {
                        const slotFound = this.service.slotsWithPax.data.find((s) => {
                            return s.id == slotId;
                        });
                        if (slotFound) this.selectSlot(slotFound);
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        hasErrors(name) {
            return this.formErrors && this.formErrors.errors && !_.isEmpty(this.formErrors.errors[name]);
        },
        selectSlot(slot) {
            this.reservation.slot_id = slot.id;
            this.reservation.slot = {
                hour_start: slot.hour_start,
                id: slot.id,
                service_id: this.service_id,
            };
            this.reservation.service = this.service;
            this.$emit("slot-selected");
        },
    },
    watch: {
        restaurant_id: {
            immediate: true,
            handler() {
                this.loadService();
            },
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>

<style scoped>
.btn-select-slot-container {
    margin-left: -15px;
    margin-right: -15px;
}

.btn-select-slot {
    border: 2px solid #ededed !important;
    width: 120px;
    color: #666666;
    margin-left: 15px;
    margin-right: 15px;
    flex: 0 1 calc(20% - 30px);
}
button.btn-select-slot:hover {
    background-color: #ededed;
}
.btn-select-slot > span {
    font-size: 20px;
}
.btn-select-slot > small {
    display: block;
    margin-top: -10px;
}
</style>
