var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row mb-2" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("strong", [_vm._v(_vm._s(_vm.$tl("labels.notifications.title")))]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-9 pt-2" }, [
          _c("label", [_vm._v(_vm._s(_vm.$tl("labels.notifications.enable")))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-3 pt-2" }, [
          _c("label", { staticClass: "container-box" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.enable_mail_notifications_,
                  expression: "enable_mail_notifications_",
                },
              ],
              attrs: { type: "checkbox", "true-value": "1" },
              domProps: {
                checked: Array.isArray(_vm.enable_mail_notifications_)
                  ? _vm._i(_vm.enable_mail_notifications_, null) > -1
                  : _vm._q(_vm.enable_mail_notifications_, "1"),
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.enable_mail_notifications_,
                    $$el = $event.target,
                    $$c = $$el.checked ? "1" : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        (_vm.enable_mail_notifications_ = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.enable_mail_notifications_ = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.enable_mail_notifications_ = $$c
                  }
                },
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "checkmark" }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-9 pt-2" }, [
          _c("label", [
            _vm._v(_vm._s(_vm.$tl("labels.notifications.enableRingtone"))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-3 pt-2" }, [
          _c("label", { staticClass: "container-box" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.enable_notification_ringtone_,
                  expression: "enable_notification_ringtone_",
                },
              ],
              attrs: { type: "checkbox", "true-value": "1" },
              domProps: {
                checked: Array.isArray(_vm.enable_notification_ringtone_)
                  ? _vm._i(_vm.enable_notification_ringtone_, null) > -1
                  : _vm._q(_vm.enable_notification_ringtone_, "1"),
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.enable_notification_ringtone_,
                    $$el = $event.target,
                    $$c = $$el.checked ? "1" : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        (_vm.enable_notification_ringtone_ = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.enable_notification_ringtone_ = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.enable_notification_ringtone_ = $$c
                  }
                },
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "checkmark" }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.enable_mail_notifications && _vm.hasRightToEditChannels
        ? _c("div", { staticClass: "row mt-3" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success btn-sm btn-circle",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.showChannelCustomization =
                        !_vm.showChannelCustomization
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("labels.notifications.editChannel")) +
                      "\n            "
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showChannelCustomization &&
      _vm.enable_mail_notifications &&
      _vm.hasRightToEditChannels
        ? [
            _vm.loading
              ? _c("LoaderComponent")
              : _c(
                  "div",
                  _vm._l(_vm.availableModules_, function (module) {
                    return _c(
                      "div",
                      {
                        key: module,
                        staticClass: "mt-3 pt-3 border-top-light",
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    module == "default"
                                      ? _vm.$tl(
                                          "labels.notifications.general.title"
                                        )
                                      : _vm.getModuleTypeLabel(module)
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                Object.keys(_vm.notificationsLabel[module]),
                                function (notification) {
                                  return _c(
                                    "div",
                                    {
                                      key: notification,
                                      staticClass: "row mt-2",
                                    },
                                    [
                                      _c("div", { staticClass: "col-7" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.notificationsLabel[module][
                                              notification
                                            ]
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-5 radio" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: _vm.smsTooltip,
                                                  expression: "smsTooltip",
                                                },
                                              ],
                                              staticClass: "container-box",
                                              class: {
                                                "cursor-not-allowed":
                                                  !_vm.smsActivated ||
                                                  !_vm.tel_is_mobile,
                                              },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .user_notifications_setting[
                                                        notification
                                                      ],
                                                    expression:
                                                      "user_notifications_setting[notification]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "radio",
                                                  disabled:
                                                    !_vm.smsActivated ||
                                                    !_vm.tel_is_mobile,
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.NOTIFICATION_TYPE_SMS
                                                      .value,
                                                  checked: _vm._q(
                                                    _vm
                                                      .user_notifications_setting[
                                                      notification
                                                    ],
                                                    _vm.NOTIFICATION_TYPE_SMS
                                                      .value
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$set(
                                                      _vm.user_notifications_setting,
                                                      notification,
                                                      _vm.NOTIFICATION_TYPE_SMS
                                                        .value
                                                    )
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                                class: {
                                                  disabled:
                                                    !_vm.smsActivated ||
                                                    !_vm.tel_is_mobile,
                                                },
                                              }),
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.getNotificationTypeLabel(
                                                      _vm.NOTIFICATION_TYPE_SMS
                                                        .value
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            { staticClass: "container-box" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .user_notifications_setting[
                                                        notification
                                                      ],
                                                    expression:
                                                      "user_notifications_setting[notification]",
                                                  },
                                                ],
                                                attrs: { type: "radio" },
                                                domProps: {
                                                  value:
                                                    _vm.NOTIFICATION_TYPE_EMAIL
                                                      .value,
                                                  checked: _vm._q(
                                                    _vm
                                                      .user_notifications_setting[
                                                      notification
                                                    ],
                                                    _vm.NOTIFICATION_TYPE_EMAIL
                                                      .value
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$set(
                                                      _vm.user_notifications_setting,
                                                      notification,
                                                      _vm
                                                        .NOTIFICATION_TYPE_EMAIL
                                                        .value
                                                    )
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                              }),
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.getNotificationTypeLabel(
                                                      _vm
                                                        .NOTIFICATION_TYPE_EMAIL
                                                        .value
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            { staticClass: "container-box" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .user_notifications_setting[
                                                        notification
                                                      ],
                                                    expression:
                                                      "user_notifications_setting[notification]",
                                                  },
                                                ],
                                                attrs: { type: "radio" },
                                                domProps: {
                                                  value:
                                                    _vm
                                                      .NOTIFICATION_TYPE_DISABLED
                                                      .value,
                                                  checked: _vm._q(
                                                    _vm
                                                      .user_notifications_setting[
                                                      notification
                                                    ],
                                                    _vm
                                                      .NOTIFICATION_TYPE_DISABLED
                                                      .value
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$set(
                                                      _vm.user_notifications_setting,
                                                      notification,
                                                      _vm
                                                        .NOTIFICATION_TYPE_DISABLED
                                                        .value
                                                    )
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                              }),
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.getNotificationTypeLabel(
                                                      _vm
                                                        .NOTIFICATION_TYPE_DISABLED
                                                        .value
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }