<template>
    <div class="w-100">
        <div class="row m-0">
            <div class="col-12">
                <h5 class="title mt-3 ml-1">
                    {{ $tl("labels.routes.onlineHelp") }}
                    <ShowNewBadgeDetails class="ml-2" badge_uuid="a644c6f5-8bc3-48e8-be08-124398dfa9e1" />
                </h5>
            </div>
        </div>
        <div class="row m-0">
            <LoaderComponent class="mx-auto" v-if="loading" />
            <template v-else-if="modules.length > 0">
                <div class="col-12 col-md-4 mt-2" v-for="module in modules" :key="module">
                    <div class="clickable tile restaurant border-light" @click="goToHelpForModule(module)">
                        <div class="logo">
                            <feather :type="getModuleTypeIcon(module)" class="feather-40 grey" />
                        </div>
                        <h6 class="restaurant-title border-top-light text-center">
                            {{ getModuleTypeLabel(module) }}
                        </h6>
                    </div>
                </div>
            </template>
            <div v-else class="col-12 font-italic mt-1 ml-2">{{ $tl("labels.videos.empty") }}</div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum";
import ShowNewBadgeDetails from "../../../components/NewBadges/showNewBadgeDetails.vue";

export default {
    data() {
        return {
            loading: false,
            videosList: [],
        };
    },
    mixins: [ModuleTypesEnum],
    methods: {
        fetchVideos() {
            this.loading = true;

            axios
                .get("/api/videos")
                .then((response) => {
                    this.videosList = response.data.data;
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        goToHelpForModule(module) {
            this.$router.push({
                name: "online_help.module",
                params: { module },
            });
        },
    },
    computed: {
        modules() {
            return _.uniq(this.videosList.map((video) => video.module));
        },
    },
    components: {
        LoaderComponent,
        ShowNewBadgeDetails,
    },
    created() {
        this.fetchVideos();
    },
};
</script>
