var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "table",
        {
          staticClass:
            "capitalize table-size-30 table table-book table-striped table-sm",
        },
        [
          _c("thead", { staticClass: "border-bottom" }, [
            _c("tr", [
              _c(
                "th",
                [
                  _c("feather", {
                    staticClass: "feather-20 none-desk",
                    attrs: { type: "user" },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "none-mobile" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$tl(
                          "labels.booking.reservations.grid.columns.client"
                        )
                      )
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "th",
                [
                  _c("feather", {
                    staticClass: "feather-20 none-desk",
                    attrs: { type: "users" },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "none-mobile" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$tl("labels.booking.reservations.grid.columns.pax")
                      )
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "th",
                [
                  _c("feather", {
                    staticClass: "feather-20 none-desk",
                    attrs: { type: "clock" },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "none-mobile" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$tl(
                          "labels.booking.reservations.history.columns.slot"
                        )
                      )
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("th", { staticClass: "none-mobile" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$tl(
                        "labels.booking.reservations.grid.columns.table",
                        _vm.restaurant_id
                      )
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "th",
                [
                  _c("feather", {
                    staticClass: "feather-20 none-desk",
                    attrs: { type: "check-circle" },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "none-mobile" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$tl(
                          "labels.booking.reservations.grid.columns.status"
                        )
                      )
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("th", { staticClass: "none-mobile" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$tl(
                        "labels.booking.reservations.grid.columns.optionBankStatus"
                      )
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "th",
                { staticClass: "text-center none-mobile" },
                [
                  _c("feather", {
                    attrs: { type: "check", "stroke-width": "4" },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.reservations_list, function (reservation) {
              return _c(
                "tr",
                {
                  key: reservation.id,
                  staticClass: "resa pointer",
                  class: {
                    updated: reservation.updated === true,
                    new: reservation.new === true,
                  },
                  attrs: { id: "resaRow" },
                  on: {
                    click: function ($event) {
                      return _vm.showResa(reservation.id, $event)
                    },
                  },
                },
                [
                  _c(
                    "td",
                    [
                      _c("inline-client", {
                        attrs: {
                          client: reservation.client,
                          "module-enum": _vm.MODULE_TYPE_BOOKING,
                          "public-comment": reservation.comment,
                          "private-comment": reservation.restaurant_comment,
                          "show-noshows": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "icons-start",
                              fn: function () {
                                return [
                                  reservation.gift
                                    ? _c("feather", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.getTooltip(
                                              reservation.gift
                                            ),
                                            expression:
                                              "getTooltip(reservation.gift)",
                                          },
                                        ],
                                        staticClass: "feather-blue",
                                        class: {
                                          "text-danger":
                                            reservation.gv_validity &&
                                            !reservation.gv_validity.valid,
                                        },
                                        attrs: { type: "tag" },
                                      })
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "table-text-center" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          reservation.nb_pers * 1 + reservation.nb_children * 1
                        ) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(reservation.slot.hour_start) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "table-text-center none-mobile" },
                    [
                      _vm._l(reservation.tables.data, function (table, index) {
                        return [
                          _vm._v(
                            "\n                        " +
                              _vm._s(table.name) +
                              _vm._s(
                                index + 1 < reservation.tables.data.length
                                  ? " - "
                                  : ""
                              ) +
                              "\n                    "
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.capitalize(
                            _vm.getReservationStatusLabel(reservation.status)
                          )
                        ) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "table-text-center none-mobile" },
                    [
                      reservation.status !== "canceled" &&
                      reservation.status !== "refused"
                        ? _c("option-bank-status-icon", {
                            attrs: { reservation: reservation },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center none-mobile" }, [
                    reservation.confirmed_by_client !== null
                      ? _c("div", [
                          reservation.confirmed_by_client === 1
                            ? _c("div", [
                                _c(
                                  "svg",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: {
                                          delay: { show: 400, hide: 0 },
                                          content: "Confirmation validée",
                                        },
                                        expression:
                                          "{ delay: { show: 400, hide: 0 }, content: 'Confirmation validée' }",
                                      },
                                    ],
                                    staticClass: "css-i6dzq1 feather",
                                    staticStyle: { color: "#06c774" },
                                    attrs: {
                                      viewBox: "0 0 24 24",
                                      width: "24",
                                      height: "24",
                                      stroke: "currentColor",
                                      "stroke-width": "2",
                                      fill: "none",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z",
                                      },
                                    }),
                                  ]
                                ),
                              ])
                            : _c("div", [
                                _c(
                                  "svg",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: {
                                          delay: { show: 400, hide: 0 },
                                          content: "Confirmation en attente",
                                        },
                                        expression:
                                          "{ delay: { show: 400, hide: 0 }, content: 'Confirmation en attente' }",
                                      },
                                    ],
                                    staticClass: "css-i6dzq1 feather",
                                    staticStyle: { color: "#ffc107" },
                                    attrs: {
                                      viewBox: "0 0 24 24",
                                      width: "24",
                                      height: "24",
                                      stroke: "currentColor",
                                      "stroke-width": "2",
                                      fill: "none",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M19.69 14a6.9 6.9 0 0 0 .31-2V5l-8-3-3.16 1.18",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        d: "M4.73 4.73L4 5v7c0 6 8 10 8 10a20.29 20.29 0 0 0 5.62-4.38",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("line", {
                                      attrs: {
                                        x1: "1",
                                        y1: "1",
                                        x2: "23",
                                        y2: "23",
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                        ])
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showReservationModal
        ? _c("show-reservation-modal", {
            attrs: {
              reservation_id: _vm.selected_reservation_id,
              restaurant_id: _vm.selected_restaurant_id,
            },
            on: {
              "resa-edit": _vm.editReservationFromShowModal,
              close: function ($event) {
                _vm.showReservationModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showEditReservationModal
        ? _c("edit-reservation-modal", {
            attrs: {
              restaurant_id: _vm.selected_restaurant_id,
              reservation_id: _vm.selected_reservation_id,
            },
            on: {
              close: function ($event) {
                _vm.showEditReservationModal = false
              },
              "reservation-edited": _vm.reservationEdited,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }