<template>
    <div>
        <div
            class="seating-plan-memos-btn"
            ref="draggableBtn"
            :class="{ show: showMemos && memos.length === 0, 'show-memos': showMemos && memos.length > 0 }">
            <button class="btn btn-success" @click="toggleShowMemos" v-tooltip="getTooltip($t('labels.booking.services.memos.titlePlural'))">
                <feather :type="showMemos ? 'chevron-right' : 'chevron-left'" />
                <feather type="message-square" />
                <feather v-if="memos.length > 0" type="info" class="text-light ml-1" />
                <feather
                    class="btn-move"
                    type="move"
                    @mousedown.prevent="onMouseDown"
                    @touchstart.prevent="onTouchStart"
                    @touchmove.prevent="onTouchMove" />
            </button>
        </div>
        <div
            class="seating-plan-memos-container bg-main-color-light text-white p-1"
            ref="draggableContainer"
            :class="{ show: showMemos, 'show-memos': showMemos && memos.length > 0 }">
            <ShowMemo
                v-for="(memo, index) in memos"
                :key="memo.id"
                v-bind="{ memo, serviceId, restaurantId, date }"
                @added="memoAdded(index, $event)"
                @deleted="memoDeleted(index)" />
            <feather class="add-new-memo float-right mr-2" :class="{ 'mt-1': memos.length === 0 }" type="plus" @click="addNewMemo" />
        </div>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import ShowMemo from "./ShowMemo.vue";

export default {
    name: "ListMemos",
    data() {
        return {
            loading: false,
            memos: [],
            showMemos: false,
            positions: {
                clientY: null,
            },
        };
    },
    props: {
        restaurantId: {
            required: true,
        },
        serviceId: {
            type: Number,
            required: true,
        },
        date: {
            type: DateTime,
            required: true,
        },
    },
    methods: {
        fetchMemos() {
            this.loading = true;
            this.showMemos = false;
            this.memos = [];

            axios
                .get(`/api/restaurants/${this.restaurantId}/services/memos/${this.serviceId}/${this.date.toISODate()}`)
                .then((response) => {
                    this.loading = false;

                    this.memos = response.data.data;
                })
                .catch((error) => {
                    this.loading = false;

                    this.notifyError(error);
                });
        },
        addNewMemo() {
            this.memos.push({
                id: null,
                title: this.$tl("labels.booking.services.memos.title"),
                note: this.$tl("labels.booking.services.memos.type"),
            });
        },
        memoAdded(index, id) {
            this.memos[index].id = id;
        },
        memoDeleted(index) {
            this.memos.splice(index, 1);
        },
        toggleShowMemos(event) {
            var targets = [];

            let currentTarget = event.target;
            for (let i = 0; i < 6 && currentTarget !== null && currentTarget !== undefined; i++) {
                targets.push(currentTarget);
                currentTarget = currentTarget.parentElement;
            }

            const isBtnMove = targets.length > 0 && targets.some((target) => target.classList.contains("btn-move"));

            if (!this.loading && !isBtnMove) {
                this.showMemos = !this.showMemos;
            }
        },
        onTouchStart(e) {
            this.positions.clientY = e.changedTouches[0].clientY;
        },
        onTouchMove(e) {
            this.moveMemos(e.changedTouches[0].clientY, 50);
        },
        onMouseDown(e) {
            this.positions.clientY = e.clientY;

            document.onmousemove = (e) => {
                e.preventDefault();

                this.moveMemos(e.clientY, 200);
            };
            document.onmouseup = () => {
                document.onmouseup = null;
                document.onmousemove = null;
            };
        },
        moveMemos(eventClientY, topLimit) {
            const movementY = this.positions.clientY - eventClientY;

            this.positions.clientY = eventClientY;

            if (this.$refs.draggableBtn.offsetTop - movementY < topLimit) {
                this.setMemosTop(topLimit);
            } else if (window.innerHeight - this.$refs.draggableContainer.offsetHeight < this.$refs.draggableContainer.offsetTop - movementY) {
                this.setMemosTop(window.innerHeight - this.$refs.draggableContainer.offsetHeight);
            } else {
                this.setMemosTop(this.$refs.draggableBtn.offsetTop - movementY);
            }
        },
        setMemosTop(top) {
            const moveToPx = `${top.toString()}px`;

            this.$refs.draggableBtn.style.top = moveToPx;
            this.$refs.draggableContainer.style.top = moveToPx;
        },
    },
    watch: {
        restaurantId() {
            this.fetchMemos();
        },
        serviceId() {
            this.fetchMemos();
        },
        date() {
            this.fetchMemos();
        },
    },
    created() {
        this.fetchMemos();
    },
    components: {
        ShowMemo,
    },
};
</script>
