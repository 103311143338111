<template>
    <div class="p-2">
        <div class="row">
            <div class="col-12">
                <div class="pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">
                        {{ $tl("labels.appUpdates.list") }}
                        <show-new-badge-details badge_uuid="ceee56bb-d4a8-4b2d-9cc3-d90792bd71c0" />
                    </h5>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <div v-else-if="appUpdatesList.length > 0">
            <div v-for="appUpdate in appUpdatesList" :key="appUpdate.id" class="row mb-2">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4 pointer" @click="showAppUpdate(appUpdate)">
                        <div class="d-flex justify-content-between">
                            <h5>{{ $t("labels.appUpdates.title", { date: displayDate(appUpdate.update_date, DATE_MED) }) }}</h5>
                            <div class="text-right">
                                <span v-for="tmpModule in appUpdate.modules" :key="tmpModule" class="badge badge-success mr-1">
                                    {{ getModuleTypeLabel(tmpModule) }}
                                </span>
                            </div>
                        </div>
                        <div v-html="formatDescription(appUpdate.description)"></div>
                        <small class="text-muted">{{
                            $t("labels.appUpdates.lastUpdate", { date: displayDate(appUpdate.updated_at, DATE_MED) })
                        }}</small>
                    </div>
                </div>
            </div>
        </div>
        <p v-else class="font-italic">{{ $tl("labels.appUpdates.noOnePublished") }}</p>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";
import showNewBadgeDetails from "../../../components/NewBadges/showNewBadgeDetails.vue";

export default {
    name: "AppUpdatesList",
    data() {
        return {
            loading: false,
            appUpdatesList: [],
        };
    },
    mixins: [ModuleTypesEnum],
    methods: {
        fetchAppUpdatesList() {
            this.loading = true;

            this.httpGet("/api/app_updates")
                .then((response) => {
                    if (response !== false) {
                        this.appUpdatesList = response.data.data;

                        this.$store.dispatch("users/markAllAppUpdatesAsRead");
                    }
                })
                .finally(() => (this.loading = false));
        },
        showAppUpdate(appUpdate) {
            this.$router.push({ name: "app_updates.show", params: { app_update_id: appUpdate.id } });
        },
        formatDescription(description) {
            return this.escapeHtml(description)
                .replace(/\n{2,}/g, "<br /><br />")
                .replace(/\n/g, "<br />");
        },
    },
    components: {
        LoaderComponent,
        showNewBadgeDetails,
    },
    created() {
        this.fetchAppUpdatesList();
    },
};
</script>
