<template>
    <div class="h-100" style="padding-top: 44px">
        <div class="alert alert-danger mt-5" v-if="error">{{ error }}</div>
        <ListSeatingPlans v-else-if="!seatingPlanId" />
        <seating-plan-konva :seatingPlanId="seatingPlanId == 'add' ? null : seatingPlanId" v-else class="h-100" />
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import SeatingPlanKonva from "../../../components/seatingPlan/SeatingPlanKonva.vue";
import ListSeatingPlans from "../../../components/seatingPlan/ListSeatingPlans.vue";
import LexiconEnum from "../../../mixins/enums/LexiconEnum.js";

export default {
    data() {
        return {
            error: null,
        };
    },
    mixins: [LexiconEnum],
    computed: {
        seatingPlanId() {
            return this.$route.params.seating_plan_id || null;
        },
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        restaurantLexicon() {
            return this.$store.getters["restaurants/lexicon"](this.restaurantId);
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_read_seatingplan() {
            return this.rights.includes("booking.seatingplan.read");
        },
    },
    components: {
        SeatingPlanKonva,
        ListSeatingPlans,
    },
    created() {
        if (this.restaurantLexicon !== this.LEXICON_RESTAURANT.value) {
            this.error = this.$tl("errors.booking.seatingPlan.featureDisabledForRestaurant");
        } else if (!this.has_right_to_read_seatingplan) {
            this.error = this.$tl("errors.common.rights.notEnough");
        }
    },
};
</script>
