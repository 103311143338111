var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading.global > 0
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.errorMessages.global
              ? _c("div", { staticClass: "row m-0" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 border-light b-radius-20 p-4 mb-3" },
                    [
                      _c(
                        "p",
                        { staticClass: "text-danger w-100 text-center m-0" },
                        [_vm._v(_vm._s(_vm.errorMessages.global))]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0" }, [
              _c(
                "div",
                { staticClass: "col-12 border-light b-radius-20 p-4 mb-3" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 mb-3 pb-3 border-bottom-light" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex w-25 justify-content-between align-items-center lead-switch switch-choice",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.command.collect_type,
                                  expression: "command.collect_type",
                                },
                              ],
                              staticClass:
                                "switch align-self-center is-rounded",
                              class: { disabled: _vm.oneServiceDisabled },
                              attrs: {
                                type: "checkbox",
                                id: "chk-collect_type",
                                "false-value": "collect",
                                "true-value": "delivery",
                                disabled: _vm.oneServiceDisabled,
                              },
                              domProps: {
                                checked: Array.isArray(_vm.command.collect_type)
                                  ? _vm._i(_vm.command.collect_type, null) > -1
                                  : _vm._q(
                                      _vm.command.collect_type,
                                      "delivery"
                                    ),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.command.collect_type,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? "delivery" : "collect"
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.command,
                                          "collect_type",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.command,
                                          "collect_type",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.command, "collect_type", $$c)
                                  }
                                },
                              },
                            }),
                            _vm._v("\n                            Retrait "),
                            _c("label", { attrs: { for: "chk-collect_type" } }),
                            _vm._v("Livraison\n                        "),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "row mb-2" }, [
                        _vm.hasErrors("date")
                          ? _c("div", { staticClass: "col-md-12" }, [
                              _c("div", {
                                staticClass: "invalid-feedback d-block",
                                domProps: {
                                  innerHTML: _vm._s(_vm.joinErrors("date")),
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-4 pt-1" }, [
                          _c("label", [
                            _vm._v("Date "),
                            _c("small", [_vm._v("*")]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-md-8 date-resa-cal",
                            staticStyle: { "font-size": "0.75rem" },
                          },
                          [
                            _c("datepicker", {
                              attrs: {
                                tabindex: "1",
                                "monday-first": true,
                                format: _vm.customDateFormater,
                                disabledDates: _vm.disabledDates,
                                language: _vm.fr,
                              },
                              model: {
                                value: _vm.commandDateComputed,
                                callback: function ($$v) {
                                  _vm.commandDateComputed = $$v
                                },
                                expression: "commandDateComputed",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.command.collect_type === "collect"
                      ? _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "row mb-2" }, [
                            _vm.hasErrors("slot.id")
                              ? _c("div", { staticClass: "col-md-12" }, [
                                  _c("div", {
                                    staticClass: "invalid-feedback d-block",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.joinErrors("slot.id")
                                      ),
                                    },
                                  }),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-4 pt-1" }, [
                              _c("label", [_vm._v("Heure de retrait *")]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-8" }, [
                              _vm.slots.length > 0
                                ? _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.command.slot,
                                          expression: "command.slot",
                                        },
                                      ],
                                      staticClass: "custom-select mb-0",
                                      staticStyle: {
                                        "margin-top": "-2px !important",
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.command,
                                            "slot",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(_vm.slots, function (slot) {
                                      return _c(
                                        "option",
                                        {
                                          key: slot.id,
                                          domProps: { value: slot },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(_vm.slotLabel(slot.hour)) +
                                              "\n                                    "
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _c(
                                    "small",
                                    { staticClass: "text-danger d-block mt-2" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tl(
                                            "errors.commands.add.no_slots"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.command.collect_type === "delivery"
                      ? _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "row mb-2" }, [
                            _vm.hasErrors("slot.id")
                              ? _c("div", { staticClass: "col-md-12" }, [
                                  _c("div", {
                                    staticClass: "invalid-feedback d-block",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.joinErrors("slot.id")
                                      ),
                                    },
                                  }),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-4 pt-1" }, [
                              _c("label", [_vm._v("Créneau de livraison *")]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-8" }, [
                              _vm.slots.length > 0
                                ? _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.command.slot,
                                          expression: "command.slot",
                                        },
                                      ],
                                      staticClass: "custom-select mb-0",
                                      staticStyle: {
                                        "margin-top": "-2px !important",
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.command,
                                            "slot",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(_vm.slots, function (slot) {
                                      return _c(
                                        "option",
                                        {
                                          key: slot.id,
                                          domProps: { value: slot },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.slotLabel(slot.hour_start)
                                              ) +
                                              " - " +
                                              _vm._s(
                                                _vm.slotLabel(slot.hour_end)
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _c(
                                    "small",
                                    { staticClass: "text-danger d-block mt-2" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tl(
                                            "errors.commands.add.no_slots"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.command.collect_type === "delivery"
                      ? _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("div", { staticClass: "col-md-4 pt-1" }, [
                              _c("label", [_vm._v("Adresse de livraison *")]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-8" },
                              [
                                _vm.command.address.deliveryInfo !== false
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "d-inline-block",
                                          staticStyle: {
                                            "padding-left": "13px",
                                            "padding-top": "7px",
                                            "padding-bottom": "7px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.command.address.raw)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("feather", {
                                        staticClass: "mt-1",
                                        staticStyle: { color: "red" },
                                        attrs: { type: "x" },
                                        on: { click: _vm.resetAddress },
                                      }),
                                    ]
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.command.address.raw,
                                          expression: "command.address.raw",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: _vm.command.address.raw,
                                      },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.command.address,
                                              "raw",
                                              $event.target.value
                                            )
                                          },
                                          _vm.clientAddressChanged,
                                        ],
                                      },
                                    }),
                                _vm._v(" "),
                                _vm.errorMessages.address
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "text-danger d-block mt-2",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.errorMessages.address)
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  staticClass: "d-block",
                                  attrs: {
                                    errors: _vm.formErrors,
                                    errorKey: "address.final.address",
                                  },
                                }),
                                _vm._v(" "),
                                _vm.addresses_.length > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "address_results border-light",
                                      },
                                      _vm._l(
                                        _vm.addresses_,
                                        function (address, index) {
                                          return _c(
                                            "span",
                                            {
                                              key: index,
                                              staticClass:
                                                "d-block p-2 address-results",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.selectAddress(
                                                    address
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(address.description) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mb-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row mb-3" }, [
                    _vm.hasErrors("products")
                      ? _c("div", { staticClass: "col-md-12 pt-1" }, [
                          _c("p", { staticClass: "text-danger" }, [
                            _vm._v("Les produits sont obligatoires"),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-4 pt-1" }, [
                      _c("label", [
                        _vm._v("Produits "),
                        _c("small", [_vm._v("*")]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-8" },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.productForm.product,
                                expression: "productForm.product",
                              },
                            ],
                            staticClass:
                              "custom-select d-inline-block mb-1 mr-1",
                            staticStyle: { width: "300px" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.productForm,
                                  "product",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { domProps: { value: null } }, [
                              _vm._v("--"),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.products, function (product) {
                              return _c(
                                "option",
                                {
                                  key: product.id,
                                  attrs: {
                                    disabled:
                                      product.out_of_stock ||
                                      (product.remaining_stock.stock !== null &&
                                        _vm.getProductQuantityInCart(
                                          product.id,
                                          false
                                        ) >= product.remaining_stock.stock),
                                  },
                                  domProps: { value: product },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        product.out_of_stock ||
                                          (product.remaining_stock.stock !==
                                            null &&
                                            _vm.getProductQuantityInCart(
                                              product.id,
                                              false
                                            ) >= product.remaining_stock.stock)
                                          ? `Épuisé - ${product.name}`
                                          : product.name
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("feather", { attrs: { type: "x" } }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.productForm.quantity,
                              expression: "productForm.quantity",
                            },
                          ],
                          staticClass:
                            "form-control d-inline-block ml-1 mr-2 pr-2",
                          staticStyle: { width: "60px" },
                          attrs: {
                            type: "number",
                            min: 1,
                            step: "1",
                            name: "",
                          },
                          domProps: { value: _vm.productForm.quantity },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.productForm,
                                "quantity",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("feather", {
                          staticClass: "text-success feather-20",
                          attrs: { type: "plus" },
                          on: { click: _vm.addProduct },
                        }),
                        _vm._v(" "),
                        _vm.errorMessages.invalidQuantity.length > 0
                          ? _c("span", { staticClass: "text-danger d-block" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.errorMessages.invalidQuantity.join(", ")
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.productForm.product &&
                        _vm.productForm.product.cc_product_components.data
                          .length > 0
                          ? [
                              _vm.errorMessages.choiceNeeded.length > 0
                                ? _c(
                                    "span",
                                    { staticClass: "text-danger d-block" },
                                    [
                                      _vm._v(
                                        "Choix obligatoire" +
                                          _vm._s(
                                            _vm.errorMessages.choiceNeeded
                                              .length > 1
                                              ? "s"
                                              : ""
                                          ) +
                                          " :\n                                    " +
                                          _vm._s(
                                            _vm.errorMessages.choiceNeeded.join(
                                              ", "
                                            )
                                          )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.errorMessages.invalidExtraQuantity.length > 0
                                ? _c(
                                    "span",
                                    { staticClass: "text-danger d-block" },
                                    [
                                      _vm._v(
                                        "Quantité" +
                                          _vm._s(
                                            _vm.errorMessages
                                              .invalidExtraQuantity.length > 1
                                              ? "s"
                                              : ""
                                          ) +
                                          " invalide" +
                                          _vm._s(
                                            _vm.errorMessages
                                              .invalidExtraQuantity.length > 1
                                              ? "s"
                                              : ""
                                          ) +
                                          "\n                                    : " +
                                          _vm._s(
                                            _vm.errorMessages.invalidExtraQuantity.join(
                                              ", "
                                            )
                                          )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(
                                _vm.sortedProductComponents,
                                function (productComponent) {
                                  return [
                                    productComponent.is_mandatory &&
                                    productComponent.cc_product_options.data
                                      .length > 0
                                      ? _c(
                                          "div",
                                          { key: productComponent.id },
                                          [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.productForm
                                                        .optionsMandatory[
                                                        productComponent.id
                                                      ],
                                                    expression:
                                                      "productForm.optionsMandatory[productComponent.id]",
                                                  },
                                                ],
                                                staticClass:
                                                  "custom-select d-inline-block mb-1 mr-1",
                                                staticStyle: { width: "300px" },
                                                on: {
                                                  change: function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.$set(
                                                      _vm.productForm
                                                        .optionsMandatory,
                                                      productComponent.id,
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  {
                                                    staticClass: "disabled",
                                                    attrs: { disabled: true },
                                                    domProps: { value: null },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          productComponent.name
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  productComponent
                                                    .cc_product_options.data,
                                                  function (productOption) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: `${productComponent.id}-${productOption.id}`,
                                                        attrs: {
                                                          disabled:
                                                            productOption
                                                              .cc_product
                                                              .out_of_stock ||
                                                            (productOption
                                                              .cc_product
                                                              .remaining_stock
                                                              .stock !== null &&
                                                              _vm.getProductQuantityInCart(
                                                                productOption
                                                                  .cc_product
                                                                  .id,
                                                                false
                                                              ) >=
                                                                productOption
                                                                  .cc_product
                                                                  .remaining_stock
                                                                  .stock),
                                                        },
                                                        domProps: {
                                                          value: productOption,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              productOption
                                                                .cc_product
                                                                .out_of_stock ||
                                                                (productOption
                                                                  .cc_product
                                                                  .remaining_stock
                                                                  .stock !==
                                                                  null &&
                                                                  _vm.getProductQuantityInCart(
                                                                    productOption
                                                                      .cc_product
                                                                      .id,
                                                                    false
                                                                  ) >=
                                                                    productOption
                                                                      .cc_product
                                                                      .remaining_stock
                                                                      .stock)
                                                                ? `Épuisé - ${productOption.cc_product.name}`
                                                                : productOption
                                                                    .cc_product
                                                                    .name
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ]
                                        )
                                      : !productComponent.is_mandatory &&
                                        productComponent.cc_product_options.data
                                          .length > 0
                                      ? [
                                          _vm._l(
                                            _vm.productForm.extras[
                                              productComponent.id
                                            ],
                                            function (extra, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: `${productComponent.id}-option-${index}`,
                                                  staticClass: "mt-3",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        width: "300px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          extra.extra.cc_product
                                                            .name
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("feather", {
                                                    attrs: { type: "x" },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName:
                                                          "v-model.number",
                                                        value: extra.quantity,
                                                        expression:
                                                          "extra.quantity",
                                                        modifiers: {
                                                          number: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass:
                                                      "form-control d-inline-block ml-1 mr-2 pr-2",
                                                    staticStyle: {
                                                      width: "60px",
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      min: "1",
                                                      step: "1",
                                                    },
                                                    domProps: {
                                                      value: extra.quantity,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          extra,
                                                          "quantity",
                                                          _vm._n(
                                                            $event.target.value
                                                          )
                                                        )
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.$forceUpdate()
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("feather", {
                                                    staticClass: "text-danger",
                                                    attrs: { type: "x" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteExtra(
                                                          productComponent.id,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          _vm.notSelectedExtras(
                                            productComponent.id
                                          ).length > 0
                                            ? _c(
                                                "div",
                                                {
                                                  key: productComponent.id,
                                                  staticClass:
                                                    "d-inline-block mt-3 mb-1 mr-1",
                                                },
                                                [
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.productForm
                                                              .extraForms[
                                                              productComponent
                                                                .id
                                                            ].extra,
                                                          expression:
                                                            "productForm.extraForms[productComponent.id].extra",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "custom-select d-inline-block mt-1 mr-1",
                                                      staticStyle: {
                                                        width: "300px",
                                                      },
                                                      on: {
                                                        change: [
                                                          function ($event) {
                                                            var $$selectedVal =
                                                              Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function (o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function (
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                            _vm.$set(
                                                              _vm.productForm
                                                                .extraForms[
                                                                productComponent
                                                                  .id
                                                              ],
                                                              "extra",
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          },
                                                          function ($event) {
                                                            return _vm.extraFormChanged(
                                                              productComponent.id
                                                            )
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "option",
                                                        {
                                                          staticClass:
                                                            "disabled",
                                                          attrs: {
                                                            disabled: true,
                                                          },
                                                          domProps: {
                                                            value: null,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    " +
                                                              _vm._s(
                                                                productComponent.name
                                                              ) +
                                                              "\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        _vm.notSelectedExtras(
                                                          productComponent.id
                                                        ),
                                                        function (
                                                          extra,
                                                          extraIndex
                                                        ) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: `${productComponent.id}-extra-${extraIndex}`,
                                                              domProps: {
                                                                value: extra,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                    " +
                                                                  _vm._s(
                                                                    extra
                                                                      .cc_product
                                                                      .name
                                                                  ) +
                                                                  "\n                                                "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c("feather", {
                                                    attrs: { type: "x" },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName:
                                                          "v-model.number",
                                                        value:
                                                          _vm.productForm
                                                            .extraForms[
                                                            productComponent.id
                                                          ].quantity,
                                                        expression:
                                                          "productForm.extraForms[productComponent.id].quantity",
                                                        modifiers: {
                                                          number: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass:
                                                      "form-control d-inline-block ml-1 mr-2 pr-2",
                                                    staticStyle: {
                                                      width: "60px",
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      min: "1",
                                                      step: "1",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.productForm
                                                          .extraForms[
                                                          productComponent.id
                                                        ].quantity,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.productForm
                                                            .extraForms[
                                                            productComponent.id
                                                          ],
                                                          "quantity",
                                                          _vm._n(
                                                            $event.target.value
                                                          )
                                                        )
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.$forceUpdate()
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("feather", {
                                                    staticClass:
                                                      "text-success feather-20",
                                                    attrs: { type: "plus" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addExtra(
                                                          productComponent.id
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "border-light p-4" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-4 pt-1" }, [
                        _c("label", [_vm._v("Récapitulatif de commande")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-md-8 pt-1",
                          staticStyle: { color: "#666666" },
                        },
                        [
                          _vm._l(
                            _vm.command.products,
                            function (product, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "border-bottom-light pb-2 mb-2 d-flex justify-content-between",
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(product.quantity) +
                                            " x " +
                                            _vm._s(product.product.name)
                                        ),
                                      ]),
                                      _c("br"),
                                      _vm._v(" "),
                                      _vm._l(
                                        Object.values(product.optionsMandatory),
                                        function (option, indexOption) {
                                          return _c(
                                            "span",
                                            {
                                              key: `${indexOption}-option`,
                                              staticClass: "ml-3 d-block",
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "ml-3" },
                                                [
                                                  _vm._v(
                                                    _vm._s(1) +
                                                      " x " +
                                                      _vm._s(
                                                        option.cc_product.name
                                                      ) +
                                                      "\n                                                " +
                                                      _vm._s(
                                                        !option.include
                                                          ? _vm.formatCurrency(
                                                              option.price
                                                            )
                                                          : ""
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        Object.keys(product.extras),
                                        function (componentId) {
                                          return _vm._l(
                                            product.extras[componentId],
                                            function (extra, indexExtra) {
                                              return _c(
                                                "span",
                                                {
                                                  key: `${componentId}-${indexExtra}-extra`,
                                                  staticClass: "ml-3 d-block",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(extra.quantity) +
                                                      " x " +
                                                      _vm._s(
                                                        extra.extra.cc_product
                                                          .name
                                                      ) +
                                                      "\n                                                " +
                                                      _vm._s(
                                                        !extra.extra.include
                                                          ? _vm.formatCurrency(
                                                              extra.extra.price
                                                            )
                                                          : " "
                                                      ) +
                                                      "\n                                                "
                                                  ),
                                                  _c("feather", {
                                                    staticClass:
                                                      "text-danger ml-2",
                                                    attrs: { type: "x" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteProductExtra(
                                                          componentId,
                                                          index,
                                                          indexExtra
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.formatCurrency(
                                              _vm.getProductPrice(product)
                                            )
                                          ) +
                                          "\n                                        "
                                      ),
                                      _c("feather", {
                                        staticClass: "text-danger ml-2",
                                        attrs: { type: "x" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteProduct(index)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }
                          ),
                          _vm._v(" "),
                          _vm.command.collect_type === "collect"
                            ? _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-between",
                                },
                                [
                                  _c("strong", [_vm._v("Total")]),
                                  _vm._v(" "),
                                  _c(
                                    "strong",
                                    { staticClass: "text-success" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatCurrency(_vm.getTotal())
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm.command.collect_type === "delivery"
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between",
                                  },
                                  [
                                    _c("strong", [_vm._v("Sous-total")]),
                                    _vm._v(" "),
                                    _c(
                                      "strong",
                                      { staticClass: "text-success" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatCurrency(_vm.getTotal())
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between",
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v("Frais de livraison"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "strong",
                                      { staticClass: "text-success" },
                                      [_vm._v(_vm._s(_vm.getDeliveryFees()))]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between",
                                  },
                                  [
                                    _c("strong", [_vm._v("Total")]),
                                    _vm._v(" "),
                                    _c(
                                      "strong",
                                      { staticClass: "text-success" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatCurrency(
                                              _vm.getTotalWithDeliveryFees()
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mb-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("strong", [_vm._v("Paiement")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-2 mt-3" }, [
                    _vm.hasErrors("payment.isOnline")
                      ? _c("div", { staticClass: "col-md-12" }, [
                          _c("div", {
                            staticClass: "invalid-feedback d-block",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.joinErrors("payment.isOnline")
                              ),
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-5 pt-1" }, [
                      _c("label", [
                        _vm._v("Type de paiement "),
                        _c("small", [_vm._v("*")]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-7" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-around align-items-center lead-switch switch-choice",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.command.payment.isOnline,
                                expression: "command.payment.isOnline",
                              },
                            ],
                            staticClass: "switch align-self-center is-rounded",
                            attrs: {
                              type: "checkbox",
                              id: "chk-send_payment_ask",
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.command.payment.isOnline
                              )
                                ? _vm._i(_vm.command.payment.isOnline, null) >
                                  -1
                                : _vm.command.payment.isOnline,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.command.payment.isOnline,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.command.payment,
                                        "isOnline",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.command.payment,
                                        "isOnline",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.command.payment, "isOnline", $$c)
                                }
                              },
                            },
                          }),
                          _vm._v(
                            "\n                                Sur place "
                          ),
                          _c("label", {
                            attrs: { for: "chk-send_payment_ask" },
                          }),
                          _vm._v(
                            "Envoyer une demande de paiement\n                            "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mb-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", [
                    _c("div", { staticClass: "row mb-2 radio" }, [
                      _vm.hasErrors("client.civility")
                        ? _c("div", { staticClass: "col-md-12" }, [
                            _c("div", {
                              staticClass: "invalid-feedback d-block",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.joinErrors("client.civility")
                                ),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-4 d-none d-md-block pt-2" },
                        [
                          _c("label", [
                            _vm._v("Civilité "),
                            _vm.mandatoryFields.civility
                              ? _c("small", [_vm._v("*")])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-8 pt-2" }, [
                        _c("label", { staticClass: "container-box" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.command.client.civility,
                                expression: "command.client.civility",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "civility",
                              value: "monsieur",
                              tabindex: "5",
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.command.client.civility,
                                "monsieur"
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.firstname.focus()
                              },
                              change: function ($event) {
                                return _vm.$set(
                                  _vm.command.client,
                                  "civility",
                                  "monsieur"
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "checkmark" }),
                          _vm._v(" Monsieur\n                                "),
                        ]),
                        _vm._v(" "),
                        _c("label", { staticClass: "container-box" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.command.client.civility,
                                expression: "command.client.civility",
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "civility",
                              value: "madame",
                              tabindex: "6",
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.command.client.civility,
                                "madame"
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.firstname.focus()
                              },
                              change: function ($event) {
                                return _vm.$set(
                                  _vm.command.client,
                                  "civility",
                                  "madame"
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "checkmark" }),
                          _vm._v(" Madame\n                                "),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-2" }, [
                      _vm.hasErrors("client.firstname")
                        ? _c("div", { staticClass: "col-md-12" }, [
                            _c("div", {
                              staticClass: "invalid-feedback d-block",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.joinErrors("client.firstname")
                                ),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-4 d-none d-md-block pt-2" },
                        [
                          _c("label", [
                            _vm._v("Prénom "),
                            _vm.mandatoryFields.firstname
                              ? _c("small", [_vm._v("*")])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-8" }, [
                        _c(
                          "div",
                          { staticClass: "dropright add-resa-search-client" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.command.client.firstname,
                                  expression: "command.client.firstname",
                                },
                              ],
                              ref: "firstname",
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: "Prénom",
                                name: "firstname",
                                autocomplete: "off",
                                tabindex: "7",
                              },
                              domProps: { value: _vm.command.client.firstname },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.command.client,
                                      "firstname",
                                      $event.target.value
                                    )
                                  },
                                  _vm.searchClientByMultiField,
                                ],
                                focus: function ($event) {
                                  _vm.command.client.firstnameHasFocus = true
                                },
                                blur: function ($event) {
                                  _vm.command.client.firstnameHasFocus = false
                                },
                              },
                            }),
                            _vm._v(" "),
                            (_vm.command.client.firstnameHasFocus ||
                              _vm.command.client.search.firstnameHasFocus) &&
                            _vm.command.client.search.results &&
                            _vm.command.client.search.results.length > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dropdown-menu prename-field shadow show p-0",
                                    on: {
                                      mouseover: function ($event) {
                                        _vm.command.client.search.firstnameHasFocus = true
                                      },
                                      mouseout: function ($event) {
                                        _vm.command.client.search.firstnameHasFocus = false
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "ul",
                                      { staticClass: "list-group" },
                                      _vm._l(
                                        _vm.command.client.search.results,
                                        function (result) {
                                          return _c(
                                            "li",
                                            {
                                              staticClass:
                                                "list-group-item p-0",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "d-block p-3",
                                                  attrs: {
                                                    href: "javascript:",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.selectClient(
                                                        result
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(result.firstname) +
                                                        " " +
                                                        _vm._s(result.lastname)
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    " |\n                                                    "
                                                  ),
                                                  _c("span", [
                                                    _vm._v(_vm._s(result.tel)),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "email-search",
                                                    },
                                                    [
                                                      result.company
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                result.company
                                                              ) + " |"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(result.email)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-2" }, [
                      _vm.hasErrors("client.lastname")
                        ? _c("div", { staticClass: "col-md-12" }, [
                            _c("div", {
                              staticClass: "invalid-feedback d-block",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.joinErrors("client.lastname")
                                ),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-4 d-none d-md-block pt-2" },
                        [
                          _c("label", [
                            _vm._v("Nom "),
                            _vm.mandatoryFields.lastname
                              ? _c("small", [_vm._v("*")])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-8" }, [
                        _c(
                          "div",
                          { staticClass: "dropright add-resa-search-client" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.command.client.lastname,
                                  expression: "command.client.lastname",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: "Nom",
                                name: "lastname",
                                autocomplete: "off",
                                tabindex: "8",
                              },
                              domProps: { value: _vm.command.client.lastname },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.command.client,
                                      "lastname",
                                      $event.target.value
                                    )
                                  },
                                  _vm.searchClientByMultiField,
                                ],
                                focus: function ($event) {
                                  _vm.command.client.lastnameHasFocus = true
                                },
                                blur: function ($event) {
                                  _vm.command.client.lastnameHasFocus = false
                                },
                              },
                            }),
                            _vm._v(" "),
                            (_vm.command.client.lastnameHasFocus ||
                              _vm.command.client.search.lastnameHasFocus) &&
                            _vm.command.client.search.results &&
                            _vm.command.client.search.results.length > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dropdown-menu name-field shadow show p-0",
                                    on: {
                                      mouseover: function ($event) {
                                        _vm.command.client.search.lastnameHasFocus = true
                                      },
                                      mouseout: function ($event) {
                                        _vm.command.client.search.lastnameHasFocus = false
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "ul",
                                      { staticClass: "list-group" },
                                      _vm._l(
                                        _vm.command.client.search.results,
                                        function (result) {
                                          return _c(
                                            "li",
                                            {
                                              staticClass:
                                                "list-group-item p-0",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "d-block p-3",
                                                  attrs: {
                                                    href: "javascript:",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.selectClient(
                                                        result
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(result.firstname) +
                                                        " " +
                                                        _vm._s(result.lastname)
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    " |\n                                                    "
                                                  ),
                                                  _c("span", [
                                                    _vm._v(_vm._s(result.tel)),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "email-search",
                                                    },
                                                    [
                                                      result.company
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                result.company
                                                              ) + " |"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(result.email)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-2" }, [
                      _vm.hasErrors("client.company")
                        ? _c("div", { staticClass: "col-md-12" }, [
                            _c("div", {
                              staticClass: "invalid-feedback d-block",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.joinErrors("client.company")
                                ),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-4 d-none d-md-block pt-2" },
                        [
                          _c("label", [
                            _vm._v("Société "),
                            _vm.mandatoryFields.company
                              ? _c("small", [_vm._v("*")])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-8" }, [
                        _c(
                          "div",
                          { staticClass: "dropright add-resa-search-client" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.command.client.company,
                                  expression: "command.client.company",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: "Société",
                                name: "lastname",
                                autocomplete: "off",
                                tabindex: "8",
                              },
                              domProps: { value: _vm.command.client.company },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.command.client,
                                      "company",
                                      $event.target.value
                                    )
                                  },
                                  _vm.searchClientByMultiField,
                                ],
                                focus: function ($event) {
                                  _vm.command.client.companyHasFocus = true
                                },
                                blur: function ($event) {
                                  _vm.command.client.companyHasFocus = false
                                },
                              },
                            }),
                            _vm._v(" "),
                            (_vm.command.client.companyHasFocus ||
                              _vm.command.client.search.companyHasFocus) &&
                            _vm.command.client.search.results &&
                            _vm.command.client.search.results.length > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dropdown-menu company-field shadow show p-0",
                                    on: {
                                      mouseover: function ($event) {
                                        _vm.command.client.search.companyHasFocus = true
                                      },
                                      mouseout: function ($event) {
                                        _vm.command.client.search.companyHasFocus = false
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "ul",
                                      { staticClass: "list-group" },
                                      _vm._l(
                                        _vm.command.client.search.results,
                                        function (result) {
                                          return _c(
                                            "li",
                                            {
                                              staticClass:
                                                "list-group-item p-0",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "d-block p-3",
                                                  attrs: {
                                                    href: "javascript:",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.selectClient(
                                                        result
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(result.firstname) +
                                                        " " +
                                                        _vm._s(result.lastname)
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    " |\n                                                    "
                                                  ),
                                                  _c("span", [
                                                    _vm._v(_vm._s(result.tel)),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "email-search",
                                                    },
                                                    [
                                                      result.company
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                result.company
                                                              ) + " |"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(result.email)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-2" }, [
                      _vm.hasErrors("client.email")
                        ? _c("div", { staticClass: "col-md-12" }, [
                            _c("div", {
                              staticClass: "invalid-feedback d-block",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.joinErrors("client.email")
                                ),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-4 d-none d-md-block pt-2" },
                        [
                          _c("label", [
                            _vm._v("Email "),
                            _vm.mandatoryFields.email
                              ? _c("small", [_vm._v("*")])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-8" }, [
                        _c(
                          "div",
                          { staticClass: "dropright add-resa-search-client" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.command.client.email,
                                  expression: "command.client.email",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: "Email",
                                name: "email",
                                autocomplete: "off",
                                tabindex: "9",
                              },
                              domProps: { value: _vm.command.client.email },
                              on: {
                                focus: function ($event) {
                                  _vm.command.client.emailHasFocus = true
                                },
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.command.client,
                                      "email",
                                      $event.target.value
                                    )
                                  },
                                  _vm.searchClientByMultiField,
                                ],
                                blur: function ($event) {
                                  _vm.command.client.emailHasFocus = false
                                },
                              },
                            }),
                            _vm._v(" "),
                            (_vm.command.client.emailHasFocus ||
                              _vm.command.client.search.emailHasFocus) &&
                            _vm.command.client.search.results &&
                            _vm.command.client.search.results.length > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dropdown-menu email-field shadow show p-0",
                                    on: {
                                      mouseover: function ($event) {
                                        _vm.command.client.search.emailHasFocus = true
                                      },
                                      mouseout: function ($event) {
                                        _vm.command.client.search.emailHasFocus = false
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "ul",
                                      { staticClass: "list-group" },
                                      _vm._l(
                                        _vm.command.client.search.results,
                                        function (result) {
                                          return _c(
                                            "li",
                                            {
                                              staticClass:
                                                "list-group-item p-0",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "d-block p-3",
                                                  attrs: {
                                                    href: "javascript:",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.selectClient(
                                                        result
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(result.firstname) +
                                                        " " +
                                                        _vm._s(result.lastname)
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    " |\n                                                    "
                                                  ),
                                                  _c("span", [
                                                    _vm._v(_vm._s(result.tel)),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "email-search",
                                                    },
                                                    [
                                                      result.company
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                result.company
                                                              ) + " |"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(result.email)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-2" }, [
                      _vm.hasErrors("client.phoneNumber") ||
                      _vm.hasErrors("client.phoneCountry") ||
                      _vm.hasErrors("client.id")
                        ? _c("div", { staticClass: "col-md-12" }, [
                            _vm.hasErrors("client.phoneNumber")
                              ? _c("div", [
                                  _c("div", {
                                    staticClass: "invalid-feedback d-block",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.joinErrors("client.phoneNumber")
                                      ),
                                    },
                                  }),
                                ])
                              : _vm.hasErrors("client.phoneCountry")
                              ? _c("div", [
                                  _c("div", {
                                    staticClass: "invalid-feedback d-block",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.joinErrors("client.phoneCountry")
                                      ),
                                    },
                                  }),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-4 d-none d-md-block pt-2" },
                        [
                          _c("label", [
                            _vm._v("Téléphone "),
                            _vm.mandatoryFields.tel
                              ? _c("small", [_vm._v("*")])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-8" }, [
                        _c(
                          "div",
                          { staticClass: "dropright add-resa-search-client" },
                          [
                            _c("vue-tel-input", {
                              ref: "vueTelInput",
                              class:
                                _vm.command.client.phone.value !== null &&
                                _vm.command.client.phone.isValid === false
                                  ? [
                                      "form-control",
                                      "is-invalid",
                                      "d-flex",
                                      "text-left",
                                    ]
                                  : ["form-control", "d-flex", "text-left"],
                              attrs: {
                                defaultCountry: _vm.defaultTelCountry,
                                name: "telInput",
                                enabledCountryCode: true,
                                inputOptions: {
                                  tabindex: 10,
                                  autocomplete: "off",
                                },
                                mode: "international",
                                inputClasses: "m-0 border-0",
                                placeholder: "Téléphone",
                              },
                              on: {
                                onValidate: _vm.setPhoneAndCountry,
                                onInput: _vm.searchClientByMultiField,
                              },
                              model: {
                                value: _vm.command.client.phone.raw,
                                callback: function ($$v) {
                                  _vm.$set(_vm.command.client.phone, "raw", $$v)
                                },
                                expression: "command.client.phone.raw",
                              },
                            }),
                            _vm._v(" "),
                            (_vm.command.client.phoneNumberHasFocus ||
                              _vm.command.client.search.phoneNumberHasFocus) &&
                            _vm.command.client.search.results &&
                            _vm.command.client.search.results.length > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dropdown-menu phone-field shadow show p-0",
                                    on: {
                                      mouseover: function ($event) {
                                        _vm.command.client.search.phoneNumberHasFocus = true
                                      },
                                      mouseout: function ($event) {
                                        _vm.command.client.search.phoneNumberHasFocus = false
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "ul",
                                      { staticClass: "list-group" },
                                      _vm._l(
                                        _vm.command.client.search.results,
                                        function (result) {
                                          return _c(
                                            "li",
                                            {
                                              staticClass:
                                                "list-group-item p-0",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "d-block p-3",
                                                  attrs: {
                                                    href: "javascript:",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.selectClient(
                                                        result
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(result.firstname) +
                                                        " " +
                                                        _vm._s(result.lastname)
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    " |\n                                                    "
                                                  ),
                                                  _c("span", [
                                                    _vm._v(_vm._s(result.tel)),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "email-search",
                                                    },
                                                    [
                                                      result.company
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                result.company
                                                              ) + " |"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(result.email)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-2" }, [
                      _vm.hasErrors("client.type")
                        ? _c("div", { staticClass: "col-md-12" }, [
                            _c("div", {
                              staticClass: "invalid-feedback d-block",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.joinErrors("client.type")
                                ),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-4 d-none d-md-block pt-2" },
                        [
                          _c("label", [
                            _vm._v(
                              _vm._s(_vm.$tl("labels.clients.type")) + " "
                            ),
                            _vm.mandatoryFields.type
                              ? _c("small", [_vm._v("*")])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-8" },
                        [
                          _c("select-client-type", {
                            attrs: { "restaurant-id": _vm.restaurant_id },
                            model: {
                              value: _vm.command.client.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.command.client, "type", $$v)
                              },
                              expression: "command.client.type",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4 pt-1" }, [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$tl("labels.clients.vip"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-8 lead-switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.command.client.is_vip,
                              expression: "command.client.is_vip",
                            },
                          ],
                          staticClass: "switch align-self-center is-rounded",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.command.client.is_vip)
                              ? _vm._i(_vm.command.client.is_vip, null) > -1
                              : _vm.command.client.is_vip,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.command.client.is_vip,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.command.client,
                                      "is_vip",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.command.client,
                                      "is_vip",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.command.client, "is_vip", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("label", {
                          on: {
                            click: function ($event) {
                              _vm.command.client.is_vip =
                                !_vm.command.client.is_vip
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4 pt-1" }, [
                        _c("label", { staticClass: "none-mobile" }, [
                          _vm._v(
                            _vm._s(_vm.$tl("labels.form.acceptNewsletter"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("label", { staticClass: "none-desk" }, [
                          _vm._v(
                            _vm._s(_vm.$tl("labels.form.acceptNewsletterShort"))
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-8 lead-switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.command.client.newsletter,
                              expression: "command.client.newsletter",
                            },
                          ],
                          staticClass: "switch align-self-center is-rounded",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.command.client.newsletter
                            )
                              ? _vm._i(_vm.command.client.newsletter, null) > -1
                              : _vm.command.client.newsletter,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.command.client.newsletter,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.command.client,
                                      "newsletter",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.command.client,
                                      "newsletter",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.command.client, "newsletter", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("label", {
                          on: {
                            click: function ($event) {
                              _vm.command.client.newsletter =
                                !_vm.command.client.newsletter
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.command.client.phone.search.results !== null &&
                    _vm.command.client.id === null
                      ? _c("div", { staticClass: "add-resa-search-client" }, [
                          _vm.command.client.phone.search.results.length > 0
                            ? _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-danger d-inline-block mt-2 mb-2",
                                  },
                                  [
                                    _vm._v(
                                      "Un client existe déjà avec ce numéro de téléphone."
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                                    Si vous enregistrez la réservation, celui-ci sera mis à jour."
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  { staticClass: "list-group-item p-0" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "d-block p-3",
                                        attrs: { href: "javascript:" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectClient(
                                              _vm.command.client.phone.search
                                                .results[0]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(
                                                _vm.command.client.phone.search
                                                  .results[0].firstname
                                              ) +
                                              "\n                                            " +
                                              _vm._s(
                                                _vm.command.client.phone.search
                                                  .results[0].lastname
                                              ) +
                                              "\n                                        "
                                          ),
                                        ]),
                                        _vm._v(
                                          "\n                                        | "
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.command.client.phone.search
                                                .results[0].tel
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "email-search" },
                                          [
                                            _vm.command.client.phone.search
                                              .results[0].company
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.command.client.phone
                                                        .search.results[0]
                                                        .company
                                                    ) + " |"
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(
                                              _vm._s(
                                                _vm.command.client.phone.search
                                                  .results[0].email
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            : _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "d-inline-block mt-2 mb-2 text-muted",
                                  },
                                  [
                                    _vm._v(
                                      "Le client sera créé à l'enregistrement de la réservation."
                                    ),
                                  ]
                                ),
                              ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-4 d-none d-md-block pt-2" },
                      [_c("label", [_vm._v("Commentaire Privé")])]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-8" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.command.private_comment,
                            expression: "command.private_comment",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { rows: "3" },
                        domProps: { value: _vm.command.private_comment },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.command,
                              "private_comment",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-4 d-none d-md-block pt-2" },
                      [_c("label", [_vm._v("Commentaire Public")])]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-8" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.command.comment,
                            expression: "command.comment",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { rows: "3" },
                        domProps: { value: _vm.command.comment },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.command,
                              "comment",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }