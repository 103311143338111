var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col-5 pt-2" }, [
                    _c("label", { attrs: { for: "name" } }, [
                      _vm._v("Nom "),
                      _c("small", [_vm._v("*")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-7" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.closure.name,
                            expression: "closure.name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.closure.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.closure, "name", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("show-errors", {
                        staticClass: "d-block",
                        attrs: { errors: _vm.errors, errorKey: "name" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col-5 pt-2" }, [
                    _c("label", [
                      _vm._v("Date de début "),
                      _c("small", [_vm._v("*")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-7 date-resa-cal" },
                    [
                      _c("datepicker", {
                        attrs: {
                          tabindex: "1",
                          "monday-first": true,
                          language: _vm.fr,
                          disabledDates: _vm.disabledFromDates,
                        },
                        model: {
                          value: _vm.closure.start_day,
                          callback: function ($$v) {
                            _vm.$set(_vm.closure, "start_day", $$v)
                          },
                          expression: "closure.start_day",
                        },
                      }),
                      _vm._v(" "),
                      _c("show-errors", {
                        staticClass: "d-block",
                        attrs: { errors: _vm.errors, errorKey: "start_day" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col-5 pt-2" }, [
                    _c("label", [
                      _vm._v("Date de fin "),
                      _c("small", [_vm._v("*")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-7 date-resa-cal" },
                    [
                      _c("datepicker", {
                        attrs: {
                          tabindex: "1",
                          "monday-first": true,
                          language: _vm.fr,
                          disabledDates: _vm.disabledToDates,
                        },
                        model: {
                          value: _vm.closure.end_day,
                          callback: function ($$v) {
                            _vm.$set(_vm.closure, "end_day", $$v)
                          },
                          expression: "closure.end_day",
                        },
                      }),
                      _vm._v(" "),
                      _c("show-errors", {
                        staticClass: "d-block",
                        attrs: { errors: _vm.errors, errorKey: "end_day" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mt-2 mb-2" }, [
                  _c("div", { staticClass: "col-5" }, [
                    _c("label", [
                      _vm._v(" Services concernés "),
                      _c("small", [_vm._v("*")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7" }, [
                    _vm.availableCollectTypes.length > 0
                      ? _c(
                          "div",
                          [
                            _c("show-errors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors,
                                errorKey: "collect_types",
                              },
                            }),
                            _vm._v(" "),
                            _c("show-errors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors,
                                errorKey: "collect_types.*",
                              },
                            }),
                            _vm._v(" "),
                            _vm._l(
                              _vm.availableCollectTypes,
                              function (collectType) {
                                return _c(
                                  "div",
                                  { key: collectType },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "container-box",
                                        staticStyle: {
                                          width: "100%",
                                          "text-transform": "capitalize",
                                        },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.closure.collect_types,
                                              expression:
                                                "closure.collect_types",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            name: "collect_types[]",
                                          },
                                          domProps: {
                                            value: collectType,
                                            checked: Array.isArray(
                                              _vm.closure.collect_types
                                            )
                                              ? _vm._i(
                                                  _vm.closure.collect_types,
                                                  collectType
                                                ) > -1
                                              : _vm.closure.collect_types,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.closure.collect_types,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = collectType,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.closure,
                                                      "collect_types",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.closure,
                                                      "collect_types",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.closure,
                                                  "collect_types",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getCollectTypeLabel(
                                                collectType
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.closure.collect_types.includes(
                                      collectType
                                    )
                                      ? [
                                          collectType ===
                                          _vm.COLLECT_TYPE_COLLECT.value
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-4" },
                                                    [
                                                      _c("label", [
                                                        _vm._v("Créneaux "),
                                                        _c("small", [
                                                          _vm._v("*"),
                                                        ]),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-8" },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "row" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-12",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "radio",
                                                                },
                                                                [
                                                                  _c(
                                                                    "label",
                                                                    {
                                                                      staticClass:
                                                                        "container-box",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  _vm
                                                                                    .closure
                                                                                    .all_click_and_collect_slots,
                                                                                expression:
                                                                                  "closure.all_click_and_collect_slots",
                                                                              },
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              type: "radio",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value: 1,
                                                                              checked:
                                                                                _vm._q(
                                                                                  _vm
                                                                                    .closure
                                                                                    .all_click_and_collect_slots,
                                                                                  1
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  _vm.closure,
                                                                                  "all_click_and_collect_slots",
                                                                                  1
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "checkmark",
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " Tous\n                                                        "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-12 d-inline mt-1",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "radio",
                                                                },
                                                                [
                                                                  _c(
                                                                    "label",
                                                                    {
                                                                      staticClass:
                                                                        "container-box d-inline-block pt-0 mt-2",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "initial",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  _vm
                                                                                    .closure
                                                                                    .all_click_and_collect_slots,
                                                                                expression:
                                                                                  "closure.all_click_and_collect_slots",
                                                                              },
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              type: "radio",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value: 0,
                                                                              checked:
                                                                                _vm._q(
                                                                                  _vm
                                                                                    .closure
                                                                                    .all_click_and_collect_slots,
                                                                                  0
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  _vm.closure,
                                                                                  "all_click_and_collect_slots",
                                                                                  0
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "checkmark",
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " Personnaliser\n                                                        "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "show-errors",
                                                                {
                                                                  staticClass:
                                                                    "d-block",
                                                                  attrs: {
                                                                    errors:
                                                                      _vm.errors,
                                                                    errorKey:
                                                                      "collect_slots.data",
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              !_vm.closure
                                                                .all_click_and_collect_slots
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "row mt-3",
                                                                    },
                                                                    _vm._l(
                                                                      _vm.allDays,
                                                                      function (
                                                                        day
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key: day,
                                                                            staticClass:
                                                                              "col-12",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "mb-2",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    staticClass:
                                                                                      "container-box",
                                                                                    staticStyle:
                                                                                      {
                                                                                        width:
                                                                                          "100%",
                                                                                        "text-transform":
                                                                                          "capitalize",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "input",
                                                                                      {
                                                                                        directives:
                                                                                          [
                                                                                            {
                                                                                              name: "model",
                                                                                              rawName:
                                                                                                "v-model",
                                                                                              value:
                                                                                                _vm
                                                                                                  .closureFrontData
                                                                                                  .collectDaysActive,
                                                                                              expression:
                                                                                                "closureFrontData.collectDaysActive",
                                                                                            },
                                                                                          ],
                                                                                        attrs:
                                                                                          {
                                                                                            type: "checkbox",
                                                                                            name: "collect_days_active[]",
                                                                                          },
                                                                                        domProps:
                                                                                          {
                                                                                            value:
                                                                                              day,
                                                                                            checked:
                                                                                              Array.isArray(
                                                                                                _vm
                                                                                                  .closureFrontData
                                                                                                  .collectDaysActive
                                                                                              )
                                                                                                ? _vm._i(
                                                                                                    _vm
                                                                                                      .closureFrontData
                                                                                                      .collectDaysActive,
                                                                                                    day
                                                                                                  ) >
                                                                                                  -1
                                                                                                : _vm
                                                                                                    .closureFrontData
                                                                                                    .collectDaysActive,
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              var $$a =
                                                                                                  _vm
                                                                                                    .closureFrontData
                                                                                                    .collectDaysActive,
                                                                                                $$el =
                                                                                                  $event.target,
                                                                                                $$c =
                                                                                                  $$el.checked
                                                                                                    ? true
                                                                                                    : false
                                                                                              if (
                                                                                                Array.isArray(
                                                                                                  $$a
                                                                                                )
                                                                                              ) {
                                                                                                var $$v =
                                                                                                    day,
                                                                                                  $$i =
                                                                                                    _vm._i(
                                                                                                      $$a,
                                                                                                      $$v
                                                                                                    )
                                                                                                if (
                                                                                                  $$el.checked
                                                                                                ) {
                                                                                                  $$i <
                                                                                                    0 &&
                                                                                                    _vm.$set(
                                                                                                      _vm.closureFrontData,
                                                                                                      "collectDaysActive",
                                                                                                      $$a.concat(
                                                                                                        [
                                                                                                          $$v,
                                                                                                        ]
                                                                                                      )
                                                                                                    )
                                                                                                } else {
                                                                                                  $$i >
                                                                                                    -1 &&
                                                                                                    _vm.$set(
                                                                                                      _vm.closureFrontData,
                                                                                                      "collectDaysActive",
                                                                                                      $$a
                                                                                                        .slice(
                                                                                                          0,
                                                                                                          $$i
                                                                                                        )
                                                                                                        .concat(
                                                                                                          $$a.slice(
                                                                                                            $$i +
                                                                                                              1
                                                                                                          )
                                                                                                        )
                                                                                                    )
                                                                                                }
                                                                                              } else {
                                                                                                _vm.$set(
                                                                                                  _vm.closureFrontData,
                                                                                                  "collectDaysActive",
                                                                                                  $$c
                                                                                                )
                                                                                              }
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "checkmark",
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      "\n                                                                    " +
                                                                                        _vm._s(
                                                                                          _vm.dayLabel(
                                                                                            day
                                                                                          )
                                                                                        ) +
                                                                                        "\n                                                                "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm.closureFrontData.collectDaysActive.includes(
                                                                              day
                                                                            )
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "setting-open",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "setting-hours",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          _vm._l(
                                                                                            _vm.getServicesForDay(
                                                                                              day
                                                                                            ),
                                                                                            function (
                                                                                              service
                                                                                            ) {
                                                                                              return _c(
                                                                                                "div",
                                                                                                {
                                                                                                  key: service.id,
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "input",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "form-control d-inline-block",
                                                                                                      staticStyle:
                                                                                                        {
                                                                                                          "max-width":
                                                                                                            "60px",
                                                                                                        },
                                                                                                      attrs:
                                                                                                        {
                                                                                                          type: "text",
                                                                                                          disabled: true,
                                                                                                        },
                                                                                                      domProps:
                                                                                                        {
                                                                                                          value:
                                                                                                            service.start_hour,
                                                                                                        },
                                                                                                    }
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    " "
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "input",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "form-control d-inline-block",
                                                                                                      staticStyle:
                                                                                                        {
                                                                                                          "max-width":
                                                                                                            "60px",
                                                                                                        },
                                                                                                      attrs:
                                                                                                        {
                                                                                                          type: "text",
                                                                                                          disabled: true,
                                                                                                        },
                                                                                                      domProps:
                                                                                                        {
                                                                                                          value:
                                                                                                            service.end_hour,
                                                                                                        },
                                                                                                    }
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    " "
                                                                                                  ),
                                                                                                  _vm._l(
                                                                                                    service.slots,
                                                                                                    function (
                                                                                                      slot
                                                                                                    ) {
                                                                                                      return _c(
                                                                                                        "label",
                                                                                                        {
                                                                                                          key: slot.id,
                                                                                                          staticClass:
                                                                                                            "container-box ml-4",
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              width:
                                                                                                                "initial",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "input",
                                                                                                            {
                                                                                                              directives:
                                                                                                                [
                                                                                                                  {
                                                                                                                    name: "model",
                                                                                                                    rawName:
                                                                                                                      "v-model",
                                                                                                                    value:
                                                                                                                      _vm
                                                                                                                        .closure
                                                                                                                        .collect_slots
                                                                                                                        .data,
                                                                                                                    expression:
                                                                                                                      "\n                                                                                        closure.collect_slots.data\n                                                                                    ",
                                                                                                                  },
                                                                                                                ],
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  type: "checkbox",
                                                                                                                  name: "collect_slots[]",
                                                                                                                },
                                                                                                              domProps:
                                                                                                                {
                                                                                                                  value:
                                                                                                                    slot.id,
                                                                                                                  checked:
                                                                                                                    Array.isArray(
                                                                                                                      _vm
                                                                                                                        .closure
                                                                                                                        .collect_slots
                                                                                                                        .data
                                                                                                                    )
                                                                                                                      ? _vm._i(
                                                                                                                          _vm
                                                                                                                            .closure
                                                                                                                            .collect_slots
                                                                                                                            .data,
                                                                                                                          slot.id
                                                                                                                        ) >
                                                                                                                        -1
                                                                                                                      : _vm
                                                                                                                          .closure
                                                                                                                          .collect_slots
                                                                                                                          .data,
                                                                                                                },
                                                                                                              on: {
                                                                                                                change:
                                                                                                                  function (
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    var $$a =
                                                                                                                        _vm
                                                                                                                          .closure
                                                                                                                          .collect_slots
                                                                                                                          .data,
                                                                                                                      $$el =
                                                                                                                        $event.target,
                                                                                                                      $$c =
                                                                                                                        $$el.checked
                                                                                                                          ? true
                                                                                                                          : false
                                                                                                                    if (
                                                                                                                      Array.isArray(
                                                                                                                        $$a
                                                                                                                      )
                                                                                                                    ) {
                                                                                                                      var $$v =
                                                                                                                          slot.id,
                                                                                                                        $$i =
                                                                                                                          _vm._i(
                                                                                                                            $$a,
                                                                                                                            $$v
                                                                                                                          )
                                                                                                                      if (
                                                                                                                        $$el.checked
                                                                                                                      ) {
                                                                                                                        $$i <
                                                                                                                          0 &&
                                                                                                                          _vm.$set(
                                                                                                                            _vm
                                                                                                                              .closure
                                                                                                                              .collect_slots,
                                                                                                                            "data",
                                                                                                                            $$a.concat(
                                                                                                                              [
                                                                                                                                $$v,
                                                                                                                              ]
                                                                                                                            )
                                                                                                                          )
                                                                                                                      } else {
                                                                                                                        $$i >
                                                                                                                          -1 &&
                                                                                                                          _vm.$set(
                                                                                                                            _vm
                                                                                                                              .closure
                                                                                                                              .collect_slots,
                                                                                                                            "data",
                                                                                                                            $$a
                                                                                                                              .slice(
                                                                                                                                0,
                                                                                                                                $$i
                                                                                                                              )
                                                                                                                              .concat(
                                                                                                                                $$a.slice(
                                                                                                                                  $$i +
                                                                                                                                    1
                                                                                                                                )
                                                                                                                              )
                                                                                                                          )
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      _vm.$set(
                                                                                                                        _vm
                                                                                                                          .closure
                                                                                                                          .collect_slots,
                                                                                                                        "data",
                                                                                                                        $$c
                                                                                                                      )
                                                                                                                    }
                                                                                                                  },
                                                                                                              },
                                                                                                            }
                                                                                                          ),
                                                                                                          _vm._v(
                                                                                                            " "
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "span",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "checkmark",
                                                                                                            }
                                                                                                          ),
                                                                                                          _vm._v(
                                                                                                            "\n                                                                                " +
                                                                                                              _vm._s(
                                                                                                                slot.hour
                                                                                                                  .replace(
                                                                                                                    ":",
                                                                                                                    "h"
                                                                                                                  )
                                                                                                                  .substr(
                                                                                                                    0,
                                                                                                                    5
                                                                                                                  )
                                                                                                              ) +
                                                                                                              "\n                                                                            "
                                                                                                          ),
                                                                                                        ]
                                                                                                      )
                                                                                                    }
                                                                                                  ),
                                                                                                ],
                                                                                                2
                                                                                              )
                                                                                            }
                                                                                          ),
                                                                                          0
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : collectType ===
                                              _vm.COLLECT_TYPE_DELIVERY.value
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-5" },
                                                    [
                                                      _c("label", [
                                                        _vm._v("Créneaux "),
                                                        _c("small", [
                                                          _vm._v("*"),
                                                        ]),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-7" },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "row" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-12",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "radio",
                                                                },
                                                                [
                                                                  _c(
                                                                    "label",
                                                                    {
                                                                      staticClass:
                                                                        "container-box",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  _vm
                                                                                    .closure
                                                                                    .all_delivery_slots,
                                                                                expression:
                                                                                  "closure.all_delivery_slots",
                                                                              },
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              type: "radio",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value: 1,
                                                                              checked:
                                                                                _vm._q(
                                                                                  _vm
                                                                                    .closure
                                                                                    .all_delivery_slots,
                                                                                  1
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  _vm.closure,
                                                                                  "all_delivery_slots",
                                                                                  1
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "checkmark",
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " Tous\n                                                        "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-12 d-inline mt-1",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "radio",
                                                                },
                                                                [
                                                                  _c(
                                                                    "label",
                                                                    {
                                                                      staticClass:
                                                                        "container-box d-inline-block pt-0 mt-2",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "initial",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  _vm
                                                                                    .closure
                                                                                    .all_delivery_slots,
                                                                                expression:
                                                                                  "closure.all_delivery_slots",
                                                                              },
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              type: "radio",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value: 0,
                                                                              checked:
                                                                                _vm._q(
                                                                                  _vm
                                                                                    .closure
                                                                                    .all_delivery_slots,
                                                                                  0
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  _vm.closure,
                                                                                  "all_delivery_slots",
                                                                                  0
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "checkmark",
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " Personnaliser\n                                                        "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "show-errors",
                                                                {
                                                                  staticClass:
                                                                    "d-block",
                                                                  attrs: {
                                                                    errors:
                                                                      _vm.errors,
                                                                    errorKey:
                                                                      "delivery_slots.data",
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              !_vm.closure
                                                                .all_delivery_slots
                                                                ? _c(
                                                                    "div",
                                                                    _vm._l(
                                                                      _vm.allDays,
                                                                      function (
                                                                        day
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key: day,
                                                                            staticClass:
                                                                              "col-12",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "mb-2",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    staticClass:
                                                                                      "container-box",
                                                                                    staticStyle:
                                                                                      {
                                                                                        width:
                                                                                          "100%",
                                                                                        "text-transform":
                                                                                          "capitalize",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "input",
                                                                                      {
                                                                                        directives:
                                                                                          [
                                                                                            {
                                                                                              name: "model",
                                                                                              rawName:
                                                                                                "v-model",
                                                                                              value:
                                                                                                _vm
                                                                                                  .closureFrontData
                                                                                                  .deliveryDaysActive,
                                                                                              expression:
                                                                                                "\n                                                                            closureFrontData.deliveryDaysActive\n                                                                        ",
                                                                                            },
                                                                                          ],
                                                                                        attrs:
                                                                                          {
                                                                                            type: "checkbox",
                                                                                            name: "delivery_days_active[]",
                                                                                          },
                                                                                        domProps:
                                                                                          {
                                                                                            value:
                                                                                              day,
                                                                                            checked:
                                                                                              Array.isArray(
                                                                                                _vm
                                                                                                  .closureFrontData
                                                                                                  .deliveryDaysActive
                                                                                              )
                                                                                                ? _vm._i(
                                                                                                    _vm
                                                                                                      .closureFrontData
                                                                                                      .deliveryDaysActive,
                                                                                                    day
                                                                                                  ) >
                                                                                                  -1
                                                                                                : _vm
                                                                                                    .closureFrontData
                                                                                                    .deliveryDaysActive,
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              var $$a =
                                                                                                  _vm
                                                                                                    .closureFrontData
                                                                                                    .deliveryDaysActive,
                                                                                                $$el =
                                                                                                  $event.target,
                                                                                                $$c =
                                                                                                  $$el.checked
                                                                                                    ? true
                                                                                                    : false
                                                                                              if (
                                                                                                Array.isArray(
                                                                                                  $$a
                                                                                                )
                                                                                              ) {
                                                                                                var $$v =
                                                                                                    day,
                                                                                                  $$i =
                                                                                                    _vm._i(
                                                                                                      $$a,
                                                                                                      $$v
                                                                                                    )
                                                                                                if (
                                                                                                  $$el.checked
                                                                                                ) {
                                                                                                  $$i <
                                                                                                    0 &&
                                                                                                    _vm.$set(
                                                                                                      _vm.closureFrontData,
                                                                                                      "deliveryDaysActive",
                                                                                                      $$a.concat(
                                                                                                        [
                                                                                                          $$v,
                                                                                                        ]
                                                                                                      )
                                                                                                    )
                                                                                                } else {
                                                                                                  $$i >
                                                                                                    -1 &&
                                                                                                    _vm.$set(
                                                                                                      _vm.closureFrontData,
                                                                                                      "deliveryDaysActive",
                                                                                                      $$a
                                                                                                        .slice(
                                                                                                          0,
                                                                                                          $$i
                                                                                                        )
                                                                                                        .concat(
                                                                                                          $$a.slice(
                                                                                                            $$i +
                                                                                                              1
                                                                                                          )
                                                                                                        )
                                                                                                    )
                                                                                                }
                                                                                              } else {
                                                                                                _vm.$set(
                                                                                                  _vm.closureFrontData,
                                                                                                  "deliveryDaysActive",
                                                                                                  $$c
                                                                                                )
                                                                                              }
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "checkmark",
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      "\n                                                                    " +
                                                                                        _vm._s(
                                                                                          _vm.dayLabel(
                                                                                            day
                                                                                          )
                                                                                        ) +
                                                                                        "\n                                                                "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm.closureFrontData.deliveryDaysActive.includes(
                                                                              day
                                                                            )
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "setting-open",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "setting-hours",
                                                                                      },
                                                                                      _vm._l(
                                                                                        _vm.getDeliverySlotsForDay(
                                                                                          day
                                                                                        ),
                                                                                        function (
                                                                                          slot
                                                                                        ) {
                                                                                          return _c(
                                                                                            "span",
                                                                                            {
                                                                                              key: slot.id,
                                                                                              staticClass:
                                                                                                "d-block",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "label",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "container-box ml-4",
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      width:
                                                                                                        "initial",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "input",
                                                                                                    {
                                                                                                      directives:
                                                                                                        [
                                                                                                          {
                                                                                                            name: "model",
                                                                                                            rawName:
                                                                                                              "v-model",
                                                                                                            value:
                                                                                                              _vm
                                                                                                                .closure
                                                                                                                .delivery_slots
                                                                                                                .data,
                                                                                                            expression:
                                                                                                              "\n                                                                                    closure.delivery_slots.data\n                                                                                ",
                                                                                                          },
                                                                                                        ],
                                                                                                      attrs:
                                                                                                        {
                                                                                                          type: "checkbox",
                                                                                                          name: "delivery_slots[]",
                                                                                                        },
                                                                                                      domProps:
                                                                                                        {
                                                                                                          value:
                                                                                                            slot.id,
                                                                                                          checked:
                                                                                                            Array.isArray(
                                                                                                              _vm
                                                                                                                .closure
                                                                                                                .delivery_slots
                                                                                                                .data
                                                                                                            )
                                                                                                              ? _vm._i(
                                                                                                                  _vm
                                                                                                                    .closure
                                                                                                                    .delivery_slots
                                                                                                                    .data,
                                                                                                                  slot.id
                                                                                                                ) >
                                                                                                                -1
                                                                                                              : _vm
                                                                                                                  .closure
                                                                                                                  .delivery_slots
                                                                                                                  .data,
                                                                                                        },
                                                                                                      on: {
                                                                                                        change:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            var $$a =
                                                                                                                _vm
                                                                                                                  .closure
                                                                                                                  .delivery_slots
                                                                                                                  .data,
                                                                                                              $$el =
                                                                                                                $event.target,
                                                                                                              $$c =
                                                                                                                $$el.checked
                                                                                                                  ? true
                                                                                                                  : false
                                                                                                            if (
                                                                                                              Array.isArray(
                                                                                                                $$a
                                                                                                              )
                                                                                                            ) {
                                                                                                              var $$v =
                                                                                                                  slot.id,
                                                                                                                $$i =
                                                                                                                  _vm._i(
                                                                                                                    $$a,
                                                                                                                    $$v
                                                                                                                  )
                                                                                                              if (
                                                                                                                $$el.checked
                                                                                                              ) {
                                                                                                                $$i <
                                                                                                                  0 &&
                                                                                                                  _vm.$set(
                                                                                                                    _vm
                                                                                                                      .closure
                                                                                                                      .delivery_slots,
                                                                                                                    "data",
                                                                                                                    $$a.concat(
                                                                                                                      [
                                                                                                                        $$v,
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  )
                                                                                                              } else {
                                                                                                                $$i >
                                                                                                                  -1 &&
                                                                                                                  _vm.$set(
                                                                                                                    _vm
                                                                                                                      .closure
                                                                                                                      .delivery_slots,
                                                                                                                    "data",
                                                                                                                    $$a
                                                                                                                      .slice(
                                                                                                                        0,
                                                                                                                        $$i
                                                                                                                      )
                                                                                                                      .concat(
                                                                                                                        $$a.slice(
                                                                                                                          $$i +
                                                                                                                            1
                                                                                                                        )
                                                                                                                      )
                                                                                                                  )
                                                                                                              }
                                                                                                            } else {
                                                                                                              _vm.$set(
                                                                                                                _vm
                                                                                                                  .closure
                                                                                                                  .delivery_slots,
                                                                                                                "data",
                                                                                                                $$c
                                                                                                              )
                                                                                                            }
                                                                                                          },
                                                                                                      },
                                                                                                    }
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    " "
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "checkmark",
                                                                                                    }
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        slot.hour_start
                                                                                                          .substr(
                                                                                                            0,
                                                                                                            5
                                                                                                          )
                                                                                                          .replace(
                                                                                                            ":",
                                                                                                            "h"
                                                                                                          )
                                                                                                      ) +
                                                                                                      "\n                                                                            -\n                                                                            " +
                                                                                                      _vm._s(
                                                                                                        slot.hour_end
                                                                                                          .substr(
                                                                                                            0,
                                                                                                            5
                                                                                                          )
                                                                                                          .replace(
                                                                                                            ":",
                                                                                                            "h"
                                                                                                          )
                                                                                                      ) +
                                                                                                      "\n                                                                        "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                      0
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : collectType ===
                                              _vm.COLLECT_TYPE_DISPATCH.value
                                            ? [
                                                _c(
                                                  "div",
                                                  { staticClass: "row mb-3" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "col-md-4",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            "Message à afficher sur le widget "
                                                          ),
                                                          _c("small", [
                                                            _vm._v("*"),
                                                          ]),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "col-md-8",
                                                      },
                                                      [
                                                        _c("textarea", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.closure
                                                                  .dispatch_message,
                                                              expression:
                                                                "closure.dispatch_message",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          domProps: {
                                                            value:
                                                              _vm.closure
                                                                .dispatch_message,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                _vm.closure,
                                                                "dispatch_message",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("show-errors", {
                                                          staticClass:
                                                            "d-block",
                                                          attrs: {
                                                            errors: _vm.errors,
                                                            errorKey:
                                                              "dispatch_message",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "col-md-4",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            "Désactiver les commandes sur cette période\n                                                    "
                                                          ),
                                                          _c("small", [
                                                            _vm._v("*"),
                                                          ]),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "col-md-8",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-inline-block align-middle lead-switch",
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.closure
                                                                      .disable_dispatch_widget,
                                                                  expression:
                                                                    "closure.disable_dispatch_widget",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "switch align-self-center is-rounded",
                                                              attrs: {
                                                                type: "checkbox",
                                                              },
                                                              domProps: {
                                                                checked:
                                                                  Array.isArray(
                                                                    _vm.closure
                                                                      .disable_dispatch_widget
                                                                  )
                                                                    ? _vm._i(
                                                                        _vm
                                                                          .closure
                                                                          .disable_dispatch_widget,
                                                                        null
                                                                      ) > -1
                                                                    : _vm
                                                                        .closure
                                                                        .disable_dispatch_widget,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$a =
                                                                        _vm
                                                                          .closure
                                                                          .disable_dispatch_widget,
                                                                      $$el =
                                                                        $event.target,
                                                                      $$c =
                                                                        $$el.checked
                                                                          ? true
                                                                          : false
                                                                    if (
                                                                      Array.isArray(
                                                                        $$a
                                                                      )
                                                                    ) {
                                                                      var $$v =
                                                                          null,
                                                                        $$i =
                                                                          _vm._i(
                                                                            $$a,
                                                                            $$v
                                                                          )
                                                                      if (
                                                                        $$el.checked
                                                                      ) {
                                                                        $$i <
                                                                          0 &&
                                                                          _vm.$set(
                                                                            _vm.closure,
                                                                            "disable_dispatch_widget",
                                                                            $$a.concat(
                                                                              [
                                                                                $$v,
                                                                              ]
                                                                            )
                                                                          )
                                                                      } else {
                                                                        $$i >
                                                                          -1 &&
                                                                          _vm.$set(
                                                                            _vm.closure,
                                                                            "disable_dispatch_widget",
                                                                            $$a
                                                                              .slice(
                                                                                0,
                                                                                $$i
                                                                              )
                                                                              .concat(
                                                                                $$a.slice(
                                                                                  $$i +
                                                                                    1
                                                                                )
                                                                              )
                                                                          )
                                                                      }
                                                                    } else {
                                                                      _vm.$set(
                                                                        _vm.closure,
                                                                        "disable_dispatch_widget",
                                                                        $$c
                                                                      )
                                                                    }
                                                                  },
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("label", {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.closure.disable_dispatch_widget =
                                                                      !_vm
                                                                        .closure
                                                                        .disable_dispatch_widget
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _c("div", [
                          _c("em", [
                            _vm._v(
                              "Aucun service n'est disponible. Merci de vérifier votre configuration."
                            ),
                          ]),
                        ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }