<template>
    <div>
        <div class="row bg-light text-center text-uppercase nav-campaign">
            <div class="nav-campaign-item col-sm-4 pt-3 pb-3" :class="{ active: step === 1 }">1 - Composez votre message</div>
            <div class="nav-campaign-item col-sm-4 pt-3 pb-3" :class="{ active: step === 2 }">2 - Choisissez votre cible</div>
            <div class="nav-campaign-item col-sm-4 pt-3 pb-3" :class="{ active: step === 3 }">3 - Diffuser votre campagne</div>
        </div>
        <LoaderComponent v-if="loading" />
        <template v-else>
            <div class="row" v-show="step === 1">
                <div class="col-lg-12 pt-3">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <div class="row">
                            <div class="col-md-4 mb-2">
                                <strong>Nom de la campagne</strong>
                            </div>
                            <div class="col-md-8">
                                <input type="text" v-model="campaign.name" class="form-control" />
                                <show-errors class="d-block" :errors="errors" error-key="name" />
                            </div>
                        </div>
                        <div class="row mt-3 switch-type">
                            <div class="col-md-4 mb-2">
                                <strong>Type de campagne</strong>
                            </div>
                            <div class="col-md-8" v-if="campaign.paid_at === null">
                                <toggle-input
                                    v-model="smsEnabled"
                                    :disabled="campaign.id !== null"
                                    :tooltip="
                                        campaign.id !== null
                                            ? getTooltip('Vous ne pouvez pas changer le type d\'une campagne une fois créée')
                                            : undefined
                                    ">
                                    <template #pre-label>Email</template>
                                    <template #post-label>SMS</template>
                                </toggle-input>
                                <show-errors class="d-block" :errors="errors" error-key="type" />
                            </div>
                            <div class="col-md-8" v-else>SMS</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-show="step === 1">
                <div class="col-lg-12">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <div class="row">
                            <div class="col-12">
                                <strong>Composez votre message</strong>
                            </div>
                        </div>
                        <template v-if="!smsEnabled">
                            <div class="row mt-3">
                                <div class="col-md-4 pt-2 mb-2">Objet *</div>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" v-model="campaign.mail_object" />
                                    <show-errors class="d-block" :errors="errors" error-key="mail_object" />
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-4 pt-2 mb-2">
                                    <span class="d-block mb-1">Message *</span>
                                    <button
                                        type="button"
                                        @click="addToMessage('[FIRSTNAME]')"
                                        class="badge badge-secondary mb-1"
                                        style="border: none">
                                        Insérer le prénom du client
                                    </button>
                                    <button type="button" @click="addToMessage('[LASTNAME]')" class="badge badge-secondary mb-1" style="border: none">
                                        Insérer le nom du client
                                    </button>
                                </div>
                                <div class="col-md-8">
                                    <textarea
                                        ref="txtEmailBody"
                                        class="form-control"
                                        style="font-size: 0.75rem"
                                        v-model="campaign.content"
                                        rows="8"></textarea>
                                    <show-errors class="d-block" :errors="errors" error-key="content" />
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-4 mb-2">Image</div>
                                <div class="col-md-8">
                                    <ImageComponent
                                        :src="campaign.img_path"
                                        suffixId="campaign"
                                        :postURI="imgPostUri"
                                        :maxSize="10"
                                        :callBack="
                                            (response) => {
                                                this.campaign.img_path = response.data.img_path;
                                            }
                                        " />
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-4 pt-2 mb-2">Lien au clique sur l'image</div>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" v-model="campaign.img_link" />
                                    <show-errors class="d-block" :errors="errors" error-key="img_link" />
                                </div>
                            </div>
                        </template>

                        <template v-else>
                            <div class="row mt-3">
                                <div class="col-md-5">
                                    <label class="mb-1">Nom affiché au client, à la réception : *</label>
                                    <small class="d-block">11 caractères maximum</small>
                                </div>
                                <div class="col-md-7">
                                    <input
                                        type="text"
                                        :class="{ 'is-invalid': !campaign.sms_expeditor.match(/^[a-z]+$/i) }"
                                        v-model="campaign.sms_expeditor"
                                        class="form-control mb-2"
                                        maxlength="11" />
                                    <show-errors class="d-block pl-2" :errors="errors" error-key="sms_expeditor" />
                                    <span class="limiter text-muted pl-2"
                                        >Il vous reste {{ sms_emitter_name_count }} caractère{{ sms_emitter_name_count > 1 ? "s" : "" }}</span
                                    >
                                    <small class="text-danger d-block pl-2"
                                        >Attention : seule les lettres minuscules et majuscules sont autorisées, les caractères spéciaux, chiffres,
                                        espaces, ou lettres accentuées ne sont pas acceptés par notre prestataire d'envoi de SMS.</small
                                    >
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-5 pt-2 mb-2">Message *</div>
                                <div class="col-md-7">
                                    <textarea
                                        :disabled="campaign.paid_at !== null"
                                        class="form-control mb-2"
                                        v-model="campaign.content"
                                        rows="4"
                                        maxlength="469"></textarea>
                                    <div class="text-danger font-weight-bold" v-if="doesSmsContainEmojis">
                                        {{ $tl("errors.common.sms.emojisNotAllowed") }}
                                    </div>
                                    <span class="limiter text-muted pl-2"
                                        >{{ sms_message_count }} caractère{{ sms_message_count > 1 ? "s" : "" }}</span
                                    >
                                    /
                                    <span v-if="sms_message_count <= 149" class="text-muted">1 crédit</span>
                                    <span v-else-if="sms_message_count <= 309" class="text-muted">2 crédits</span>
                                    <span v-else class="text-muted">3 crédits</span>
                                    <show-errors class="d-block" :errors="errors" error-key="content" />
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="row" v-show="step === 2">
                <div class="col-lg-12 pt-3">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <div class="row">
                            <div class="col-12">
                                <strong>Choisissez votre cible</strong>
                                <show-errors class="d-block" :errors="errors" error-key="targets" />
                            </div>
                        </div>
                        <div class="row" v-if="campaign.paid_at !== null">
                            <div class="col-12 text-danger">Désolé, vous ne pouvez pas modifiez vos cibles une fois la campagne payée</div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-md-5 mt-3">
                                <div class="row">
                                    <div class="col-9 pt-1">Tous mes clients ({{ clients.all }})</div>
                                    <div class="col-3">
                                        <label class="container-box">
                                            <input type="checkbox" v-model="hasCheckedAllFilters" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <show-errors class="col-12" :errors="errors" error-key="client_types" />
                                </div>
                                <div class="row mt-3">
                                    <div class="col-9 pt-1">Inclure les clients VIP ({{ clients.vip }})</div>
                                    <div class="col-3">
                                        <label class="container-box">
                                            <input type="checkbox" v-model="campaign.include_vip" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <show-errors class="col-12" :errors="errors" error-key="include_vip" />
                                </div>
                                <div class="row mt-3">
                                    <div class="col-9 pt-1">Inclure les clients à risque ({{ clients.risky }})</div>
                                    <div class="col-3">
                                        <label class="container-box">
                                            <input type="checkbox" v-model="campaign.include_risky" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <show-errors class="col-12" :errors="errors" error-key="include_vip" />
                                </div>
                                <div class="row mt-3" v-for="(clientType, index) in availableClientTypes" :key="clientType">
                                    <div class="col-9 pt-1">
                                        {{ clientType === "no_status" ? "Aucune typologie attribuée" : getClientTypeLabel(clientType) }} ({{
                                            clients.types[clientType]
                                        }})
                                    </div>
                                    <div class="col-3">
                                        <label class="container-box">
                                            <input type="checkbox" name="client_types[]" v-model="campaign.client_types" :value="clientType" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <show-errors class="col-12" :errors="errors" :error-key="`client_types.${index}`" />
                                </div>
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <vue-multiselect
                                            v-model="selectedLoyaltyLevels"
                                            :options="availableLoyaltyLevels"
                                            :multiple="true"
                                            label="label"
                                            track-by="value"
                                            placeholder="Niveau de fidélité"
                                            :searchable="false"
                                            :close-on-select="false"
                                            :show-labels="false"
                                            open-direction="bottom">
                                        </vue-multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7 mt-3">
                                <div class="row">
                                    <div class="col-9 pt-1">Filtres avancés</div>
                                    <div class="col-3">
                                        <input type="checkbox" class="switch align-self-center is-rounded" :checked="advanceFilters" />
                                        <label @click="advanceFilters = !advanceFilters"></label>
                                    </div>
                                </div>
                                <template v-if="advanceFilters">
                                    <div class="row mt-3">
                                        <div class="col-9 pt-1">Ayant une réservation</div>
                                        <checkbox-input-component
                                            class="col-3"
                                            v-model="campaign.at_least_one_resa"
                                            error-key="at_least_one_resa"></checkbox-input-component>
                                    </div>
                                    <template v-if="campaign.at_least_one_resa">
                                        <div class="row mt-3">
                                            <div class="col-9 pt-1">Réservation passée</div>
                                            <checkbox-input-component
                                                class="col-3"
                                                v-model="campaign.at_least_one_past_resa"
                                                error-key="at_least_one_past_resa"></checkbox-input-component>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-9 pt-1">Réservation future</div>
                                            <checkbox-input-component
                                                class="col-3"
                                                v-model="campaign.at_least_one_future_resa"
                                                error-key="at_least_one_future_resa"></checkbox-input-component>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-9 pt-1">Réservation dans l'établissement {{ restaurantName }} uniquement</div>
                                            <checkbox-input-component
                                                class="col-3"
                                                v-model="campaign.at_least_one_resa_in_restaurant"
                                                error-key="at_least_one_resa_in_restaurant"></checkbox-input-component>
                                        </div>
                                    </template>
                                    <template v-if="smsEnabled">
                                        <div class="row mt-3">
                                            <div class="col-9">
                                                <label class="mb-1">Envoyer la campagne aux numéros étrangers</label>
                                                <a href="#" class="d-block" @click="showInternationalSmsCostModal = true"
                                                    ><small>Coût en crédits des sms à l'étranger</small></a
                                                >
                                            </div>
                                            <div class="col-3">
                                                <label class="container-box">
                                                    <input type="checkbox" v-model="campaign.send_to_foreign_numbers" true-value="1" />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <show-errors class="d-block" :errors="errors" error-key="send_to_foreign_numbers" />
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-show="step === 3">
                <div class="col-lg-12 pt-3">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <div class="row">
                            <div class="col-12">
                                <strong>Diffuser votre campagne</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row mb-2 radio mt-3">
                                    <div class="col-12 pt-2">
                                        <label class="container-box" style="display: block; width: 100%">
                                            <input type="radio" name="civility" value="send" tabindex="5" v-model="campaign.save_type" />
                                            <span class="checkmark"></span> Envoyer maintenant
                                        </label>
                                        <label class="container-box" style="display: block; width: 100%">
                                            <input type="radio" name="civility" value="save" tabindex="6" v-model="campaign.save_type" />
                                            <span class="checkmark"></span> Enregistrer sans envoyer
                                        </label>
                                        <label class="container-box" style="display: block; width: 100%">
                                            <input type="radio" name="civility" value="prog" tabindex="6" v-model="campaign.save_type" />
                                            <span class="checkmark"></span> Programmer l'envoi
                                        </label>
                                    </div>
                                </div>
                                <template v-if="campaign.save_type === 'prog'">
                                    <div class="row mt-3">
                                        <div class="col-md-5">Date</div>
                                        <div class="col-md-7">
                                            <datepicker
                                                format="dd/MM/yyyy"
                                                :disabled-dates="disabledDates"
                                                calendar-class="positition-relative mt-2"
                                                input-class="w-auto"
                                                :monday-first="true"
                                                :language="fr"
                                                v-model="willBeSendAtDate"></datepicker>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-md-5">Heure</div>
                                        <div class="col-md-7">
                                            <vue-timepicker
                                                format="HH:mm"
                                                :minute-interval="15"
                                                v-model="willBeSendAtHour"
                                                :hide-disabled-items="true"
                                                :hour-range="hourRange"
                                                :lazy="true"
                                                @change="dateOrHourChanged"></vue-timepicker>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="col-md-6 mt-3">
                                <template v-if="smsEnabled">
                                    <div class="row">
                                        <div class="col-12 mb-1">
                                            <button
                                                @click="showPurchaseSmsCampaignModalFct()"
                                                :disabled="campaign.paid_at !== null || !has_right_to_buy_sms || campaign.is_paying == 1"
                                                type="button"
                                                class="btn btn-success btn-circle btn-sm">
                                                Payer la campagne
                                            </button>
                                            <small class="d-block text-warning" v-if="campaign.is_paying">
                                                Une tentative de paiement est en cours.
                                            </small>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">Envoyer un SMS de test ({{ campaign.nb_sms_test_remaining }} restant(s))</div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-12">
                                            <input
                                                type="phone"
                                                name="tel"
                                                v-model="campaign.tel_test"
                                                class="form-control"
                                                placeholder="Votre numéro de teléphone" />
                                        </div>
                                    </div>
                                    <div class="row mt-2" v-if="campaign.nb_sms_test_remaining >= 1">
                                        <div class="col-12 text-danger">Merci de saisir un numéro français et de ne pas répondre au SMS</div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-12">
                                            <button
                                                :disabled="campaign.nb_sms_test_remaining < 1"
                                                class="btn btn-sm btn-success btn-circle"
                                                type="button"
                                                @click="$emit('sendSmsTest')">
                                                Envoyer
                                            </button>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="row">
                                        <div class="col-8 mb-1">Nombre d'emails disponibles :</div>
                                        <div :class="credits.nb_clients > credits.nb_campaign_emails_remaining ? 'col-4 text-danger' : 'col-4'">
                                            {{ credits.nb_campaign_emails_remaining }}
                                        </div>
                                        <div class="col-8 mb-1">Nombre de cibles potentielles :</div>
                                        <div class="col-4">{{ credits.nb_clients }}</div>
                                        <div class="col-12" v-if="credits.nb_clients > credits.nb_campaign_emails_remaining">
                                            <button
                                                @click="showPurchaseEmailsCampaignModal = true"
                                                type="button"
                                                class="btn btn-success btn-circle btn-sm"
                                                :disabled="!has_right_to_buy_sms || is_paying_emails_campaign == 1">
                                                Acheter des crédits emails
                                            </button>
                                            <small class="d-block text-warning mt-1" v-if="is_paying_emails_campaign">
                                                Une tentative d'achat d'un pack d'emails de campagne est en cours
                                            </small>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-12">Envoyer un EMAIL de test</div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-12">
                                            <input
                                                type="email"
                                                name="email"
                                                v-model="campaign.email_test"
                                                class="form-control"
                                                placeholder="Votre email" />
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-12">
                                            <button class="btn btn-sm btn-success btn-circle" type="button" @click="$emit('sendEmailTest')">
                                                Envoyer
                                            </button>
                                        </div>
                                    </div>
                                </template>
                                <show-errors v-if="campaign.save_type === 'prog'" class="d-block mt-4" :errors="errors" error-key="will_be_send_at" />
                            </div>
                        </div>
                        <div v-if="smsEnabled" class="row mt-2">
                            <div class="col-12">
                                <small class="text-danger">
                                    Pour information, les envois de campagnes vers des numéros français ne sont pas autorisés le dimanche, les jours
                                    fériés et la nuit entre 22h et 8h
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <InternationalSmsCostModal v-if="showInternationalSmsCostModal" @close="showInternationalSmsCostModal = false" />
        <PurchaseEmailsCampaignPackModal
            v-if="showPurchaseEmailsCampaignModal"
            @close="showPurchaseEmailsCampaignModal = false"
            @paid="emailsCampaignPackPaid" />
        <PurchaseSmsCampaignModal
            v-if="showPurchaseSmsCampaignModal"
            :campaign_id="campaign.id"
            @close="showPurchaseSmsCampaignModal = false"
            @paid="smsCampaignPaid" />
    </div>
</template>

<script>
import InternationalSmsCostModal from "../Modals/sms/InternationalSmsCostModal.vue";
import ImageComponent from "../forms/ImageComponent.vue";
import LoaderComponent from "../LoaderComponent.vue";
import VueTimepicker from "vue2-timepicker";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import PurchaseEmailsCampaignPackModal from "../Modals/campaign/PurchaseEmailsCampaignPackModal.vue";
import PurchaseSmsCampaignModal from "../Modals/campaign/PurchaseSmsCampaignModal.vue";
import SmsUtils from "../../mixins/helpers/SmsUtils.js";
import ClientTypeEnum from "../../mixins/enums/ClientTypeEnum.js";
import ShowErrors from "../errors/ShowErrors.vue";
import ClientLoyaltyLevelEnum from "../../mixins/enums/ClientLoyaltyLevelEnum.js";
import VueMultiselect from "vue-multiselect";
import CheckboxInputComponent from "../forms/CheckboxInputComponent.vue";
import ToggleInput from "../forms/ToggleInput.vue";

export default {
    data() {
        return {
            fr,
            loading: false,
            smsEnabled: this.campaign.type === "sms",
            showInternationalSmsCostModal: false,
            showPurchaseEmailsCampaignModal: false,
            showPurchaseSmsCampaignModal: false,
            campaignTitle: null,
            advanceFilters:
                this.campaign.at_least_one_resa ||
                this.campaign.at_least_one_past_resa ||
                this.campaign.at_least_one_future_resa ||
                this.campaign.at_least_one_resa_in_restaurant ||
                this.campaign.send_to_foreign_numbers == 1,
            willBeSendAtDate: this.campaign.will_be_send_at != null ? new Date(this.campaign.will_be_send_at.split(" ")[0]) : new Date(),
            willBeSendAtHour: {
                HH: this.campaign.will_be_send_at != null ? this.campaign.will_be_send_at.split(" ")[1].split(":")[0] : "10",
                mm: this.campaign.will_be_send_at != null ? this.campaign.will_be_send_at.split(" ")[1].split(":")[1] : "00",
            },
        };
    },
    mixins: [SmsUtils, ClientTypeEnum, ClientLoyaltyLevelEnum],
    props: {
        is_paying_emails_campaign: {
            default: false,
        },
        step: {
            type: Number,
            required: true,
        },
        campaign: {
            type: Object,
            required: true,
        },
        clients: {
            type: Object,
            required: true,
        },
        credits: {
            type: Object,
            required: true,
        },
        errors: {
            default: null,
        },
    },
    computed: {
        restaurantName() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurantId)?.name;
        },
        hasCheckedAllFilters: {
            get() {
                return (
                    this.campaign.include_vip &&
                    this.campaign.include_risky &&
                    this.campaign.client_types.length === this.availableClientTypes.length &&
                    this.campaign.loyalty_levels.length === 0 &&
                    !this.campaign.at_least_one_past_resa &&
                    !this.campaign.at_least_one_future_resa &&
                    !this.campaign.at_least_one_resa_in_restaurant
                );
            },
            set(newVal) {
                if (newVal) {
                    this.campaign.include_vip = true;
                    this.campaign.include_risky = true;
                    this.campaign.client_types = this.availableClientTypes.slice();
                    this.campaign.loyalty_levels = [];
                    this.campaign.at_least_one_past_resa = false;
                    this.campaign.at_least_one_future_resa = false;
                    this.campaign.at_least_one_resa_in_restaurant = false;
                } else {
                    this.campaign.include_vip = false;
                    this.campaign.include_risky = false;
                    this.campaign.client_types = [];
                }
            },
        },
        availableClientTypes() {
            return Object.keys(this.clients.types).sort((a, b) => (a === "no_status" ? 1 : -1));
        },
        availableLoyaltyLevels() {
            return [{ value: null, label: this.$tl("labels.none") }].concat(
                this.ALL_CLIENT_LOYALTIES.map((loyalty) => ({ value: loyalty.value, label: this.getClientLoyaltyLevelLabel(loyalty.value) }))
            );
        },
        selectedLoyaltyLevels: {
            get() {
                return this.availableLoyaltyLevels.filter((availableloyaltyLevel) =>
                    this.campaign.loyalty_levels.some((loyaltyLevel) => loyaltyLevel === availableloyaltyLevel.value)
                );
            },
            set(newVal) {
                this.campaign.loyalty_levels = this.arrayPluck("value", newVal);
            },
        },
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_buy_sms() {
            return this.rights.includes("default.sms.update");
        },
        restaurantId() {
            return this.$route.params.restaurant_id;
        },
        doesSmsContainEmojis() {
            return this.emojisRegex.test(this.campaign.content);
        },
        disabledDates() {
            const today = new Date();
            const disabledDates = {
                to: today,
            };

            today.setDate(today.getDate() - 1);

            if (this.campaign.type !== "sms") {
                return disabledDates;
            }

            disabledDates.days = [0];

            return disabledDates;
        },
        hourRange() {
            return this.campaign.type === "sms" ? [[8, 21]] : [[0, 23]];
        },
        sms_emitter_name_count() {
            return typeof this.campaign.sms_expeditor === "string"
                ? this.maxEmitterLength - this.campaign.sms_expeditor.length
                : this.maxEmitterLength;
        },
        sms_message_count() {
            return typeof this.campaign.content === "string" ? this.getSmsLength(this.campaign.content) : 0;
        },
        imgPostUri() {
            return (
                `/api/campaigns/restaurants/${this.restaurantId}/campaigns/imgs/` +
                (this.campaign.img_path ? this.fileNameOnly(this.campaign.img_path) : "none") +
                "/" +
                (this.campaign.id !== null ? this.campaign.id : "none")
            );
        },
    },
    methods: {
        addToMessage(str) {
            this.campaign.content += str;
            this.$refs.txtEmailBody.focus();
        },
        showPurchaseSmsCampaignModalFct() {
            if (!confirm("Une fois votre campagne payée, vous ne pourrez plus la modifier. Êtes vous sûr de vouloir continuer ?")) return;
            this.$emit("purchaseSmsCampaign");
        },
        emailsCampaignPackPaid(e) {
            if (e.hasPaidWithCard) this.credits.nb_campaign_emails_remaining += 10000;
            else this.$emit("setIsPayingEmailsCampaign", true);
            this.showPurchaseEmailsCampaignModal = false;
        },
        smsCampaignPaid(e) {
            if (e.hasPaidWithCard) this.campaign.paid_at = moment().format("Y-MM-DD HH:mm");
            else this.campaign.is_paying = true;
            this.showPurchaseSmsCampaignModal = false;
        },
        getDate(date) {
            const toTwoDigits = (num) => (num < 10 ? "0" + num : num);
            let year = date.getFullYear();
            let month = toTwoDigits(date.getMonth() + 1);
            let day = toTwoDigits(date.getDate());
            return `${year}-${month}-${day}`;
        },
        hasErrors(field) {
            if (this.errors !== null) return Object.keys(this.errors).includes(field);
            return false;
        },
        joinErrors(field) {
            return this.errors[field].join("<br />");
        },
        dateOrHourChanged() {
            this.campaign.will_be_send_at =
                this.campaign.save_type === "prog"
                    ? this.getDate(this.willBeSendAtDate) + " " + this.willBeSendAtHour.HH + ":" + this.willBeSendAtHour.mm
                    : null;
        },
        checkAllFilters() {
            this.hasCheckedAllFilters = true;
        },
    },
    components: {
        ToggleInput,
        CheckboxInputComponent,
        InternationalSmsCostModal,
        ImageComponent,
        VueTimepicker,
        Datepicker,
        PurchaseEmailsCampaignPackModal,
        PurchaseSmsCampaignModal,
        LoaderComponent,
        ShowErrors,
        VueMultiselect,
    },
    watch: {
        "campaign.save_type": function (newVal) {
            if (newVal === "prog") this.dateOrHourChanged();
            else this.campaign.will_be_send_at = null;
        },
        "campaign.sms_expeditor": function (newVal, oldVal) {
            if (!oldVal || !newVal || newVal.length < oldVal.length) return;
            if (newVal.length > 0 && !newVal.replace(oldVal, "").match(/^[a-z]+$/i)) this.campaign.sms_expeditor = oldVal;
        },
        willBeSendAtDate() {
            this.dateOrHourChanged();
        },
        smsEnabled(newVal) {
            this.campaign.type = newVal ? "sms" : "email";
        },
    },
};
</script>

<style>
.positition-relative {
    position: relative !important;
}
button.btn:disabled {
    cursor: default;
}
</style>
