var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("LoaderComponent")
    : _c(
        "div",
        [
          _vm.error
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v("\n        " + _vm._s(_vm.error) + "\n    "),
              ])
            : _vm.reservation
            ? _c("div", { staticClass: "div-profil" }, [
                _vm.reservation.immutable
                  ? _c("div", { staticClass: "p-4" }, [
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm.$tl(
                              "infos.booking.reservations.immutable",
                              undefined,
                              { theme: _vm.themeUppercased }
                            )
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row m-0" }, [
                  _c("div", { staticClass: "col-md-7 mb-3" }, [
                    _c(
                      "div",
                      { staticClass: "border-light b-radius-20 p-4" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$tl("labels.booking.reservations.date")
                                ) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6 value" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.displayDate(
                                    _vm.reservation.reservation_datetime
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "sepa" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$tl("labels.booking.reservations.hour")
                                ) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6 value" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.displayDate(
                                    _vm.setHourOnDateTime(
                                      _vm.reservation.slot.hour_start
                                    ),
                                    _vm.TIME_SIMPLE
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.reservation.rotation_enabled
                          ? _c("div", { staticClass: "sepa" })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.reservation.rotation_enabled
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-6" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.reservations.duration"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-6 value" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.reservation.duration.substr(0, 5)
                                    ) +
                                    "\n                        "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "sepa" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$tl("labels.booking.reservations.nbPers")
                                ) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6 value" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.reservation.nb_pers) +
                                "\n                        "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "sepa" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.reservations.nbChildren"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6 value" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.reservation.nb_children) +
                                "\n                        "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "sepa" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.reservations.table",
                                    _vm.restaurant_id
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-6 value" },
                            [
                              _vm.reservation.tables.data.length > 0
                                ? [
                                    _vm._l(
                                      _vm.reservation.tables.data,
                                      function (table, index) {
                                        return [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(table.name) +
                                              _vm._s(
                                                index + 1 <
                                                  _vm.reservation.tables.data
                                                    .length
                                                  ? " - "
                                                  : ""
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      }
                                    ),
                                  ]
                                : _vm.reservation.num_table &&
                                  _vm.reservation.num_table !== ""
                                ? [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.reservation.num_table) +
                                        "\n                            "
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.reservations.noTable",
                                            _vm.restaurant_id
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.isFeatRoomNumbersEnable || _vm.isHotelModeEnabled
                          ? [
                              _c("div", { staticClass: "sepa" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.reservations.roomNumber"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-6 value" },
                                  [
                                    _vm.reservation.is_hotel_client
                                      ? _c("hotel-client-icon")
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.reservation.room_number) +
                                        "\n                            "
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.reservation.prefered_room_name
                          ? [
                              _c("div", { staticClass: "sepa" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.reservations.roomPreference"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-6 value" }, [
                                  _vm._v(
                                    _vm._s(_vm.reservation.prefered_room_name)
                                  ),
                                ]),
                              ]),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "sepa" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.reservations.giftVoucher.title"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6" }, [
                            _vm.$_.isEmpty(_vm.reservation.gift)
                              ? _c("span", { staticClass: "value" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.reservations.giftVoucher.empty"
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.reservation.gv_sale_id !== null
                              ? _c("span", { staticClass: "value" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "value",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showShowGiftVoucherModal = true
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.reservation.gift))]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.reservation.gv_sale_id === null &&
                            _vm.reservation.gv_validity.valid === false
                              ? _c(
                                  "span",
                                  { staticClass: "value text-danger" },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.reservation.gift)
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      {
                                        staticClass: "text-danger pointer",
                                        staticStyle: {
                                          "text-decoration": "underline",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.showReservation =
                                              _vm.reservation.gv_validity.reservation_id
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.booking.reservations.giftVoucher.alreadyUsed"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm.reservation.gv_sale_id === null &&
                                _vm.reservation.gift != "" &&
                                _vm.reservation.gift != null
                              ? _c("span", { staticClass: "value" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.reservation.gift) +
                                      "\n                            "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "sepa" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mb-1" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.reservations.publicComment"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("blockquote", [
                              _vm.$_.isEmpty(_vm.reservation.comment)
                                ? _c("p", { staticClass: "value" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.reservations.noPublicComment"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ])
                                : _c("p", {
                                    staticClass: "value",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.formattedComment(
                                          _vm.reservation.comment
                                        )
                                      ),
                                    },
                                  }),
                            ]),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-5 mb-3" },
                    [
                      _vm.isClientLoading
                        ? _c("LoaderComponent")
                        : [
                            _vm.hasClient
                              ? _c("client-card", {
                                  attrs: {
                                    client: _vm.reservation.client,
                                    "module-enum": _vm.MODULE_TYPE_BOOKING,
                                    "is-change-in-progress":
                                      _vm.isAssociatingInProgress,
                                  },
                                  on: {
                                    "cancel-in-progress": _vm.emptyClient,
                                    "update-in-progress":
                                      _vm.associateClientBack,
                                  },
                                })
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-center align-items-center h-100",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-success btn-circle",
                                        attrs: {
                                          disabled: _vm.reservation.immutable,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.canOpenSearchClientModal = true
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.$t(
                                                "labels.form.actions.associate.client"
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                          ],
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row m-0" }, [
                  _vm.reservation.restaurant_comment &&
                  _vm.reservation.restaurant_comment !== ""
                    ? _c("div", { staticClass: "col-12 mb-3" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4" },
                          [
                            _c("label", { staticClass: "mb-1" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.reservations.privateComment"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", {
                                staticClass: "col-12",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.formattedComment(
                                      _vm.reservation.restaurant_comment
                                    )
                                  ),
                                },
                              }),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row m-0" }, [
                  _vm.reservation.menus &&
                  _vm.reservation.menus.data &&
                  _vm.reservation.menus.data.length > 0
                    ? _c("div", { staticClass: "col-12 mb-3" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4" },
                          _vm._l(_vm.reservation.menus.data, function (m) {
                            return _c(
                              "div",
                              { staticClass: "mb-2" },
                              [
                                _c("div", { staticClass: "row text-left" }, [
                                  _c("div", { staticClass: "col-7" }, [
                                    _c("label", { staticClass: "mb-0" }, [
                                      _vm._v(
                                        _vm._s(m.name) +
                                          " - " +
                                          _vm._s(
                                            _vm.formatCurrency(
                                              m.price,
                                              _vm.getRestaurantCurrency(
                                                _vm.restaurant_id_
                                              )
                                            )
                                          )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      { staticClass: "d-block text-muted" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(m.description) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-5" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(m.nb) +
                                        "\n                            "
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm._l(m.options.data, function (opt) {
                                  return _c(
                                    "div",
                                    { staticClass: "row text-left" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-list-item col-6 offset-1",
                                        },
                                        [
                                          _c("label", { staticClass: "mb-0" }, [
                                            _vm._v(
                                              _vm._s(opt.name) +
                                                " - " +
                                                _vm._s(
                                                  _vm.formatCurrency(
                                                    opt.price,
                                                    _vm.getRestaurantCurrency(
                                                      _vm.restaurant_id_
                                                    )
                                                  )
                                                )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "small",
                                            {
                                              staticClass: "d-block text-muted",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(opt.description) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-5" }, [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(opt.nb) +
                                            "\n                            "
                                        ),
                                      ]),
                                    ]
                                  )
                                }),
                              ],
                              2
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row m-0" }, [
                  _vm.reservation.general_options &&
                  _vm.reservation.general_options.data &&
                  _vm.reservation.general_options.data.length > 0
                    ? _c("div", { staticClass: "col-12 mb-3" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4" },
                          _vm._l(
                            _vm.reservation.general_options.data,
                            function (o) {
                              return _c("div", [
                                _c("div", { staticClass: "row text-left" }, [
                                  _c("div", { staticClass: "col-7" }, [
                                    _c("label", { staticClass: "mb-0" }, [
                                      _vm._v(
                                        _vm._s(o.name) +
                                          " - " +
                                          _vm._s(
                                            _vm.formatCurrency(
                                              o.price,
                                              _vm.getRestaurantCurrency(
                                                _vm.restaurant_id_
                                              )
                                            )
                                          )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      { staticClass: "d-block text-muted" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(o.description) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-5" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(o.nb) +
                                        "\n                            "
                                    ),
                                  ]),
                                ]),
                              ])
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row m-0" }, [
                  _c("div", { staticClass: "col-12 mb-3" }, [
                    _c(
                      "div",
                      { staticClass: "border-light b-radius-20 p-4" },
                      [
                        _vm.reservation.payment
                          ? [
                              _vm.reservation.payment.option_bank ===
                              _vm.OPTION_BANK_PAYMENT.value
                                ? _c("div", [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.reservations.payment.showResa.title"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                    _vm.reservation.payment.status ===
                                    _vm.PAYMENT_STATUS_CAPTURED.value
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "labels.booking.reservations.payment.showResa.captured",
                                                  {
                                                    amount: _vm.formatCurrency(
                                                      _vm.reservation.payment
                                                        .amount_captured / 100
                                                    ),
                                                  }
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ])
                                      : _vm.reservation.payment.status ===
                                        _vm.PAYMENT_STATUS_CAPTURABLE.value
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "labels.booking.reservations.payment.showResa.capturable",
                                                  {
                                                    amount: _vm.formatCurrency(
                                                      _vm.reservation.payment
                                                        .stripe_intent_amount /
                                                        100
                                                    ),
                                                  }
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ])
                                      : _vm.reservation.payment.status ===
                                        _vm.PAYMENT_STATUS_REFUNDED.value
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "labels.booking.reservations.payment.showResa.refunded",
                                                  {
                                                    amount: _vm.formatCurrency(
                                                      _vm.reservation.payment
                                                        .stripe_intent_amount /
                                                        100
                                                    ),
                                                    date: _vm.displayDate(
                                                      _vm.reservation.payment
                                                        .refunded_at
                                                    ),
                                                  }
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ])
                                      : _vm.reservation.payment.status ===
                                        _vm.PAYMENT_STATUS_PARTIALLY_REFUNDED
                                          .value
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "labels.booking.reservations.payment.showResa.refundedPartially",
                                                  {
                                                    total_amount:
                                                      _vm.formatCurrency(
                                                        _vm.reservation.payment
                                                          .stripe_intent_amount /
                                                          100
                                                      ),
                                                    amount_refunded:
                                                      _vm.formatCurrency(
                                                        _vm.reservation.payment
                                                          .amount_refunded / 100
                                                      ),
                                                    date: _vm.displayDate(
                                                      _vm.reservation.payment
                                                        .refunded_at
                                                    ),
                                                  }
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ])
                                      : _vm.reservation.payment.status ===
                                        _vm.PAYMENT_STATUS_CANCELED.value
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.reservations.payment.showResa.canceled"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ])
                                      : _vm.reservation.payment.status ===
                                        _vm.PAYMENT_STATUS_PENDING.value
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "labels.booking.reservations.payment.showResa.pending",
                                                  {
                                                    amount: _vm.formatCurrency(
                                                      _vm.reservation.payment
                                                        .stripe_intent_amount /
                                                        100
                                                    ),
                                                  }
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ])
                                      : _vm.reservation.payment.status ===
                                        _vm.PAYMENT_STATUS_EXPIRED.value
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.reservations.payment.showResa.expired"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm.reservation.payment.option_bank ===
                                  _vm.OPTION_BANK_FOOTPRINT.value
                                ? _c("div", [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.reservations.footprint.showResa.title"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                    _vm.reservation.status === "canceled" ||
                                    _vm.reservation.status === "refused"
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.reservations.footprint.showResa.noOne"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ])
                                      : _vm.reservation.payment.status ===
                                        _vm.PAYMENT_STATUS_PENDING.value
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.reservations.footprint.showResa.pending"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ])
                                      : _vm.reservation.payment.status ===
                                          _vm.PAYMENT_STATUS_CAPTURABLE.value ||
                                        _vm.reservation.payment.status ===
                                          _vm.PAYMENT_STATUS_CAPTURED.value
                                      ? _c("div", [
                                          _vm.reservation.status === "noshow"
                                            ? _c("div", [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "labels.booking.reservations.footprint.showResa.captured",
                                                        {
                                                          amount:
                                                            _vm.formatCurrency(
                                                              _vm.reservation
                                                                .payment
                                                                .amount_captured /
                                                                100
                                                            ),
                                                        }
                                                      )
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ])
                                            : _vm.reservation.status === "over"
                                            ? _c("div", [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.booking.reservations.footprint.showResa.over"
                                                      )
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ])
                                            : _c("div", [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.booking.reservations.footprint.showResa.capturable",
                                                        _vm.restaurant_id,
                                                        {
                                                          amount:
                                                            _vm.formatCurrency(
                                                              _vm.reservation
                                                                .amount_if_noshow /
                                                                100
                                                            ),
                                                        }
                                                      )
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ]),
                                        ])
                                      : _vm.reservation.payment.status ===
                                        _vm.PAYMENT_STATUS_EXPIRED.value
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.reservations.footprint.showResa.expired"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ]
                          : _c("div", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.reservations.noFootprintNorPayment"
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]),
                      ],
                      2
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row m-0" }, [
                  _c("div", { staticClass: "col-12 mb-3" }, [
                    _c(
                      "div",
                      { staticClass: "border-light b-radius-20 p-4" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "row",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                _vm.showLog = !_vm.showLog
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "col-12" }, [
                              _c("strong", { staticClass: "mb-0" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.reservations.logs.title"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.showLog
                          ? [
                              _c("ShowReservationLogsList", {
                                attrs: { reservation: _vm.reservation },
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row m-0" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 text-right pr-4" },
                    [
                      _c("div", [
                        _c("small", [
                          _vm._v(
                            _vm._s(
                              _vm.getReservationIssuerLabel(
                                _vm.reservation.created_from,
                                _vm.reservation.provider_name,
                                _vm.reservation.created_at,
                                _vm.reservation.user
                              )
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("ShowReservationMailLogsList", {
                        attrs: { reservation: _vm.reservation },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showShowGiftVoucherModal
            ? _c("show-gift-voucher-modal", {
                attrs: { sale: _vm.reservation.gv_sale },
                on: {
                  close: function ($event) {
                    _vm.showShowGiftVoucherModal = false
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showReservation !== false
            ? _c("show-reservation-modal", {
                attrs: {
                  reservation_id: _vm.showReservation,
                  restaurant_id: _vm.restaurant_id_,
                },
                on: {
                  close: function ($event) {
                    _vm.showReservation = false
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.canOpenSearchClientModal
            ? _c("wrapper-search-client-modal", {
                on: {
                  close: function ($event) {
                    _vm.canOpenSearchClientModal = false
                  },
                  "selected-client": _vm.associateClient,
                },
              })
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }