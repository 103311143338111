var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : !_vm.website_enabled
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("Votre site web n'est pas activé"),
          ])
        : [
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                  },
                  [
                    _c("h5", { staticClass: "title mt-2" }, [
                      _vm._v("Configuration des modules connectés"),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12 mb-3" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-5" }, [
                      _c("label", [
                        _c("strong", [_vm._v('Module "Réservation"')]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-7" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-inline-block align-middle lead-switch",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.website_settings.module_booking_enabled,
                                expression:
                                  "website_settings.module_booking_enabled",
                              },
                            ],
                            staticClass: "switch align-self-center is-rounded",
                            attrs: {
                              type: "checkbox",
                              disabled:
                                !_vm.has_right_to_update_customization ||
                                !_vm.website_settings.has_booking_module,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.website_settings.module_booking_enabled
                              )
                                ? _vm._i(
                                    _vm.website_settings.module_booking_enabled,
                                    null
                                  ) > -1
                                : _vm.website_settings.module_booking_enabled,
                            },
                            on: {
                              change: function ($event) {
                                var $$a =
                                    _vm.website_settings.module_booking_enabled,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.website_settings,
                                        "module_booking_enabled",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.website_settings,
                                        "module_booking_enabled",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.website_settings,
                                    "module_booking_enabled",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.getTooltip(
                                  "Activer / désactiver le widget de réservation"
                                ),
                                expression:
                                  "getTooltip('Activer / désactiver le widget de réservation')",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                _vm.website_settings.module_booking_enabled =
                                  _vm.website_settings.has_booking_module &&
                                  _vm.has_right_to_update_customization
                                    ? !_vm.website_settings
                                        .module_booking_enabled
                                    : _vm.website_settings
                                        .module_booking_enabled
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 mb-3" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col-md-5" }, [
                      _c("label", [
                        _c("strong", [_vm._v('Module "Vente en ligne"')]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-7" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-inline-block align-middle lead-switch",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.website_settings
                                    .module_click_and_collect_enabled,
                                expression:
                                  "website_settings.module_click_and_collect_enabled",
                              },
                            ],
                            staticClass: "switch align-self-center is-rounded",
                            attrs: {
                              type: "checkbox",
                              disabled:
                                !_vm.has_right_to_update_customization ||
                                !_vm.website_settings
                                  .has_click_and_collect_module ||
                                !_vm.website_settings
                                  .has_click_and_collect_api_key,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.website_settings
                                  .module_click_and_collect_enabled
                              )
                                ? _vm._i(
                                    _vm.website_settings
                                      .module_click_and_collect_enabled,
                                    null
                                  ) > -1
                                : _vm.website_settings
                                    .module_click_and_collect_enabled,
                            },
                            on: {
                              change: function ($event) {
                                var $$a =
                                    _vm.website_settings
                                      .module_click_and_collect_enabled,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.website_settings,
                                        "module_click_and_collect_enabled",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.website_settings,
                                        "module_click_and_collect_enabled",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.website_settings,
                                    "module_click_and_collect_enabled",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.getTooltip(
                                  "Activer / désactiver le widget de vente en ligne"
                                ),
                                expression:
                                  "getTooltip('Activer / désactiver le widget de vente en ligne')",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                _vm.website_settings.module_click_and_collect_enabled =
                                  _vm.website_settings
                                    .has_click_and_collect_module &&
                                  _vm.website_settings
                                    .has_click_and_collect_api_key &&
                                  _vm.has_right_to_update_customization
                                    ? !_vm.website_settings
                                        .module_click_and_collect_enabled
                                    : _vm.website_settings
                                        .module_click_and_collect_enabled
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.website_settings.has_click_and_collect_module &&
                      !_vm.website_settings.has_click_and_collect_api_key
                        ? _c(
                            "small",
                            { staticClass: "d-block text-danger mt-1" },
                            [
                              _vm._v(
                                "\n                                Vous devez au préalable\n                                "
                              ),
                              _vm.has_right_to_update_cc_config
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        target: "_blank",
                                        to: {
                                          name: "click_and_collect.restaurants.configuration.widget",
                                          params: {
                                            restaurant_id: _vm.restaurant_id,
                                          },
                                        },
                                      },
                                    },
                                    [_vm._v("générer une clé d'api")]
                                  )
                                : _c("span", [_vm._v("générer une clé d'api")]),
                              _vm._v(
                                " afin de pouvoir activer le widget sur votre site web.\n                            "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.website_settings.module_click_and_collect_enabled
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-5" }, [
                          _c(
                            "label",
                            { attrs: { for: "txt-menupdf3-title" } },
                            [
                              _vm._v("Texte sur le bouton "),
                              _c("small", { staticClass: "text-danger" }, [
                                _vm._v("*"),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-7" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.website_settings.cc_btn_label,
                                  expression: "website_settings.cc_btn_label",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                disabled:
                                  !_vm.has_right_to_update_customization,
                                type: "text",
                                id: "txt-menupdf3-title",
                                maxlength: "40",
                              },
                              domProps: {
                                value: _vm.website_settings.cc_btn_label,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.website_settings,
                                    "cc_btn_label",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("ShowErrors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors,
                                errorKey: "cc_btn_label",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-5" }, [
                      _c("label", [
                        _c("strong", [_vm._v('Module "Bons Cadeaux"')]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-7" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-inline-block align-middle lead-switch",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.website_settings
                                    .module_gift_vouchers_enabled,
                                expression:
                                  "website_settings.module_gift_vouchers_enabled",
                              },
                            ],
                            staticClass: "switch align-self-center is-rounded",
                            attrs: {
                              type: "checkbox",
                              disabled:
                                !_vm.has_right_to_update_customization ||
                                !_vm.website_settings
                                  .has_gift_vouchers_module ||
                                !_vm.website_settings.has_gift_vouchers_api_key,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.website_settings
                                  .module_gift_vouchers_enabled
                              )
                                ? _vm._i(
                                    _vm.website_settings
                                      .module_gift_vouchers_enabled,
                                    null
                                  ) > -1
                                : _vm.website_settings
                                    .module_gift_vouchers_enabled,
                            },
                            on: {
                              change: function ($event) {
                                var $$a =
                                    _vm.website_settings
                                      .module_gift_vouchers_enabled,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.website_settings,
                                        "module_gift_vouchers_enabled",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.website_settings,
                                        "module_gift_vouchers_enabled",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.website_settings,
                                    "module_gift_vouchers_enabled",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.getTooltip(
                                  "Activer / désactiver le widget des bons cadeaux"
                                ),
                                expression:
                                  "getTooltip('Activer / désactiver le widget des bons cadeaux')",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                _vm.website_settings.module_gift_vouchers_enabled =
                                  _vm.website_settings
                                    .has_gift_vouchers_module &&
                                  _vm.website_settings
                                    .has_gift_vouchers_api_key &&
                                  _vm.has_right_to_update_customization
                                    ? !_vm.website_settings
                                        .module_gift_vouchers_enabled
                                    : _vm.website_settings
                                        .module_gift_vouchers_enabled
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.website_settings.has_gift_vouchers_module &&
                      !_vm.website_settings.has_gift_vouchers_api_key
                        ? _c(
                            "small",
                            { staticClass: "d-block text-danger mt-1" },
                            [
                              _vm._v(
                                "\n                                Vous devez au préalable\n                                "
                              ),
                              _vm.has_right_to_update_gv_config
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        target: "_blank",
                                        to: {
                                          name: "gift_vouchers.restaurants.configuration.widget",
                                          params: {
                                            restaurant_id: _vm.restaurant_id,
                                          },
                                        },
                                      },
                                    },
                                    [_vm._v("générer une clé d'api")]
                                  )
                                : _c("span", [_vm._v("générer une clé d'api")]),
                              _vm._v(
                                " afin de pouvoir activer le widget sur votre site web.\n                            "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0 mt-3" }, [
              _c("div", { staticClass: "col-12 d-inline-block" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success btn-circle mr-1",
                    attrs: {
                      type: "button",
                      disabled: !_vm.has_right_to_update_customization,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.postForm()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Enregistrer\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    'Pour rendre vos modifications visibles sur votre site internet, pensez à "'
                  ),
                  _c("strong", [_vm._v("Enregistrer et publier")]),
                  _vm._v(
                    '" en\n                    cliquant sur le bouton dans la navigation.'
                  ),
                ]),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }