<template>
    <div>
        <div class="row mb-2">
            <div class="col-12">
                <strong class="pointer" @click="show = !show">CGV / CGU / CP Acceptées</strong>
            </div>
        </div>
        <div class="row" v-if="show">
            <div class="col-12">
                <table v-if="cgvs && cgvs.length > 0" class="table table-sm table-striped border-bottom">
                    <thead>
                        <th>Type</th>
                        <th>Email</th>
                        <th>Adresse IP</th>
                        <th>Date d'acceptation</th>
                        <th>Date de réception du mail</th>
                        <th>Date d'ouverture du mail</th>
                    </thead>
                    <tbody>
                        <tr v-for="cgv in cgvs" :key="cgv.id">
                            <td v-html="getFormattedType(cgv)"></td>
                            <td>{{ cgv.email_address || "Aucun mail n'a été envoyé" }}</td>
                            <td>{{ cgv.ip_address || "--" }}</td>
                            <td>{{ moment(cgv.created_at).format("DD/MM/Y") }}</td>
                            <td>
                                {{ cgv.email_delivered_at ? moment(cgv.email_delivered_at).format("DD/MM/Y") : "--" }}
                            </td>
                            <td>{{ cgv.email_opened_at ? moment(cgv.email_opened_at).format("DD/MM/Y") : "--" }}</td>
                        </tr>
                    </tbody>
                </table>
                <div v-else>
                    <p class="text-muted">Cet utilisateur n'a rien accepté</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import AcceptedCgvTypesEnum from "../../mixins/enums/AcceptedCgvTypesEnum";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum";

export default {
    data() {
        return {
            show: false,
            moment,
        };
    },
    props: {
        cgvs: {
            required: true,
            type: Array,
        },
    },
    mixins: [AcceptedCgvTypesEnum, ModuleTypesEnum],
    methods: {
        getFormattedType(cgv) {
            if (cgv.type === this.ACCEPTED_CGV_TYPE_MODULES.value) {
                let formatted = [];
                cgv.modules.forEach((module) => {
                    formatted.push(`${this.ACCEPTED_CGV_TYPE_MODULES.label} - ${this.getModuleTypeLabel(module)}`);
                });
                return formatted.join("<br/>");
            }
            return this.getAcceptedCgvTypeLabel(cgv.type);
        },
    },
};
</script>
