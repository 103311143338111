<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Importer des numéros de chambre</h5>
        <div slot="body">
            <LoaderComponent
                v-if="loading || loadingImport"
                :message="loadingImport ? 'Import en cours ...' : undefined" />
            <div class="row" v-show="!loading && !loadingImport">
                <div class="col-12">
                    <p>
                        Les formats de fichiers acceptés sont {{ formattedAllowedExtensions }}.<br />
                        Pour les fichiers ".csv", le delimiteur doit être le point-virgule (";") et posséder le format
                        UTF-8.<br />
                        Attention : le fichier importé <strong>ne doit pas comporter d'entête</strong>.
                    </p>
                    <div>
                        <a
                            v-for="ext in allowedExtensions"
                            :key="ext"
                            :href="`/assets/room_numbers${ext}`"
                            :download="`room_numbers${ext}`"
                            class="d-block">
                            <small>Exemple au format "{{ ext }}"</small>
                        </a>
                    </div>
                    <input id="fileInput" @change="fileInputChanged" type="file" :accept="allowedExtensions" />
                    <ShowErrors
                        class="alert alert-danger position-relative"
                        style="top: 0"
                        errClass="d-block"
                        :errors="errors"
                        errorKey="*" />
                </div>
            </div>
        </div>
        <div slot="footer" class="d-flex">
            <button class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="close">Fermer</button>
            <button
                class="btn btn-sm btn-success btn-circle ml-2"
                :class="{ disabled: btnDisabled }"
                :disabled="btnDisabled"
                @click="importFile">
                Importer
            </button>
        </div>
    </modal>
</template>

<script>
import LoaderComponent from "../../LoaderComponent.vue";
import ShowErrors from "../../errors/ShowErrors";

export default {
    data() {
        return {
            loading: false,
            loadingImport: false,
            file: undefined,
            errors: null,
        };
    },
    props: {
        allowedExtensions: {
            default: () => {
                return [".csv", ".xls"];
            },
        },
    },
    computed: {
        btnDisabled() {
            return this.loading || !this.file;
        },
        formattedAllowedExtensions() {
            let str = "";
            let i = 0;
            while (i < this.allowedExtensions.length) {
                str += `"${this.allowedExtensions[i]}"`;
                if (i < this.allowedExtensions.length - 2) str += ", ";
                else if (i < this.allowedExtensions.length - 1) str += " et ";
                else str += "";
                i++;
            }
            return str;
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        fileInputChanged(event) {
            if (event.target.files && event.target.files.length > 0) {
                this.file = event.target.files[0];
            }
        },
        importFile(e) {
            if (e) e.preventDefault();

            this.loadingImport = true;
            let formData = new FormData();
            formData.append("file", this.file);
            axios
                .post(`/api/restaurants/${this.restaurant_id}/widget/room_numbers/importFile`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    this.$emit("import-done");
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                })
                .catch((error) => {
                    this.loadingImport = false;
                    if (error.response && error.response.data && error.response.data.errors)
                        this.errors = error.response.data.errors;
                    if (!this.errors) this.errors = ["Une erreur est survenue lors de l'import"];
                });
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (!e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
    },
};
</script>
