<template>
    <div class="pb-5 pt-5">
        <div>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else>
                <div class="row m-0">
                    <div class="col-12">
                        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                            <h5 class="title mt-2">{{ $tl("labels.routes.booking.settings") }}</h5>
                        </div>
                    </div>
                </div>
                <div v-if="formErrors" class="alert alert-danger">
                    <p v-for="(error, index) in formErrors" :key="index">
                        {{ error }}
                    </p>
                </div>
                <LoaderComponent v-if="isWidgetLoading || isFormLoading" />
                <form v-else id="addWidget" @submit.prevent="updateWidget" method="post">
                    <automatic-export-component
                        v-model="currentWidget.automatic_export"
                        :module="MODULE_TYPE_BOOKING.value"
                        services-name="booking_services"
                        class="row m-0" />

                    <div class="row m-0">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4 mb-3">
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <strong>{{ $tl("labels.booking.widget.messages.title") }}</strong>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 col-10">
                                        <label>
                                            {{ $tl("labels.booking.widget.messages.general") }}
                                            <feather
                                                v-tooltip="getTooltipNoticeLexicon('notices.booking.widget.mainMessage', restaurant_id)"
                                                type="info"
                                                class="ml-1 pointer tooltip-infos" />
                                        </label>
                                    </div>
                                    <div class="col-md-7 col-2">
                                        <label class="container-box">
                                            <input
                                                :disabled="!has_right_to_update_widget"
                                                type="checkbox"
                                                v-model="currentWidget.widget_messages.cover_page_message_activated" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <template v-if="currentWidget.widget_messages.cover_page_message_activated">
                                    <div class="row mb-1">
                                        <div class="col-md-7 offset-md-5">
                                            <div>
                                                <textarea
                                                    :disabled="!has_right_to_update_widget"
                                                    maxlength="1500"
                                                    v-model="currentWidget.widget_messages.cover_page_message_content"
                                                    class="form-control"
                                                    :placeholder="$t('labels.langs.fr')"
                                                    rows="3">
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-7 offset-md-5">
                                            <div>
                                                <textarea
                                                    :disabled="!has_right_to_update_widget"
                                                    maxlength="1500"
                                                    v-model="currentWidget.widget_messages.cover_page_message_content_en"
                                                    class="form-control"
                                                    :placeholder="$t('labels.langs.en')"
                                                    rows="3">
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div class="row mb-1">
                                    <div class="col-md-5 col-10">
                                        <label>
                                            {{ $tl("labels.booking.widget.messages.slot") }}
                                            <feather
                                                v-tooltip="getTooltipNoticeLexicon('notices.booking.widget.slotMessage', restaurant_id)"
                                                type="info"
                                                class="ml-1 pointer tooltip-infos" />
                                        </label>
                                    </div>
                                    <div class="col-md-7 col-2">
                                        <label class="container-box">
                                            <input
                                                :disabled="!has_right_to_update_widget"
                                                type="checkbox"
                                                v-model="currentWidget.widget_messages.slot_message_activated"
                                                true-value="1" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <template v-if="currentWidget.widget_messages.slot_message_activated">
                                    <div class="row mb-1">
                                        <div class="col-md-7 offset-md-5">
                                            <textarea
                                                :disabled="!has_right_to_update_widget"
                                                maxlength="220"
                                                v-model="currentWidget.widget_messages.slot_message_content"
                                                :placeholder="$t('labels.langs.fr')"
                                                class="form-control"
                                                rows="3">
                                            </textarea>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-md-7 offset-md-5">
                                            <textarea
                                                :disabled="!has_right_to_update_widget"
                                                maxlength="220"
                                                v-model="currentWidget.widget_messages.slot_message_content_en"
                                                :placeholder="$t('labels.langs.en')"
                                                class="form-control"
                                                rows="3">
                                            </textarea>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4 mb-3">
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <strong>{{ $tl("labels.booking.widget.resaSettings.title") }}</strong>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-2">
                                        <label>{{ $tl("labels.booking.widget.resaSettings.nbPersMin") }} <small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <input
                                                    :disabled="!has_right_to_update_widget"
                                                    type="number"
                                                    v-model="currentWidget.min_pers"
                                                    class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-2">
                                        <label>{{ $tl("labels.booking.widget.resaSettings.nbPersMax") }} <small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <input
                                                    :disabled="!has_right_to_update_widget"
                                                    type="number"
                                                    v-model="currentWidget.max_pers"
                                                    class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-2">
                                        <label>{{ $tl("labels.booking.widget.resaSettings.enableChildren") }}</label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="row">
                                            <div class="col-1 pt-2">
                                                <label class="container-box">
                                                    <input
                                                        :disabled="!has_right_to_update_widget"
                                                        type="checkbox"
                                                        v-model="currentWidget.enable_children" />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div v-if="currentWidget.enable_children" class="col-md-4 pt-2">
                                                <label>{{ $tl("labels.booking.widget.resaSettings.maxPerResa") }}</label>
                                            </div>
                                            <div v-if="currentWidget.enable_children" class="col-md-7">
                                                <input
                                                    :disabled="!has_right_to_update_widget || currentWidget.unlimited_nb_children"
                                                    type="text"
                                                    v-model="currentWidget.max_children"
                                                    class="form-control d-inline-block"
                                                    :class="{ disabled: currentWidget.unlimited_nb_children }"
                                                    style="width: auto" />
                                                <label class="container-box ml-2">
                                                    <input
                                                        :disabled="!has_right_to_update_widget"
                                                        type="checkbox"
                                                        v-model="currentWidget.unlimited_nb_children" />
                                                    <span class="checkmark"></span>{{ $tl("labels.unlimited") }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-2">
                                        <label>
                                            {{ $tl("labels.booking.widget.resaSettings.openingPeriod") }}
                                            <small>*</small>
                                        </label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="row">
                                            <div class="col-md-3 col-6">
                                                <input
                                                    :disabled="!has_right_to_update_widget"
                                                    type="text"
                                                    v-model="openingPeriod.maxReservationHours"
                                                    class="form-control" />
                                            </div>
                                            <div class="col-md-9 col-6">
                                                <select
                                                    :disabled="!has_right_to_update_widget"
                                                    v-model="openingPeriod.maxReservationUnit"
                                                    class="custom-select">
                                                    <option value="hours">{{ $tl("labels.hour") }}</option>
                                                    <option value="days">{{ $tl("labels.day") }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="isFeatRoomNumbersEnable">
                                    <div class="col-md-5 pt-2">
                                        <label> {{ $tl("labels.booking.widget.roomNumbers.title") }} <small>*</small> </label>
                                    </div>
                                    <div class="col-md-7 mt-1">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <button
                                                    class="btn btn-success btn-circle btn-sm"
                                                    type="button"
                                                    @click="showImportRoomNumbersModal = true">
                                                    {{ $tl("labels.booking.widget.roomNumbers.edit") }}
                                                </button>
                                            </div>
                                            <div class="col-md-6">
                                                <button
                                                    class="btn btn-success btn-circle btn-sm"
                                                    type="button"
                                                    @click="showShowRoomNumbersModal = true">
                                                    {{ $tl("labels.booking.widget.roomNumbers.show") }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <setting-part-form-gtag v-model="currentWidget.gtag_id" />

                    <div class="row m-0" v-if="isReserveWithGoogleEnabled()">
                        <div class="col-12">
                            <google-reserve class="mb-3" :settings="currentWidget.googleReserveSettings" :restaurant-id="restaurant_id" for-widget />
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4 mb-3">
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <strong>{{ $tl("labels.booking.widget.resaOptions.title") }}</strong>
                                    </div>
                                </div>
                                <div v-if="!isFeatRoomNumbersEnable" class="row mb-2">
                                    <div class="col-md-5 col-10">
                                        <label>
                                            {{ $tl("labels.booking.widget.hotelMode") }}
                                            <feather
                                                v-tooltip="getTooltipNotice('notices.booking.widget.hotelMode')"
                                                type="info"
                                                class="ml-1 pointer tooltip-infos" />
                                            <show-new-badge-details badge_uuid="af5163fc-9b76-43f0-af01-75f9e97de030" />
                                        </label>
                                    </div>
                                    <div class="col-md-7 col-2">
                                        <label class="container-box">
                                            <input :disabled="!has_right_to_update_widget" type="checkbox" v-model="currentWidget.hotel_mode" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 col-10">
                                        <label>
                                            {{ $tl("labels.booking.widget.resaOptions.enableCompany") }}
                                        </label>
                                    </div>
                                    <div class="col-md-7 col-2">
                                        <label class="container-box">
                                            <input :disabled="!has_right_to_update_widget" type="checkbox" v-model="currentWidget.enable_society" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 col-10">
                                        <label>
                                            {{ $tl("labels.booking.widget.resaOptions.enableComment") }}
                                            <feather
                                                v-tooltip="getTooltipNotice('notices.booking.widget.commentField')"
                                                type="info"
                                                class="ml-1 pointer tooltip-infos" />
                                        </label>
                                    </div>
                                    <div class="col-md-7 col-2">
                                        <label class="container-box">
                                            <input :disabled="!has_right_to_update_widget" type="checkbox" v-model="currentWidget.comment_enabled" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <template v-if="currentWidget.comment_enabled">
                                    <div class="row mb-3">
                                        <div class="col-md-5 pt-2">
                                            <label>
                                                {{ $tl("labels.booking.widget.resaOptions.addCommentPlaceHolder") }}
                                                <feather
                                                    v-tooltip="getTooltipNotice('notices.booking.widget.commentInfo')"
                                                    type="info"
                                                    class="ml-1 pointer tooltip-infos" />
                                            </label>
                                        </div>
                                        <div class="col-md-7">
                                            <textarea
                                                v-model="currentWidget.comment_label"
                                                :placeholder="$t('labels.langs.fr')"
                                                class="form-control mb-1"
                                                rows="3"
                                                :class="{ disabled: !currentWidget.comment_enabled }"
                                                :disabled="!currentWidget.comment_enabled || !has_right_to_update_widget">
                                            </textarea>
                                            <textarea
                                                v-model="currentWidget.comment_label_en"
                                                :placeholder="$t('labels.langs.en')"
                                                class="form-control"
                                                rows="3"
                                                :class="{ disabled: !currentWidget.comment_enabled }"
                                                :disabled="!currentWidget.comment_enabled || !has_right_to_update_widget"></textarea>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5 col-10">
                                            <label>
                                                {{ $tl("labels.booking.widget.resaOptions.setCommentMandatory") }}
                                                <feather
                                                    v-tooltip="getTooltipNotice('notices.booking.widget.commentRequired')"
                                                    type="info"
                                                    class="ml-1 pointer tooltip-infos" />
                                            </label>
                                        </div>
                                        <div class="col-md-7 col-2">
                                            <label class="container-box">
                                                <input
                                                    type="checkbox"
                                                    v-model="currentWidget.comment_mandatory"
                                                    :class="{ disabled: !currentWidget.comment_enabled }"
                                                    :disabled="!currentWidget.comment_enabled || !has_right_to_update_widget" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <div class="row mb-2">
                                    <div class="col-md-5 col-10">
                                        <label class="mb-0">
                                            {{ $tl("labels.booking.widget.resaOptions.enableGiftVoucher") }}
                                            <feather
                                                v-tooltip="getTooltipNotice('notices.booking.widget.giftVoucherField')"
                                                type="info"
                                                class="ml-1 pointer tooltip-infos" />
                                        </label>
                                    </div>
                                    <div class="col-md-7 col-2">
                                        <label class="container-box">
                                            <input :disabled="!has_right_to_update_widget" type="checkbox" v-model="currentWidget.enable_gift" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div v-if="currentWidget.enable_gift" class="row mb-2">
                                    <div class="col-5 pt-2">
                                        <label>
                                            {{ $tl("labels.booking.widget.resaOptions.confirmResaGiftVoucher") }}
                                            <small>*</small>
                                        </label>
                                    </div>
                                    <div class="col-7 radio w-100 h-100 d-table pt-2">
                                        <div class="d-table-cell align-middle">
                                            <label class="container-box">
                                                <input
                                                    :disabled="!has_right_to_update_widget"
                                                    type="radio"
                                                    v-model="giftVoucherConfirmMethod"
                                                    value="manual" />
                                                <span class="checkmark"></span> {{ $tl("labels.manual") }}
                                            </label>
                                            <label class="container-box">
                                                <input
                                                    :disabled="!has_right_to_update_widget"
                                                    type="radio"
                                                    v-model="giftVoucherConfirmMethod"
                                                    value="auto" />
                                                <span class="checkmark"></span> {{ $tl("labels.auto") }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="currentWidget.enable_gift" class="row mb-2">
                                    <div class="col-md-5 col-10">
                                        <label class="mb-0">
                                            {{ $tl("labels.booking.widget.resaOptions.disableFootprintIfGiftVoucher") }}
                                        </label>
                                    </div>
                                    <div class="col-md-7 col-2">
                                        <label class="container-box">
                                            <input
                                                :disabled="!has_right_to_update_widget"
                                                type="checkbox"
                                                v-model="currentWidget.gift_voucher_footprint" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row m-0 mb-3">
                        <div class="col-12">
                            <option-bank-settings :is-editable="has_right_to_update_widget" :settings="currentWidget"></option-bank-settings>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4 mb-3">
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <strong>{{ $tl("labels.booking.widget.waitingList.title") }}</strong>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 col-10">
                                        <label>
                                            {{ $tl("labels.booking.widget.waitingList.enable") }}
                                            <feather
                                                v-tooltip="getTooltipNoticeLexicon('notices.booking.widget.waitingList', restaurant_id)"
                                                type="info"
                                                class="ml-1 pointer tooltip-infos" />
                                        </label>
                                    </div>
                                    <div class="col-md-7 col-2">
                                        <label class="container-box">
                                            <input :disabled="!has_right_to_update_widget" type="checkbox" v-model="currentWidget.enable_waitings" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <template v-if="currentWidget.enable_waitings">
                                    <div class="row mb-2">
                                        <div class="col-md-5 col-10">
                                            <label>
                                                {{ $tl("labels.booking.widget.waitingList.autoNotifTableAvailable") }}
                                                <feather
                                                    v-tooltip="getTooltipNoticeLexicon('notices.booking.widget.waitingListAutoNotif', restaurant_id)"
                                                    type="info"
                                                    class="ml-1 pointer tooltip-infos" />
                                            </label>
                                        </div>
                                        <div class="col-md-7 col-2">
                                            <label class="container-box">
                                                <input
                                                    type="checkbox"
                                                    v-model="currentWidget.enable_mail_auto"
                                                    :class="{ disabled: !currentWidget.enable_waitings }"
                                                    :disabled="!currentWidget.enable_waitings || !has_right_to_update_widget" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <template v-if="currentWidget.enable_waitings && currentWidget.enable_mail_auto">
                                        <div class="row mb-2">
                                            <div class="col-md-5 col-10">
                                                <label class="mb-0">
                                                    {{ $tl("labels.booking.widget.waitingList.priorityClientVIP") }}
                                                    <feather
                                                        v-tooltip="getTooltipNotice('notices.booking.widget.waitingListPriority')"
                                                        type="info"
                                                        class="ml-1 pointer tooltip-infos" />
                                                </label>
                                            </div>
                                            <div class="col-md-7 col-2">
                                                <label class="container-box">
                                                    <input
                                                        type="checkbox"
                                                        v-model="currentWidget.enable_waiting_list_priority_for_vip"
                                                        :class="{ disabled: !has_right_to_update_widget }"
                                                        :disabled="!has_right_to_update_widget" />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-5 col-10">
                                                <label class="mb-0">
                                                    {{ $tl("labels.booking.widget.waitingList.priorityClientLoyal") }}
                                                    <feather
                                                        v-tooltip="getTooltipNotice('notices.booking.widget.waitingListPriority')"
                                                        type="info"
                                                        class="ml-1 pointer tooltip-infos" />
                                                </label>
                                            </div>
                                            <div class="col-md-7 col-2">
                                                <label class="container-box">
                                                    <input
                                                        type="checkbox"
                                                        v-model="enableWaitingListPriorityFromLoyaltyLevel"
                                                        :class="{ disabled: !currentWidget.enable_waitings }"
                                                        :disabled="!currentWidget.enable_waitings || !has_right_to_update_widget" />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <div class="d-inline-block" v-if="enableWaitingListPriorityFromLoyaltyLevel">
                                                    {{ $tl("labels.from") }}
                                                    <select
                                                        class="d-inline-block custom-select ml-1 mb-0 w-auto"
                                                        v-model="currentWidget.enable_waiting_list_priority_from_loyalty_level">
                                                        <option v-for="level in ALL_CLIENT_LOYALTIES" :key="level.value" :value="level.value">
                                                            {{ getClientLoyaltyLevelLabel(level.value) }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>

                    <setting-part-form-display
                        v-model="currentWidget"
                        :is-seating-plan-available="hasFeatSeatingPlan"
                        :has-right-to-update-widget="has_right_to_update_widget" />

                    <div class="row m-0 mb-4">
                        <div class="col-12">
                            <input
                                :disabled="!has_right_to_update_widget"
                                type="submit"
                                name="submit"
                                :value="$t('labels.form.actions.save')"
                                class="btn btn-success btn-circle" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <ImportRoomNumbersModal
            v-if="showImportRoomNumbersModal"
            @close="showImportRoomNumbersModal = false"
            @import-done="showImportRoomNumbersModal = false" />
        <ShowRoomNumbersModal v-if="showShowRoomNumbersModal" @close="showShowRoomNumbersModal = false" />
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import ImportRoomNumbersModal from "../../../components/Modals/roomNumbers/ImportRoomNumbersModal";
import ShowRoomNumbersModal from "../../../components/Modals/roomNumbers/ShowRoomNumbersModal";
import AutomaticExportComponent from "./AutomaticExport/AutomaticExportComponent.vue";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";
import LexiconEnum from "../../../mixins/enums/LexiconEnum.js";
import ClientLoyaltyLevelEnum from "../../../mixins/enums/ClientLoyaltyLevelEnum.js";
import showNewBadgeDetails from "../../../components/NewBadges/showNewBadgeDetails.vue";
import SettingPartFormGtag from "../Settings/SettingForm/SettingPartFormGtag.vue";
import SettingPartFormDisplay from "../Settings/SettingForm/SettingPartFormDisplay.vue";
import googleReserve from "../../../components/services/googleReserve.vue";
import OptionBankSettings from "../../../components/widgets/OptionBankSettings.vue";

export default {
    data() {
        return {
            isFormLoading: false,
            error: null,
            formErrors: null,
            showImportRoomNumbersModal: false,
            showShowRoomNumbersModal: false,
            giftVoucherConfirmMethod: "manual",
            openingPeriod: {
                maxReservationUnit: "days",
                maxReservationHours: 0,
            },
            currentWidget: {
                hotel_mode: false,
                enable_children: false,
                enable_gift: false,
                enable_society: true,
                gift_voucher_confirm_method: false,
                gift_voucher_footprint: false,
                enable_waitings: true,
                enable_mail_auto: true,
                enable_waiting_list_priority_for_vip: false,
                enable_waiting_list_priority_from_loyalty_level: null,
                comment_label: null,
                comment_label_en: null,
                min_pers: 1,
                max_pers: 10,
                max_children: null,
                unlimited_nb_children: false,
                comment_mandatory: false,
                comment_enabled: true,
                max_reservation_hours: 0,
                can_display_memos: true,
                gtag_id: null,
                default_booking_view: "list",
                option_bank_short_delay: 1,
                widget_messages: {
                    cover_page_message_activated: false,
                    cover_page_message_content: "",
                    cover_page_message_content_en: "",
                    slot_message_activated: false,
                    slot_message_content: "",
                    slot_message_content_en: "",
                },
                automatic_export: {
                    is_enabled: false,
                    minutes: 30,
                    booking_services: [],
                    emails: [""],
                },
                googleReserveSettings: {
                    enabled: false,
                    texts: [],
                },
            },
        };
    },
    mixins: [ModuleTypesEnum, ClientLoyaltyLevelEnum, LexiconEnum],
    methods: {
        sanitizeAutomaticExportEmails() {
            if (this.currentWidget.automatic_export.emails.length > 1) {
                this.currentWidget.automatic_export.emails = this.currentWidget.automatic_export.emails.filter((email) => email !== "");
            }
        },
        initOpeningPeriod() {
            if (this.currentWidget.max_reservation_hours % 24 === 0) {
                this.openingPeriod.maxReservationHours = this.currentWidget.max_reservation_hours / 24;
                this.openingPeriod.maxReservationUnit = "days";
            } else {
                this.openingPeriod.maxReservationHours = this.currentWidget.max_reservation_hours;
                this.openingPeriod.maxReservationUnit = "hours";
            }
        },
        initWidget() {
            let widget = this.widget;

            if (this.widget.automatic_export === null) {
                const { automatic_export, ...newPayload } = this.widget;
                widget = newPayload;
                this.currentWidget.automatic_export = {
                    is_enabled: false,
                    minutes: 30,
                    booking_services: [],
                    emails: [""],
                };
            }

            this.currentWidget = Object.assign(this.currentWidget, widget);
            this.currentWidget.unlimited_nb_children = this.widget.max_children === 0;
            this.currentWidget.enable_gift = this.widget.enable_gift === 1;
            this.currentWidget.googleReserveSettings.enabled = this.widget.enable_google_reserve;
            this.currentWidget.googleReserveSettings.texts = this.widget.google_reserve_texts;
            this.giftVoucherConfirmMethod = this.currentWidget.gift_voucher_confirm_method ? "auto" : "manual";
            this.initOpeningPeriod();
        },
        updateWidget() {
            this.isFormLoading = true;
            this.formErrors = null;

            this.currentWidget.max_reservation_hours =
                this.openingPeriod.maxReservationUnit === "days"
                    ? this.openingPeriod.maxReservationHours * 24
                    : this.openingPeriod.maxReservationHours;
            this.currentWidget.gift_voucher_confirm_method = this.giftVoucherConfirmMethod === "auto";
            this.currentWidget.enable_google_reserve = this.currentWidget.googleReserveSettings.enabled;
            this.currentWidget.google_reserve_texts = this.currentWidget.googleReserveSettings.texts;

            this.sanitizeAutomaticExportEmails();

            this.httpPut(`/api/restaurants/${this.restaurant_id}/widget`, this.currentWidget, { handleReject: false })
                .then(() =>
                    this.$store.dispatch("widgets/getCurrentWidget", {
                        restaurant_id: this.restaurant_id,
                    })
                )
                .catch((error) => {
                    this.notifyError(error);
                    if (error && error.response && error.response.data && error.response.data.errors) {
                        this.formErrors = error.response.data.errors;
                    } else {
                        this.formErrors = [this.getErrorMsgFromErrorResponse(error)];
                    }
                })
                .finally(() => (this.isFormLoading = false));
        },
    },
    computed: {
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_widget() {
            return this.rights.includes("booking.widget.update");
        },
        restaurant_id() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        restaurant() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurant_id);
        },
        hasFeatSeatingPlan() {
            return typeof this.restaurant !== "undefined" && this.restaurant.feat_seating_plan;
        },
        isFeatRoomNumbersEnable() {
            return this.restaurant ? this.restaurant.feat_room_numbers : false;
        },
        widget() {
            return this.$store.getters["widgets/getWidget"];
        },
        isWidgetLoading() {
            return this.$store.getters["widgets/getIsLoading"];
        },
        enableWaitingListPriorityFromLoyaltyLevel: {
            get() {
                return this.currentWidget.enable_waiting_list_priority_from_loyalty_level !== null;
            },
            set(newVal) {
                this.currentWidget.enable_waiting_list_priority_from_loyalty_level = newVal ? this.CLIENT_LOYALTY_LEVEL_FIRST.value : null;
            },
        },
    },
    watch: {
        "currentWidget.unlimited_nb_children": function (value) {
            if (value) {
                this.currentWidget.max_children = null;
            }
        },
        "currentWidget.enable_waitings": function (value) {
            if (!value) {
                this.currentWidget.enable_mail_auto = false;
            }
        },
        "currentWidget.comment_enabled": function (value) {
            if (!value) {
                this.currentWidget.comment_mandatory = false;
            }
        },
        widget() {
            this.initWidget();
        },
    },
    components: {
        OptionBankSettings,
        LoaderComponent,
        ImportRoomNumbersModal,
        ShowRoomNumbersModal,
        AutomaticExportComponent,
        showNewBadgeDetails,
        SettingPartFormGtag,
        SettingPartFormDisplay,
        googleReserve,
    },
    created() {
        this.initWidget();
    },
};
</script>
