<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Réservations</h5>
        <div slot="body">
            <show-reservations-list
                :emit_resa_chosen="emit_resa_chosen"
                :reservations_list="reservations_list"
                :restaurant_id="restaurant_id"
                @reservation-edited="$emit('reservation-edited', $event)"
                @resa-chosen="$emit('resa-chosen', $event)" />
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="close">Fermer</button>
        </div>
    </modal>
</template>

<script>
import ShowReservationsList from "../../reservations/ShowReservationsList.vue";

export default {
    props: {
        emit_resa_chosen: {
            default: false,
        },
        reservations_list: {
            default: [],
        },
        restaurant_id: {
            default: "",
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        ShowReservationsList,
    },
};
</script>
