import { render, staticRenderFns } from "./NotificationCard.vue?vue&type=template&id=5f2e010d"
import script from "./NotificationCard.vue?vue&type=script&lang=js"
export * from "./NotificationCard.vue?vue&type=script&lang=js"
import style0 from "./NotificationCard.vue?vue&type=style&index=0&id=5f2e010d&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f2e010d')) {
      api.createRecord('5f2e010d', component.options)
    } else {
      api.reload('5f2e010d', component.options)
    }
    module.hot.accept("./NotificationCard.vue?vue&type=template&id=5f2e010d", function () {
      api.rerender('5f2e010d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/notifications/NotificationCard.vue"
export default component.exports