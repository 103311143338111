<template>
    <div>
        <table class="capitalize table-size-30 table table-book table-striped table-sm">
            <thead class="border-bottom">
                <tr>
                    <th>
                        <feather type="user" class="feather-20 none-desk" />
                        <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.client") }}</span>
                    </th>
                    <th>
                        <feather type="users" class="feather-20 none-desk" />
                        <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.pax") }}</span>
                    </th>
                    <th>
                        <feather type="clock" class="feather-20 none-desk" />
                        <span class="none-mobile">{{ $tl("labels.booking.reservations.history.columns.slot") }}</span>
                    </th>
                    <th class="none-mobile">
                        <span>{{ $tl("labels.booking.reservations.grid.columns.table", restaurant_id) }}</span>
                    </th>
                    <th>
                        <feather type="check-circle" class="feather-20 none-desk" />
                        <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.status") }}</span>
                    </th>
                    <th class="none-mobile">
                        <span>{{ $tl("labels.booking.reservations.grid.columns.optionBankStatus") }}</span>
                    </th>
                    <th class="text-center none-mobile">
                        <feather type="check" stroke-width="4" />
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    id="resaRow"
                    v-for="reservation in reservations_list"
                    :key="reservation.id"
                    v-on:click="showResa(reservation.id, $event)"
                    class="resa pointer"
                    :class="{
                        updated: reservation.updated === true,
                        new: reservation.new === true,
                    }">
                    <td>
                        <inline-client
                            :client="reservation.client"
                            :module-enum="MODULE_TYPE_BOOKING"
                            :public-comment="reservation.comment"
                            :private-comment="reservation.restaurant_comment"
                            show-noshows>
                            <template v-slot:icons-start>
                                <feather
                                    v-if="reservation.gift"
                                    type="tag"
                                    class="feather-blue"
                                    :class="{ 'text-danger': reservation.gv_validity && !reservation.gv_validity.valid }"
                                    v-tooltip="getTooltip(reservation.gift)" />
                            </template>
                        </inline-client>
                    </td>
                    <td class="table-text-center">
                        {{ reservation.nb_pers * 1 + reservation.nb_children * 1 }}
                    </td>
                    <td>
                        {{ reservation.slot.hour_start }}
                    </td>
                    <td class="table-text-center none-mobile">
                        <template v-for="(table, index) in reservation.tables.data">
                            {{ table.name }}{{ index + 1 < reservation.tables.data.length ? " - " : "" }}
                        </template>
                    </td>
                    <td>
                        {{ capitalize(getReservationStatusLabel(reservation.status)) }}
                    </td>
                    <td class="table-text-center none-mobile">
                        <option-bank-status-icon
                            v-if="reservation.status !== 'canceled' && reservation.status !== 'refused'"
                            :reservation="reservation" />
                    </td>
                    <td class="text-center none-mobile">
                        <div v-if="reservation.confirmed_by_client !== null">
                            <div v-if="reservation.confirmed_by_client === 1">
                                <svg
                                    v-tooltip="getTooltip('Confirmation validée')"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="css-i6dzq1 feather"
                                    style="color: #06c774">
                                    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                </svg>
                            </div>
                            <div v-else>
                                <svg
                                    v-tooltip="getTooltip('Confirmation en attente')"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="css-i6dzq1 feather"
                                    style="color: #ffc107">
                                    <path d="M19.69 14a6.9 6.9 0 0 0 .31-2V5l-8-3-3.16 1.18"></path>
                                    <path d="M4.73 4.73L4 5v7c0 6 8 10 8 10a20.29 20.29 0 0 0 5.62-4.38"></path>
                                    <line x1="1" y1="1" x2="23" y2="23"></line>
                                </svg>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <show-reservation-modal
            v-if="showReservationModal"
            :reservation_id="selected_reservation_id"
            :restaurant_id="selected_restaurant_id"
            @resa-edit="editReservationFromShowModal"
            @close="showReservationModal = false" />
        <edit-reservation-modal
            v-if="showEditReservationModal"
            :restaurant_id="selected_restaurant_id"
            :reservation_id="selected_reservation_id"
            @close="showEditReservationModal = false"
            @reservation-edited="reservationEdited" />
    </div>
</template>

<script>
import EditReservationModal from "../Modals/reservations/EditReservationModal.vue";
import OptionBankStatusIcon from "../stripe/OptionBankStatusIcon.vue";
import ShowReservationModal from "../Modals/reservations/ShowReservationModal.vue";
import ReservationStatusEnum from "../../mixins/enums/booking/ReservationStatusEnum.js";
import InlineClient from "../Default/Clients/inlineClient.vue";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum.js";

export default {
    props: {
        reservations_list: {
            default: [],
        },
        emit_resa_chosen: {
            default: false,
        },
    },
    data() {
        return {
            selected_restaurant_id: null,
            selected_reservation_id: null,
            showReservationModal: false,
            showEditReservationModal: false,
        };
    },
    mixins: [ReservationStatusEnum, ModuleTypesEnum],
    created() {
        this.selected_restaurant_id = this.$route.params.restaurant_id;
    },
    methods: {
        showResa(id, event) {
            if (this.emit_resa_chosen) {
                this.$emit("resa-chosen", id);
                return;
            }
            const excludedTags = ["BUTTON", "A"];

            if (!event || !event.target || !excludedTags.includes(event.target.tagName)) {
                this.$set(this, "selected_reservation_id", id);
                this.$nextTick(() => {
                    this.showReservationModal = true;
                });
            }
        },
        editReservationFromShowModal({ reservation_id }) {
            this.$set(this, "selected_reservation_id", reservation_id);
            this.$nextTick(() => {
                this.showReservationModal = false;
                this.showEditReservationModal = true;
            });
        },
        reservationEdited(event) {
            this.showEditReservationModal = false;
            this.$emit("reservation-edited", event);
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    components: {
        EditReservationModal,
        OptionBankStatusIcon,
        ShowReservationModal,
        InlineClient,
    },
};
</script>
