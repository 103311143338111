<template>
    <div class="">
        <div class="col-12">
            <div v-if="restaurant">
                <no-opened-services v-if="allServicesClosedAndNoResa" is-from-closure :closure-name="closureName" />

                <div v-else-if="servicesToDisplay.length > 0" v-for="(service, serviceIndex) in servicesToDisplay" :key="serviceIndex">
                    <div
                        :ref="`service-${service.id}`"
                        v-if="service.reservations.length > 0 || service.waitings_count > 0 || service.nbPassingCustomer > 0"
                        class="d-flex flex-wrap justify-content-between flex-md-nowrap pb-0 mb-0 day-div">
                        <h6 style="text-transform: uppercase; margin-bottom: 0.3em" class="pt-1">
                            {{ displayDate(reservation_date, "cccc") }}
                            <span class="capacite badge badge-secondary"
                                >{{ getServiceCategoryLabel(service) }}
                                {{ service.special === 1 ? ` (${$tl("labels.booking.services.special.title")})` : "" }}</span
                            >
                            <template v-if="hasResaNotPlaced(service)">
                                <span class="text-danger text-uppercase">{{ $tl("labels.booking.reservations.futureBlocked") }}</span>
                                <feather
                                    type="info"
                                    class="text-danger"
                                    v-tooltip="{ content: $tl('infos.booking.reservations.placeOnSeatingPlan') }" />
                            </template>
                            <template v-if="!service.has_closure">
                                <button
                                    v-if="service.is_full === 1"
                                    class="btn badge badge-danger text-uppercase"
                                    style="color: white !important"
                                    @click="showServiceFull(service.id)">
                                    {{ $tl("labels.booking.services.closed") }}
                                </button>
                                <button
                                    v-if="service.is_full === 0"
                                    class="btn text-white badge badge-warning text-uppercase"
                                    @click="showServiceFull(service.id)">
                                    {{ $tl("labels.booking.services.closedPartially") }}
                                </button>
                            </template>
                            <router-link
                                v-if="service.has_closure"
                                :to="{
                                    name: 'booking.restaurants.settings.closures',
                                    param: { restaurant_id: $route.params.restaurant_id },
                                }"
                                class="badge badge-danger text-uppercase">
                                {{ $tl("labels.booking.closures.title") }}
                            </router-link>
                            <div class="d-inline-block text-muted ml-2" style="text-transform: none">
                                <div
                                    v-if="!service.is_editing_note"
                                    class="clickable p-2"
                                    style="
                                        font-weight: 400;
                                        margin-top: 1px;
                                        font-size: 0.8rem;
                                        margin-bottom: 1px;
                                        padding-left: 13px !important;
                                        padding-right: 13px !important;
                                        border-radius: 20px !important;
                                    "
                                    @click="editServiceNote(service)">
                                    {{ service.note || $tl("labels.booking.services.notes.add") }}
                                </div>
                                <input
                                    type="text"
                                    style="margin-top: -1px; border-radius: 20px !important; width: 300px"
                                    v-else
                                    class="form-control"
                                    maxlength="40"
                                    v-model="services[service.serviceIndex].note"
                                    :ref="`service-${service.id}-edit-note`"
                                    @blur="onBlurEditServiceNote(service)" />
                            </div>
                        </h6>
                        <h6 class="pt-1">
                            <button
                                v-if="service.waitings_count > 0"
                                class="btn btn-sm btn-outline-secondary btn-circle"
                                @click="showWaitingList(service.id, reservation_date.toISODate())">
                                {{ $tl("labels.booking.waitings.list.title") }}
                                <span class="badge badge-danger">{{ service.waitings_count }}</span>
                            </button>
                            <span class="capacite badge badge-secondary">
                                {{ service.pax }} / {{ service.new_pax !== null ? service.new_pax : service.max_pax }}
                            </span>
                        </h6>
                    </div>
                    <div v-if="service.reservations.length > 0" class="border-bottom mb-3">
                        <ReservationsGrid
                            :restaurant="restaurant"
                            :service="service"
                            :reservations="service.reservations"
                            @displayRefundPartiallyModal="displayRefundPartiallyModal"
                            @displayNoshowModal="displayNoshowModal"
                            @displayCancelModal="displayCancelModal"
                            @showResa="showResa"
                            @editReservation="editReservation"
                            @sendEmail="sendEmail" />
                    </div>
                    <span v-if="service.reservations.length > 0 || service.nbPassingCustomer > 0" class="badge badge-secondary ml-1 mb-4">
                        <div v-if="service.reservations.length > 0">
                            {{ $tc("labels.booking.reservations.nbValidated", reservationsValidated(service.reservations).length) }}
                            / {{ service.reservations.length }}
                        </div>
                        <div v-if="service.nbPassingCustomer > 0">
                            {{ $tcl("labels.booking.reservations.passingCustomer.showCount", service.nbPassingCustomer) }}
                        </div>
                    </span>
                </div>
                <div v-if="services.length > 0 && !hasReservations && !allServicesClosedAndNoResa">
                    <div class="row m-0">
                        <div class="col-lg-6 offset-lg-3 pt-5 text-center">
                            <div class="border-light b-radius-20 p-4 mb-3">
                                <h5>{{ $tl("labels.booking.list.empty") }}</h5>
                                <img class="mt-5 none-mobile" width="360" src="/images/empty-table.png" alt="No Show" />
                                <img class="mt-3 none-desk" width="260" src="/images/empty-table.png" alt="No Show" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-white pb-4">.</div>
        <set-noshow-modal
            v-if="showSetNoshowModal"
            :restaurant_id="selected_restaurant_id"
            :reservation="selected_reservation"
            @close="showSetNoshowModal = false" />
        <cancel-paid-reservation-modal
            v-if="showRefundPartiallyModal"
            :reservation_id="selected_reservation.id"
            @close="showRefundPartiallyModal = false" />
        <show-reservation-modal
            v-if="showReservationModal"
            :reservation_id="selected_reservation_id"
            :restaurant_id="selected_restaurant_id"
            @resa-edit="editReservationFromShowModal"
            @close="showReservationModal = false" />
        <show-waitings-modal
            v-if="showWaitingsModal"
            :service_id="selected_service_id"
            :reservation_date="selected_reservation_date"
            @close="showWaitingsModal = false" />
        <edit-reservation-modal
            v-if="showEditReservationModal"
            :restaurant_id="selected_restaurant_id"
            :reservation_id="selected_reservation_id"
            @close="showEditReservationModal = false"
            @reservation-edited="reservationEdited" />
        <cancel-reservation-modal
            v-if="showCancelModal"
            :reservation-id="selected_reservation_id"
            :client="selected_reservation_client"
            :status="newCancelStatus"
            @close="showCancelModal = false" />
    </div>
</template>

<script>
import SetNoshowModal from "../Modals/reservations/SetNoshowModal.vue";
import CancelPaidReservationModal from "../Modals/reservations/CancelPaidReservationModal.vue";
import CancelReservationModal from "../Modals/reservations/CancelReservationModal.vue";
import OptionBankStatusIcon from "../stripe/OptionBankStatusIcon";
import ShowReservationModal from "../Modals/reservations/ShowReservationModal";
import EditReservationModal from "../Modals/reservations/EditReservationModal";
import ShowWaitingsModal from "../Modals/waitings/ShowWaitingsModal";
import ReservationsGrid from "./ReservationsGrid";
import ReservationStatusEnum from "../../mixins/enums/booking/ReservationStatusEnum";
import ServiceNoteTypesEnum from "../../mixins/enums/booking/ServiceNoteTypesEnum";
import LangsEnum from "../../mixins/enums/LangsEnum";
import NoOpenedServices from '../Booking/NoOpenedServices.vue';

export default {
    name: "ReservationsPerService",
    mixins: [ReservationStatusEnum, ServiceNoteTypesEnum, LangsEnum],
    components: {
        CancelPaidReservationModal,
        CancelReservationModal,
        SetNoshowModal,
        OptionBankStatusIcon,
        ShowReservationModal,
        EditReservationModal,
        ShowWaitingsModal,
        ReservationsGrid,
        NoOpenedServices,
    },
    props: {
        services: {
            required: true,
            type: Array,
        },
        restaurant: {
            required: true,
            type: Object,
        },
        reservation_date: {
            required: true,
        },
        allServicesClosedAndNoResa: {
            type: Boolean,
            default: false,
        },
        hasReservations: {
            type: Boolean,
            default: false,
        },
        closureName: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            showSetNoshowModal: false,
            showRefundPartiallyModal: false,
            showCancelModal: false,
            showReservationModal: false,
            showWaitingsModal: false,
            showEditReservationModal: false,
            newCancelStatus: "canceled",
            selected_restaurant_id: null,
            selected_reservation_id: null,
            selected_reservation_client: null,
            selected_service_id: null,
            selected_reservation: null,
            selected_reservation_date: null,
        };
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_reservation() {
            return this.rights.includes("booking.booking.update");
        },
        servicesToDisplay() {
            const result = this.services.map((service, index) => this.computeService(service, index));

            result.sort((a, b) => {
                const dateTimeA = this.setHourOnDateTime(a.hour_begin);
                const dateTimeB = this.setHourOnDateTime(b.hour_begin);

                return dateTimeA.diff(dateTimeB, "hours").hours;
            });

            return result;
        },
    },
    created() {
        this.selected_restaurant_id = this.$route.params.restaurant_id;
    },
    methods: {
        hasResaNotPlaced(service) {
            if (!service.is_seating_plan_algorithm_enabled || !service.reserations) return false;
            return service.reservations.some((reservation) => {
                if (!this.inEnum(reservation.status, this.validReservationStatus) || reservation.ignore_placement) return false;
                return !reservation.tables || !reservation.tables.data || reservation.tables.data.length === 0;
            });
        },
        editServiceNote(service) {
            this.services.forEach((s, index) => {
                if (s.id === service.id) {
                    this.$set(this.services[index], "is_editing_note", true);
                    this.$nextTick(() => {
                        this.$refs[`service-${s.id}-edit-note`][0].focus();
                    });
                }
            });
        },
        onBlurEditServiceNote(service) {
            this.services.forEach((s, index) => {
                if (s.id === service.id) {
                    this.$set(this.services[index], "is_editing_note", false);
                    this.addNoteForService(service);
                }
            });
        },
        addNoteForService(service) {
            axios
                .post(`/api/restaurants/${this.$route.params.restaurant_id}/services/notes/${service.id}`, {
                    reservation_date: this.reservation_date.toISODate(),
                    note: service.note,
                    type: this.SERVICE_NOTE_TYPE_RESERVATIONS_LIST.value,
                })
                .catch((error) => {
                    this.notifyError(error);
                });
        },
        reservationsValidated(reservations) {
            const validatedStatus = [
                this.RESERVATION_STATUS_CONFIRMED.value,
                this.RESERVATION_STATUS_VALIDATED.value,
                this.RESERVATION_STATUS_AT_TABLE.value,
                this.RESERVATION_STATUS_OVER.value,
            ];
            return reservations.filter((r) => validatedStatus.includes(r.status));
        },
        computeService(service, index) {
            var result = {
                id: service.id,
                serviceIndex: index,
                name: service.name,
                category: service.category,
                category_en: service.category_en,
                days: service.days,
                hour_begin: service.hour_begin,
                hour_end: service.hour_end,
                max_pax: service.max_pax,
                new_pax: service.new_pax,
                lock_reservation_until: service.lock_reservation_until,
                special: service.special,
                special_date_begin: service.special_date_begin,
                special_date_end: service.special_date_end,
                menu_unique: service.menu_unique,
                menu_unique_from: service.menu_unique_from,
                prepayment_mandatory: service.prepayment_mandatory,
                prepayment_percent: service.prepayment_percent,
                cancellation_until: service.cancellation_until,
                is_full: service.is_full,
                waitings_count: service.waitings_count,
                has_closure: service.has_closure,
                reservations: [],
                pax: 0,
                is_editing_note: service.is_editing_note,
                is_seating_plan_algorithm_enabled: service.is_seating_plan_algorithm_enabled,
                note: service.note,
                note_seating_plan: service.note_seating_plan,
            };

            var { reservations, pax, nbPassingCustomer } = this.computeReservations(service.reservations);

            result.reservations = reservations;
            result.pax = pax;
            result.nbPassingCustomer = nbPassingCustomer;

            return result;
        },
        computeReservations(reservations) {
            var result = [];
            var pax = 0;
            var nbPassingCustomer = 0;
            const avoidStatusForPax = ["canceled", "noshow", "refused", "pending"];

            reservations.forEach((reservation) => {
                if (reservation.status !== "pending" && !reservation.is_passing_customer) {
                    result.push(reservation);
                }

                if (reservation.is_passing_customer) {
                    ++nbPassingCustomer;
                }

                if (!avoidStatusForPax.includes(reservation.status)) {
                    pax += reservation.nb_children;
                    pax += reservation.nb_pers;
                }
            });

            result.sort(this.sortReservations);

            return { reservations: result, pax, nbPassingCustomer };
        },
        sortReservations(resaA, resaB) {
            const resaAStatusScore = this.reservationStatusToScore(resaA.status);
            const resaBStatusScore = this.reservationStatusToScore(resaB.status);

            if (resaAStatusScore === resaBStatusScore) {
                const resaDateTimeA = this.getDateTime(resaA.reservation_datetime);
                const resaDateTimeB = this.getDateTime(resaB.reservation_datetime);

                if (resaDateTimeA < resaDateTimeB) {
                    return -1;
                }
                if (resaDateTimeA > resaDateTimeB) {
                    return 1;
                }
                return 0;
            } else if (resaAStatusScore < resaBStatusScore) {
                return 1;
            }
            return -1;
        },
        reservationStatusToScore(status) {
            switch (status) {
                case "at_table":
                    return 9;
                case "confirmed":
                    return 8;
                case "validated":
                    return 7;
                case "over":
                    return 6;
                case "created":
                    return 5;
                case "option":
                    return 4;
                case "noshow":
                    return 3;
                case "canceled":
                    return 2;
                case "refused":
                    return 1;
                default:
                    return 0;
            }
        },
        showWaitingList(serviceId, reservationDate) {
            this.$set(this, "selected_service_id", serviceId);
            this.$set(this, "selected_reservation_date", reservationDate);

            this.$nextTick(() => {
                this.showWaitingsModal = true;
            });
        },
        displayRefundPartiallyModal(reservation) {
            this.selected_reservation = reservation;
            this.showRefundPartiallyModal = true;
        },
        displayCancelModal({ newStatus, data }) {
            this.selected_reservation_id = data.id;
            this.selected_reservation_client = data.client;
            this.newCancelStatus = newStatus;
            this.showCancelModal = true;
        },
        displayNoshowModal(reservation) {
            this.$set(this, "selected_restaurant_id", this.$route.params.restaurant_id);
            this.$set(this, "selected_reservation", reservation);

            this.$nextTick(() => {
                this.$set(this, "showSetNoshowModal", true);
            });
        },
        sendEmail(event, reservation_id) {
            event.preventDefault();
            event.stopPropagation();
            axios
                .post(`/api/restaurants/${this.$route.params.restaurant_id}/reservations/${reservation_id}/mail`, {
                    reservation_id: reservation_id,
                })
                .then(() => {
                    this.notifySuccess(null, this.$tl("success.booking.reservations.confirmationSent"));
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => (this.loading = false));
        },
        askConfirmation(event, id) {
            event.preventDefault();
            event.stopPropagation();

            event.target.setAttribute("disabled", true);
            axios
                .post(`/api/restaurants/${this.$route.params.restaurant_id}/reservations/${id}/confirmation`)
                .then(() => {
                    event.target.removeAttribute("disabled");
                    this.notifySuccess(null, this.$tl("success.booking.reservations.askConfirmationSent"));
                })
                .catch((error) => {
                    event.target.removeAttribute("disabled");
                    this.notifyError(error);
                });
        },
        showResa(id, event) {
            const excludedTags = ["BUTTON", "A"];

            if (!event || !event.target || !excludedTags.includes(event.target.tagName)) {
                this.$set(this, "selected_reservation_id", id);
                this.$nextTick(() => {
                    this.showReservationModal = true;
                });
            }
        },
        editReservation(event, id) {
            event.preventDefault();
            event.stopPropagation();

            this.$set(this, "selected_reservation_id", id);
            this.$nextTick(() => {
                this.showEditReservationModal = true;
            });
        },
        reservationEdited() {
            this.$parent.fetchData();
            this.showEditReservationModal = false;
            this.notifySuccess(null, this.$tl("success.booking.reservations.edited"));
        },
        editReservationFromShowModal({ reservation_id }) {
            this.$set(this, "selected_reservation_id", reservation_id);
            this.$nextTick(() => {
                this.showReservationModal = false;
                this.showEditReservationModal = true;
            });
        },
        showServiceFull(id) {
            this.$emit("show-service-full", {
                service_id: id,
            });
        },
        confirmResaForClient(e, reservation) {
            e.preventDefault();
            e.stopPropagation();
            axios
                .put(`/api/restaurants/${this.$route.params.restaurant_id}/reservations/${reservation.id}/confirmation`)
                .then(() => {
                    this.notifySuccess(null, this.$tl("success.booking.reservations.confirmed"));
                })
                .catch((error) => {
                    this.notifyError(error);
                });
        },
    },
    watch: {
        "$route.params.restaurant_id": function (id) {
            this.selected_restaurant_id = this.$route.params.restaurant_id;
        },
        "$route.query.reservation_id": {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.selected_reservation_id = newVal;
                    this.showReservationModal = true;
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.day-div {
    margin-top: 0;
}

@media (max-width: 1180px) and (min-width: 992px) {
    .day-div {
        margin-top: 2rem;
    }
}
</style>
