<template>
    <div class="d-inline align-middle w-100">
        <span class="text-uppercase text-center align-middle d-block bg-danger text-white p-1">
            {{ errorMessage }} :
            <u><router-link class="text-white" :to="{ name: 'subscription.card' }">Modifier ma carte</router-link></u>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            required: true,
            validator: function (value) {
                return ["card_expires_soon", "card_expired"].includes(value);
            },
        },
    },
    computed: {
        errorMessage() {
            switch (this.type) {
                case "card_expires_soon":
                    return "Votre carte bancaire arrive bientôt à expiration. Pensez à changer votre moyen de paiement dans la section Abonnement";
                case "card_expired":
                    return "Votre carte bancaire est expirée. Pour éviter toute interruption de service, modifiez dès maintenant votre moyen de paiement dans la section Abonnement";
            }
            return "Unknown error";
        },
    },
};
</script>
