<template>
    <div>
        <div class="row mt-2" v-for="column in log.changed_columns" :key="column">
            <div class="col-md-9">
                <feather class="mr-2" :type="getSaleColumnIcon(column, log)" /> <strong>{{ userDisplayable }}</strong> a modifié
                {{ getSaleColumnLabel(column, log) }} du bon cadeau :
                <strong>{{ getSaleColumnValueLabel(column, log) }}</strong>
            </div>
            <div class="col-md-3 text-right">
                <small>{{ createdAt }}</small>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import SaleColumnsEnum from "../../../mixins/enums/gift_vouchers/SaleColumnsEnum.js";

export default {
    data() {
        return {};
    },
    mixins: [SaleColumnsEnum],
    props: {
        log: {
            required: true,
            type: Object,
        },
    },
    methods: {},
    computed: {
        userDisplayable() {
            if (!this.log.user_id) return "Le client";
            if (!this.log.user) return "Un utilisateur";
            return `${this.log.user.firstname} ${this.log.user.lastname}`;
        },
        createdAt() {
            return moment(this.log.created_at).format("[le] DD/MM/Y [à] HH[h]mm");
        },
    },
};
</script>
