<template>
    <modal @close.stop="closeFromModal">
        <h5 slot="header">Détails du code promo</h5>
        <div slot="body">
            <promo-code-fields :promo-code="promoCode" enable-copy-component />
            <div class="row m-0">
                <div class="col-12 mb-3">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row" style="cursor: pointer" @click="canShowLogs = !canShowLogs">
                            <div class="col-12">
                                <strong class="mb-0">Historique des modifications</strong>
                            </div>
                        </div>
                        <template v-if="canShowLogs">
                            <PromoCodeLogList :promo-code="promoCode" />
                        </template>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12 mb-3">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row" style="cursor: pointer" @click="canShowRestrictedUsers = !canShowRestrictedUsers">
                            <div class="col-12">
                                <strong class="mb-0">Utilisateurs pouvant utiliser le code promo</strong>
                            </div>
                        </div>
                        <template v-if="canShowRestrictedUsers">
                            <PromoCodeRestrictedUsersList :promo-code="promoCode" />
                        </template>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12 mb-3">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-between">
                                <strong class="mb-0">Utilisateurs ayant utilisé le code promo</strong>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-circle btn-success"
                                    @click.prevent.stop="canShowUsedByUsers = !canShowUsedByUsers">
                                    Voir la liste
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PromoCodeUsedUsersListModal :promo-code="promoCode" v-if="canShowUsedByUsers" @close="canShowUsedByUsers = false" />
        </div>
        <div slot="footer">
            <button type="button" class="modal-default-button btn btn-secondary btn-circle" @click="close">
                {{ $t("labels.form.actions.close") }}
            </button>
        </div>
    </modal>
</template>
<script>
import PromoCodeLogList from "../../../components/Admin/PromoCodes/Show/PromoCodeLogList.vue";
import PromoCodeRestrictedUsersList from "../../../components/Admin/PromoCodes/Show/PromoCodeRestrictedUsersList.vue";
import PromoCodeUsedUsersListModal from "../../../components/Admin/PromoCodes/Show/PromoCodeUsedUsersListModal.vue";
import PromoCodeFields from "../../../components/Admin/PromoCodes/Show/PromoCodeFields.vue";

export default {
    props: {
        promoCode: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    components: {
        PromoCodeLogList,
        PromoCodeRestrictedUsersList,
        PromoCodeUsedUsersListModal,
        PromoCodeFields,
    },
    data() {
        return {
            canShowLogs: false,
            canShowRestrictedUsers: false,
            canShowUsedByUsers: false,
            fields: [],
        };
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal(event) {
            if (event && event.target && !event.target.classList.contains("modal-wrapper")) {
                event.stopPropagation();
            } else {
                this.close();
            }
        },
    },
};
</script>
<style scoped>
.copy-container {
    max-width: 300px;
}
</style>
