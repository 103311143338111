var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loadings.menu || _vm.loadings.form
        ? _c("loader-component")
        : _vm.errors.fetchMenu !== null
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v("\n        " + _vm._s(_vm.errors.fetchMenu) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.loadings.menu &&
                !_vm.loadings.form &&
                _vm.errors.fetchMenu === null,
              expression:
                "!loadings.menu && !loadings.form && errors.fetchMenu === null",
            },
          ],
          ref: "form",
          attrs: { method: "post" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "border-light b-radius-20 p-4" },
                [
                  _c("input-component", {
                    attrs: {
                      inputName: "name",
                      required: "",
                      disabled: !_vm.isEditable,
                      label: _vm.$tl("labels.form.name"),
                      "form-errors": _vm.errors.form,
                    },
                    model: {
                      value: _vm.menu.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.menu, "name", $$v)
                      },
                      expression: "menu.name",
                    },
                  }),
                  _vm._v(" "),
                  _vm.widget.enable_children
                    ? _c("switch-input-component", {
                        attrs: {
                          inputName: "for_children",
                          disabled: !_vm.isEditable,
                          label: _vm.$tl(
                            "labels.booking.menus.childrenOnly",
                            _vm.restaurantId
                          ),
                          "form-errors": _vm.errors.form,
                        },
                        model: {
                          value: _vm.menu.for_children,
                          callback: function ($$v) {
                            _vm.$set(_vm.menu, "for_children", $$v)
                          },
                          expression: "menu.for_children",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input-component", {
                    attrs: {
                      inputName: "price",
                      inputType: "number",
                      required: "",
                      step: "0.01",
                      min: "0",
                      disabled: !_vm.isEditable,
                      label: _vm.$t("labels.booking.menus.price", {
                        currency: _vm.getRestaurantCurrency(_vm.restaurantId),
                      }),
                      "form-errors": _vm.errors.form,
                    },
                    model: {
                      value: _vm.menu.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.menu, "price", $$v)
                      },
                      expression: "menu.price",
                    },
                  }),
                  _vm._v(" "),
                  !_vm.isTvaDisabled
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-5 pt-2" }, [
                          _c("label", { attrs: { for: "tax_rate" } }, [
                            _vm._v(_vm._s(_vm.$tl("labels.taxRate")) + " "),
                            _c("small", [_vm._v("*")]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-7" }, [
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c("div", { staticClass: "col-md-4" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.menu.tax_rate,
                                        expression: "menu.tax_rate",
                                      },
                                    ],
                                    staticClass: "custom-select mb-0",
                                    attrs: {
                                      placeholder:
                                        "Veuillez sélectionner un taux",
                                      name: "tax_rate",
                                      disabled: !_vm.isEditable,
                                      required: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.menu,
                                          "tax_rate",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { domProps: { value: null } },
                                      [_vm._v("--")]
                                    ),
                                    _vm._v(" "),
                                    _c("option", { domProps: { value: 5.5 } }, [
                                      _vm._v("5,5"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { domProps: { value: 10 } }, [
                                      _vm._v("10"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { domProps: { value: 20 } }, [
                                      _vm._v("20"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { domProps: { value: 8.1 } }, [
                                      _vm._v("8.1"),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-8 mt-1" }, [
                                _vm._v("%"),
                              ]),
                              _vm._v(" "),
                              _c("show-errors", {
                                staticClass: "col-12",
                                attrs: {
                                  errors: _vm.errors.form,
                                  errorKey: "tax_rate",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col-5" }, [
                      _c("label", { attrs: { for: "description" } }, [
                        _vm._v(_vm._s(_vm.$tl("labels.form.description"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-7" },
                      [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.menu.description,
                              expression: "menu.description",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            name: "description",
                            disabled: !_vm.isEditable,
                          },
                          domProps: { value: _vm.menu.description },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.menu,
                                "description",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("show-errors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors.form,
                            errorKey: "description",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row my-2" }, [
                    _c("div", { staticClass: "col-5" }, [
                      _c(
                        "label",
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.menus.externalDescription.externalDescription"
                                )
                              ) +
                              "\n                                "
                          ),
                          _c("feather", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.getTooltipNoticeLexicon(
                                  "notices.booking.menus.externalDescription",
                                  _vm.restaurantId
                                ),
                                expression:
                                  "getTooltipNoticeLexicon('notices.booking.menus.externalDescription', restaurantId)",
                              },
                            ],
                            staticClass: "ml-1 pointer tooltip-infos",
                            attrs: { type: "info" },
                          }),
                          _vm._v(" "),
                          _c("show-new-badge-details", {
                            staticClass: "ml-1",
                            attrs: {
                              badge_uuid:
                                "fa229fe9-ff8c-4390-b288-843b65b8176d",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-7" },
                      [
                        _c(
                          "div",
                          { staticClass: "radio" },
                          [
                            _c(
                              "radio-button-component",
                              {
                                attrs: {
                                  inputValue:
                                    _vm.EXTERNAL_DESCRIPTION_FILE.value,
                                },
                                model: {
                                  value: _vm.menu.external_description_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.menu,
                                      "external_description_type",
                                      $$v
                                    )
                                  },
                                  expression: "menu.external_description_type",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.menus.externalDescription.file.pdf"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "radio-button-component",
                              {
                                attrs: {
                                  inputValue:
                                    _vm.EXTERNAL_DESCRIPTION_LINK.value,
                                },
                                model: {
                                  value: _vm.menu.external_description_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.menu,
                                      "external_description_type",
                                      $$v
                                    )
                                  },
                                  expression: "menu.external_description_type",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.menus.externalDescription.link.link"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "radio-button-component",
                              {
                                attrs: { inputValue: null },
                                model: {
                                  value: _vm.menu.external_description_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.menu,
                                      "external_description_type",
                                      $$v
                                    )
                                  },
                                  expression: "menu.external_description_type",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.menus.externalDescription.disabled"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("show-errors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors.form,
                            errorKey: "external_description_type",
                          },
                        }),
                        _vm._v(" "),
                        _vm.menu.external_description_type ===
                        _vm.EXTERNAL_DESCRIPTION_FILE.value
                          ? _c(
                              "div",
                              { staticClass: "my-2" },
                              [
                                _c("file-uploader-component", {
                                  attrs: {
                                    "props-file": _vm.file,
                                    errors: _vm.errors.form,
                                    hasDirectUpload: false,
                                    suffixId: "ext-desc",
                                    src: _vm.pathFile,
                                    deleteURI: _vm.deleteURIFile,
                                    "max-size": 20,
                                    "call-back-delete": () => {
                                      _vm.pathFile = ""
                                      _vm.file = null
                                      _vm.menu.external_description.file = ""
                                    },
                                  },
                                  on: {
                                    "upload-file": function ($event) {
                                      _vm.file = $event
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("show-errors", {
                                  staticClass: "d-block",
                                  attrs: {
                                    errors: _vm.errors.form,
                                    errorKey: "external_description.file",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm.menu.external_description_type ===
                            _vm.EXTERNAL_DESCRIPTION_LINK.value
                          ? _c(
                              "div",
                              { staticClass: "my-2" },
                              [
                                _c("input-component", {
                                  attrs: {
                                    label: "",
                                    inputName: "external_description.link",
                                    "form-errors": _vm.errors.form,
                                    placeholder: _vm.$tl(
                                      "labels.booking.menus.externalDescription.link.placeholder"
                                    ),
                                    labelWrapperClasses: "d-none",
                                    type: "url",
                                  },
                                  model: {
                                    value: _vm.menu.external_description.link,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.menu.external_description,
                                        "link",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "menu.external_description.link",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-5" }, [
                      _vm._v(_vm._s(_vm.$tl("labels.form.picture"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-7" },
                      [
                        _c("image-uploader", {
                          attrs: {
                            "uploaded-img-url": _vm.img.uploadedImgUrl,
                            "uploaded-img-path": _vm.img.uploadedImgPath,
                            "delete-url": _vm.deleteImageURL,
                            maxSize: 2,
                            disabled: !_vm.isEditable,
                          },
                          on: {
                            "image-selected": function ($event) {
                              _vm.img.file = $event
                            },
                            "image-deleted": _vm.onImageDeleted,
                          },
                        }),
                        _vm._v(" "),
                        _c("show-errors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors.form,
                            errorKey: "img_file",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.loadings.menuOptions
                    ? _c("loader-component")
                    : _c("div", { staticClass: "row mb-2 mt-4" }, [
                        _c("div", { staticClass: "col-5" }, [
                          _c("label", [
                            _vm._v(
                              _vm._s(
                                _vm.$tl("labels.booking.menus.options.link")
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-7" },
                          [
                            _vm.errors.fetchMenuOptions !== null
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.errors.fetchMenuOptions) +
                                      "\n                            "
                                  ),
                                ])
                              : _vm.availableMenuOptions.length > 0
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.availableMenuOptions,
                                    function (option) {
                                      return _c(
                                        "div",
                                        { key: option.id, staticClass: "mb-2" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "container-box",
                                              staticStyle: {
                                                "margin-bottom": "0",
                                                width: "100%",
                                              },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.menu.menu_options,
                                                    expression:
                                                      "menu.menu_options",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  name: "menu_options[]",
                                                  disabled: !_vm.isEditable,
                                                },
                                                domProps: {
                                                  value: option.id,
                                                  checked: Array.isArray(
                                                    _vm.menu.menu_options
                                                  )
                                                    ? _vm._i(
                                                        _vm.menu.menu_options,
                                                        option.id
                                                      ) > -1
                                                    : _vm.menu.menu_options,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.menu.menu_options,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = option.id,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.menu,
                                                            "menu_options",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.menu,
                                                            "menu_options",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.menu,
                                                        "menu_options",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(option.name) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("show-errors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors.form,
                                errorKey: "menu_options",
                              },
                            }),
                            _vm._v(" "),
                            _c("show-errors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors.form,
                                errorKey: "menu_options.*",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-4" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-success btn-sm btn-circle",
                                  attrs: {
                                    type: "button",
                                    disabled: !_vm.has_right_to_create_menu,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.showAddMenuOptionModal = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.menus.options.add"
                                        )
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showSaveButton
                ? _c("div", { staticClass: "row mt-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("input", {
                        staticClass: "btn btn-success btn-circle",
                        attrs: {
                          type: "submit",
                          name: "submit",
                          disabled: !_vm.isEditable,
                        },
                        domProps: {
                          value: _vm.$tl("labels.form.actions.save"),
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.showAddMenuOptionModal
        ? _c("add-menu-option-modal", {
            attrs: {
              "option-type": _vm.MENU_OPTION_MENU.value,
              "restaurant-id": _vm.restaurantId,
            },
            on: {
              close: function ($event) {
                _vm.showAddMenuOptionModal = false
              },
              "menu-option-added": _vm.fetchMenuOptions,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }