var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Réinitialiser les mails et sms de test (3 maximum)"),
    ]),
    _vm._v(" "),
    _c("div", { attrs: { slot: "body" }, slot: "body" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "d-flex justify-content-around my-2" }, [
            _c(
              "button",
              {
                staticClass: "modal-default-button btn btn-success btn-circle",
                attrs: { type: "button", disabled: _vm.isLoading },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.resetRateLimiter("send-emails-test")
                  },
                },
              },
              [
                _vm._v(
                  "\n                        Réinitialiser les envois de mails\n                    "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "modal-default-button btn btn-success btn-circle",
                attrs: { type: "button", disabled: _vm.isLoading },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.resetRateLimiter("send-sms-test")
                  },
                },
              },
              [
                _vm._v(
                  "\n                        Réinitialiser les envois de sms\n                    "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex w-100 justify-center",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn-sm btn btn-secondary btn-circle",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.close.apply(null, arguments)
              },
            },
          },
          [_vm._v("Fermer")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }