var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "reservation-vue",
      staticStyle: { height: "calc(100vh - 124px) !important" },
    },
    [
      _c("LoaderComponent", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading",
            },
          ],
          staticClass: "top-nav-resa",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.error,
                  expression: "error",
                },
              ],
              staticClass: "error",
            },
            [_vm._v("\n            " + _vm._s(_vm.error) + "\n        ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-0 pt-2 pb-1 pl-3 pr-3 tablet-menu",
            },
            [
              _c("div", [
                _c(
                  "h2",
                  { staticClass: "h5 d-inline-block" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "btn btn-sm btn-outline-secondary radius-btn-square",
                        staticStyle: { width: "29px", "padding-left": "5px" },
                        attrs: {
                          to: {
                            name: "booking.restaurants.reservations",
                            params: { restaurant_id: _vm.restaurantId },
                            query: {
                              date: _vm.reservation_date_prev,
                              view: _vm.view,
                            },
                          },
                        },
                      },
                      [_c("feather", { attrs: { type: "chevron-left" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "date-resa ml-1 mr-1 pt-1",
                        staticStyle: { "text-transform": "uppercase" },
                        on: { click: _vm.openPicker },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.displayDate(
                                _vm.reservation_date,
                                "cccc dd LLL"
                              )
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "btn btn-sm btn-outline-secondary radius-btn-square",
                        staticStyle: { width: "29px", "padding-left": "6px" },
                        attrs: {
                          to: {
                            name: "booking.restaurants.reservations",
                            params: { restaurant_id: _vm.restaurantId },
                            query: {
                              date: _vm.reservation_date_next,
                              view: _vm.view,
                            },
                          },
                        },
                      },
                      [_c("feather", { attrs: { type: "chevron-right" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-sm btn-outline-secondary none-mobile btn-circle",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.reservation_date = _vm.getDateTime()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$tl("labels.today")) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-sm btn-outline-secondary none-desk btn-circle",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.reservation_date = _vm.getDateTime()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$tl("labels.todayShort")) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-sm btn-danger btn-circle none-mobile ml-2",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.showEventsForDay()
                      },
                    },
                  },
                  [
                    _c("feather", {
                      staticClass: "ml-1",
                      attrs: { type: "plus" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.showEventsForDay(true)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("feather", {
                      staticClass: "mx-1",
                      attrs: { type: "volume-2" },
                    }),
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.$tl("labels.booking.customEvents.titleForDay")
                        ) +
                        " (" +
                        _vm._s(_vm.eventsForDay.length) +
                        ")\n                "
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-inline-block align-middle switch-div-tablet",
                  staticStyle: { "margin-top": "-8px" },
                },
                [
                  _vm.hasFeatSeatingPlan
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "h-100 d-inline-block align-middle none-mobile",
                        },
                        [
                          _c("toggle-switch", {
                            staticClass: "p-0 m-0 d-inline-flex align-middle",
                            attrs: { options: _vm.toggleSwitchOptions },
                            model: {
                              value: _vm.contentToDisplay,
                              callback: function ($$v) {
                                _vm.contentToDisplay = $$v
                              },
                              expression: "contentToDisplay",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ml-1 h-100 d-inline-block btn-toolbar none-mobile align-middle",
                    },
                    [
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchInput,
                              expression: "searchInput",
                            },
                          ],
                          staticClass: "search-resa form-control",
                          staticStyle: {
                            "border-radius": "20px 0 0 20px !important",
                          },
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("labels.form.actions.search"),
                          },
                          domProps: { value: _vm.searchInput },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.search()
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.searchInput = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-outline-secondary",
                            staticStyle: { "border-radius": "0 20px 20px 0" },
                            on: {
                              click: function ($event) {
                                return _vm.search()
                              },
                            },
                          },
                          [_c("feather", { attrs: { type: "search" } })],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-sm btn-circle btn-outline-secondary",
                      class: {
                        disabled: !_vm.has_right_to_export_reservations,
                      },
                      attrs: {
                        type: "button",
                        disabled: !_vm.has_right_to_export_reservations,
                      },
                      on: { click: _vm.exportReservations },
                    },
                    [
                      _c("feather", { attrs: { type: "upload" } }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$tl("labels.form.actions.export")) +
                          "\n                "
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("DatePicker", {
        ref: "theDatePicker",
        staticClass: "btn btn-sm date-resa date-resa-cal cal-fixed",
        model: {
          value: _vm.reservation_date,
          callback: function ($$v) {
            _vm.reservation_date = $$v
          },
          expression: "reservation_date",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.error,
              expression: "!error",
            },
          ],
          staticClass: "h-100 d-flex flex-column content-resa",
          staticStyle: { "margin-top": "-10px" },
        },
        [
          !_vm.loading && _vm.services
            ? [
                _vm.services.length > 0
                  ? _c(
                      "div",
                      { staticClass: "h-100" },
                      [
                        _vm.contentToDisplay ===
                        _vm.$t("labels.booking.list.title")
                          ? _c("reservations-per-service", {
                              attrs: {
                                services: _vm.services,
                                restaurant: _vm.restaurant,
                                reservation_date: _vm.reservation_date,
                                "has-reservations": _vm.hasReservations,
                                "all-services-closed-and-no-resa":
                                  _vm.allServicesClosedAndNoResa,
                                "closure-name": _vm.closureName,
                              },
                              on: {
                                "show-service-full": function ($event) {
                                  return _vm.$parent.$emit(
                                    "show-service-full",
                                    $event
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.contentToDisplay ===
                        _vm.$t("labels.booking.seatingPlan.title")
                          ? _c("seating-plan-per-service", {
                              attrs: {
                                date: _vm.reservation_date,
                                services: _vm.services,
                                restaurant: _vm.restaurant,
                                "reservation-added": _vm.reservationAdded,
                                "reservation-updated": _vm.reservationUpdated,
                                "reservation-deleted": _vm.reservationDeleted,
                                "all-services-closed-and-no-resa":
                                  _vm.allServicesClosedAndNoResa,
                                "closure-name": _vm.closureName,
                              },
                              on: {
                                "add-reservation":
                                  _vm.displayAddReservationModal,
                                "add-passing-customer": function ($event) {
                                  return _vm.displayAddReservationModal(
                                    $event,
                                    true
                                  )
                                },
                                "go-to-resa-list": function ($event) {
                                  _vm.contentToDisplay = _vm.$t(
                                    "labels.booking.list.title"
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.contentToDisplay ===
                        _vm.$t("labels.booking.timeline.title")
                          ? _c("timeline-per-service", {
                              attrs: {
                                restaurant: _vm.restaurant,
                                services: _vm.services,
                                reservation_date: _vm.reservation_date,
                                "all-services-closed-and-no-resa":
                                  _vm.allServicesClosedAndNoResa,
                                "closure-name": _vm.closureName,
                              },
                              on: {
                                "add-reservation":
                                  _vm.displayAddReservationModal,
                                "add-passing-customer": function ($event) {
                                  return _vm.displayAddReservationModal(
                                    $event,
                                    true
                                  )
                                },
                                "go-to-resa-list": function ($event) {
                                  _vm.contentToDisplay = _vm.$t(
                                    "labels.booking.list.title"
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm.services.length === 0
                  ? _c("no-opened-services", {
                      attrs: { "is-forbbiden": _vm.isForbidden },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.showAddReservationModal && _vm.has_right_to_create_reservation
        ? _c("add-reservation-modal", {
            attrs: {
              tablesIdToPreselect: _vm.selected_tables_id,
              service_id: _vm.selected_service_id,
              slot_id: _vm.selected_slot_id,
              restaurant_id: _vm.restaurantId,
            },
            on: {
              "reservation-saved": function ($event) {
                _vm.showAddReservationModal = false
              },
              close: function ($event) {
                _vm.showAddReservationModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAddPassingCustomerModal && _vm.has_right_to_create_reservation
        ? _c("add-passing-customer-modal", {
            attrs: {
              tablesIdToPreselect: _vm.selected_tables_id,
              serviceId: _vm.selected_service_id,
              restaurantId: _vm.restaurantId,
              date: _vm.selectedDate,
            },
            on: {
              saved: function ($event) {
                _vm.showAddPassingCustomerModal = false
              },
              close: function ($event) {
                _vm.showAddPassingCustomerModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "list-custom-events",
        _vm._b(
          { ref: "listCustomEvents" },
          "list-custom-events",
          {
            restaurantId: _vm.restaurantId,
            date: _vm.reservation_date.toISODate(),
          },
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }