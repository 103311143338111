var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex flex-column" }, [
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("strong", [_vm._v(_vm._s(_vm.$tl("labels.detail")))]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _vm.loading
          ? _c("LoaderComponent")
          : [
              _vm.error
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.error) +
                        "\n            "
                    ),
                  ])
                : [
                    _c(
                      "div",
                      [
                        _vm._l(_vm.modules, function (module, index) {
                          return _c(
                            "div",
                            { staticClass: "p-2 my-1 border-light" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-between align-items-center",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$tl("labels.licence")) +
                                        " " +
                                        _vm._s(_vm.getModuleTitle(module)) +
                                        "\n                                " +
                                        _vm._s(
                                          _vm.plan === "yearly"
                                            ? _vm.$tl("labels.yearlyF")
                                            : _vm.$tl("labels.monthlyF")
                                        )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v("x " + _vm._s(module.count)),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm.training
                          ? _c(
                              "div",
                              { staticClass: "p-2 my-1 border-light" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between align-items-center",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.form.licences.training"
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("small", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.form.licences.quotationSendAfter"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.isNoShow
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center ml-2",
                          },
                          [
                            _c("label", { attrs: { for: "promo-code" } }, [
                              _vm._v(_vm._s(_vm.$tl("labels.promoCode.title"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center position-relative",
                              },
                              [
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.promoCode,
                                          expression: "promoCode",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        disabled: _vm.hasReduction,
                                        name: "promo-code",
                                        placeholder: "XXXXXX",
                                      },
                                      domProps: { value: _vm.promoCode },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.promoCode = $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                !_vm.hasReduction
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-circle btn-success h-100 ml-2",
                                        attrs: {
                                          type: "button",
                                          disabled: _vm.isPromoCodeLoading,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.submitPromoCode.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.form.actions.apply"
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass:
                                          "d-inline-block pointer position-absolute",
                                        staticStyle: { right: "0.25rem" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.resetPromoCode.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("feather", {
                                          staticClass: "feather-red",
                                          attrs: { type: "x" },
                                        }),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasReduction &&
                    _vm.reduction.duration !== _vm.COUPON_DURATION_FOREVER.value
                      ? _c("small", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$tl(
                                  "labels.promoCode.appliedWithDuration",
                                  undefined,
                                  {
                                    promoCode: _vm.getReductionLabelAmount(),
                                    period: _vm.getPeriod(),
                                    total: _vm.getTotalReduction(),
                                  }
                                )
                              ) +
                              "\n                "
                          ),
                        ])
                      : _vm.hasReduction
                      ? _c("small", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$tl("labels.promoCode.applied", undefined, {
                                  promoCode: _vm.getReductionLabelAmount(),
                                })
                              ) +
                              "\n                "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "p-2 my-1 border-light d-flex justify-content-between align-items-center bg-light",
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.plan === _vm.STRIPE_PLAN_TYPE_MONTHLY.value
                                  ? _vm.$tl("labels.form.licences.monthlyCost")
                                  : _vm.$tl("labels.form.licences.yearlyCost")
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm.hasReduction
                              ? _c("s", [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        this.formatCurrency(
                                          _vm.licencesCost / 100
                                        )
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  this.formatCurrency(_vm.calculatedPrice / 100)
                                )
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ],
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }