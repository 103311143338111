export default {
    booking: {
        closures: {
            services: "Jeu(x)",
            concernedServices: "Jeux concernés",
            noServiceForDate: "Aucun jeu disponible pour les dates sélectionnées",
        },
        services: {
            add: "Ajouter un jeu",
            name: "Nom du jeu",
            edit: "Éditer le jeu :",
            duplicate: "Dupliquer un jeu",
            next: "Prochain jeu",
            live: "Jeu en cours",
            createFirst: "Créer mon premier jeu",
            noOneAvailable: "Aucun jeu n'est disponible",
            startHour: "Heure de début du jeu",
            endHour: "Heure de fin du jeu",
            inCaseFull: "En cas de jeu complet",
            recap: "Récapitulatif du jeu",
            editDayMaxPax: "Modifier le PAX max du jour sur ce jeu",
            maxPax: "Nombre de personnes maximum par jeu",
            showOnDashboardLong: "Affichage du jeu sur le tableau de bord",
            emptyForRestaurant: "Aucun jeu pour cet établissement",
            takeResaLive: "Prendre des réservations pendant le jeu",
            minsBeforeStart: "minutes avant le début du jeu",
            showRestaurantService: "Proposer au client les jeux des établissements suivants :",
        },
        reservations: {
            editService: "Modifier le jeu",
        },
        stats: {
            filters: {
                serviceCategory: "Catégorie de jeu",
            },
        },
        widget: {
            automaticExport: {
                nbMinutes: "Planifier l'envoi avant le début du jeu (en minutes)",
                services: "Choisir les jeux concernés",
            },
        },
        restaurant: {
            closedForSomeService: "Établissement fermé aujourd'hui pour certains jeux.",
        },
        list: {
            emptyForService: "Vous n'avez pas de réservations pour ce jeu.",
        },
        seatingPlan: {
            emptyForService: "Vous n'avez pas de réservations aujourd'hui, pour le jeu \"{service}\".",
            room: {
                disabledForService: "Cette salle est désactivée pour ce jeu",
                enabledForService: "Cette salle a été activée pour ce jeu uniquement",
            },
            columns: {
                services: "Jeux",
            },
        },
    },
    routes: {
        booking: {
            config: {
                services: "Jeux",
            },
            services: {
                current: "Jeux courants",
            },
        },
    },
    giftVouchers: {
        products: {
            service: "Jeu",
        },
    },
    notifications: {
        booking: {
            serviceOver: "Liste d'attente - jeu terminé",
        },
    },
};
