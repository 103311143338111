<template>
    <div>
        <template v-if="stripTags">
            <div v-if="hasMergeTags">
                <button
                    style="border-color: #ededed"
                    class="btn btn-sm btn-outline-secondary dropdown-toggle border-bottom-0"
                    type="button"
                    data-toggle="dropdown">
                    {{ $tl("labels.mergeTags") }}
                </button>
                <div class="dropdown-menu dropdown-menu-left shadow" style="transform: translate3d(13px, 29px, 0px); min-width: 100px">
                    <button
                        v-for="mergeTag in mergeTags"
                        :key="mergeTag.name"
                        type="button"
                        @click="addText(mergeTag.tag)"
                        class="dropdown-item d-flex pl-1">
                        {{ mergeTag.description }}
                    </button>
                </div>
            </div>

            <textarea ref="smsTextarea" class="form-control" v-model="valueComputed" :disabled="disabled" rows="4"></textarea>
        </template>
        <ckeditor v-else v-model="valueComputed" ref="ckMailEditor" :editor="editor" :config="editorConfig" :disabled="disabled"></ckeditor>
        <slot></slot>
    </div>
</template>

<script>
import LangsEnum from "../../mixins/enums/LangsEnum.js";

/* Base */
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";

/* Info boxes plugins */
import StylePlugin from "@ckeditor/ckeditor5-style/src/style";
import GeneralHtmlSupportPlugin from "@ckeditor/ckeditor5-html-support/src/generalhtmlsupport";

/* Text plugins */
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import UnderlinePlugin from "@ckeditor/ckeditor5-basic-styles/src/underline";
import StrikethroughPlugin from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Font from "@ckeditor/ckeditor5-font/src/font";

/* Lists plugins */
import List from "@ckeditor/ckeditor5-list/src/list";
import ListProperties from "@ckeditor/ckeditor5-list/src/listproperties";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";

/* Image plugins */
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import LinkImage from "@ckeditor/ckeditor5-link/src/linkimage";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert";

/* Adapters plugins */
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";

/* Custom plugins */
import MergeTagsPlugin from "../../plugins/ckeditor/MergeTags.js";

/* Translations */
import "../../build/translations/fr.js";
import { add } from "@ckeditor/ckeditor5-utils/src/translation-service";
add("fr", {
    "Toggle caption on": "Ajouter une légende",
    "Toggle caption off": "Supprimer la légende",
});

export default {
    data() {
        return {
            editor: ClassicEditor,
        };
    },
    mixins: [LangsEnum],
    props: {
        value: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        mergeTags: {
            type: Array,
            default: null,
        },
        stripTags: {
            type: Boolean,
            default: false,
        },
        contentLocale: {
            type: String,
        },
        allowFonts: {
            type: Boolean,
            default: false,
        },
        allowImages: {
            type: Boolean,
            default: false,
        },
        allowLists: {
            type: Boolean,
            default: false,
        },
        allowInfoBoxes: {
            type: Boolean,
            default: false,
        },
        disableSpecificPlugins: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        editorConfig() {
            const config = {
                language: {
                    ui: this.userLang,
                    content: this.contentLocale,
                },
                plugins: [
                    EssentialsPlugin,
                    GeneralHtmlSupportPlugin,
                    Heading,
                    BoldPlugin,
                    ItalicPlugin,
                    UnderlinePlugin,
                    StrikethroughPlugin,
                    LinkPlugin,
                    ParagraphPlugin,
                    MergeTagsPlugin,
                    SimpleUploadAdapter,
                ],
                toolbar: ["heading"],
                mergeTags: {
                    tags: [],
                },
                simpleUpload: {
                    uploadUrl: "/api/ckeditor/upload/image",
                },
                style: {
                    definitions: [
                        {
                            name: "Info box",
                            element: "p",
                            classes: ["info-box"],
                        },
                    ],
                },
            };

            const stylingPlugins = [
                { plugin: BoldPlugin, name: "bold" },
                { plugin: ItalicPlugin, name: "italic" },
                { plugin: UnderlinePlugin, name: "underline" },
                { plugin: StrikethroughPlugin, name: "strikethrough" },
                { plugin: LinkPlugin, name: "link" },
            ];

            if (this.allowInfoBoxes) {
                config.plugins.push(StylePlugin);
                config.toolbar.push("|", "style");
            }

            let atLeastOneStylingPlugin = false;
            stylingPlugins.forEach((stylingPlugin) => {
                if (!this.disableSpecificPlugins.includes(stylingPlugin.name)) {
                    if (!atLeastOneStylingPlugin) {
                        atLeastOneStylingPlugin = true;
                        config.toolbar.push("|");
                    }
                    config.plugins.push(stylingPlugin.plugin);
                    config.toolbar.push(stylingPlugin.name);
                }
            });

            if (this.allowFonts) {
                config.plugins.push(Font);
                config.toolbar.push("|", "fontSize", "fontColor", "fontBackgroundColor");
            }

            if (this.allowLists) {
                config.plugins.push(List, ListProperties, Indent, IndentBlock);
                config.toolbar.push("|", "bulletedList", "numberedList", "indent", "outdent");
            }

            if (this.allowImages) {
                config.plugins.push(Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, LinkImage, ImageUpload, ImageInsert);
                config.toolbar.push("|", "insertImage");
                config.image = {
                    toolbar: [
                        "imageStyle:inline",
                        "imageStyle:block",
                        {
                            name: "imageStyle:customDropdown",
                            title: "Align",
                            items: ["imageStyle:alignLeft", "imageStyle:alignRight"],
                            defaultItem: "imageStyle:alignLeft",
                        },
                        "|",
                        "toggleImageCaption",
                        "imageTextAlternative",
                        "|",
                        "linkImage",
                    ],
                };
            }

            config.toolbar.push("|", "undo", "redo");

            if (this.hasMergeTags) {
                config.plugins.push(MergeTagsPlugin);
                config.toolbar.push("|", "mergeTags");
                config.mergeTags.tags = this.mergeTags;
            }

            if (this.stripTags) {
                config.plugins = ["Typing", "Undo"];
                config.autoParagraph = false;
                config.toolbar = ["undo", "redo"];
            }

            return config;
        },
        valueComputed: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        hasMergeTags() {
            return Array.isArray(this.mergeTags) && this.mergeTags.length > 0;
        },
    },
    methods: {
        addText(text) {
            if (this.stripTags) {
                this.addTextToSmsTextarea(text);
            } else {
                this.addTextToCkMailEditor(text);
            }
        },
        addTextToCkMailEditor(text) {
            const editor = this.$refs.ckMailEditor.$_instance;

            editor.model.change((writer) => {
                editor.model.insertContent(writer.createText(text));
            });

            editor.editing.view.focus();
        },
        addTextToSmsTextarea(text) {
            const textarea = this.$refs.smsTextarea;

            textarea.setRangeText(text, textarea.selectionStart, textarea.selectionEnd, "end");

            this.valueComputed = textarea.value;

            textarea.focus();
        },
    },
};
</script>

<style>
:root {
    --ck-z-modal: 9999;
}

.ck.ck-icon.ck-dropdown__arrow {
    z-index: auto;
}

.ck.ck-dropdown .ck-button.ck-dropdown__button .ck-button__label {
    width: auto;
}
</style>
