<template>
    <div class="pb-5 pt-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Configuration</h5>
                </div>
            </div>
        </div>
        <div class="row m-0 pb-5">
            <div class="col-12">
                <LoaderComponent v-if="loading" />
                <div v-else>
                    <div v-if="error" class="alert alert-danger">
                        {{ error }}
                    </div>
                    <div v-else-if="has_right_to_read">
                        <!-- <div class="row mb-3">
                            <div class="col-12">
                                <div class="border-light b-radius-20 p-4">
                                    <div class="row" style="cursor: pointer;" @click="showGeneralConfig = !showGeneralConfig">
                                        <div class="col-12">
                                            <strong>Général</strong>
                                        </div>
                                    </div>
                                    <template v-if="showGeneralConfig">
                                        <div class="row mt-3">
                                            <div class="col-md-5 pt-1">
                                                <label>Nombre de bons cadeaux maximum acceptés par service</label>
                                            </div>
                                            <div class="col-md-7">
                                                <input type="number" class="form-control" name="">
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div> -->

                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="border-light b-radius-20 p-4">
                                    <div class="row" style="cursor: pointer" @click="showShippingConfig = !showShippingConfig">
                                        <div class="col-12">
                                            <strong>Modes d'expédition</strong>
                                        </div>
                                    </div>
                                    <template v-if="showShippingConfig">
                                        <div class="row mt-3 mb-2">
                                            <div class="col-md-5 pt-1">
                                                <label>Activer l'option envoi par courrier postal</label>
                                            </div>
                                            <div class="col-md-7 pt-1">
                                                <div class="d-inline-block align-middle lead-switch">
                                                    <input
                                                        type="checkbox"
                                                        :disabled="!has_right_to_update"
                                                        class="switch align-self-center is-rounded"
                                                        v-model="config.postal_mail" />
                                                    <label
                                                        @click="
                                                            config.postal_mail = has_right_to_update ? !config.postal_mail : config.postal_mail
                                                        "></label>
                                                </div>
                                                <ShowErrors :errors="errors" errorKey="postal_mail" />
                                            </div>
                                        </div>
                                        <template v-if="config.postal_mail">
                                            <div class="row">
                                                <div class="col-md-5 pt-1">
                                                    <label>Zone d'expédition</label>
                                                </div>
                                                <div class="col-md-7">
                                                    <button
                                                        :disabled="nb_areas_remaining < 1 || !has_right_to_update"
                                                        @click="addDispatchArea()"
                                                        class="btn btn-sm btn-success btn-circle">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            aria-hidden="true"
                                                            class="feather feather-plus sc-dnqmqq jxshSx">
                                                            <line x1="12" y1="5" x2="12" y2="19"></line>
                                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                                        </svg>
                                                        <span>Ajouter une zone d'expédition</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="row mt-3 mb-3" v-if="config.dispatch_areas.length > 0">
                                                <div class="col-12">
                                                    <div class="border-light b-radius-20 p-4">
                                                        <table class="capitalize table table-sm table-striped border-bottom">
                                                            <thead class="border-bottom">
                                                                <tr>
                                                                    <th>Zone</th>
                                                                    <th>Frais d'expédition</th>
                                                                    <th>Délai d'envoi</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="area in config.dispatch_areas" :key="area.id">
                                                                    <td>{{ getAreaLabel(area.area) }}</td>
                                                                    <td>{{ area.fees }} €</td>
                                                                    <td>{{ area.shipping_time }} jours</td>
                                                                    <td>
                                                                        <button
                                                                            class="btn btn-sm btn-success btn-square"
                                                                            title="Modifier la réservation"
                                                                            :disabled="!has_right_to_update"
                                                                            @click="editDispatchArea(area)"
                                                                            v-tooltip="{ content: 'Éditer' }">
                                                                            <svg
                                                                                class="feather feather-edit sc-dnqmqq jxshSx"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="currentColor"
                                                                                stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                aria-hidden="true">
                                                                                <path
                                                                                    d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                                                                <path
                                                                                    d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                                                            </svg>
                                                                        </button>
                                                                        <button
                                                                            :disabled="!has_right_to_update"
                                                                            @click="deleteDispatchArea(area.id)"
                                                                            class="btn btn-sm btn-danger btn-square"
                                                                            v-tooltip="{ content: 'Supprimer' }">
                                                                            <svg
                                                                                class="feather feather-trash-2 sc-dnqmqq jxshSx"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="currentColor"
                                                                                stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                aria-hidden="true">
                                                                                <polyline points="3 6 5 6 21 6"></polyline>
                                                                                <path
                                                                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                                                                <line x1="14" y1="11" x2="14" y2="17"></line>
                                                                            </svg>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="row text-center mt-2">
                                                <div class="col-12">Vous n'avez ajouté aucune zone d'expédition</div>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="border-light b-radius-20 p-4">
                                    <div class="row" style="cursor: pointer" @click="showPaymentConfig = !showPaymentConfig">
                                        <div class="col-12">
                                            <strong>Paiements</strong>
                                        </div>
                                    </div>
                                    <template v-if="showPaymentConfig">
                                        <div class="row mt-3">
                                            <div class="col-md-5 pt-1">
                                                <label>Autoriser les paiements de groupe</label>
                                            </div>
                                            <div class="col-md-7 pt-1">
                                                <div class="d-inline-block align-middle lead-switch">
                                                    <input
                                                        type="checkbox"
                                                        :disabled="!has_right_to_update"
                                                        class="switch align-self-center is-rounded"
                                                        v-model="config.group_payment" />
                                                    <label
                                                        @click="
                                                            config.group_payment = has_right_to_update ? !config.group_payment : config.group_payment
                                                        "></label>
                                                </div>
                                                <ShowErrors :errors="errors" errorKey="group_payment" />
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="border-light b-radius-20 p-4">
                                    <div class="row" style="cursor: pointer" @click="showPaymentConfig = !showPaymentConfig">
                                        <div class="col-12">
                                            <strong>Widget</strong>
                                        </div>
                                    </div>
                                    <template v-if="showPaymentConfig">
                                        <div class="row mt-3">
                                            <div class="col-md-5 pt-1">
                                                <label>Afficher les offres et bons cadeaux par catégorie</label>
                                            </div>
                                            <div class="col-md-7 pt-1">
                                                <div class="d-inline-block align-middle lead-switch">
                                                    <input
                                                        type="checkbox"
                                                        :disabled="!has_right_to_update"
                                                        class="switch align-self-center is-rounded"
                                                        v-model="config.display_categories" />
                                                    <label
                                                        @click="
                                                            config.display_categories = has_right_to_update
                                                                ? !config.display_categories
                                                                : config.display_categories
                                                        "></label>
                                                </div>
                                                <ShowErrors :errors="errors" errorKey="display_categories" />
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <button class="btn btn-success btn-circle" :disabled="!has_right_to_update" @click="save()" type="button">
                                    Enregistrer
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-else class="alert alert-danger">Désolé, vous n'avez pas le droit d'accéder à cette page.</div>
                    <add-shipping-area-modal
                        v-if="showAddShippingAreaModal"
                        :area="area"
                        @saved="dispatchAreaSaved()"
                        @close="showAddShippingAreaModal = false" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import addShippingAreaModal from "../../../components/Modals/giftVouchers/addShippingAreaModal.vue";
import ShowErrors from "../../../components/errors/ShowErrors";

function defaultDispatchArea() {
    return {
        id: null,
        area: null,
        fees: null,
        shipping_time: null,
    };
}

export default {
    data() {
        return {
            loading: false,
            error: null,
            errors: null,
            showGeneralConfig: true,
            showShippingConfig: true,
            showPaymentConfig: true,
            showAddShippingAreaModal: false,
            config: null,
            area: null,
            nb_areas_remaining: 0,
        };
    },
    computed: {
        builtData() {
            let data = {};
            Object.assign(data, this.config);
            return data;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update() {
            return this.rights.includes("gift_vouchers.config.update");
        },
        has_right_to_read() {
            return this.rights.includes("gift_vouchers.config.read");
        },
    },
    methods: {
        addDispatchArea() {
            this.area = defaultDispatchArea();
            this.showAddShippingAreaModal = true;
        },
        editDispatchArea(area) {
            this.area = {};
            Object.assign(this.area, area);
            this.showAddShippingAreaModal = true;
        },
        dispatchAreaSaved() {
            this.showAddShippingAreaModal = false;
            this.fetchDispatchAreas();
        },
        getAreaLabel(area) {
            switch (area) {
                case "fr":
                    return "France";
                case "int":
                    return "International";
                default:
                    return "";
            }
        },
        deleteDispatchArea(area_id) {
            if (!confirm("Êtes vous sûr de vouloir supprimer cette zone d'expédition ?")) return;
            this.loading = true;
            this.error = null;

            axios
                .delete(`/api/gift_vouchers/${this.$route.params.restaurant_id}/config/dispatchAreas/${area_id}`)
                .then((response) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    this.fetchDispatchAreas().then(() => {
                        if (this.config.dispatch_areas !== null && this.config.dispatch_areas.length < 1) this.config.postal_mail = false;
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: error.response.data.message ? error.response.data.message : "Une erreur est survenue",
                    });
                });
        },
        fetchData() {
            this.loading = true;
            this.error = null;
            this.config = null;

            axios
                .get(`/api/gift_vouchers/${this.$route.params.restaurant_id}/config`)
                .then((response) => {
                    this.loading = false;
                    this.config = response.data.config;
                    this.nb_areas_remaining = response.data.nb_areas_remaining;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = error.response.data.message ? error.response.data.message : "Une erreur est survenue";
                });
        },
        fetchDispatchAreas() {
            this.loading = true;
            this.error = null;
            this.config.dispatch_areas = null;

            return axios
                .get(`/api/gift_vouchers/${this.$route.params.restaurant_id}/config/dispatchAreas`)
                .then((response) => {
                    this.loading = false;
                    this.config.dispatch_areas = response.data.dispatch_areas;
                    this.nb_areas_remaining = response.data.nb_areas_remaining;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = error.response.data.message ? error.response.data.message : "Une erreur est survenue";
                });
        },
        save() {
            if (!this.has_right_to_update) return;
            this.loading = true;
            this.errors = null;

            axios
                .put(`/api/gift_vouchers/${this.$route.params.restaurant_id}/config`, this.builtData)
                .then((response) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    this.errors = error.response.data.errors;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: error.response.data.message ? error.response.data.message : "Une erreur est survenue",
                    });
                });
        },
    },
    components: {
        addShippingAreaModal,
        LoaderComponent,
        ShowErrors,
    },
    created() {
        if (this.has_right_to_read) this.fetchData();
    },
};
</script>

<style scoped>
button.btn:disabled {
    cursor: default;
}
</style>
