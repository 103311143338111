<template>
    <div class="pb-5 pt-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Produits</h5>
                    <div class="d-flex align-items-center btn-group">
                        <button
                            :disabled="config === null || !has_right_to_create_product"
                            @click="addProduct"
                            class="btn btn-sm btn-success btn-circle"
                            style="border-radius: 20px 0 0 20px !important">
                            <feather type="plus"></feather>
                            <span>Ajouter un produit</span>
                        </button>
                        <button
                            :disabled="config === null || !has_right_to_create_product"
                            @click="addFormule"
                            class="btn btn-sm btn-outline-secondary btn-circle"
                            style="border-radius: 0 20px 20px 0 !important">
                            <feather type="plus"></feather>
                            <span>Ajouter une formule</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-0 pb-3">
            <div class="col-12">
                <LoaderComponent v-if="loading" />
                <div v-else>
                    <div v-if="error" class="alert alert-danger">{{ error }}</div>
                    <div v-else-if="config !== null">
                        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-3">
                            <div>
                                <div style="margin-top: 4px" class="d-inline-block">
                                    <form @submit="fetchData">
                                        <div style="border-radius: 20px 0 0 20px !important" class="form-control search-client d-inline pr-1">
                                            <input v-model="filter" class="search-input" placeholder="Nom, Description..." type="text" />
                                            <button
                                                @click="resetFilter"
                                                type="button"
                                                class="p-0 m-0 clear-search-button"
                                                style="background: none; border: none">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-x">
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            </button>
                                        </div>
                                        <button
                                            type="submit"
                                            class="btn btn-sm btn-outline-secondary search-client-btn"
                                            style="border-radius: 0 20px 20px 0">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-search">
                                                <circle cx="11" cy="11" r="8" />
                                                <line x1="21" y1="21" x2="16.65" y2="16.65" />
                                            </svg>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <table class="table table-sm table-striped border-bottom">
                            <thead class="border-bottom">
                                <tr>
                                    <th>Nom</th>
                                    <th>Statut</th>
                                    <th>Catégorie</th>
                                    <!-- <th>Description</th> -->
                                    <th>Prix TTC</th>
                                    <th v-if="!isTvaDisabled">TVA</th>
                                    <th>Stock</th>
                                    <th>Préparation</th>
                                    <th>Disponibilité</th>
                                    <th>Créneaux</th>
                                    <th>
                                        <feather type="shopping-bag" class="mr-1" />
                                        <feather type="truck" class="mr-1" />
                                        <feather type="box" />
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="product in products" :key="product.id">
                                    <td>
                                        <span v-if="product.name.length < 30">{{ product.name }}</span>
                                        <span
                                            v-tooltip="{ delay: { show: 400, hide: 0 }, content: product.name }"
                                            v-else-if="product.name.length >= 30"
                                            >{{ product.name.substring(0, 30) + "..." }}</span
                                        >
                                    </td>
                                    <td>
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                :disabled="!has_right_to_update_product"
                                                type="checkbox"
                                                class="switch align-self-center is-rounded"
                                                v-model="product.active" />
                                            <label
                                                v-tooltip="{ delay: { show: 400, hide: 0 }, content: 'Actif' }"
                                                @click="editActiveProduct(product)"></label>
                                        </div>
                                    </td>
                                    <td>{{ product.category_label }}</td>
                                    <td>{{ product.price !== null ? formatCurrency(product.price) : "--" }}</td>
                                    <td v-if="!isTvaDisabled">
                                        {{ product.tax_rate && product.type == "single" ? `${product.tax_rate}%` : "--" }}
                                    </td>
                                    <td>
                                        <svg
                                            v-if="product.stock === null"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="css-i6dzq1 feather">
                                            <circle cx="7" cy="11.5" r="4.5" />
                                            <circle cx="17" cy="11.5" r="4.5" />
                                        </svg>
                                        <span v-else>{{ product.stock }}</span>
                                    </td>
                                    <td>
                                        <span v-if="product.single_type === 'menu'">--</span>
                                        <template v-else>
                                            <template v-if="displayCollectParams(product)">
                                                <span v-if="product.preparation_time !== null"
                                                    >{{ product.preparation_time }}
                                                    {{ preparationTimeUnitLabel(product.preparation_time_unit, product.preparation_time) }}</span
                                                >
                                                <span v-else>{{ configPreparationTime }}</span>
                                            </template>
                                            <span v-if="displayCollectParams(product) && displayDeliveryParams(product)"> - </span>
                                            <template v-if="displayDeliveryParams(product)">
                                                <span v-if="product.delivery_preparation_time !== null"
                                                    >{{ product.delivery_preparation_time }}
                                                    {{
                                                        preparationTimeUnitLabel(
                                                            product.delivery_preparation_time_unit,
                                                            product.delivery_preparation_time
                                                        )
                                                    }}</span
                                                >
                                                <span v-else>{{ configDeliveryPreparationTime }}</span>
                                            </template>
                                        </template>
                                    </td>
                                    <td>
                                        <span v-if="product.single_type === 'menu'">--</span>
                                        <template v-else>
                                            <template v-if="displayCollectParams(product)">
                                                <span v-if="product.available_from !== null && product.available_to !== null">
                                                    {{ dateToFr(product.available_from) }} -
                                                    {{ dateToFr(product.available_to) }}
                                                </span>
                                                <svg
                                                    v-else
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="css-i6dzq1 feather">
                                                    <circle cx="7" cy="11.5" r="4.5" />
                                                    <circle cx="17" cy="11.5" r="4.5" />
                                                </svg>
                                            </template>
                                            <br v-if="displayCollectParams(product) && displayDeliveryParams(product)" />
                                            <template v-if="displayDeliveryParams(product)">
                                                <span v-if="product.delivery_available_from !== null && product.delivery_available_to !== null">
                                                    {{ dateToFr(product.delivery_available_from) }} -
                                                    {{ dateToFr(product.delivery_available_to) }}
                                                </span>
                                                <svg
                                                    v-else
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="css-i6dzq1 feather">
                                                    <circle cx="7" cy="11.5" r="4.5" />
                                                    <circle cx="17" cy="11.5" r="4.5" />
                                                </svg>
                                            </template>
                                        </template>
                                    </td>
                                    <td>
                                        <span v-if="product.single_type === 'menu'">--</span>
                                        <template v-else>
                                            <span v-if="displayCollectParams(product)">
                                                {{ product.use_default_slots == true ? "Tous" : "Limité" }}
                                            </span>
                                            <span v-if="displayCollectParams(product) && displayDeliveryParams(product)"> / </span>
                                            <span v-if="displayDeliveryParams(product)">
                                                {{ product.use_default_delivery_slots == true ? "Tous" : "Limité" }}
                                            </span>
                                        </template>
                                    </td>
                                    <td>
                                        <feather
                                            v-if="config.is_click_and_collect_enabled"
                                            type="shopping-bag"
                                            :class="{
                                                'mr-1': config.is_delivery_enabled || config.is_dispatch_enabled,
                                                'text-success': product.is_enabled_for_click_and_collect,
                                                'text-danger': !product.is_enabled_for_click_and_collect,
                                            }" />
                                        <feather
                                            v-if="config.is_delivery_enabled"
                                            type="truck"
                                            :class="{
                                                'mr-1': config.is_dispatch_enabled,
                                                'text-success': product.is_enabled_for_delivery,
                                                'text-danger': !product.is_enabled_for_delivery,
                                            }" />
                                        <feather
                                            v-if="config.is_dispatch_enabled"
                                            type="box"
                                            :class="{
                                                'text-success': product.is_enabled_for_dispatch,
                                                'text-danger': !product.is_enabled_for_dispatch,
                                            }" />
                                    </td>
                                    <td>
                                        <button
                                            :disabled="!has_right_to_update_product"
                                            class="btn btn-sm btn-success btn-square"
                                            @click="editProduct(product)"
                                            v-tooltip="{ delay: { show: 400, hide: 0 }, content: 'Éditer' }">
                                            <svg
                                                class="feather feather-edit sc-dnqmqq jxshSx"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                aria-hidden="true">
                                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                                            </svg>
                                        </button>
                                        <button
                                            :disabled="!has_right_to_create_product"
                                            class="btn btn-sm btn-success btn-square"
                                            @click="duplicateProduct(product)"
                                            v-tooltip="{ delay: { show: 400, hide: 0 }, content: 'Dupliquer' }">
                                            <svg
                                                viewBox="0 0 24 24"
                                                width="24"
                                                height="24"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="css-i6dzq1 feather">
                                                <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                            </svg>
                                        </button>
                                        <span
                                            v-tooltip="{
                                                delay: { show: 400, hide: 0 },
                                                content: product.has_future_commands
                                                    ? 'Ce produit ne peut être supprimer car il est inclus dans des commandes futures. Vous pouvez néanmoins désactiver ce produit en attendant de pouvoir le supprimer.'
                                                    : 'Supprimer',
                                            }">
                                            <button
                                                :disabled="!has_right_to_delete_product || product.has_future_commands"
                                                class="btn btn-sm btn-danger btn-square"
                                                @click="deleteProduct(product.id)">
                                                <svg
                                                    class="feather feather-trash-2 sc-dnqmqq jxshSx"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    aria-hidden="true">
                                                    <polyline points="3 6 5 6 21 6" />
                                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                                    <line x1="10" y1="11" x2="10" y2="17" />
                                                    <line x1="14" y1="11" x2="14" y2="17" />
                                                </svg>
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <add-click-and-collect-product-modal
                            v-if="showAddClickAndCollectProductModal"
                            @close="showAddClickAndCollectProductModal = false"
                            @saved="productSaved"
                            :product="product"
                            :duplicate="isDuplicating"
                            :config="config" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-0 mb-3" v-if="!loading && error !== null && has_right_to_update_product">
            <div class="col-12 text-muted">
                Vous avez la possibilité d'ordonner vos produits en cliquant sur "Catégories" puis sur l'icone
                <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="css-i6dzq1 feather">
                    <polyline points="9 5 12 2 15 5"></polyline>
                    <polyline points="15 19 12 22 9 19"></polyline>
                    <line x1="12" y1="2" x2="12" y2="22"></line></svg
                >.
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import addClickAndCollectProductModal from "../../../components/Modals/clickAndCollect/addClickAndCollectProductModal.vue";
import showNewBadgeDetails from "../../../components/NewBadges/showNewBadgeDetails.vue";
import moment from "moment";

function defaultProduct(type) {
    return {
        id: null,
        type,
        name: null,
        description: null,
        cc_products_category_id: null,
        img: null,
        price: null,
        tax_rate: null,
        stock: null,
        stock_type: "daily",
        preparation_time: null,
        preparation_time_unit: "mins",
        available_from: null,
        available_to: null,
        use_default_slots: true,
        delivery_preparation_time: null,
        delivery_preparation_time_unit: "mins",
        delivery_available_from: null,
        delivery_available_to: null,
        use_default_delivery_slots: true,
        cc_product_delivery_slots: {
            data: [],
        },
        enable_allergens: false,
        allergens: [],
        single_type: "single",
        cc_product_components: {
            data: [],
        },
        is_enabled_for_click_and_collect: true,
        is_enabled_for_delivery: true,
        is_enabled_for_dispatch: true,
        weight: null,
        enable_price_offer: false,
        price_offer: null,
        offer_starts_on: null,
        offer_ends_on: null,
        payable_with_mealvouchers: false,
    };
}

export default {
    data() {
        return {
            loading: false,
            error: null,
            filter: null,
            product: null,
            product_statut_enable: true,
            product_statut_disable: false,
            showAddClickAndCollectProductModal: false,
            products: null,
            config: null,
            isDuplicating: false,
        };
    },
    computed: {
        isTvaDisabled() {
            return this.$store.getters["restaurants/isTvaDisabled"](this.$route.params.restaurant_id);
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_product() {
            return this.rights.includes("click_and_collect.products.create");
        },
        has_right_to_update_product() {
            return this.rights.includes("click_and_collect.products.update");
        },
        has_right_to_delete_product() {
            return this.rights.includes("click_and_collect.products.delete");
        },
        configDeliveryPreparationTime() {
            if (this.config === null) {
                return "";
            }

            return `${this.config.delivery_preparation_time} ${this.preparationTimeUnitLabel(
                this.config.delivery_preparation_time_unit,
                this.config.delivery_preparation_time
            )}`;
        },
        configPreparationTime() {
            if (this.config === null) {
                return "";
            }

            let unit = "";
            if (Number.isInteger(this.config.preparation_time / 1440)) {
                unit = "jours";
            } else if (Number.isInteger(this.config.preparation_time / 60)) {
                unit = "heures";
            } else {
                unit = "minutes";
            }
            if (unit === "minutes") {
                return this.config.preparation_time + " min";
            } else if (unit === "heures") {
                if (this.config.preparation_time / 60 > 1) {
                    return this.config.preparation_time / 60 + " heures";
                }
                return this.config.preparation_time / 60 + " heure";
            } else if (unit === "jours") {
                if (this.config.preparation_time / 1440 > 1) {
                    return this.config.preparation_time / 1440 + " jours";
                }
                return this.config.preparation_time / 1440 + " jour";
            }
            return "";
        },
    },
    methods: {
        displayCollectParams(product) {
            return this.config.preparation_time !== null && product.is_enabled_for_click_and_collect && this.config.is_click_and_collect_enabled;
        },
        displayDeliveryParams(product) {
            return this.config.delivery_preparation_time !== null && product.is_enabled_for_delivery && this.config.is_delivery_enabled;
        },
        getDescription(description) {
            if (description === null) return "";
            return description.length <= 30 ? description : description.substr(0, 30) + "...";
        },
        resetFilter() {
            this.filter = null;
            this.fetchData();
        },
        dateToFr(date) {
            return moment(date).format("DD MMM");
        },
        preparationTimeUnitLabel(unit, value) {
            switch (unit) {
                case "mins":
                    return "min";
                case "hours":
                    return "heure" + (value > 1 ? "s" : "");
                case "days":
                    return "jour" + (value > 1 ? "s" : "");
                default:
                    return "";
            }
        },
        addProduct() {
            if (this.config === null) {
                return;
            }

            this.product = defaultProduct("single");
            if (!this.config.is_click_and_collect_enabled) this.product.is_enabled_for_click_and_collect = false;
            if (!this.config.is_delivery_enabled) this.product.is_enabled_for_delivery = false;
            if (!this.config.is_dispatch_enabled) this.product.is_enabled_for_dispatch = false;
            this.isDuplicating = false;
            this.showAddClickAndCollectProductModal = true;
        },
        addFormule() {
            if (this.config === null) {
                return;
            }

            this.product = defaultProduct("menu");
            if (!this.config.is_click_and_collect_enabled) this.product.is_enabled_for_click_and_collect = false;
            if (!this.config.is_delivery_enabled) this.product.is_enabled_for_delivery = false;
            if (!this.config.is_dispatch_enabled) this.product.is_enabled_for_dispatch = false;
            this.isDuplicating = false;
            this.showAddClickAndCollectProductModal = true;
        },
        editProduct(product) {
            if (this.config === null) {
                return;
            }

            this.product = _.cloneDeep(product);
            if (!this.config.is_click_and_collect_enabled) this.product.is_enabled_for_click_and_collect = false;
            if (!this.config.is_delivery_enabled) this.product.is_enabled_for_delivery = false;
            if (!this.config.is_dispatch_enabled) this.product.is_enabled_for_dispatch = false;
            this.isDuplicating = false;
            this.showAddClickAndCollectProductModal = true;
        },
        duplicateProduct(product) {
            if (this.config === null) {
                return;
            }

            this.product = _.cloneDeep(product);
            if (!this.config.is_click_and_collect_enabled) this.product.is_enabled_for_click_and_collect = false;
            if (!this.config.is_delivery_enabled) this.product.is_enabled_for_delivery = false;
            if (!this.config.is_dispatch_enabled) this.product.is_enabled_for_dispatch = false;
            this.isDuplicating = true;
            this.showAddClickAndCollectProductModal = true;
        },
        productSaved() {
            this.showAddClickAndCollectProductModal = false;
            this.fetchData();
        },
        deleteProduct(product_id) {
            if (!this.has_right_to_delete_product) return;
            if (!confirm("Êtes vous sûr de vouloir supprimer ce produit ?")) return;
            this.loading = true;

            axios
                .delete(`/api/click_and_collect/${this.$route.params.restaurant_id}/products/${product_id}`)
                .then((response) => {
                    this.notifySuccess(response);
                    this.fetchData();
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => (this.loading = false));
        },
        fetchData(e) {
            this.loading = true;
            this.error = null;
            this.products = null;

            if (e) e.preventDefault();

            let url = `/api/click_and_collect/${this.$route.params.restaurant_id}/products?include=has_future_commands,cc_product_slots,cc_product_delivery_slots,cc_product_components.cc_product_options.cc_product`;
            if (this.filter !== null) url += "&filter=" + this.filter;

            axios
                .get(url)
                .then((response) => {
                    this.products = response.data.products;
                    this.config = response.data.configuration;
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => (this.loading = false));
        },
        isProductLinkedToMenus(productId) {
            return this.products.some((p) =>
                p.cc_product_components.data.some((c) => c.cc_product_options.data.some((o) => o.cc_product_id === productId))
            );
        },
        editActiveProduct(product) {
            if (!this.has_right_to_update_product) return;

            if (!product.active == false && this.isProductLinkedToMenus(product.id))
                if (!confirm("Attention, ce produit fait partie d'une formule. Souhaitez vous vraiment le désactiver ?")) return;
            product.active = !product.active;
            this.loading = true;

            axios
                .put(`/api/click_and_collect/${this.$route.params.restaurant_id}/products/${product.id}/active`, {
                    active: product.active,
                })
                .then((response) => {
                    this.notifySuccess(response);
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => (this.loading = false));
        },
    },
    components: {
        LoaderComponent,
        addClickAndCollectProductModal,
        showNewBadgeDetails,
    },
    created() {
        this.fetchData();
    },
};
</script>
