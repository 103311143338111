<template>
    <div>
        <!-- begin:Desktop -->
        <div class="d-flex align-items-center w-100 none-mobile">
            <button
                type="button"
                class="btn btn-sm p-0"
                :disabled="currentPageComputed < 2"
                @click.stop="currentPageComputed = 1"
                v-tooltip="getTooltip($tl('labels.pager.firstPage'))">
                <feather type="skip-back" />
            </button>
            <button
                type="button"
                class="btn btn-sm p-0"
                :disabled="currentPageComputed < 2"
                @click.stop="--currentPageComputed"
                v-tooltip="getTooltip($tl('labels.pager.previousPage'))">
                <feather type="chevron-left" />
            </button>

            <button
                v-if="startPageNumbersRange > 1"
                class="btn btn-sm p-0"
                @click="currentPageComputed = startPageNumbersRange - 1"
                v-tooltip="getTooltip($tl('labels.pager.previous'))">
                <feather type="more-horizontal" />
            </button>

            <button
                v-for="numPage in pageNumbersRange"
                :key="numPage"
                type="button"
                class="btn btn-sm btn-circle py-1 px-2 mx-1"
                :class="{ 'btn-success': currentPageComputed === numPage }"
                @click.stop="currentPageComputed = numPage">
                {{ numPage }}
            </button>

            <button
                v-if="endPageNumbersRange < nbPages"
                class="btn btn-sm p-0"
                @click="currentPageComputed = endPageNumbersRange"
                v-tooltip="getTooltip($tl('labels.pager.next'))">
                <feather type="more-horizontal" />
            </button>

            <button
                type="button"
                class="btn btn-sm p-0"
                :disabled="currentPageComputed > nbPages - 1"
                @click.stop="++currentPageComputed"
                v-tooltip="getTooltip($tl('labels.pager.nextPage'))">
                <feather type="chevron-right" />
            </button>
            <button
                type="button"
                class="btn btn-sm p-0"
                :disabled="currentPageComputed > nbPages - 1"
                @click.stop="currentPageComputed = nbPages"
                v-tooltip="getTooltip($tl('labels.pager.lastPage'))">
                <feather type="skip-forward" />
            </button>

            <select class="custom-select w-auto mx-2 mb-1" v-model="nbResultsPerPageComputed">
                <option v-for="nbResult in availableNbResultsPerPage" :key="nbResult.value" :value="nbResult.value">
                    {{ nbResult.label }}
                </option>
            </select>

            <div class="d-flex justify-content-between w-100">
                <p class="my-auto" style="min-width: 150px">{{ $tl("labels.pager.nbResultsPerPage") }}</p>
                <p class="my-auto" style="min-width: 185px">
                    {{ $t("labels.pager.currentPageInfo", { currentPage: currentPageComputed, nbPages }) }}
                    {{ $t("labels.pager.nbResults", { nbResults }) }}
                </p>
            </div>
        </div>
        <!-- end:Desktop -->

        <!-- begin:Mobile -->
        <div class="d-flex w-100 none-desk">
            <button
                type="button"
                class="btn btn-sm p-0"
                :disabled="currentPageComputed < 2"
                @click="currentPageComputed = 1"
                v-tooltip="getTooltip($tl('labels.pager.firstPage'))">
                <feather type="skip-back" />
            </button>
            <button
                type="button"
                class="btn btn-sm p-0"
                :disabled="currentPageComputed < 2"
                @click="--currentPageComputed"
                v-tooltip="getTooltip($tl('labels.pager.previousPage'))">
                <feather type="chevron-left" />
            </button>

            <div>
                <p class="my-auto mx-2">
                    {{ $t("labels.pager.currentPageInfo", { currentPage: currentPageComputed, nbPages }) }}
                </p>
            </div>

            <button
                type="button"
                class="btn btn-sm p-0"
                :disabled="currentPageComputed > nbPages - 1"
                @click="++currentPageComputed"
                v-tooltip="getTooltip($tl('labels.pager.nextPage'))">
                <feather type="chevron-right" />
            </button>
            <button
                type="button"
                class="btn btn-sm p-0"
                :disabled="currentPageComputed > nbPages - 1"
                @click="currentPageComputed = nbPages"
                v-tooltip="getTooltip($tl('labels.pager.lastPage'))">
                <feather type="skip-forward" />
            </button>
        </div>
        <!-- end:Mobile -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            additionalNbResultsPerPage: [],
        };
    },
    props: {
        nbPages: {
            type: Number,
            required: true,
        },
        nbResults: {
            type: Number,
            required: true,
        },
        nbResultsPerPage: {
            type: [Number, String],
            default: 12,
        },
        currentPage: {
            type: Number,
            default: 1,
        },
        nbNumPages: {
            type: Number,
            default: 10,
        },
    },
    computed: {
        nbResultsPerPageComputed: {
            get() {
                return this.nbResultsPerPage;
            },
            set(value) {
                this.$emit("set-nb-results-per-page", value);
                this.currentPageComputed = 1;
            },
        },
        currentPageComputed: {
            get() {
                return this.currentPage;
            },
            set(value) {
                this.$emit("set-current-page", value);
            },
        },
        availableNbResultsPerPage() {
            let values = [5, 10, 12, 20].concat(this.additionalNbResultsPerPage);

            if (!values.concat("all").includes(this.nbResultsPerPage)) {
                this.additionalNbResultsPerPage.push(this.nbResultsPerPage);
            }

            values.sort((a, b) => a - b);

            return [
                {
                    value: "all",
                    label: "Tout",
                },
                ...values.map((value) => {
                    return { value, label: value };
                }),
            ];
        },
        startPageNumbersRange() {
            const getStartRange = (multiplier = 1) => {
                if (this.currentPageComputed > this.nbNumPages * multiplier) {
                    return getStartRange(multiplier + 1);
                }

                return (multiplier - 1) * this.nbNumPages + 1;
            };

            return getStartRange();
        },
        endPageNumbersRange() {
            return this.startPageNumbersRange + this.nbNumPages > this.nbPages + 1 ? this.nbPages + 1 : this.startPageNumbersRange + this.nbNumPages;
        },
        pageNumbersRange() {
            return _.range(this.startPageNumbersRange, this.endPageNumbersRange);
        },
    },
};
</script>
