<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Scanner un bon cadeau</h5>
        <div slot="body">
            <LoaderComponent v-if="loading" />
            <div v-else>
                <div v-if="error" class="alert alert-danger">
                    <p>
                        {{ error }}
                    </p>
                </div>
                <div v-else>
                    <qrcode-stream v-if="!showValidateGiftVoucherModal" @decode="findGvSale" @init="onInit" />
                    <validate-gift-voucher-modal
                        v-if="showValidateGiftVoucherModal"
                        :gv_sale="gv_sale"
                        @close="showValidateGiftVoucherModal = false"
                        @updated="$emit('updated')" />
                    <invalid-gift-voucher-modal
                        v-if="showInvalidGiftVoucherModal"
                        :gv_sale="gv_sale"
                        :invalid_reason="invalid_reason"
                        @close="showInvalidGiftVoucherModal = false" />
                </div>
            </div>
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="close">Fermer</button>
        </div>
    </modal>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import InvalidGiftVoucherModal from "./InvalidGiftVoucherModal.vue";
import ValidateGiftVoucherModal from "./ValidateGiftVoucherModal.vue";
import LoaderComponent from "../../LoaderComponent";
import axios from "axios";

export default {
    data() {
        return {
            loading: false,
            error: null,
            showValidateGiftVoucherModal: false,
            showInvalidGiftVoucherModal: false,
            gv_sale: null,
            invalid_reason: null,
        };
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
        findGvSale(token) {
            this.loading = true;
            this.error = null;

            axios
                .get(
                    `/api/gift_vouchers/${this.$route.params.restaurant_id}/sales/token/${token}?include=client,gv_product,gv_sale_product_options,gv_sale_product_options.gv_products_option`
                )
                .then((response) => {
                    this.loading = false;
                    this.gv_sale = response.data.gv_sale;
                    if (response.data.is_valid) this.showValidateGiftVoucherModal = true;
                    else {
                        this.invalid_reason = response.data.invalid_reason;
                        this.showInvalidGiftVoucherModal = true;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = error.response.data.message
                        ? error.response.data.message
                        : "Une erreur est survenue lors de la récupération du bon cadeau";
                });
        },
        onInit(promise) {
            promise.catch((error) => {
                switch (error.name) {
                    case "NotAllowedError":
                        this.error = "Veuillez autoriser l'accès a la caméra.";
                        break;
                    case "NotFoundError":
                        this.error = "Aucune caméra disponible.";
                        break;
                    case "NotSupportedError":
                    case "InsecureContextError":
                        this.error = "Veuillez utiliser cette fonctionnalité dans un contexte sécurisé (HTTPS).";
                        break;
                    case "NotReadableError":
                        this.error = "La caméra est déjà utilisée.";
                        break;
                    case "OverconstrainedError":
                        this.error = "Les caméra disponibles ne sont pas compatibles.";
                        break;
                    case "StreamApiNotSupportedError":
                        this.error = "Votre navigateur ne supporte pas cette fonctionnalité.";
                        break;
                    default:
                        this.error = "Une erreur inconnue est survenue.";
                        break;
                }
            });
        },
    },
    components: {
        QrcodeStream,
        ValidateGiftVoucherModal,
        InvalidGiftVoucherModal,
        LoaderComponent,
    },
};
</script>
