var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "reservation-vue",
      staticStyle: { height: "calc(100vh - 130px) !important" },
    },
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", { staticClass: "h-100" }, [
            _vm.error
              ? _c(
                  "div",
                  { staticClass: "error" },
                  [
                    _c("em", [_vm._v(_vm._s(_vm.error))]),
                    _c("br"),
                    _vm._v(" "),
                    _vm.errorCode === "noServiceFound"
                      ? _c(
                          "router-link",
                          {
                            staticClass: "btn btn-sm btn-success btn-circle",
                            staticStyle: { "margin-top": "15px" },
                            attrs: {
                              to: {
                                name: "booking.restaurants.settings.services.add",
                                params: {
                                  restaurant_id:
                                    _vm.$route.params.restaurant_id,
                                },
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.services.createFirst",
                                    _vm.restaurant_id
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _c("div", { staticClass: "h-100" }, [
                  _c("div", { staticClass: "row m-0 h-100" }, [
                    _c("div", { staticClass: "col-12 h-100 p-0" }, [
                      _vm.restaurant
                        ? _c("div", { staticClass: "h-100" }, [
                            _c(
                              "div",
                              { staticClass: "d-inline align-middle w-100" },
                              [
                                !_vm.isLive && _vm.isInFuture
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-uppercase text-center align-middle d-block off-air",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.booking.services.next",
                                                _vm.restaurant_id
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isLive
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-uppercase text-center align-middle d-block on-air",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.booking.services.live",
                                                _vm.restaurant_id
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-wrap justify-content-between flex-md-nowrap mb-2 pt-2 pl-3 pr-3",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c(
                                      "h6",
                                      {
                                        staticClass: "pt-1",
                                        staticStyle: {
                                          "text-transform": "uppercase",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.displayDate(
                                                _vm.meta.date,
                                                _vm.DATE_MED_DAY_NO_YEAR
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                        _vm.services.length === 1
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "capacite badge badge-secondary",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm.getServiceCategoryLabel(
                                                        _vm.meta
                                                      )
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("show-custom-events-btn", {
                                      staticClass: "none-mobile ml-2",
                                      attrs: {
                                        restaurantId: _vm.restaurant.id,
                                        date: _vm.meta.date,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-inline-block align-middle",
                                  },
                                  [
                                    _vm.hasFeatSeatingPlan
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-inline-block align-middle mr-2 none-mobile",
                                          },
                                          [
                                            _c("toggle-switch", {
                                              staticClass:
                                                "p-0 m-0 d-inline-flex align-middle",
                                              attrs: {
                                                options:
                                                  _vm.toggleSwitchOptions,
                                              },
                                              model: {
                                                value: _vm.contentToDisplay,
                                                callback: function ($$v) {
                                                  _vm.contentToDisplay = $$v
                                                },
                                                expression: "contentToDisplay",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.restaurant.notifications_setting
                                      .enable_qr_code == true
                                      ? _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.getTooltip(
                                                  _vm.$t(
                                                    "labels.booking.reservations.actions.scan"
                                                  )
                                                ),
                                                expression:
                                                  "getTooltip($t('labels.booking.reservations.actions.scan'))",
                                              },
                                            ],
                                            staticClass:
                                              "btn radius-btn-square btn-success scan-btn",
                                            staticStyle: {
                                              "padding-top": "3px",
                                              "padding-left": "4px",
                                            },
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                _vm.showScanReservationModal = true
                                              },
                                            },
                                          },
                                          [
                                            _c("feather", {
                                              staticClass: "text-white",
                                              attrs: { type: "aperture" },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "h-100 d-flex flex-column" },
                              [
                                _vm.services.length > 0
                                  ? _c(
                                      "div",
                                      { staticClass: "h-100" },
                                      [
                                        _vm.contentToDisplay ===
                                        _vm.$t("labels.booking.list.title")
                                          ? _c(
                                              "live-reservations-per-service",
                                              {
                                                attrs: {
                                                  services: _vm.services,
                                                  restaurant: _vm.restaurant,
                                                },
                                                on: {
                                                  "show-service-full":
                                                    function ($event) {
                                                      return _vm.$parent.$emit(
                                                        "show-service-full",
                                                        $event
                                                      )
                                                    },
                                                  "reservation-updated":
                                                    function ($event) {
                                                      return _vm.onReservationUpdated(
                                                        { reservation: $event }
                                                      )
                                                    },
                                                },
                                              }
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.contentToDisplay ===
                                        _vm.$t(
                                          "labels.booking.seatingPlan.title"
                                        )
                                          ? _c("seating-plan-per-service", {
                                              attrs: {
                                                date: _vm.meta.date,
                                                services: _vm.services,
                                                restaurant: _vm.restaurant,
                                                "reservation-added":
                                                  _vm.reservationAdded,
                                                "reservation-updated":
                                                  _vm.reservationUpdated,
                                                addDataKeyToReservationsList: true,
                                              },
                                              on: {
                                                "add-reservation":
                                                  _vm.displayAddReservationModal,
                                                "add-passing-customer":
                                                  function ($event) {
                                                    return _vm.displayAddReservationModal(
                                                      $event,
                                                      true
                                                    )
                                                  },
                                                "go-to-resa-list": function (
                                                  $event
                                                ) {
                                                  _vm.contentToDisplay = _vm.$t(
                                                    "labels.booking.list.title"
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.contentToDisplay ===
                                        _vm.$t("labels.booking.timeline.title")
                                          ? _c("timeline-per-service", {
                                              attrs: {
                                                services: _vm.services,
                                                restaurant: _vm.restaurant,
                                                reservation_date: _vm.meta.date,
                                              },
                                              on: {
                                                "add-reservation":
                                                  _vm.displayAddReservationModal,
                                                "add-passing-customer":
                                                  function ($event) {
                                                    return _vm.displayAddReservationModal(
                                                      $event,
                                                      true
                                                    )
                                                  },
                                                "go-to-resa-list": function (
                                                  $event
                                                ) {
                                                  _vm.contentToDisplay = _vm.$t(
                                                    "labels.booking.list.title"
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _c("div", [
                                      _c("em", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.booking.restaurant.closedForSomeService",
                                              _vm.restaurant_id
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
          ]),
      _vm._v(" "),
      _vm.showScanReservationModal
        ? _c("scan-reservation-modal", {
            ref: "scanResaModal",
            attrs: { reservations: _vm.reservations },
            on: {
              close: function ($event) {
                _vm.showScanReservationModal = false
              },
              "validate-resa": function ($event) {
                return _vm.setResaOver($event)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAddReservationModal && _vm.has_right_to_create_reservation
        ? _c("add-reservation-modal", {
            attrs: {
              tablesIdToPreselect: _vm.selected_tables_id,
              service_id: _vm.selected_service_id,
              slot_id: _vm.selected_slot_id,
              date: _vm.selected_reservation_date,
              restaurant_id: _vm.selected_restaurant_id,
            },
            on: {
              "reservation-saved": function ($event) {
                _vm.showAddReservationModal = false
              },
              close: function ($event) {
                _vm.showAddReservationModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAddPassingCustomerModal && _vm.has_right_to_create_reservation
        ? _c("add-passing-customer-modal", {
            attrs: {
              tablesIdToPreselect: _vm.selected_tables_id,
              serviceId: _vm.selected_service_id,
              restaurantId: Number.parseInt(_vm.selected_restaurant_id),
              date: _vm.selected_reservation_date,
            },
            on: {
              saved: function ($event) {
                _vm.showAddPassingCustomerModal = false
              },
              close: function ($event) {
                _vm.showAddPassingCustomerModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }