<template>
    <div>
        <div class="d-inline-block">
            <template v-if="client !== null">
                <show-client-type :client-type="client.type" />
                <show-client-loyalty-level v-if="loyaltyLevel !== null" :client-loyalty-level="loyaltyLevel" />
                <feather v-if="client.is_vip" type="star" class="feather-orange" v-tooltip="getTooltip($tl('labels.clients.vip'))" />
                <feather v-if="client.is_risky" type="alert-triangle" class="feather-orange" v-tooltip="getTooltip($tl('labels.clients.risky'))" />
            </template>
            <slot name="icons-start"></slot>
            <feather v-if="![null, ''].includes(publicComment)" type="message-circle" class="feather-blue" v-tooltip="getTooltip(publicComment)" />
            <feather
                v-if="![null, ''].includes(privateComment)"
                type="message-circle"
                class="feather-orange"
                v-tooltip="getTooltip(privateComment)" />
        </div>
        <component v-if="!hideName && client !== null" :is="link !== null ? 'router-link' : 'div'" :to="link" class="d-inline-block m-auto">
            <span class="none-tablet">{{ civility }} </span>{{ client.firstname }}
            <span class="text-uppercase">{{ client.lastname }}</span>
            <feather
                v-if="showNoshows && client.noshows > 0"
                type="alert-triangle"
                class="feather-red"
                v-tooltip="getTooltip(`${client.noshows} ${$tl('labels.clients.noshow')}`)" />
            <slot name="icons-end"></slot>
        </component>
    </div>
</template>

<script>
import showClientLoyaltyLevel from "./showClientLoyaltyLevel.vue";
import showClientType from "./showClientType.vue";
import ClientCivilityEnum from "../../../mixins/enums/ClientCivilityEnum.js";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";

export default {
    props: {
        client: {
            type: Object,
            default: null,
        },
        moduleEnum: {
            type: Object,
            default: null,
        },
        publicComment: {
            type: String,
            default: null,
        },
        privateComment: {
            type: String,
            default: null,
        },
        longCivility: {
            type: Boolean,
            default: false,
        },
        showNoshows: {
            type: Boolean,
            default: false,
        },
        hideName: {
            type: Boolean,
            default: false,
        },
        link: {
            type: [String, Object],
            default: null,
        },
    },
    mixins: [ClientCivilityEnum, ModuleTypesEnum],
    computed: {
        civility() {
            return this.longCivility && this.client !== null
                ? this.getClientCivilityLabelLong(this.client.civility)
                : this.getClientCivilityLabelShort(this.client.civility);
        },
        loyaltyLevel() {
            if (
                this.client === null ||
                this.moduleEnum === null ||
                !this.inEnum(this.moduleEnum.value, [this.MODULE_TYPE_BOOKING, this.MODULE_TYPE_CLICK_AND_COLLECT, this.MODULE_TYPE_GIFT_VOUCHERS])
            ) {
                return null;
            }

            return this.client[`${this.moduleEnum.urlValue}_loyalty_level`];
        },
    },
    components: {
        showClientType,
        showClientLoyaltyLevel,
    },
};
</script>
