<template>
    <modal @close="closeFromModal">
        <h5 slot="header" v-html="headerText"></h5>
        <div slot="body">
            <LoaderComponent v-if="loading || loadingMergeTags" />
            <template v-else>
                <div class="row" v-if="step === 1">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4 mb-3">
                            <p>
                                {{ $tl("infos.booking.reservations.confirmDeleteSlots") }}
                            </p>
                            <table class="table table-book table-striped table-sm">
                                <thead class="border-bottom">
                                    <tr>
                                        <th>
                                            <feather type="user" class="none-desk feather-20" />
                                            <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.client") }}</span>
                                        </th>
                                        <th>
                                            <feather type="users" class="none-desk feather-20" />
                                            <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.pax") }}</span>
                                        </th>
                                        <th>
                                            <feather type="clock" class="none-desk feather-20" />
                                            <span class="none-mobile">{{ $tl("labels.form.date") }}</span>
                                        </th>
                                        <th>
                                            <feather type="monitor" class="none-desk feather-20" />
                                            <span class="none-mobile">{{ $tl("labels.form.phoneNumber") }}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="resa in reservations_" :key="resa.id">
                                        <td>
                                            <inline-client
                                                v-if="resa.client !== null"
                                                :client="resa.client"
                                                :module-enum="MODULE_TYPE_BOOKING"
                                                :public-comment="resa.reservation_comment"
                                                :private-comment="resa.restaurant_comment"
                                                :link="{ name: 'booking.clients.form', params: { client_id: resa.client.id } }">
                                                <template v-slot:icons-start>
                                                    <feather v-if="resa.gift" type="tag" class="feather-blue" v-tooltip="getTooltip(resa.gift)" />
                                                </template>
                                            </inline-client>
                                        </td>
                                        <td class="table-text-center">
                                            s
                                            {{ resa.nb_pers * 1 + resa.nb_children * 1 }}
                                        </td>
                                        <td>
                                            {{ capitalize(displayDate(resa.reservation_datetime, DATETIME_SHORT_DAY)) }}
                                        </td>
                                        <td>
                                            <a v-if="resa.client !== null" :href="`tel:${resa.client.tel}`">{{ resa.client.tel }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="step === 2">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4 mb-3">
                            <form action="javascript:void()" class="container-fluid">
                                <div class="row">
                                    <div class="col-md-5">
                                        <label for="txt-closure-object">{{ $tl("labels.form.object") }} *</label>
                                    </div>
                                    <div class="col-md-7 mb-1">
                                        <input
                                            type="text"
                                            v-model="cancelData.message_object"
                                            required
                                            id="txt-closure-object"
                                            class="form-control" />
                                    </div>
                                    <div class="col-md-5">
                                        <label for="txt-closure-body">{{ $tl("labels.form.messageContent") }} *</label>
                                    </div>
                                    <div class="col-md-7">
                                        <editor-with-merge-tags v-model="cancelData.message_body" :merge-tags="mergeTags"></editor-with-merge-tags>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button :disabled="loading" class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="close">
                {{ $tl("labels.form.actions.cancel") }}
            </button>
            <button :disabled="loading" class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="step = 1" v-if="step === 2">
                {{ $tl("labels.form.actions.back") }}
            </button>
            <button :disabled="loading" class="btn btn-success btn-sm btn-circle" @click="$emit('confirmed-without-cancel')" v-if="step === 1">
                {{ $tl("labels.form.actions.confirmNoCancel") }}
            </button>
            <button :disabled="loading" class="btn btn-success btn-sm btn-circle" @click="continueAndCancel">
                {{ $tl(`labels.form.actions.${step === 1 ? "cancelAllBookings" : "confirm"}`) }}
            </button>
        </div>
    </modal>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent.vue";
import editorWithMergeTags from "../../components/forms/editorWithMergeTags.vue";
import inlineClient from "../Default/Clients/inlineClient.vue";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum.js";

export default {
    data() {
        return {
            error: null,
            loadingMergeTags: false,
            step: 1,
            cancelData: {
                message_object: "",
                message_body: "",
            },
            mergeTags: [],
        };
    },
    props: {
        headerText: {
            required: true,
            type: String,
        },
        reservations: {
            required: true,
            type: Array,
        },
        loading: {
            required: true,
            type: Boolean,
        },
    },
    mixins: [ModuleTypesEnum],
    computed: {
        reservations_() {
            return this.reservations.sort((a, b) => {
                const resaDateTimeA = this.getDateTime(a.reservation_datetime);
                const resaDateTimeB = this.getDateTime(b.reservation_datetime);
                if (resaDateTimeA < resaDateTimeB) return -1;
                if (resaDateTimeA > resaDateTimeB) return 1;
                return 0;
            });
        },
        restaurantId() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        fetchMailMergeTags() {
            this.loadingMergeTags = true;

            this.httpGet(`/api/restaurants/${this.restaurantId}/configs?include=notifications_setting.mails_merge_tags`)
                .then((response) => {
                    if (response !== false) {
                        this.mergeTags = response.data.notifications_setting.mails_merge_tags.client_resa_canceled;
                    }
                })
                .finally(() => {
                    this.loadingMergeTags = false;
                });
        },
        continueAndCancel() {
            if (this.step === 2) {
                this.$emit("confirmed", this.cancelData);
                return;
            }
            this.step = 2;
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        LoaderComponent,
        editorWithMergeTags,
        inlineClient,
    },
    created() {
        this.fetchMailMergeTags();
    },
};
</script>
