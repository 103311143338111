<template>
    <modal>
        <h5 slot="header">Zones de livraison dessinées</h5>
        <div slot="body">
            <small class="text-muted d-block mb-3">
                Attention, si certaines zones de livraison se chevauchent, la zone dont les frais de livraison sont les plus élevés sera prise en
                compte.
            </small>
            <GoogleMap ref="map" :stopLoader="false" :mapZoom="12" :mapCenter="mapCenter" @initialized="onMapInitialized" />
            <template v-if="!loading">
                <div class="mt-2">
                    <button class="btn btn-success btn-circle btn-sm" :disabled="!editable" @click="showAddDeliveryAreaModal()">
                        <feather type="plus" class="mr-1" />Ajouter une zone de livraison
                    </button>
                </div>
                <ShowErrors class="d-block mt-1 mb-2" :errors="errors" errorKey="*" />
                <template v-if="deliveryAreasFormatted.length > 0">
                    <table class="table table-sm table-striped border-bottom">
                        <thead class="border-bottom">
                            <tr>
                                <th>Nom</th>
                                <th>Couleur</th>
                                <th>Prix (TTC)</th>
                                <th v-if="!isTvaDisabled">TVA</th>
                                <th>Livraison gratuite</th>
                                <th>Montant minimum</th>
                                <th>Créneaux</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(area, index) in deliveryAreasFormatted.map((d) => d.deliveryAreaObj)" :key="index">
                                <td>{{ area.name }}</td>
                                <td>
                                    <div style="height: 20px; width: 80px" :style="`background-color: ${area.drawn_color}`"></div>
                                </td>
                                <td>
                                    {{ area.price !== null && !area.is_free ? formatCurrency(area.price) : "Gratuite" }}
                                </td>
                                <td v-if="!isTvaDisabled">
                                    {{ area.tax_rate !== null && !area.is_free ? `${area.tax_rate} %` : "--" }}
                                </td>
                                <td>
                                    {{ area.free_above !== null ? formatCurrency(area.free_above) : "--" }}
                                </td>
                                <td>
                                    {{ area.min_amount !== null ? formatCurrency(area.min_amount) : "--" }}
                                </td>
                                <td>
                                    {{ area.use_default_delivery_slots ? "Tous" : "Limité" }}
                                </td>
                                <td>
                                    <button
                                        class="btn btn-sm btn-success btn-square"
                                        :disabled="!editable"
                                        @click="showAddDeliveryAreaModal(index)"
                                        v-tooltip="getTooltip('Éditer')">
                                        <feather type="edit" />
                                    </button>
                                    <button
                                        :disabled="!editable"
                                        @click="deleteDeliveryArea(index, area.name)"
                                        class="btn btn-sm btn-danger btn-square"
                                        v-tooltip="getTooltip('Supprimer')">
                                        <feather type="trash-2" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
                <addClickAndCollectDeliveryModal
                    v-if="addDeliveryArea.showAddDeliveryAreaModal"
                    :deliveryArea="addDeliveryArea.deliveryArea"
                    :do_not_save="true"
                    @saved="createNewArea"
                    @close="addDeliveryArea.showAddDeliveryAreaModal = false" />
            </template>
        </div>
        <div slot="footer" class="d-flex">
            <button type="button" class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="close">Annuler</button>
            <button type="button" class="btn btn-sm btn-success btn-circle ml-2" @click="saveAreas">Enregistrer</button>
        </div>
    </modal>
</template>

<script>
import ShowErrors from "../../../errors/ShowErrors";
import GoogleMap from "../../../Maps/GoogleMap";
import addClickAndCollectDeliveryModal from "./addClickAndCollectDeliveryModal";

export default {
    data() {
        return {
            loading: false,
            errors: null,
            colorsList: [
                "#F8B195",
                "#F67280",
                "#C06C84",
                "#6C5B7B",
                "#355C7D",
                "#99B898",
                "#FECEAB",
                "#FF847C",
                "#E84A5F",
                "#2A363B",
                "#A8E6CE",
                "#DCEDC2",
                "#F9CDAD",
                "#FFAAA6",
                "#FF8C94",
                "#9DE0AD",
                "#45ADA8",
                "#547980",
                "#c06c84",
                "#594F4F",
            ],
            pointRadius: 50,
            areaRadius: 1000,
            shapePointOptions: {
                strokeOpacity: 0.8,
                strokeWeight: 1,
                fillOpacity: 1,
                zIndex: 1, // Points in foreground
            },
            shapeAreaOptions: {
                strokeOpacity: 0.8,
                strokeWeight: 1,
                fillOpacity: 0.35,
                zIndex: 0, // Area in background
            },
            deliveryAreasFormatted: [],
            addDeliveryArea: {
                deliveryArea: null,
                areaIndex: null,
                showAddDeliveryAreaModal: false,
            },
        };
    },
    props: {
        config: {
            type: Object,
            required: true,
        },
        editable: {
            default: false,
        },
        getDefaultDeliveryArea: {
            type: Function,
            required: true,
        },
        isTvaDisabled: {
            default: false,
        },
    },
    computed: {
        cc_configuration_id() {
            return this.config ? this.config.id : null;
        },
        deliveryAreas() {
            if (!this.config || !this.config.deliveryAreas || !this.config.deliveryAreas.data) return [];
            return this.config.deliveryAreas.data.filter((area) => area.type == this.config.delivery_areas_type);
        },
        mapCenter() {
            if (this.config && this.config.delivery_departure_lat && this.config.delivery_departure_lng)
                return {
                    lat: parseFloat(this.config.delivery_departure_lat),
                    lng: parseFloat(this.config.delivery_departure_lng),
                };
            return undefined;
        },
        restaurantAddress() {
            if (
                this.config &&
                this.config.delivery_departure_address &&
                this.config.delivery_departure_postal_code &&
                this.config.delivery_departure_city &&
                this.config.delivery_departure_country
            )
                return `${this.config.delivery_departure_address}, ${this.config.delivery_departure_postal_code} ${this.config.delivery_departure_city}`;
            return undefined;
        },
    },
    methods: {
        checkForCollisionOnAreaDragged(baseAreaCoordinates, newAreaCoordinates) {
            // const checkForCollision = this.$refs.map.getMapLibrary().geometry.poly.containsLocation;
            // return this.deliveryAreasFormatted.some(area => {
            //     return area.points.some(point => {
            //         return checkForCollision()
            //     })
            // });
        },
        // Get next available color
        getNextColor() {
            return this.colorsList.find((color) => !this.deliveryAreasFormatted.some((d) => d.deliveryAreaObj.drawn_color == color)) || "#1A73E8";
        },
        // Show modal for adding delivery area
        showAddDeliveryAreaModal(areaIndex = null) {
            let area = null;
            if (areaIndex !== null && this.deliveryAreasFormatted.length > areaIndex) {
                area = this.$_.cloneDeep(this.deliveryAreasFormatted[areaIndex].deliveryAreaObj);
                this.addDeliveryArea.areaIndex = areaIndex;
            }
            this.addDeliveryArea.deliveryArea = area || this.getDefaultDeliveryArea();
            if (!area) {
                this.addDeliveryArea.deliveryArea.drawn_color = this.getNextColor();
                this.addDeliveryArea.deliveryArea.type = this.config.delivery_areas_type;
                const center = this.$refs.map.getMap().getCenter();
                this.addDeliveryArea.deliveryArea.center_lat = center.lat();
                this.addDeliveryArea.deliveryArea.center_lng = center.lng();
            }
            this.addDeliveryArea.showAddDeliveryAreaModal = true;
        },
        // Create and draw new area based on modal filled info
        createNewArea(area) {
            let baseArea = {
                points: [],
                shape: null,
                coordinates: this.$refs.map.getMap().getCenter(),
            };
            if (this.addDeliveryArea.areaIndex !== null) baseArea = this.deliveryAreasFormatted[this.addDeliveryArea.areaIndex];
            let deliveryArea = {
                deliveryAreaObj: {
                    ...area,
                    price: area.price ? area.price / 100 : null,
                    free_above: area.free_above ? area.free_above / 100 : null,
                    min_amount: area.min_amount ? area.min_amount / 100 : null,
                },
                coordinates: baseArea.coordinates,
                points: baseArea.points,
                shape: baseArea.shape,
            };
            this.addDeliveryArea.deliveryArea = null;
            this.addDeliveryArea.showAddDeliveryAreaModal = false;

            // Create new Area
            if (this.addDeliveryArea.areaIndex === null) {
                this.$refs.map
                    .getCirclePoints({ lat: deliveryArea.coordinates.lat(), lng: deliveryArea.coordinates.lng() }, this.areaRadius, true, 32)
                    .forEach((point) => {
                        deliveryArea.points.push(
                            this.drawPoint(deliveryArea, {
                                shape: undefined,
                                coordinates: point,
                            })
                        );
                    });
                this.drawArea(deliveryArea);

                this.deliveryAreasFormatted.push(deliveryArea);
            } else {
                // Editing area
                this.deliveryAreasFormatted.splice(this.addDeliveryArea.areaIndex, 1, deliveryArea);
            }
            this.addDeliveryArea.areaIndex = null;
        },
        // Delete delivery area
        deleteDeliveryArea(index, name) {
            if (!confirm(`Êtes vous sûr de vouloir supprimer la zone "${name}" ?`)) return;
            this.deliveryAreasFormatted.splice(index, 1).forEach((deleted) => {
                deleted.shape.setMap(null);
                deleted.points.forEach((point) => point.shape.setMap(null));
            });
        },
        // Call by google.maps when point is dragged
        onPointDragged(area, point) {
            // When point is dragged, draw new point with new center
            // and draw area for update
            return (e) => {
                point.coordinates = e.latLng;
                const points = this.$refs.map.getCirclePoints(
                    { lat: point.coordinates.lat(), lng: point.coordinates.lng() },
                    this.pointRadius,
                    true,
                    32
                );
                point.shape.setPaths(points);
                area.shape.setPaths(area.points.map((p) => p.coordinates));
            };
        },
        // Call by google.maps when area is dragged
        onAreaDragged(area) {
            // When area is dragged, compute new area's & points' coordinates
            // and draw points & area for update
            return (e) => {
                const diffLat = area.coordinates.lat() - e.latLng.lat();
                const diffLng = area.coordinates.lng() - e.latLng.lng();
                area.points.forEach((point) => {
                    const newPointCoordinates = {
                        lat: point.coordinates.lat() - diffLat,
                        lng: point.coordinates.lng() - diffLng,
                    };
                    point.coordinates = this.$refs.map.getLatLngObj(newPointCoordinates.lat, newPointCoordinates.lng);
                    const points = this.$refs.map.getCirclePoints(newPointCoordinates, this.pointRadius, true, 32);
                    point.shape.setPaths(points);
                });
                area.shape.setPath(area.points.map((p) => p.coordinates));
                area.coordinates = e.latLng;
            };
        },
        // Draw Area (without points) on map
        drawArea(area, shapeOptions = null) {
            let shapeOpts = { ...this.shapeAreaOptions };
            this.$_.assign(shapeOpts, shapeOptions);
            area.shape = this.$refs.map.drawPolygon({
                paths: [area.points.map((p) => p.coordinates)],
                ...shapeOpts,
                strokeColor: area.deliveryAreaObj.drawn_color,
                fillColor: area.deliveryAreaObj.drawn_color,
                draggable: this.editable,
            });
            area.shape.addListener("drag", this.onAreaDragged(area));
            this.$refs.map.addInfoWindow(area.shape, area.deliveryAreaObj.name);
        },
        // Draw a single point on the map
        drawPoint(area, point, shapeOptions = null) {
            let shapeOpts = { ...this.shapePointOptions };
            this.$_.assign(shapeOpts, shapeOptions);
            const points = this.$refs.map.getCirclePoints({ lat: point.coordinates.lat(), lng: point.coordinates.lng() }, this.pointRadius, true, 32);
            let shape = this.$refs.map.drawPolygon({
                paths: [points],
                ...shapeOpts,
                strokeColor: area.deliveryAreaObj.drawn_color,
                fillColor: "#FFFFFF",
                draggable: this.editable,
            });
            let pointData = {
                shape,
                coordinates: point.coordinates,
            };
            shape.addListener("drag", this.onPointDragged(area, pointData));
            return pointData;
        },
        // Format delivery areas for this component
        formatDeliveryAreas() {
            if (!this.config || !this.config.deliveryAreas || !this.config.deliveryAreas.data) return;
            this.config.deliveryAreas.data
                .filter((area) => area.type == this.config.delivery_areas_type)
                .forEach((deliveryArea) => {
                    this.deliveryAreasFormatted.push({
                        deliveryAreaObj: deliveryArea,
                        shape: undefined,
                        coordinates: this.$refs.map.getLatLngObj(
                            Number.parseFloat(deliveryArea.center_lat),
                            Number.parseFloat(deliveryArea.center_lng)
                        ),
                        points: deliveryArea.gps_points.data.map((point) => {
                            return {
                                shape: undefined,
                                coordinates: this.$refs.map.getLatLngObj(Number.parseFloat(point.lat), Number.parseFloat(point.lng)),
                            };
                        }),
                    });
                });
        },
        formatDeliveryAreasForSave() {
            return this.deliveryAreasFormatted.map((area) => {
                return {
                    ...area.deliveryAreaObj,
                    price: area.deliveryAreaObj.price !== null ? area.deliveryAreaObj.price * 100 : null,
                    free_above: area.deliveryAreaObj.free_above !== null ? area.deliveryAreaObj.free_above * 100 : null,
                    min_amount: area.deliveryAreaObj.min_amount !== null ? area.deliveryAreaObj.min_amount * 100 : null,
                    delivery_slots: {
                        data: area.deliveryAreaObj.delivery_slots.data.map((slot) => {
                            if (slot.cc_delivery_slot_id) return { id: slot.cc_delivery_slot_id };
                            return { id: slot.id };
                        }),
                    },
                    center_lat: area.coordinates.lat(),
                    center_lng: area.coordinates.lng(),
                    gps_points: area.points.map((point) => {
                        return {
                            lat: point.coordinates.lat(),
                            lng: point.coordinates.lng(),
                        };
                    }),
                };
            });
        },
        // Initialize the map
        onMapInitialized() {
            // Create "this.deliveryAreasFormatted"
            this.formatDeliveryAreas();

            this.$nextTick(() => {
                // Draw existing delivery areas
                this.deliveryAreasFormatted.forEach((deliveryArea) => {
                    deliveryArea.points = deliveryArea.points.map((point) => this.drawPoint(deliveryArea, point));
                    this.drawArea(deliveryArea);
                });

                // Place Marker for restaurant address
                if (this.restaurantAddress) {
                    let marker = this.$refs.map.addMarker({
                        position: this.mapCenter,
                        title: this.restaurantAddress,
                    });
                    this.$refs.map.addInfoWindow(marker, this.restaurantAddress);
                }

                setTimeout(() => {
                    this.$refs.map.setLoading(false);
                }, 500);
            });
        },
        setLoading(newVal) {
            this.loading = newVal;
            this.$refs.map.setLoading(newVal);
        },
        // Save areas in DB
        saveAreas() {
            this.setLoading(true);
            this.errors = null;

            axios
                .post(`/api/click_and_collect/${this.$route.params.restaurant_id}/drawnDeliveryAreas`, {
                    delivery_areas: this.formatDeliveryAreasForSave(),
                })
                .then((response) => {
                    this.setLoading(false);
                    this.$emit("saved");
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                })
                .catch((error) => {
                    this.setLoading(false);
                    if (error.response && error.response.data && error.response.data.errors) this.errors = error.response.data.errors;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        close() {
            this.$emit("close");
        },
    },
    components: {
        ShowErrors,
        GoogleMap,
        addClickAndCollectDeliveryModal,
    },
};
</script>
