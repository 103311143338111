var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-2" },
    [
      _c("h5", [_vm._v("Montant")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "radio my-3" },
        [
          _c(
            "radio-button-component",
            {
              attrs: { inputValue: _vm.DISCOUNT_TYPE_AMOUNT_OFF.value },
              model: {
                value: _vm.form.discount_type,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "discount_type", $$v)
                },
                expression: "form.discount_type",
              },
            },
            [_vm._v("Montant fixe")]
          ),
          _vm._v(" "),
          _c(
            "radio-button-component",
            {
              attrs: { inputValue: _vm.DISCOUNT_TYPE_PERCENT_OFF.value },
              model: {
                value: _vm.form.discount_type,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "discount_type", $$v)
                },
                expression: "form.discount_type",
              },
            },
            [_vm._v("\n            Réduction en pourcentage\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.form.discount_type === _vm.DISCOUNT_TYPE_AMOUNT_OFF.value
        ? _c("input-component", {
            attrs: {
              "input-name": _vm.DISCOUNT_TYPE_AMOUNT_OFF.value,
              "input-type": "number",
              label: "Montant (en euros)",
              "append-element": "€",
              required: "",
              min: 1,
              "form-errors": _vm.errors.form,
              inputClasses: "w-80",
            },
            model: {
              value: _vm.form.amount_off,
              callback: function ($$v) {
                _vm.$set(_vm.form, "amount_off", $$v)
              },
              expression: "form.amount_off",
            },
          })
        : _c("input-component", {
            attrs: {
              "input-name": _vm.DISCOUNT_TYPE_PERCENT_OFF.value,
              "input-type": "number",
              label: "Réduction (en %)",
              "append-element": "%",
              required: "",
              min: 1,
              max: 100,
              "form-errors": _vm.errors.form,
              inputClasses: "w-80",
            },
            model: {
              value: _vm.form.percent_off,
              callback: function ($$v) {
                _vm.$set(_vm.form, "percent_off", $$v)
              },
              expression: "form.percent_off",
            },
          }),
      _vm._v(" "),
      _c("switch-input-component", {
        attrs: {
          label: "Définir un nombre maximum d'utilisation",
          "input-name": "switchNbUseMax",
        },
        model: {
          value: _vm.canPreciseMaxRedemption,
          callback: function ($$v) {
            _vm.canPreciseMaxRedemption = $$v
          },
          expression: "canPreciseMaxRedemption",
        },
      }),
      _vm._v(" "),
      _vm.canPreciseMaxRedemption
        ? _c("input-component", {
            attrs: {
              required: "",
              "input-name": "max_redemptions",
              "input-type": "number",
              label: "Nombre d'utilisations maximum pour le code promo",
            },
            model: {
              value: _vm.form.max_redemptions,
              callback: function ($$v) {
                _vm.$set(_vm.form, "max_redemptions", $$v)
              },
              expression: "form.max_redemptions",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("switch-input-component", {
        attrs: {
          label: "Définir un nombre maximum d'utilisation par client",
          "input-name": "switchNbUseMaxPerUser",
          min: 1,
        },
        model: {
          value: _vm.canPreciseMaxRedemptionPerUser,
          callback: function ($$v) {
            _vm.canPreciseMaxRedemptionPerUser = $$v
          },
          expression: "canPreciseMaxRedemptionPerUser",
        },
      }),
      _vm._v(" "),
      _vm.canPreciseMaxRedemptionPerUser
        ? _c("input-component", {
            attrs: {
              required: "",
              "input-name": "maxRedemptionPerUSer",
              "input-type": "number",
              min: 1,
              label:
                "Nombre d'utilisations maximum par client pour le code promo",
            },
            model: {
              value: _vm.form.max_redemptions_by_user,
              callback: function ($$v) {
                _vm.$set(_vm.form, "max_redemptions_by_user", $$v)
              },
              expression: "form.max_redemptions_by_user",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }