var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row p-1" }, [
      _c("div", { staticClass: "col-12 text-muted" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.$tl("labels.booking.reservations.add.nbPers")) +
            "\n        "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 mt-2" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 d-flex justify-content-between" },
            _vm._l(_vm.paxes1, function (n) {
              return _c(
                "button",
                {
                  key: n,
                  staticClass: "btn btn-select-pax",
                  class: { "btn-success": n === _vm.reservation.nb_pers },
                  on: {
                    click: function ($event) {
                      return _vm.clickPax(n)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(n) +
                      "\n                    "
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 d-flex justify-content-between mt-3" },
            _vm._l(_vm.paxes2, function (n) {
              return _c(
                "button",
                {
                  key: n,
                  staticClass: "btn btn-select-pax",
                  class: { "btn-success": n === _vm.reservation.nb_pers },
                  on: {
                    click: function ($event) {
                      return _vm.clickPax(n)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(n) +
                      "\n                    "
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 d-flex justify-content-between mt-3" },
            [
              _vm._l(_vm.paxes3, function (n) {
                return _c(
                  "button",
                  {
                    key: n,
                    staticClass: "btn btn-select-pax",
                    class: { "btn-success": n === _vm.reservation.nb_pers },
                    on: {
                      click: function ($event) {
                        return _vm.clickPax(n)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(n) +
                        "\n                    "
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              !_vm.showMorePax
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-select-pax",
                      on: {
                        click: function ($event) {
                          return _vm.clickPax("+14")
                        },
                      },
                    },
                    [_vm._v("+14")]
                  )
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.reservation.nb_pers,
                        expression: "reservation.nb_pers",
                        modifiers: { number: true },
                      },
                    ],
                    ref: "input-more-max",
                    staticClass: "form-control btn-select-pax text-center",
                    attrs: { type: "number", step: "1" },
                    domProps: { value: _vm.reservation.nb_pers },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.reservation,
                          "nb_pers",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 text-muted d-flex align-items-center mt-3" },
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$tl("labels.booking.reservations.add.nbChildren")) +
              "\n            "
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.reservation.nb_children,
                expression: "reservation.nb_children",
                modifiers: { number: true },
              },
            ],
            staticClass: "form-control ml-2 w-auto",
            attrs: { type: "number", step: "1" },
            domProps: { value: _vm.reservation.nb_children },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(
                  _vm.reservation,
                  "nb_children",
                  _vm._n($event.target.value)
                )
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }