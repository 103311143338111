<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Ajouter une option</h5>
        <div slot="body">
            <add-product-option
                ref="addProductOptionComponent"
                :product_option="product_option"
                @saved="$emit('saved', $event)" />
        </div>
        <div slot="footer" class="d-flex">
            <button
                type="button"
                class="modal-default-button btn btn-sm btn-secondary btn-circle"
                @click="$emit('close')">
                Annuler
            </button>
            <button
                type="button"
                class="btn btn-sm btn-success btn-circle ml-2"
                @click="$refs.addProductOptionComponent.save()">
                Enregistrer
            </button>
        </div>
    </modal>
</template>

<script>
import addProductOption from "../../giftVouchers/addProductOption.vue";

export default {
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    props: {
        product_option: {
            type: Object,
            required: true,
        },
    },
    components: {
        addProductOption,
    },
};
</script>
