<template>
    <modal @close="closeFromModal" style="overflow: auto" :modalContainerStyle="'overflow: visible'">
        <h5 slot="header">{{ $tl(`labels.booking.reservations.${status}Modal.title`) }}</h5>
        <div slot="body">
            <loader-component v-if="isLoading" />
            <template v-else>
                <p>{{ $tl(`labels.booking.reservations.${status}Modal.ask`) }}</p>
                <switch-input-component v-model="canSendMail" :disabled="forceSilent || forceVerbose" v-tooltip="getTooltip(getLabel)">
                    <template v-slot:label>
                        <div>{{ $tl(`labels.booking.reservations.${status}Modal.sendEmail`) }}</div>
                    </template>
                </switch-input-component>
            </template>
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button type="button" :disabled="isLoading" class="btn btn-secondary btn-circle btn btn-sm" @click="close">
                {{ $tl("labels.form.actions.cancel") }}
            </button>
            <button type="button" :disabled="isLoading" class="btn btn-success btn-sm btn-circle" @click="cancelReservation">
                {{ $tl("labels.form.actions.validate") }}
            </button>
        </div>
    </modal>
</template>

<script>
import LoaderComponent from "../../LoaderComponent.vue";
import SwitchInputComponent from "../../../components/forms/SwitchInputComponent.vue";
import NotificationTypesEnum from "../../../mixins/enums/NotificationTypesEnum.js";
import ReservationStatusEnum from "../../../mixins/enums/booking/ReservationStatusEnum.js";
import ReservationIssuerEnum from "../../../mixins/enums/booking/ReservationIssuerEnum.js";

export default {
    components: {
        LoaderComponent,
        SwitchInputComponent,
    },
    props: {
        reservationId: {
            type: Number,
            required: true,
        },
        client: {
            type: Object,
            default: null,
        },
        status: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            canSendMailData: true,
            forceVerbose: false,
        };
    },
    mixins: [NotificationTypesEnum, ReservationStatusEnum, ReservationIssuerEnum],
    computed: {
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        canSendMail: {
            get() {
                if (this.forceVerbose) {
                    return true;
                }

                if (this.forceSilent) {
                    return false;
                }

                return this.canSendMailData;
            },
            set(newVal) {
                if (this.forceSilent || this.forceVerbose) {
                    return;
                }

                this.canSendMailData = newVal;
            },
        },
        forceSilent() {
            const restaurant = this.$store.getters["restaurants/findRestaurantById"](this.restaurantId);

            if (!restaurant || !restaurant.notifications_setting || !this.client) {
                return true;
            }

            switch (this.status) {
                case this.RESERVATION_STATUS_CANCELED.value:
                    return !this.doesClientHaveValidChannelForNotificationType(
                        restaurant.notifications_setting.client_resa_canceled_mode,
                        this.client
                    );
                case this.RESERVATION_STATUS_REFUSED.value:
                    return !this.doesClientHaveValidChannelForNotificationType(
                        restaurant.notifications_setting.client_resa_refused_mode,
                        this.client
                    );
                default:
                    return false;
            }
        },
    },
    methods: {
        fetchReservation() {
            this.isLoading = true;

            this.httpGet(`/api/restaurants/${this.restaurantId}/reservations/${this.reservationId}`)
                .then((response) => {
                    if (response !== false) {
                        this.forceVerbose = response.data.created_from === this.RESERVATION_ISSUER_GOOGLE_RESERVE.value;
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        cancelReservation() {
            this.isLoading = true;

            this.httpPut(
                `/api/restaurants/${this.restaurantId}/reservations/${this.reservationId}/status?status=${this.status}&silent=${!this.canSendMail}`,
                {},
                {
                    defaultMessage: this.$tl("success.booking.reservations.updated"),
                    defaultErrorMessage: this.$tl("errors.booking.reservations.notUpdated"),
                }
            ).finally(() => {
                this.isLoading = false;
                this.close();
            });
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
        getLabel() {
            if (this.forceSilent) {
                return this.$tl("notices.booking.clients.cannotBeNotified");
            }
            if (this.forceVerbose) {
                return this.$tl("notices.booking.clients.cannotBeUnNotifiedGoogle");
            }
            return "";
        },
    },
    created() {
        this.fetchReservation();
    },
};
</script>
