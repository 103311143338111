export const getters = {
    restaurants(state, getters, rootState) {
        return state.restaurants;
    },
    findRestaurantById(state, getters, rootState) {
        return (id) => {
            return state.restaurants.find((restaurant) => {
                return restaurant.id == id;
            });
        };
    },
    getRestaurantsByService(state, getters, rootState) {
        return (service) => {
            const subscriptions =
                typeof rootState.users.ownerSubscriptions === "object"
                    ? Object.values(rootState.users.ownerSubscriptions)
                    : rootState.users.ownerSubscriptions;
            if (!subscriptions) return [];
            const restaurantsId = subscriptions
                .filter((s) => ["active", "trialing", "past_due", "unpaid"].includes(s.status) && s.module.type === service)
                .map((s) => s.restaurant_id);

            return state.restaurants.filter((r) => restaurantsId.includes(r.id));
        };
    },
    getCGV(state, getters, rootState) {
        return (restaurant_id) => {
            const restaurant = state.restaurants.find((restaurant) => {
                return restaurant.id == restaurant_id;
            });

            return typeof restaurant !== "undefined" ? restaurant.cgv : null;
        };
    },
    hasModule(state, getters) {
        return (module, restaurant_id) => getters.getRestaurantsByService(module).some((r) => r.id == restaurant_id);
    },
    isTvaDisabled(state) {
        return (restaurant_id) => {
            const restaurant = state.restaurants.find((restaurant) => restaurant.id == restaurant_id);

            return typeof restaurant !== "undefined" ? restaurant.tva_disabled : false;
        };
    },
    currency(state) {
        return (restaurant_id) => {
            const restaurant = state.restaurants.find((restaurant) => restaurant.id == restaurant_id);

            return typeof restaurant !== "undefined" ? restaurant.currency : "EUR";
        };
    },
    unsavedNotificationsChanges(state) {
        return state.unsavedNotificationsChanges;
    },
    lexicon(state) {
        return (restaurant_id) => {
            const restaurant = state.restaurants.find((restaurant) => restaurant.id == restaurant_id);

            return typeof restaurant !== "undefined" ? restaurant.lexicon : "restaurant";
        };
    },
};

export default getters;
