var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "overflow-hidden": _vm.seatingPlanPrint.isPrinting } },
    [
      _c("LoaderComponent", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.seatingPlanLoading || _vm.seatingPlanPrint.isPrinting,
            expression: "seatingPlanLoading || seatingPlanPrint.isPrinting",
          },
        ],
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.seatingPlanLoading,
              expression: "!seatingPlanLoading",
            },
          ],
          staticClass: "row h-100 m-0 p-0",
          class: { invisible: _vm.seatingPlanPrint.isPrinting },
        },
        [
          _c("seating-plan-sidebar", {
            staticClass: "col-3 bg-light table-sidebar",
            attrs: {
              seatingPlanColorType: _vm.seatingPlanColorType,
              selectedTables: _vm.selectedTables,
              selectedTablesId: _vm.selectedTablesId,
              selectedGroupId: _vm.selectedGroupId,
              currentRoom: _vm.currentRoom,
              mainStage: _vm.mainStage,
              rooms: _vm.rooms,
              seatingPlanId: _vm.duplicate ? null : _vm.seatingPlanId_,
              baseStyle: _vm.style,
              seatingPlanName: _vm.seatingPlanName,
            },
            on: {
              "start-loading": function ($event) {
                _vm.seatingPlanLoading = true
              },
              "stop-loading": function ($event) {
                _vm.seatingPlanLoading = false
              },
              dragged: _vm.handleDraggedElement,
              "tables-selected": _vm.selectMultipleTables,
              duplicate: _vm.duplicateSelectedTables,
              changeSize: _vm.changeSelectedTablesSize,
              "set-name": function ($event) {
                _vm.seatingPlanName = $event
              },
              "set-color-type": function ($event) {
                _vm.seatingPlanColorType = $event
              },
              "draw-table": function ($event) {
                return _vm.drawTable(
                  $event.table,
                  $event.modelElement,
                  $event.forceSelection || false
                )
              },
              "group-selected-tables": _vm.groupSelectedTables,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-9 h-100 table-plan m-0 p-0 d-flex flex-column",
            },
            [
              _c("seating-plan-topbar", {
                attrs: {
                  rooms: _vm.rooms,
                  sortable: true,
                  currentRoom: _vm.currentRoom,
                },
                on: {
                  "room-changed": _vm.roomChanged,
                  "new-room": _vm.addNewRoom,
                  "room-selected": _vm.roomIsSelected,
                  "room-deleted": _vm.roomIsDeleted,
                  save: _vm.saveSeatingPlan,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row h-100 m-0 p-0 overflow-auto" },
                [
                  _c("div", {
                    ref: "seatingPlanCanvas",
                    staticClass: "h-100 col-9 p-0",
                    attrs: { id: "seating-plan-canvas" },
                    on: {
                      contextmenu: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("seating-plan-tree-view", {
                    staticClass: "col-3 h-100",
                    attrs: {
                      sortable: true,
                      currentRoom: _vm.currentRoom,
                      selectedTables: _vm.selectedTables,
                      selectedTablesId: _vm.selectedTablesId,
                      selectedGroupId: _vm.selectedGroupId,
                      rooms: _vm.rooms,
                    },
                    on: {
                      print: _vm.printSelectedRooms,
                      "groups-changed": _vm.groupsChanged,
                      "tables-changed": _vm.tablesChanged,
                      "unselect-all-tables": _vm.unselectAllTables,
                      "table-selected": _vm.selectTable,
                      "group-selected": _vm.selectGroup,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }