<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="error" class="error">
                {{ error }}
            </div>
            <div v-else>
                <div v-if="formErrors && formErrors.message" class="alert alert-danger">
                    {{ $tl("errors.common.unknown") }}
                </div>
                <div v-if="formSuccess" class="alert alert-success">
                    {{ formSuccess }}
                </div>

                <LoaderComponent v-if="formLoading" :message="$t('labels.saveInProgress')" />
                <form v-else @submit="postForm" method="post">
                    <div class="row">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4">
                                <div class="form-row mb-2">
                                    <div class="col-4">
                                        <label>{{ $tl("labels.form.date") }}</label>
                                    </div>
                                    <div class="col-md-4 col-8 date-resa-cal">
                                        <DatePicker :class="{ 'is-invalid': hasErrors('date') }" :disabled="readonly" v-model="date_selected" />
                                        <div v-if="hasErrors('date')">
                                            <div class="invalid-feedback d-block" v-for="(err, index) in formErrors.errors.date" :key="index">
                                                {{ err }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!service_id">
                                    <div class="row mt-3">
                                        <div class="col-12 mb-2">
                                            <label>{{
                                                $tl("labels.booking.services.servicesOnDate", restaurant_id, {
                                                    date: displayDate(date_selected),
                                                })
                                            }}</label>
                                            <div v-if="hasErrors('services')">
                                                <div class="invalid-feedback d-block" v-for="(err, index) in formErrors.errors.services" :key="index">
                                                    {{ err }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-12">
                                            <template v-for="service in services_available">
                                                <div v-if="!$_.isEmpty(services)" :key="service.id">
                                                    <label class="container-box" style="width: 100%; text-transform: capitalize">
                                                        <input
                                                            type="checkbox"
                                                            name="services[]"
                                                            :value="true"
                                                            v-model="services[service.id].value"
                                                            @click="setSelectedService($event, service)" />
                                                        <span class="checkmark" :disabled="readonly"></span>
                                                        {{ getServiceCategoryLabel(service) }} - {{ service.name }}
                                                    </label>
                                                    <div class="offset-1 col-11">
                                                        <template v-for="slot in service.slots">
                                                            <div :key="slot.id" class="row mb-1">
                                                                <span class="col-5">
                                                                    <label class="container-box" style="text-transform: capitalize; width: 30%">
                                                                        <input
                                                                            type="checkbox"
                                                                            :name="`services-slots-${service.id}[]`"
                                                                            :value="true"
                                                                            @click="setSelectedServiceSlot($event, service, slot)"
                                                                            v-model="services[service.id].slots[slot.id].value" />
                                                                        <span class="checkmark"></span>
                                                                        {{ slot.hour_start }}
                                                                    </label>
                                                                </span>
                                                                <span class="col-7">
                                                                    <button
                                                                        class="btn btn-outline-secondary btn-circle btn-sm"
                                                                        type="button"
                                                                        @click="services[service.id].slots[slot.id].displayPaxes = true">
                                                                        {{ $tl("labels.booking.services.managePerPax") }}
                                                                    </button>
                                                                </span>
                                                                <div
                                                                    class="offset-1 col-11 p-0"
                                                                    v-show="services[service.id].slots[slot.id].displayPaxes === true">
                                                                    <label
                                                                        class="container-box"
                                                                        style="width: 80px; text-transform: capitalize"
                                                                        v-for="value in $_.range(minPax, maxPax + 1)"
                                                                        :key="value">
                                                                        <input
                                                                            type="checkbox"
                                                                            :name="`slot-paxes-${slot.id}[]`"
                                                                            :value="value"
                                                                            v-model="services[service.id].slots[slot.id].paxes" />
                                                                        <span class="checkmark"></span>
                                                                        {{ capitalize($tl("labels.pax")) }} {{ value }}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="service_id">
                                    <div class="row mt-3">
                                        <div class="col-12 mb-2">
                                            <label>{{
                                                $tl("labels.booking.services.servicesOnDate", restaurant_id, {
                                                    date: displayDate(date_selected),
                                                })
                                            }}</label>
                                            <div v-if="hasErrors('services')">
                                                <div class="invalid-feedback d-block" v-for="(err, index) in formErrors.errors.services" :key="index">
                                                    {{ err }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-12">
                                            <template v-for="service in services_available">
                                                <div :key="service.id" v-if="!$_.isEmpty(services)">
                                                    <label
                                                        v-if="service_id === service.id"
                                                        class="container-box"
                                                        style="width: 100%; text-transform: capitalize">
                                                        <input
                                                            type="checkbox"
                                                            name="services[]"
                                                            :value="true"
                                                            v-model="services[service.id].value"
                                                            @click="setSelectedService($event, service)" />
                                                        <span class="checkmark"></span>
                                                        {{ getServiceCategoryLabel(service) }} - {{ service.name }}
                                                    </label>
                                                    <div v-if="service_id === service.id" class="offset-1 col-11">
                                                        <template v-for="slot in service.slots">
                                                            <div :key="slot.id" class="row mb-1">
                                                                <span class="col-12">
                                                                    <label class="container-box" style="text-transform: capitalize">
                                                                        <input
                                                                            type="checkbox"
                                                                            :name="`services-slots-${service.id}[]`"
                                                                            :value="true"
                                                                            @click="setSelectedServiceSlot($event, service, slot)"
                                                                            v-model="services[service.id].slots[slot.id].value" />
                                                                        <span class="checkmark"></span>
                                                                        {{ slot.hour_start }}
                                                                    </label>
                                                                    <button
                                                                        class="btn btn-outline-secondary btn-circle btn-sm"
                                                                        type="button"
                                                                        @click="services[service.id].slots[slot.id].displayPaxes = true">
                                                                        {{ $tl("labels.booking.services.managePerPax") }}
                                                                    </button>
                                                                </span>

                                                                <div
                                                                    class="offset-1 col-11 p-0"
                                                                    v-show="services[service.id].slots[slot.id].displayPaxes === true">
                                                                    <label
                                                                        class="container-box"
                                                                        style="width: 80px; text-transform: capitalize"
                                                                        v-for="value in $_.range(minPax, maxPax + 1)"
                                                                        :key="value">
                                                                        <input
                                                                            type="checkbox"
                                                                            :name="`slot-paxes-${slot.id}[]`"
                                                                            :value="value"
                                                                            v-model="services[service.id].slots[slot.id].paxes" />
                                                                        <span class="checkmark"></span>
                                                                        {{ capitalize($tl("labels.pax")) }} {{ value }}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="sepa"></div>
                                <div v-if="showSaveButton" class="form-row mb-2 mt-4">
                                    <div class="col-12">
                                        <input
                                            type="submit"
                                            name="submit"
                                            :value="$t('labels.form.actions.save')"
                                            class="btn btn-success btn-circle btn-sm" />
                                    </div>
                                </div>
                                <i18n :path="$getTranslationKey('infos.booking.services.enableBack', restaurant_id)" tag="small" class="text-muted">
                                    <template slot="dashboard">
                                        <router-link :to="{ name: 'booking.home' }">{{ $tl("labels.dashboard") }}</router-link>
                                    </template>
                                </i18n>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";
import axios from "axios";
import LangsEnum from "../../mixins/enums/LangsEnum";
import DatePicker from "../forms/DatePicker.vue";

export default {
    props: {
        restaurant_id: {
            required: true,
        },
        service_id: {
            required: false,
            default: null,
        },
        slot_id: {
            required: false,
            default: null,
        },
        date: {
            required: false,
            default: null,
        },
        readonly: {
            required: false,
            default: false,
        },
        showSaveButton: {
            required: false,
            default: true,
        },
        setFree: {
            required: false,
            default: false,
        },
        prefill: {
            required: false,
            default: true,
        },
    },
    mixins: [LangsEnum],
    data() {
        return {
            formLoading: false,
            formErrors: null,
            formSuccess: null,
            error: null,
            loading: false,
            date_selected: this.getDateTime(),
            services: {},
            services_available: [],
            restaurant: {},
            widget: {
                min_pax: null,
                max_pax: null,
                enable_children: null,
                max_children: null,
            },
        };
    },
    computed: {
        minPax() {
            return this.widget.min_pax ? this.widget.min_pax : 0;
        },
        maxPax() {
            const baseMaxPax = this.widget.max_pax ? this.widget.max_pax : 0;

            if (this.widget.enable_children) {
                return baseMaxPax + (this.widget.max_children ? this.widget.max_children : 0);
            }

            return baseMaxPax;
        },
    },
    methods: {
        initData(changing_closure_id) {
            this.error = this.restaurant = null;
            this.loading = true;

            const date = this.date_selected.toISODate();

            axios
                .get(`/api/restaurants/${this.restaurant_id}/widget`)
                .then((response) => {
                    this.widget.min_pax = response.data.min_pers;
                    this.widget.max_pax = response.data.max_pers;
                    this.widget.enable_children = response.data.enable_children;
                    this.widget.max_children = response.data.max_children;
                })
                .catch(() => {});

            axios
                .get(`/api/restaurants/${this.restaurant_id}/services?all=1&from=${date}&to=${date}`)
                .then((response) => {
                    this.services_available = response.data;

                    if (changing_closure_id) {
                        this.emptyForm();
                    }

                    this.$nextTick(() => {
                        this.initSelectedServices();

                        this.$nextTick(() => {
                            this.loadServicesFull().then(() => {
                                this.$nextTick(() => {
                                    if (this.prefill) {
                                        if (
                                            this.slot_id &&
                                            this.service_id &&
                                            !this.$_.isEmpty(this.services[this.service_id]) &&
                                            !this.$_.isEmpty(this.services[this.service_id].slots[this.slot_id])
                                        ) {
                                            this.services[this.service_id].slots[this.slot_id].paxes = this.setFree
                                                ? []
                                                : this.$_.range(this.minPax, this.maxPax + 1);
                                            this.services[this.service_id].slots[this.slot_id].value = this.setFree;
                                        } else if (this.service_id && !this.$_.isEmpty(this.services[this.service_id])) {
                                            for (var index in this.services[this.service_id].slots) {
                                                this.services[this.service_id].slots[index].paxes = this.setFree
                                                    ? []
                                                    : this.$_.range(this.minPax, this.maxPax + 1);
                                                this.services[this.service_id].slots[index].value = this.setFree;
                                            }
                                            this.services[this.service_id].value = true;
                                        }
                                    }

                                    this.$nextTick(() => {
                                        this.loading = false;
                                    });
                                });
                            });
                        });
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        postForm: function (e) {
            if (e !== null) {
                e.preventDefault();
            }
            this.formLoading = true;
            this.formSuccess = null;
            this.formErrors = null;

            // adding a new closure
            const axiosPromise = axios.post(`/api/restaurants/${this.restaurant_id}/services/full`, {
                date: this.date_selected.toISODate(),
                services: this.services,
            });

            if (this.showSaveButton) {
                axiosPromise
                    .then(() => {
                        // display success message
                        this.formLoading = false;
                        this.formSuccess = this.$tl("success.booking.services.nowFull", this.restaurant_id);
                        // empty form fields
                        this.emptyForm();
                    })
                    .catch((error) => {
                        this.formErrors = error.response ? error.response.data : {};
                        this.formLoading = false;
                    });
            } else {
                return axiosPromise;
            }
        },
        emptyForm() {
            this.name = null;
            this.date_selected = this.getDateTime();
            this.message = null;
            this.services = [];
        },
        dateUpdated() {
            this.initData();
        },
        setFormErrors(value) {
            this.formErrors = value;
        },
        setFormLoading(value) {
            this.formLoading = value;
        },
        hasErrors(name) {
            return this.formErrors && this.formErrors.errors && !_.isEmpty(this.formErrors.errors[name]);
        },
        initSelectedServices() {
            var tmpServices = {};

            this.services_available.forEach((service) => {
                let slots = {};

                service.slots.forEach(function (slot) {
                    slots[slot.id] = {
                        id: slot.id,
                        value: false,
                        displayPaxes: false,
                        paxes: [],
                    };
                });

                tmpServices[service.id] = {
                    id: service.id,
                    value: false,
                    slots,
                };
            });

            this.$set(this, "services", tmpServices);
        },
        loadServicesFull() {
            return axios
                .get(`/api/restaurants/${this.restaurant_id}/services/full?date=${this.date_selected.toISODate()}`)
                .then((response) => {
                    const services = response.data;

                    services.forEach((service) => {
                        if (this.services[service.id]) {
                            service.slots.forEach((slot) => {
                                if (this.services[service.id].slots[slot.id]) {
                                    this.services[service.id].slots[slot.id].paxes = slot.paxes;
                                    this.services[service.id].slots[slot.id].value = slot.value;
                                }
                            });
                            this.services[service.id].value = service.value;
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        setSelectedService(event, service) {
            const value = event.target.checked;
            if (value && value === true) {
                for (const slotIndex in this.services[service.id].slots) {
                    this.$set(this.services[service.id].slots[slotIndex], "paxes", this.$_.range(this.minPax, this.maxPax + 1));
                    this.$set(this.services[service.id].slots[slotIndex], "value", true);
                }
                this.$set(this.services[service.id], "value", true);
            } else {
                for (const slotIndex in this.services[service.id].slots) {
                    this.$set(this.services[service.id].slots[slotIndex], "paxes", []);
                    this.$set(this.services[service.id].slots[slotIndex], "value", false);
                }
                this.$set(this.services[service.id], "value", false);
            }
        },
        setSelectedServiceSlot(event, service, slot) {
            const value = event.target.checked;

            if (value && value === true) {
                this.$set(this.services[service.id].slots[slot.id], "value", true);
                this.$set(this.services[service.id].slots[slot.id], "paxes", this.$_.range(this.minPax, this.maxPax + 1));
            } else {
                this.$set(this.services[service.id], "value", false);
                this.$set(this.services[service.id].slots[slot.id], "value", false);
                this.$set(this.services[service.id].slots[slot.id], "paxes", []);
            }
        },
    },
    created() {
        this.initData();
    },
    components: {
        DatePicker,
        LoaderComponent,
    },
    watch: {
        restaurant_id: function (id) {
            this.initData();
        },
        date_selected: function () {
            this.dateUpdated();
        },
        date: {
            immediate: true,
            handler: function (newVal, oldVal) {
                if (newVal && newVal !== oldVal) {
                    this.date_selected = this.getDateTime(newVal, false);
                }
            },
        },
        "$route.query.date": {
            immediate: true,
            handler: function (newVal) {
                if (
                    this.$route.matched.some(({ name }) => {
                        return name === "booking.restaurants.reservations";
                    })
                ) {
                    if (!newVal) {
                        this.date_selected = this.getDateTime();
                    } else {
                        this.date_selected = this.getDateTime(newVal, false);
                    }
                }
            },
        },
        services: {
            deep: true,
            handler: function (newVal, oldVal) {
                if (newVal) {
                    for (var serviceIndex in newVal) {
                        const service = newVal[serviceIndex];

                        let allSlotsFullyChecked = true;

                        for (var slotIndex in service.slots) {
                            const slot = service.slots[slotIndex];

                            let atLeastOnePaxChecked = false;
                            let allPaxesChecked = true;

                            if (slot.paxes.length > 0) {
                                for (const value of this.$_.range(this.minPax, this.maxPax + 1)) {
                                    if (!slot.paxes.includes(value)) {
                                        allPaxesChecked = false;
                                    } else {
                                        atLeastOnePaxChecked = true;
                                    }
                                }
                            }

                            if (atLeastOnePaxChecked) {
                                this.services[serviceIndex].slots[slotIndex].value = true;
                            } else {
                                this.services[serviceIndex].slots[slotIndex].value = false;
                            }

                            if (!allPaxesChecked || this.services[serviceIndex].slots[slotIndex].value === false) {
                                allSlotsFullyChecked = false;
                            }

                            if (!allPaxesChecked && atLeastOnePaxChecked) {
                                this.services[serviceIndex].slots[slotIndex].displayPaxes = true;
                            }
                        }

                        if (allSlotsFullyChecked) {
                            this.services[serviceIndex].value = true;
                        } else {
                            this.services[serviceIndex].value = false;
                        }
                    }
                }
            },
        },
    },
};
</script>
