<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div class="alert alert-danger" v-else-if="!website_enabled">Votre site web n'est pas activé</div>
        <template v-else>
            <div class="row m-0">
                <div class="col-12">
                    <div
                        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2">Configuration des liens externes</h5>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12 mb-3">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <strong>Réseaux sociaux</strong>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-5 pt-2">
                                <label for="txt-facebook-link">Url de la page Facebook</label>
                            </div>
                            <div class="col-md-7">
                                <input
                                    :disabled="!has_right_to_update_customization"
                                    type="text"
                                    class="form-control"
                                    v-model="website_settings.facebook_link"
                                    id="txt-facebook-link"
                                    placeholder="https://facebook.com/votreidentifiant" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="facebook_link" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5 pt-2">
                                <label for="txt-instagram-link">Url de votre compte Instagram</label>
                            </div>
                            <div class="col-md-7">
                                <input
                                    :disabled="!has_right_to_update_customization"
                                    type="text"
                                    class="form-control"
                                    v-model="website_settings.instagram_link"
                                    id="txt-instagram-link"
                                    placeholder="https://instagram.com/votreidentifant" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="instagram_link" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <strong>Liens externes</strong>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-5 pt-2">
                                <label for="txt-deliveroo-link">Url de la page Deliveroo</label>
                            </div>
                            <div class="col-md-7">
                                <input
                                    :disabled="!has_right_to_update_customization"
                                    type="text"
                                    class="form-control"
                                    v-model="website_settings.deliveroo_link"
                                    id="txt-deliveroo-link"
                                    placeholder="https://deliveroo.fr/xxxxx" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="deliveroo_link" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5 pt-2">
                                <label for="txt-uber_eats-link">Url de la page UberEats</label>
                            </div>
                            <div class="col-md-7">
                                <input
                                    :disabled="!has_right_to_update_customization"
                                    type="text"
                                    class="form-control"
                                    v-model="website_settings.uber_eats_link"
                                    id="txt-uber_eats-link"
                                    placeholder="https://www.ubereats.com/xxxxx" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="uber_eats_link" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0 mt-3">
                <div class="col-12 d-inline-block">
                    <button
                        type="button"
                        class="btn btn-success btn-circle mr-1"
                        @click="postForm()"
                        :disabled="!has_right_to_update_customization">
                        Enregistrer
                    </button>
                    <small
                        >Pour rendre vos modifications visibles sur votre site internet, pensez à "<strong
                            >Enregistrer et publier</strong
                        >" en cliquant sur le bouton dans la navigation.</small
                    >
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent";
import ShowErrors from "../../../components/errors/ShowErrors";

export default {
    data() {
        return {
            loading: 0,
            errors: null,
        };
    },
    props: {
        website_settings: {
            type: Object,
            required: true,
        },
        website_enabled: {
            required: true,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_customization() {
            return this.rights.includes("website.customization.update");
        },
    },
    methods: {
        postForm(doNotFetch = false) {
            this.loading++;
            this.errors = null;

            return this.$store
                .dispatch("websites/updateWebsiteSettings", {
                    restaurant_id: this.restaurant_id,
                    params: {
                        ...this.website_settings,
                        show_address: this.website_settings.show_address ? false : true,
                    },
                })
                .then((response) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    if (!doNotFetch) this.$emit("fetch-data");
                    return true;
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                    if (error.response && error.response.data.errors) this.errors = error.response.data.errors;
                    return false;
                });
        },
    },
    components: {
        ShowErrors,
        LoaderComponent,
    },
};
</script>
