var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100" }, [
    _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "h5",
          { staticClass: "title mt-3 ml-1" },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$tl("labels.routes.onlineHelp")) +
                "\n                "
            ),
            _c("ShowNewBadgeDetails", {
              staticClass: "ml-2",
              attrs: { badge_uuid: "a644c6f5-8bc3-48e8-be08-124398dfa9e1" },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row m-0" },
      [
        _vm.loading
          ? _c("LoaderComponent", { staticClass: "mx-auto" })
          : _vm.modules.length > 0
          ? _vm._l(_vm.modules, function (module) {
              return _c(
                "div",
                { key: module, staticClass: "col-12 col-md-4 mt-2" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clickable tile restaurant border-light",
                      on: {
                        click: function ($event) {
                          return _vm.goToHelpForModule(module)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "logo" },
                        [
                          _c("feather", {
                            staticClass: "feather-40 grey",
                            attrs: { type: _vm.getModuleTypeIcon(module) },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "h6",
                        {
                          staticClass:
                            "restaurant-title border-top-light text-center",
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.getModuleTypeLabel(module)) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            })
          : _c("div", { staticClass: "col-12 font-italic mt-1 ml-2" }, [
              _vm._v(_vm._s(_vm.$tl("labels.videos.empty"))),
            ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }