<template>
    <component
        :is="tag"
        ref="contentEditable"
        :contenteditable="editable"
        :class="cssClass"
        :style="cssStyle"
        v-on="listeners">
    </component>
</template>

<script>
export default {
    name: "Editable",
    data() {
        return {};
    },
    props: {
        tag: {
            type: String,
            default: "div",
        },
        value: {
            type: String,
            default: "",
        },
        editable: {
            type: Boolean,
            default: true,
        },
        cssClass: {
            type: String,
            default: "",
        },
        cssStyle: {
            type: String,
            default: "",
        },
        maxLength: {
            type: Number,
            default: null,
        },
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: this.onInput,
            };
        },
    },
    methods: {
        getRef() {
            return this.$refs.contentEditable;
        },
        onInput(e) {
            let content = e.target.innerText;

            if (this.maxLength !== null && content.length > this.maxLength) {
                this.setContent((content = content.substr(0, this.maxLength)));
            }

            this.$emit("input", content);
        },
        setContent(content) {
            this.getRef().innerText = content;
        },
    },
    mounted() {
        this.setContent(this.value);
    },
};
</script>
