<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Scanner une réservation</h5>
        <div slot="body">
            <div v-if="error" class="alert alert-danger">
                <p>
                    {{ error }}
                </p>
            </div>
            <qrcode-stream v-if="showValidateReservationModal === false" @decode="reservationFound" @init="onInit" />
            <validate-reservation-modal
                v-if="showValidateReservationModal === true"
                :reservation_id="selected_reservation_id"
                :restaurant_id="selected_restaurant_id"
                @close="showValidateReservationModal = false"
                @validate-resa="$emit('validate-resa', $event)" />
            <invalid-reservation-modal
                v-if="showInvalidReservationModal === true"
                :reservation="selected_reservation"
                @close="showInvalidReservationModal = false" />
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="close">{{ $tl("labels.form.actions.close") }}</button>
        </div>
    </modal>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import InvalidReservationModal from "./InvalidReservationModal.vue";
import ValidateReservationModal from "./ValidateReservationModal.vue";

export default {
    data() {
        return {
            error: null,
            showValidateReservationModal: false,
            showInvalidReservationModal: false,
            selected_reservation_id: null,
            selected_restaurant_id: null,
        };
    },
    props: {
        reservations: {
            required: true,
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
        reservationFound(decoded) {
            if (!this.isLoading) {
                this.isLoading = true;
                this.error = null;
                this.findReservationByToken(decoded).then(({ reservation, valid }) => {
                    if (reservation) {
                        if (valid) {
                            this.selected_reservation_id = reservation.id;
                            this.selected_restaurant_id = this.$route.params.restaurant_id;
                            this.$nextTick(() => {
                                this.showValidateReservationModal = true;
                            });
                        } else {
                            this.selected_reservation = reservation;
                            this.$nextTick(() => {
                                this.showInvalidReservationModal = true;
                            });
                        }
                    } else {
                        this.error = "La réservation n'existe pas";
                    }

                    this.isLoading = false;
                });
            }
        },
        onInit(promise) {
            promise.catch((error) => {
                if (error.name === "NotAllowedError") {
                    this.error = "Veuillez autoriser l'accès a la caméra.";
                } else if (error.name === "NotFoundError") {
                    this.error = "Aucune caméra disponible.";
                } else if (error.name === "NotSupportedError" || error.name === "InsecureContextError") {
                    this.error = "Veuillez utiliser cette fonctionnalité dans un contexte sécurisé (HTTPS).";
                } else if (error.name === "NotReadableError") {
                    this.error = "La caméra est déjà utilisée.";
                } else if (error.name === "OverconstrainedError") {
                    this.error = "Les caméra disponibles ne sont pas compatibles";
                } else if (error.name === "StreamApiNotSupportedError") {
                    this.error = "Votre navigateur ne supporte pas cette fonctionnalité";
                }
            });
        },
        async findReservationByToken(token) {
            for (var reservation of this.reservations) {
                if (reservation.token === token) {
                    return {
                        valid: true,
                        reservation,
                    };
                }
            }

            try {
                const response = await axios.get(
                    `/api/restaurants/${this.$route.params.restaurant_id}/reservations/token/${token}?include=slot,client,tables`
                );

                return {
                    valid: false,
                    reservation: response.data,
                };
            } catch (error) {
                return {
                    valid: false,
                    reservation: null,
                };
            }
        },
        closeValidatePopUp() {
            this.showValidateReservationModal = false;
        },
    },
    components: {
        QrcodeStream,
        ValidateReservationModal,
        InvalidReservationModal,
    },
};
</script>
