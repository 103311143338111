<template>
    <div>
        <div class="row mb-3">
            <div class="col-12">
                <strong>Détail du pack</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-4">Nom du pack</div>
                    <div class="col-8 value">
                        {{ emailsCampaignPack.name }}
                    </div>
                </div>
                <div class="sepa"></div>
                <div class="row">
                    <div class="col-4">Description</div>
                    <div class="col-8 value">
                        {{ emailsCampaignPack.description }}
                    </div>
                </div>
                <div class="sepa"></div>
                <div class="row">
                    <div class="col-4">Prix TTC</div>
                    <div class="col-8 value">{{ emailsCampaignPack.price / 100 }} €</div>
                </div>
                <div class="sepa"></div>
                <div class="row">
                    <div class="col-4">Prix HT</div>
                    <div class="col-8 value">{{ emailsCampaignPack.price_ht / 100 }} €</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ShowEmailsCampaignPack",
    props: {
        emailsCampaignPack: {
            default: "",
        },
    },
    methods: {},
};
</script>
