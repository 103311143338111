<template>
    <div class="p-2">
        <div class="row">
            <div class="col-12">
                <div
                    class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">{{ $t("labels.admin.videos.title") }}</h5>
                    <button class="btn btn-sm btn-success btn-circle" @click="addVideo">
                        <feather type="plus" class="mr-2" />{{ $t("labels.admin.videos.add") }}
                    </button>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <table v-if="videosList.length > 0" class="table table-sm table-striped border-bottom">
                <thead class="border-bottom">
                    <tr>
                        <th>{{ $t("labels.admin.videos.columns.title") }}</th>
                        <th>{{ $t("labels.admin.videos.columns.description") }}</th>
                        <th>{{ $t("labels.admin.videos.columns.link") }}</th>
                        <th>{{ $t("labels.admin.videos.columns.module") }}</th>
                        <th>{{ $t("labels.admin.videos.columns.createdAt") }}</th>
                        <th>{{ $t("labels.admin.videos.columns.updatedAt") }}</th>
                        <th>{{ $t("labels.form.actions.title") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="video in videosList" :key="video.id">
                        <td>{{ video.title }}</td>
                        <td>{{ getVideoDescription(video) }}</td>
                        <td>
                            <a :href="`${YOUTUBE_EMBED_BASE_URL}/${video.youtube_video_id}`" target="_blank">{{
                                $t("labels.admin.videos.show")
                            }}</a>
                        </td>
                        <td>{{ getModuleTypeLabel(video.module) }}</td>
                        <td>{{ displayDate(video.created_at, DATE_SHORT) }}</td>
                        <td>{{ displayDate(video.updated_at, DATE_SHORT) }}</td>
                        <td>
                            <button
                                class="btn btn-sm btn-square btn-success"
                                type="button"
                                @click="editVideo(video)"
                                v-tooltip="{ delay: { show: 400, hide: 0 }, content: $t('labels.form.actions.edit') }">
                                <feather type="edit" />
                            </button>
                            <button
                                class="btn btn-sm btn-square btn-danger"
                                type="button"
                                @click="deleteVideo(video)"
                                v-tooltip="{
                                    delay: { show: 400, hide: 0 },
                                    content: $t('labels.form.actions.delete'),
                                }">
                                <feather type="trash-2" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p v-else class="font-italic">{{ $t("labels.admin.videos.empty") }}</p>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../components/LoaderComponent";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum";

export default {
    name: "VideosList",
    data() {
        return {
            loading: false,
            videosList: [],
        };
    },
    mixins: [ModuleTypesEnum],
    methods: {
        fetchVideosList() {
            this.loading = true;

            axios
                .get("/api/admin/videos")
                .then((response) => {
                    this.videosList = response.data.data;
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addVideo() {
            this.$router.push({ name: "admin.videos.edit", params: { video_id: "add" } });
        },
        editVideo(video) {
            this.$router.push({ name: "admin.videos.edit", params: { video_id: video.id } });
        },
        deleteVideo(video) {
            if (!confirm(this.$t("questions.admin.videos.delete"))) {
                return;
            }

            this.loading = true;

            axios
                .delete(`/api/admin/videos/${video.id}`)
                .then((response) => {
                    this.notifySuccess(response);
                    this.fetchVideosList();
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getVideoDescription(video) {
            if (video.description === null || video.description === undefined) {
                return "";
            }
            if (video.description.length < 100) {
                return video.description;
            }
            return video.description.substr(0, 100) + "...";
        },
    },
    components: {
        LoaderComponent,
    },
    created() {
        this.fetchVideosList();
    },
};
</script>
