var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row m-0 p-2 pt-5" },
    [
      _c("tab-bar", {
        staticClass: "border-right col-2 pr-0",
        attrs: {
          side: "left",
          direction: "vertical",
          entries: _vm.tabBarEntries,
        },
      }),
      _vm._v(" "),
      _c("router-view", { staticClass: "col-10 pl-0" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }