import Vue from "vue";
import _ from "lodash";

const Plugin = {
    install(VueInstance) {
        if (this.installed) {
            return;
        }

        this.installed = true;

        VueInstance.prototype.$_ = _;
    },
};

export default Plugin;
