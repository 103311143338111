<template>
    <li v-if="has_rights" class="nav-item text-white">
        <template v-if="entry.url !== undefined">
            <a v-if="entry.onClick" class="nav-link pt-2 pb-2" :href="entry.url" @click.prevent="entry.onClick" :class="entry.class">
                {{ entryTitle }}
            </a>
            <a v-else :href="entry.url" class="nav-link pt-2 pb-2" :target="entry.target" :class="entry.class">
                {{ entryTitle }}
            </a>
        </template>
        <router-link v-else :to="entry.to" :class="entry.class" class="nav-link pt-2 pb-2">
            <span>{{ entryTitle }}</span>
            <div v-if="entry.to.name === 'app_updates.list' && hasUnreadAppUpdates" class="red-bubble ml-2"></div>
            <newBadge v-if="hasNewBadge" />
        </router-link>
    </li>
</template>

<script>
import newBadge from "../../components/NewBadges/newBadge";

export default {
    props: {
        entry: {
            required: true,
        },
        user: {
            default: null,
        },
    },
    computed: {
        hasUnreadAppUpdates() {
            return this.$store.getters["users/hasUnreadAppUpdates"];
        },
        entryTitle() {
            if ("undefined" !== typeof this.entry.titleI18n) return this.$t(`labels.routes.${this.entry.titleI18n}`);
            return this.entry.title;
        },
        hasNewBadge() {
            return this.entry && this.entry.to && this.entry.to.name ? this.checkForExistingNewBadge(this.entry.to.name) : false;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_rights() {
            if (this.entry.rights.includes("any")) return true;
            return this.entry.rights.some((right) => {
                if (this.rights.includes(right)) return true;
                let i = right.indexOf("*");
                if (i !== -1) {
                    right = right.substring(0, i).replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
                    let regexp = new RegExp(`${right}.+`, "g");
                    return this.rights.some((cright) => cright.match(regexp));
                }
                return false;
            });
        },
    },
    components: {
        newBadge,
    },
};
</script>
