<template>
    <div class="home-vue p-0 pb-5" v-if="user && user != '' && user.role !== 'kitchen'">
        <!-- <div class="mobile-dashboard none-desk pb-4" v-if="restaurant">
      <div>
        <div
          class="mobile-dashboard-header text-white p-3 shadow-home d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center"
          style="cursor: pointer"
          data-toggle="collapse"
          :data-target="`#collapseOne-${restaurant.id}`"
          :aria-controls="`collapseOne-${restaurant.id}`"
        >
          <div class="mobile-dashboard-restaurant-name">
            <strong>{{ restaurant.name }}</strong>
          </div>
        </div>
      </div>
    </div> -->

        <div class="row m-0">
            <div class="col-12">
                <div
                    class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Tableau de bord</h5>
                </div>
            </div>
        </div>
        <div class="card-body pl-3 pr-3 mb-3 pt-0">
            <LoaderComponent class="w-100" v-if="loadingNbCommands" />
            <div class="text-danger" v-else-if="errorNbCommands">{{ errorNbCommands }}</div>
            <template v-else>
                <router-link
                    class="media align-items-stretch bg-grad-warning white mr-2"
                    :to="{ name: 'click_and_collect.restaurants.sales_waiting_confirmation' }">
                    <div class="p-3 media-middle">
                        <feather type="clock" class="mt-2"></feather>
                    </div>
                    <div class="media-body pt-3 pr-3 pb-3 pl-1">
                        <small class="mt-2" style="display: block"> Nombre de commandes </small>
                        <h6>En attente</h6>
                    </div>
                    <div class="media-right p-3 media-middle">
                        <h4 class="pt-2">{{ nb_commands_waiting_confirmation }}</h4>
                    </div>
                </router-link>

                <router-link
                    class="media align-items-stretch bg-grad white mr-2"
                    :to="{ name: 'click_and_collect.restaurants.sales' }">
                    <div class="p-3 media-middle">
                        <feather type="calendar" class="mt-2"></feather>
                    </div>
                    <div class="media-body pt-3 pr-3 pb-3 pl-1">
                        <small class="mt-2" style="display: block"> Nombre de commandes </small>
                        <h6>Aujourd'hui</h6>
                    </div>
                    <div class="media-right p-3 media-middle">
                        <h4 class="pt-2">{{ nb_commands_today }}</h4>
                    </div>
                </router-link>
            </template>
        </div>

        <div class="row m-0">
            <div class="col-12">
                <LoaderComponent v-if="!restaurant" />
                <div v-else>
                    <div v-if="error" class="error">{{ error }}</div>
                    <div v-if="restaurant">
                        <click-and-collect-restaurant-dashboard class="mb-3" :restaurant="restaurant" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-popups/styles/material.css";
</style>

<script>
import ClickAndCollectRestaurantDashboard from "../../components/clickAndCollect/Board/ClickAndCollectRestaurantDashboard";
import LoaderComponent from "../../components/LoaderComponent";

export default {
    data() {
        return {
            error: null,
            loadingNbCommands: false,
            errorNbCommands: null,
            restaurant: null,
            nb_commands_waiting_confirmation: 0,
            nb_commands_today: 0,
        };
    },
    props: {
        user: {
            required: true,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    created() {
        this.fetchRestaurant();
        this.getNbCommands();
    },
    methods: {
        fetchRestaurant() {
            axios
                .get(`/api/restaurants/${this.restaurant_id}`)
                .then((response) => {
                    this.restaurant = response.data;
                })
                .catch((error) => {
                    if (error.response && error.response.data.message) this.error = error.response.data.message;
                    else if (error.message) this.error = error.message;
                    else this.error = "Une erreur est survenue";
                });
        },
        getNbCommands() {
            this.loadingNbCommands = true;
            this.errorNbCommands = null;

            axios
                .get(`/api/click_and_collect/${this.$route.params.restaurant_id}/commands/nb`)
                .then((response) => {
                    this.loadingNbCommands = false;
                    this.nb_commands_waiting_confirmation = response.data.nb_commands_waiting_confirmation;
                    this.nb_commands_today = response.data.nb_commands_today;
                })
                .catch((error) => {
                    this.loadingNbCommands = false;
                    if (error.response && error.response.data.message)
                        this.errorNbCommands = error.response.data.message;
                    else if (error.message) this.errorNbCommands = error.message;
                    else this.errorNbCommands = "Une erreur est survenue";
                });
        },
    },
    components: {
        LoaderComponent,
        ClickAndCollectRestaurantDashboard,
    },
};
</script>
