<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ campaign_id ? "Éditer" : "Créer" }} une campagne</h5>
        <div slot="body">
            <campaign-add
                ref="campaignAdd"
                :step="step"
                @set-save-type="save_type = $event"
                @set-loading="loading = $event"
                @saved="$emit('saved', $event)"
                :campaign_id="campaign_id" />
        </div>
        <div slot="footer" class="d-flex">
            <button class="modal-default-button btn-sm btn btn-secondary btn-circle" :disabled="loading" @click="close">
                Fermer
            </button>
            <button class="btn btn-success btn-sm btn-circle ml-2" :disabled="loading" v-if="step > 1" @click="step--">
                Étape précédente
            </button>
            <button class="btn btn-success btn-sm btn-circle ml-2" :disabled="loading" v-if="step < 3" @click="step++">
                Étape suivante
            </button>
            <button
                class="btn btn-success btn-sm btn-circle ml-2"
                :disabled="loading"
                v-if="step !== 3 || save_type !== SAVE_TYPE_SEND.value"
                @click="save">
                Enregistrer
            </button>
            <button
                class="btn btn-success btn-sm btn-circle ml-2"
                :disabled="loading"
                v-if="step === 3 && save_type === SAVE_TYPE_SEND.value"
                @click="save">
                Envoyer
            </button>
        </div>
    </modal>
</template>

<script>
import CampaignAdd from "../../../Admin/Campaigns/CampaignAdd";
import SaveTypesEnum from "../../../../mixins/enums/campaigns/SaveTypesEnum";

export default {
    data: function () {
        return {
            loading: false,
            step: 1,
            save_type: null,
        };
    },
    mixins: [SaveTypesEnum],
    props: {
        campaign_id: {
            default: null,
        },
    },
    methods: {
        save() {
            if (this.step < 3 && this.save_type === this.SAVE_TYPE_SEND.value)
                this.$refs.campaignAdd.save(this.SAVE_TYPE_SAVE.value);
            else this.$refs.campaignAdd.save();
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        CampaignAdd,
    },
};
</script>
