<template>
    <div>
        <div class="col-md-5 pt-1 mb-2">
            <label>Point de départ</label>
        </div>
        <div class="col-md-7">
            <input :disabled="true" type="text" class="form-control mb-2" :value="displayableDeliveryDeparture" />
        </div>
        <div class="col-md-5 pt-1 mb-2">
            <label>Distance minimale</label>
        </div>
        <div class="col-md-7">
            <div class="d-flex">
                <input
                    type="number"
                    min="0"
                    step="0.001"
                    class="form-control mb-2"
                    v-model="deliveryArea.distance_min_km" />
                <span class="ml-2 mt-1">km</span>
            </div>
            <ShowErrors class="d-block" :errors="errors" errorKey="distance_min_km" />
        </div>
        <div class="col-md-5 pt-1 mb-2">
            <label>Distance maximale <small>*</small></label>
        </div>
        <div class="col-md-7">
            <div class="d-flex">
                <input
                    type="number"
                    step="0.001"
                    :min="deliveryArea.distance_min_km || 0"
                    class="form-control mb-2"
                    v-model="deliveryArea.distance_max_km" />
                <span class="ml-2 mt-1">km</span>
            </div>
            <ShowErrors class="d-block" :errors="errors" errorKey="distance_max_km" />
        </div>
    </div>
</template>

<script>
import ShowErrors from "../../../errors/ShowErrors";

export default {
    props: {
        deliveryArea: {
            type: Object,
            required: true,
        },
        errors: {
            default: null,
        },
        displayableDeliveryDeparture: {
            type: String,
            required: true,
        },
    },
    components: {
        ShowErrors,
    },
};
</script>
