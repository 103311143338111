var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "position-absolute pl-1",
      class: { pointer: _vm.event.clickable },
      style: {
        ...(_vm.event.style || {}),
        left: _vm.left,
        width: _vm.width,
        top: _vm.top,
      },
      on: { click: _vm.onEventClicked },
    },
    [
      _vm._t(
        "event",
        function () {
          return [
            _c("div", { staticStyle: { "font-weight": "500" } }, [
              _vm._v(_vm._s(_vm.event.name)),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("small", [
                _vm._v(
                  _vm._s(_vm.displayDate(_vm.event.startsAt, _vm.TIME_SIMPLE)) +
                    " - " +
                    _vm._s(_vm.displayDate(_vm.event.endsAt, _vm.TIME_SIMPLE))
                ),
              ]),
            ]),
          ]
        },
        null,
        { event: _vm.event }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }