var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(_vm._s(_vm.$tl("labels.booking.waitingList.title"))),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _c("waiting-list", {
          ref: "showWaitingListComponent",
          attrs: {
            service_id: _vm.service_id,
            reservation_date: _vm.reservation_date,
          },
        }),
        _vm._v(" "),
        _vm.showNotifyWaitingsModal
          ? _c("NotifyWaitingsModal", {
              attrs: {
                restaurant_id: _vm.restaurant_id,
                service_id: _vm.service_id,
                reservation_date: _vm.reservation_date,
              },
              on: {
                sent: _vm.onWaitingsNotified,
                close: function ($event) {
                  _vm.showNotifyWaitingsModal = false
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-success btn-circle mr-2",
            on: {
              click: function ($event) {
                _vm.showNotifyWaitingsModal = true
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$tl(
                    "labels.booking.waitingList.buttons.remindCustomerWaitingList"
                  )
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            on: { click: _vm.close },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.close")) +
                "\n        "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }