<template>
    <div class="pb-5">
        <loader-component v-if="loading" />
        <div class="default-home" v-else>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else>
                <div>
                    <div class="row m-0">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4 mb-3">
                                <div class="row">
                                    <div class="col-12 mb-3">
                                        <strong>{{ $tl("labels.visualIdentity") }}</strong>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5 mb-3">
                                        <label>{{ $tl("labels.form.logo") }}</label>
                                    </div>
                                    <div class="col-7">
                                        <ImageComponent
                                            :src="restaurant.logo.url"
                                            :disabled="!has_right_to_update_restaurant"
                                            :upload="false"
                                            :maxSize="10"
                                            suffixId="logo"
                                            @new-file="restaurant.logo.file = $event" />
                                        <div class="text-danger" v-if="hasErrors('logo')" v-html="joinErrors('logo')"></div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-5 pt-2">
                                        <label>{{ $tl("labels.form.color") }}</label>
                                    </div>
                                    <div class="col-md-7">
                                        <chrome-picker v-if="has_right_to_update_restaurant" v-model="restaurant.widget_color" />
                                        <div class="mt-2 text-center" v-else :style="`width: 60px; background-color: ${restaurant.widget_color}`">
                                            {{ restaurant.widget_color }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5 pt-2">
                                        <label>{{ $tl("labels.form.restaurant.titleFont") }} <small>*</small></label>
                                    </div>
                                    <div class="col-md-7 d-flex align-items-center">
                                        <select v-model="restaurant.widget_font_title" class="custom-select w-auto m-0">
                                            <option v-for="font in availableFontsTitle" :key="font.value" :value="font.value">
                                                {{ font.name }}
                                            </option>
                                        </select>
                                        <span class="ml-2 font-previsu" :style="'font-family: ' + restaurant.widget_font_title">{{
                                            restaurant.name || $tl("labels.reservation")
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form id="editRestaurant" @submit="postForm" method="post">
                    <div class="row m-0">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4">
                                <loader-component v-if="formLoading" />
                                <div class="row">
                                    <div class="col-12 mb-3">
                                        <strong>{{ $tl("labels.infos") }}</strong
                                        ><feather
                                            type="info"
                                            class="text-warning ml-1"
                                            v-tooltip="
                                                getTooltip(
                                                    'La modification des informations de l\'établissement modifie également les informations de contact et les coordonnées du médiateur'
                                                )
                                            " />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-5 pt-2">
                                        <label>{{ $tl("labels.form.restaurant.name") }} <small>*</small></label>
                                    </div>
                                    <div class="col-7">
                                        <input
                                            :disabled="!has_right_to_update_restaurant"
                                            type="text"
                                            v-model="restaurant.name"
                                            class="form-control"
                                            required
                                            @input="fillCgvDataOnRestaurantInfosEdit" />
                                        <div class="text-danger" v-if="hasErrors('name')" v-html="joinErrors('name')"></div>
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-md-5 pt-2">
                                        {{ $tl("labels.form.address") }} <small>*</small>
                                        <small v-if="!hasFillGoogleMapsAddress && restaurant.id" class="text-warn d-block mt-2">
                                            {{ $tl("infos.restaurants.newAddressComponent") }}
                                        </small>
                                    </div>
                                    <div class="col-md-7">
                                        <postal-address-autocomplete
                                            v-model="restaurant.google_maps_address"
                                            @input="fillCgvDataOnRestaurantInfosEdit"
                                            :search-address-type="false" />
                                        <div
                                            class="text-danger"
                                            v-if="hasErrors('google_maps_address')"
                                            v-html="joinErrors('google_maps_address')"></div>
                                    </div>
                                </div>

                                <input-component
                                    v-model="restaurant.further_address_details"
                                    :label="$t('labels.form.addressComp')"
                                    notice="notices.restaurants.addressComp"
                                    inputName="further_address_details"
                                    :form-errors="formErrors" />

                                <template v-if="!hasFillGoogleMapsAddress && restaurant.id">
                                    <div class="row mb-2">
                                        <div class="col-5 pt-2">
                                            <label>{{ $tl("labels.form.address") }} <small>*</small></label>
                                        </div>
                                        <div class="col-7">
                                            <input
                                                disabled
                                                type="text"
                                                v-model="restaurant.address"
                                                class="form-control"
                                                required
                                                @input="fillCgvDataOnRestaurantInfosEdit" />
                                            <div class="text-danger" v-if="hasErrors('address')" v-html="joinErrors('address')"></div>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-5 pt-2">
                                            <label>{{ $tl("labels.form.addressComp") }}</label>
                                        </div>
                                        <div class="col-7">
                                            <input disabled type="text" v-model="restaurant.further_address_details" class="form-control" />
                                            <div
                                                class="text-danger"
                                                v-if="hasErrors('further_address_details')"
                                                v-html="joinErrors('further_address_details')"></div>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-5 pt-2">
                                            <label>{{ $tl("labels.form.postalCode") }} <small>*</small></label>
                                        </div>
                                        <div class="col-7">
                                            <input
                                                disabled
                                                type="text"
                                                maxlength="5"
                                                v-model="restaurant.zipcode"
                                                class="form-control"
                                                required
                                                @input="fillCgvDataOnRestaurantInfosEdit" />
                                            <div class="text-danger" v-if="hasErrors('zipcode')" v-html="joinErrors('zipcode')"></div>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-5 pt-2">
                                            <label>{{ $tl("labels.form.city") }} <small>*</small></label>
                                        </div>
                                        <div class="col-7">
                                            <input
                                                disabled
                                                type="text"
                                                v-model="restaurant.city"
                                                class="form-control"
                                                required
                                                @input="fillCgvDataOnRestaurantInfosEdit" />
                                            <div class="text-danger" v-if="hasErrors('city')" v-html="joinErrors('city')"></div>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-5 pt-2">
                                            <label>{{ $tl("labels.form.country") }} <small>*</small></label>
                                        </div>
                                        <div class="col-7">
                                            <input
                                                disabled
                                                type="text"
                                                v-model="restaurant.country"
                                                class="form-control"
                                                required
                                                @input="fillCgvDataOnRestaurantInfosEdit" />
                                            <div class="text-danger" v-if="hasErrors('country')" v-html="joinErrors('country')"></div>
                                        </div>
                                    </div>
                                </template>

                                <div class="row mb-2">
                                    <div class="col-5 pt-2">
                                        <label>{{ $tl("labels.form.phoneNumber") }}</label>
                                    </div>
                                    <div class="col-7">
                                        <vue-tel-input
                                            defaultCountry="FR"
                                            name="telInput"
                                            :disabled="!has_right_to_update_restaurant"
                                            class="form-control"
                                            @validate="setPhoneAndCountry"
                                            v-model="phone.raw"
                                            :enabledCountryCode="true"
                                            :wrapperClasses="phone.isValid === null || phone.isValid ? '' : 'border border-danger'"
                                            inputClasses="m-0 border-0"
                                            mode="international"
                                            :placeholder="$t('labels.form.phoneNumber')"></vue-tel-input>
                                        <div class="text-danger" v-if="hasErrors('tel')" v-html="joinErrors('tel')"></div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-5 pt-2">
                                        <label>{{ $tl("labels.form.website") }}</label>
                                    </div>
                                    <div class="col-7">
                                        <input
                                            :disabled="!has_right_to_update_restaurant"
                                            type="text"
                                            v-model="restaurant.website"
                                            class="form-control"
                                            placeholder="www.monsite.com" />
                                        <div class="text-danger" v-if="hasErrors('website')" v-html="joinErrors('website')"></div>
                                    </div>
                                </div>
                                <div class="row pt-1 mb-1">
                                    <div class="col-5">
                                        <label>{{ $tl("labels.form.restaurant.noTVA") }}</label>
                                    </div>
                                    <div class="col-7">
                                        <label class="container-box" style="width: initial">
                                            <input
                                                type="checkbox"
                                                v-model="restaurant.tva_disabled"
                                                true-value="1"
                                                :disabled="!has_right_to_update_restaurant" />
                                            <span class="checkmark" :class="{ disabled: !has_right_to_update_restaurant }"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5 pt-2">
                                        <label>{{ $tl("labels.timezone") }} <small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <TimeZonePicker v-model="restaurant.timezone" class="custom-select w-auto m-0" />
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="timezone" />
                                    </div>
                                </div>
                                <div class="row mb-2 mt-2">
                                    <div class="col-5 pt-2">
                                        <label>{{ $tl("labels.form.indicative") }}</label>
                                        <feather
                                            v-tooltip="getTooltipNotice('notices.defaultTelCountry')"
                                            type="info"
                                            class="ml-1 pointer tooltip-infos" />
                                    </div>
                                    <div style="padding-left: 15px">
                                        <vue-tel-input
                                            class="form-control p-0"
                                            :defaultCountry="restaurant.default_tel_country"
                                            @country-changed="(e) => (restaurant.default_tel_country = e.iso2)"
                                            inputClasses="d-none">
                                        </vue-tel-input>
                                    </div>
                                </div>

                                <div class="row mb-2 mt-2">
                                    <div class="col-5 pt-2">
                                        <label>{{ $tl("labels.form.restaurant.currency") }}</label>
                                        <feather v-tooltip="getTooltipNotice('notices.currency')" type="info" class="ml-1 pointer tooltip-infos" />
                                    </div>
                                    <div class="col-md-7 d-flex align-items-center">
                                        <choose-currency :disabled="restaurant.has_psp" v-model="restaurant.currency" />
                                    </div>
                                </div>

                                <template v-if="isYservices">
                                    <div class="row mb-2 mt-2">
                                        <div class="col-5 pt-2">
                                            <label>{{ $tl("labels.lexicons.lexicon") }}</label>
                                        </div>
                                        <div class="col-md-7 d-flex align-items-center">
                                            <choose-lexicon v-model="restaurant.lexicon" />
                                        </div>
                                    </div>

                                    <switch-input-component
                                        v-if="restaurant.lexicon !== LEXICON_RESTAURANT.value"
                                        :label="$t('labels.form.restaurant.showToRealizeView')"
                                        v-model="restaurant.show_to_realize_view"></switch-input-component>

                                    <switch-input-component
                                        v-if="restaurant.lexicon !== LEXICON_RESTAURANT.value"
                                        :label="$t('labels.form.restaurant.enableSeatingPlan')"
                                        v-model="restaurant.feat_seating_plan"></switch-input-component>
                                </template>
                            </div>
                            <Cgv
                                :restaurant_id="restaurant.id || null"
                                v-show="!formLoading"
                                :formErrors="formErrors"
                                ref="cgvComponent"
                                :isTvaDisabledProp="restaurant.tva_disabled" />
                            <div class="row mt-3">
                                <div class="col-12">
                                    <button :disabled="!has_right_to_update_restaurant" class="btn btn-success btn-circle" type="submit">
                                        {{ $tl("labels.form.actions.save") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import ImageComponent from "../../../components/forms/ImageComponent";
import { Chrome } from "vue-color";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum";
import ModulePlanTypesEnum from "../../../mixins/enums/ModulePlanTypesEnum";
import CardPaymentSystemEnum from "../../../mixins/enums/CardPaymentSystemEnum";
import Cgv from "../../../components/Default/Restaurants/cgv";
import TimeZonePicker from "../../../components/forms/TimeZonePicker.vue";
import ShowErrors from "../../../components/errors/ShowErrors.vue";
import ChooseCurrency from "../../../components/Default/ChooseCurrency.vue";
import ChooseLexicon from "../../../components/Default/ChooseLexicon.vue";
import PostalAddressAutocomplete from "../../../components/forms/PostalAddressAutocomplete.vue";
import InputComponent from "../../../components/forms/InputComponent.vue";
import LexiconEnum from "../../../mixins/enums/LexiconEnum.js";
import SwitchInputComponent from "../../../components/forms/SwitchInputComponent.vue";

export default {
    name: "AddRestaurant",
    data() {
        return {
            formLoading: null,
            formErrors: null,
            loading: false,
            error: null,
            licences: {},
            availableFontsTitle: [
                {
                    name: "Montserrat Alternates",
                    value: "'Montserrat Alternates', sans-serif",
                },
                {
                    name: "Roboto",
                    value: "'Roboto', sans-serif",
                },
                {
                    name: "Open Sans",
                    value: "'Open Sans', sans-serif",
                },
                {
                    name: "Poppins",
                    value: "'Poppins', sans-serif",
                },
                {
                    name: "Oswald",
                    value: "'Oswald', sans-serif",
                },
                {
                    name: "Playfair Display",
                    value: "'Playfair Display', sans-serif",
                },
                {
                    name: "DM Serif Display",
                    value: "'DM Serif Display', sans-serif",
                },
                {
                    name: "Lato",
                    value: "'Lato', sans-serif",
                },
                {
                    name: "Raleway",
                    value: "'Raleway', sans-serif",
                },
            ],
            phone: {
                raw: null,
                isValid: null,
                country: null,
                value: null,
                search: {
                    results: null,
                },
            },
            hasFillGoogleMapsAddress: false,
        };
    },
    props: {
        restaurant: {
            required: true,
        },
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_restaurant() {
            return this.rights.includes("default.restaurants.update");
        },
        has_right_to_update_subscription() {
            return this.rights.includes("default.subscription.update");
        },
    },
    created() {
        if (this.restaurant.id) {
            this.hasFillGoogleMapsAddress = this.restaurant.google_maps_address !== null;
            this.restaurant.logo = {
                url: this.restaurant.logo,
                file: null,
            };
            this.phone.raw = this.restaurant.tel;
        }
        this.initLicences();
    },
    methods: {
        setPhoneAndCountry(phoneObject) {
            if (phoneObject.number && phoneObject.isValid !== undefined && phoneObject.country) {
                this.phone.isValid = phoneObject.isValid;

                if (this.phone.isValid) {
                    this.phone.value = phoneObject.number;
                    this.restaurant.tel = this.phone.value.international || this.phone.raw;
                    this.fillCgvDataOnRestaurantInfosEdit();

                    if (phoneObject.regionCode && phoneObject.regionCode !== "") {
                        this.phone.country = phoneObject.regionCode;
                    } else {
                        this.phone.country = phoneObject.country.iso2;
                    }
                }
            } else {
                this.phone.isValid = false;
            }
        },
        initLicences() {
            var promises = [];
            this.ALL_MODULES_TYPES.forEach((ModuleType) => {
                promises.push(this.$set(this.licences, ModuleType.value, null));
            });

            return promises;
        },
        setLicences(licences) {
            licences.forEach((licence) => {
                this.licences[licence.module.type] = licence.id;
            });
        },
        fillCgvDataOnRestaurantInfosEdit() {
            this.$refs.cgvComponent.setParam("contact", "company", this.restaurant.name);

            if (this.restaurant.google_maps_address !== null) {
                this.$refs.cgvComponent.setParam("contact", "address", this.restaurant.google_maps_address.street_address);
                this.$refs.cgvComponent.setParam("contact", "zipcode", this.restaurant.google_maps_address.postal_code);
                this.$refs.cgvComponent.setParam("contact", "city", this.restaurant.google_maps_address.locality ?? "");
                this.$refs.cgvComponent.setParam("contact", "country", this.restaurant.google_maps_address.country);

                this.$refs.cgvComponent.setParam("mediator", "address", this.restaurant.google_maps_address.street_address);
                this.$refs.cgvComponent.setParam("mediator", "zipcode", this.restaurant.google_maps_address.postal_code);
                this.$refs.cgvComponent.setParam("mediator", "city", this.restaurant.google_maps_address.locality ?? "");
                this.$refs.cgvComponent.setParam("mediator", "country", this.restaurant.google_maps_address.country);
            }

            // Set tel only if value is available
            // (Set at all times create a mystik bug : focus on tel input after first char input in restaurant's name)
            if (this.restaurant.tel) {
                this.$refs.cgvComponent.setParam("contact", "tel", this.restaurant.tel);
            }
        },
        fillRestaurant(data) {
            this.restaurant.id = data.id;
            this.restaurant.name = data.name;
            this.restaurant.address = data.address;
            this.restaurant.further_address_details = data.further_address_details;
            this.restaurant.zipcode = data.zipcode;
            this.restaurant.city = data.city;
            this.restaurant.country = data.country;
            this.restaurant.tel = data.tel;
            this.phone.raw = data.tel;
            this.restaurant.tel_country = data.tel_country;
            this.restaurant.website = data.website;
            this.restaurant.logo.url = data.logo;
            this.restaurant.widget_color = data.widget_color;
            this.restaurant.widget_font_title = data.widget_font_title;
            this.restaurant.tva_disabled = data.tva_disabled;
            this.restaurant.prefered_payment_system = data.prefered_payment_system || this.CARD_PAYMENT_SYSTEM_STRIPE.value;
            this.restaurant.timezone = data.timezone || "Europe/Paris";
            this.restaurant.default_tel_country = data.default_tel_country || "FR";
            this.restaurant.currency = data.currency || "EUR";
            this.restaurant.lexicon = data.lexicon || this.LEXICON_RESTAURANT.value;
            this.restaurant.google_maps_address = data.google_maps_address;
            this.restaurant.show_to_realize_view = data.show_to_realize_view;
            this.restaurant.feat_seating_plan = data.feat_seating_plan;
            if (data.subscriptions && data.subscriptions.data) {
                this.setLicences(data.subscriptions.data);
            }
        },
        buildForm() {
            var formData = new FormData();
            const lexicon = this.restaurant.lexicon || this.LEXICON_RESTAURANT.value;

            formData.append("name", this.restaurant.name);
            formData.append(
                "further_address_details",
                this.restaurant.further_address_details !== null ? this.restaurant.further_address_details : ""
            );
            formData.append("tel", this.phone.isValid ? this.phone.value.international || this.phone.raw : "");
            formData.append("tel_country", this.phone.isValid ? this.phone.country : "");
            formData.append("website", this.restaurant.website || "");
            formData.append("tva_disabled", this.restaurant.tva_disabled ? 1 : 0);
            formData.append("widget_color", this.restaurant.widget_color.hex ? this.restaurant.widget_color.hex : this.restaurant.widget_color);
            formData.append("widget_font_title", this.restaurant.widget_font_title);
            formData.append("prefered_payment_system", this.restaurant.prefered_payment_system);
            formData.append("timezone", this.restaurant.timezone);
            formData.append("default_tel_country", this.restaurant.default_tel_country);
            formData.append("currency", this.restaurant.currency);
            formData.append("lexicon", lexicon);
            formData.append(
                "show_to_realize_view",
                (lexicon === this.LEXICON_RESTAURANT.value ? true : this.restaurant.show_to_realize_view) ? 1 : 0
            );
            formData.append("feat_seating_plan", (lexicon === this.LEXICON_RESTAURANT.value ? true : this.restaurant.feat_seating_plan) ? 1 : 0);
            formData.append(
                "google_maps_address",
                this.restaurant.google_maps_address !== null ? JSON.stringify(this.restaurant.google_maps_address) : null
            );
            if (this.restaurant.logo.file) {
                formData.append("logo", this.restaurant.logo.file);
            }
            const cgvData = this.$refs.cgvComponent.getParams();
            Object.keys(cgvData).forEach((key) => {
                if (cgvData[key]) formData.append(key, cgvData[key]);
            });

            for (const [key, value] of Object.entries(this.licences)) {
                if (value) {
                    formData.append(`subscriptions_${key}`, value);
                }
            }

            return formData;
        },
        postForm: function (e) {
            e.preventDefault();
            this.formLoading = true;
            this.formErrors = null;
            const formData = this.buildForm();
            var promise = null;

            if (this.restaurant.id) {
                promise = this.$store.dispatch("restaurants/editRestaurant", {
                    restaurant_id: this.restaurant.id,
                    formData,
                });
            } else {
                promise = this.$store.dispatch("restaurants/addRestaurant", {
                    formData,
                });
            }

            promise
                .then((response) => {
                    this.$store.dispatch("restaurants/fetchAllRestaurants", { store: true }).then((response) => {
                        this.formLoading = false;

                        this.fillRestaurant(response.data.data[0]);
                        this.$nextTick(() => {
                            this.$emit("done");
                        });
                    });
                })
                .catch((error) => {
                    if (error.response && error.response.data.errors) {
                        this.formErrors = error.response.data.errors;
                    }
                    this.formLoading = false;
                });
        },
        hasErrors(key) {
            return this.formErrors !== null && this.formErrors[key] && this.formErrors[key].length > 0;
        },
        joinErrors(key) {
            return this.formErrors[key].join("<br/>");
        },
    },
    components: {
        LoaderComponent,
        ImageComponent,
        "chrome-picker": Chrome,
        Cgv,
        TimeZonePicker,
        ShowErrors,
        ChooseCurrency,
        ChooseLexicon,
        PostalAddressAutocomplete,
        InputComponent,
        SwitchInputComponent,
    },
    mixins: [ModuleTypesEnum, ModulePlanTypesEnum, CardPaymentSystemEnum, LexiconEnum],
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
