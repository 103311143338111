<template>
    <div>
        <LoaderComponent v-if="loading > 0" />
        <div v-else>
            <div>
                <div class="row m-0">
                    <div class="col-md-6" v-for="(smsPack, index) in smsPacks" :key="index">
                        <div class="border-light b-radius-20 p-4 mb-3">
                            <div class="w-100 d-flex justify-content-between">
                                <h6>{{ $t("labels.invoices.smsPack", { nb: smsPack.nb_sms }) }}</h6>
                                <h6>{{ smsPack.price_ht / 100 }} € {{ $tl("labels.ht") }}</h6>
                            </div>
                            <div class="text-right">
                                <small class="text-muted"
                                    >{{ smsPack.price_ht / 100 / smsPack.nb_sms }} € {{ $tl("labels.ht") }} / {{ $tl("labels.credit") }}</small
                                >
                            </div>
                            <div class="text-right mt-4">
                                <button
                                    :disabled="isBuyingSmsPack"
                                    class="btn btn-sm btn-success btn-circle"
                                    @click="displaySmsPackModal(smsPack)"
                                    v-tooltip="isBuyingSmsPack ? { content: $t('infos.sms.pendingSmsPackOrder') } : undefined">
                                    {{ $tl("labels.form.actions.buy") }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <a href="#" class="d-block" @click="showInternationalSmsCostModal = true"
                            ><small>{{ $tl("infos.sms.foreignCost") }}</small></a
                        >
                    </div>
                </div>
            </div>
        </div>
        <show-sms-pack-modal v-if="showSmsPackModal" :smsPack="smsPack" @close="showSmsPackModal = false"></show-sms-pack-modal>
        <InternationalSmsCostModal v-if="showInternationalSmsCostModal" @close="showInternationalSmsCostModal = false" />
    </div>
</template>

<script>
import axios from "axios";
import showSmsPackModal from "../Modals/sms/ShowSmsPackModal";
import LoaderComponent from "../LoaderComponent.vue";
import InternationalSmsCostModal from "../Modals/sms/InternationalSmsCostModal";

export default {
    name: "SmsPack",
    data: function () {
        return {
            smsPacks: null,
            smsPack: null,
            showSmsPackModal: false,
            loading: 0,
            showInternationalSmsCostModal: false,
        };
    },
    computed: {
        isBuyingSmsPack() {
            return this.$store.getters["sms/isBuyingPack"];
        },
    },
    methods: {
        fetchPacks() {
            this.loading++;

            axios
                .get("/api/smsPack")
                .then((response) => {
                    this.smsPacks = response.data.data;
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading--;
                });
        },
        fetchSmsInfo() {
            this.loading++;

            this.$store.dispatch("sms/fetchSmsInfos").finally(() => this.loading--);
        },
        displaySmsPackModal(smsPack) {
            this.$set(this, "smsPack", smsPack);
            this.$nextTick(() => {
                this.showSmsPackModal = true;
            });
        },
    },
    created() {
        this.fetchPacks();
        this.fetchSmsInfo();
    },
    components: {
        showSmsPackModal,
        LoaderComponent,
        InternationalSmsCostModal,
    },
};
</script>
