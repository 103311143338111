export default {
    booking: {
        menus: {
            deleted: "Le menu a bien été supprimé",
        },
        seatingPlan: {
            rooms: {
                enabledForService: "La salle a été activée pour ce service",
                disabledForService: "La salle a été désactivée pour ce service",
            },
            tables: {
                enabledForService: "La table a été activée pour ce service",
                disabledForService: "La table a été désactivée pour ce service",
            },
            services: {
                added: "Le service a bien été ajouté.",
                duplicated: "Le service a bien été dupliqué",
                deleted: "Le service a bien été supprimé",
                nowFull: 'Les services séléctionnés sont désormais affichés "complet"',
                removeFull: "Le service sélectionné n'est plus complet",
            },
        },
    },
};
