var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : !_vm.website_enabled
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("Votre site web n'est pas activé"),
          ])
        : [
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                  },
                  [
                    _c("h5", { staticClass: "title mt-2" }, [
                      _vm._v("Configuration de la charte graphique"),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12 mb-3" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "border-light b-radius-20 p-4 bg-grad text-center",
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12 mb-3 text-white" }, [
                        _c("strong", { staticClass: "mb-3" }, [
                          _vm._v("Imformation importante"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 text-white" }, [
                        _c("span", { staticClass: "text-warning" }, [
                          _vm._v(
                            "Taille d'image maximale acceptée : 5000 px / 10 Mo."
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "text-white btn btn-sm btn-outline-secondary btn-circle mt-3",
                              attrs: {
                                href: "https://www.iloveimg.com/fr/compresser-image",
                                target: "_blank",
                              },
                            },
                            [
                              _c("feather", {
                                staticClass: "text-warning mr-1",
                                attrs: { type: "image" },
                              }),
                              _vm._v("Compresser une image"),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 mb-3" }, [
                      _c("strong", [_vm._v("Éléments graphiques")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-5 pt-2" }, [
                      _c("label", [
                        _vm._v("Police des titres "),
                        _c("small", { staticClass: "text-danger" }, [
                          _vm._v("*"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col-md-7 mb-2 d-flex align-items-center",
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.website_settings.font_title,
                                expression: "website_settings.font_title",
                              },
                            ],
                            staticClass: "custom-select m-0",
                            staticStyle: { width: "50%" },
                            attrs: {
                              disabled: !_vm.has_right_to_update_config,
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.website_settings,
                                  "font_title",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.availableFontsTitle, function (font) {
                            return _c(
                              "option",
                              {
                                key: font.name,
                                domProps: { value: font.value },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(font.name) +
                                    "\n                                "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "ml-2 font-previsu",
                            style:
                              "font-weight: 600; font-family: " +
                              _vm.website_settings.font_title,
                          },
                          [_vm._v("Notre Histoire")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-5 pt-2" }, [
                      _c("label", [
                        _vm._v("Police du corps de texte "),
                        _c("small", { staticClass: "text-danger" }, [
                          _vm._v("*"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col-md-7 mb-2 d-flex align-items-center",
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.website_settings.font_body,
                                expression: "website_settings.font_body",
                              },
                            ],
                            staticClass: "custom-select m-0",
                            staticStyle: { width: "50%" },
                            attrs: {
                              disabled: !_vm.has_right_to_update_config,
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.website_settings,
                                  "font_body",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.availableFontsBody, function (font) {
                            return _c(
                              "option",
                              {
                                key: font.name,
                                domProps: { value: font.value },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(font.name) +
                                    "\n                                "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "ml-2 font-previsu",
                            style:
                              "font-family: " + _vm.website_settings.font_body,
                          },
                          [_vm._v("Corps de texte")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-5 pt-2" }, [
                      _c("label", [
                        _vm._v("Police de la barre de navigation "),
                        _c("small", { staticClass: "text-danger" }, [
                          _vm._v("*"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col-md-7 mb-2 d-flex align-items-center",
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.website_settings.font_nav,
                                expression: "website_settings.font_nav",
                              },
                            ],
                            staticClass: "custom-select m-0",
                            staticStyle: { width: "50%" },
                            attrs: {
                              disabled: !_vm.has_right_to_update_config,
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.website_settings,
                                  "font_nav",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.availableFontsNav, function (font) {
                            return _c(
                              "option",
                              {
                                key: font.name,
                                domProps: { value: font.value },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(font.name) +
                                    "\n                                "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "ml-2 font-previsu",
                            style:
                              "font-family: " + _vm.website_settings.font_nav,
                          },
                          [_vm._v("Gallerie")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-5 mt-2" }, [
                      _c("label", { staticClass: "mb-0" }, [
                        _vm._v("Logo de la barre de navigation "),
                        _c("small", { staticClass: "text-danger" }, [
                          _vm._v("*"),
                        ]),
                      ]),
                      _c("br"),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v("Noir ou couleur"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-7 mb-1 mt-1" },
                      [
                        _c("ImageComponent", {
                          attrs: {
                            disabled: !_vm.has_right_to_update_config,
                            suffixId: "logo_nav",
                            src: _vm.website_settings.logo_nav_img,
                            postURI: _vm.basePostURL + "logo_nav",
                            callBack: (response) => {
                              _vm.website_settings.logo_nav_img =
                                response.data.img
                            },
                            maxSize: 10,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-5 mt-2" }, [
                      _c("label", { staticClass: "mb-0" }, [
                        _vm._v("Logo principal "),
                        _c("small", { staticClass: "text-danger" }, [
                          _vm._v("*"),
                        ]),
                      ]),
                      _c("br"),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v("De préférence blanc"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-7 mb-1 mt-1" },
                      [
                        _c("ImageComponent", {
                          attrs: {
                            disabled: !_vm.has_right_to_update_config,
                            suffixId: "logo_banner",
                            src: _vm.website_settings.logo_banner_img,
                            postURI: _vm.basePostURL + "logo_banner",
                            callBack: (response) => {
                              _vm.website_settings.logo_banner_img =
                                response.data.img
                            },
                            maxSize: 10,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0 mt-3" }, [
              _c("div", { staticClass: "col-12 d-inline-block" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success btn-circle mr-1",
                    attrs: {
                      type: "button",
                      disabled: !_vm.has_right_to_update_config,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.postForm()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Enregistrer\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    'Pour rendre vos modifications visibles sur votre site internet, pensez à "'
                  ),
                  _c("strong", [_vm._v("Enregistrer et publier")]),
                  _vm._v('" en cliquant sur le bouton dans la navigation.'),
                ]),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }