var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pb-5 pt-5" }, [
    _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [
            _c("h5", { staticClass: "title mt-2" }, [_vm._v("Catégories")]),
            _vm._v(" "),
            _vm.has_right_to_create_categories
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-success mr-1 btn-circle",
                    on: {
                      click: function ($event) {
                        _vm.showAddCategoryModal = true
                      },
                    },
                  },
                  [
                    _c("feather", { attrs: { type: "plus" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Ajouter une catégorie")]),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row m-0" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.isLoading
            ? _c("LoaderComponent")
            : _c("div", [
                _vm.error
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _vm._v(_vm._s(_vm.error)),
                    ])
                  : _c("div", [
                      _c(
                        "div",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "table",
                            {
                              staticClass:
                                "capitalize table table-sm table-striped border-bottom",
                            },
                            [
                              _c("thead", { staticClass: "border-bottom" }, [
                                _c("tr", [
                                  _c("th", [_vm._v("Ordre")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Nom")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Actions")]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "draggable",
                                {
                                  tag: "transition-group",
                                  attrs: {
                                    tag: "tbody",
                                    disabled:
                                      !_vm.has_right_to_update_categories ||
                                      _vm.selectedCategory !== null,
                                    name: !_vm.isDragged ? "flip-list" : null,
                                  },
                                  on: {
                                    start: function ($event) {
                                      _vm.isDragged = true
                                    },
                                    end: function ($event) {
                                      _vm.isDragged = false
                                    },
                                  },
                                  model: {
                                    value: _vm.categories,
                                    callback: function ($$v) {
                                      _vm.categories = $$v
                                    },
                                    expression: "categories",
                                  },
                                },
                                [
                                  _vm._l(_vm.categories, function (category) {
                                    return [
                                      _vm.selectedCategory === null ||
                                      _vm.selectedCategory === category.id
                                        ? _c(
                                            "tr",
                                            {
                                              key: category.id,
                                              staticClass: "categories",
                                            },
                                            [
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                },
                                                [
                                                  _vm.selectedCategory === null
                                                    ? _c(
                                                        "svg",
                                                        {
                                                          staticClass:
                                                            "css-i6dzq1 feather mr-1",
                                                          staticStyle: {
                                                            cursor: "grab",
                                                            color:
                                                              "rgb(170, 170, 170)",
                                                          },
                                                          attrs: {
                                                            viewBox:
                                                              "0 0 24 24",
                                                            width: "24",
                                                            height: "24",
                                                            stroke:
                                                              "currentColor",
                                                            "stroke-width": "2",
                                                            fill: "none",
                                                            "stroke-linecap":
                                                              "round",
                                                            "stroke-linejoin":
                                                              "round",
                                                          },
                                                        },
                                                        [
                                                          _c("line", {
                                                            attrs: {
                                                              x1: "3",
                                                              y1: "9",
                                                              x2: "21",
                                                              y2: "9",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("line", {
                                                            attrs: {
                                                              x1: "3",
                                                              y1: "15",
                                                              x2: "21",
                                                              y2: "15",
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn pl-0",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.selectedCategory =
                                                                null
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("feather", {
                                                            staticClass:
                                                              "feather-24",
                                                            attrs: {
                                                              type: "chevron-left",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm.selectedCategory !== null
                                                  ? _c("strong", [
                                                      _vm._v(
                                                        _vm._s(category.name)
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(category.name)
                                                      ),
                                                    ]),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    width: "300px",
                                                  },
                                                },
                                                [
                                                  _vm.has_right_to_update_categories
                                                    ? _c(
                                                        "button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "tooltip",
                                                              rawName:
                                                                "v-tooltip",
                                                              value:
                                                                _vm.getTooltip(
                                                                  "Éditer"
                                                                ),
                                                              expression:
                                                                "getTooltip('Éditer')",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "btn btn-sm btn-success btn-square",
                                                          attrs: {
                                                            title:
                                                              "Modifier la réservation",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.prepareEditCategory(
                                                                category
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("feather", {
                                                            attrs: {
                                                              type: "edit",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.has_right_to_delete_categories &&
                                                  category.can_delete
                                                    ? _c(
                                                        "button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "tooltip",
                                                              rawName:
                                                                "v-tooltip",
                                                              value:
                                                                _vm.getTooltip(
                                                                  "Supprimer"
                                                                ),
                                                              expression:
                                                                "getTooltip('Supprimer')",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "btn btn-sm btn-danger btn-square",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteCategory(
                                                                category.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("feather", {
                                                            attrs: {
                                                              type: "trash-2",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "tooltip",
                                                              rawName:
                                                                "v-tooltip",
                                                              value:
                                                                _vm.getTooltip(
                                                                  "Vous ne pouvez pas supprimer cette catégorie, car c'est la catégorie par défaut"
                                                                ),
                                                              expression:
                                                                "\n                                                    getTooltip('Vous ne pouvez pas supprimer cette catégorie, car c\\'est la catégorie par défaut')\n                                                ",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "btn btn-sm btn-secondary btn-square",
                                                        },
                                                        [
                                                          _c("feather", {
                                                            attrs: {
                                                              type: "trash-2",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                  _vm._v(" "),
                                                  _vm.selectedCategory ===
                                                    null &&
                                                  _vm.has_right_to_update_categories &&
                                                  _vm.config &&
                                                  _vm.config.display_categories
                                                    ? _c(
                                                        "button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "tooltip",
                                                              rawName:
                                                                "v-tooltip",
                                                              value:
                                                                _vm.getTooltip(
                                                                  "Ordonner les produits de cette catégorie"
                                                                ),
                                                              expression:
                                                                "getTooltip('Ordonner les produits de cette catégorie')",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "btn btn-sm btn-outline-secondary btn-square",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.displayOneCategory(
                                                                category.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "css-i6dzq1 feather",
                                                              attrs: {
                                                                viewBox:
                                                                  "0 0 24 24",
                                                                width: "24",
                                                                height: "24",
                                                                stroke:
                                                                  "currentColor",
                                                                "stroke-width":
                                                                  "2",
                                                                fill: "none",
                                                                "stroke-linecap":
                                                                  "round",
                                                                "stroke-linejoin":
                                                                  "round",
                                                              },
                                                            },
                                                            [
                                                              _c("polyline", {
                                                                attrs: {
                                                                  points:
                                                                    "9 5 12 2 15 5",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("polyline", {
                                                                attrs: {
                                                                  points:
                                                                    "15 19 12 22 9 19",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("line", {
                                                                attrs: {
                                                                  x1: "12",
                                                                  y1: "2",
                                                                  x2: "12",
                                                                  y2: "22",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.selectedCategory !== null
                                ? [
                                    _vm.isLoadingProduct
                                      ? _c("LoaderComponent")
                                      : _c(
                                          "draggable",
                                          {
                                            attrs: {
                                              tag: "tbody",
                                              draggable:
                                                _vm.has_right_to_update_categories
                                                  ? ".products"
                                                  : "",
                                            },
                                            model: {
                                              value: _vm.products,
                                              callback: function ($$v) {
                                                _vm.products = $$v
                                              },
                                              expression: "products",
                                            },
                                          },
                                          _vm._l(
                                            _vm.products,
                                            function (product) {
                                              return _c(
                                                "tr",
                                                {
                                                  key: product.id,
                                                  staticClass: "products",
                                                },
                                                [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        cursor: "grab",
                                                        width: "70px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        {
                                                          staticClass:
                                                            "css-i6dzq1 feather mr-1",
                                                          staticStyle: {
                                                            color:
                                                              "rgb(170, 170, 170)",
                                                          },
                                                          attrs: {
                                                            viewBox:
                                                              "0 0 24 24",
                                                            width: "24",
                                                            height: "24",
                                                            stroke:
                                                              "currentColor",
                                                            "stroke-width": "2",
                                                            fill: "none",
                                                            "stroke-linecap":
                                                              "round",
                                                            "stroke-linejoin":
                                                              "round",
                                                          },
                                                        },
                                                        [
                                                          _c("line", {
                                                            attrs: {
                                                              x1: "3",
                                                              y1: "9",
                                                              x2: "21",
                                                              y2: "9",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("line", {
                                                            attrs: {
                                                              x1: "3",
                                                              y1: "15",
                                                              x2: "21",
                                                              y2: "15",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(product.name)
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.showAddCategoryModal
                            ? _c("add-gift-vouchers-category-modal", {
                                attrs: {
                                  "current-category": _vm.currentCategory,
                                },
                                on: {
                                  "category-added": _vm.addCategory,
                                  "category-edited": _vm.editCategory,
                                  close: function ($event) {
                                    _vm.showAddCategoryModal = false
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
              ]),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    !_vm.isLoading && _vm.has_right_to_update_categories
      ? _c("div", { staticClass: "row m-0 mb-3" }, [
          _c(
            "div",
            { staticClass: "col-12 text-muted" },
            [
              _vm._v(
                "\n            Vous avez la possibilité d'ordonner vos\n            "
              ),
              _vm.selectedCategory === null
                ? [_vm._v("catégories")]
                : [_vm._v("produits")],
              _vm._v(" en cliquant sur l'icone\n            "),
              _c(
                "svg",
                {
                  staticClass: "css-i6dzq1 feather mr-1",
                  staticStyle: { color: "rgb(170, 170, 170)" },
                  attrs: {
                    viewBox: "0 0 24 24",
                    width: "24",
                    height: "24",
                    stroke: "currentColor",
                    "stroke-width": "2",
                    fill: "none",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                  },
                },
                [
                  _c("line", {
                    attrs: { x1: "3", y1: "9", x2: "21", y2: "9" },
                  }),
                  _vm._v(" "),
                  _c("line", {
                    attrs: { x1: "3", y1: "15", x2: "21", y2: "15" },
                  }),
                ]
              ),
              _vm._v(
                "\n            et en déplaçant la ligne. Pensez à enregistrer vos changements.\n        "
              ),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isLoading
      ? _c("div", { staticClass: "row m-0" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success btn-circle",
                attrs: {
                  disabled: !_vm.has_right_to_update_categories,
                  type: "button",
                },
                on: { click: _vm.OrderItems },
              },
              [_vm._v("\n                Enregistrer\n            ")]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }