import { render, staticRenderFns } from "./MenuAndOptionsGroup.vue?vue&type=template&id=12a9b078"
import script from "./MenuAndOptionsGroup.vue?vue&type=script&lang=js"
export * from "./MenuAndOptionsGroup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('12a9b078')) {
      api.createRecord('12a9b078', component.options)
    } else {
      api.reload('12a9b078', component.options)
    }
    module.hot.accept("./MenuAndOptionsGroup.vue?vue&type=template&id=12a9b078", function () {
      api.rerender('12a9b078', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/services/addServiceForm/MenuAndOptionsGroup.vue"
export default component.exports