var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-inline align-middle w-100" }, [
    _c(
      "span",
      {
        staticClass:
          "text-uppercase text-center align-middle d-block bg-danger text-white p-1",
      },
      [
        _vm._v("\n        Des licences comportent des erreurs :\n        "),
        _c(
          "u",
          [
            _c(
              "router-link",
              {
                staticClass: "text-white",
                attrs: { to: { name: "subscription" } },
              },
              [_vm._v("Vérifier mes licences ")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }