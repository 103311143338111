<template>
    <div>
        <loader-component v-if="isLoading" />
        <div v-else>
            <div class="row mt-2" v-for="log in logs" :key="log.id">
                <div class="col-md-9">
                    <span v-if="log.crud_type === CRUD_TYPE_CREATE.value">
                        Le code promo a été créé par <strong>{{ displayUserName(log.user, log.user_type) }}</strong>
                    </span>
                    <span v-else-if="log.crud_type === CRUD_TYPE_UPDATE.value">
                        Le code promo a été {{ log.is_active ? "activé" : "désactivé" }} par
                        <strong>{{ displayUserName(log.user, log.user_type) }}</strong>
                    </span>
                    <span v-else-if="log.crud_type === CRUD_TYPE_SOFT_DELETE.value" class="text-danger">
                        Le code promo a été archivé par
                        <strong>{{ displayUserName(log.user, log.user_type) }}</strong>
                    </span>
                </div>
                <div class="col-md-3 text-right">
                    <i>
                        <small>{{ displayLogDate(log) }}</small>
                    </i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LoaderComponent from "../../../LoaderComponent.vue";
import CrudTypeEnum from "../../../../mixins/enums/CrudTypeEnum.js";
import UserTypeEnum from "../../../../mixins/enums/UserTypeEnum.js";

export default {
    props: {
        promoCode: {
            type: Object,
            required: true,
        },
    },
    components: {
        LoaderComponent,
    },
    mixins: [CrudTypeEnum, UserTypeEnum],
    data() {
        return {
            isLoading: false,
            logs: [],
        };
    },
    created() {
        this.getLogs();
    },
    methods: {
        displayUserName(user, userType) {
            if (userType === this.USER_TYPE_USER.value && user !== null) {
                return `${user.firstname || ""} ${user.lastname || ""}`;
            } else if (userType === this.USER_TYPE_MACHINE.value) {
                return "le système";
            }
            return "";
        },
        displayLogDate(log) {
            return this.displayDate(log.created_at, this.DATETIME_SHORT);
        },
        getLogs() {
            this.isLoading = true;
            this.httpGet(`/api/admin/promo_codes/${this.promoCode.id}/logs`)
                .then((response) => {
                    if (response !== false) {
                        this.logs = response.data;
                    }
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
