var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c(
            "form",
            {
              staticClass: "file-uploader-component",
              attrs: { action: "javascript:void();" },
            },
            [
              _c("div", [
                _c(
                  "label",
                  {
                    staticClass: "label-file mr-2",
                    class: {
                      "text-muted": _vm.disabled,
                      "cursor-d": _vm.disabled,
                    },
                    attrs: { for: _vm.formFileId },
                  },
                  [_vm._v(_vm._s(_vm.getLabel))]
                ),
                _vm._v(" "),
                _c("input", {
                  ref: "uploadFile",
                  staticClass: "input-file",
                  attrs: {
                    disabled: _vm.disabled,
                    type: "file",
                    id: _vm.formFileId,
                    accept: _vm.allowedExtensions,
                  },
                  on: { change: _vm.onInputFileChanges },
                }),
                _vm._v(" "),
                _vm.hasDirectUpload && _vm.unsaveChanges
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-success btn-sm btn-circle",
                        attrs: { disabled: _vm.disabled, type: "button" },
                        on: { click: _vm.postFile },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$tl("labels.form.actions.send")) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.checkFile()
                  ? _c("a", { attrs: { href: this.src, target: "_blank" } }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success btn-sm btn-circle",
                          attrs: { type: "button" },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$tl("labels.form.file.view")) +
                              "\n                "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.checkFile() && _vm.deleteURI !== null
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-danger btn-sm btn-circle",
                        attrs: { type: "button" },
                        on: { click: _vm.deleteFile },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$tl("labels.form.file.delete")) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isValidFile(_vm.file)
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-danger btn-sm btn-circle",
                        attrs: { type: "button" },
                        on: { click: _vm.cancel },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$tl("labels.form.actions.cancel")) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
      _vm._v(" "),
      _c("ShowErrors", { attrs: { errors: _vm.errors, "error-key": "file" } }),
      _vm._v(" "),
      _vm.isFileTooBig
        ? _c("span", { staticClass: "text-danger" }, [
            _c("small", [
              _vm._v(
                _vm._s(
                  _vm.$t("errors.common.file.tooBig", {
                    fileSize: _vm.fileSize,
                    maxSize: _vm.maxSize,
                  })
                )
              ),
            ]),
          ])
        : _vm.hasDirectUpload && _vm.unsaveChanges
        ? _c("span", { staticClass: "text-danger" }, [
            _c("small", [
              _vm._v(_vm._s(_vm.$tl("labels.form.file.dontForgetSave"))),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }