import moment from "moment";
import SaleStatusEnum from "./SaleStatusEnum";

export default {
    data() {
        return {
            SALE_COLUMN_STATUS: {
                label: "le statut",
                value: "status",
                valueLabel: (log) => this.capitalize(this.getSaleStatusLabel(log.status)),
                icon: "user",
            },
            SALE_COLUMN_EXPIRES_ON: {
                label: "la date d'expiration",
                value: "expires_on",
                valueLabel: (log) => moment(log.expires_on).format("DD/MM/Y"),
                icon: "calendar",
            },
            SALE_COLUMN_BENEFICIARY_INFOS: {
                label: "les coordonnées du bénéficiaire",
                value: "beneficiary_infos",
                valueLabel: (log) => {
                    let name = `${log.beneficiary_firstname} ${log.beneficiary_lastname}`;
                    if (log.beneficiary_email !== null) {
                        name += ` - ${log.beneficiary_email}`;
                    }
                    return name;
                },
                icon: "user",
            },
            SALE_COLUMN_DELIVERY_ADDRESS: {
                label: "l'adresse de livraison",
                value: "delivery_address",
                valueLabel: (log) =>
                    `${log.delivery_firstname} ${log.delivery_lastname}, ${log.delivery_address}, ${log.delivery_postal_code}, ${log.delivery_city}, ${log.delivery_country}`,
                icon: "package",
            },
            SALE_COLUMN_COMMENT_PRIVATE: {
                label: "le commentaire",
                value: "comment_private",
                valueLabel: (log) => log.comment_private,
                icon: "message-square",
            },
            SALE_COLUMN_USED: {
                label: "l'état",
                value: "used",
                valueLabel: (log) => (log.used ? "Utilisé" : "Non utilisé"),
                icon: "check",
            },
        };
    },
    mixins: [SaleStatusEnum],
    methods: {
        getSaleColumnLabel(value, log) {
            const found = this.ALL_SALE_COLUMNS.find((w) => w.value == value);
            if (typeof found != "undefined") return typeof found.label == "function" ? found.label(log) : found.label;
            return value;
        },
        getSaleColumnValueLabel(value, log) {
            const found = this.ALL_SALE_COLUMNS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.valueLabel(log);
            return value;
        },
        getSaleColumnIcon(value, log) {
            const found = this.ALL_SALE_COLUMNS.find((w) => w.value == value);
            if (typeof found != "undefined") return typeof found.icon == "function" ? found.icon(log) : found.icon;
            return "x";
        },
    },
    computed: {
        ALL_SALE_COLUMNS() {
            return [
                this.SALE_COLUMN_STATUS,
                this.SALE_COLUMN_EXPIRES_ON,
                this.SALE_COLUMN_BENEFICIARY_INFOS,
                this.SALE_COLUMN_DELIVERY_ADDRESS,
                this.SALE_COLUMN_COMMENT_PRIVATE,
                this.SALE_COLUMN_USED,
            ];
        },
    },
};
