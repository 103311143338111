<template>
    <button
        class="btn btn-sm btn-outline-secondary btn-square"
        v-if="command.status != COMMAND_COLLECTED.value"
        @click="markDelivered()"
        :disabled="loading"
        v-tooltip="{ delay: { show: 400, hide: 0 }, content: 'Marquer la commande comme \'Livrée\'' }">
        <feather type="check" />
    </button>
</template>

<script>
import CommandStatusEnum from "../../../mixins/enums/click_and_collect/CommandStatusEnum";

export default {
    data() {
        return {
            loading: false,
        };
    },
    props: {
        command: {
            type: Object,
            required: true,
        },
    },
    mixins: [CommandStatusEnum],
    methods: {
        markDelivered() {
            this.loading = true;

            this.$store
                .dispatch("ccCommands/updateStatus", {
                    restaurant_id: this.command.restaurant_id,
                    command_id: this.command.id,
                    status: this.COMMAND_COLLECTED.value,
                })
                .then((response) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    this.$emit("command-updated", this.command);
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
    },
};
</script>
