<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ $tl("labels.booking.reservations.details") }}</h5>
        <div slot="body">
            <validate-reservation
                :showValidateButton="false"
                :reservation_id="reservation_id"
                :restaurant_id="restaurant_id"
                @reservation-get="setReservation($event)" />
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="close">
                {{ $tl("labels.form.actions.close") }}
            </button>
            <button
                v-if="reservation && inEnum(reservation.status, editableReservationStatus)"
                class="btn btn-sm btn-success btn-circle"
                @click="$emit('validate-resa', { restaurant_id, reservation_id })">
                {{ $tl("labels.form.actions.validate") }}
            </button>
        </div>
    </modal>
</template>

<script>
import ValidateReservation from "../../reservations/ValidateReservation.vue";
import ReservationStatusEnum from "../../../mixins/enums/booking/ReservationStatusEnum.js";

export default {
    data() {
        return {
            reservation: null,
        };
    },
    mixins: [ReservationStatusEnum],
    props: {
        reservation_id: {
            default: "",
        },
        restaurant_id: {
            default: "",
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
        setReservation(reservation) {
            this.reservation = reservation;
        },
    },
    components: {
        ValidateReservation,
    },
};
</script>
