var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container col-12" },
    [
      _vm.restaurants.length <= _vm.sizeForAlphabeticGroups
        ? _c(
            "div",
            { staticClass: "d-flex flex-column flex-wrap flex-md-row" },
            _vm._l(
              _vm.sortElementsForGrid(_vm.sortedRestaurants, 3),
              function (restaurant) {
                return _c(
                  "label",
                  {
                    key: restaurant.id,
                    staticClass:
                      "container-box selected__establishment col-4 mr-0",
                  },
                  [
                    _c("input", {
                      attrs: { type: "checkbox" },
                      domProps: {
                        value: restaurant.id,
                        checked: _vm.localSelectedRestaurants.includes(
                          restaurant.id
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.toggleRestaurant(restaurant.id)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "checkmark" }),
                    _vm._v(
                      "\n            " + _vm._s(restaurant.name) + "\n        "
                    ),
                  ]
                )
              }
            ),
            0
          )
        : _vm._l(_vm.alphabeticGroups, function (restaurants, range) {
            return _c(
              "div",
              { key: range },
              [
                _c(
                  "h5",
                  { staticClass: "text-success mb-4 font-weight-bold" },
                  [_vm._v(_vm._s(range))]
                ),
                _vm._v(" "),
                _vm._l(
                  _vm.sortElementsForGrid(restaurants, 3),
                  function (restaurant) {
                    return _c(
                      "label",
                      {
                        key: restaurant.id,
                        staticClass:
                          "container-box selected__establishment col-4",
                      },
                      [
                        _c("input", {
                          attrs: { type: "checkbox" },
                          domProps: {
                            value: restaurant.id,
                            checked: _vm.localSelectedRestaurants.includes(
                              restaurant.id
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.toggleRestaurant(restaurant.id)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkmark" }),
                        _vm._v(
                          "\n            " +
                            _vm._s(restaurant.name) +
                            "\n        "
                        ),
                      ]
                    )
                  }
                ),
                _vm._v(" "),
                _c("div", { staticClass: "sepa" }),
              ],
              2
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }