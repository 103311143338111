export default {
    booking: {
        services: {
            delete: "Are you sure you want to delete this game?",
            removeFull: "Open the game to reservations?",
            openServiceForDate: 'The "{service}" game will be open for reservations for {date}. Do you want to continue?',
            openSlotForDate: 'The {hour} slot for the "{service}" game will be open for reservations for {date}. Do you want to continue?',
        },
    },
};
