<template>
    <div>
        <loader-component v-if="loading" />
        <tab-bar v-else direction="horizontal" side="top" :entries="tabBarEntries" />
        <router-view />
    </div>
</template>

<script>
import TabBar from "../../components/tabBar/TabBar";
import LoaderComponent from "../../components/LoaderComponent";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum";

export default {
    data() {
        return {
            loading: false,
            is_click_and_collect_enabled: false,
            has_collect_cmds: false,
            is_delivery_enabled: false,
            has_delivery_cmds: false,
            is_dispatch_enabled: false,
            has_dispatch_cmds: false,
        };
    },
    mixins: [ModuleTypesEnum],
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        tabBarEntries() {
            let entries = [];
            if (this.is_click_and_collect_enabled || this.has_collect_cmds)
                entries.push({
                    to: {
                        name: "click_and_collect.restaurants.sales.collect",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Retrait",
                    exactUrlMatching: false,
                });
            if (this.is_delivery_enabled || this.has_delivery_cmds)
                entries.push({
                    to: {
                        name: "click_and_collect.restaurants.sales.delivery",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Livraison",
                    exactUrlMatching: false,
                });
            if (this.is_dispatch_enabled || this.has_dispatch_cmds)
                entries.push({
                    to: {
                        name: "click_and_collect.restaurants.sales.dispatch",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Expédition",
                    exactUrlMatching: false,
                });
            return entries;
        },
    },
    components: {
        TabBar,
        LoaderComponent,
    },
    created() {
        if (this.$route.name === "click_and_collect.restaurants.sales") this.loading = true;
        axios
            .get(`/api/click_and_collect/${this.restaurant_id}/settings?include=has_commands_for_type`)
            .then((response) => {
                this.loading = false;
                this.is_click_and_collect_enabled = response.data.is_click_and_collect_enabled;
                this.has_collect_cmds = response.data.has_commands_for_type.collect;
                this.is_delivery_enabled = response.data.is_delivery_enabled;
                this.has_delivery_cmds = response.data.has_commands_for_type.delivery;
                this.is_dispatch_enabled = response.data.is_dispatch_enabled;
                this.has_dispatch_cmds = response.data.has_commands_for_type.dispatch;
                if (this.$route.name === "click_and_collect.restaurants.sales") {
                    this.$nextTick(() => {
                        if (this.is_click_and_collect_enabled)
                            this.$router.push({ name: "click_and_collect.restaurants.sales.collect" });
                        else if (this.is_delivery_enabled)
                            this.$router.push({ name: "click_and_collect.restaurants.sales.delivery" });
                        else if (this.is_dispatch_enabled)
                            this.$router.push({ name: "click_and_collect.restaurants.sales.dispatch" });
                    });
                }
            })
            .catch(() => {
                this.loading = false;
                this.is_click_and_collect_enabled = true;
                this.has_collect_cmds = true;
                this.is_delivery_enabled = true;
                this.has_delivery_cmds = true;
                this.is_dispatch_enabled = true;
                this.has_dispatch_cmds = true;
            });
    },
};
</script>
