<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="errors.fetchAccount" class="alert alert-danger">
                {{ errors.fetchAccount }}
            </div>
            <div v-else class="default-home">
                <div class="row m-0">
                    <div class="col-12">
                        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                            <h5 class="title mt-2">{{ $tl("labels.routes.account") }}</h5>
                        </div>
                    </div>
                </div>

                <div v-if="errors.form" class="alert alert-danger">
                    <p v-for="error in errors.form">
                        {{ error }}
                    </p>
                </div>

                <div v-if="formSuccess" class="alert alert-success">
                    {{ formSuccess }}
                </div>

                <LoaderComponent v-if="formLoading" />
                <form v-else @submit="postForm" method="post">
                    <div class="row m-0">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-12">
                                    <div class="border-light b-radius-20 p-4 mb-3">
                                        <div class="row mb-2">
                                            <div class="col-12">
                                                <strong>{{ $tl("labels.personalInfos") }}</strong>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-5 pt-2">
                                                <label for="firstname">{{ $tl("labels.form.firstname") }} <small>*</small></label>
                                            </div>
                                            <div class="col-7">
                                                <input
                                                    :disabled="isOwner"
                                                    type="text"
                                                    name="firstname"
                                                    v-model="firstname"
                                                    id="firstname"
                                                    required
                                                    class="form-control" />
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-5 pt-2">
                                                <label for="lastname">{{ $tl("labels.form.lastname") }} <small>*</small></label>
                                            </div>
                                            <div class="col-7">
                                                <input
                                                    :disabled="isOwner"
                                                    type="text"
                                                    name="lastname"
                                                    v-model="lastname"
                                                    id="lastname"
                                                    required
                                                    class="form-control" />
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-5 pt-2">
                                                <label for="email">{{ $tl("labels.form.email") }} <small>*</small></label>
                                            </div>
                                            <div class="col-7">
                                                <input
                                                    :disabled="isOwner"
                                                    type="email"
                                                    name="email"
                                                    v-model="email"
                                                    id="email"
                                                    required
                                                    class="form-control" />
                                            </div>
                                        </div>
                                        <div class="row mb-2" v-if="!isAdmin">
                                            <div class="col-5 pt-2">
                                                <label class="mb-0" for="tel">{{ $tl("labels.form.phoneNumber") }} <small>*</small></label>
                                                <br />
                                                <small class="text-muted">{{ $tl("infos.account.changePhoneNumber") }}</small>
                                            </div>
                                            <div class="col-7">
                                                <vue-tel-input
                                                    :disabled="isOwner"
                                                    defaultCountry="FR"
                                                    name="telInput"
                                                    class="mb-2 form-control"
                                                    @validate="setPhoneAndCountry"
                                                    v-model="phone.raw"
                                                    :enabledCountryCode="true"
                                                    :wrapperClasses="phone.isValid === null || phone.isValid ? '' : 'border border-danger'"
                                                    inputClasses="m-0 border-0"
                                                    mode="international"
                                                    :placeholder="$t('labels.form.phoneNumber')"
                                                    required></vue-tel-input>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-5 pt-2">
                                                <label>
                                                    {{ $tl("labels.form.lang") }} <small>*</small>
                                                    <feather
                                                        v-if="!isAdmin"
                                                        v-tooltip="getTooltipNotice('notices.lang')"
                                                        type="info"
                                                        class="ml-1 pointer tooltip-infos" />
                                                    <ShowNewBadgeDetails badge_uuid="3c0f6651-a664-47b9-8696-ce9139dcb05b" />
                                                </label>
                                            </div>
                                            <div class="col-7">
                                                <select class="custom-select mb-0" v-model="lang" required>
                                                    <option v-for="lang in ALL_LANGS" :key="lang.value" :value="lang.value">
                                                        {{ lang.label }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="has_right_to_read_subscription && !isAdmin">
                                <div class="col-12">
                                    <div class="border-light b-radius-20 p-4 mb-3">
                                        <div class="row mb-2">
                                            <div class="col-12">
                                                <strong>{{ $tl("labels.form.facturation.title") }}</strong>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-5 pt-2">
                                                <label>{{ $tl("labels.form.businessName") }} <small>*</small></label>
                                            </div>
                                            <div class="col-7">
                                                <input :disabled="true" type="text" name="company" v-model="company" required class="form-control" />
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-5 pt-2">
                                                <label>{{ $tl("labels.form.businessType") }} <small>*</small></label>
                                            </div>
                                            <div class="col-7">
                                                <select
                                                    class="custom-select mb-0"
                                                    id="company_type"
                                                    name="companyType"
                                                    v-model="companyType"
                                                    :disabled="true"
                                                    required>
                                                    <option
                                                        v-for="businessType in ALL_BUSINESS_TYPES"
                                                        :key="businessType.value"
                                                        :value="businessType.value">
                                                        {{ getBusinessTypeLabel(businessType.value) }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-5 pt-2">
                                                <label>{{ $tl("labels.form.facturation.siret") }} <small>*</small></label>
                                            </div>
                                            <div class="col-7">
                                                <input
                                                    :disabled="true"
                                                    minlength="14"
                                                    maxlength="14"
                                                    type="text"
                                                    name="companySiret"
                                                    v-model="companySiret"
                                                    required
                                                    class="form-control" />
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-5 pt-2">
                                                <label>{{ $tl("labels.form.facturation.nafCode") }} <small>*</small></label>
                                            </div>
                                            <div class="col-7">
                                                <input
                                                    :disabled="true"
                                                    type="text"
                                                    name="companyCodeNaf"
                                                    v-model="companyCodeNaf"
                                                    class="form-control" />
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-5 pt-2">
                                                <label>{{ $tl("labels.form.facturation.tvaNumber") }} <small>*</small></label>
                                            </div>
                                            <div class="col-7">
                                                <input
                                                    :disabled="true"
                                                    type="text"
                                                    name="companyCodeTva"
                                                    v-model="companyCodeTva"
                                                    class="form-control" />
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-5 pt-2">
                                                <label>{{ $tl("labels.form.address") }} <small>*</small></label>
                                            </div>
                                            <div class="col-7">
                                                <input
                                                    :disabled="true"
                                                    type="text"
                                                    name="companyAddress"
                                                    v-model="companyAddress"
                                                    required
                                                    class="form-control" />
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-5 pt-2">
                                                <label>{{ $tl("labels.form.postalCode") }} <small>*</small></label>
                                            </div>
                                            <div class="col-7">
                                                <input
                                                    :disabled="true"
                                                    type="text"
                                                    name="companyZipcode"
                                                    v-model="companyZipcode"
                                                    required
                                                    class="form-control" />
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-5 pt-2">
                                                <label>{{ $tl("labels.form.city") }} <small>*</small></label>
                                            </div>
                                            <div class="col-7">
                                                <input
                                                    :disabled="true"
                                                    type="text"
                                                    name="companyCity"
                                                    v-model="companyCity"
                                                    required
                                                    class="form-control" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-5 pt-2">
                                                <label>{{ $tl("labels.form.country") }} <small>*</small></label>
                                            </div>
                                            <div class="col-7">
                                                <input
                                                    :disabled="true"
                                                    type="text"
                                                    name="companyCountry"
                                                    v-model="companyCountry"
                                                    required
                                                    class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="row" v-if="isNoShow || isAdmin || !isYproxUser">
                                <div class="col-12">
                                    <div class="border-light b-radius-20 p-4 mb-3">
                                        <div class="row mb-2">
                                            <div class="col-12">
                                                <strong>{{ $tl("labels.form.changePassword") }}</strong>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="row mb-2">
                                                <div class="col-6 pt-2">
                                                    <label for="password">{{ $tl("labels.form.oldPassword") }}</label>
                                                </div>
                                                <div class="col-6">
                                                    <input
                                                        type="password"
                                                        name="old_password"
                                                        v-model="old_password"
                                                        id="old_password"
                                                        class="form-control" />
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-6 pt-2">
                                                    <label for="password">{{ $tl("labels.form.newPassword") }}</label>
                                                </div>
                                                <div class="col-6">
                                                    <PasswordInputComponent v-model="password" />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <label>{{ $tl("labels.form.newPasswordConfirm") }}</label>
                                                </div>
                                                <div class="col-6">
                                                    <PasswordConfirmationInputComponent :mainPassword="password" v-model="confirm_password" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row" v-if="!isWithoutAccount && !isAdmin">
                                <div class="col-12">
                                    <EditUserNotificationsSetting
                                        class="border-light b-radius-20 p-4 mb-3"
                                        :user_notifications_setting="user_notifications_setting"
                                        :enable_mail_notifications="enable_mail_notifications"
                                        :enable_notification_ringtone="enable_notification_ringtone"
                                        :tel_is_mobile="phone.isMobile"
                                        @set-property="setProperty" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="col-12">
                            <input type="submit" name="submit" value="Enregistrer" class="btn btn-success btn-circle" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../components/LoaderComponent.vue";
import EditUserNotificationsSetting from "../components/Default/Users/EditUserNotificationsSetting.vue";
import PasswordInputComponent from "../components/forms/PasswordInputComponent.vue";
import PasswordConfirmationInputComponent from "../components/forms/PasswordConfirmationInputComponent.vue";
import LangsEnum from "../mixins/enums/LangsEnum.js";
import BusinessTypesEnum from "../mixins/enums/BusinessTypesEnum.js";
import RolesEnum from "../mixins/enums/RolesEnum.js";
import ShowNewBadgeDetails from "../components/NewBadges/showNewBadgeDetails.vue";

export default {
    data() {
        return {
            loading: false,
            formLoading: false,
            errors: {
                fetchAccount: null,
                form: null,
            },
            formSuccess: null,
            firstname: null,
            lastname: null,
            email: null,
            tel: null,
            lang: "fr",
            company: null,
            companyType: null,
            companySiret: null,
            companyCodeNaf: null,
            companyCodeTva: null,
            companyAddress: null,
            companyZipcode: null,
            companyCity: null,
            companyCountry: null,
            old_password: null,
            password: null,
            confirm_password: null,
            enable_mail_notifications: null,
            enable_notification_ringtone: null,
            user_notifications_setting: null,
            phone: {
                raw: null,
                isValid: null,
                country: null,
                value: null,
                isMobile: false,
            },
        };
    },
    mixins: [LangsEnum, BusinessTypesEnum, RolesEnum],
    computed: {
        isAdmin() {
            return this.$store.getters["users/role"] === this.ROLE_ADMIN.value;
        },
        isOwner() {
            return this.$store.getters["users/role"] === this.ROLE_OWNER.value;
        },
        isYproxUser() {
            return this.$store.getters["users/isYproxUser"];
        },
        isWithoutAccount() {
            return this.$store.getters["users/role"] === this.ROLE_USER_WITHOUT_ACCOUNT.value;
        },
        isPasswordSecured() {
            return !this.password ? true : /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[?{}[\]\^!@#&%\*])(?=.{8,})/.test(this.password);
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_read_subscription() {
            return this.rights.includes("default.subscription.read") || this.isAdmin;
        },
        has_right_to_update_subscription() {
            return this.rights.includes("default.subscription.update") || this.isAdmin;
        },
    },
    methods: {
        setPhoneAndCountry(phoneObject) {
            if (phoneObject.number && phoneObject.isValid !== undefined && phoneObject.country) {
                this.phone.isValid = phoneObject.isValid;

                if (this.phone.isValid) {
                    this.phone.value = phoneObject.number;
                    this.phone.isMobile = phoneObject.type === "mobile";

                    if (phoneObject.regionCode && phoneObject.regionCode !== "") {
                        this.phone.country = phoneObject.regionCode;
                    } else {
                        this.phone.country = phoneObject.country.iso2;
                    }
                } else {
                    this.phone.isMobile = false;
                }
            } else {
                this.phone.isValid = false;
                this.phone.isMobile = false;
            }
        },
        setProperty(e) {
            this.$set(this, e.key, e.value);
        },
        initData() {
            this.loading = true;
            this.errors.fetchAccount = null;
            this.restaurant = null;

            this.httpGet("/api/account?include=user_notifications_setting", { mustReject: true })
                .then((response) => {
                    this.firstname = response.data.firstname;
                    this.lastname = response.data.lastname;
                    this.email = response.data.email;
                    this.tel = response.data.tel;
                    this.lang = response.data.lang;
                    this.company = response.data.company;
                    this.companyType = response.data.company_type;
                    this.companySiret = response.data.company_siret;
                    this.companyCodeNaf = response.data.company_code_naf;
                    this.companyCodeTva = response.data.company_code_tva;
                    this.companyAddress = response.data.company_address;
                    this.companyZipcode = response.data.company_zipcode;
                    this.companyCity = response.data.company_city;
                    this.companyCountry = response.data.company_country;
                    this.old_password = null;
                    this.password = null;
                    this.confirm_password = null;
                    this.enable_mail_notifications = response.data.enable_mail_notifications;
                    this.enable_notification_ringtone = response.data.enable_notification_ringtone;
                    this.user_notifications_setting = response.data.user_notifications_setting;
                    this.phone.raw = response.data.tel;
                })
                .catch((error) => {
                    this.errors.fetchAccount = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => (this.loading = false));
        },
        postForm(e) {
            e.preventDefault();
            this.formLoading = true;
            this.formSuccess = null;
            this.errors.form = null;

            this.httpPut("/api/account", {
                firstname: this.firstname,
                lastname: this.lastname,
                email: this.email,
                tel: this.phone.raw,
                lang: this.lang,
                company: this.company,
                company_type: this.companyType,
                company_siret: this.companySiret,
                company_code_naf: this.companyCodeNaf,
                company_code_tva: this.companyCodeTva,
                company_address: this.companyAddress,
                company_zipcode: this.companyZipcode,
                company_city: this.companyCity,
                company_country: this.companyCountry,
                old_password: this.old_password,
                password: this.password,
                confirm_password: this.confirm_password,
                enable_mail_notifications: this.enable_mail_notifications,
                enable_notification_ringtone: this.enable_notification_ringtone,
                user_notifications_setting: this.user_notifications_setting,
                tel_country: this.phone.country,
                tel_is_valid: this.phone.isValid,
                tel_is_mobile: this.phone.isMobile,
            })
                .then((response) => {
                    if (response !== false) {
                        this.$store.dispatch("users/fetchCurrentUser", { set: true }).catch(() => false);
                        this.setUserGlobalLang(this.lang);
                    }
                })
                .finally(() => (this.formLoading = false));
        },
    },
    created() {
        this.initData();
    },
    updated() {
        if (this.$refs.vueTelInput) {
            this.$refs.vueTelInput.$el.classList.remove("vue-tel-input");
            this.$refs.vueTelInput.$refs.input.addEventListener("focus", this.onVueTelInputFocus);
            this.$refs.vueTelInput.$refs.input.addEventListener("blur", this.onVueTelInputBlur);
        }
    },
    components: {
        LoaderComponent,
        EditUserNotificationsSetting,
        PasswordInputComponent,
        PasswordConfirmationInputComponent,
        ShowNewBadgeDetails,
    },
};
</script>
