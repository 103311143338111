var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Détails du " + _vm._s(_vm.emailsCampaignPack.name)),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _c("purchase-emails-campaign-pack", {
          attrs: { emailsCampaignPack: _vm.emailsCampaignPack },
          on: {
            close: _vm.close,
            paid: function ($event) {
              return _vm.$emit("paid", $event)
            },
            "disable-close-button": function ($event) {
              _vm.closeButtonEnable = false
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex w-100 justify-content-between",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn-sm btn btn-secondary btn-circle",
            attrs: { disabled: !_vm.closeButtonEnable },
            on: { click: _vm.close },
          },
          [_vm._v("\n            Fermer\n        ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }