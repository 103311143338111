<template class="overflow-auto">
    <modal-m0-p0 @close="closeFromModal">
        <h5 slot="header">{{ $tl("labels.notifications.preview.mail") }}</h5>
        <div slot="body">
            <LoaderComponent v-if="loading" />
            <div v-else>
                <div v-if="error" class="alert alert-danger">
                    {{ error }}
                </div>
                <div v-else v-html="resultMailContent"></div>
            </div>
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button type="button" class="modal-default-button btn btn-secondary btn-circle" @click="close">
                {{ $tl("labels.form.actions.close") }}
            </button>
        </div>
    </modal-m0-p0>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent.vue";
import axios from "axios";

export default {
    data() {
        return {
            resultMailContent: "",
            error: null,
            loading: false,
        };
    },
    props: {
        mailType: {
            type: String,
            required: true,
        },
        saas: {
            default: "restaurants",
        },
        locale: {
            type: String,
        },
        customContent: {
            type: String,
        },
    },
    methods: {
        fetchData() {
            this.loading = true;

            let url = `/api/${this.saas}/${this.$route.params.restaurant_id}/configs/mail/${this.mailType}?`;

            if (typeof this.locale !== "undefined") {
                url += `&locale=${this.locale}`;
            }

            axios
                .get(url)
                .then((response) => {
                    this.resultMailContent = response.data;
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchDataCustomContent() {
            this.loading = true;

            axios
                .post(`/api/${this.saas}/${this.$route.params.restaurant_id}/configs/mail/${this.mailType}?`, {
                    locale: this.locale,
                    content: this.customContent,
                })
                .then((response) => {
                    this.resultMailContent = response.data;
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        close() {
            this.error = null;
            this.resultMailContent = "";
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (!e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    created() {
        if (typeof this.customContent !== "undefined") {
            this.fetchDataCustomContent();
        } else {
            this.fetchData();
        }
    },
    components: {
        LoaderComponent,
    },
};
</script>
