var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(
        "\n        " +
          _vm._s(
            _vm.product.id !== null && !_vm.duplicate ? "Éditer" : "Ajouter"
          ) +
          "\n        " +
          _vm._s(_vm.product.type === "menu" ? "une formule" : "un produit") +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _c("add-click-and-collect-product", {
          ref: "addClickAndCollectProductComponent",
          attrs: {
            product: _vm.product,
            duplicate: _vm.duplicate,
            config: _vm.config,
          },
          on: {
            saved: function ($event) {
              return _vm.$emit("saved")
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v("\n            Annuler\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-success btn-circle ml-2",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.$refs.addClickAndCollectProductComponent.save()
              },
            },
          },
          [_vm._v("\n            Enregistrer\n        ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }