var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.step === 0
        ? _c("select-licence", {
            staticClass: "px-3",
            attrs: {
              onlyOneModule: true,
              "modules-enabled": _vm.modulesEnabled,
              "preselected-modules": _vm.modules,
              training: _vm.training,
            },
            on: {
              selected: function ($event) {
                _vm.modules = $event.selectedModules
                _vm.plan = $event.plan
                ;(_vm.training = $event.training), (_vm.step = 1)
              },
            },
          })
        : _vm.step === 1
        ? _c("pay-licences", {
            staticClass: "px-3",
            attrs: {
              modules: _vm.modules,
              training: _vm.training,
              redirect: { name: "subscription" },
              plan: _vm.plan,
            },
            on: {
              back: function ($event) {
                _vm.step = 0
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }