var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.value_,
          expression: "value_",
        },
      ],
      staticClass: "custom-select w-auto m-0",
      on: {
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.value_ = $event.target.multiple ? $$selectedVal : $$selectedVal[0]
        },
      },
    },
    _vm._l(_vm.ALL_LEXICONS, function (lexicon) {
      return _c(
        "option",
        { key: lexicon.value, domProps: { value: lexicon.value } },
        [
          _vm._v(
            "\n        " + _vm._s(_vm.getLexiconLabel(lexicon.value)) + "\n    "
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }