<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div class="row" v-else>
            <div class="col-12">
                <div class="border-light b-radius-20 p-4">
                    <div class="row mb-2">
                        <div class="col-5 pt-2">
                            <label for="name">{{ $tl("labels.form.email") }} <small>*</small></label>
                        </div>
                        <div class="col-7">
                            <input type="email" v-model="email" class="form-control" />
                            <span class="text-danger d-block mt-1" v-if="error">{{ error }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent";
import axios from "axios";

export default {
    data() {
        return {
            loading: false,
            email: null,
            error: null,
        };
    },
    props: {
        url: {
            type: String,
            required: true,
        },
    },
    components: {
        LoaderComponent,
    },
    methods: {
        send() {
            if (this.loading) return;
            this.loading = true;
            this.$emit("loading", true);
            this.error = null;

            axios
                .post(this.url, { email: this.email })
                .then((response) => {
                    this.$emit("sent", response.data);
                })
                .catch((error) => {
                    this.loading = false;
                    this.$emit("loading", false);
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
    },
};
</script>
