var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                ])
              : _c(
                  "div",
                  [
                    _vm.showHeadTitle
                      ? _c("div", { staticClass: "row m-0" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                              },
                              [
                                _vm.general_option_id
                                  ? _c("div", [
                                      _c("h5", { staticClass: "title mt-2" }, [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.booking.generalOptions.edit"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c("span", { staticClass: "value" }, [
                                          _vm._v(_vm._s(_vm.name)),
                                        ]),
                                      ]),
                                    ])
                                  : _c("div", [
                                      _c("h5", { staticClass: "title mt-2" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.booking.generalOptions.add"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("ShowErrors", {
                      attrs: {
                        errClass: "alert alert-danger",
                        errors: _vm.formErrors,
                        errorKey: "general_option_id",
                      },
                    }),
                    _vm._v(" "),
                    _vm.formSuccess
                      ? _c("div", { staticClass: "alert alert-success" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.formSuccess) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.formLoading
                      ? _c("LoaderComponent")
                      : _c(
                          "form",
                          {
                            attrs: { id: "addOption", method: "post" },
                            on: { submit: _vm.postForm },
                          },
                          [
                            _c("div", { staticClass: "row m-0" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "border-light b-radius-20 p-4 mb-3",
                                  },
                                  [
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c("div", { staticClass: "col-5 pt-2" }, [
                                        _c(
                                          "label",
                                          { attrs: { for: "name" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl("labels.form.name")
                                              ) + " "
                                            ),
                                            _c("small", [_vm._v("*")]),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-7" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.name,
                                                expression: "name",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              disabled:
                                                !_vm.has_right_to_update_menu,
                                              type: "text",
                                              name: "name",
                                              id: "name",
                                              required: "",
                                            },
                                            domProps: { value: _vm.name },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.name = $event.target.value
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            staticClass: "d-block",
                                            attrs: {
                                              errors: _vm.formErrors,
                                              errorKey: "name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c("div", { staticClass: "col-5 pt-2" }, [
                                        _c(
                                          "label",
                                          { attrs: { for: "description" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.form.description"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-7" },
                                        [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.description,
                                                expression: "description",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              disabled:
                                                !_vm.has_right_to_update_menu,
                                              name: "description",
                                              id: "description",
                                            },
                                            domProps: {
                                              value: _vm.description,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.description =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            staticClass: "d-block",
                                            attrs: {
                                              errors: _vm.formErrors,
                                              errorKey: "description",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c("div", { staticClass: "col-5 pt-2" }, [
                                        _c(
                                          "label",
                                          { attrs: { for: "price" } },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$tl("labels.price")) +
                                                " "
                                            ),
                                            _c("small", [_vm._v("*")]),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-7" }, [
                                        _c(
                                          "div",
                                          { staticClass: "row" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-4" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.price,
                                                      expression: "price",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    disabled:
                                                      !_vm.has_right_to_update_menu,
                                                    type: "number",
                                                    step: "0.01",
                                                    min: "0",
                                                    name: "price",
                                                    id: "price",
                                                    required: "",
                                                  },
                                                  domProps: {
                                                    value: _vm.price,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.price =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-8 mt-2" },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.getRestaurantCurrency(
                                                        _vm.restaurantId
                                                      )
                                                    ) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("ShowErrors", {
                                              staticClass: "col-12",
                                              attrs: {
                                                errors: _vm.formErrors,
                                                errorKey: "price",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    !_vm.isTvaDisabled
                                      ? _c("div", { staticClass: "row mb-2" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-5 pt-2" },
                                            [
                                              _c(
                                                "label",
                                                { attrs: { for: "tax_rate" } },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "labels.taxRate"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("small", [_vm._v("*")]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-7" }, [
                                            _c(
                                              "div",
                                              { staticClass: "row" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-4" },
                                                  [
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: _vm.tax_rate,
                                                            expression:
                                                              "tax_rate",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "custom-select mb-0",
                                                        attrs: {
                                                          disabled:
                                                            !_vm.has_right_to_update_menu,
                                                          required: "",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$selectedVal =
                                                              Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function (o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function (
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                            _vm.tax_rate =
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            domProps: {
                                                              value: null,
                                                            },
                                                          },
                                                          [_vm._v("--")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "option",
                                                          {
                                                            domProps: {
                                                              value: 5.5,
                                                            },
                                                          },
                                                          [_vm._v("5,5")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "option",
                                                          {
                                                            domProps: {
                                                              value: 10,
                                                            },
                                                          },
                                                          [_vm._v("10")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "option",
                                                          {
                                                            domProps: {
                                                              value: 20,
                                                            },
                                                          },
                                                          [_vm._v("20")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-md-8 mt-1",
                                                  },
                                                  [_vm._v("%")]
                                                ),
                                                _vm._v(" "),
                                                _c("ShowErrors", {
                                                  staticClass: "col-12",
                                                  attrs: {
                                                    errors: _vm.formErrors,
                                                    errorKey: "tax_rate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-5 pt-2" }, [
                                        _c(
                                          "label",
                                          { attrs: { for: "quantity_type" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.menus.options.quantity"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-7" },
                                        [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.quantity_type,
                                                  expression: "quantity_type",
                                                },
                                              ],
                                              staticClass: "custom-select mb-0",
                                              attrs: {
                                                disabled:
                                                  !_vm.has_right_to_update_menu,
                                                id: "quantity_type",
                                                name: "quantity_type",
                                                required: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.quantity_type = $event
                                                    .target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                },
                                              },
                                            },
                                            _vm._l(
                                              _vm.ALL_OPTION_QUANTITY_TYPES,
                                              function (optionQuantityType) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: optionQuantityType.value,
                                                    domProps: {
                                                      value:
                                                        optionQuantityType.value,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm.getOptionQuantityTypeLabel(
                                                            optionQuantityType.value,
                                                            _vm.restaurantId
                                                          )
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            staticClass: "d-block",
                                            attrs: {
                                              errors: _vm.formErrors,
                                              errorKey: "quantity_type",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row m-0" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "border-light b-radius-20 p-4",
                                  },
                                  [
                                    _c("div", { staticClass: "row mb-3" }, [
                                      _c("div", { staticClass: "col-12" }, [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.booking.menus.options.enabledSettings"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c("div", { staticClass: "col-5" }, [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "container-box",
                                            staticStyle: { width: "30px" },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.enable_min_pers,
                                                  expression: "enable_min_pers",
                                                },
                                              ],
                                              attrs: {
                                                disabled:
                                                  !_vm.has_right_to_update_menu,
                                                type: "checkbox",
                                                "true-value": "1",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.enable_min_pers
                                                )
                                                  ? _vm._i(
                                                      _vm.enable_min_pers,
                                                      null
                                                    ) > -1
                                                  : _vm._q(
                                                      _vm.enable_min_pers,
                                                      "1"
                                                    ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.enable_min_pers,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? "1"
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.enable_min_pers =
                                                          $$a.concat([$$v]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.enable_min_pers =
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            ))
                                                    }
                                                  } else {
                                                    _vm.enable_min_pers = $$c
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "checkmark",
                                              staticStyle: {
                                                "margin-top": "-2px",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "pt-2",
                                            attrs: { for: "price" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.menus.options.enabledFrom"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-7" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.nb_pers_min,
                                                    expression: "nb_pers_min",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                staticStyle: { width: "30%" },
                                                attrs: {
                                                  type: "number",
                                                  step: "1",
                                                  min: "0",
                                                  name: "nb_pers_min",
                                                  disabled:
                                                    !_vm.enable_min_pers ||
                                                    !_vm.has_right_to_update_menu,
                                                  id: "nb_pers_min",
                                                },
                                                domProps: {
                                                  value: _vm.nb_pers_min,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.nb_pers_min =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                { staticClass: "m-0 p-0 ml-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tl("labels.persons")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            staticClass: "d-block",
                                            attrs: {
                                              errors: _vm.formErrors,
                                              errorKey: "enable_min_pers",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            staticClass: "d-block",
                                            attrs: {
                                              errors: _vm.formErrors,
                                              errorKey: "nb_pers_min",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-5" }, [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "container-box",
                                            staticStyle: { width: "30px" },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.enable_max_pers,
                                                  expression: "enable_max_pers",
                                                },
                                              ],
                                              attrs: {
                                                disabled:
                                                  !_vm.has_right_to_update_menu,
                                                type: "checkbox",
                                                "true-value": "1",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.enable_max_pers
                                                )
                                                  ? _vm._i(
                                                      _vm.enable_max_pers,
                                                      null
                                                    ) > -1
                                                  : _vm._q(
                                                      _vm.enable_max_pers,
                                                      "1"
                                                    ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.enable_max_pers,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? "1"
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.enable_max_pers =
                                                          $$a.concat([$$v]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.enable_max_pers =
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            ))
                                                    }
                                                  } else {
                                                    _vm.enable_max_pers = $$c
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "checkmark",
                                              staticStyle: {
                                                "margin-top": "-2px",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "pt-2",
                                            attrs: { for: "price" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.menus.options.disabledFrom"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-7" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.nb_pers_max,
                                                    expression: "nb_pers_max",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                staticStyle: { width: "30%" },
                                                attrs: {
                                                  type: "number",
                                                  step: "1",
                                                  min: "0",
                                                  name: "nb_pers_max",
                                                  disabled:
                                                    !_vm.enable_max_pers ||
                                                    !_vm.has_right_to_update_menu,
                                                  id: "nb_pers_max",
                                                },
                                                domProps: {
                                                  value: _vm.nb_pers_max,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.nb_pers_max =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                { staticClass: "m-0 p-0 ml-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tl("labels.persons")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            staticClass: "d-block",
                                            attrs: {
                                              errors: _vm.formErrors,
                                              errorKey: "enable_max_pers",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            staticClass: "d-block",
                                            attrs: {
                                              errors: _vm.formErrors,
                                              errorKey: "nb_pers_max",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.showSaveButton
                              ? _c("div", { staticClass: "row mt-3 m-0" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c("input", {
                                      staticClass: "btn btn-success btn-circle",
                                      attrs: {
                                        disabled: !_vm.has_right_to_update_menu,
                                        type: "submit",
                                        name: "submit",
                                      },
                                      domProps: {
                                        value: _vm.$t(
                                          "labels.form.actions.save"
                                        ),
                                      },
                                    }),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        ),
                  ],
                  1
                ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }