<template>
    <div class="row mb-3">
        <div class="col-12">
            <loader-component v-if="nbLoading > 0" />
            <div v-else-if="stats.main" class="border-light b-radius-20 p-4">
                <div class="row m-0">
                    <h6 class="mt-3 col-10">
                        <strong>{{ $tl("labels.booking.stats.waitingList.title") }}</strong>
                    </h6>
                    <div class="d-flex justify-content-end align-items-center lead-switch col-2">
                        <button
                            class="btn btn-sm btn-square"
                            type="button"
                            :class="showGraph ? 'btn-success' : 'btn-secondary'"
                            @click.stop.prevent="showGraph = !showGraph">
                            <feather type="pie-chart" />
                        </button>
                    </div>
                </div>
                <div class="row m-0 click-collect-report">
                    <div class="col-md-6 p-1">
                        <div class="border-light p-3 b-radius-20 h-100">
                            <span>{{ $tl("labels.booking.stats.waitingList.nb") }}</span>
                            <div class="pt-2">
                                <strong class="value">
                                    <span v-if="!filters.hasGroupComparison">
                                        {{ $tl("labels.booking.stats.periodOne") }} {{ stats.main.count_waitings }}
                                    </span>
                                    <span v-else> {{ $tl("labels.booking.stats.groupOne") }} {{ stats.main.count_waitings }} </span>
                                </strong>
                            </div>
                            <template v-if="(stats.comparison && filters.hasComparison) || (stats.comparison && filters.hasGroupComparison)">
                                <div class="pt-2">
                                    <strong class="text-success">
                                        <span v-if="!filters.hasGroupComparison">
                                            {{ $tl("labels.booking.stats.periodTwo") }} {{ stats.comparison.count_waitings }}
                                        </span>
                                        <span v-else> {{ $tl("labels.booking.stats.groupTwo") }} {{ stats.comparison.count_waitings }} </span>
                                    </strong>
                                    <ArrowPercentEvolutionComponent
                                        v-if="!(stats.main.count_waitings == 0 && stats.comparison.count_waitings == 0)"
                                        :difference="stats.main.count_waitings - stats.comparison.count_waitings"
                                        :percent="getEvolution(stats.main.count_waitings, stats.comparison.count_waitings)"
                                        class="pt-2 font-weight-bold" />
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="col-md-6 p-1">
                        <div class="border-light p-3 b-radius-20 h-100">
                            <span>{{ $tl("labels.booking.stats.waitingList.nbLeadToResa") }}</span>
                            <div class="pt-2">
                                <strong class="value">
                                    <span v-if="!filters.hasGroupComparison">
                                        {{ $tl("labels.booking.stats.periodOne") }} {{ stats.main.count_waitings_accepted }}
                                    </span>
                                    <span v-else> {{ $tl("labels.booking.stats.groupOne") }} {{ stats.main.count_waitings_accepted }} </span>
                                    <span class="ml-2">({{ getPercent(stats.main.count_waitings_accepted, stats.main.count_waitings) }}%)</span>
                                </strong>
                            </div>
                            <template v-if="(stats.comparison && filters.hasComparison) || (stats.comparison && filters.hasGroupComparison)">
                                <div class="pt-2">
                                    <strong class="text-success">
                                        <span v-if="!filters.hasGroupComparison">
                                            {{ $tl("labels.booking.stats.periodTwo") }} {{ stats.comparison.count_waitings_accepted }}
                                        </span>
                                        <span v-else>
                                            {{ $tl("labels.booking.stats.groupTwo") }} {{ stats.comparison.count_waitings_accepted }}
                                        </span>
                                        <template v-if="stats.comparison.count_waitings_accepted != 0">
                                            <span class="ml-2"
                                                >({{ getPercent(stats.comparison.count_waitings_accepted, stats.comparison.count_waitings) }}%)</span
                                            >
                                        </template>
                                    </strong>

                                    <ArrowPercentEvolutionComponent
                                        v-if="!(stats.main.count_waitings_accepted == 0 && stats.comparison.count_waitings_accepted == 0)"
                                        :difference="stats.main.count_waitings_accepted - stats.comparison.count_waitings_accepted"
                                        :percent="getEvolution(stats.main.count_waitings_accepted, stats.comparison.count_waitings_accepted)"
                                        class="pt-2 font-weight-bold" />
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div v-if="showGraph" class="row d-flex flex-column align-items-center">
                    <div class="p-3 d-flex">
                        <strong class="text-success pr-1">{{ labelPeriodMain }}</strong>
                        <span v-if="stats.comparison && filters.hasComparison">
                            {{ $tl("labels.filters.comparedTo") }}
                            <strong class="text-success">{{ labelPeriodComparison }}</strong>
                        </span>
                    </div>
                    <v-chart
                        class="chart"
                        :option="{
                            title: nbWaitingResa.title,
                            series: barChartWaitingList,
                            xAxis: nbWaitingResa.xAxis,
                            yAxis: yAxisWaitingList,
                            tooltip: tooltipWaiting,
                            grid: grid,
                        }">
                    </v-chart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArrowPercentEvolutionComponent from "./ArrowPercentEvolutionComponent.vue";
import VChart from "vue-echarts";
import LoaderComponent from "../LoaderComponent.vue";
import StatsUtils from "../../mixins/helpers/booking/StatsUtils.js";

export default {
    props: {
        grid: {
            required: false,
            type: Object,
            default: () => ({}),
        },
        filters: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            showGraph: false,
            nbWaitingResa: {
                title: {
                    text: this.$tl("labels.booking.waitings.list.title"),
                    left: "center",
                    textStyle: {
                        fontWeight: "normal",
                    },
                },
                xAxis: {
                    type: "value",
                },
            },
            stats: {
                main: null,
                comparison: null,
            },
        };
    },
    mixins: [StatsUtils],
    computed: {
        barChartWaitingList() {
            const series = [
                {
                    name: this.$tl("labels.booking.stats.waitingList.nb"),
                    type: "bar",
                    label: {
                        show: true,
                        position: "inside",
                    },
                    emphasis: {
                        focus: "series",
                    },
                    data: [this.stats.main.count_waitings],
                    itemStyle: {
                        color: "#8c54ff",
                    },
                    animation: true,
                    animationDuration: 1000,
                    animationEasing: "exponentialInOut",
                },
                {
                    name: this.$tl("labels.booking.stats.waitingList.nbLeadToResa"),
                    type: "bar",
                    label: {
                        show: true,
                    },
                    emphasis: {
                        focus: "series",
                    },
                    data: [this.stats.main.count_waitings_accepted],
                    itemStyle: {
                        color: "#bc43e1",
                    },
                    animation: true,
                    animationDuration: 3000,
                    animationEasing: "elasticOut",
                },
            ];

            if (this.filters.hasComparison && this.stats.comparison) {
                if (this.stats.comparison.count_waitings !== undefined) {
                    series[0].data.push(this.stats.comparison.count_waitings);
                }

                if (this.stats.comparison.count_waitings_accepted !== undefined) {
                    series[1].data.push(this.stats.comparison.count_waitings_accepted);
                }
            }

            return series;
        },
        yAxisWaitingList() {
            const data = [this.labelPeriodMain];
            if (this.filters.hasComparison) {
                data.push(this.labelPeriodComparison);
            }
            return {
                type: "category",
                axisTick: {
                    show: false,
                },
                data: data,
            };
        },
        tooltipWaiting() {
            return {
                trigger: "item",
                formatter: (params) => {
                    const nb = params.value;
                    const name = params.name;
                    const translatedName = params.seriesName;

                    return `<b>${name}</b><br />${translatedName}: ${nb}`;
                },
            };
        },
    },
    methods: {
        fetchStats(abortControllerSignal = null, forComparison = false) {
            const filters = this.getFiltersQueryParams(forComparison);

            return this.httpGet(`/api/stats/waitings-count?${filters}`, {
                config: { signal: abortControllerSignal },
                handleReject: false,
            }).then((response) => {
                if (response !== false) {
                    this.stats[forComparison ? "comparison" : "main"] = response.data;
                }
            });
        },
    },
    components: {
        LoaderComponent,
        ArrowPercentEvolutionComponent,
        VChart,
    },
};
</script>

<style scoped>
button.btn:disabled {
    cursor: default;
}

.chart {
    height: 450px;
}
</style>
