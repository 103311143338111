<template>
    <div class="position-relative" ref="dropdown" tabindex="0">
        <button
            type="button"
            :class="btnClassesString"
            @click.prevent.stop.capture="canShowOptions = !canShowOptions"
            :disabled="isLoading"
            @dblclick.prevent.stop.capture=""
            @blur="hideDropDown">
            <slot v-if="useSlot"></slot>
            <span v-else-if="useLabel">
                {{ selectedOption[optionLabel] || title || "" }}
            </span>
            <feather
                v-if="useIcon && checkIcon(selectedOption, 'feather')"
                :type="selectedOption[optionIcon].feather"
                class="feather-20"
                :class="selectedOption[optionIcon].class || ''" />
            <span v-else-if="useIcon && checkIcon(selectedOption, 'html')" v-html="selectedOption[optionIcon].html"></span>
            <feather v-if="canDisplayChevronDown" type="chevron-down" />
        </button>
        <div v-if="canShowOptions" class="dropdown-container">
            <template v-for="option in currentOptions">
                <div class="dropdown-item current-dropdown-item font-size-2" @click.prevent.stop="changeOption(option)">
                    <span v-if="useLabel">
                        {{ option[optionLabel] }}
                    </span>
                    <feather
                        v-if="useIcon && checkIcon(option, 'feather')"
                        :type="option[optionIcon].feather"
                        class="feather-20 mr-2"
                        :class="option[optionIcon].class || ''" />
                    <span v-else-if="useIcon && checkIcon(option, 'html')" v-html="option[optionIcon].html" class="d-inline-block mr-2"></span>
                    <span v-if="useLabelForIcon">{{ option[optionLabel] }}</span>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        optionIcon: {
            type: String,
            default: "icon",
        },
        optionLabel: {
            type: String,
            default: "label",
        },
        optionValue: {
            type: String,
            default: "value",
        },
        selectedOption: {
            type: Object,
            default: () => {},
        },
        useSlot: {
            type: Boolean,
            default: false,
        },
        useLabel: {
            type: Boolean,
            default: true,
        },
        useIcon: {
            type: Boolean,
            default: false,
        },
        useLabelForIcon: {
            type: Boolean,
            default: false,
        },
        btnClassesString: {
            type: String,
            default: "btn btn-sm btn-outline-secondary btn-circle important",
        },
        removeSelectedElementInOptions: {
            type: Boolean,
            default: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        canUpdateWithoutSync: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "Select",
        },
    },
    data() {
        return {
            canShowOptions: false,
        };
    },
    computed: {
        mappedOptions() {
            return this.options.map((option) => {
                if (typeof option !== "object") {
                    const obj = {};

                    obj[this.optionLabel] = option;
                    obj[this.optionValue] = option;
                    obj[this.optionIcon] = option;

                    return obj;
                } else {
                    return option;
                }
            });
        },
        currentOptions() {
            if (!this.useSlot && this.removeSelectedElementInOptions) {
                this.mappedOptions.filter((option) => this.selectedOption[this.optionValue] !== option[this.optionValue]);
            }

            return this.options;
        },
        optionsLength() {
            return this.currentOptions.length;
        },
        canDisplayChevronDown() {
            return this.optionsLength > 0;
        },
    },
    methods: {
        checkIcon(option, iconType = "feather") {
            return typeof option === "object" && typeof option.icon === "object" && typeof option.icon[iconType] !== "undefined";
        },
        changeOption(option) {
            if (this.canUpdateWithoutSync) {
                this.selectedOption = option;
            }
            this.$emit("option-changed", option);
            this.canShowOptions = false;
        },
        hideDropDown(event) {
            if (!this.$refs.dropdown.contains(event.relatedTarget)) {
                this.canShowOptions = false;
            }
        },
    },
};
</script>
<style>
.dropdown-container {
    position: absolute;
    left: 0;
    top: 100%;
    display: flex;
    flex-direction: column;
    z-index: 9999;
    background: white;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.06);
    cursor: pointer;
}

.dropdown-container .dropdown-item {
    display: flex;
    align-items: center;
}
</style>
