<template>
    <form class="form-signin" @submit.prevent="login" data-test-id="form-login">
        <img class="mb-5" width="200" src="/images/logo-lead.png" alt="No Show" />

        <div class="d-flex justify-content-between align-items-center mb-3">
            <h5 class="m-0" data-test-id="title-login_page">
                {{ $tl(`labels.form.login.${isYservices ? "loginWith" : "login"}`) }}
            </h5>
            <ChooseLang />
        </div>
        <loader-component v-if="loading" />
        <div v-if="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
        </div>
        <template v-if="!loading">
            <template v-if="isYservices">
                <a class="d-block bg-white text-center py-2" href="https://admin.yproximite.fr/y-services/autologin">
                    <img src="/images/logo-yprox.png" alt="Log with Yproximité" />
                </a>
                <div class="text-muted my-3 text-center d-flex align-items-center">
                    <hr style="width: 20%" />
                    {{ $tl("labels.form.login.useMyEmail") }}
                    <hr style="width: 20%" />
                </div>
            </template>
            <label class="sr-only">{{ $tl("labels.form.username") }}</label>
            <label class="sr-only">{{ $tl("labels.form.password") }}</label>
            <div class="input-group mb-1">
                <div class="input-group-prepend">
                    <span class="input-group-text">
                        <feather type="user" class="text-success" />
                    </span>
                </div>
                <input
                    id="email"
                    data-test-id="input-txt-email"
                    type="email"
                    class="form-control"
                    :class="{ 'is-invalid': hasErrors('email') }"
                    name="email"
                    v-model="email"
                    :placeholder="$t('labels.form.email')"
                    required
                    autofocus />
                <div data-test-id="errors-email" v-if="hasErrors('email')" class="invalid-feedback" role="alert" v-html="joinErrors('email')"></div>
            </div>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">
                        <feather type="lock" class="text-success" />
                    </span>
                </div>

                <input
                    id="password"
                    type="password"
                    data-test-id="input-txt-password"
                    class="form-control"
                    name="password"
                    :class="{ 'is-invalid': hasErrors('password') }"
                    v-model="password"
                    :placeholder="$t('labels.form.password')"
                    required />
                <div v-if="hasErrors('password')" class="invalid-feedback" role="alert" v-html="joinErrors('password')"></div>
            </div>
            <div class="checkbox mb-3">
                <label class="container-box" style="width: 100%">
                    <input class="mr-2" data-test-id="input-chk-remember" type="checkbox" name="remember" id="remember" v-model="remember" />
                    <span class="checkmark"></span> {{ $tl("labels.form.login.rememberMe") }}
                </label>
            </div>

            <button data-test-id="btn-login" type="submit" class="btn btn-success btn-circle login-submit">
                {{ $tl("labels.form.login.button") }}
            </button>

            <footer class="content-footer mt-4">
                <nav class="footer-navigation mb-4">
                    <a class="footer-navigation-item" href="/password/reset">
                        {{ $tl("labels.form.login.forgotPassword") }}
                    </a>
                </nav>
                <small class="text-muted"> ©{{ displayDate(null, "y") }} </small>
            </footer>
        </template>
    </form>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent";
import ChooseLang from "../../components/Default/ChooseLang.vue";

export default {
    name: "AuthLogin",
    data() {
        return {
            loading: false,
            errorMessage: null,
            errors: null,
            email: "",
            password: "",
            remember: false,
        };
    },
    methods: {
        login() {
            this.loading = true;

            let redirect = this.$route.query.redirect;

            this.$store
                .dispatch("auth/login", {
                    email: this.email,
                    password: this.password,
                    remember: this.remember,
                    redirect,
                })
                .then(() => {
                    if (typeof redirect === "undefined") {
                        redirect = "/";
                    }

                    window.location.href = redirect;
                })
                .catch((error) => {
                    if (error.request && error.request.status && error.request.responseURL) {
                        const codes = [404, 403];
                        if (codes.includes(error.request.status)) {
                            document.location.href = error.request.responseURL;
                            return;
                        }
                    }
                    if (error.response && error.response.data.message) {
                        this.errorMessage = error.response.data.message;
                    }

                    if (error.response && error.response.data.errors) {
                        this.errors = error.response.data.errors;
                    } else if (error.message) {
                        this.errorMessage = error.message;
                    }
                    this.password = "";
                    this.loading = false;
                });
        },
        hasErrors(key) {
            return this.errors !== null && this.errors[key] && this.errors[key].length > 0;
        },
        joinErrors(key) {
            return this.errors[key].join("<br/>");
        },
    },
    components: {
        LoaderComponent,
        ChooseLang,
    },
};
</script>
