var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.log.changed_columns, function (column) {
      return _c("div", { key: column, staticClass: "row mt-2" }, [
        _c(
          "div",
          { staticClass: "col-md-9" },
          [
            _c("feather", {
              staticClass: "mr-2",
              attrs: { type: _vm.getReservationColumnIcon(column) },
            }),
            _vm._v(" "),
            _c("strong", [_vm._v(_vm._s(_vm.userDisplayable))]),
            _vm._v(
              " " +
                _vm._s(_vm.getReservationColumnLabel(column)) +
                "\n            "
            ),
            typeof _vm.getReservationColumnValueLabel(
              column,
              _vm.log,
              _vm.restaurantId
            ) !== "undefined"
              ? _c("strong", [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.getReservationColumnValueLabel(
                          column,
                          _vm.log,
                          _vm.restaurantId
                        )
                      ) +
                      "\n            "
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-3 text-right" }, [
          _c("small", [_vm._v(_vm._s(_vm.createdAt))]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }