var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100", staticStyle: { "padding-top": "44px" } },
    [
      _vm.error
        ? _c("div", { staticClass: "alert alert-danger mt-5" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : !_vm.seatingPlanId
        ? _c("ListSeatingPlans")
        : _c("seating-plan-konva", {
            staticClass: "h-100",
            attrs: {
              seatingPlanId:
                _vm.seatingPlanId == "add" ? null : _vm.seatingPlanId,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }