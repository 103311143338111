var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass:
        "navbar navbar-dark justify-content-between fixed-top flex-md-nowrap p-0 bg-light",
    },
    [
      _c(
        "router-link",
        {
          staticClass: "shadow-none navbar-brand mr-0 col-lg-2 col-md-3",
          attrs: { to: _vm.url },
        },
        [
          !_vm.isDevelopement()
            ? _c("img", {
                staticClass: "img-logo display-sid-min",
                staticStyle: { width: "160px" },
                attrs: { src: "/images/logo-lead-extend.png" },
              })
            : _c("img", {
                staticClass: "img-logo display-sid-min",
                staticStyle: { width: "160px" },
                attrs: { src: "/images/logo-lead-extend.png" },
              }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.isCourier
            ? _c("ShowNotificationsComponent", {
                attrs: {
                  showNotifications: _vm.notifications.show,
                  module_type: _vm.MODULE_TYPE_CLICK_AND_COLLECT.value,
                },
                on: {
                  open: function ($event) {
                    _vm.notifications.show = true
                  },
                  close: function ($event) {
                    _vm.notifications.show = false
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.getTooltip("Déconnexion"),
                  expression: "getTooltip('Déconnexion')",
                },
              ],
              staticClass: "logout-default",
              attrs: { "data-test-id": "btn-logout", href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.logout.apply(null, arguments)
                },
              },
            },
            [
              _c("feather", {
                staticClass: "feather-22 text-danger mr-3",
                class: { "mt-1": _vm.isCourier },
                attrs: { type: "log-out" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }