<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Zones de livraison à vol d'oiseau</h5>
        <div slot="body">
            <GoogleMap
                ref="googleMap"
                :stopLoader="false"
                :mapZoom="12"
                :mapCenter="mapCenter"
                @initialized="onMapInitialized" />
        </div>
        <div slot="footer" class="text-center">
            <button type="button" class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="close">
                Fermer
            </button>
        </div>
    </modal>
</template>

<script>
import GoogleMap from "../../../Maps/GoogleMap";

export default {
    data() {
        return {
            colorsList: [
                "#F8B195",
                "#F67280",
                "#C06C84",
                "#6C5B7B",
                "#355C7D",
                "#99B898",
                "#FECEAB",
                "#FF847C",
                "#E84A5F",
                "#2A363B",
                "#A8E6CE",
                "#DCEDC2",
                "#F9CDAD",
                "#FFAAA6",
                "#FF8C94",
                "#9DE0AD",
                "#45ADA8",
                "#547980",
                "#c06c84",
                "#594F4F",
            ],
        };
    },
    props: {
        config: {
            type: Object,
            required: true,
        },
    },
    computed: {
        mapCenter() {
            if (this.config && this.config.delivery_departure_lat && this.config.delivery_departure_lng)
                return {
                    lat: parseFloat(this.config.delivery_departure_lat),
                    lng: parseFloat(this.config.delivery_departure_lng),
                };
            return undefined;
        },
        deliveryAreas() {
            if (!this.config || !this.config.deliveryAreas || !this.config.deliveryAreas.data) return [];
            let areas = this.config.deliveryAreas.data.filter((area) => area.type == this.config.delivery_areas_type);
            return areas.sort((a, b) => {
                const aVal = a || 0;
                const bVal = b || 0;
                return b - a;
            });
        },
        restaurantAddress() {
            if (
                this.config &&
                this.config.delivery_departure_address &&
                this.config.delivery_departure_postal_code &&
                this.config.delivery_departure_city &&
                this.config.delivery_departure_country
            )
                return `${this.config.delivery_departure_address}, ${this.config.delivery_departure_postal_code} ${this.config.delivery_departure_city}`;
            return undefined;
        },
    },
    methods: {
        getColorForArea(areaIndex) {
            return this.colorsList.length > areaIndex ? this.colorsList[areaIndex] : "#FF0000";
        },
        onMapInitialized() {
            this.deliveryAreas.forEach((area, index) => {
                const color = this.getColorForArea(index);
                const shapeOptions = {
                    strokeColor: color,
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    fillColor: color,
                    fillOpacity: 0.35,
                };
                if (area.distance_min_km && area.distance_min_km > 0) {
                    var shape = this.$refs.googleMap.drawPolygon({
                        paths: [
                            this.$refs.googleMap.getCirclePoints(this.mapCenter, area.distance_max_km * 1000, true),
                            this.$refs.googleMap.getCirclePoints(this.mapCenter, area.distance_min_km * 1000, false),
                        ],
                        ...shapeOptions,
                    });
                } else {
                    var shape = this.$refs.googleMap.drawCircle({
                        center: this.mapCenter,
                        radius: area.distance_max_km * 1000, // Radius in meters
                        ...shapeOptions,
                    });
                }
                if (area.distance_min_km)
                    var content = `Entre ${area.distance_min_km} km et ${area.distance_max_km} km du point de départ de livraison<br>`;
                else var content = `À moins de ${area.distance_max_km} km du point de départ de livraison<br>`;
                content += "Prix : " + (area.price !== null ? this.formatCurrency(area.price) : "Gratuite");
                if (area.tax_rate !== null) content += ` - TVA : ${area.tax_rate} %`;
                content += "<br>";
                if (area.free_above !== null)
                    content += `Livraison gratuite à partir de ${this.formatCurrency(area.free_above)}<br>`;
                if (area.min_amount !== null)
                    content += `Montant minimum de commande : ${this.formatCurrency(area.min_amount)}<br>`;
                content += "Créneaux : " + (area.use_default_delivery_slots ? "Tous" : "Limité");
                this.$refs.googleMap.addInfoWindow(shape, content);
            });
            if (this.restaurantAddress) {
                let marker = this.$refs.googleMap.addMarker({
                    position: this.mapCenter,
                    title: this.restaurantAddress,
                });
                this.$refs.googleMap.addInfoWindow(marker, this.restaurantAddress);
            }

            setTimeout(() => {
                this.$refs.googleMap.setLoading(false);
            }, 500);
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        GoogleMap,
    },
};
</script>
