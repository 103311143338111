<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Détails du bon cadeau</h5>
        <div slot="body">
            <validate-gift-voucher ref="validateGiftVoucherComponent" :gv_sale="gv_sale" @updated="$emit('updated')" />
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="close">Fermer</button>
            <button
                class="btn btn-sm btn-success btn-circle"
                v-if="has_right_to_update"
                @click="$refs.validateGiftVoucherComponent.markGvSaleAsUsed()">
                Marquer le bon cadeau comme "utilisé"
            </button>
        </div>
    </modal>
</template>

<script>
import ValidateGiftVoucher from "../../giftVouchers/ValidateGiftVoucher";

export default {
    props: {
        gv_sale: {
            type: Object,
            required: true,
        },
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update() {
            return this.rights.includes("gift_vouchers.sales.update");
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        ValidateGiftVoucher,
    },
};
</script>
