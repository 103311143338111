var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "border-light b-radius-20 p-4 mb-3" },
          [
            _c(
              "ul",
              {
                staticClass: "settings-menu nav nav-tabs flex-row top mb-2",
                staticStyle: { position: "relative", top: "0" },
              },
              _vm._l(_vm.tabs, function (tab, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "nav-item",
                    on: {
                      click: function ($event) {
                        _vm.currentTab = index
                      },
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        class: {
                          "router-link-active": index === _vm.currentTab,
                        },
                        staticStyle: { color: "#6c757d" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(tab.label) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "keep-alive",
              [_c(_vm.currentComponent, { tag: "component" })],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [
            _c("div", [
              _c("h5", { staticClass: "title mt-2" }, [_vm._v("Contrats")]),
            ]),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }