<template>
    <div>
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <div v-if="appUpdateId !== null">
                        <h5 class="title mt-2">
                            Modifier la note de mise à jour du
                            <span v-if="appUpdateDate !== null" class="value">{{ displayDate(appUpdateDate, DATE_SHORT) }}</span>
                        </h5>
                    </div>
                    <div v-else>
                        <h5 class="title mt-2">Créer une note de mise à jour</h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="row m-0">
            <div class="col-12">
                <app-update-form ref="form" @fully-loaded="onFormLoaded" @submit="onFormSubmitted" :app-update-id="appUpdateId" show-save-button />
            </div>
        </div>
    </div>
</template>

<script>
import appUpdateForm from "../../../components/Admin/AppUpdates/appUpdateForm.vue";

export default {
    data() {
        return {
            appUpdateDate: null,
        };
    },
    computed: {
        appUpdateId() {
            return typeof this.$route.params.app_update_id !== "undefined" ? Number.parseInt(this.$route.params.app_update_id) : null;
        },
    },
    methods: {
        onFormLoaded({ appUpdate }) {
            this.appUpdateDate = appUpdate.update_date;
        },
        onFormSubmitted({ promise }) {
            promise.then((success) => {
                if (success !== false) {
                    this.$router.push({
                        name: "admin.app_updates.list",
                    });
                }
            });
        },
    },
    components: {
        appUpdateForm,
    },
};
</script>
