var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row m-0 div-valid-resa" }, [
    _c(
      "div",
      { staticClass: "col-lg-8 offset-lg-2 mb-3" },
      [
        _c(
          "div",
          {
            staticClass: "bg-danger text-center text-white p-1",
            staticStyle: { "border-radius": "100px" },
          },
          [
            _vm._v(
              "\n            La commande ne correspond pas au service\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c("show-click-and-collect-command", {
          attrs: {
            restaurant_id: _vm.command.restaurant_id,
            command_id: _vm.command.id,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }