<template>
    <div>
        <div class="tile module-product px-0 border-light h-100">
            <div class="hoverable position-relative">
                <h6 class="title py-4 border-bottom-light">{{ getModuleTitle(module) }}</h6>
                <button v-if="onlyOneModule && count > 0" class="btn top-right p-0 m-1" type="button" @click="count = 0">
                    <feather class="icon w-auto h-auto bg-none" type="x" size="20" />
                </button>
                <div class="p-3">
                    <toggle-switch
                        v-if="!disablePlan"
                        class="w-100 mb-3"
                        :group="`${module.type}-${module.id}`"
                        :options="toggleSwitchOptions"
                        v-model="planLabel" />
                    <div class="mb-3">
                        <template v-if="max !== 1">
                            <select
                                class="custom-select mb-1 mr-2"
                                style="width: inherit"
                                :id="`licence-count-${module.type}-${module.id}`"
                                v-model.number="count">
                                <option v-for="(value, index) in $_.range(max ? max + 1 : 6)" :key="index" :value="value">
                                    {{ value }}
                                </option>
                            </select>
                            <span>{{ $tl("labels.form.licences.titleSingular") }}</span>
                        </template>
                        <label v-else class="container-box w-auto" style="padding-left: 20px !important">
                            <input type="checkbox" :id="`licence-count-${module.type}-${module.id}`" :value="1" v-model.number="count" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <h5 v-if="!disablePayment" class="value">
                        <span v-if="discountedPrice">
                            <s
                                >{{ (priceNoReduction / 100).toFixed(2) }}
                                <small>{{ $tl("labels.ht") }} / {{ $tl(`labels.${keyForLabels}`) }}</small></s
                            ><br /> </span
                        >{{ (price / 100).toFixed(2) }}
                        <small> {{ $tl("labels.ht") }} / {{ $tl(`labels.${keyForLabels}`) }}</small>
                    </h5>
                    <p v-if="module.description">
                        {{ module.description }}
                    </p>
                </div>
                <h5 class="mb-0 pt-3 pb-3 border-top-light" v-if="count > 0 && !disablePayment">
                    {{ $tl("labels.total") }} : {{ (priceComputed / 100).toFixed(2) }}€
                    <small>{{ $tl("labels.ht") }} / {{ $tl(`labels.${keyForLabels}`) }}</small>
                </h5>
            </div>
        </div>
    </div>
</template>

<script>
import _ResizeObserver from "resize-observer-polyfill";
import ToggleSwitch from "vuejs-toggle-switch";
import ModuleTypeEnum from "../../mixins/enums/ModuleTypesEnum";
import LangsEnum from "../../mixins/enums/LangsEnum";
import SubscriptionStatusEnum from "../../mixins/enums/SubscriptionStatusEnum";

export default {
    name: "ModuleProduct",
    props: {
        module: {
            required: true,
        },
        value: {
            required: true,
        },
        plan: {
            default: undefined,
        },
        allModules: {
            default: undefined,
        },
        alreadyTried: {
            default: false,
        },
        onlyOneModule: {
            default: false,
        },
        max: {
            default: null,
        },
        disablePayment: {
            type: Boolean,
            default: false,
        },
        disablePlan: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [ModuleTypeEnum, SubscriptionStatusEnum, LangsEnum],
    data() {
        return {
            style: {
                icon: "",
            },
            resizeObserver: null,
            plan_: "yearly",
            count_: 0,
        };
    },
    computed: {
        keyForLabels() {
            if (this.planComputed === this.STRIPE_PLAN_TYPE_MONTHLY.value) {
                return "month";
            }

            if (this.planComputed === this.STRIPE_PLAN_TYPE_YEARLY.value) {
                return "year";
            }

            return this.planComputed;
        },
        showDispatchOption() {
            return false;
        },
        discountedPrice() {
            if (this.disablePayment) {
                return false;
            }

            if (this.planComputed === this.STRIPE_PLAN_TYPE_MONTHLY.value) {
                return false;
            }

            if (this.getValidForDiscountSubscriptions(this.planComputed).length > 0) {
                return true;
            }

            if (this.planComputed === this.STRIPE_PLAN_TYPE_YEARLY.value && this.allModules !== undefined && this.allModules !== null) {
                const isFirstSelectedModule =
                    this.allModules.findIndex((m) => m.type === this.module.type) === this.allModules.findIndex((m) => m.count > 0);

                return !isFirstSelectedModule && this.allModules.filter((m) => m.type !== this.module.type && m.count > 0).length > 0;
            }

            return false;
        },
        modules: {
            get: function () {
                if (typeof this.allModules == "undefined") {
                    return [{ ...this.module, count }];
                } else {
                    return this.allModules;
                }
            },
            set(value) {},
        },
        planComputed: {
            get() {
                if (typeof this.plan === "undefined") {
                    return this.plan_;
                } else {
                    return this.plan;
                }
            },
            set(value) {
                if (typeof this.plan === "undefined") {
                    this.plan_ = value;
                } else {
                    this.$emit("input-plan", value);
                }
            },
        },
        planLabel: {
            get() {
                if (this.planComputed === this.STRIPE_PLAN_TYPE_YEARLY.value) {
                    return this.$tl("labels.yearly");
                } else if (this.planComputed === this.STRIPE_PLAN_TYPE_MONTHLY.value) {
                    return this.$tl("labels.monthly");
                }
            },
            set(value) {
                if (value === this.$tl("labels.yearly")) {
                    this.planComputed = this.STRIPE_PLAN_TYPE_YEARLY.value;
                } else if (value === this.$tl("labels.monthly")) {
                    this.planComputed = this.STRIPE_PLAN_TYPE_MONTHLY.value;
                }
            },
        },
        priceNoReduction() {
            return this.module[`stripe_${this.planComputed}_price`];
        },
        price() {
            return this.discountedPrice
                ? this.module[`stripe_${this.planComputed}_discounted_price`]
                : this.module[`stripe_${this.planComputed}_price`];
        },
        count: {
            get: function () {
                return typeof this.value === "undefined" ? this.count_ : this.value;
            },
            set: function (value) {
                if (value === true) {
                    value = 1;
                }

                if (typeof this.value == "undefined") {
                    this.count_ = value;
                } else {
                    this.$emit("input", value);
                }
            },
        },
        toggleSwitchOptions() {
            let preselectedItem = this.$tl("labels.yearly");

            if (this.planComputed && this.planComputed === this.STRIPE_PLAN_TYPE_MONTHLY.value) {
                preselectedItem = this.$tl("labels.monthly");
            }

            return {
                layout: {
                    color: "#30a4b7",
                    fontWeightSelected: "normal",
                    backgroundColor: "white",
                    selectedColor: "white",
                    selectedBackgroundColor: "#30a4b7",
                    borderColor: "#30a4b7",
                },
                size: {
                    fontSize: 0.75,
                    height: 1.625,
                    padding: 0.25,
                },
                items: {
                    delay: 0.4,
                    preSelected: preselectedItem,
                    labels: [{ name: this.$tl("labels.yearly") }, { name: this.$tl("labels.monthly") }],
                },
            };
        },
        selectedModules() {
            return this.modules.filter((module) => {
                return module && module.count && module.count > 0;
            });
        },
        mainSelectedModule() {
            if (this.$store.getters["users/getOwnerSubscriptions"].filter((s) => s.module.type != "campaigns").length == 0) {
                const sortedSelectedModules = this.selectedModules.sort((moduleA, moduleB) => {
                    return Number.parseInt(moduleA.id) - Number.parseInt(moduleA.id);
                });

                if (sortedSelectedModules.length > 0) {
                    return sortedSelectedModules[0];
                }
            }

            return null;
        },
        priceComputed() {
            if (this.discountedPrice) {
                return this.module[`stripe_${this.planComputed}_discounted_price`] * this.count;
            }

            return this.module[`stripe_${this.planComputed}_price`] * this.count;
        },
    },
    mounted() {
        let currentStyle = this.getCurrentStyle(this.$el);

        this.setIconStyle(currentStyle.height, currentStyle.width);

        this.resizeObserver = new ResizeObserver(this.resizeObserverHandler);

        this.resizeObserver.observe(this.$el);
    },
    methods: {
        getCurrentStyle(el) {
            let currentStyle = {
                height: null,
                width: null,
            };

            try {
                currentStyle.height = window.getComputedStyle(el, null).getPropertyValue("height");
                currentStyle.width = window.getComputedStyle(el, null).getPropertyValue("width");
            } catch (e) {
                currentStyle.height = el.currentStyle.height;
                currentStyle.width = el.currentStyle.width;
            }

            return currentStyle;
        },
        setIconStyle(parentStyleHeight, parentStyleWidth) {
            const result = `width: ${parentStyleWidth}; ` + `height: ${parentStyleWidth};`;

            this.style.icon = result;
        },
        resizeObserverHandler(entries) {
            for (var entry of entries) {
                const currentStyle = this.getCurrentStyle(entry.target);
                this.setIconStyle(currentStyle.height, currentStyle.width);
            }
        },
    },
    components: {
        ToggleSwitch,
    },
};
</script>
