<template>
    <div class="row mb-3">
        <div class="col-12">
            <loader-component v-if="nbLoading > 0" />
            <div v-else-if="stats.main" class="border-light b-radius-20 p-4">
                <div class="row pointer" @click="showEvolutionNbResa = !showEvolutionNbResa">
                    <strong class="col-10 d-flex align-items-center">{{ $tl("labels.booking.stats.charts.resaEvolution.title") }}</strong>
                    <div class="d-flex justify-content-end align-items-center lead-switch col-2">
                        <button
                            class="btn btn-sm btn-square"
                            type="button"
                            :class="pinEvolutionNbResa ? 'btn-success' : 'btn-secondary'"
                            @click.stop.prevent="pinEvolutionNbResa = !pinEvolutionNbResa">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="18px" height="18px" fill="currentColor">
                                <path
                                    d="M300.8 203.9L290.7 128H328c13.2 0 24-10.8 24-24V24c0-13.2-10.8-24-24-24H56C42.8 0 32 10.8 32 24v80c0 13.2 10.8 24 24 24h37.3l-10.1 75.9C34.9 231.5 0 278.4 0 335.2c0 8.8 7.2 16 16 16h160V472c0 .7 .1 1.3 .2 1.9l8 32c2 8 13.5 8.1 15.5 0l8-32c.2-.6 .2-1.3 .2-1.9V351.2h160c8.8 0 16-7.2 16-16 .1-56.8-34.8-103.7-83.1-131.3zM33.3 319.2c6.8-42.9 39.6-76.4 79.5-94.5L128 96H64V32h256v64h-64l15.3 128.8c40 18.2 72.7 51.8 79.5 94.5H33.3z" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div v-if="showEvolutionNbResa || pinEvolutionNbResa" class="row d-flex flex-column align-items-center">
                    <div class="row d-flex flex-column align-items-center col-12">
                        <div class="p-3 d-flex">
                            <strong class="text-success pr-1">{{ labelPeriodMain }}</strong>
                            <span v-if="filters.hasComparison">
                                {{ $tl("labels.filters.comparedTo") }}
                                <strong class="text-success">{{ labelPeriodComparison }}</strong>
                            </span>
                        </div>
                        <VChart
                            class="chart"
                            :option="{
                                title: evolutionNbResaChart.title,
                                series: seriesDataLine,
                                xAxis: evolutionNbResaChart.xAxis,
                                yAxis: evolutionNbResaChart.yAxis,
                                tooltip: { trigger: 'axis' },
                                legend: evolutionNbResaChart.legend,
                                grid: grid,
                            }">
                        </VChart>
                    </div>
                    <div
                        v-if="
                            stats.comparison &&
                            filters.hasComparison &&
                            seriesDataLineNbResaEvolv.nbValues.length !== seriesDataLineNbResaEvolvComparison.nbValues.length
                        "
                        class="row d-flex flex-column align-items-center pt-3 col-12">
                        <strong class="text-success pr-1 p-3">{{ labelPeriodComparison }}</strong>
                        <VChart
                            class="chart"
                            :option="{
                                title: evolutionNbResaChartComparison.title,
                                series: seriesDataLineComparison,
                                xAxis: evolutionNbResaChartComparison.xAxis,
                                yAxis: evolutionNbResaChartComparison.yAxis,
                                tooltip: { trigger: 'axis' },
                                legend: evolutionNbResaChartComparison.legend,
                                grid: grid,
                            }">
                        </VChart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VChart from "vue-echarts";
import { graphic } from "echarts";
import LoaderComponent from "../LoaderComponent.vue";
import StatsUtils from "../../mixins/helpers/booking/StatsUtils.js";

export default {
    name: "EvolutionNbResaComponent",
    props: {
        grid: {
            required: true,
            type: Object,
        },
        filters: {
            required: true,
            type: Object,
        },
    },
    mixins: [StatsUtils],
    data() {
        return {
            showEvolutionNbResa: false,
            evolutionNbResaChart: {
                title: {
                    text: this.$tl("labels.booking.stats.charts.resaEvolution.title"),
                    left: "center",
                    textStyle: {
                        fontWeight: "normal",
                    },
                    padding: [0, 0, 30, 0],
                },
                xAxis: {
                    type: "category",
                    name: this.$tl("labels.booking.stats.charts.resaEvolution.x"),
                    nameLocation: "middle",
                    nameTextStyle: {
                        fontWeight: "bold",
                        padding: [10, 0, 10, 0],
                    },
                    data: [],
                },
                yAxis: {
                    type: "value",
                    name: this.$tl("labels.booking.stats.charts.resaEvolution.y"),
                    nameLocation: "middle",
                    nameTextStyle: {
                        fontWeight: "bold",
                        padding: [0, 0, 30, 0],
                    },
                    nameRotate: 90,
                },
                legend: {
                    top: "1%",
                    padding: [30, 0, 0, 0],
                },
            },
            evolutionNbResaChartComparison: {
                title: {
                    text: this.$tl("labels.booking.stats.charts.resaEvolution.title"),
                    left: "center",
                    textStyle: {
                        fontWeight: "normal",
                    },
                    padding: [0, 0, 30, 0],
                },
                xAxis: {
                    type: "category",
                    name: this.$tl("labels.booking.stats.charts.resaEvolution.x"),
                    nameLocation: "middle",
                    nameTextStyle: {
                        fontWeight: "bold",
                        padding: [10, 0, 10, 0],
                    },
                    data: [],
                },
                yAxis: {
                    type: "value",
                    name: this.$tl("labels.booking.stats.charts.resaEvolution.y"),
                    nameLocation: "middle",
                    nameTextStyle: {
                        fontWeight: "bold",
                        padding: [0, 0, 30, 0],
                    },
                    nameRotate: 90,
                },
                legend: {
                    top: "1%",
                    padding: [30, 0, 0, 0],
                },
            },
            stats: {
                main: null,
                comparison: null,
            },
        };
    },
    computed: {
        pinEvolutionNbResa: {
            get() {
                return this.$store.getters["userPreferences/pinEvolutionNbResa"];
            },
            set(newVal) {
                this.$store.dispatch("userPreferences/setPinEvolutionNbResa", newVal);
            },
        },
        seriesDataLine() {
            const seriesNbResaEvolv = [
                {
                    type: "line",
                    name: this.labelPeriodMain,
                    data: this.seriesDataLineNbResaEvolv.nbValues,
                    animation: true,
                    animationDuration: 1000,
                    animationEasing: "elasticOut",
                    itemStyle: {
                        color: "#8C54FF",
                    },
                    areaStyle: {
                        opacity: 0.2,
                        color: new graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: "#8C54FF",
                            },
                            {
                                offset: 1,
                                color: "rgba(255,255,255,0)",
                            },
                        ]),
                    },
                },
            ];

            if (
                this.filters.hasComparison &&
                this.stats.comparison &&
                this.seriesDataLineNbResaEvolv.nbValues.length === this.seriesDataLineNbResaEvolvComparison.nbValues.length
            ) {
                seriesNbResaEvolv.push(...this.seriesDataLineComparison);
            }

            return seriesNbResaEvolv;
        },
        seriesDataLineComparison() {
            return [
                {
                    type: "line",
                    name: this.labelPeriodComparison,
                    data: this.seriesDataLineNbResaEvolvComparison.nbValues,
                    animation: true,
                    animationDuration: 1000,
                    animationEasing: "elasticOut",
                    itemStyle: {
                        color: "#bc43e1",
                    },
                    areaStyle: {
                        opacity: 0.2,
                        color: new graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: "#bc43e1",
                            },
                            {
                                offset: 1,
                                color: "rgba(255,255,255,0)",
                            },
                        ]),
                    },
                },
            ];
        },
        seriesDataLineNbResaEvolv() {
            const stats = {};
            this.stats.main.forEach((stat) => {
                stats[stat.resa_date] = stat.count_reservations;
            });

            let fromDate = this.filters.main.fromDate || this.getDateTime();
            const toDate = this.filters.main.toDate || this.getDateTime();
            const dayLabel = this.$tl("labels.booking.stats.reservations.day");
            let dayCounter = 1;
            const dates = [];
            const nbValues = [];

            const isComparisonValid = this.isComparisonValid();

            while (fromDate <= toDate) {
                if (isComparisonValid) {
                    dates.push(`${dayLabel}${dayCounter}`);
                } else {
                    dates.push(this.displayDate(fromDate, this.DATE_MED));
                }

                nbValues.push(stats[fromDate.toISODate()] || 0);
                fromDate = fromDate.plus({ days: 1 });
                dayCounter++;
            }

            this.evolutionNbResaChart.xAxis.data = dates;

            return {
                dates: dates,
                nbValues: nbValues,
            };
        },
        seriesDataLineNbResaEvolvComparison() {
            const stats = {};
            this.stats.comparison.forEach((stat) => {
                stats[stat.resa_date] = stat.count_reservations;
            });

            let fromDate = this.filters.comparison.fromDate || this.getDateTime();
            const toDate = this.filters.comparison.toDate || this.getDateTime();
            const dates = [];
            const nbValues = [];

            while (fromDate <= toDate) {
                dates.push(this.displayDate(fromDate, this.DATE_MED));
                nbValues.push(stats[fromDate.toISODate()] || 0);
                fromDate = fromDate.plus({ days: 1 });
            }

            this.evolutionNbResaChartComparison.xAxis.data = dates;

            return {
                dates: dates,
                nbValues: nbValues,
            };
        },
    },
    methods: {
        fetchStats(abortControllerSignal = null, forComparison = false) {
            const filters = this.getFiltersQueryParams(forComparison);

            return this.httpGet(`/api/stats/reservations-count-per-date?${filters}`, {
                config: { signal: abortControllerSignal },
                handleReject: false,
            }).then((response) => {
                if (response !== false) {
                    this.stats[forComparison ? "comparison" : "main"] = response.data;
                }
            });
        },
        isComparisonValid() {
            return (
                this.filters.hasComparison &&
                this.stats.comparison &&
                this.filters.main.toDate.diff(this.filters.main.fromDate, "days").days ===
                    this.filters.comparison.toDate.diff(this.filters.comparison.fromDate, "days").days
            );
        },
    },
    components: { LoaderComponent, VChart },
    watch: {
        pinEvolutionNbResa(newVal) {
            if (!newVal) {
                this.showEvolutionNbResa = false;
            }
        },
    },
};
</script>

<style scoped>
button.btn:disabled {
    cursor: default;
}

.chart {
    height: 450px;
}
</style>
