<template>
    <div>
        <template v-if="sale.gv_product">
            <span class="d-block" v-if="sale.gv_product.type === 'menu'"> {{ sale.menu_quantity }} x {{ sale.gv_product.name }} </span>
            <span class="d-block" v-if="sale.gv_product.type === 'amount'">
                {{ sale.gv_product.name }}
            </span>
            <span class="d-block ml-3" v-for="option in sale.gv_sale_product_options.data" :key="option.id">
                {{ option.quantity }} x {{ option.gv_products_option.name }}
            </span>
        </template>
        <span class="d-block" v-else>{{ sale.imported_product_name || "--" }}</span>
        <span class="d-block" v-if="sale.expedition_type === EXPEDITION_TYPE_POSTAL_MAIL.value">1 x Livraison</span>
    </div>
</template>
<script>
import ExpeditionTypesEnum from "../../mixins/enums/gift_vouchers/ExpeditionTypesEnum";

export default {
    props: {
        sale: {
            type: Object,
            require: true,
        },
    },
    mixins: [ExpeditionTypesEnum],
};
</script>
