<template>
    <div class="pb-5 pt-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-1 pt-2 my-1">
                    <h5 class="title mt-2">Catégories</h5>
                    <button :disabled="!has_right_to_create_categories" @click="addCategory" class="btn btn-sm btn-success mr-1 btn-circle">
                        <feather type="plus"></feather>
                        <span>Ajouter une catégorie</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-12">
                <LoaderComponent v-if="loading" />
                <div v-else>
                    <div v-if="error" class="alert alert-danger">{{ error }}</div>
                    <div v-else>
                        <div class="mb-1">
                            <switch-input-component
                                :disabled="isTogglingIsDisplayPerCategory || !has_right_to_update_config"
                                :is-form-grid="false"
                                :value="display_per_category"
                                @input="toggleIsDisplayPerCategory">
                                <template v-slot:label>
                                    Activer l'affichage des produits par catégorie
                                    <feather
                                        v-tooltip="getTooltipNotice('notices.clickAndCollect.categories.isDisplayPerCategory')"
                                        type="info"
                                        class="ml-1 pointer tooltip-infos" />
                                </template>
                            </switch-input-component>
                        </div>
                        <div class="mb-1">
                            <switch-input-component
                                :disabled="isTogglingUseBurgerMenu || !has_right_to_update_config"
                                :is-form-grid="false"
                                :value="use_burger_menu"
                                @input="toggleUseBurgerMenu">
                                <template v-slot:label>
                                    Activer l'affichage des catégories par menu déroulant
                                    <feather
                                        v-tooltip="getTooltipNotice('notices.clickAndCollect.categories.useBurgerMenu')"
                                        type="info"
                                        class="ml-1 pointer tooltip-infos" />
                                </template>
                            </switch-input-component>
                        </div>
                        <div class="mb-3">
                            <table class="capitalize table table-sm table-striped border-bottom">
                                <thead class="border-bottom">
                                    <tr>
                                        <th>Ordre</th>
                                        <th>Nom</th>
                                        <th>Stock / Créneau</th>
                                        <th>Stock / Service - Plage de livraison</th>
                                        <th>Stock / Jour</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <draggable
                                    :disabled="!has_right_to_update_categories || selectedCategory"
                                    tag="tbody"
                                    v-model="categories"
                                    :draggable="'.categories'">
                                    <template v-for="printCategory in categories">
                                        <tr
                                            v-bind:key="printCategory.id"
                                            class="categories"
                                            v-if="selectedCategory == printCategory.id || !selectedCategory">
                                            <td style="width: 70px">
                                                <svg
                                                    v-if="!selectedCategory"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="css-i6dzq1 feather mr-1"
                                                    style="cursor: grab; color: rgb(170, 170, 170)">
                                                    <line x1="3" y1="9" x2="21" y2="9"></line>
                                                    <line x1="3" y1="15" x2="21" y2="15"></line>
                                                </svg>
                                                <button
                                                    v-else
                                                    @click="selectedCategory ? (selectedCategory = null) : (selectedCategory = printCategory.id)"
                                                    class="btn pl-0">
                                                    <feather type="chevron-left" class="feather-24" />
                                                </button>
                                            </td>
                                            <td>
                                                <strong v-if="selectedCategory">{{ printCategory.name }}</strong>
                                                <span v-else>{{ printCategory.name }}</span>
                                            </td>
                                            <td>
                                                <span class="text-muted" v-if="!printCategory.is_stock_per_slot_editable">--</span>
                                                <svg
                                                    v-else-if="printCategory.stock_per_slot === null"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="css-i6dzq1 feather">
                                                    <circle cx="7" cy="11.5" r="4.5" />
                                                    <circle cx="17" cy="11.5" r="4.5" />
                                                </svg>
                                                <span v-else>{{ printCategory.stock_per_slot }}</span>
                                            </td>
                                            <td>
                                                <svg
                                                    v-if="printCategory.stock_per_service === null"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="css-i6dzq1 feather">
                                                    <circle cx="7" cy="11.5" r="4.5" />
                                                    <circle cx="17" cy="11.5" r="4.5" />
                                                </svg>
                                                <span v-else>{{ printCategory.stock_per_service }}</span>
                                            </td>
                                            <td>
                                                <svg
                                                    v-if="printCategory.stock_per_day === null"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="css-i6dzq1 feather">
                                                    <circle cx="7" cy="11.5" r="4.5" />
                                                    <circle cx="17" cy="11.5" r="4.5" />
                                                </svg>
                                                <span v-else>{{ printCategory.stock_per_day }}</span>
                                            </td>
                                            <td>
                                                <button
                                                    :disabled="!has_right_to_update_categories"
                                                    class="btn btn-sm btn-success btn-square"
                                                    title="Modifier la réservation"
                                                    @click="editCategory(printCategory)"
                                                    v-tooltip="{ delay: { show: 400, hide: 0 }, content: 'Éditer' }">
                                                    <feather type="edit" />
                                                </button>
                                                <button
                                                    :disabled="!has_right_to_delete_categories"
                                                    class="btn btn-sm btn-danger btn-square"
                                                    @click="deleteCategory(printCategory.id)"
                                                    v-tooltip="{ delay: { show: 400, hide: 0 }, content: 'Supprimer' }">
                                                    <feather type="trash-2" />
                                                </button>
                                                <button
                                                    v-if="!selectedCategory"
                                                    :disabled="!has_right_to_update_categories"
                                                    class="btn btn-sm btn-outline-secondary btn-square"
                                                    @click="selectedCategory ? (selectedCategory = null) : (selectedCategory = printCategory.id)"
                                                    v-tooltip="{
                                                        delay: { show: 400, hide: 0 },
                                                        content: 'Ordonner les produits de cette catégorie',
                                                    }">
                                                    <svg
                                                        viewBox="0 0 24 24"
                                                        width="24"
                                                        height="24"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        class="css-i6dzq1 feather">
                                                        <polyline points="9 5 12 2 15 5"></polyline>
                                                        <polyline points="15 19 12 22 9 19"></polyline>
                                                        <line x1="12" y1="2" x2="12" y2="22"></line>
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </template>
                                </draggable>
                                <template v-if="selectedCategory !== null">
                                    <LoaderComponent v-if="loadingProduct" />
                                    <draggable tag="tbody" v-else v-model="products" :draggable="has_right_to_update_categories ? '.products' : ''">
                                        <tr class="products" v-for="printProduct in products" :key="printProduct.id">
                                            <td style="cursor: grab; width: 70px">
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="css-i6dzq1 feather mr-1"
                                                    style="color: rgb(170, 170, 170)">
                                                    <line x1="3" y1="9" x2="21" y2="9"></line>
                                                    <line x1="3" y1="15" x2="21" y2="15"></line>
                                                </svg>
                                            </td>
                                            <td>{{ printProduct.name }}</td>
                                            <!-- <td>
                                --
                            </td> -->
                                        </tr>
                                    </draggable>
                                </template>
                            </table>
                            <add-click-and-collect-category-modal
                                v-if="showAddClickAndCollectCategoryModal"
                                @close="showAddClickAndCollectCategoryModal = false"
                                @saved="categorySaved"
                                :category="category" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-0 mb-3" v-if="!loading && has_right_to_update_categories">
            <div class="col-12 text-muted">
                Vous avez la possibilité d'ordonner vos <template v-if="!selectedCategory">catégories</template
                ><template v-else>produits</template> en cliquant sur l'icone
                <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="css-i6dzq1 feather mr-1"
                    style="color: rgb(170, 170, 170)">
                    <line x1="3" y1="9" x2="21" y2="9"></line>
                    <line x1="3" y1="15" x2="21" y2="15"></line>
                </svg>
                et en déplaçant la ligne. Pensez à enregistrer vos changements.
            </div>
        </div>

        <div class="row m-0" v-if="!loading">
            <div class="col-12">
                <input
                    :disabled="!has_right_to_update_categories"
                    @click="save()"
                    type="submit"
                    name="submit"
                    value="Enregistrer"
                    class="btn btn-success btn-circle" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import addClickAndCollectCategoryModal from "../../../components/Modals/clickAndCollect/addClickAndCollectCategoryModal.vue";
import draggable from "vuedraggable";
import SwitchInputComponent from "../../../components/forms/SwitchInputComponent.vue";

export default {
    data() {
        return {
            loading: false,
            error: null,
            showAddClickAndCollectCategoryModal: false,
            categories: null,
            category: null,
            selectedCategory: null,
            products: null,
            loadingProduct: false,
            display_per_category: false,
            isTogglingIsDisplayPerCategory: false,
            use_burger_menu: false,
            isTogglingUseBurgerMenu: false,
        };
    },
    watch: {
        selectedCategory: {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.fetchProducts();
                } else {
                    this.fetchCategories();
                }
            },
        },
    },
    computed: {
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_categories() {
            return this.rights.includes("click_and_collect.products.create");
        },
        has_right_to_update_categories() {
            return this.rights.includes("click_and_collect.products.update");
        },
        has_right_to_delete_categories() {
            return this.rights.includes("click_and_collect.products.delete");
        },
        has_right_to_update_config() {
            return this.rights.includes("click_and_collect.config.update");
        },
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
    },
    methods: {
        toggleIsDisplayPerCategory(newVal) {
            const { action, toggle } = newVal ? { action: "activer", toggle: "enable" } : { action: "désactiver", toggle: "disable" };

            if (this.isTogglingIsDisplayPerCategory || !confirm(`Êtes vous sûr de vouloir ${action} l'affichage par catégorie ?`)) {
                return;
            }

            this.isTogglingIsDisplayPerCategory = true;

            this.httpPut(`/api/click_and_collect/${this.restaurantId}/categoriesSettings/displayPerCategory/${toggle}`)
                .then((response) => {
                    this.display_per_category = response === false ? !newVal : newVal;
                })
                .finally(() => (this.isTogglingIsDisplayPerCategory = false));
        },
        toggleUseBurgerMenu(newVal) {
            const { action, toggle } = newVal ? { action: "activer", toggle: "enable" } : { action: "désactiver", toggle: "disable" };

            if (this.isTogglingUseBurgerMenu || !confirm(`Êtes vous sûr de vouloir ${action} le menu déroulant ?`)) {
                return;
            }

            this.isTogglingUseBurgerMenu = true;

            this.httpPut(`/api/click_and_collect/${this.restaurantId}/categoriesSettings/useBurgerMenu/${toggle}`)
                .then((response) => {
                    this.use_burger_menu = response === false ? !newVal : newVal;
                })
                .finally(() => (this.isTogglingUseBurgerMenu = false));
        },
        addCategory() {
            if (!this.has_right_to_create_categories) {
                return;
            }

            this.category = {
                id: null,
                name: null,
                order: this.categories.length + 1,
                stock_per_slot: null,
                stock_per_service: null,
                stock_per_day: null,
                is_stock_per_day_editable: false,
            };
            this.showAddClickAndCollectCategoryModal = true;
        },
        editCategory(category) {
            if (!this.has_right_to_update_categories) {
                return;
            }

            this.category = _.cloneDeep(category);
            this.showAddClickAndCollectCategoryModal = true;
        },
        categorySaved() {
            this.showAddClickAndCollectCategoryModal = false;
            this.fetchCategories();
        },
        deleteCategory(category_id) {
            if (!this.has_right_to_delete_categories || !confirm("Êtes vous sûr de vouloir supprimer cette catégorie ?")) {
                return;
            }

            this.loading = true;

            this.httpDelete(`/api/click_and_collect/${this.restaurantId}/products/categories/${category_id}`)
                .then((response) => {
                    if (response !== false) {
                        this.fetchCategories();
                    }
                })
                .finally(() => (this.loading = false));
        },
        fetchCategories(e) {
            this.loading = true;
            this.error = null;
            this.categories = null;
            this.selectedCategory = null;

            if (e) {
                e.preventDefault();
            }

            this.httpGet(`/api/click_and_collect/${this.restaurantId}/products/categories?include=is_stock_per_slot_editable`)
                .then((response) => {
                    if (response !== false) {
                        this.categories = response.data.data;
                        this.display_per_category = response.data.display_per_category;
                        this.use_burger_menu = response.data.use_burger_menu;
                    }
                })
                .finally(() => (this.loading = false));
        },
        fetchProducts(e) {
            this.loadingProduct = true;
            this.error = null;
            this.products = null;

            if (e) {
                e.preventDefault();
            }

            this.httpGet(`/api/click_and_collect/${this.restaurantId}/categories/${this.selectedCategory}/products`)
                .then((response) => {
                    if (response !== false) {
                        this.products = response.data.products;
                    }
                })
                .finally(() => (this.loadingProduct = false));
        },
        save() {
            if (!this.has_right_to_update_categories) {
                return;
            }

            this.loading = true;

            let url = "";
            if (this.selectedCategory) {
                url = `/api/click_and_collect/${this.restaurantId}/categories/${this.selectedCategory}/products/order`;
            } else {
                url = `/api/click_and_collect/${this.restaurantId}/products/order`;
            }

            this.httpPost(url, { orders: this.selectedCategory ? this.products : this.categories }).finally(() => (this.loading = false));
        },
    },
    components: {
        LoaderComponent,
        addClickAndCollectCategoryModal,
        draggable,
        SwitchInputComponent,
    },
    created() {
        this.fetchCategories();
    },
};
</script>
