export default {
    data() {
        return {
            COMMAND_PENDING: {
                value: "pending",
            },
            COMMAND_TO_ACCEPT: {
                value: "to_accept",
            },
            COMMAND_TO_PREPARE: {
                value: "to_prepare",
            },
            COMMAND_READY: {
                value: "ready",
            },
            COMMAND_DISPATCHED: {
                value: "dispatched",
            },
            COMMAND_COLLECTED: {
                value: "collected",
            },
            COMMAND_CANCELED: {
                value: "canceled",
            },
        };
    },
    methods: {},
    computed: {
        ALL_COMMAND_STATUS() {
            return [
                this.COMMAND_PENDING,
                this.COMMAND_TO_ACCEPT,
                this.COMMAND_TO_PREPARE,
                this.COMMAND_READY,
                this.COMMAND_DISPATCHED,
                this.COMMAND_COLLECTED,
                this.COMMAND_CANCELED,
            ];
        },
        VALID_COMMAND_STATUS_FOR_LABEL() {
            return [this.COMMAND_READY.value, this.COMMAND_DISPATCHED.value];
        },
    },
};
