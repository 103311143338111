var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.getTooltip(
              _vm.$t("labels.booking.reservations.actions.accept")
            ),
            expression:
              "getTooltip($t('labels.booking.reservations.actions.accept'))",
          },
        ],
        staticClass: "btn btn-success btn-sm btn-square",
        attrs: { type: "button", disabled: _vm.isDisabled },
        on: {
          click: function ($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.changeStatus(true)
          },
        },
      },
      [
        _c("feather", {
          staticStyle: { "pointer-events": "none" },
          attrs: { type: "check" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.getTooltip(
              _vm.$t("labels.booking.reservations.actions.refuse")
            ),
            expression:
              "getTooltip($t('labels.booking.reservations.actions.refuse'))",
          },
        ],
        staticClass: "btn btn-danger btn-sm text-white btn-square",
        attrs: { type: "button", disabled: _vm.isDisabled },
        on: {
          click: function ($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.changeStatus(false)
          },
        },
      },
      [
        _c("feather", {
          staticStyle: { "pointer-events": "none" },
          attrs: { type: "x" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.canAskFootprint
      ? _c(
          "button",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.getTooltip(
                  _vm.$t("labels.booking.reservations.actions.acceptFootprint")
                ),
                expression:
                  "getTooltip($t('labels.booking.reservations.actions.acceptFootprint'))",
              },
            ],
            staticClass: "btn btn-warning btn-sm btn-square text-white",
            attrs: { type: "button", disabled: _vm.isDisabled },
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.askFootprint.apply(null, arguments)
              },
            },
          },
          [
            _c("feather", {
              staticStyle: { "pointer-events": "none" },
              attrs: { type: "credit-card" },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }