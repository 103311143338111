<template>
    <div>
        <div class="col-12">
            <div v-if="restaurant">
                <div v-if="servicesToDisplay.length > 0" v-for="service in servicesToDisplay" class="mb-3">
                    <div
                        :ref="`service-${service.id}`"
                        v-if="service.reservations.length > 0 || service.nbPassingCustomer > 0"
                        class="d-flex flex-wrap justify-content-between flex-md-nowrap pb-1 mb-3 border-bottom">
                        <h6 style="text-transform: uppercase">
                            {{ displayDate(reservation_date, "cccc") }}
                            <span class="capacite badge badge-secondary"
                                >{{ service.category }} {{ service.special === 1 ? " (exceptionnel) " : "" }}</span
                            >
                            <button
                                v-if="service.is_full === 1"
                                class="btn badge badge-danger"
                                style="color: white !important"
                                @click="showServiceFull(service.id)">
                                {{ $tl("labels.booking.services.closed").toUpperCase() }}
                            </button>
                            <button v-if="service.is_full === 0" class="btn text-white badge btn-warning" @click="showServiceFull(service.id)">
                                {{ $tl("labels.booking.services.closedPartially").toUpperCase() }}
                            </button>
                        </h6>

                        <h6>
                            <span class="capacite badge badge-secondary"
                                >{{ service.pax }} / {{ service.new_pax !== null ? service.new_pax : service.max_pax }}</span
                            >
                        </h6>
                    </div>
                    <div v-if="service.reservations.length > 0 || service.nbPassingCustomer > 0" class="border-bottom mb-2">
                        <table class="capitalize table table-book table-striped table-sm">
                            <thead class="border-bottom">
                                <tr>
                                    <th class="client-column">
                                        <feather type="user" style="width: 20px; height: 20px" class="none-desk" />
                                        <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.client") }}</span>
                                    </th>
                                    <th>
                                        <feather type="users" style="width: 20px; height: 20px" class="none-desk" />
                                        <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.pax") }}</span>
                                    </th>
                                    <th>
                                        <feather type="monitor" style="width: 20px; height: 20px" class="none-desk" />
                                        <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.table", restaurant_id) }}</span>
                                    </th>
                                    <th>
                                        <feather type="clock" style="width: 20px; height: 20px" class="none-desk" />
                                        <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.hour") }}</span>
                                    </th>
                                    <th>
                                        <feather type="book-open" style="width: 20px; height: 20px" class="none-desk" />
                                        <span class="none-mobile">{{
                                            $tl("labels.booking.reservations.grid.columns.menusAndOptions", selected_restaurant_id)
                                        }}</span>
                                    </th>
                                    <th>
                                        <feather type="message-circle" style="width: 20px; height: 20px" class="none-desk" />
                                        <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.comments") }}</span>
                                    </th>
                                    <th class="text-center">
                                        <feather type="check-circle" style="width: 20px; height: 20px" class="none-desk" />
                                        <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.status") }}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    id="resaRow"
                                    v-for="reservation in service.reservations"
                                    class="resa"
                                    :key="`${service.id}-${reservation.id}`"
                                    :class="{
                                        updated: reservation.updated && reservation.updated === true,
                                        new: reservation.new && reservation.new === true,
                                    }">
                                    <td>
                                        <inline-client :client="reservation.client" :module-enum="MODULE_TYPE_BOOKING"></inline-client>
                                    </td>
                                    <td class="table-text-center">
                                        {{ reservation.nb_pers * 1 + reservation.nb_children * 1 }}
                                    </td>
                                    <td class="table-text-center">
                                        <template v-if="reservation.tables.data.length > 0" v-for="(table, index) in reservation.tables.data">
                                            {{ table.name }}{{ index + 1 < reservation.tables.data.length ? " - " : "" }}
                                        </template>
                                        <template v-else-if="reservation.num_table && reservation.num_table !== ''">
                                            {{ reservation.num_table }}
                                        </template>
                                    </td>
                                    <td>
                                        {{ displayDate(setHourOnDateTime(reservation.slot.hour_start), TIME_SIMPLE) }}
                                    </td>
                                    <td v-html="formatMenusAndGeneralOptions(reservation)"></td>
                                    <td>
                                        <div v-if="reservation.comment">
                                            <strong class="text-success">{{ $tl("labels.clientColon") }}</strong>
                                            {{ reservation.comment }}
                                        </div>
                                        <div
                                            v-if="!$_.isEmpty(reservation.comment) && !$_.isEmpty(reservation.restaurant_comment)"
                                            class="sepa"></div>
                                        <div v-if="reservation.restaurant_comment">
                                            <strong class="text-warning">{{ $tl("labels.privateColon") }}</strong>
                                            {{ reservation.restaurant_comment }}
                                        </div>
                                    </td>
                                    <td
                                        class="text-center"
                                        v-if="
                                            reservation.status === RESERVATION_STATUS_VALIDATED.value ||
                                            reservation.status === RESERVATION_STATUS_CONFIRMED.value
                                        ">
                                        <strong>{{ $tl("labels.booking.reservations.status.confirmed") }}</strong>
                                    </td>
                                    <td class="text-center bg-main-color-light" v-else-if="reservation.status === RESERVATION_STATUS_AT_TABLE.value">
                                        <strong class="text-white">{{ $tl("labels.booking.reservations.status.at_table", restaurant_id) }}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <span v-if="service.reservations.length > 0 || service.nbPassingCustomer > 0" class="badge badge-secondary ml-1">
                        <div v-if="service.reservations.length > 0">
                            {{ $tc("labels.booking.reservations.count", service.reservations.length) }}
                        </div>
                        <div v-if="service.nbPassingCustomer > 0">
                            {{ $tcl("labels.booking.reservations.passingCustomer.showCount", service.nbPassingCustomer) }}
                        </div>
                    </span>
                </div>
                <div v-if="services.length > 0 && !hasReservations">
                    <div class="row m-0">
                        <div class="col-lg-6 offset-lg-3 pt-5 text-center">
                            <div class="border-light b-radius-20 p-4 mb-3">
                                <h5>{{ $tl("labels.booking.list.empty") }}</h5>
                                <img class="mt-5 none-mobile" width="360" src="/images/empty-table.png" alt="No Show" />
                                <img class="mt-3 none-desk" width="260" src="/images/empty-table.png" alt="No Show" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReservationStatusEnum from "../../mixins/enums/booking/ReservationStatusEnum";
import LangsEnum from "../../mixins/enums/LangsEnum";
import InlineClient from "../Default/Clients/inlineClient.vue";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum.js";

export default {
    name: "ReservationsPerServiceForKitchen",
    props: {
        services: {
            required: true,
            type: Array,
        },
        restaurant: {
            required: true,
            type: Object,
        },
        reservation_date: {
            required: true,
        },
    },
    data() {
        return {
            selected_restaurant_id: null,
            selected_reservation_id: null,
            selected_service_id: null,
            selected_reservation: null,
            selected_reservation_date: null,
        };
    },
    mixins: [ReservationStatusEnum, LangsEnum, ModuleTypesEnum],
    components: {
        InlineClient,
    },
    computed: {
        hasReservations() {
            for (const index in this.services) {
                if (this.services[index].reservations && this.services[index].reservations.length > 0) return true;
            }

            return false;
        },
        servicesToDisplay() {
            return this.services.map((service) => this.computeService(service));
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    created() {
        this.selected_restaurant_id = this.$route.params.restaurant_id;
    },
    methods: {
        formatMenusAndGeneralOptions(reservation) {
            let menus = [];
            let generalOptions = [];
            reservation.menus.data.forEach((menu) => {
                let menuOptions = [
                    `<div class="kitchen-menu"><span class="number-product badge badge-success"><strong>${menu.nb}</strong></span> <strong>${menu.name}</strong></div>`,
                ];
                menu.options.data.forEach((menuOpt) => {
                    menuOptions.push(
                        `<div class="kitchen-option ml-4"><span class="number-product badge badge-warning"><strong>${menuOpt.nb}</strong></span> ${menuOpt.name}</div>`
                    );
                });
                menus.push(menuOptions.join(""));
            });
            reservation.general_options.data.forEach((opt) => {
                generalOptions.push(
                    `<div class="kitchen-general-option mt-2"><span class="number-product badge badge-danger"><strong>${opt.nb}</strong></span> ${opt.name}</div>`
                );
            });
            return menus.concat(generalOptions).join("");
        },
        computeService(service) {
            var result = {
                id: service.id,
                name: service.name,
                category: service.category,
                category_en: service.category_en,
                days: service.days,
                hour_begin: service.hour_begin,
                hour_end: service.hour_end,
                max_pax: service.max_pax,
                new_pax: service.new_pax,
                lock_reservation_until: service.lock_reservation_until,
                special: service.special,
                special_date_begin: service.special_date_begin,
                special_date_end: service.special_date_end,
                menu_unique: service.menu_unique,
                menu_unique_from: service.menu_unique_from,
                prepayment_mandatory: service.prepayment_mandatory,
                prepayment_percent: service.prepayment_percent,
                cancellation_until: service.cancellation_until,
                is_full: service.is_full,
                waitings_count: service.waitings_count,
                reservations: [],
                pax: 0,
            };

            var { reservations, pax, nbPassingCustomer } = this.computeReservations(service.reservations);

            result.reservations = reservations;
            result.pax = pax;
            result.nbPassingCustomer = nbPassingCustomer;

            return result;
        },
        computeReservations(reservations) {
            var result = [];
            var pax = 0;
            var nbPassingCustomer = 0;
            const avoidStatusForPax = [
                this.RESERVATION_STATUS_CANCELED.value,
                this.RESERVATION_STATUS_NOSHOW.value,
                this.RESERVATION_STATUS_REFUSED.value,
                this.RESERVATION_STATUS_PENDING.value,
            ];
            const acceptedStatus = [
                this.RESERVATION_STATUS_VALIDATED.value,
                this.RESERVATION_STATUS_CONFIRMED.value,
                this.RESERVATION_STATUS_AT_TABLE.value,
            ];

            reservations.forEach((reservation) => {
                if (acceptedStatus.includes(reservation.status) && !reservation.is_passing_customer) {
                    result.push(reservation);
                }

                if (reservation.is_passing_customer) {
                    ++nbPassingCustomer;
                }

                if (!avoidStatusForPax.includes(reservation.status)) {
                    pax += reservation.nb_children;
                    pax += reservation.nb_pers;
                }
            });

            result.sort(this.sortReservations);

            return { reservations: result, pax, nbPassingCustomer };
        },
        sortReservations(resaA, resaB) {
            const resaAStatusScore = this.reservationStatusToScore(resaA.status);
            const resaBStatusScore = this.reservationStatusToScore(resaB.status);

            if (resaAStatusScore === resaBStatusScore) {
                const resaDateTimeA = this.getDateTime(resaA.reservation_datetime);
                const resaDateTimeB = this.getDateTime(resaB.reservation_datetime);

                if (resaDateTimeA < resaDateTimeB) {
                    return -1;
                }
                if (resaDateTimeA > resaDateTimeB) {
                    return 1;
                }
                return 0;
            } else if (resaAStatusScore < resaBStatusScore) {
                return 1;
            }
            return -1;
        },
        reservationStatusToScore(status) {
            switch (status) {
                case "at_table":
                    return 9;
                case "confirmed":
                    return 8;
                case "validated":
                    return 7;
                case "over":
                    return 6;
                case "created":
                    return 5;
                case "option":
                    return 4;
                case "noshow":
                    return 3;
                case "canceled":
                    return 2;
                case "refused":
                    return 1;
                default:
                    return 0;
            }
        },
        showServiceFull(id) {
            this.$emit("show-service-full", {
                service_id: id,
            });
        },
    },
    watch: {
        "$route.params.restaurant_id": function (id) {
            this.selected_restaurant_id = this.$route.params.restaurant_id;
        },
    },
};
</script>
