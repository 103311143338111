var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "col-12" }, [
        _vm.restaurant
          ? _c(
              "div",
              [
                _vm.allServicesClosedAndNoResa
                  ? _c("no-opened-services", {
                      attrs: {
                        "is-from-closure": "",
                        "closure-name": _vm.closureName,
                      },
                    })
                  : _vm.servicesToDisplay.length > 0
                  ? _vm._l(
                      _vm.servicesToDisplay,
                      function (service, serviceIndex) {
                        return _c("div", { key: serviceIndex }, [
                          service.reservations.length > 0 ||
                          service.waitings_count > 0 ||
                          service.nbPassingCustomer > 0
                            ? _c(
                                "div",
                                {
                                  ref: `service-${service.id}`,
                                  refInFor: true,
                                  staticClass:
                                    "d-flex flex-wrap justify-content-between flex-md-nowrap pb-0 mb-0 day-div",
                                },
                                [
                                  _c(
                                    "h6",
                                    {
                                      staticClass: "pt-1",
                                      staticStyle: {
                                        "text-transform": "uppercase",
                                        "margin-bottom": "0.3em",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.displayDate(
                                              _vm.reservation_date,
                                              "cccc"
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "capacite badge badge-secondary",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getServiceCategoryLabel(
                                                service
                                              )
                                            ) +
                                              "\n                            " +
                                              _vm._s(
                                                service.special === 1
                                                  ? ` (${_vm.$tl(
                                                      "labels.booking.services.special.title"
                                                    )})`
                                                  : ""
                                              )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.hasResaNotPlaced(service)
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-danger text-uppercase",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.booking.reservations.futureBlocked"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("feather", {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: {
                                                    content: _vm.$tl(
                                                      "infos.booking.reservations.placeOnSeatingPlan"
                                                    ),
                                                  },
                                                  expression:
                                                    "{ content: $tl('infos.booking.reservations.placeOnSeatingPlan') }",
                                                },
                                              ],
                                              staticClass: "text-danger",
                                              attrs: { type: "info" },
                                            }),
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !service.has_closure
                                        ? [
                                            service.is_full === 1
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn badge badge-danger text-uppercase",
                                                    staticStyle: {
                                                      color: "white !important",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showServiceFull(
                                                          service.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                " +
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.booking.services.closed"
                                                          )
                                                        ) +
                                                        "\n                            "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            service.is_full === 0
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn text-white badge badge-warning text-uppercase",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showServiceFull(
                                                          service.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                " +
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.booking.services.closedPartially"
                                                          )
                                                        ) +
                                                        "\n                            "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      service.has_closure
                                        ? _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "badge badge-danger text-uppercase",
                                              attrs: {
                                                to: {
                                                  name: "booking.restaurants.settings.closures",
                                                  param: {
                                                    restaurant_id:
                                                      _vm.$route.params
                                                        .restaurant_id,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.booking.closures.title"
                                                    )
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-inline-block text-muted ml-2",
                                          staticStyle: {
                                            "text-transform": "none",
                                          },
                                        },
                                        [
                                          !service.is_editing_note
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "clickable p-2",
                                                  staticStyle: {
                                                    "font-weight": "400",
                                                    "margin-top": "1px",
                                                    "font-size": "0.8rem",
                                                    "margin-bottom": "1px",
                                                    "padding-left":
                                                      "13px !important",
                                                    "padding-right":
                                                      "13px !important",
                                                    "border-radius":
                                                      "20px !important",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editServiceNote(
                                                        service
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                " +
                                                      _vm._s(
                                                        service.note ||
                                                          _vm.$tl(
                                                            "labels.booking.services.notes.add"
                                                          )
                                                      ) +
                                                      "\n                            "
                                                  ),
                                                ]
                                              )
                                            : _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.services[
                                                        service.serviceIndex
                                                      ].note,
                                                    expression:
                                                      "services[service.serviceIndex].note",
                                                  },
                                                ],
                                                ref: `service-${service.id}-edit-note`,
                                                refInFor: true,
                                                staticClass: "form-control",
                                                staticStyle: {
                                                  "margin-top": "-1px",
                                                  "border-radius":
                                                    "20px !important",
                                                  width: "300px",
                                                },
                                                attrs: {
                                                  type: "text",
                                                  maxlength: "40",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.services[
                                                      service.serviceIndex
                                                    ].note,
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.onBlurEditServiceNote(
                                                      service
                                                    )
                                                  },
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.services[
                                                        service.serviceIndex
                                                      ],
                                                      "note",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("h6", { staticClass: "pt-1" }, [
                                    service.waitings_count > 0
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-outline-secondary btn-circle",
                                            on: {
                                              click: function ($event) {
                                                _vm.showWaitingList(
                                                  service.id,
                                                  _vm.reservation_date.toISODate()
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.booking.waitings.list.title"
                                                  )
                                                ) +
                                                "\n                            "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge badge-danger",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(service.waitings_count)
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "capacite badge badge-secondary",
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(service.pax) +
                                            " / " +
                                            _vm._s(
                                              service.new_pax !== null
                                                ? service.new_pax
                                                : service.max_pax
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          service.reservations.length > 0
                            ? _c(
                                "div",
                                { staticClass: "border-bottom mb-3" },
                                [
                                  _c("ReservationsGrid", {
                                    attrs: {
                                      restaurant: _vm.restaurant,
                                      service: service,
                                      reservations: service.reservations,
                                    },
                                    on: {
                                      displayRefundPartiallyModal:
                                        _vm.displayRefundPartiallyModal,
                                      displayNoshowModal:
                                        _vm.displayNoshowModal,
                                      displayCancelModal:
                                        _vm.displayCancelModal,
                                      showResa: _vm.showResa,
                                      editReservation: _vm.editReservation,
                                      sendEmail: _vm.sendEmail,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          service.reservations.length > 0 ||
                          service.nbPassingCustomer > 0
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "badge badge-secondary ml-1 mb-4",
                                },
                                [
                                  service.reservations.length > 0
                                    ? _c("div", [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$tc(
                                                "labels.booking.reservations.nbValidated",
                                                _vm.reservationsValidated(
                                                  service.reservations
                                                ).length
                                              )
                                            ) +
                                            "\n                        / " +
                                            _vm._s(
                                              service.reservations.length
                                            ) +
                                            "\n                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  service.nbPassingCustomer > 0
                                    ? _c("div", [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$tcl(
                                                "labels.booking.reservations.passingCustomer.showCount",
                                                service.nbPassingCustomer
                                              )
                                            ) +
                                            "\n                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ])
                      }
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.services.length > 0 &&
                !_vm.hasReservations &&
                !_vm.allServicesClosedAndNoResa
                  ? _c("div", [
                      _c("div", { staticClass: "row m-0" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-lg-6 offset-lg-3 pt-5 text-center",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "border-light b-radius-20 p-4 mb-3",
                              },
                              [
                                _c("h5", [
                                  _vm._v(
                                    _vm._s(_vm.$tl("labels.booking.list.empty"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "mt-5 none-mobile",
                                  attrs: {
                                    width: "360",
                                    src: "/images/empty-table.png",
                                    alt: "No Show",
                                  },
                                }),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "mt-3 none-desk",
                                  attrs: {
                                    width: "260",
                                    src: "/images/empty-table.png",
                                    alt: "No Show",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-white pb-4" }, [_vm._v(".")]),
      _vm._v(" "),
      _vm.showSetNoshowModal
        ? _c("set-noshow-modal", {
            attrs: {
              restaurant_id: _vm.selected_restaurant_id,
              reservation: _vm.selected_reservation,
            },
            on: {
              close: function ($event) {
                _vm.showSetNoshowModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showRefundPartiallyModal
        ? _c("cancel-paid-reservation-modal", {
            attrs: { reservation_id: _vm.selected_reservation.id },
            on: {
              close: function ($event) {
                _vm.showRefundPartiallyModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showReservationModal
        ? _c("show-reservation-modal", {
            attrs: {
              reservation_id: _vm.selected_reservation_id,
              restaurant_id: _vm.selected_restaurant_id,
            },
            on: {
              "resa-edit": _vm.editReservationFromShowModal,
              close: function ($event) {
                _vm.showReservationModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showWaitingsModal
        ? _c("show-waitings-modal", {
            attrs: {
              service_id: _vm.selected_service_id,
              reservation_date: _vm.selected_reservation_date,
            },
            on: {
              close: function ($event) {
                _vm.showWaitingsModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showEditReservationModal
        ? _c("edit-reservation-modal", {
            attrs: {
              restaurant_id: _vm.selected_restaurant_id,
              reservation_id: _vm.selected_reservation_id,
            },
            on: {
              close: function ($event) {
                _vm.showEditReservationModal = false
              },
              "reservation-edited": _vm.reservationEdited,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showCancelModal
        ? _c("cancel-reservation-modal", {
            attrs: {
              "reservation-id": _vm.selected_reservation_id,
              client: _vm.selected_reservation_client,
              status: _vm.newCancelStatus,
            },
            on: {
              close: function ($event) {
                _vm.showCancelModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }