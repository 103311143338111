export default {
    data() {
        return {
            SENDCLOUD_ERROR_STATUS: {
                value: "error",
                label: "Action requise",
                class: "text-danger",
            },
            SENDCLOUD_NO_LABEL: {
                value: "no_label",
                label: "Brouillon",
                class: "text-muted",
            },
            SENDCLOUD_READY: {
                value: "ready",
                label: "Étiquette prête",
                class: "text-success",
            },
            SENDCLOUD_CANCELED: {
                value: "canceled",
                label: "Annulé",
                class: "text-danger",
            },
            SENDCLOUD_DELAYED: {
                value: "delayed",
                label: "Retardé",
                class: "text-warning",
            },
            SENDCLOUD_DISPATCHED: {
                value: "dispatched",
                label: "En route",
                class: "text-success",
            },
            SENDCLOUD_COLLECTED: {
                value: "collected",
                label: "Livré",
                class: "text-success",
            },
        };
    },
    methods: {
        getSendcloudStatusLabel(value) {
            const found = this.ALL_SENDCLOUD_STATUS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
        getSendcloudStatusClass(value) {
            const found = this.ALL_SENDCLOUD_STATUS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.class;
            return "";
        },
    },
    computed: {
        ALL_SENDCLOUD_STATUS() {
            return [
                this.SENDCLOUD_ERROR_STATUS,
                this.SENDCLOUD_NO_LABEL,
                this.SENDCLOUD_CANCELED,
                this.SENDCLOUD_READY,
                this.SENDCLOUD_DELAYED,
                this.SENDCLOUD_DISPATCHED,
                this.SENDCLOUD_COLLECTED,
            ];
        },
        VALID_SENDCLOUD_STATUS_FOR_LABEL() {
            return [this.SENDCLOUD_READY.value];
        },
    },
};
