var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Conditions Générales d'Utilisation"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm.loading
          ? _c("LoaderComponent")
          : [
              _c("p", [
                _vm._v(
                  "\n                Pour accéder à " +
                    _vm._s(_vm.isNoShow ? "NoShow" : "Y-Services") +
                    ", vous devez accepter les Conditions Générales d'Utilisation du service et\n                la Politique de Confidentialité des Données Personnelles.\n            "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row m-0 mt-2" }, [
                _c("label", { staticClass: "container-box w-100" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.cguAccepted,
                        expression: "cguAccepted",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.cguAccepted)
                        ? _vm._i(_vm.cguAccepted, null) > -1
                        : _vm.cguAccepted,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.cguAccepted,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.cguAccepted = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.cguAccepted = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.cguAccepted = $$c
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" }),
                  _vm._v(
                    "\n                    Je déclare avoir pris connaissance des\n                    "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "alert-link",
                      attrs: {
                        href: `/cgu_global.pdf?v=${_vm.$__BUILD_ID__}`,
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n                        Conditions Générales d'Utilisation du service\n                    "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row m-0 mt-2" }, [
                _c("label", { staticClass: "container-box w-100" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.datapolicyAccepted,
                        expression: "datapolicyAccepted",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.datapolicyAccepted)
                        ? _vm._i(_vm.datapolicyAccepted, null) > -1
                        : _vm.datapolicyAccepted,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.datapolicyAccepted,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.datapolicyAccepted = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.datapolicyAccepted = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.datapolicyAccepted = $$c
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" }),
                  _vm._v(
                    "\n                    Je déclare avoir pris connaissance de la\n                    "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "alert-link",
                      attrs: {
                        href: `/datapolicy.pdf?v=${_vm.$__BUILD_ID__}`,
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n                        Politique de Confidentialité des Données Personnelles\n                    "
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-success btn-sm btn-circle ml-2",
            attrs: {
              disabled:
                !_vm.cguAccepted || !_vm.datapolicyAccepted || _vm.loading,
            },
            on: { click: _vm.acceptCgv },
          },
          [_vm._v("\n            Valider\n        ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }