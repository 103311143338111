<template>
    <div ref="main" class="position-relative">
        <div ref="content" class="">
            <slot name="content" />
        </div>
        <div
            ref="overlay"
            class="position-absolute overlay-bg"
            :class="{ 'd-none': !displayOverlay }"
            style="top: 0; left: 0; z-index: 500"
            :style="{ height: `${clientHeight}px`, width: `${clientWidth}px` }">
            <slot name="overlay" />
        </div>
    </div>
</template>

<script>
import _ResizeObserver from "resize-observer-polyfill";

export default {
    data() {
        return {
            isMounted: false,
            clientHeight: 0,
            clientWidth: 0,
            sizeObserver: null,
        };
    },
    props: {
        displayOverlay: {
            required: true,
            type: Boolean,
        },
    },
    created() {},
    destroyed() {},
    mounted() {
        this.$set(this, "clientWidth", this.$refs.main.clientWidth);
        this.$set(this, "clientHeight", this.$refs.main.clientHeight);
        this.sizeObserver = new _ResizeObserver((entries) => {
            entries.forEach((entry) => {
                this.$set(this, "clientWidth", entry.contentRect.width);
                this.$set(this, "clientHeight", entry.contentRect.height);
            });
        });

        this.sizeObserver.observe(this.$refs.main);
    },
};
</script>
