<template>
    <div class="mb-5">
        <LoaderComponent v-if="loading" />
        <template v-else>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-0 border-bottom cc-board">
                <h2 class="h5">
                    <button
                        class="btn btn-sm btn-outline-secondary float-left radius-btn-square"
                        style="width: 29px; padding-left: 5px"
                        v-on:click="offset -= 1">
                        <svg
                            class="feather feather-chevron-left sc-dnqmqq jxshSx"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            aria-hidden="true">
                            <polyline points="15 18 9 12 15 6" />
                        </svg>
                    </button>

                    <span class="ml-1 mr-1" v-if="!isDay">
                        <datepicker
                            class="date-resa-cal float-left mt-1"
                            tabindex="1"
                            :monday-first="true"
                            v-model="pickerDate"
                            :language="fr"
                            :format="weekDateFormatter" />
                    </span>
                    <span class="ml-1 mr-1" v-if="isDay">
                        <datepicker class="date-resa-cal float-left mt-1" tabindex="1" :monday-first="true" v-model="pickerDate" :language="fr" />
                    </span>
                    <button
                        class="btn btn-sm btn-outline-secondary float-left radius-btn-square"
                        style="width: 29px; padding-left: 6px"
                        v-on:click="offset += 1">
                        <svg
                            class="feather feather-chevron-right sc-dnqmqq jxshSx"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            aria-hidden="true">
                            <polyline points="9 18 15 12 9 6" />
                        </svg>
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-secondary btn-circle" v-on:click="offset = 0" v-if="!isDay">
                        Cette semaine
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-secondary btn-circle" v-on:click="offset = 0" v-else>Aujourd'hui</button>
                </h2>
                <div>
                    <button
                        :disabled="!has_right_to_export_commands"
                        @click="exportBoard()"
                        class="btn btn-sm btn-outline-secondary btn-circle mr-1"
                        style="margin-top: -8px">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-upload">
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                            <polyline points="17 8 12 3 7 8"></polyline>
                            <line x1="12" y1="3" x2="12" y2="15"></line>
                        </svg>
                        Export Excel
                    </button>
                    <div class="btn-group" style="margin-top: -8px">
                        <button
                            class="btn btn-outline-secondary btn-sm btn-circle"
                            :class="{ active: isDay == false }"
                            style="border-radius: 50px 0 0 50px !important"
                            v-on:click="
                                isDay = false;
                                offset = 0;
                            ">
                            Vue semaine
                        </button>
                        <button
                            class="btn btn-outline-secondary btn-sm btn-circle"
                            :class="{ active: isDay == true }"
                            style="border-radius: 0 50px 50px 0 !important"
                            v-on:click="
                                isDay = true;
                                offset = 0;
                            ">
                            Vue jour
                        </button>
                    </div>
                </div>
            </div>

            <ejs-tooltip
                ref="boardStockTooltip"
                opensOn="Custom"
                :content="tooltipContent"
                :afterOpen="
                    () => {
                        eventHub.$emit('set-main-focus');
                    }
                "
                target=".cc-board-stock-for-day">
                <table tabindex="-1" class="table table-sm table-striped border-bottom">
                    <thead tabindex="-1" class="border-bottom">
                        <tr tabindex="-1">
                            <th tabindex="-1"></th>
                            <template v-if="!isDay">
                                <th
                                    v-for="(dayLabel, indexDay) in days"
                                    :key="indexDay"
                                    tabindex="-1"
                                    class="text-center"
                                    :class="{ 'board-current-day': offset === 0 && indexDay === currentDay }">
                                    {{ dayLabel }} - {{ formatDate(dates[indexDay]) }}
                                </th>
                            </template>
                            <th style="width: 50%" tabindex="-1" :class="{ 'board-current-day': offset === 0 }" class="text-center" v-else>
                                {{ formatDate(range.from) }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="category in categories">
                            <tr v-bind:key="category.id" v-if="category.id in products" @click="toggleCategory(category.id)" class="toggle-cat">
                                <td>
                                    <strong class="text-success">{{ category.name }}</strong>
                                    <svg
                                        class="feather feather-chevron-down sc-dnqmqq jxshSx"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        aria-hidden="true"
                                        v-if="openCategories.includes(category.id)">
                                        <polyline points="6 9 12 15 18 9" />
                                    </svg>
                                    <svg
                                        class="feather feather-chevron-right sc-dnqmqq jxshSx"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        aria-hidden="true"
                                        v-else>
                                        <polyline points="9 18 15 12 9 6" />
                                    </svg>
                                </td>
                                <template v-for="(dayLabel, indexDay) in days">
                                    <td
                                        v-if="indexDay === 0 || !isDay"
                                        :key="indexDay"
                                        :class="{ 'board-current-day': offset === 0 && indexDay === currentDay }"></td>
                                </template>
                            </tr>
                            <template v-for="product in products[category.id]">
                                <tr v-if="openCategories.includes(category.id) && !isDay" v-bind:key="category.id + '-' + product.info.id">
                                    <td class="pl-3">
                                        <span v-if="product.info.name.length < 30 || isDay">{{ product.info.name }}</span>
                                        <span
                                            v-tooltip="{ delay: { show: 400, hide: 0 }, content: product.info.name }"
                                            v-else-if="product.info.name.length >= 30"
                                            >{{ product.info.name.substring(0, 30) + "..." }}</span
                                        >
                                        <span v-if="product.info.stock_type === GENERAL_STOCK.value && product.info.stock !== null"
                                            >(Stock : {{ product.info.stock }})</span
                                        >
                                    </td>
                                    <td
                                        v-for="(dayLabel, indexDay) in days"
                                        :key="indexDay"
                                        class="text-center"
                                        :class="{ 'board-current-day': offset === 0 && indexDay === currentDay }">
                                        <span
                                            @click="openTooltip($event, product[indexDay] ? product[indexDay].id : null, dates[indexDay])"
                                            class="d-block w-100 cc-board-stock-for-day"
                                            style="cursor: pointer"
                                            :class="{ 'board-current-day': offset === 0 }">
                                            <span v-html="getProductStockHtml(product[indexDay], dates[indexDay])"></span>
                                        </span>
                                    </td>
                                </tr>
                                <tr v-if="openCategories.includes(category.id) && isDay" v-bind:key="category.id + '-' + product.id">
                                    <td class="pl-3">
                                        <span v-if="product.name.length < 30 || isDay">{{ product.name }}</span>
                                        <span v-tooltip="{ content: product.name }" v-else-if="product.name.length >= 30">{{
                                            product.name.substring(0, 30) + "..."
                                        }}</span>
                                        <span v-if="product.stock_type === GENERAL_STOCK.value && product.stock !== null"
                                            >(Stock : {{ product.stock }})</span
                                        >
                                    </td>
                                    <td style="width: 50%" class="text-center">
                                        <span
                                            @click="openTooltip($event, product ? product.id : null, range.from)"
                                            class="d-block w-100 cc-board-stock-for-day"
                                            style="cursor: pointer"
                                            :class="{ 'board-current-day': offset === 0 }">
                                            <span v-html="getProductStockHtml(product, range.from)"></span>
                                        </span>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </ejs-tooltip>
        </template>
    </div>
</template>

<script>
import Vue from "vue";
import LoaderComponent from "../../LoaderComponent";
import moment from "moment";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import { TooltipPlugin } from "@syncfusion/ej2-vue-popups";
import EditStock from "./EditStock";
import StockTypeEnum from "../../../mixins/enums/click_and_collect/StockTypeEnum";

Vue.use(TooltipPlugin);

export default {
    data() {
        return {
            loading: false,
            days: ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
            fr: fr,
            eventHub: new Vue(),
            offset: 0,
            range: null,
            products: false,
            dates: null,
            selectedMenuLoading: false,
            selectedMenu: null,
            selectedMenuOptions: null,
            categories: [
                { id: -1, name: "Produits de formules", order: 0 },
                { id: -2, name: "Formules", order: 0 },
            ],
            openCategories: [],
            isDay: true,
            pickerDate: moment().toDate(),
            tooltip: null,
            products_unavailabilities: [],
        };
    },
    mixins: [StockTypeEnum],
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_export_commands() {
            return this.rights.includes("click_and_collect.commands.export");
        },
        tooltipContent() {
            return () => {
                return {
                    template: {
                        extends: EditStock,
                        propsData: {
                            eventHub: this.eventHub,
                            restaurant_id: this.restaurant.id,
                            ...this.tooltip,
                        },
                    },
                };
            };
        },
        currentDay() {
            return moment().isoWeekday() - 1;
        },
    },
    props: {
        restaurant: {
            required: true,
        },
    },
    watch: {
        offset() {
            this.fetchProducts();
        },
        isDay() {
            this.fetchProducts();
        },
        selectedMenu() {
            this.fetchOptions();
        },
        range(newVal) {
            this.pickerDate = newVal.from;
        },
        pickerDate(newVal) {
            if (this.isDay) {
                this.offset = moment(newVal).diff(moment().startOf("day"), "day");
            } else {
                this.offset = moment(newVal).startOf("week").diff(moment().startOf("week"), "week");
            }
        },
    },
    methods: {
        exportBoard() {
            if (!this.has_right_to_export_commands) return;
            let api_url = `/api/click_and_collect/${this.$route.params.restaurant_id}/export/board?offset=${this.offset}&is_day=${
                this.isDay ? 1 : 0
            }`;
            window.open(api_url, "_blank");
        },
        weekDateFormatter(date) {
            return moment(date).startOf("week").format("DD/MM") + " - " + moment(date).endOf("week").format("DD/MM");
        },
        toggleCategory(id) {
            const index = this.openCategories.indexOf(id);
            if (index > -1) {
                this.openCategories.splice(index, 1);
            } else {
                this.openCategories.push(id);
            }
        },
        fetchCategories() {
            axios
                .get(`/api/click_and_collect/${this.restaurant.id}/products/categories`)
                .then((response) => {
                    this.categories = this.categories.concat(response.data.data);
                })
                .catch((error) => {
                    this.error = error.response.data.message ? error.response.data.message : "Une erreur est survenue";
                });
        },
        fetchOptions() {
            this.selectedMenuLoading = true;

            axios
                .get(`/api/click_and_collect/${this.restaurant.id}/board/options/${this.selectedMenu}?offset=${this.offset}`)
                .then((response) => {
                    this.selectedMenuOptions = response.data;
                    this.selectedMenuLoading = false;
                })
                .catch((error) => {
                    this.error = error.response.data.message || error.message;
                    if (this.error === "Unauthenticated.") {
                        document.location.reload(true);
                    }
                    reject(error);
                });
        },
        fetchProducts() {
            this.loading = true;

            const promiseProducts = axios
                .get(`/api/click_and_collect/${this.restaurant.id}/board?offset=${this.offset}&isday=${this.isDay}`)
                .then((response) => {
                    this.products = response.data.products;
                    this.range = response.data.range;
                    this.dates = response.data.dates;
                    this.openCategories = [];
                    Object.keys(response.data.products).forEach((id) => {
                        this.toggleCategory(parseInt(id));
                    });
                    this.loading = false;
                })
                .catch((error) => {
                    if (error.response && error.response.data.message) this.error = error.response.data.message;
                    else if (error.message) this.error = error.message;
                    else this.error = "Une erreur est survenue";
                    this.loading = false;
                });
        },
        fetchProductsUnavailabilities() {
            axios
                .get(`/api/click_and_collect/${this.restaurant.id}/products/unavailabilities`)
                .then((response) => {
                    this.products_unavailabilities = response.data.data;
                })
                .catch(() => {});
        },
        formatDate(date) {
            return moment(date).format("DD/MM");
        },
        manageError(error) {
            let errorMessage = null;

            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            } else if (error.message) {
                errorMessage = error.message;
            } else {
                errorMessage = "Une erreur est survenue.";
            }

            this.$notify({
                group: "notification",
                type: "error",
                title: errorMessage,
            });
        },
        redirectToFirstRestaurant() {
            if (this.user != "" && typeof this.restaurants !== "undefined" && this.restaurants !== null) {
                if (this.user.role === "kitchen" && this.restaurants.length > 0)
                    this.$router.push(`/restaurants/${this.restaurants[0].id}/reservations-cuisine`);
                else if (this.user.role === "kitchen") this.$router.push("/mon-compte");
            }
        },
        openTooltip(args, product_id, date) {
            if (product_id && date && args.target) {
                this.tooltip = {
                    product_id,
                    date,
                };
                this.$refs.boardStockTooltip.open(args.target);
            }
        },
        closeTooltip() {
            this.tooltip = null;
            this.$refs.boardStockTooltip.close();
        },
        productUnavailabilitySaved(product_data) {
            this.closeTooltip();
            if (product_data.id) this.fetchProductsUnavailabilities();
            else {
                Object.values(this.products).forEach((products) => {
                    if (this.isDay) {
                        products.forEach((product) => {
                            if (product.group_id === product_data.group_id) product.stock = 0;
                        });
                    } else {
                        Object.values(products).forEach((days) => {
                            Object.values(days).forEach((product) => {
                                if (product.group_id === product_data.group_id) product.stock = 0;
                            });
                        });
                    }
                });
            }
        },
        getProductUnavailabity(product, date) {
            const pu = this.products_unavailabilities.find((p) => p.group_id == product.group_id && p.date === date);
            if (pu && (pu.out_of_stock || pu.new_stock)) return pu;
            return false;
        },
        getProductStockHtml(product, date) {
            let html = "";
            if (!product) html += "--";
            else if (product.amount) html += product.amount;
            else html += 0;
            if (product) {
                const pu = this.getProductUnavailabity(product, date);
                if (product.stock_type === this.DAILY_STOCK.value) {
                    if (pu && pu.new_stock) html += `/${pu.new_stock}`;
                    else if (product.stock != null) html += `/${product.stock}`;
                }
                if ((pu && pu.out_of_stock) || (product.stock_type === this.GENERAL_STOCK.value && product.stock === 0))
                    html += `<svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1 feather text-danger ml-1"><circle cx="12" cy="12" r="10"></circle><line x1="8" y1="12" x2="16" y2="12"></line></svg>`;
            }
            return html;
        },
    },
    components: {
        LoaderComponent,
        Datepicker,
    },
    created() {
        this.fetchCategories();
        this.fetchProducts();
        this.fetchProductsUnavailabilities();
        this.eventHub.$on("blur", this.closeTooltip);
        this.eventHub.$on("product-unavailability-saved", this.productUnavailabilitySaved);
    },
};
</script>
