export default {
    data() {
        return {
            GR_IMPORTANT_NOTE_TEXT: {
                value: "important_note",
            },
        };
    },
    methods: {
        getGrLocalizedTextLabel(value) {
            const found = this.ALL_GR_LOCALIZED_TEXTS.find((type) => type.value === value);

            return typeof found !== "undefined" ? this.$t(`labels.googleReserve.texts.${value}`) : value;
        },
    },
    computed: {
        ALL_GR_LOCALIZED_TEXTS() {
            return [this.GR_IMPORTANT_NOTE_TEXT];
        },
    },
};
