var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : !_vm.website_enabled
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("Votre site web n'est pas activé"),
          ])
        : [
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                  },
                  [
                    _c("h5", { staticClass: "title mt-2" }, [
                      _vm._v("Configuration du référencement"),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 mb-3" }, [
                      _c("strong", [_vm._v("Référencement")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-5 mb-2" }, [
                      _c(
                        "label",
                        {
                          staticClass: "mb-0",
                          attrs: { for: "txt-website-title" },
                        },
                        [
                          _vm._v("Title "),
                          _c("small", { staticClass: "text-danger" }, [
                            _vm._v("*"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "small",
                        {
                          staticClass: "form-text text-muted",
                          attrs: { id: "help-website-title" },
                        },
                        [
                          _vm._v(
                            'Le "title" correspond au titre de votre site web tel qu\'il sera affiché sur les moteurs de recherche.'
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-7 mb-3" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.website_settings.title,
                              expression: "website_settings.title",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            disabled: !_vm.has_right_to_update_config,
                            type: "text",
                            maxlength: "60",
                            id: "txt-website-title",
                            "aria-describedby": "help-website-title",
                          },
                          domProps: { value: _vm.website_settings.title },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.website_settings,
                                "title",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          staticClass: "d-block",
                          attrs: { errors: _vm.errors, errorKey: "title" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-5" }, [
                      _c(
                        "label",
                        {
                          staticClass: "mb-0",
                          attrs: { for: "txt-website-meta-description" },
                        },
                        [
                          _vm._v("Meta Description "),
                          _c("small", { staticClass: "text-danger" }, [
                            _vm._v("*"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "small",
                        {
                          staticClass: "form-text text-muted",
                          attrs: { id: "help-website-meta-description" },
                        },
                        [
                          _vm._v(
                            'La "meta description" est la phrase décrivant votre activité qui sera affichée juste en dessous du "title" sur\n                                les moteurs de recherche.'
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-7" },
                      [
                        _c("TextAreaComponentOld", {
                          attrs: {
                            disabled: !_vm.has_right_to_update_config,
                            minNbChar: 120,
                            maxNbChar: 160,
                            txtAreaId: "txt-contact-content",
                            txtAreaObj: _vm.website_settings,
                            txtAreaObjKey: "meta_description",
                            txtAreaRows: "2",
                          },
                        }),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors,
                            errorKey: "meta_description",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0 mt-3" }, [
              _c("div", { staticClass: "col-12 d-inline-block" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success btn-circle mr-1",
                    attrs: {
                      type: "button",
                      disabled: !_vm.has_right_to_update_config,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.postForm()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Enregistrer\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    'Pour rendre vos modifications visibles sur votre site internet, pensez à "'
                  ),
                  _c("strong", [_vm._v("Enregistrer et publier")]),
                  _vm._v(
                    '" en\n                    cliquant sur le bouton dans la navigation.'
                  ),
                ]),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }