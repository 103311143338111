export default {
    booking: {
        dashboard:
            "Manage my availability: you can switch a service to full, block specific slots or block PAX by clicking directly on the box for the service concerned.",
        externalMenus:
            "This feature allows the client to check the menu while booking. It can be a PDF file or a dedicated web page (fill in the URL of this page).",
        servicesConfig: {
            serviceName:
                "This name will only be displayed for you and will not be visible to your customers.<br/> It should allow you to differentiate between two services, e.g. Weekday lunch - Weekend lunch if you have created two separate services.",
            activeDays:
                "If you have booking conditions that vary between several services, consider creating different services.<br/> For example, if the booking slots are not identical on weekdays and weekends, you will need to create two services: one that will be active for weekdays, another for weekend days.",
            paxMaxPerService:
                "This value will be taken into account for the reservation confirmation calculation.<br/> If you enable automatic seat filling according to your seating plan, this value will still be taken into account to limit the number of covers in the service.<br/> If you enable rotation, the capacity is considered to be the real-time capacity (e.g. 20 covers at a time in the restaurant).",
            limitReservation: "If you wish to take reservations until the time your service starts, the value to be entered is 0.",
            tableRotation:
                "If you offer more than one service, this feature is ideal for providing an overview of your reservations and for using the timeline view (you will be able to see the reallocated tables).",
            automaticSeatingPlan:
                "This feature allows you to use our smart seating plan and automate the generation of your seating plan.<br/> Filling seats is based on your configuration (PAX allowed for each table / groups of tables / reservation duration with the table rotation).",
            uniqueMenu:
                "Your customers will be required to choose one single menu for the whole table.<br/> You can make the choice to impose a single from a certain number of people, for example six people.<br/> In this case, for all tables of fewer than six people, customers will be able to choose different menus.<br/> Beyond six, the menu will be a single menu.",
            bankFootprint:
                "If the credit/debit card pre-authorisation is enabled, your customer will automatically have to go through this step to validate the reservation.<br/> It is important to note that a credit/debit card pre-authorisation is only valid for seven days. <br/>If the reservation is made for less than six days, the credit/debit card pre-authorisation will be requested immediately at the last step of the reservation widget which will be integrated into your website. <br/>If the reservation is made for a date later than the next six days, the customer will receive an email specifying that a credit/debit card pre-authorisation will be requested six days before the reservation. <br/>If you wish to request a credit/debit card pre-authorisation only for certain services, consider creating several distinct services.<br/> You can also disable credit/debit card pre-authorisation for regular and VIP customers by going to the Customers > Options tab.<br/> To enable credit/debit card pre-authorisation, you must have connected a Stripe account beforehand.",
            prepayment:
                "You can implement this solution to request full payment at the time of reservation or to manage the taking of deposits to secure your reservations.<br/> This functionality is necessarily linked to the choice of menus.<br/> The calculation of the amount to be paid by the customer is based on the menus and options selected.<br/> This function can be used, for example, to request deposits for groups of more than X people.",
            exceptionalOpening:
                "If you are usually open on these dates, the special opening will override your usual services.<br/> If you are not usually open on the day concerned, the availability will simply be displayed on your reservation module.",
            exceptionalClosureConcernedServices:
                "If you wish to close only one of the services (and not all of them), you can select only the service(s) concerned.<br/> This can be used, for example, in the context of a private booking or a temporary change of the services offered.",
        },
        widget: {
            slotMessage:
                'This message is displayed in step two of the reservation module.<br/> It is an indication that you can give to your customers.<br/> For example: "Please keep to your arrival time. If you are more than 15 minutes late, your table may be reallocated.',
            waitingList:
                "When your restaurant is fully booked, the customer has the option of joining a waiting list to be notified if a table becomes available.",
            waitingListAutoNotif:
                "If you enable automatic notifications, as soon as a cancellation is saved, all people on the waiting list and corresponding to the size of the available table will be notified.<br/> Priority will be given to the person who made the reservation first.",
        },
        notifications: {
            notificationsConfig:
                "For each type of notification, you can set the desired sending channel.<br/> If you wish to send notifications by SMS, you must first have enabled SMS on your account.<br/> When your SMS quota is zero, email notifications take over until your account is topped up. <br/> To limit no-shows as much as possible and optimise your seat filling, we advise you to send two notifications: <br/>- the reservation confirmation request 24 hours before the time of the reservation <br/>- the notification that a table is available for customers on the waiting list.",
            qrCode: 'If you select this option, a QR code will be automatically added to the confirmation email sent to the customer.<br/> You will then be able to "Flash" it from the "In Service" menu to confirm the customer\'s arrival and simplify/improve your customer reception process.',
        },
        tablePlan: {
            disablingTable:
                "If you disable a table, it will no longer appear on the table plan view.<br/> It will also no longer be considered in table groups.<br/> It is, therefore, essential to make all the necessary updates before saving.<br/> The disabling of the table is saved for all services without any time limit.<br/> If you wish to block a table for a specific day, we suggest you block this table directly from the table plan view from the Reservations tab.",
            disablingRoom:
                'The disabling of the room is saved for all services with no time limitation.<br/> If you wish to manage the opening of a room according to dates, you can do so in "Room configuration", "Start date" and "End date".',
        },
        menus: {
            externalDescription:
                "You can add an additional description to a menu in the form of a PDF file or an external link to a dedicated web page. This description is directly available to the client on the booking widget (menu details, picture of different dishes...)",
        },
    },
    clickAndCollect: {
        config: {
            orderPreparationDelay:
                "This is the minimum time between when the customer places the order and comes to collect it on-site.<br/> For example, if you indicate 30 mins preparation time, the customer will be able to collect their order from midday for an order placed at 11:30. Be aware that a feature allows you to suspend new orders during the service.<br/> If you want your customers to order the day before for the next day, it is advisable to set a time limit of 15 to 24 hours.",
            commandConfirm:
                "This feature allows you to take control of accepting orders.<br/> You can use manual confirmation, in which case you will have to confirm all the orders yourself.<br/> If you use the automatic function, you can specify parameters to take control only in certain cases.<br/> If, for example, confirmation of orders is automatic up to six orders per service, over six orders for the same service, you will have to validate further orders manually.<br/> To be in 100% automatic mode, you just have to set the max value for each of the parameters to 0.",
        },
    },
};
