<template>
    <loader-component v-if="nbLoading > 0" />
    <div v-else class="home-vue p-2">
        <div class="row none-mobile m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">{{ $t("labels.routes.booking.globalStats") }}</h5>
                    <button
                        v-if="hasRightToExportStats && !isAdmin"
                        type="button"
                        class="btn btn-sm btn-circle btn-outline-secondary"
                        style="border: 1px solid #30a4b7"
                        :disabled="filters === null"
                        @click="excelExport">
                        <feather type="download-cloud" class="mr-1" />
                        {{ $t("labels.form.actions.excelExport") }}
                    </button>
                </div>
            </div>
        </div>
        <div v-if="!hasRights" class="alert alert-danger">
            {{ $t("errors.common.rights.notEnoughForGlobalReport") }}
        </div>
        <template v-else>
            <div class="pt-0 pr-3 pb-3 pl-3">
                <div class="row mb-3">
                    <div class="col-12">
                        <booking-stats-filters
                            ref="filters"
                            @filters-updated="onFiltersUpdated"
                            :is-global-report="true"
                            @update-rwg="checkRwg"></booking-stats-filters>
                    </div>
                </div>

                <origin-booking-component v-if="filters !== null" :filters="filters" :has-rwg="hasRwg"></origin-booking-component>
                <details-per-restaurants
                    v-if="filters !== null"
                    :filters="filters"
                    :has-rwg="hasRwg"
                    :statsType="StatsType.RESERVATIONS_COUNT"></details-per-restaurants>
                <nb-pax-by-origin-component v-if="filters !== null" :filters="filters" :has-rwg="hasRwg"></nb-pax-by-origin-component>
                <details-per-restaurants
                    v-if="filters !== null"
                    :filters="filters"
                    :has-rwg="hasRwg"
                    :statsType="StatsType.RESERVATIONS_PAX"></details-per-restaurants>
                <waiting-list-component v-if="filters !== null" :filters="filters"></waiting-list-component>
                <details-per-restaurants-waiting-list v-if="filters !== null" :filters="filters"></details-per-restaurants-waiting-list>
            </div>
        </template>
    </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent.vue";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum.js";
import BookingStatsFilters from "../../components/charts/BookingStatsFilters.vue";
import OriginBookingComponent from "../../components/charts/OriginBookingComponent.vue";
import NbPaxByOriginComponent from "../../components/charts/NbPaxByOriginComponent.vue";
import WaitingListComponent from "../../components/charts/WaitingListComponent.vue";
import DetailsPerRestaurants from "../../components/charts/DetailsPerRestaurants.vue";
import { StatsType } from "../../mixins/enums/booking/DetailsPerRestaurantEnum.js";
import DetailsPerRestaurantsWaitingList from "../../components/charts/DetailsPerRestaurantsWaitingList.vue";
export default {
    data() {
        return {
            nbLoading: 0,
            filters: null,
            hasRwg: false,
        };
    },
    mixins: [ModuleTypesEnum],
    computed: {
        StatsType() {
            return StatsType;
        },
        userRights() {
            return this.$store.getters["users/formattedRights"];
        },
        hasRights() {
            if (!this.userRights.includes("booking.statistic.read")) {
                return false;
            }

            return !this.$store.getters["restaurants/restaurants"].some(
                (restaurant) => !this.$store.getters["users/restaurantIds"].includes(restaurant.id)
            );
        },
        isAdmin() {
            return this.$store.getters["users/role"] === "admin";
        },
        hasRightToExportStats() {
            return this.userRights.includes("booking.statistic.export");
        },
    },
    methods: {
        onFiltersUpdated(filters) {
            this.filters = filters;
        },
        fetchData() {
            ++this.nbLoading;

            setTimeout(() => --this.nbLoading, 1000);
        },
        checkRwg(event) {
            this.hasRwg = event;
        },
        excelExport() {
            if (!this.$refs.filters.emitFiltersUpdate()) {
                return;
            }

            this.$nextTick(() => {
                let comparisonType = "none";

                if (this.filters.hasComparison) {
                    comparisonType = "dates";
                } else if (this.filters.hasGroupComparison) {
                    comparisonType = "establishments";
                }

                let params = `comparison_type=${comparisonType}`;

                params += this.filters.main.selectedRestaurants.map((restaurant) => `&main[restaurants][]=${restaurant.id}`).join("");

                if (this.filters.main.selectedZone !== "all") {
                    params += `&main[zone]=${this.filters.main.selectedZone}`;
                }

                params += `&main[from_date]=${this.filters.main.fromDate.toISODate()}`;
                params += `&main[to_date]=${this.filters.main.toDate.toISODate()}`;

                switch (comparisonType) {
                    case "dates":
                        params += `&comparison[from_date]=${this.filters.comparison.fromDate.toISODate()}`;
                        params += `&comparison[to_date]=${this.filters.comparison.toDate.toISODate()}`;
                        break;
                    case "establishments":
                        params += this.filters.comparison.selectedRestaurants
                            .map((restaurant) => `&comparison[restaurants][]=${restaurant.id}`)
                            .join("");

                        if (this.filters.comparison.selectedZone !== "all") {
                            params += `&comparison[zone]=${this.filters.comparison.selectedZone}`;
                        }
                        break;
                }

                window.open(this.addLangToURL(`/api/stats/excel/global?${params}`), "_blank");
            });
        },
    },
    created() {
        this.fetchData();
    },
    components: {
        LoaderComponent,
        BookingStatsFilters,
        OriginBookingComponent,
        DetailsPerRestaurants,
        NbPaxByOriginComponent,
        WaitingListComponent,
        DetailsPerRestaurantsWaitingList,
    },
};
</script>
