var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Scanner une commande"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm.error
          ? _c("div", { staticClass: "alert alert-danger" }, [
              _c("p", [
                _vm._v(
                  "\n                " + _vm._s(_vm.error) + "\n            "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.showValidateCommandModal === false
          ? _c("qrcode-stream", {
              on: { decode: _vm.commandFound, init: _vm.onInit },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.showValidateCommandModal === true
          ? _c("validate-command-modal", {
              attrs: {
                command_id: _vm.command_id,
                restaurant_id: _vm.restaurant_id,
              },
              on: {
                close: function ($event) {
                  _vm.showValidateCommandModal = false
                },
                "validate-command": function ($event) {
                  _vm.$emit("validate-command", $event)
                  _vm.showValidateCommandModal = false
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.showInvalidCommandModal === true
          ? _c("invalid-command-modal", {
              attrs: { command: _vm.command },
              on: {
                close: function ($event) {
                  _vm.showInvalidCommandModal = false
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex w-100 justify-content-between",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn-sm btn btn-secondary btn-circle",
            on: { click: _vm.close },
          },
          [_vm._v("Fermer")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }