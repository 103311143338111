<template>
    <div class="row">
        <div class="col-12">
            <div class="border-light b-radius-20 p-4">
                <div class="row">
                    <div class="col-12 pointer" @click="showIntegration = !showIntegration">
                        <strong>{{ $tl("infos.widget.instagramIntegration.title") }}</strong
                        ><showNewBadgeDetails v-if="badgeUUID !== null" :badge_uuid="badgeUUID" />
                    </div>
                </div>
                <div v-if="showIntegration" class="row mt-3">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-5">
                                <p>{{ $tl("infos.widget.subtitle") }}</p>
                            </div>
                            <div class="col-7">
                                <strong>{{ $t("labels.stepX", { step: 1 }) }}{{ $tl("labels.colon") }}</strong>
                                {{ $t("infos.widget.instagramIntegration.step1") }}
                                <p class="mt-1 mb-3">{{ $t("infos.widget.facebookIntegration.content1") }}</p>
                                <strong>{{ $t("labels.stepX", { step: 2 }) }}{{ $tl("labels.colon") }}</strong>
                                {{ $t("infos.widget.instagramIntegration.step2") }}
                                <p class="mt-1">{{ $t("infos.widget.instagramIntegration.content2") }}</p>
                                <img class="w-25 mb-3" src="/images/instagram_step1.png" alt="Clique sur le logo mon compte" />
                                <p>{{ $t("infos.widget.instagramIntegration.content3") }}</p>
                                <img class="w-25 mb-3" src="/images/instagram_step2.png" alt="Clique sur le bouton modifier profil" />
                                <p>{{ $t("infos.widget.instagramIntegration.content4") }}</p>
                                <img class="w-25 mb-3" src="/images/instagram_step3.png" alt="Coller le lien dans la case site web" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum.js";
import showNewBadgeDetails from "../../components/NewBadges/showNewBadgeDetails.vue";

export default {
    data() {
        return {
            showIntegration: false,
        };
    },
    props: {
        module: {
            type: String,
            default: null,
        },
    },
    mixins: [ModuleTypesEnum],
    computed: {
        badgeUUID() {
            if (this.module === this.MODULE_TYPE_BOOKING.value) {
                return "9099846d-e9ac-4f13-92c0-edefc09e3a98";
            }

            if (this.module === this.MODULE_TYPE_CLICK_AND_COLLECT.value) {
                return "09d16718-4305-44cd-ad23-185318555dca";
            }

            if (this.module === this.MODULE_TYPE_GIFT_VOUCHERS.value) {
                return "74eecc6f-db62-4378-b5ae-1caa499b8416";
            }

            return null;
        },
    },
    components: {
        showNewBadgeDetails,
    },
};
</script>
