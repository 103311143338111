<template>
    <div class="row mb-3">
        <div class="col-12">
            <div class="border-light b-radius-20 p-4">
                <div class="row">
                    <div class="col-md-5">
                        <label>Catégorie</label>
                    </div>
                    <div class="col-md-7">
                        <div class="d-inline-block align-middle lead-switch switch-choice">
                            <select class="custom-select" v-model="currentCategoryComponent">
                                <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
                                <option :value="-1">Ajouter catégorie</option>
                            </select>
                            <input
                                v-if="currentCategoryComponent === -1"
                                type="text"
                                @input="$emit('input', $event.target.value)"
                                class="form-control" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            customCategoryName: "",
        };
    },
    props: {
        categories: {
            type: Array,
            required: true,
        },
        currentCategory: {
            type: Number,
            default: null,
        },
    },
    computed: {
        currentCategoryComponent: {
            get() {
                return this.currentCategory;
            },
            set(newValue) {
                this.$emit("category-changed", newValue);
            },
        },
    },
};
</script>
