var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100 m-0 p-0 d-flex flex-column table-plan" },
    [
      _c(
        "ul",
        {
          staticClass: "nav nav-tabs nav-service",
          staticStyle: { display: "-webkit-box", "margin-top": "-10px" },
        },
        _vm._l(_vm.filteredServices, function (service, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "nav-item",
              on: {
                click: function ($event) {
                  _vm.selectedServiceIndex = service.originalIndex
                },
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  class: {
                    active: service.originalIndex === _vm.selectedServiceIndex,
                  },
                  attrs: { href: "#" },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(service.name) +
                      " - " +
                      _vm._s(_vm.getServiceCategoryLabel(service)) +
                      "\n                " +
                      _vm._s(
                        service.special === 1
                          ? ` (${_vm.$tl(
                              "labels.booking.services.special.title"
                            )}) `
                          : ""
                      ) +
                      "\n            "
                  ),
                ]
              ),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("loaderComponent", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
      }),
      _vm._v(" "),
      !_vm.isLoading && _vm.selectedService
        ? _c(
            "span",
            { staticClass: "capacite badge badge-secondary count-resa" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.selectedService.pax) +
                  " / " +
                  _vm._s(
                    _vm.selectedService.new_pax !== null
                      ? _vm.selectedService.new_pax
                      : _vm.selectedService.max_pax
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && _vm.selectedService && _vm.selectedService.seatingPlan
        ? _c(
            "div",
            { staticClass: "h-100 w-100" },
            [
              _c("ns-timeline", {
                key: _vm.selectedService.id,
                attrs: {
                  "data-source": _vm.timelineDataSource,
                  events: _vm.timelineEvents,
                  "start-hour": _vm.selectedService.hour_begin,
                  "end-hour": _vm.selectedService.hour_end,
                  "current-date": _vm.reservation_date,
                  "cell-width": _vm.cellWidth,
                },
                on: { "event-clicked": _vm.onEventClicked },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell-collapse-name",
                      fn: function ({ data }) {
                        return [
                          _vm._v(
                            "\n                " +
                              _vm._s(data.name) +
                              "\n            "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell-row-name",
                      fn: function ({ data }) {
                        return [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.seatingPlan.table.title"
                                )
                              ) +
                              " " +
                              _vm._s(data.name) +
                              " |\n                "
                          ),
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              "(" + _vm._s(data.enabledPax.join(", ")) + ")"
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell-body",
                      fn: function ({ hour, data, uniqId }) {
                        return [
                          _vm.canAddReservation(hour.toFormat("HH:mm"), data)
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn p-0",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      _vm.openDropdown(
                                        _vm.getCellCoordinates(uniqId, hour)
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("feather", {
                                    staticClass: "feather-blue",
                                    attrs: { type: "plus-circle" },
                                  }),
                                  _vm._v(" "),
                                  _vm.visibledDropdowns[
                                    _vm.getCellCoordinates(uniqId, hour)
                                  ]
                                    ? _c("Dropdown", {
                                        attrs: { items: _vm.dropdownItems },
                                        on: {
                                          selected: function ($event) {
                                            return _vm.onDropdownOptionSelected(
                                              $event,
                                              hour,
                                              data
                                            )
                                          },
                                          close: function ($event) {
                                            _vm.closeDropdown(
                                              _vm.getCellCoordinates(
                                                uniqId,
                                                hour
                                              )
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2550710491
                ),
              }),
            ],
            1
          )
        : _vm.allServicesClosedAndNoResa
        ? _c("no-opened-services", {
            attrs: { "is-from-closure": "", "closure-name": _vm.closureName },
          })
        : !_vm.isLoading &&
          _vm.selectedService &&
          !_vm.selectedService.seatingPlan
        ? _c("NoSeatingPlan", {
            staticClass: "mt-3",
            attrs: { service: _vm.selectedService },
            on: {
              close: function ($event) {
                return _vm.$emit("go-to-resa-list")
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showReservationModal
        ? _c("show-reservation-modal", {
            attrs: {
              reservation_id: _vm.selected_reservation_id,
              restaurant_id: _vm.selected_restaurant_id,
            },
            on: {
              "resa-edit": _vm.displayReservationEdit,
              close: function ($event) {
                _vm.showReservationModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showEditReservationModal
        ? _c("edit-reservation-modal", {
            attrs: {
              reservation_id: _vm.selected_reservation_id,
              restaurant_id: _vm.selected_restaurant_id,
            },
            on: {
              "reservation-edited": function ($event) {
                _vm.showEditReservationModal = false
                _vm.fetchData()
              },
              close: function ($event) {
                _vm.showEditReservationModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }