var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "dropdown",
      staticClass: "ns-dropdown",
      attrs: { tabindex: "0" },
      on: { blur: _vm.hideDropdown },
    },
    [
      _c(
        "ul",
        { staticClass: "ns-dropdown__container" },
        _vm._l(_vm.items, function (item, index) {
          return _c(
            "li",
            {
              key: `${index}-${item.label}`,
              staticClass: "ns-dropdown__item",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.selectedIndex({ item, index })
                },
              },
            },
            [_vm._v("\n            " + _vm._s(item.label) + "\n        ")]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }