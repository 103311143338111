var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "li",
        [
          _c(
            "button",
            {
              staticClass:
                "w-100 btn d-flex flex-row module hoverable my-3 mx-0 border-0 p-0 align-items-stretch",
              class: { disabled: !_vm.module.enabled, small: _vm.small },
              style: _vm.showButtons ? "cursor: default !important;" : "",
              on: { click: _vm.moduleClicked },
            },
            [
              _c(
                "div",
                {
                  staticClass: "icon-wrapper text-center",
                  class: { disabled: !_vm.module.enabled, small: _vm.small },
                },
                [
                  _c("feather", {
                    staticClass: "icon w-auto h-auto",
                    attrs: { type: _vm.module.icon, size: "28" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "w-100 border-top-light border-bottom-light border-right-light py-4 d-flex flex-row justify-content-between",
                },
                [
                  _c(
                    "h5",
                    { staticClass: "my-auto ml-3" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.getModuleTypeLabel(_vm.module.value)) +
                          "\n                "
                      ),
                      _vm.hasNewBadge
                        ? _c("newBadge", { staticClass: "ml-2" })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showButtons
                    ? _c(
                        "span",
                        { staticClass: "float-right my-auto mr-3" },
                        [
                          _vm.isYproxModule
                            ? _c(
                                "a",
                                {
                                  staticClass: "btn btn-primary btn-circle",
                                  class: { "btn-sm": _vm.isNoShow },
                                  attrs: {
                                    href: _vm.module.goToUrl,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$tl("labels.modules.actions.goTo")
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              )
                            : [
                                _vm.module.enabled
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "btn btn-primary btn-circle",
                                        class: { "btn-sm": _vm.isNoShow },
                                        attrs: { to: _vm.module.goToUrl },
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.modules.actions.goTo"
                                              )
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    )
                                  : [
                                      _vm.isNoShow
                                        ? _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "btn btn-primary btn-circle btn-sm mr-2",
                                              attrs: {
                                                to: _vm.module.activateUrl,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.modules.actions.enable"
                                                    )
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          )
                                        : [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-more-infos btn-circle mr-2",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    _vm.canDisplayModal = true
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.subscriptions.moreInfos"
                                                      )
                                                    ) +
                                                    "\n                            "
                                                ),
                                              ]
                                            ),
                                          ],
                                    ],
                              ],
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.canDisplayModal
            ? _c("ask-for-subscription", {
                attrs: { module: _vm.module.value },
                on: {
                  close: function ($event) {
                    _vm.canDisplayModal = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }