var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "d-flex justify-content-between" }, [
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("feather", {
            staticClass: "btn btn-sm stepper-btn",
            class: _vm.getClasses(0),
            attrs: { type: "calendar" },
            on: {
              click: function ($event) {
                return _vm.changeStep(0)
              },
            },
          }),
          _vm._v(" "),
          _vm.reservation_date !== false
            ? _c("small", { staticClass: "text-muted d-block" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.reservation_date) +
                    "\n            "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("feather", {
            staticClass: "btn btn-sm stepper-btn",
            class: _vm.getClasses(1),
            attrs: { type: "users" },
            on: {
              click: function ($event) {
                return _vm.changeStep(1)
              },
            },
          }),
          _vm._v(" "),
          _vm.pax !== false
            ? _c("small", { staticClass: "text-muted d-block" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$tc("labels.person", _vm.pax)) +
                    "\n            "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("feather", {
            staticClass: "btn btn-sm stepper-btn",
            class: _vm.getClasses(2),
            attrs: { type: "clock" },
            on: {
              click: function ($event) {
                return _vm.changeStep(2)
              },
            },
          }),
          _vm._v(" "),
          _vm.slotHour !== false
            ? _c("small", { staticClass: "text-muted d-block" }, [
                _vm._v(
                  "\n                " + _vm._s(_vm.slotHour) + "\n            "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("feather", {
            staticClass: "btn btn-sm stepper-btn",
            class: _vm.getClasses(3),
            attrs: { type: "tool" },
            on: {
              click: function ($event) {
                return _vm.changeStep(3)
              },
            },
          }),
          _vm._v(" "),
          _vm.step > 3
            ? _c("small", { staticClass: "text-muted d-block" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.$tl("labels.booking.menus.title", _vm.restaurant_id)
                    ) +
                    "\n            "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("feather", {
            staticClass: "btn btn-sm stepper-btn",
            class: _vm.getClasses(4),
            attrs: { type: "user" },
            on: {
              click: function ($event) {
                return _vm.changeStep(4)
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }