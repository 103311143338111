<template>
    <div class="pr-0 pb-5">
        <div class="row">
            <div class="col-12">
                <LoaderComponent v-if="loading" />
                <div v-else>
                    <div v-if="error" class="alert alert-danger">
                        {{ error }}
                    </div>
                    <div v-else>
                        <div class="row m-0">
                            <div class="col-12">
                                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                                    <h5 class="title mt-2">{{ $tl("labels.routes.booking.services.closures") }}</h5>
                                    <router-link
                                        :to="{
                                            name: 'booking.restaurants.settings.closures.add',
                                            params: { restaurant_id: restaurant_id },
                                        }"
                                        class="btn btn-sm btn-success btn-circle"
                                        :class="{ disabled: !has_right_to_create_service }">
                                        <feather type="plus"></feather>
                                        <span>{{ $tl("labels.booking.closures.add") }}</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div v-if="closures * 1 === 0">
                            <div class="row m-0">
                                <div class="col-12">
                                    <em>{{ $tl("labels.booking.closures.empty", restaurant_id) }}</em>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="row m-0">
                                <div class="col-12">
                                    <table v-if="closures" class="table table-sm table-striped border-bottom">
                                        <thead class="border-bottom">
                                            <tr>
                                                <th>{{ $tl("labels.form.name") }}</th>
                                                <th>{{ $tl("labels.booking.closures.services", restaurant_id) }}</th>
                                                <th>{{ $tl("labels.booking.closures.dates") }}</th>
                                                <th>{{ $tl("labels.form.actions.title") }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="closure in closures" :key="closure.id">
                                                <td>{{ closure.name }}</td>
                                                <td>
                                                    <div v-for="service in closure.services" :key="service.id">
                                                        {{ service.name }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <template v-if="areDatesSame(closure.datetime_begin, closure.datetime_end)">
                                                        {{ displayDate(closure.datetime_begin) }}
                                                    </template>
                                                    <template v-else>
                                                        {{ $tl("labels.filters.dates.from") }}
                                                        {{ displayDate(closure.datetime_begin) }}
                                                        {{ $tl("labels.filters.dates.to").toLowerCase() }}
                                                        {{ displayDate(closure.datetime_end) }}
                                                    </template>
                                                </td>
                                                <td>
                                                    <router-link
                                                        :to="{
                                                            name: 'booking.restaurants.settings.closures.edit',
                                                            params: {
                                                                restaurant_id: restaurant_id,
                                                                closure_id: closure.id,
                                                            },
                                                        }"
                                                        class="btn btn-sm btn-success btn-square"
                                                        :class="{ disabled: !has_right_to_update_service }"
                                                        v-tooltip="getTooltip($t('labels.form.actions.edit'))">
                                                        <feather type="edit" />
                                                    </router-link>
                                                    <button
                                                        v-on:click="deleteClosure(closure.id)"
                                                        :data-id="closure.id"
                                                        class="btn btn-sm btn-danger btn-square"
                                                        :disabled="!has_right_to_delete_service"
                                                        v-tooltip="getTooltip($t('labels.form.actions.delete'))">
                                                        <feather type="trash-2" />
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../../components/LoaderComponent.vue";

export default {
    data() {
        return {
            loading: false,
            restaurant_id: this.$route.params.restaurant_id,
            closures: [],
            paginate: false,
            links: {
                next: null,
                prev: null,
            },
            error: null,
        };
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_service() {
            return this.rights.includes("booking.service.create");
        },
        has_right_to_update_service() {
            return this.rights.includes("booking.service.update");
        },
        has_right_to_delete_service() {
            return this.rights.includes("booking.service.delete");
        },
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData(api_url) {
            this.error = this.closures = this.links.next = this.links.prev = null;
            this.loading = true;

            axios
                .get(api_url || `/api/restaurants/${this.restaurant_id}/closures`)
                .then((response) => {
                    this.closures = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        deleteClosure(delete_closure_id) {
            if (confirm(this.$tl("questions.booking.closures.delete"))) {
                this.loading = true;

                axios
                    .delete(`/api/restaurants/${this.restaurant_id}/closures/${delete_closure_id}`)
                    .then(() => {
                        this.loading = false;
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: this.$tl("success.booking.closures.deleted"),
                        });
                        this.fetchData();
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.$notify({
                            group: "notification",
                            type: "error",
                            title: this.getErrorMsgFromErrorResponse(error),
                        });
                    });
            }
        },
    },
    watch: {
        "$route.params.restaurant_id": function (id) {
            this.restaurant_id = this.$route.params.restaurant_id;
            this.fetchData();
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>
