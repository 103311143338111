<template>
    <div class="nav-btn-2 mx-md-5" v-if="restaurant_id && user">
        <div class="btn-group">
            <button
                title="Ajouter une commande"
                class="btn btn-sm btn-success btn-nav-mob btn-circle"
                :disabled="!has_right_to_create_commands"
                @click="showAddSaleModal">
                <svg
                    class="feather feather-plus sc-dnqmqq jxshSx"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    :class="{
                        'mr-0': isSidebarCollapsed,
                    }"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    aria-hidden="true">
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                Ajouter une commande
            </button>
        </div>
        <add-click-and-collect-sale-modal
            v-if="showAddClickAndCollectSaleModal"
            @close="showAddClickAndCollectSaleModal = false" />
    </div>
</template>

<script>
import addClickAndCollectSaleModal from "../../components/Modals/clickAndCollect/addClickAndCollectSaleModal.vue";

export default {
    name: "ClickAndCollectTopbarActions",
    data() {
        return {
            showAddClickAndCollectSaleModal: false,
        };
    },
    props: {
        user: {
            required: true,
        },
        isSidebarCollapsed: {
            default: false,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_commands() {
            return this.rights.includes("click_and_collect.commands.create");
        },
    },
    methods: {
        showAddSaleModal() {
            if (!this.has_right_to_create_commands) return;
            this.showAddClickAndCollectSaleModal = true;
        },
    },
    components: {
        addClickAndCollectSaleModal,
    },
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
