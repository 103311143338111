var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _vm.error
        ? _c("span", { staticClass: "text-danger" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : _c("div", { staticClass: "p-1" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$tl("labels.booking.reservations.add.client")) +
                    "\n            "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-7 mt-3" }, [
                _c(
                  "div",
                  { staticClass: "border-light b-radius-20 p-4" },
                  [
                    _c("clients-reservations", {
                      attrs: {
                        formErrors: _vm.formErrors,
                        restaurant_id: _vm.restaurant_id,
                        reservation: _vm.reservation,
                        readOnly: _vm.readOnly,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-5 mt-3" }, [
                _c(
                  "div",
                  { staticClass: "border-light b-radius-20 p-4" },
                  [
                    _c("commentary-reservations", {
                      attrs: {
                        restaurant_id: _vm.restaurant_id,
                        reservation: _vm.reservation,
                        restaurant: _vm.restaurant,
                        formErrors: _vm.formErrors,
                        readOnly: _vm.readOnly,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.canAddOptionBank
              ? _c("div", { staticClass: "row m-0 mt-3" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                      _c(
                        "div",
                        {
                          staticClass: "row radio",
                          staticStyle: { position: "relative" },
                        },
                        [
                          _c("div", { staticClass: "col-md-3 mb-2" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$tl("labels.booking.reservations.secure")
                                ) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-9" }, [
                            _c("label", { staticClass: "container-box" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.reservation.option_bank,
                                    expression: "reservation.option_bank",
                                  },
                                ],
                                attrs: { type: "radio" },
                                domProps: {
                                  value: null,
                                  checked: _vm._q(
                                    _vm.reservation.option_bank,
                                    null
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.reservation,
                                      "option_bank",
                                      null
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "checkmark" }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$tl("labels.none")) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "none-desk",
                              staticStyle: { height: "8px" },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value:
                                      _vm.isFootprintAvailable !== true
                                        ? { content: _vm.isFootprintAvailable }
                                        : undefined,
                                    expression:
                                      "isFootprintAvailable !== true ? { content: isFootprintAvailable } : undefined",
                                  },
                                ],
                                staticClass: "container-box",
                                class: {
                                  "cursor-disabled":
                                    _vm.isFootprintAvailable !== true,
                                },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reservation.option_bank,
                                      expression: "reservation.option_bank",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    disabled: _vm.isFootprintAvailable !== true,
                                  },
                                  domProps: {
                                    value: _vm.OPTION_BANK_FOOTPRINT.value,
                                    checked: _vm._q(
                                      _vm.reservation.option_bank,
                                      _vm.OPTION_BANK_FOOTPRINT.value
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.reservation,
                                        "option_bank",
                                        _vm.OPTION_BANK_FOOTPRINT.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "checkmark",
                                  class: {
                                    disabled: _vm.isFootprintAvailable !== true,
                                  },
                                }),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.reservations.footprint.title"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "none-desk",
                              staticStyle: { height: "8px" },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value:
                                      _vm.isPaymentAvailable !== true
                                        ? { content: _vm.isPaymentAvailable }
                                        : undefined,
                                    expression:
                                      "isPaymentAvailable !== true ? { content: isPaymentAvailable } : undefined",
                                  },
                                ],
                                staticClass: "container-box",
                                class: {
                                  "cursor-disabled":
                                    _vm.isPaymentAvailable !== true,
                                },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reservation.option_bank,
                                      expression: "reservation.option_bank",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    disabled: _vm.isPaymentAvailable !== true,
                                  },
                                  domProps: {
                                    value: _vm.OPTION_BANK_PAYMENT.value,
                                    checked: _vm._q(
                                      _vm.reservation.option_bank,
                                      _vm.OPTION_BANK_PAYMENT.value
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.reservation,
                                        "option_bank",
                                        _vm.OPTION_BANK_PAYMENT.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "checkmark",
                                  class: {
                                    disabled: _vm.isPaymentAvailable !== true,
                                  },
                                }),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.reservations.payment.title"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.reservation.option_bank ===
                      _vm.OPTION_BANK_PAYMENT.value
                        ? _c("div", { staticClass: "row mt-3" }, [
                            _c("div", { staticClass: "col-md-3" }, [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.reservations.payment.amountToPay"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-9" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.reservation.amount_to_pay,
                                    expression: "reservation.amount_to_pay",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  disabled: _vm.isPaymentAvailable !== true,
                                },
                                domProps: {
                                  value: _vm.reservation.amount_to_pay,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.reservation,
                                      "amount_to_pay",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "d-block text-danger mt-2" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$tl(
                                          "infos.booking.reservations.payment.willBeSendOnSave"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm.reservation.option_bank ===
                            _vm.OPTION_BANK_FOOTPRINT.value &&
                          _vm.footprint_date_send
                        ? _c("div", { staticClass: "offset-md-3" }, [
                            _c(
                              "small",
                              {
                                staticClass:
                                  "d-block alert-info text-info-noshow mt-2 p-2",
                              },
                              [
                                _vm.footprint_date_send === "NOW"
                                  ? [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$tl(
                                              "infos.booking.reservations.footprint.willBeSendOnSave"
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  : [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$t(
                                              "infos.booking.reservations.footprint.willBeSendOnDate",
                                              {
                                                date: _vm.footprint_date_send,
                                              }
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ],
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.reservation.option_bank === null && _vm.showConfirmation
              ? _c("div", { staticClass: "row m-0 mt-3" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                      _c(
                        "label",
                        {
                          staticClass: "container-box d-inline",
                          staticStyle: { width: "70px" },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.reservation.send_confirmation,
                                expression: "reservation.send_confirmation",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              disabled: _vm.send_confirmation_disabled,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.reservation.send_confirmation
                              )
                                ? _vm._i(
                                    _vm.reservation.send_confirmation,
                                    null
                                  ) > -1
                                : _vm.reservation.send_confirmation,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.reservation.send_confirmation,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.reservation,
                                        "send_confirmation",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.reservation,
                                        "send_confirmation",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.reservation,
                                    "send_confirmation",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "checkmark" }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.reservations.confirmation.send"
                                )
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.employee_accounts && _vm.employee_accounts.length > 0
              ? _c("div", { staticClass: "row m-0 mt-3" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                      _c("div", { staticClass: "row m-0" }, [
                        _c("div", { staticClass: "col-md-4 pt-1" }, [
                          _c("label", { staticClass: "d-block" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$tl("labels.booking.reservations.savedBy")
                                ) +
                                "\n                            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-8" },
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.reservation.user_id,
                                    expression: "reservation.user_id",
                                  },
                                ],
                                staticClass: "d-block custom-select mb-0",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.reservation,
                                      "user_id",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.employee_accounts, function (user) {
                                return _c(
                                  "option",
                                  {
                                    key: user.id,
                                    domProps: { value: user.id },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(user.firstname) +
                                        " " +
                                        _vm._s(user.lastname) +
                                        "\n                                "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("ShowErrors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.formErrors
                                  ? _vm.formErrors.errors
                                  : null,
                                errorKey: "user_id",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }