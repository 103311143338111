<template>
    <div class="p-2">
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Liste des factures</h5>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-2">
                <div>
                    <div style="margin-top: 4px" class="d-inline-block">
                        <form @submit="fetchInvoices">
                            <div class="row">
                                <div class="col-12 d-inline-block date-resa-cal">
                                    Du&nbsp;
                                    <datepicker
                                        class="d-inline-block"
                                        format="dd/MM/yyyy"
                                        input-class="form-control"
                                        :disabledDates="disabledDatesFrom"
                                        :monday-first="true"
                                        :language="fr"
                                        v-model="filter.from"></datepicker>
                                    <feather type="x" class="text-danger ml-1 pointer" @click="filter.from = null" />
                                    &nbsp;au&nbsp;
                                    <datepicker
                                        class="d-inline-block"
                                        format="dd/MM/yyyy"
                                        input-class="form-control"
                                        :disabledDates="disabledDatesTo"
                                        :monday-first="true"
                                        :language="fr"
                                        v-model="filter.to"></datepicker>
                                    <feather type="x" class="text-danger ml-1 pointer" @click="filter.to = null" />
                                    <button type="submit" class="btn btn-sm btn-success btn-circle ml-2">Rechercher</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div>
                    <div class="btn-group">
                        <button @click="downloadInvoices()" :disabled="loadingExport" class="btn btn-sm btn-outline-secondary btn-circle none-mobile">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-upload">
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                <polyline points="17 8 12 3 7 8"></polyline>
                                <line x1="12" y1="3" x2="12" y2="15"></line>
                            </svg>
                            Télécharger toutes les factures
                        </button>
                    </div>
                    <div class="btn-group">
                        <div>
                            <a target="_blank" :href="downloadRoyalties"><button class="btn btn-sm btn-success btn-circle">Export excel</button></a>
                        </div>
                    </div>
                </div>
            </div>

            <SortableTable
                tableClass="table table-sm table-striped border-bottom"
                :columns="columns"
                :data="filteredInvoices"
                :actionColumn="actionColumn" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import LoaderComponent from "../../components/LoaderComponent";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import SortableTable from "../../components/SortableTable";

export default {
    data() {
        return {
            loading: false,
            loadingExport: false,
            invoices: [],
            fr,
            filter: {
                from: null,
                to: null,
            },
            columns: [
                {
                    name: "Numéro",
                    key: "number",
                    sortable: true,
                    sortable_type: "string",
                },
                {
                    name: "Compte admin",
                    style: "text-transform: capitalize;",
                    sortable: true,
                    sortable_type: "string",
                    sort_on: "label",
                    data_format: "custom",
                    data_format_fct: (data) => (data.owner ? `${data.owner.firstname} ${data.owner.lastname}` : "Compte inexistant"),
                    get_custom_class: (data) => (data.owner ? "" : "text-danger"),
                },
                {
                    name: "Statut",
                    sortable: true,
                    sortable_type: "string",
                    sort_on: "label",
                    data_format: "custom",
                    data_format_fct: (data) => this.getInvoiceStatusLabel(data.status),
                    get_custom_class: (data) => this.getInvoiceStatusClass(data.status),
                },
                {
                    name: "Montant HT",
                    sortable: true,
                    sortable_type: "int",
                    data_format: "price_in_cent",
                    data_getter: (data) => this.getHtAmount(data),
                },
                {
                    name: "Montant TTC",
                    key: "amount_due",
                    sortable: true,
                    sortable_type: "int",
                    data_format: "price_in_cent",
                },
                {
                    name: "Date d'émission",
                    sortable: true,
                    sortable_type: "datetime",
                    data_format: "moment_unix",
                    data_format_moment: "DD MMM Y",
                    data_getter: (data) => this.getCreationDate(data),
                },
                {
                    name: "Date de paiement",
                    sortable: true,
                    sortable_type: "datetime",
                    data_format: "moment_unix",
                    data_format_moment: "DD MMM Y",
                    data_getter: (data) => this.getPaymentDate(data),
                },
                {
                    name: "Stripe",
                    key: "id",
                    sortable: false,
                    data_format: "string",
                    link: {
                        url: (data) => `${this.stripeInvoicesUrl}/${data.id}`,
                        target: "_blank",
                    },
                },
            ],
            actionColumn: {
                name: "Actions",
                buttons: [
                    {
                        feather: "download-cloud",
                        class: "btn btn-sm btn-outline-secondary btn-square",
                        disabled: (data) => !data.noshow_invoice,
                        link: {
                            url: (data) => "/api/admin/invoices/download?file=" + encodeURIComponent(data.noshow_invoice),
                            target: "_blank",
                        },
                    },
                ],
            },
        };
    },
    computed: {
        filteredInvoices() {
            return this.invoices.filter((invoice) => {
                if (!["sms_pack", "sms_campaign", "email_campaign"].includes(invoice.type)) {
                    return true;
                }

                return invoice.status !== "open" && invoice.status !== "past_due";
            });
        },
        stripeInvoicesUrl() {
            return this.isProduction() ? "https://dashboard.stripe.com/invoices" : "https://dashboard.stripe.com/test/invoices";
        },
        disabledDatesFrom() {
            if (!this.filter.to) {
                return undefined;
            }

            return {
                from: this.filter.to,
            };
        },
        disabledDatesTo() {
            if (!this.filter.from) {
                return undefined;
            }

            return {
                to: this.filter.from,
            };
        },
        downloadRoyalties() {
            let url = "/api/admin/invoices/download_royalties?";
            if (this.filter.from) {
                url += "&from=" + moment(this.filter.from).format("Y-MM-DD");
            }
            if (this.filter.to) {
                url += "&to=" + moment(this.filter.to).format("Y-MM-DD");
            }
            if (this.filter.type) {
                url += "&type=" + this.filter.type;
            }

            return url;
        },
    },
    methods: {
        getInvoiceStatusLabel(status) {
            switch (status) {
                case "paid":
                    return "Payée";
                case "uncollectible":
                    return "Annulée";
                case "open":
                    return "En retard";
                case "draft":
                    return "Brouillon";
                case "void":
                    return "Annulée";
                default:
                    return status;
            }
        },
        getInvoiceStatusClass(status) {
            switch (status) {
                case "paid":
                    return "text-success";
                case "uncollectible":
                    return "text-muted";
                case "open":
                    return "text-warning";
                default:
                    return status;
            }
        },
        getHtAmount(invoice) {
            let amount = 0;

            if (invoice.lines && invoice.lines.data) {
                invoice.lines.data.forEach((line) => (amount += line.amount));
            }

            return amount;
        },
        getCreationDate(invoice) {
            return invoice.status_transitions && invoice.status_transitions.finalized_at ? invoice.status_transitions.finalized_at : invoice.created;
        },
        getPaymentDate(invoice) {
            return invoice.status_transitions && invoice.status_transitions.paid_at ? invoice.status_transitions.paid_at : null;
        },
        fetchInvoices() {
            this.loading = true;
            this.invoices = [];

            let url = "/api/admin/invoices/all?include=owner,noshow_invoice";

            if (this.filter.from) {
                url += "&from=" + moment(this.filter.from).format("Y-MM-DD");
            }

            if (this.filter.to) {
                url += "&to=" + moment(this.filter.to).format("Y-MM-DD");
            }

            this.httpGet(url)
                .then((response) => {
                    if (response !== false) {
                        this.invoices = response.data.data;
                    }
                })
                .finally(() => (this.loading = false));
        },
        downloadInvoices() {
            this.loadingExport = true;

            let data = {};

            if (this.filter.from) {
                data.from = moment(this.filter.from).format("Y-MM-DD");
            }

            if (this.filter.to) {
                data.to = moment(this.filter.to).format("Y-MM-DD");
            }

            this.httpPost("/api/admin/invoices/download_all", data).finally(() => (this.loadingExport = false));
        },
    },
    components: {
        LoaderComponent,
        Datepicker,
        SortableTable,
    },
};
</script>
