<template>
    <div class="pb-5 pt-5">
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else>
                <div class="row m-0">
                    <div class="col-12">
                        <div class="d-flex justify-content-between pb-2 pt-2 mt-1 mb-1">
                            <h5 class="title mt-2">
                                {{ $tl("labels.routes.booking.config.notifications") }}
                            </h5>
                            <button type="button" class="btn btn-success btn-circle" :disabled="formLoading" @click="postForm">
                                {{ $tl("labels.form.actions.save") }}
                            </button>
                        </div>
                    </div>
                </div>

                <div v-if="formErrors && formErrors.length > 0" class="alert alert-danger">
                    <p v-for="(error, index) in formErrors" :key="index">
                        {{ error }}
                    </p>
                </div>

                <form id="editRestaurantConfig" @submit.prevent="postForm" method="post">
                    <LoaderComponent v-if="formLoading" />
                    <div v-else class="row m-0">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4 mb-3">
                                <div class="row mb-3">
                                    <div class="col-md-4">
                                        <strong>{{ $tl("labels.notifications.config") }}</strong>
                                        <feather
                                            type="info"
                                            class="ml-1 pointer tooltip-infos"
                                            v-tooltip="getTooltipNoticeLexicon('notices.booking.notifications.notificationsConfig', restaurant_id)" />
                                        <show-new-badge-details class="ml-1" badge_uuid="ba792829-2eb4-4b9b-9ada-6e05c37a4b01" />
                                    </div>
                                    <div class="col-md-8">
                                        <ChooseLang
                                            class="d-inline-block"
                                            style="z-index: auto !important"
                                            v-model="locale"
                                            :disabled="formLoading"
                                            is-input-mode
                                            should-translate-label>
                                            <template v-slot:default="{ lang }">
                                                <div
                                                    v-if="lang.value !== locale && getUnsavedChangeIndex(null, lang.value) !== -1"
                                                    class="ml-2 red-bubble"></div>
                                            </template>
                                        </ChooseLang>
                                        <div
                                            v-if="hasUnsavedChangeForOtherLocale"
                                            class="red-bubble"
                                            v-tooltip="getTooltip($t('infos.common.unsavedChanges'))"></div>
                                    </div>
                                </div>

                                <div>
                                    <EditNotification
                                        class="mb-4"
                                        :label="$t('labels.notifications.booking.createdShort')"
                                        mail-preview-id="client-created"
                                        sms-preview-id="client-created"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        v-bind="getPropertiesBindingForEditComponent('client_resa_created')"
                                        v-on="getEventsBindingForEditComponent('client_resa_created')"
                                        :formErrors="formErrors"
                                        errorKey="client_resa_created">
                                    </EditNotification>

                                    <div class="sepa"></div>

                                    <EditNotification
                                        class="mt-4 mb-4"
                                        :label="$t('labels.notifications.booking.confirmed')"
                                        mail-preview-id="client-validated"
                                        sms-preview-id="client-validated"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        v-bind="getPropertiesBindingForEditComponent('client_resa_validated')"
                                        v-on="getEventsBindingForEditComponent('client_resa_validated')"
                                        :formErrors="formErrors"
                                        errorKey="client_resa_validated">
                                    </EditNotification>

                                    <div class="sepa"></div>

                                    <EditNotification
                                        class="mt-4 mb-4"
                                        :label="$t('labels.notifications.booking.confirmedWithFootprint')"
                                        mail-preview-id="client-validated-footprint"
                                        sms-preview-id="client-validated-footprint"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        v-bind="getPropertiesBindingForEditComponent('client_resa_validated_footprint')"
                                        v-on="getEventsBindingForEditComponent('client_resa_validated_footprint')"
                                        :formErrors="formErrors"
                                        errorKey="client_resa_validated_footprint">
                                    </EditNotification>

                                    <div class="sepa"></div>

                                    <EditNotification
                                        class="mt-4 mb-4"
                                        :label="$t('labels.notifications.booking.refused')"
                                        mail-preview-id="client-refused"
                                        sms-preview-id="client-refused"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        v-bind="getPropertiesBindingForEditComponent('client_resa_refused')"
                                        v-on="getEventsBindingForEditComponent('client_resa_refused')"
                                        :formErrors="formErrors"
                                        errorKey="client_resa_refused">
                                    </EditNotification>

                                    <div class="sepa"></div>

                                    <EditNotification
                                        class="mt-4 mb-4"
                                        :label="$t('labels.notifications.booking.askFootprint')"
                                        mail-preview-id="client-resa-footprint"
                                        sms-preview-id="client-resa-footprint"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        v-bind="getPropertiesBindingForEditComponent('client_resa_footprint')"
                                        v-on="getEventsBindingForEditComponent('client_resa_footprint')"
                                        :formErrors="formErrors"
                                        errorKey="client_resa_footprint">
                                    </EditNotification>

                                    <div class="sepa"></div>

                                    <EditNotification
                                        class="mt-4 mb-4"
                                        :label="$t('labels.notifications.booking.askPayment')"
                                        mail-preview-id="client-resa-payment"
                                        sms-preview-id="client-resa-payment"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        v-bind="getPropertiesBindingForEditComponent('client_resa_payment')"
                                        v-on="getEventsBindingForEditComponent('client_resa_payment')"
                                        :formErrors="formErrors"
                                        errorKey="client_resa_payment">
                                    </EditNotification>

                                    <div class="sepa"></div>

                                    <EditNotification
                                        class="mt-4 mb-4"
                                        :label="$t('labels.notifications.booking.reminderFootprint')"
                                        mail-preview-id="client-resa-reminder-footprint"
                                        sms-preview-id="client-resa-reminder-footprint"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        v-bind="getPropertiesBindingForEditComponent('client_resa_reminder_footprint')"
                                        v-on="getEventsBindingForEditComponent('client_resa_reminder_footprint')"
                                        :formErrors="formErrors"
                                        errorKey="client_resa_reminder_footprint">
                                    </EditNotification>

                                    <div class="sepa"></div>

                                    <EditNotification
                                        class="mt-4 mb-4"
                                        :label="$t('labels.notifications.booking.reminderPayment')"
                                        mail-preview-id="client-resa-reminder-payment"
                                        sms-preview-id="client-resa-reminder-payment"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        v-bind="getPropertiesBindingForEditComponent('client_resa_reminder_payment')"
                                        v-on="getEventsBindingForEditComponent('client_resa_reminder_payment')"
                                        :formErrors="formErrors"
                                        errorKey="client_resa_reminder_payment">
                                    </EditNotification>

                                    <EditNotification
                                        class="mt-4 mb-4"
                                        :label="$t('labels.notifications.booking.reminder')"
                                        mail-preview-id="client-reminder"
                                        sms-preview-id="client-reminder"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        :canBeDisabled="true"
                                        v-bind="getPropertiesBindingForEditComponent('client_resa_reminder')"
                                        v-on="getEventsBindingForEditComponent('client_resa_reminder')"
                                        :formErrors="formErrors"
                                        errorKey="client_resa_reminder">
                                    </EditNotification>

                                    <div class="sepa"></div>

                                    <EditNotification
                                        class="mt-4 mb-4"
                                        :label="$t('labels.notifications.booking.askConfirmation')"
                                        mail-preview-id="client-ask-resa-confirmation"
                                        sms-preview-id="client-ask-resa-confirmation"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        v-bind="getPropertiesBindingForEditComponent('client_resa_ask_confirmation')"
                                        v-on="getEventsBindingForEditComponent('client_resa_ask_confirmation')"
                                        :formErrors="formErrors"
                                        errorKey="client_resa_ask_confirmation">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <label>{{ $tl("labels.notifications.send.title") }}</label>
                                            </div>
                                            <div class="col-md-8 radio">
                                                <radio-button
                                                    v-model="notifications_setting.client_resa_ask_confirmation_is_auto"
                                                    :inputValue="false"
                                                    :disabled="!has_right_to_update_notification">
                                                    {{ $tl("labels.notifications.send.manually") }}
                                                </radio-button>

                                                <radio-button
                                                    v-model="notifications_setting.client_resa_ask_confirmation_is_auto"
                                                    :inputValue="true"
                                                    :disabled="!has_right_to_update_notification"
                                                    :tooltip="$t('infos.booking.notifications.send24HoursBeforeResa')">
                                                    {{ $tl("labels.notifications.send.auto") }}
                                                </radio-button>

                                                <div class="mt-2">
                                                    <small v-if="notifications_setting.client_resa_ask_confirmation_is_auto">
                                                        {{ $t("infos.booking.notifications.send24HoursBeforeResa") }}
                                                    </small>
                                                </div>
                                            </div>

                                            <ShowErrors class="d-block" :errors="formErrors" errorKey="client_resa_ask_confirmation_is_auto" />
                                        </div>
                                    </EditNotification>

                                    <div class="sepa"></div>

                                    <EditNotification
                                        class="mt-4 mb-4"
                                        :label="$t('labels.notifications.booking.canceled')"
                                        mail-preview-id="client-canceled"
                                        sms-preview-id="client-canceled"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        v-bind="getPropertiesBindingForEditComponent('client_resa_canceled')"
                                        v-on="getEventsBindingForEditComponent('client_resa_canceled')"
                                        :formErrors="formErrors"
                                        errorKey="client_resa_canceled">
                                    </EditNotification>

                                    <div class="sepa"></div>

                                    <EditNotification
                                        class="mt-4 mb-4"
                                        :label="$t('labels.notifications.booking.canceledNoFootprint')"
                                        mail-preview-id="client-canceled-footprint"
                                        sms-preview-id="client-canceled-footprint"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        v-bind="getPropertiesBindingForEditComponent('client_resa_canceled_footprint')"
                                        v-on="getEventsBindingForEditComponent('client_resa_canceled_footprint')"
                                        :formErrors="formErrors"
                                        errorKey="client_resa_canceled_footprint">
                                    </EditNotification>

                                    <div class="sepa"></div>

                                    <EditNotification
                                        class="mt-4 mb-4"
                                        :label="$t('labels.notifications.booking.canceledNoPayment')"
                                        mail-preview-id="client-canceled-payment"
                                        sms-preview-id="client-canceled-payment"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        v-bind="getPropertiesBindingForEditComponent('client_resa_canceled_payment')"
                                        v-on="getEventsBindingForEditComponent('client_resa_canceled_payment')"
                                        :formErrors="formErrors"
                                        errorKey="client_resa_canceled_payment">
                                    </EditNotification>

                                    <div class="sepa"></div>

                                    <EditNotification
                                        class="mt-4 mb-4"
                                        :label="$tl('labels.notifications.booking.tableAvailable', restaurant_id)"
                                        mail-preview-id="client-resa-available"
                                        sms-preview-id="client-resa-available"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        v-bind="getPropertiesBindingForEditComponent('client_resa_available')"
                                        v-on="getEventsBindingForEditComponent('client_resa_available')"
                                        :formErrors="formErrors"
                                        errorKey="client_resa_available">
                                    </EditNotification>

                                    <div class="sepa"></div>

                                    <EditNotification
                                        class="mt-4 mb-4"
                                        :label="$t('labels.notifications.booking.noshowTotal')"
                                        mail-preview-id="client-noshow"
                                        sms-preview-id="client-noshow"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        v-bind="getPropertiesBindingForEditComponent('client_resa_noshow')"
                                        v-on="getEventsBindingForEditComponent('client_resa_noshow')"
                                        :formErrors="formErrors"
                                        errorKey="client_resa_noshow">
                                    </EditNotification>

                                    <div class="sepa"></div>

                                    <EditNotification
                                        class="mt-4 mb-4"
                                        :label="$t('labels.notifications.booking.noshowPartial')"
                                        mail-preview-id="client-noshow-partial"
                                        sms-preview-id="client-noshow-partial"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        v-bind="getPropertiesBindingForEditComponent('client_resa_noshow_partial')"
                                        v-on="getEventsBindingForEditComponent('client_resa_noshow_partial')"
                                        :formErrors="formErrors"
                                        errorKey="client_resa_noshow_partial">
                                    </EditNotification>

                                    <div class="sepa"></div>

                                    <EditNotification
                                        class="mt-4 mb-4"
                                        :label="$t('labels.notifications.booking.noshowFree')"
                                        mail-preview-id="client-noshow-free"
                                        sms-preview-id="client-noshow-free"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        v-bind="getPropertiesBindingForEditComponent('client_resa_noshow_free')"
                                        v-on="getEventsBindingForEditComponent('client_resa_noshow_free')"
                                        :formErrors="formErrors"
                                        errorKey="client_resa_noshow_free">
                                    </EditNotification>

                                    <div class="sepa"></div>

                                    <EditNotification
                                        class="mt-4 mb-4"
                                        :label="$t('labels.notifications.booking.overThanks')"
                                        mail-preview-id="client-thanks"
                                        sms-preview-id="client-thanks"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        :canBeDisabled="true"
                                        v-bind="getPropertiesBindingForEditComponent('client_thanks')"
                                        v-on="getEventsBindingForEditComponent('client_thanks')"
                                        :formErrors="formErrors"
                                        errorKey="client_thanks">
                                    </EditNotification>

                                    <div class="sepa"></div>

                                    <EditNotification
                                        class="mt-4 mb-4"
                                        :label="$tl('labels.notifications.booking.serviceOver', restaurant_id)"
                                        mail-preview-id="client-waiting-service-ended"
                                        sms-preview-id="client-waiting-service-ended"
                                        :isSmsModeAvailable="notifications_setting.sms_activated"
                                        :disabled="!has_right_to_update_notification"
                                        :canBeDisabled="true"
                                        v-bind="getPropertiesBindingForEditComponent('client_waiting_service_ended')"
                                        v-on="getEventsBindingForEditComponent('client_waiting_service_ended')"
                                        :formErrors="formErrors"
                                        errorKey="client_waiting_service_ended">
                                    </EditNotification>
                                </div>
                            </div>

                            <div class="border-light b-radius-20 p-4">
                                <div class="row mb-3">
                                    <div class="col-12 mb-2">
                                        <strong>{{ $tl("labels.notifications.qrCode.title") }}</strong>
                                        <feather
                                            type="info"
                                            class="ml-1 pointer tooltip-infos"
                                            v-tooltip="getTooltipNoticeLexicon('notices.booking.notifications.qrCode', restaurant_id)" />
                                    </div>
                                    <div class="col-4 mt-2">
                                        <label class="mb-1">{{ $tl("labels.notifications.qrCode.enableForResa") }}</label>
                                    </div>
                                    <div class="col-8 mt-2">
                                        <label class="container-box">
                                            <input
                                                :disabled="!has_right_to_update_notification"
                                                type="checkbox"
                                                :value="true"
                                                v-model="notifications_setting.enable_qr_code" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-0 mt-3">
                        <div class="col-12">
                            <input
                                type="submit"
                                name="submit"
                                class="btn btn-success btn-circle"
                                :disabled="!has_right_to_update_notification"
                                :value="$t('labels.form.actions.save')" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import EditNotification from "../../../components/notifications/EditNotification.vue";
import RadioButton from "../../../components/forms/RadioButtonComponent.vue";
import ShowErrors from "../../../components/errors/ShowErrors.vue";
import ChooseLang from "../../../components/Default/ChooseLang.vue";
import LangsEnum from "../../../mixins/enums/LangsEnum.js";
import showNewBadgeDetails from "../../../components/NewBadges/showNewBadgeDetails.vue";

export default {
    data() {
        return {
            loading: false,
            error: null,
            formLoading: null,
            formErrors: null,
            notifications_setting: null,
            originalNotificationsContents: [],
            locale: null,
            unsavedChanges: [],
        };
    },
    mixins: [LangsEnum],
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_notification() {
            return this.rights.includes("booking.notification.update");
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        notificationContent() {
            if (this.notifications_setting === null) {
                return undefined;
            }

            return this.notifications_setting.notifications_contents.data.find((nc) => nc.locale === this.locale);
        },
        originalNotificationContent() {
            return this.originalNotificationsContents.find((obc) => obc.locale === this.locale);
        },
        hasUnsavedChangeForOtherLocale() {
            return this.unsavedChanges.some((change) => change.locale !== this.locale);
        },
    },
    methods: {
        getPropertiesBindingForEditComponent(key) {
            if (typeof this.notificationContent === "undefined") {
                return {};
            }

            return {
                mode: this.notifications_setting[`${key}_mode`],
                "sms-content": this.notificationContent[`${key}_sms_content`],
                "sms-merge-tags": this.notifications_setting.sms_merge_tags[key],
                "has-sms-unsaved-change": this.getUnsavedChangeIndex(`${key}_sms_content`, this.locale) !== -1,
                "mail-content": this.notificationContent[`${key}_mail_content`],
                "mails-merge-tags": this.notifications_setting.mails_merge_tags[key],
                "has-mail-unsaved-change": this.getUnsavedChangeIndex(`${key}_mail_content`, this.locale) !== -1,
                "content-locale": this.locale,
            };
        },
        getEventsBindingForEditComponent(key) {
            if (typeof this.notificationContent === "undefined") {
                return {};
            }

            return {
                "set-mode": (e) => (this.notifications_setting[`${key}_mode`] = e),
                "set-sms-content": (e) => {
                    this.notificationContent[`${key}_sms_content`] = e;

                    if (this.originalNotificationContent[`${key}_sms_content`] !== e) {
                        this.addUnsavedChange(`${key}_sms_content`, this.locale);
                    } else {
                        this.removeUnsavedChange(`${key}_sms_content`, this.locale);
                    }
                },
                "set-mail-content": (e) => {
                    this.notificationContent[`${key}_mail_content`] = e;

                    if (this.originalNotificationContent[`${key}_mail_content`] !== e) {
                        this.addUnsavedChange(`${key}_mail_content`, this.locale);
                    } else {
                        this.removeUnsavedChange(`${key}_mail_content`, this.locale);
                    }
                },
            };
        },
        addUnsavedChange(key, locale) {
            const index = this.getUnsavedChangeIndex(key, locale);

            if (index === -1) {
                this.unsavedChanges.push({ key, locale });
            }
        },
        removeUnsavedChange(key, locale) {
            const index = this.getUnsavedChangeIndex(key, locale);

            if (index !== -1) {
                this.unsavedChanges.splice(index, 1);
            }
        },
        getUnsavedChangeIndex(key, locale) {
            return this.unsavedChanges.findIndex((change) => (change.key === key || key === null) && change.locale === locale);
        },
        fetchData() {
            this.error = null;
            this.loading = true;

            axios
                .get(
                    `/api/restaurants/${this.restaurant_id}/configs?include=notifications_setting.notifications_contents,notifications_setting.mails_merge_tags,notifications_setting.sms_merge_tags`
                )
                .then((response) => {
                    this.notifications_setting = response.data.notifications_setting;
                    this.originalNotificationsContents = _.cloneDeep(this.notifications_setting.notifications_contents.data);
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        postForm() {
            this.formErrors = null;
            this.formLoading = true;

            axios
                .put(`/api/restaurants/${this.restaurant_id}/configs`, this.notifications_setting)
                .then((response) => {
                    this.notifySuccess(response);

                    this.unsavedChanges = [];

                    this.fetchData();
                })
                .catch((error) => {
                    this.notifyError(error);

                    if (error.response && error.response.data) {
                        this.formErrors = error.response.data.errors;
                    }
                })
                .finally(() => {
                    this.formLoading = false;
                });
        },
        beforeWindowUnload(e) {
            if (this.unsavedChanges.length > 0) {
                e.preventDefault();

                // Should customize closing confirm message, but doesn't work on all browsers
                // See https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#browser_compatibility
                return (e.returnValue = this.$tl("questions.common.unsavedChanges"));
            }
        },
    },
    created() {
        this.locale = this.userLang;

        window.addEventListener("beforeunload", this.beforeWindowUnload);

        this.fetchData();
    },
    beforeDestroy() {
        window.removeEventListener("beforeunload", this.beforeWindowUnload);
    },
    watch: {
        restaurant_id() {
            this.fetchData();
        },
        unsavedChanges(newVal) {
            this.$store.dispatch("restaurants/setUnsavedNotificationsChanges", newVal.length > 0);
        },
    },
    components: {
        LoaderComponent,
        EditNotification,
        RadioButton,
        ShowErrors,
        ChooseLang,
        showNewBadgeDetails,
    },
};
</script>
