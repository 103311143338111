<template>
    <div>
        <LoaderComponent v-if="loading" />
        <template v-else>
            <label class="mb-3">
                <strong>{{ $tl("labels.booking.reservations.privateComment") }}</strong>
            </label>
            <div class="row mb-3">
                <div class="col-12 tags-com-resa">
                    <div>
                        <span
                            v-for="(tag, indexTag) in restaurant.custom_tags"
                            :key="indexTag"
                            class="badge badge-secondary p-1 pl-2 pr-2"
                            style="margin-right: 2px"
                            @click="addComment(getReservationTagLabelFromFrLabel(tag) || tag)">
                            {{ getReservationTagLabelFromFrLabel(tag) || tag }}
                        </span>
                    </div>
                    <textarea
                        name="restaurant_comment"
                        tabindex="11"
                        autocomplete="off"
                        v-model="reservation.restaurant_comment"
                        class="form-control mt-1"
                        rows="6">
                    </textarea>
                    <div v-if="hasErrors('restaurant_comment')">
                        <div class="invalid-feedback d-block" v-for="(err, i) in formErrors.errors.restaurant_comment" :key="i">
                            {{ err }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="sepa"></div>
            <div class="row">
                <div class="col-md-5 pt-2">
                    <label>{{ $tl("labels.booking.reservations.giftVoucher.title") }}</label>
                </div>
                <div class="col-md-7">
                    <input
                        type="text"
                        name="gift"
                        autocomplete="off"
                        v-model="reservation.gift"
                        tabindex="12"
                        class="form-control"
                        :class="{ 'is-invalid': hasErrors('gift') }" />
                    <div v-if="hasErrors('gift')">
                        <div class="invalid-feedback d-block" v-for="(err, i) in formErrors.errors.gift" :key="i">
                            {{ err }}
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="isHotelModeEnabled">
                <div class="sepa"></div>
                <switch-input-component :wrapperClasses="{ 'mb-2': false }" labelWrapperClasses="pt-2" v-model="reservation.is_hotel_client">
                    <template v-slot:label>
                        {{ $tl("labels.clients.isHotel") }}
                    </template>
                </switch-input-component>
            </template>
            <template v-if="isFeatRoomNumbersEnable || reservation.is_hotel_client">
                <div v-if="!isHotelModeEnabled" class="sepa"></div>
                <div class="row">
                    <div class="col-md-5 pt-2">
                        {{ $tl("labels.booking.reservations.roomNumberShort") }}
                    </div>
                    <div class="col-md-7">
                        <input type="text" class="form-control" v-model="reservation.room_number" />
                        <div
                            v-if="
                                reservation.room_number !== null &&
                                reservation.room_number.length > 0 &&
                                roomNumbersForAutocomplete.length > 0 &&
                                !isRoomNumberValid
                            "
                            class="room-dropdown">
                            <span
                                class="d-block pointer"
                                v-for="room in roomNumbersForAutocomplete"
                                :key="room.id"
                                @click="reservation.room_number = room.number">
                                {{ room.number }}
                            </span>
                        </div>
                        <span
                            class="text-danger d-block"
                            v-if="
                                roomNumbers.length > 0 &&
                                !isRoomNumberValid &&
                                reservation.room_number !== null &&
                                reservation.room_number.length > 0 &&
                                roomNumbersForAutocomplete.length === 0
                            ">
                            {{ $tl("errors.booking.reservations.noHotelRoomFound") }}
                        </span>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import LoaderComponent from "../../LoaderComponent.vue";
import ReservationTagsEnum from "../../../mixins/enums/booking/ReservationTagsEnum.js";
import SwitchInputComponent from "../../forms/SwitchInputComponent.vue";

export default {
    name: "commentaryReservations",
    data() {
        return {
            loading: false,
            roomNumbers: [],
        };
    },
    mixins: [ReservationTagsEnum],
    props: {
        restaurant_id: {
            default: null,
        },
        reservation: {
            type: Object,
            required: true,
        },
        restaurant: {
            type: Object,
            required: true,
        },
        formErrors: {
            default: null,
        },
        readOnly: {
            default: false,
        },
    },
    computed: {
        isRoomNumberValid() {
            const firstRoomNumberInList = this.roomNumbersForAutocomplete.length > 0 ? this.roomNumbersForAutocomplete[0] : null;
            return firstRoomNumberInList && this.reservation.room_number !== null && this.reservation.room_number === firstRoomNumberInList.number;
        },
        roomNumbersForAutocomplete() {
            if (this.reservation.room_number !== null && this.reservation.room_number.length > 0)
                return this.roomNumbers.filter((room) => room.number.toLowerCase().includes(this.reservation.room_number.toLowerCase()));
            return [];
        },
        restaurant_id_() {
            return this.restaurant_id || this.$route.params.restaurant_id;
        },
        isFeatRoomNumbersEnable() {
            const restaurant = this.$store.getters["restaurants/findRestaurantById"](this.restaurant_id_);

            return restaurant ? restaurant.feat_room_numbers : false;
        },
        isHotelModeEnabled() {
            return this.$store.getters["widgets/getWidget"].hotel_mode;
        },
    },
    methods: {
        fetchRoomNumbers() {
            this.loading = true;
            this.roomNumbers = [];

            this.httpGet(`/api/restaurants/${this.restaurant_id}/roomNumbers`)
                .then((response) => {
                    if (response !== false) {
                        this.roomNumbers = response.data.data;
                    }
                })
                .finally(() => (this.loading = false));
        },
        addComment(text) {
            if (this.reservation.restaurant_comment) this.reservation.restaurant_comment += `\n${text}`;
            else this.reservation.restaurant_comment = text;
        },
        hasErrors(name) {
            return this.formErrors && this.formErrors.errors && !_.isEmpty(this.formErrors.errors[name]);
        },
    },
    watch: {
        restaurant_id_: {
            immediate: true,
            handler() {
                if (this.isFeatRoomNumbersEnable) {
                    this.fetchRoomNumbers();
                }
            },
        },
        "reservation.is_hotel_client": function (newVal) {
            if (!newVal) {
                this.reservation.room_number = null;
            }
        },
    },
    components: {
        LoaderComponent,
        SwitchInputComponent,
    },
};
</script>
