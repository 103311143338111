<template>
    <div class="w-100">
        <loader-component v-if="nbLoading > 0" />
        <div class="default-home" v-else-if="typeof restaurant !== 'undefined' && hasOptedIn !== null && isReserveWithGoogleEnabled()">
            <div class="row m-0">
                <div class="col-12">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2">{{ $tl("labels.routes.rwg", restaurant.id) }}</h5>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-between align-item-center">
                                <h5 class="title mt-2">{{ restaurant.name }}</h5>
                                <p v-if="hasOptedIn" class="mt-2 mb-0 font-italic">
                                    {{ $tl("labels.googleReserve.contract.active", restaurant.id, { date: displayDate(optedInDate, DATE_SHORT) }) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <div class="row mb-3">
                            <div class="col-12 d-flex justify-content-between">
                                <strong>{{ $tl("labels.googleReserve.contract.title", restaurant.id) }}</strong>
                                <button @click="toggleOptIn" class="btn btn-circle btn-sm" :class="hasOptedIn ? 'btn-danger' : 'btn-primary'">
                                    {{ $tl(`labels.googleReserve.contract.actions.${this.currentAction}.btn`, restaurant.id) }}
                                </button>
                            </div>
                        </div>
                        <div class="row text-center">
                            <div class="col-12 pt-2">
                                <a href="https://storage.googleapis.com/noshow-data/noshow/cgu_reserve_with_google.pdf" target="_blank">
                                    <p class="bg-light p-3">{{ $tl("labels.googleReserve.contract.terms", restaurant.id) }}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-if="appUpdateContent !== null">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <div class="row mb-3">
                            <div class="col-12">
                                <strong>{{ $tl("labels.googleReserve.setup", restaurant.id) }}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 pt-2 ck-content" style="overflow-x: auto" v-html="appUpdateContent"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="text-danger">{{ $tl("errors.common.unknown", restaurant.id) }}</div>

        <opt-in-modal v-if="showOptInModal" :restaurant-id="restaurantId" @close="showOptInModal = false" @opted-in="onOptedIn"></opt-in-modal>

        <opt-out-modal v-if="showOptOutModal" :restaurant-id="restaurantId" @close="showOptOutModal = false" @opted-out="onOptedOut"></opt-out-modal>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import OptInModal from "../../../components/Modals/reserveWithGoogle/OptInModal.vue";
import OptOutModal from "../../../components/Modals/reserveWithGoogle/OptOutModal.vue";

export default {
    data() {
        return {
            nbLoading: 0,
            error: false,
            hasOptedIn: null,
            optedInDate: null,
            showOptInModal: false,
            showOptOutModal: false,
            appUpdateContent: null,
        };
    },
    computed: {
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        restaurant() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurantId);
        },
        currentAction() {
            return this.hasOptedIn ? "optOut" : "optIn";
        },
        appUpdateId() {
            if (this.isDevelopement()) {
                return 2;
            }

            if (this.isProduction()) {
                return 9;
            }

            return null;
        },
    },
    methods: {
        fetchHasOptedIn() {
            this.nbLoading++;

            this.httpGet(`/api/reserve_with_google/${this.restaurantId}/has_opted_in`)
                .then((response) => {
                    if (response !== false) {
                        this.hasOptedIn = response.data.has_opted_in;

                        if (this.hasOptedIn) {
                            this.optedInDate = response.data.has_opted_in_on;
                        }
                    }
                })
                .finally(() => this.nbLoading--);
        },
        fetchAppUpdate() {
            if (this.appUpdateId === null) {
                return;
            }

            this.nbLoading++;

            this.httpGet(`/api/app_updates/${this.appUpdateId}`, { handleReject: false })
                .then((response) => {
                    if (response !== false) {
                        this.appUpdateContent = response.data.content;
                    }
                })
                .finally(() => this.nbLoading--);
        },
        toggleOptIn() {
            this[this.currentAction]();
        },
        optIn() {
            this.showOptInModal = true;
        },
        optOut() {
            this.showOptOutModal = true;
        },
        onOptedIn() {
            this.showOptInModal = false;

            this.fetchHasOptedIn();
        },
        onOptedOut() {
            this.showOptOutModal = false;

            this.fetchHasOptedIn();
        },
    },
    created() {
        this.fetchHasOptedIn();

        this.fetchAppUpdate();
    },
    components: {
        LoaderComponent,
        OptInModal,
        OptOutModal,
    },
};
</script>
