var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(
        _vm._s(_vm.$tl("labels.clients.import.title")) +
          " - " +
          _vm._s(_vm.$t("labels.stepX", { step: _vm.step })) +
          " - " +
          _vm._s(_vm.stepName)
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm.loading || _vm.loadingImport
          ? _c("LoaderComponent", {
              attrs: {
                message: _vm.loadingImport
                  ? _vm.$tl("labels.clients.import.inProgress")
                  : undefined,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading && !_vm.loadingImport,
                expression: "!loading && !loadingImport",
              },
            ],
            staticClass: "row",
          },
          [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("Step1", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 1,
                      expression: "step === 1",
                    },
                  ],
                  ref: "step1Component",
                  staticClass: "border-light b-radius-20 p-4",
                  attrs: { file: _vm.file },
                  on: { "set-data": _vm.setData },
                }),
                _vm._v(" "),
                _c("Step2", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 2,
                      expression: "step === 2",
                    },
                  ],
                  ref: "step2Component",
                  staticClass: "border-light b-radius-20 p-4",
                  attrs: { firstLines: _vm.firstLines, file: _vm.file },
                  on: { "set-data": _vm.setData, "import-done": _vm.close },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            on: { click: _vm.close },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.close")) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.step > 1
          ? _c(
              "button",
              {
                staticClass: "btn btn-sm btn-secondary btn-circle",
                class: { disabled: _vm.btnDisabled },
                attrs: { disabled: _vm.btnDisabled },
                on: { click: _vm.previousStep },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$tl("labels.form.actions.previous")) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-success btn-circle ml-2",
            class: { disabled: _vm.btnDisabled },
            attrs: { disabled: _vm.btnDisabled },
            on: { click: _vm.nextStep },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.step < 2
                    ? _vm.$tl("labels.form.actions.next")
                    : _vm.$tl("labels.form.actions.import")
                ) +
                "\n        "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }